import {Directive, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Subscription} from "rxjs";

@Directive({
  selector: '[hover]'
})
export class HoverDirective implements OnInit, OnDestroy {
  @Input() public hoverClass: string = "hover";

  public constructor(
    private elementRef: ElementRef,
    private zone: NgZone,
    private renderer: Renderer2) {
  }

  public ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.subscriptions.add(this.registerElementListener('mouseenter',(event:MouseEvent) => this.onMouseEnter(event)));
      this.subscriptions.add(this.registerElementListener('mouseleave',(event:MouseEvent) => this.onMouseLeave(event)));
      this.subscriptions.add(this.registerElementListener('mouseover',(event:MouseEvent) => this.onMouseOver(event)));
    });
  }

  protected registerElementListener(event:string,handler:(event:DragEvent|KeyboardEvent|TouchEvent|MouseEvent)=>void):()=>void {
    return this.renderer.listen(this.elementRef.nativeElement,event,(event:DragEvent|KeyboardEvent|TouchEvent|MouseEvent)=>this.zone.run(()=>handler(event)));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  protected entered = false;
  protected added   = false;
  protected subscriptions = new Subscription();

  onMouseEnter(event:MouseEvent) {
    this.entered = true;
    this.added   = false;
    if (event.buttons==0) {
      this.added = true;
      this.elementRef.nativeElement.classList.add(this.hoverClass);
    }
  }

  onMouseLeave(event:MouseEvent) {
    this.entered = false;
    this.added   = false;
    this.elementRef.nativeElement.classList.remove(this.hoverClass);
  }

  onMouseOver(event:MouseEvent) {
    if (!this.added && this.entered && event.buttons==0) {
      this.added = true;
      this.elementRef.nativeElement.classList.add(this.hoverClass);
    }
  }
}
