import {Inject, Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import includes  from "lodash/includes";

import {Logger} from '../logger.service';
import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {SyncStorage} from "../storage/sync/storage";
import {LOCAL_STORAGE} from "../storage/storage.module";
import {isEqual} from "lodash";
// import deDE from '../../translations/de-DE.json';
// import enUS from '../../translations/en-US.json';

const log = new Logger('I18nService');
const languageKey = 'language';

export interface Registration {
  deregister(): boolean;
}

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string) {
  return s;
}

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  defaultLanguage: string;
  languageChangeSubscription: Subscription;
  translateServices: TranslateService[] = [];

  private _supportedLanguagesSubject$ = new BehaviorSubject<string[]>([]);
  private _supportedLanguages$ = this._supportedLanguagesSubject$.asObservable();
  private _language$ = new BehaviorSubject<string>(undefined);

  protected logger = new Logger('I18nService');

  constructor(private translateService: TranslateService,
              @Inject(LOCAL_STORAGE)
              private storage: SyncStorage) {
    // Embed languages to avoid extra HTTP requests
    // translateService.setTranslation('de-DE', deDE);
    // translateService.setTranslation('en-US', enUS);

    // https://angular.io/api/common/DecimalPipe
    // needs preloaded locale data to work....
    registerLocaleData(localeEn,"en");
    registerLocaleData(localeDe,"de");
    registerLocaleData(localeFr,"fr");
    //registerLocaleData(localeEs,"es");
    this._supportedLanguagesSubject$.subscribe(languages => {
      if (!isEqual(languages, this.translateService.langs)) {
        this.translateService.langs = [...languages];
      }
    })
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.logger.trace("INIT", { defaultLanguage, supportedLanguages });
    this.defaultLanguage = defaultLanguage;
    supportedLanguages = supportedLanguages || []
    this._supportedLanguagesSubject$.next(supportedLanguages);
    if (supportedLanguages.length && !supportedLanguages.includes(this.defaultLanguage)) {
      this.defaultLanguage = supportedLanguages[0];
    }
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
    //this.translateService.setDefaultLang(defaultLanguage); // value will taken from defaultLanguage when translation in current language is missing
    this.languageChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.storage.set(languageKey, event.lang);
      this._language$.next(event.lang);
    });
    this._language$.next(this.language);
  }

  register(translateService: TranslateService): Registration {
    if (this.translateServices.indexOf(translateService) < 0) {
      translateService.langs = [...this.supportedLanguages];
      // translateService.use(this.language);
      this.translateServices.push(translateService);
    }
    return {
      deregister: () => {
        let index = this.translateServices.indexOf(translateService);
        if (index >= 0) {
          this.translateServices.splice(index, 1, null);
          return true;
        }
        return false;
      }
    }
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  setLanguage(language?: string): Promise<void> {
    const supportedLanguages = this.supportedLanguages;
    language = language || this.storage.get(languageKey) || this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = includes(supportedLanguages, language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }
    //log.debug(`Language set to ${language} (browser.default:${this.translateService.getBrowserCultureLang()})`);
    const promises: Promise<any>[] = [];
    promises.push(this.translateService.use(language).toPromise());
    this.translateServices.forEach((translateService) => {
      if (translateService!=this.translateService) {
        this.storage.set(languageKey,language);
        promises.push(translateService.use(language).toPromise());
      }
    });
    return Promise.all(promises).then(result => Promise.resolve())
  }

  reloadLanguage(): Promise<void> {
    const promises: Promise<any>[] = [];
    promises.push(this.translateService.reloadLang(this.translateService.currentLang).toPromise());
    this.translateServices.forEach((translateService) => {
      const language = translateService.currentLang;
      if (translateService!=this.translateService) {
        promises.push(translateService.reloadLang(language).toPromise().then(result => {
          if (Object.keys(result).length > 0) {
            return result;
          } else {
            throw new Error(`Failed to reload language ${language}`);
          }
        }));
      }
    });
    return Promise.all(promises).then(result => Promise.resolve())
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

  get storedLanguage(): string {
    return this.storage.get(languageKey) || this.translateService.getBrowserCultureLang();
  }

  /**
   * Gets the current language as observable.
   * @return The observable for the language code.
   */
  get language$(): Observable<string> {
    return this._language$;
  }

  get supportedLanguages$(): Observable<string[]> {
    return this._supportedLanguages$;
  }

  get supportedLanguages(): string[] {
    return this._supportedLanguagesSubject$.getValue();
  }
}
