import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, cordova } from '@ionic-native/core';
import { Observable } from 'rxjs';
var QRScanner = /** @class */function (_super) {
  __extends(QRScanner, _super);
  function QRScanner() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  QRScanner.prototype.prepare = function () {
    return cordova(this, "prepare", {
      "callbackStyle": "node"
    }, arguments);
  };
  QRScanner.prototype.scan = function () {
    return cordova(this, "scan", {
      "callbackStyle": "node",
      "observable": true,
      "clearFunction": "cancelScan"
    }, arguments);
  };
  QRScanner.prototype.show = function () {
    return cordova(this, "show", {}, arguments);
  };
  QRScanner.prototype.hide = function () {
    return cordova(this, "hide", {}, arguments);
  };
  QRScanner.prototype.enableLight = function () {
    return cordova(this, "enableLight", {
      "callbackStyle": "node"
    }, arguments);
  };
  QRScanner.prototype.destroy = function () {
    return cordova(this, "destroy", {}, arguments);
  };
  QRScanner.prototype.disableLight = function () {
    return cordova(this, "disableLight", {
      "callbackStyle": "node"
    }, arguments);
  };
  QRScanner.prototype.useFrontCamera = function () {
    return cordova(this, "useFrontCamera", {
      "callbackStyle": "node"
    }, arguments);
  };
  QRScanner.prototype.useBackCamera = function () {
    return cordova(this, "useBackCamera", {
      "callbackStyle": "node"
    }, arguments);
  };
  QRScanner.prototype.useCamera = function (camera) {
    return cordova(this, "useCamera", {
      "callbackStyle": "node"
    }, arguments);
  };
  QRScanner.prototype.pausePreview = function () {
    return cordova(this, "pausePreview", {}, arguments);
  };
  QRScanner.prototype.resumePreview = function () {
    return cordova(this, "resumePreview", {}, arguments);
  };
  QRScanner.prototype.getStatus = function () {
    return cordova(this, "getStatus", {}, arguments);
  };
  QRScanner.prototype.openSettings = function () {
    return cordova(this, "openSettings", {
      "sync": true
    }, arguments);
  };
  QRScanner.pluginName = "QRScanner";
  QRScanner.plugin = "cordova-plugin-qrscanner";
  QRScanner.pluginRef = "QRScanner";
  QRScanner.repo = "https://github.com/bitpay/cordova-plugin-qrscanner";
  QRScanner.platforms = ["Android", "Browser", "iOS", "Windows"];
  QRScanner.decorators = [{
    type: Injectable
  }];
  return QRScanner;
}(IonicNativePlugin);
export { QRScanner };
