import {NgModule, Optional, SkipSelf} from "@angular/core";
import {EffectsModule} from "@ngrx/effects";
import {StoreModule} from "@ngrx/store";
import {PropertiesEffects} from "./store/effects";
import {propertiesFeatureKey, propertiesReducer} from "./store/reducers";
import {PropertiesService} from "./properties-service";
import {take} from "rxjs/operators";
import {NULL_USER, User} from "core";
import {Device} from "@ionic-native/device/ngx";

export function initializeProperties(propertiesService: PropertiesService) {
  // this factory needs to return a function (that then returns a promise)
  return () => {
    return propertiesService.reload().then((properties) =>
      console.info("APP PROPERTIES LOADED",properties)
    );
  }
}

export function nullUserFactory(): User {
  return User.NULL;
}

@NgModule({
  imports: [
    StoreModule.forFeature(propertiesFeatureKey, propertiesReducer),
    EffectsModule.forFeature([PropertiesEffects]),
  ],
  providers: [
    // AppIdentifierProvider,
    // PropertiesService,
    Device,

    // {
    //   'provide'   : APP_INITIALIZER,
    //   'useFactory': initializeProperties,
    //   'deps'      : [PropertiesService],
    //   'multi'     : true,
    // },

    // using User.NULL causes library build time error:
    // "Only initialized variables and constants can be referenced in decorators"
    // { provide: NULL_USER, useValue: User.NULL}
    { provide: NULL_USER, useFactory: nullUserFactory}
  ]
})
export class PropertiesModule {

  constructor(@Optional() @SkipSelf() parentModule: PropertiesModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import PropertiesModule module only once.`);
    }
  }
}
