import {
  clearAnimationAction,
  closeSidenavAction,
  openSidenavAction,
  setSidenavContentAction,
  setSidenavModeAction,
  updateSidenavAction
} from "./actions";
import isEqual from "lodash/isEqual";
import {createFeatureSelector, createReducer, createSelector, on} from "@ngrx/store";

export enum Sidenav {
  NAVIGATION = "navigation",
  DETAILS = "details"
}

export enum SidenavMode {
  OVER = "over",
  SIDE = "side",
  PUSH = "push"
}

export interface SidenavState {
  open: boolean | null,
  animation: boolean,         // currently animated
  mode: SidenavMode | null,   // 'over' overlaps, 'side' reduces size of main content
  preferredMode: SidenavMode | null,
  contentType: string | null, // content type
  content: any | null         // depending on type, a content is awaited
}

export interface State {
  navigation: SidenavState,   // menu or for chat "conversations"
  details: SidenavState       // details for the main content or the currenty selected item
}

export let initialLayoutState: State = {
  navigation: {
    open: false,
    animation: false,
    mode: SidenavMode.SIDE,
    preferredMode: SidenavMode.SIDE,
    contentType: 'menu',
    content: null
  },
  details: {
    open: false,
    animation: false,
    mode: SidenavMode.OVER,
    preferredMode: SidenavMode.SIDE,
    contentType: null,
    content: null
  }
};

export interface LayoutState {
  layout: State;
}

export const reducer = createReducer(initialLayoutState,
  on(openSidenavAction,(state, action) => {
    //console.log("openSidenavAction",state,action);
    if (action.sidenav==Sidenav.NAVIGATION) {
      if (state.navigation.open && (!action.mode || action.mode==state.navigation.mode)) {
        return state;
      } else {
        let open = !!state.navigation.contentType; // why is this so intelligent? open means OPEN! Caller should not forced to coordinate SET_SIDENAV_CONTENT and OPEN_SIDENAV actions
        return {
          details: state.details,
          navigation: {
            ...state.navigation,
            open: open,
            animation: state.navigation.animation || open!=state.navigation.open,
            mode: action.mode || state.navigation.mode
          }
        }
      }
    } else {
      if (state.details.open && (!action.mode || action.mode==state.details.mode)) {
        return state;
      } else {
        let open = !!state.details.contentType; // why is this so intelligent? open means OPEN! Caller should not forced to coordinate SET_SIDENAV_CONTENT and OPEN_SIDENAV actions
        return {
          navigation: state.navigation,
          details: {
            ...state.details,
            open: open,
            animation: state.details.animation || open!=state.details.open,
            mode: action.mode || state.details.mode
          }
        }
      }
    }
  }),
  on(updateSidenavAction,(state, action) => {
    //console.log("updateSidenavAction",state,action);
    let open = action.state.open && !!action.state.contentType;
    if (action.sidenav==Sidenav.NAVIGATION) {
      if (!action.state || isEqual(state.navigation,action.state)) {
        return state;
      } else {
        return {
          ...state,
          navigation: {
            ...action.state,
            open: open,
            animation: state.navigation.animation || open!=state.navigation.open
          }
        }
      }
    } else {
      if (!action.state || isEqual(state.details,action.state)) {
        return state;
      } else {
        return {
          ...state,
          details: {
            ...action.state,
            open: open,
            animation: state.details.animation || open!=state.details.open
          }
        }
      }
    }
  }),
  on(closeSidenavAction,(state, action) => {
    //console.log("closeSidenavAction",state,action);
    if (action.sidenav==Sidenav.NAVIGATION) {
      let close = action.predicate ? action.predicate(state.navigation) : true;
      if (!state.navigation.open || !close) {
        return state;
      } else {
        return {
          details: state.details,
          navigation: {
            ...state.navigation,
            open: false,
            animation: true
          }
        }
      }
    } else {
      let close = action.predicate ? action.predicate(state.details) : true;
      if (!state.details.open || !close) {
        return state;
      } else {
        return {
          navigation: state.navigation,
          details: {
            ...state.details,
            open: false,
            animation: true
          }
        }
      }
    }
  }),
  on(setSidenavContentAction,(state, action) => {
    //console.log("setSidenavContentAction",state,action);
    if (action.sidenav==Sidenav.NAVIGATION) {
      return {
        details: state.details,
        navigation: {
          ...state.navigation,
          contentType: action.contentType,
          content: action.content
        }
      }
    } else {
      return {
        navigation: state.navigation,
        details: {
          ...state.details,
          contentType: action.contentType,
          content: action.content
        }
      }
    }
  }),
  on(setSidenavModeAction,(state, action) => {
    //console.log("setSidenavModeAction",state,action);
    if (action.sidenav==Sidenav.NAVIGATION) {
      if ((!action.mode || action.mode==state.navigation.mode) &&
        (!action.preferredMode || action.preferredMode==state.navigation.preferredMode)) {
        return state;
      } else {
        return {
          details: state.details,
          navigation: {
            ...state.navigation,
            mode: action.mode || state.navigation.mode,
            preferredMode: action.preferredMode || state.navigation.preferredMode
          }
        }
      }
    } else {
      if ((!action.mode || action.mode==state.details.mode) &&
        (!action.preferredMode || action.preferredMode==state.details.preferredMode)) {
        return state;
      } else {
        return {
          navigation: state.navigation,
          details: {
            ...state.details,
            mode: action.mode || state.details.mode,
            preferredMode: action.preferredMode || state.details.preferredMode
          }
        }
      }
    }
  }),
  on(clearAnimationAction,(state, action) => {
    //console.log("clearAnimationAction",state,action);
    if (action.sidenav==Sidenav.NAVIGATION) {
      return !state.navigation.animation ? state : {
        details: state.details,
        navigation: {
          ...state.navigation,
          animation: false
        }
      };
    } else {
      return !state.details.animation ? state : {
        navigation: state.navigation,
        details: {
          ...state.details,
          animation: false
        }
      };
    }
  })
);

export const selectLayoutState = createFeatureSelector<LayoutState, State>('layout');

export const selectNavigationState = createSelector(
  selectLayoutState,
  (state):SidenavState => state.navigation
);

export const selectDetailsState = createSelector(
  selectLayoutState,
  (state):SidenavState => state.details
);
