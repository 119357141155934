import {InvitationToken} from "./invitation-token";
import {TokenCode} from "../util/token-code";
import {CRC16} from "../util/crc16";

export class InvitationTokenImpl extends InvitationToken {
  protected _code : string = null;
  protected _time : number = 0;
  protected _user : number = 0;
  protected _info : number = 0;
  protected _app  : number = 0;
  protected _expiring : boolean = true;
  //protected _email : boolean = false;
  //protected _sms : boolean = false;
  //protected _whatsapp : boolean = false;

  public static MASK_9CHAR  = 0x0_1fff_ffff_ffff; 		// 9x5 = 45 bit = 11x4 hex char + 1 bit
  public static MASK_6CHAR  = 0x0_3fff_ffff; 	// 6x5 = 30 bit = 7x4 char + 2 bit
  public static MASK_12CHAR = 0x0fff_ffff_ffff_ffff; 	// 12x5 = 60 bit = 15x4 char
  public static MASK_4CHAR  = 0x0f_ffff; 				// 4x5 = 20 bit = 5x4 hex char
  public static MASK_MILLIS = 0x07ff;					// 1024
  public static MASK_9CHAR_NO_MILLIS = 0x0_1fff_ffff_f800;
  public static MASK_28BIT  = 0x0fff_ffff;			// for user
  public static MASK_32BIT  = 0x0_ffff_ffff;			// for hash
  public static MUL_11BIT   = 0x0800; // 2^11
  public static MASK_10BIT  = 0x0_03ff;				// for app
  public static MASK_20BIT  = 0x0f_ffff;				// for data

  public get code() : string {
    if (!this._code) {
      this._code = this.toToken();
    }
    return this._code;
  }

  public get formatted() : string {
    let code   = this.code;
    let result = code.substring(0,5);
    for (let i=1; i<5; i++) {
      result = result + '-' + code.substring(i*5,i*5+5);
    }
    return result;
  }

  public get time() : number {
    return this._time;
  }
  public get user() : number {
    return this._user;
  }
  public get app() : number {
    return this._app;
  }
  public get info() : number {
    return this._info;
  }
  public get expiring() : boolean {
    return this._expiring;
  }
  /*
  public get email() : boolean {
    return this._email;
  }
  public get sms() : boolean {
    return this._sms;
  }
  public get whatsapp() : boolean {
    return this._whatsapp;
  }*/

  protected pad(s:string,length:number,padCharCode:number) : string {
    while (s.length<length) {
      s = String.fromCharCode(padCharCode)+s;
    }
    return s;
  }

  protected toToken() : string {
    let zero  = TokenCode.default.charOfDigit(0);
    let xdata = (((this._info & InvitationTokenImpl.MASK_20BIT)<<10) + (this._app & InvitationTokenImpl.MASK_10BIT)) & InvitationTokenImpl.MASK_6CHAR;
    let xuser = this._user & InvitationTokenImpl.MASK_6CHAR;
    let time  = this.pad(TokenCode.default.toTokenString(this._time),9,zero);
    let user  = this.pad(TokenCode.default.toTokenString(xuser),6,zero);
    let data  = this.pad(TokenCode.default.toTokenString(xdata),6,zero);
    let crc16 = CRC16.fromString(time+data+user);
    //console.debug("time+hash+user: "+time+'_'+hash+'_'+user+" crc:"+crc16+" hash:"+this._hash.toString(2)+" xhash:"+xhash.toString(2));
    let millis = Math.max(this._time & InvitationTokenImpl.MASK_MILLIS, 0x1);
//		let mask = ((((((((millis & 0x01ff)<<11)+millis)<<11)+millis)<<11)+millis)<<11)+millis;
    let MUL11 = InvitationTokenImpl.MUL_11BIT;
    let mask  = ((((((((millis & 0x01ff)*MUL11)+millis)*MUL11)+millis)*MUL11)+millis)*MUL11)+millis;
    //console.debug("mask: "+mask.toString(2));
    let xtime = this._time % InvitationTokenImpl.MUL_11BIT;
    //console.debug("time.0: "+xtime.toString(2));
    for (let i=0, t=this._time, m=mask, x=1, n=InvitationTokenImpl.MASK_9CHAR; i<4; i++) {
      t = Math.floor(t/InvitationTokenImpl.MUL_11BIT);
      m = Math.floor(m/InvitationTokenImpl.MUL_11BIT);
      n = Math.floor(n/InvitationTokenImpl.MUL_11BIT);
      x = x*InvitationTokenImpl.MUL_11BIT;
      let v = ((t%InvitationTokenImpl.MUL_11BIT) ^ (m%InvitationTokenImpl.MUL_11BIT)) & (n%InvitationTokenImpl.MUL_11BIT);
      xtime += x*v;
      //console.debug("time."+(i+1)+": "+v.toString(2)+" "+xtime.toString(2));
    }
    time  = this.pad(TokenCode.default.toTokenString(xtime),9,zero);

    user  = this.pad(TokenCode.default.toTokenString((xuser^mask) & InvitationTokenImpl.MASK_6CHAR),6,zero);
    data  = this.pad(TokenCode.default.toTokenString((xdata^(mask>>1)) & InvitationTokenImpl.MASK_6CHAR),6,zero);
    let checksum = this.pad(TokenCode.default.toTokenString(
      ((((((this.expiring ? 0x8 : 0) /*+
        (this.email ? 0x4 : 0) +
        (this.sms ? 0x2 : 0) +
        (this.whatsapp ? 0x1 : 0)*/) << 16) + crc16)^mask) & InvitationTokenImpl.MASK_4CHAR)), 4, zero);
    // console.debug("time+hash+user: "+time+'_'+hash+'_'+user+" checksum:"+checksum);

    let code =
      user[4]+	// 0
      data[2]+	// 1
      time[4]+	// 2
      user[5]+	// 3
      data[0]+	// 4
      checksum[3]+// 5
      data[1]+	// 6
      time[8]+	// 7
      user[3]+	// 8
      checksum[1]+// 9
      time[5]+	// 10
      user[0]+	// 11
      time[6]+	// 12
      checksum[2]+// 13
      data[4]+	// 14
      user[1]+	// 15
      data[3]+	// 16
      time[2]+	// 17
      user[2]+	// 18
      time[0]+	// 19
      checksum[0]+// 20
      time[7]+	// 21
      time[1]+	// 22
      data[5]+	// 23
      time[3];	// 24
    return code;
  }

  protected fromToken(token : string) : void {
  }

  public createWithToken(token:string) : InvitationToken {
    let result = new InvitationTokenImpl();
    result.fromToken(token);
    return result;
  }

  public create(time:number,user:number,info:number,app:number,expiring:boolean/*,email:boolean,sms:boolean,whatsapp:boolean*/) : InvitationToken {
    let result = new InvitationTokenImpl();
    result._time = time;
    result._user = user;
    result._info = info;
    result._app  = app;
    result._expiring = expiring;
    //result._email = email;
    //result._sms = sms;
    //result._whatsapp = whatsapp;
    return result;
  }

  /*
  public create(time:number,user:number,info:number,app:number,expiring:boolean,email:boolean,sms:boolean,whatsapp:boolean) : InvitationToken {
    let result = new InvitationTokenImpl();
    result._time = time;
    result._user = user;
    result._info = info;
    result._app  = app;
    result._expiring = expiring;
    result._email = email;
    result._sms = sms;
    result._whatsapp = whatsapp;
    return result;
  }*/
}

