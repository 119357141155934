import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {APP_ID, EMPTY_ARRAY, ENVIRONMENT, FilterTypes, Logger, Topic} from "core";
import {LayoutService} from "layout"
import {PropertiesService} from "properties";
import {AccordionSectionDefinition, BasicContainerComponent, MenuService} from "shared";
import {firstValueFrom, Observable} from "rxjs";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import isEqual from "lodash/isEqual";
import {AccordionFilterSectionContext, FilterComponentFactoryService, FilterService} from "filter";
import {MediaService} from "../../service/media.service";
import {MediaTopicsService} from '../../service/media-topics.service';
import {Router} from "@angular/router";

export interface AccordionFilterSectionDefinition extends AccordionSectionDefinition {
  context: AccordionFilterSectionContext
}

/**
 * MediaFilterContainerComponent
 *
 * should hold a FilterSectionContainer (component)
 * which holds FilterSectionComponent(s) (interfaces ... implemented by components).
 *
 * FilterSection is an interface to access the FilterSectionFactory.
 * FilterSection is obtained from a registry by *type*.
 * for all the possible filters with their type, there are FilterSections requested
 * and FilterSectionComponent(s) are created and initialized with the filters
 * defined in domain....
 */
@Component({
  selector: 'app-media-filter-container',
  templateUrl: './media-filter-container.component.html',
  styleUrls: ['./media-filter-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaFilterContainerComponent extends BasicContainerComponent {

  sections: AccordionFilterSectionDefinition[] = [];
  filters: Topic[] = [];
  filterCount = 0;

  protected _context: any = {};
  protected _combinedFilters: {[type:string]:Observable<string[]>} = {};

  protected logger = new Logger('MediaFilterContainerComponent');

  constructor(public layoutService: LayoutService,
              public menuService: MenuService,
              public mediaService: MediaService,
              public filterService: FilterService,
              public translateService: TranslateService,
              public propertiesService: PropertiesService,
              public changeDetectorRef: ChangeDetectorRef,
              public filterComponentFactoryService: FilterComponentFactoryService,
              protected mediaTopicsService: MediaTopicsService,
              protected router: Router,
              @Inject(ENVIRONMENT) protected environment: any,
              @Inject(APP_ID) protected appId: number) {
    super();
    this.logger.debug("ctor");
  }

  ngOnInit() {
    // navigationFilters.clear()
    this.mediaService.getCombinedFilters$((type,filters)=>type==FilterTypes.NAVIGATION)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(filters=>{
        const filterCount = filters?.length ?? 0;
        if (this.filterCount!=filterCount) {
          this.filterCount = filterCount;
          this.changeDetectorRef.markForCheck();
        }
        console.log("SELECTION.MFCC",this.menuService.menuSelection,"FILTERS",filters,"COUNT",this.filterCount);
      });
    this.menuService.menuSelection$
      .pipe(
        takeUntil(this.onDestroy$),
        distinctUntilChanged((a,b)=>isEqual(a,b))
      ).subscribe(selection=>{
        if (!!selection?.section && selection.hasFilters() && this.propertiesService.user?.app?.filters?.length>0) {
          //this.logger.debug("SELECTION",selection);
          //this.topicFilterIds = [];
          const view = selection.filters.find(f=>f.startsWith('view:'))?.substr('view:'.length);
          const path = `${selection.section}.${selection.filters[0]}`;
          firstValueFrom(this.filterService.getFilterTopics$(path, view,
            (filter,root,level,path,view) => {
              //this.logger.debug("FILTER",filter,"root",root,"level",level,"path",path,"view",view);
              if (filter.tags?.includes('media')) {
                //this.logger.debug("filter",filter,"level",level,"path",path,"alternatives",alternatives);
                const views = Object.keys(filter.properties?.paths?.[path]?.views || {});
                if (views.length==0 || (!!view && views.includes(view))) {
                  if (level >= 1) {
                    //this.topicFilterIds.push(filter.id);
                  }
                  return true;
                }
              }
              return false;
            })).then(topics=>{
              this.update(topics);
            });
        } else {
          this.update([]);
        }
      });
  }

  protected getCombinedFilters$(sectionType:string,caller?:string):Observable<string[]> {
    let combinedFilters$ = this._combinedFilters[sectionType];
    if (!combinedFilters$) {
      combinedFilters$ = this.mediaService.getCombinedFilters$((type,filters)=>type!=sectionType,caller);
      this._combinedFilters[sectionType] = combinedFilters$;
    }
    return combinedFilters$;
  }

  protected update(topics:Topic[]) {
    //console.log("UPDATE2",topics,this.router.url);
    //this.logger.trace("CONTEXT:TOPICS",topics);
    const rendered:{[key:string]:AccordionFilterSectionDefinition} = {};
    const sections:AccordionFilterSectionDefinition[] = [];
    const done:{[key:number]:Topic} = {};
    const TAGS_ID = 'tags';
    topics?.forEach((value,index)=>{
      if (!done[index]) {
        const sectionType = value.type ?? TAGS_ID;
        done[index] = value;
        const context:AccordionFilterSectionContext = {
          topics: [ value ],
          selectedFilters$: this.getCombinedFilters$(sectionType,"MediaFilterContainerComponent:"+sectionType),
          updateFilters: (filters)=>this.mediaService.setTypedFilters({[sectionType]:filters})
        };
        if (!this.filterComponentFactoryService.getViewerFactory(sectionType, context)?.isSingleTopicView()) {
          for (let i=index+1, max=topics.length; i<max; i++) {
            if (topics[i].type == value.type) {
              done[i] = topics[i];
              context.topics.push(topics[i]);
            }
          }
        }
        const section:AccordionFilterSectionDefinition = {
          factoryService: this.filterComponentFactoryService,
          id: value.id,
          label: !!value.type ? value.label : 'media.filters.tags',
          type: sectionType,
          context
        };
        sections.push(section);
        rendered[section.type] = section;
      }
    });
    //if (!this.environment.production || this.appId==2) {
    /* if (this.appId==2) {
      const topicsId = 'topics';
      if (!rendered[topicsId]) {
        sections.push(rendered[topicsId] = {
          factoryService: this.filterComponentFactoryService,
          id: topicsId,
          type: topicsId,
          label: "groups.topics.label",
          context: {
            topics: this.mediaTopicsService.getTopics(),
            search: true,
            multiselect: true,
            selectedFilters$: this.getCombinedFilters$(topicsId,"MediaFilterContainerComponent:"+topicsId),
            updateFilters: (filters)=>this.mediaService.setTypedFilters({[topicsId]:filters})
          }
        });
      }
      const bibleId = 'bible';
      if (!rendered[bibleId]) {
        sections.push(rendered[bibleId] = {
          factoryService: this.filterComponentFactoryService,
          id: bibleId,
          type: topicsId,
          label: "bible.books.title",
          context: {
            topics: this.mediaTopicsService.getBibleBooks(),
            search: true,
            multiselect: true,
            selectedFilters$: this.getCombinedFilters$(bibleId,"MediaFilterContainerComponent:"+bibleId),
            updateFilters: (filters)=>this.mediaService.setTypedFilters({[bibleId]:filters})
          }
        });
      }
      const authorId = 'authors';
      if (!rendered[authorId]) {
        sections.push(rendered[authorId] = {
          factoryService: this.filterComponentFactoryService,
          id: authorId,
          type: authorId,
          label: "media.authors",
          context: {
            topics: [],
            search: true,
            multiselect: true,
            selectedFilters$: this.getCombinedFilters$(authorId,"MediaFilterContainerComponent:"+authorId),
            updateFilters: (filters)=>this.mediaService.setTypedFilters({[authorId]:filters})
          }
        });
      }
    }
     */
    const countryId = 'country';
    if (!rendered[countryId]) {
      sections.push(rendered[countryId] = {
        factoryService: this.filterComponentFactoryService,
        id: countryId,
        type: countryId,
        label: "media.countries",
        context: {
          topics: [{ id: countryId, type: countryId}],
          selectedFilters$: this.getCombinedFilters$(countryId,"MediaFilterContainerComponent:"+countryId),
          updateFilters: (filters)=>this.mediaService.setTypedFilters({[countryId]:filters})
        }
      });
    }
    const visibilityId = 'visibility';
    if (this.propertiesService.user?.isRoot || this.propertiesService.user?.isAdmin) {
      const settingsMedia = this.router.url=='/settings/media';
      const size = rendered[visibilityId]?.context?.topics?.[0]?.topics?.length ?? 0;
      if (!settingsMedia && size!=2) {
        sections.push(rendered[visibilityId] = {
          factoryService: this.filterComponentFactoryService,
          id: visibilityId,
          type: visibilityId,
          label: "media.filters.visibility",
          context: {
            topics: [{
              id: "downline",
              label: "media.filters.visibility",
              topics: [
                {
                  id: 'downline.excluded',
                  label: 'media.filters.downline.excluded'
                },
                {
                  id: 'downline.undecided',
                  label: 'media.filters.downline.undecided'
                }
              ]
            }],
            selectedFilters$: this.getCombinedFilters$(visibilityId),
            updateFilters: (filters)=>this.mediaService.setTypedFilters({[visibilityId]:filters})
          }
        });
      } else if (settingsMedia && size<=2) {
        sections.push(rendered[visibilityId] = {
          factoryService: this.filterComponentFactoryService,
          id: visibilityId,
          type: visibilityId,
          label: "media.filters.visibility",
          context: {
            topics: [{
              id: "downline",
              label: "media.filters.visibility",
              topics: [
                {
                  id: 'downline.excluded',
                  label: 'media.filters.downline.excluded'
                },
                {
                  id: 'downline.undecided',
                  label: 'media.filters.downline.undecided'
                },
                {
                  id: 'mine',
                  label: 'media.filters.mine'
                },
                {
                  id: 'pending',
                  label: 'media.filters.pending'
                },
                {
                  id: 'approved',
                  label: 'media.filters.approved'
                },
                {
                  id: 'declined',
                  label: 'media.filters.declined'
                }
              ]
            }],
            selectedFilters$: this.getCombinedFilters$(visibilityId),
            updateFilters: (filters)=>this.mediaService.setTypedFilters({[visibilityId]:filters})
          }
        });
      }

      /*
        visibilityFilter: Topic = {
id: 'visibility',
label: 'media.filters.visibility',
topics: [
  {
    id: 'mine',
    label: 'media.filters.mine'
  },
  {
    id: 'pending',
    label: 'media.filters.pending'
  },
  {
    id: 'approved',
    label: 'media.filters.approved'
  },
  {
    id: 'declined',
    label: 'media.filters.declined'
  }
]
};


       */


    }
    this.sections.filter(section=>!rendered[section.type]).forEach(section=>{
      section.context?.updateFilters(EMPTY_ARRAY);
    });
    this.sections = sections;
    console.log("MEDIA_FC.UPDATE",topics,"\nsections",this.sections);
    this.changeDetectorRef.markForCheck();
    this.logger.debug('update()', { section: this.sections });
  }

  set context(context: any) {
    //this.logger.trace("SET.CONTEXT", context);
    //this.logger.debug("CONTEXT",context);
    context = context || {};
    if (!isEqual(context, this.context)) {
      this._context = context;
    }
  }

  get context(): any {
    return this._context;
  }

  /*
  onFilter(event: FilterChangeEvent) {
    this.logger.debug('onFilter', { event });
    const topicId = event.filter?.id;
    const topicFilters = topicId ?
      this.selectedFilterIds.filter(f => f && f!=topicId && !f.startsWith(topicId+'.'))
      : { ...this.selectedFilterIds };
    if (event.selectedFilterIds?.length) {
      topicFilters.splice(topicFilters.length, 0, ...event.selectedFilterIds);
    }
    if (!isEqual(this.selectedFilterIds, topicFilters)) {
      this.filterService.setFilterValues$(topicFilters, topicId).then(filterIds => {
        this.selectedFilterIds = filterIds;
        this.onFilterChange();
      })
    }
    // if (event.selectedFilterIds.length > 0) {
    //   event.selectedFilterIds.forEach(id => {
    //     this.selectedFilterIds = this.filterService.setFilter(this.selectedFilterIds,
    //                             id,
    //                             !event.exclusive,
    //                             event.filter?.id);
    //   });
    // }
  }

  onFilterChange() {
    typeof this._context.onFilterChange=='function' &&
           this._context.onFilterChange(this.selectedFilterIds);
  }*/

  clearFilter() {
    this.mediaService.setTypedFilters({},(type,filters)=>type!=FilterTypes.NAVIGATION);
  }
}
