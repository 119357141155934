<h1 mat-dialog-title  align="center" translate>{{title}}</h1>
<div mat-dialog-content>
  <div class="message">
    <p [innerHTML]="message | safeHtml"></p>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="'ok'" cdkFocusInitial>
    <span translate>actions.ok</span>
  </button>
</div>
