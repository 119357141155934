import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {ymdToDate} from "core";

@Injectable({providedIn: "root"})
@Pipe({name: 'ymdFormat'})
export class YearMonthDayFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const date = ymdToDate(value);
    return date && `${date.getFullYear()}.${date.getMonth()+1}.${date.getDate()}` || '';
  }
}
