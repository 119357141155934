import {Component, ContentChild, Input, TemplateRef, ViewChild, Optional} from '@angular/core';
import {LayoutService} from "layout";
import {ToolbarComponent} from "../toolbar/toolbar.component";
import {MenuService} from "../menu/menu.service";
import {BasicContainerComponent} from "../basic-container/basic-container.component";
import {delay, map, mergeMap, takeUntil} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject, of} from "rxjs";


@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent extends BasicContainerComponent {

  @Input() hasToolbar = true;
  @Input() leftButtonAction:  () => any = () => { this.layoutService.navigation.toggle(); };
  @Input() rightButtonAction: () => any = () => { this.layoutService.details.toggle();    };
  @ViewChild(ToolbarComponent) toolbar: ToolbarComponent;
  @ContentChild('primaryButtonTemplate') primaryButtonTemplate: TemplateRef<any>;
  @ContentChild('pageContentTemplate') pageContentTemplate: TemplateRef<any>;

  protected title$ = new BehaviorSubject(null);
  protected section$ = new BehaviorSubject<string>(null);

  constructor(public layoutService: LayoutService,
              @Optional() protected menuService: MenuService,
              protected translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (!this.title$.getValue()) {
      this.menuService.breadcrumb$.pipe(
        takeUntil(this.onDestroy$),
        // distinctUntilChanged(),
        mergeMap((breadcrumb) => {
          //console.log("BREADCRUMB",breadcrumb,"FILTERSECTIONS",this.menuService.visibleFilterSections);
          if (breadcrumb.length > 0) {
            return this.translateService.stream(breadcrumb)
              .pipe(map((labels: any) => {
                let section = labels[breadcrumb[0]];
                let title   = section;
                if (breadcrumb.length > 1) {
                  // title += ' <span class="separator">&#9654;</span> '; U+25B6
                  section = `${labels[breadcrumb[1]]}`;
                  title  += ' <span class="separator">&#9658;</span> '; // U+25BA
                  title  += section;
                  /*
                  title += ' <span class="separator">&#9654;</span> ';
                  let separator = '';
                  for (let i = 1, max = breadcrumb.length; i < max; i++) {
                    title += `${separator}${labels[breadcrumb[i]]}`;
                    separator = ', ';
                  }
                  */
                }
                this.section = section;
                return title;
              }))
          } else {
            this.section = '';
            return of('');
          }
        }),
        delay(0)
      ).subscribe(title => {
        this.title = title;
      })
    }
  }

  @Input()
  set module(name: string) {
    // this.title$ = this.translateService.stream(`module.${name}`);
  }

  @Input()
  set title(title: string) {
    this.title$.next(title);
  }

  get title(): string {
    return this.title$.getValue();
  }

  @Input()
  set section(section: string) {
    if (section!=this.section) {
      this.section$.next(section);
    }
  }

  get section(): string {
    return this.section$.getValue();
  }
}
