import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatMenuTrigger} from "@angular/material/menu";
import {I18nService} from "core";
import {animate, style, transition, trigger} from "@angular/animations";

export class MenuSelector {
  id:string;
  icon?:string;
  label:string;
  params?:{};
}

@Component({
  selector: 'menu-selector',
  templateUrl: './menu-selector.component.html',
  styleUrls: ['./menu-selector.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('300ms', style({opacity: 0}))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSelectorComponent implements OnInit, OnChanges {

  @Input() tabIndex: number | null;
  @Input() disableRipple: boolean;
  @Input() selectors: MenuSelector[] = [];
  @Output() selected = new EventEmitter<MenuSelector>();

  @ViewChild('button') button: MatButton;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(protected i18nService: I18nService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.debug("selectors",this.selectors,"changes",changes);
  }

  @Input()
  get disabled(): boolean {
    if (!this.selectors || this.selectors.length==0) {
      return true;
    }
    //if (this._disabled === undefined && this.datepicker) {
    //  return this.datepicker.disabled;
    //}
    return !!this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }
  private _disabled: boolean;

  open(event: Event): void {
    if (!this.disabled) {
      event.stopPropagation();
      if (!this.trigger.menuOpen) {
        this.button.disabled = true;
        this.trigger.openMenu();
      }
    }
  }

  getSelectorLabel(selector:MenuSelector) : string {
    return 'contact.'+selector.id;
  }

  select(event: Event, selector:MenuSelector): void {
    //console.log("event",event,"selector",selector);
    //event.stopPropagation();
    this.selected.emit(selector);
  }
}
