<app-language-list [languages$]="languages$"
                   [lru]="lru$ | async"
                   (selectionChange)="onSelectionChange($event)">
  <ng-template #controlsTemplate let-index="index" let-language="language" let-isLru="isLru">
    <button tabindex="-1" *ngIf="isLru && language.code!=currentLanguage"
            mat-icon-button
            (click)="onRemoveLru(index, language, $event)"
            class="lru-remove">
      <mat-icon>clear</mat-icon>
    </button>
  </ng-template>
</app-language-list>
