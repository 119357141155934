import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PropertiesService} from "properties";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBoxComponent implements OnInit, OnDestroy {

  message: string;
  title: string;
  protected subscription: Subscription;

  constructor(public dialogRef: MatDialogRef<MessageBoxComponent>,
              protected propertiesService: PropertiesService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              protected changeDetector: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.message = this.data?.message;
    this.title = this.data?.title;
    if (!this.title) {
      this.subscription = this.propertiesService.properties$.subscribe(properties => {
        const name = properties.group?.name ?? 'app.name';
        if (this.title!=name) {
          this.title = name;
          this.changeDetector.detectChanges();
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
