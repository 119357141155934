<div *ngIf="filters?.length>0" class="filters">
  <ng-container *ngFor="let filter of (filters??[])">
    <filter-tag-section [filter]="filter"
                        [selected]="selected"
                        [markEditable]="false"
                        (onFilter)="onFilter.emit($event)"
                        class="section">
    </filter-tag-section>
  </ng-container>
</div>
