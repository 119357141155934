import {ChangeDetectorRef, Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {User} from 'core';
import has from 'lodash/has';
import {MediaReviewState} from '../../store/models';
import {MediaDetailsShareDataService} from '../../service/media-details-share-data.service';
import {PropertiesService} from 'properties';
import {combineLatest, Subject, takeUntil} from 'rxjs';
import {FormChangeDetector} from 'shared';
import {MediaDetailsSectionComponent} from '../media-details/media-details-section.component';

@Component({
  selector: 'media-details-review',
  templateUrl: './media-details-review.component.html',
  styleUrls: ['./media-details-review.component.scss']
})
export class MediaDetailsReviewComponent extends MediaDetailsSectionComponent {

  editDownlineVisibility: boolean = false;
  reviewForm: FormGroup;
  formChangeReset: Subject<void>;
  private _user: User;

  constructor(protected formBuilder: FormBuilder,
    private formChangeDetector: FormChangeDetector,
    protected mediaDetailsShareDataService: MediaDetailsShareDataService,
    public changeDetector: ChangeDetectorRef,
    private propertiesService: PropertiesService) {
    super(mediaDetailsShareDataService);
    this.propertiesService.user$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user: User) => {
        this._user = user;
      });
  }

  ngOnInit(): void {
    combineLatest([
      this.mediaDetailsShareDataService.getEditMode$,
      this.mediaDetailsShareDataService.getMedia$,
      this.mediaDetailsShareDataService.getReviewForm$,
      this.mediaDetailsShareDataService.getOptions$
    ])
      .pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(
        ([editMode, media, reviewForm, options]) => {
          this.editDownlineVisibility = !!options.editDownlineVisibility;
          const isVerifiedMember = this._user.isVerifiedMember || this._user.isAdmin;

          let hidden = editMode || !media || !media.author || !!media.readOnly || !media.published || !isVerifiedMember;
          if(!hidden) {
            hidden = !(this.isApprover || this.isMaster || this.editDownlineVisibility);
          }
          this.isHidden.emit(hidden);
          this.reviewForm = reviewForm;

          const handlePristine = (form: FormGroup, pristine: boolean, externalState?: boolean) => {
            //this.logger.debug('handlePristine', { form, pristine, externalState });
            // if (!pristine && !this.dirtyForms.includes(form)) {
            //   this.dirtyForms.push(form);
            // } else if (pristine && this.dirtyForms.includes(form)) {
            //   this.dirtyForms = this.dirtyForms.filter((f) => f != form);
            // }
            if (pristine && !externalState && !form.pristine) { form.markAsPristine(); }
            else if ((!pristine || externalState) && form.pristine) { form.markAsDirty(); }
            // In theory detectChanges() call should not be needed
            // but in some cases e.g. when changing downline visibility, approve/reject status,
            // priority, published flag, etc. view and backing state can go out of sync
            // If the mouse cursor is slightly moved the changes are reflected.
            // Initially this issue was not present but started to appear maybe after angular or material lib upgrade
            this.changeDetector.detectChanges();
          };

          const detectorOptions = { resetter: this.formChangeReset };

          const reviewFormChangeSubscription = this.formChangeDetector.detect(this.reviewForm, detectorOptions)
            .subscribe((pristine: boolean) => {
              handlePristine(this.reviewForm, pristine);
            });
        });
  }

  get isEditor(): boolean {
    return this.media.editable;
  }

  get isApprover(): boolean {
    return this.media.approvable;
  }

  get isMaster(): boolean {
    return this.isEditor && this.isApprover;
  }

  onSaveReview() {
    const previous = { ...this.media };
    const approved = this.reviewForm.get('approved').value;
    const declined = this.reviewForm.get('declined').value;
    const reason = this.reviewForm.get('reason').value;
    const state = approved ? MediaReviewState.Approved : declined ? MediaReviewState.Declined : MediaReviewState.Pending;
    if (state !== MediaReviewState.Pending || previous.review) { // do not save pending state if media does have a review yet
      const stateInfo = previous.review || this.stateInfo;
      const review: any = { ...stateInfo, state: state };
      if (declined) {
        review.reason = reason;
      } else if (has(previous, 'review.reason')) {
        review.reason = previous.review.reason;
      }
      this.media.review = review;
    }
    this.media.downline_include = this.reviewForm.get('downlineInclude').value;
    // form reset is needed in order to start a new form change detection cycle after save
    // if the internal form change detector state is not re-initialized the form dirty state (and therefore the save button visibility)
    // will continue to be resolved based on the original form value.
    // TODO: use separate form change detection resetter for each form
    // this.resetForm(this.reviewForm);

    // this.formChangeReset.next(); // TODO check if needed here
    this.mediaDetailsShareDataService.setMediaUpdate({ source: 'review', mediaUpdate: [this.media, previous] });
  }

  get stateInfo() {
    return { contact: { id: this._user.id, name: this._user.name }, time: Date.now() }
  }

}
