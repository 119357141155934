import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {Logger} from '../logger.service';
import {ENVIRONMENT} from "../config";

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(@Inject(ENVIRONMENT) private environment: any) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry(2), // try 2 times before giving up
      catchError(error => this.errorHandler(request, error))
    );
  }

  private errorHandler(request: HttpRequest<any>, error: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!this.environment.production) {
      log.error(`Request url: ${request.urlWithParams}. Error: ${JSON.stringify(error)}`);
    }
    throw error;
  }
}
