<h1 mat-dialog-title align="center">{{'login.qrCode.scanToLogin' | translate:data}}</h1>
<div mat-dialog-content>
  <qr-code-all  #qrCode
                [qrCodeValue]="data.token | async"
                qrCodeECLevel="L"
                [qrCodeColorDark]="qrCodeColorDark"
                [qrCodeColorLight]="qrCodeColorLight"
                [width]="200"
                [margin]="0"
                [scale]="4"
                [scanQrCode]="false">
  </qr-code-all>
  <div class="step">
    <div>
      <mat-icon>smartphone</mat-icon>
    </div>
    <div>
      <span [innerHTML]="'login.qrCode.appInstall' | translate:data | safeHtml"></span>
      <span [innerHTML]="'login.qrCode.appInstallDetails' | translate:data | safeHtml"></span>
    </div>
  </div>
  <div class="step">
    <div>
      <mat-icon>login</mat-icon>
    </div>
    <div>
      <span [innerHTML]="'login.qrCode.scan' | translate:data | safeHtml"></span>
      <span [innerHTML]="'login.qrCode.scanDetails' | translate:data | safeHtml"></span>
    </div>
  </div>
</div>
<button mat-icon-button mat-dialog-close aria-label="close" tabIndex="-1">
  <mat-icon>close</mat-icon>
</button>
