import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {DateAdapter} from "@angular/material/core";
import moment from "moment";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {Logger} from "core";

@Pipe({
  name: 'birthday',
  pure: false // the pipe should be invoked on each change-detection cycle, even if the arguments have not changed
})
export class BirthdayPipe implements PipeTransform, OnDestroy {

  protected static regexTrim = new RegExp("^[,. ]+|[,. ]+$","g");
  protected static regexTrimNumber = new RegExp("^[ 0]+","g");

  protected birthday: string;
  protected lastDate: string;
  protected lastFormat: string;

  protected onLangChange        : Subscription;
  protected onDefaultLangChange : Subscription;

  protected logger = new Logger('BirthdayPipe');

  constructor(protected dateAdapter: DateAdapter<moment.Moment>,
              protected translateService: TranslateService,
              protected changeDetector: ChangeDetectorRef
  ) {}

  transform(date: any, format = 'LL'): any {
    // this.logger.debug(date, format);
    if (date!=this.lastDate || format!=this.lastFormat) {
      this.lastDate   = date;
      this.lastFormat = format;
      this.updateBirthday(date, format);
      this.unsubscribe();
      if (!this.onLangChange) {
        this.onLangChange = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
          if (this.lastDate) {
            this.lastDate = null;
            this.updateBirthday(date, format);
          }
        });
      }
      if (!this.onDefaultLangChange) {
        this.onDefaultLangChange = this.translateService.onDefaultLangChange.subscribe(() => {
          if (this.lastDate) {
            this.lastDate = null;
            this.updateBirthday(date, format);
          }
        });
      }
    }
    return this.birthday;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  protected updateBirthday(date: any, format: string) {
    let birthday;
    try {
      let value = Number(date.replace(BirthdayPipe.regexTrimNumber,''));
      // this.logger.debug(date,format,value);
      const day   = value%100;
      const month = ~~(value/100) % 100;
      const year  = ~~(value/10000);
      // this.logger.debug(date,format,"day",day,"month",month,"year",year);
      if (day>0 && day<=31 && month>0 && month<=12 && year<=9999) {
        const ymdMoment = moment(year<=1000 ? ((value%10000)+10000000).toString() : value.toString());
        birthday  = this.dateAdapter.format(ymdMoment, format);
        if (ymdMoment.year()<=1000) {
          let year = this.dateAdapter.getYearName(ymdMoment);
          birthday = birthday.replace(year,'');
          birthday = birthday.replace(BirthdayPipe.regexTrim,'');
        }
      }
    } catch (e) {
      this.logger.error(e);
    }
    this.birthday = birthday || date;
    this.lastDate = date;
    window.setTimeout(() => {
      this.changeDetector.markForCheck();
    });
  }

  protected unsubscribe() {
    if (this.onLangChange) {
      this.onLangChange.unsubscribe();
      this.onLangChange = undefined;
    }
    if (this.onLangChange) {
      this.onDefaultLangChange.unsubscribe();
      this.onDefaultLangChange = undefined;
    }
  }
}
