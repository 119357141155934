import { ChangeDetectorRef, Component } from '@angular/core';
import { FilterService } from "../../filter.service";
import { BasicFilterComponent } from "../core/basic-filter.component";
import { Observable, of } from 'rxjs';
import { Topic } from 'core';

interface FilterOption {
  id: string,
  name: string,
  children?: {}
}

interface BreadcrumbItem {
  id: string,
  name: string
}

@Component({
  selector: 'hierarchical-filter',
  templateUrl: './hierarchical-filter.component.html',
  styleUrls: ['./hierarchical-filter.component.scss']
})
export class HierarchicalFilterComponent extends BasicFilterComponent {

  previousSelection: Topic[];
  step = 0;
  options: Observable<Topic[]>;
  selectionElements: any[];
  startGap: number;

  // home: MenuItem;
  items: BreadcrumbItem[] = [];
  largeEntries = true;

  cacheId$: Observable<string>;

  levels: { id: string, name: string, large: boolean }[] = [{
    id: 'book',
    name: 'Buch',
    large: false
  }, {
    id: 'chapter',
    name: 'Kapitel',
    large: true
  }, {
    id: 'verse',
    name: 'Vers',
    large: true
  }];
  selection: any[] = [];
  // rootOptions: Topic[];

  constructor(
    protected filterService: FilterService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(filterService, changeDetectorRef);
    this.cacheId$ = of('cid_' + Date.now() + '_' + Math.floor((Math.random() * 1000) + 1));
  }

  // public get filter(): Topic {
  //   return this._filter;
  // }

  // @Input()
  protected onContextUpdate() {
    // super.filter = filter;

    this.items = [
      // { label: 'Auswählen'}
      // { label: 'Buch' },
      // { label: 'Kapitel' },
      // { label: 'Vers' }
    ];

    this.refreshSelectionList(null);
    // this.options = this._filter.options;
  }

  getLevelName(index: number) {
    return this.levels[index].name;
  }

  resetFilter() {
    super.resetFilters();
    this.step = 0;
    this.previousSelection = [];
    this.refreshSelectionList(null);
    this.items = [];
    this.setStartGap();
  }

  setStartGap() {
    // this.startGap = this.items.length === 0 ? 40 : 100;
  }

  getGridStyle() {
    if (this.items.length === 0) {
      return '40px';
    }
    return '100px';
  }

  getHeight() {
    return this.height; //'calc(100vh - 56px - 36px - ' + this._sectionCount + ' * 49px)';
  }

  onItemClick(item) {
    this.step = this.items.indexOf(item);
    this.items.splice(this.step);
    // this._filter.selection = [];
    this.items.forEach((item, index) => {
      // this._filter.selection[0][this._filter.levels[index].id] = item.id;
    });
    if (this.step === 0) {
      // this._filter.selection = [];
      // this.options = this._filter.options;
      this.refreshSelectionList(null);
    } else {
      const selectedItem = this.selection[this.step - 1];
      this.refreshSelectionList(selectedItem);
    }
  }

  onListboxClick(selectedOption) {
    // const selectedOption = this.options.pipe(map(movies => movies.find(movie => movie.id == selectedId)));
    // (o => o.id === selectedId);
    if (this.selection.length === 0) {
      this.selection.push({});
    }
    this.selection[0][this.levels[this.step].id] = selectedOption.id;
    // this.listRef.deselectAll();

    let item = {
      id: selectedOption.id,
      name: selectedOption.name
    }
    this.step++;
    this.items.splice(this.items.length - 1);
    this.items = [...this.items, item];
    if (this.step < this.levels.length) {
      // this.items = [...this.items, { label: 'Auswählen' }];
    }
    this.refreshSelectionList(selectedOption);
  }

  private refreshSelectionList(selectedOption: any) {
    this.setStartGap();
    this.largeEntries = this.levels[this.step].large;
    this.options = new Observable(observer => {
      if (this.step === 0) {
        // this._filter.options.forEach(option => observer.next(option));
        observer.next(this.context.topics);
      } else if (this.step === (this.levels.length - 1)) {
        const newOptions = [];
        for (let index = 1; index <= selectedOption.children.count; index++) {
          newOptions.push({
            id: '' + index,
            name: '' + index
          });
        }
        observer.next(newOptions);
      } else if (this.step < this.levels.length) {
        const newOptions = [];
        for (let index = 1; index <= selectedOption.children.count; index++) {
          newOptions.push({
            id: '' + index,
            name: '' + index,
            children: {
              count: selectedOption.children.items[index]
            }
          });
        }
        observer.next(newOptions);
      }
    });
    if (this.step < this.selection.length) {
    }
  }

  // onOpen() {
  //   this.previousSelection = this._filter.selection;
  // }

  trackByMedia(index: number, option: { name: string }): string {
    //const safeMedia = this.safeMedia(media);
    // return `${safeMedia.id}.${safeMedia.version}`;
    return `${option?.name}.`;//media?.id+media?.version+;//`${safeMedia.id}.${safeMedia.version}`;
  }

  onPanelSlide($event) {
  }
}
