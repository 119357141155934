/* projects/shared/src/lib/components/slide-panel/slide-panel-container.component.scss */
:host {
  display: block;
  overflow: hidden;
  position: relative;
}
:host > .container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 200%;
  display: flex;
}
:host > .container > div {
  width: 50%;
  position: relative;
}
/*# sourceMappingURL=slide-panel-container.component-GVZJSOYV.css.map */
