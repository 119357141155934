import {Size} from "../types/size";
import {Rectangle} from "../types/rectangle";

export function getElementSize(element: HTMLElement) : Size {
  let result = element.getBoundingClientRect();
  let styles = getComputedStyle(element);
  let marginTop = parseInt(styles['margin-top'], 10) ?? 0;
  let marginBottom = parseInt(styles['margin-bottom'], 10) ?? 0;
  let marginLeft = parseInt(styles['margin-left'], 10) ?? 0;
  let marginRight = parseInt(styles['margin-right'], 10) ?? 0;

  return {
    //top: result.top + marginTop,
    //bottom: result.bottom + marginBottom,
    //left: result.left + marginLeft,
    //right: result.right + marginRight,
    width: result.width + marginLeft + marginRight,
    height: result.height + marginTop + marginBottom
  };
}

export function getElementBoundingRectangle(element:HTMLElement) : Rectangle {
  let result = element?.getBoundingClientRect();
  return {
    left: result?.left ?? 0,
    top: result?.top ?? 0,
    width: result?.width ?? 0,
    height: result?.height ?? 0
  };
}
