import {Inject, Injectable} from "@angular/core";
import {Device as NativeDevice} from "@ionic-native/device/ngx"
import {File} from '@ionic-native/file/ngx';
import omit     from "lodash/omit";
import pickBy   from "lodash/pickBy";
import identity from "lodash/identity";
import {Logger} from "../logger.service";
import {Platform} from "./platform.service";
import {APP_PACKAGE_ID, AppIdentifierProvider} from "../app/app.provider";
import {WINDOW} from "../core.token";

export abstract class Device {

  private _id: string;
  public model: string;
  public platform: string;
  public uuid: string;
  public version?: string;
  public manufacturer?: string;
  public serial?: string;
  public isVirtual?: boolean;
  public previous: Device;

  constructor(device: Partial<Device>) {
    Object.assign(this, device);
  }

  get id(): string {
    return this._id || !this.valid ? this._id : (this._id = this.model+"#"+this.platform+"#"+this.uuid);
  }

  get valid(): boolean {
    return !!(this.model && this.platform && this.uuid)
  }

  abstract update(): Promise<void>;
  abstract purge(): Promise<void>;

  toJSON(): Partial<Device> {
    const result: Partial<Device> = omit(this, ['_id', 'id', 'valid']);
    return pickBy(result, identity);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  private _device: Device;
  private _package: any = null;

  private logger = new Logger('DeviceService');

  constructor(private _nativeDevice: NativeDevice,
              private platform: Platform,
              private file: File,
              private appIdentifier: AppIdentifierProvider,
              @Inject(APP_PACKAGE_ID) private readonly appPackageId: string,
              @Inject(WINDOW) private readonly window: Window
              // removed httpClient dependency as it is not currently used but causes a runtime error in angular v9:
              // "Error: Cannot instantiate cyclic dependency! HttpClient"
              // If needed later we can get a reference to the HttpClient instance lazily
              // via injector.get(HttpClient) inside device() api
              // private httpClient: HttpClient
              ) {}


  async device(): Promise<Device> {
    if (this._device) {
      return this._device;
    } else {
      await this.platform.ready();
      if (this.platform.is('hybrid')) {
        // dataDirectory it private, r/w, persisted and not synced to the cloud according to:
        // https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-file/
        // syncedDataDirectory (ios) - same as dataDirectory but synced to iCloud
        const directory = this.platform.is('ios') ? this.file.syncedDataDirectory : this.file.dataDirectory;
        const fileName = `.${this.appIdentifier.getApp()}`;
        const file = this.file;
        // const httpClient = this.httpClient;
        const device = new (class extends Device {
          async update(): Promise<void> {
            const config = await this.readConfig();
            const device: Device = config.device ? new (class extends Device {
              update(): Promise<void> { return Promise.reject('NOT IMPLEMENTED'); }
              purge():  Promise<void> { return Promise.reject('NOT IMPLEMENTED'); }
            })(config.device) : null;
            if (!device || device.id!=this.id) {
              if (this.valid) {
                this.previous = device;
                this.writeConfig();
                // await httpClient.post(`/v1.0/device/upgrade`, this).toPromise().then(() => true);
              } else {
                throw new Error('Invalid device');
              }
            }
          }
          async purge(): Promise<void> {
            if (this.previous) {
              this.previous = null;
              this.writeConfig();
            }
          }
          async writeConfig(config?: any): Promise<void> {
            config = config || await this.readConfig();
            config.device = this;
            let text: string;
            try {
              console.debug('writeConfig. config', config);
              text = JSON.stringify(config);
              console.debug('writeConfig. text', text);
              await file.writeFile(directory, fileName, text, {replace: true});
            } catch(e) {
              if (!text) {
                throw new Error(`Failed to stringify config. Error: ${e}`);
              } else {
                throw e;
              }
            }
          }
          async readConfig(): Promise<any>  {
            let config = {};
            try {
              // File: checkFile(path, file) - FILE_NOT_FOUND:
              // https://github.com/ionic-team/ionic-native/issues/1711
              // https://forum.ionicframework.com/t/ionic-native-file-unexpected-behavior-1-not-found-err/98099
              const exists = await file.checkFile(directory, fileName)
                                       .then((exists) => exists, (error) => false);
              if (exists) {
                const text = await file.readAsText(directory, fileName);
                if (text) {
                  try {
                    config = JSON.parse(text);
                  } catch (e) {
                    // throw new Error(`Failed to parse config: ${text}. Error: ${e}`);
                    console.warn(`Failed to parse config: ${text}. Error: ${e}`);
                  }
                } else {
                  console.warn('Empty config file');
                }
              }
              return config;
            } catch (e) {
              console.error('Failed to read config file', e);
            }
            return config;
          };
        })({
            model: this._nativeDevice.model,
            platform: this._nativeDevice.platform,
            uuid: this._nativeDevice.uuid,
            version: this._nativeDevice.version,
            manufacturer: this._nativeDevice.manufacturer,
            serial: this._nativeDevice.serial,
            isVirtual: this._nativeDevice.isVirtual
          }
        );
        await device.update().catch((error) => {
          throw new Error (`Failed to upgrade device: Error ${error}`);
        });
        this._device = device;
      } else {
        /*
         * seems device plugin does not work in browser as advertised in the following reference doc:
         * https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-device/
         * so we manually compose a minimal device object to fill in the gap for browser platforms
         *
         * in addition device properties are lazy loaded - we must access the property to trigger its value retrieval
         */
        this._device = new (class extends Device {
          update(): Promise<void> { return Promise.resolve() }
          purge():  Promise<void> { return Promise.resolve() }
        })({
          model: this.window.navigator.userAgent,
          platform: 'browser',
          uuid: '0'
        });
      }
    }
    return this._device;
  }

  async package(): Promise<any> {
    if (this._package != null) {
      return Promise.resolve(this._package);
    } else {
      await this.platform.ready();
      if (this.platform.is('android')) {
        // NOTE: if the package info is not yet derived (i.e. _package is not initialized) it could happen that
        // more than one concurrent calls to fetch this data are issued
        // there is no suck blocking mechanism implemented as it is assumed that
        // the call is not so expensive and this method is not invoked very often
        return Promise
          .all([
            this.appPackage().catch((error) => undefined),
            this.installerPackageName().catch((error) => undefined)
          ])
          .then(([appPackage, installerPackageName]) => {
            if (appPackage || installerPackageName) {
              const pkg = appPackage || {};
              if (installerPackageName) {
                pkg['installerPackageName'] = installerPackageName;
              }
              this._package = pkg;
              return pkg;
            } else {
              return this._package = undefined;
            }
          });
      } else {
        return Promise.resolve(this._package = undefined);
      }
    }
  }

  protected async appPackage(): Promise<any> {
    const packageManager = this.window['plugins'].packagemanager;
    if (packageManager) {
      return new Promise((resolve, reject) => {
        packageManager.getPackageInfo(
          function(pkg: object[]) {
            resolve(pkg.length ? pkg[0] : undefined);
          },
          function(error) {
            reject(error);
          },
          this.appPackageId,
          ['GET_META_DATA', /*'MATCH_SYSTEM_ONLY',*/, 'GET_GIDS']
        );
      });
    }
    return Promise.resolve(undefined);
  }

  /*
   * installerPackage name for android depends on how the app has been installed
   * a) adb install app_name.apk - returns null
   * b) adb push app_name.apk '/data/local/tmp/'
   *    adb shell
   *    pm install -i 'installerName' /data/local/tmp/app_name.apk
   *    -> returns the specified 'installerName'
   * c) play store installations return: com.android.vending
   */
  protected async installerPackageName(): Promise<string> {
    const social = this.window['plugins'].social;
    if (social) {
      return new Promise((resolve, reject) => {
        social.getInstallerPackageName(
          function(name) {
            resolve(name);
          },
          function(error) {
            reject(error);
          },
          this.appPackageId
        );
      });
    }
    return Promise.resolve(undefined);
  }
}
