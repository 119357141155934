import {
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {BasicContainerComponent} from "shared";
import {MediaViewerContext} from "../media-viewer-context";
import {MediaViewerContainer} from "../media-viewer-container";
import {Media, MediaAction} from "../../../store/models";
import {MEDIA_VIEWER_OVERLAY_DATA} from "./media-viewer-overlay.component";
import {MediaSliderComponent} from "../containers/media-slider/media-slider.component";
import {Logger} from "core";

@Component({
  selector: 'media-viewer-bottom-sheet',
  template: `<ng-template #viewerContainer></ng-template>`,
  styles: [`
    :host {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    ::ng-deep .media-viewer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  `]
})
export class MediaViewerBottomSheetComponent extends BasicContainerComponent {

  @ViewChild ('viewerContainer', { read: ViewContainerRef, static: true }) viewerContainer: ViewContainerRef;
  protected viewerRef: MediaViewerContainer;
  protected logger = new Logger('MediaViewerBottomSheetComponent');

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA)
              protected data: MediaViewerContext,
              protected bottomSheetRef: MatBottomSheetRef,
              protected resolver: ComponentFactoryResolver,
              protected renderer: Renderer2,
              protected injector: Injector) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit()
  }

  ngAfterViewInit() {
    super.ngOnInit()
    const injector =  Injector.create({
      providers: [{ provide: MEDIA_VIEWER_OVERLAY_DATA, useValue: this.data }],
      parent: this.injector
    });
    const componentFactory = this.resolver.resolveComponentFactory<MediaSliderComponent>(MediaSliderComponent);
    if (componentFactory) {
      const componentRef = this.viewerContainer.createComponent<MediaSliderComponent>(componentFactory, 0, injector);
      const viewer = this.viewerRef = componentRef.instance as MediaViewerContainer;
      viewer.changed.pipe(takeUntil(this.onDestroy$)).subscribe(event => this.onChanged(event) );
      viewer.action.pipe(takeUntil(this.onDestroy$)).subscribe(event => this.onAction(event) );
      viewer.completed.pipe(takeUntil(this.onDestroy$)).subscribe(event => this.onCompleted(event) );
      viewer.closed.pipe(takeUntil(this.onDestroy$)).subscribe(event => this.onClosed() );
      if (viewer.actionsTemplate) {
        viewer.actionsTemplate = this.data?.actionsTemplate;
      }
      this.renderer.addClass(componentRef.location.nativeElement, 'media-viewer');
    } else {
      this.logger.error('Cannot resolve factory for MediaViewer component');
    }
  }

  protected onAction(event: { media: Media, index?: number, action: MediaAction, data?: any }) {
    this.logger.debug('ACTION', event);
    if (typeof this.data?.onAction === 'function') {
      this.logger.debug('ACTION.2', event);
      this.data.onAction(event.action, event.data);
    }
  }

  protected onCompleted(event: { media: Media; index?: number }) {
    if (typeof this.data?.onComplete === 'function') {
      this.data.onComplete(event.media);
    }
  }


  protected onChanged(event: { media?: Media; index?: number }) {
    if (typeof this.data?.index === 'function' && event.index) {
      this.data.index(event.index);
    }
    if (typeof this.data?.onChange === 'function' && event.media) {
      this.data.onChange(event.media);
    }
  }

  protected onClosed() {
    this.bottomSheetRef.dismiss();
  }

  get viewer(): MediaViewerContainer {
    return this.viewerRef;
  }
}
