/* projects/shared/src/lib/components/busy/busy.component.scss */
.mat-mdc-progress-spinner {
  display: inline-block;
  vertical-align: middle;
}
.message {
  margin-left: 0.5em;
}
:host.app-busy-fill {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6274509804);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}
:host.app-busy-fill .message,
:host.app-busy-fill .mat-mdc-progress-spinner {
  display: block;
}
:host.app-busy-fill .mat-mdc-progress-spinner {
  display: inline-block;
}
:host.app-busy-fill .message {
  padding-top: 1em;
  margin-left: 0;
}
/*# sourceMappingURL=busy.component-CLL5YJNZ.css.map */
