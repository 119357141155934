<ng-content></ng-content>
<app-status-bar [class.filterButton]="filterButton" [class.dragging]="debug && dragging" [class.vertical-freeze]="debug && isVerticalDragFreezed()" [class.horizontal-freeze]="debug && filterCarousel.isHorizontalDragFreezed()">
  <div class="debug" *ngIf="debug"></div>
  <div #arrow class="arrow" *ngIf="filterArrow"><div></div></div>
  <filter-carousel #filterCarousel (onDrag)="onDrag($event)" [touchGrow]="touchGrow"
          [extendedDragArea]="extendedDragArea"
          [selected]="selectedFilter"
          (onSelect)="onSelectedFilterEmit($event)">
    <span class="filter"
      *ngFor="let filterLabel of filterLabels; let index = index;"
      [pressTriggerTime]="1000"
      [class.current]="selectedFilter==index"
      (onPress)="onPressFilter($event,index)"
      (onPressStart)="onPressFilterStart($event,index)"
      >{{filterLabel | translate}}</span>
    <grip *ngIf="filterLabels?.length==0"
      [pressTriggerTime]="1000"
      (onPress)="onPressFilter($event)"
      (onPressStart)="onPressFilterStart($event)"></grip>
  </filter-carousel>
  <div class="thumb-status-left">
    <ng-container *ngTemplateOutlet="thumbStatusLeft; context: {}"></ng-container>
  </div>
  <div class="thumb-status-right">
    <ng-container *ngTemplateOutlet="thumbStatusRight; context: {}"></ng-container>
  </div>
</app-status-bar>
<div #tabBarContainer class="tab-bar-container"
     [@snapAnimation]="{value:snapAnimationState,params:{from:snapAnimationFrom,to:snapAnimationTo,time:snapAnimationTime}}"
     (@snapAnimation.done)="onSnapDoneEvent($event)">
  <div #tabBarContent>
    <tab-bar #tabBar [tabDefinitions]="tabDefinitions"
             [selected]="selectedTab"
             (onSelect)="onSelectTab.emit($event)"></tab-bar>
    <ng-container *ngTemplateOutlet="tabBarExtension; context: {}"></ng-container>
  </div>
</div>

<ng-template #filterMenuTemplate>
  <div class="filter-list">
    <div *ngIf="!!homeLabel" class="home filter" (click)=onHome.emit()>
      <mat-icon>home</mat-icon>
      <span>{{homeLabel | translate}}</span>
    </div>
    <div class="theme filter">
      <mat-icon [class.selected]="isPreferredThemeMode('light')"   (click)="setPreferredThemeMode('light')">light_mode</mat-icon>
      <mat-icon [class.selected]="isPreferredThemeMode('dark')"    (click)="setPreferredThemeMode('dark')">dark_mode</mat-icon>
      <mat-icon [class.selected]="isPreferredThemeMode(undefined)" (click)="setPreferredThemeMode(undefined)">clear</mat-icon>
    </div>
    <ng-container *ngLet="propertiesService.hideTabBar$ | async as hideTabBar">
      <div class="startBar filter" (click)="setHideTabBar$(!hideTabBar)">
        <span translate>contact.showStartBar</span>
      </div>
    </ng-container>
    <div *ngIf="!!extensionMenuTemplate" class="filter menuExtension">
      <ng-container *ngTemplateOutlet="extensionMenuTemplate"></ng-container>
    </div>
    <ng-container *ngFor="let filterLabel of filterLabels; let index = index;">
      <div class="filter" [class.first]="index==0"
           [class.disabled]="index==selectedFilter"
           (click)=onSelectFilter.emit(index)
           >{{filterLabel | translate}}</div>
    </ng-container>
  </div>
</ng-template>


