import {Injectable, InjectionToken} from "@angular/core";
import {Device} from "core";
import {SessionRelatedMessage} from "../../authentication/models/messages";

export interface Session {
  id: string;
  ipAddress: string;
  host: string;
  uri: string;
  referrer: string;
  agent: string;
  agentParsed?: UserAgent;
  timeUpdated: number;
  device?: Device;
  localization?: {[key: string]: any};
  current: boolean;
}

export interface UserAgent {
  AgentClass: string;
  AgentName: string;
  AgentNameVersion: string;
  AgentNameVersionMajor: string;
  AgentVersion: string;
  AgentVersionMajor: string;
  DeviceBrand: string;
  DeviceClass: string;
  DeviceName: string;
  LayoutEngineClass: string;
  LayoutEngineName: string;
  LayoutEngineNameVersion: string;
  LayoutEngineNameVersionMajor: string;
  LayoutEngineVersion: string;
  LayoutEngineVersionMajor: string;
  OperatingSystemClass: string;
  OperatingSystemName: string;
  OperatingSystemNameVersion: string;
  OperatingSystemVersion: string;
  OperatingSystemVersionBuild: string;
  WebviewAppName: string;
  WebviewAppNameVersionMajor: string;
  WebviewAppVersion: string;
  WebviewAppVersionMajor: string;
}

export const SessionUpdateMessageType : string = "sessionUpdate";
export interface SessionUpdateMessage extends SessionRelatedMessage {
  sessionId: string;
  session?: Session;
  current?: boolean;
}

export const SessionListSubscribeMessageType : string = "sessionListSubscribe";
export interface SessionListSubscribeMessage extends SessionRelatedMessage {
}

export const SessionListUnsubscribeMessageType : string = "sessionListUnsubscribe";
export interface SessionListUnsubscribeMessage extends SessionRelatedMessage {
}

@Injectable({
  providedIn: 'root'
})
// @dynamic
export class NullSession implements Session {
  id: string;
  ipAddress: string;
  host: string;
  uri: string;
  referrer: string;
  agent: string;
  timeUpdated: 0;
  current = false;
}

export const nullSession = new InjectionToken('NullSession');
