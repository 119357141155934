import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {FileProgress, SuccessResponse, UploadResult, Uppy, UppyFile} from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import {Logger} from "core";
import {ImageCropperOverlayService} from "shared";
import {take} from "rxjs/operators";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {SessionTokenService} from "session";

export class ImageUploadActionEvent {
  constructor(public action: 'select' | 'delete',
              public prevent: (prevent: boolean) => void){
  }
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  protected static MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 mega bytes

  @Input() imageSrc: string;
  @Input() minWidth  = 0;
  @Input() minHeight = 0;
  @Input() hasEditButton = true;
  @Input() hasDeleteButton = true;

  @Output() onComplete: EventEmitter<any>  = new EventEmitter();
  @Output()    onError: EventEmitter<any>  = new EventEmitter();
  @Output()   onAction: EventEmitter<ImageUploadActionEvent> = new EventEmitter();

  @ViewChild('input') input: ElementRef;
  @ViewChild('image') image: ElementRef;
  @ViewChild('progress') progress : ElementRef;

  protected _uppy: Uppy;
  protected _endpoint: string;

  protected logger  = new Logger('ImageUploadComponent');

  constructor(protected imageCropperOverlayService: ImageCropperOverlayService,
              protected sessionTokenService: SessionTokenService,
              protected renderer: Renderer2) { }

  ngOnInit() {
  }

  @Input() 
  set endpoint(endpoint: string) {
    this._endpoint = endpoint;
    // Reset uppy to ensure that the correct enpoint is used
    // uppy could also be updated with the new endpoint, but to be on the safe side we reset it
    this._uppy = undefined; 
  }

  get endpoint() {
    return this._endpoint;
  }

  onTap(event: Event) {
    this.input.nativeElement.click();
  }

  onTapEdit(event: MouseEvent) {
    event.stopPropagation();
    const selectEvent = new ImageUploadActionEvent('select', (prevent) => {
      if (!prevent) {
        this.input.nativeElement.click();
      }
    });
    this.onAction.emit(selectEvent);
  }

  onTapDelete(event: MouseEvent) {
    event.stopPropagation();
    this.onAction.emit(new ImageUploadActionEvent('delete', () => {}));
  }

  onInputChange(event: Event) {
    const target: HTMLInputElement = <HTMLInputElement> event.target;
    const files: FileList = target.files;
    if (files.length > 0) {
      const file = files[0];
      console.log('FILE SIZE', file.size);
      if (file.size <= ImageUploadComponent.MAX_FILE_SIZE) {
        const imageSize: (callback: (width: number, height: number) => void) => void = (callback) => {
          const image = new Image();
          const imageURL = URL.createObjectURL(file);
          image.src = imageURL;
          image.onload = () => {
            const width = image.width;
            const height = image.height;
            URL.revokeObjectURL(imageURL);
            callback(width, height);
          };
        };
        imageSize((width, height) => {
          if (width && height) {
            const aspectRatio = Math.floor((width / height + Number.EPSILON) * 1000) / 1000;
            console.log('ASPECT RATIO', width, height, aspectRatio);
            const overlayRef = this.imageCropperOverlayService.open({ data: {
                fileChangeEvent: event,
                aspectRatio: aspectRatio,
                maintainAspectRatio: false,
                canChangeMaintainAspectRatio: true,
                format: "jpeg",
                onCrop: (imageCroppedEvent: ImageCroppedEvent) => {
                  fetch(imageCroppedEvent.base64)
                    .then(response =>  response.blob())
                    .then((blob) => {
                      this.logger.debug('CROPPED IMAGE BLOB', blob);
                      overlayRef.close();
                      const width = imageCroppedEvent.width, height = imageCroppedEvent.height;
                      if (width >= this.minWidth && height >= this.minHeight && blob.size) {
                        // NOTE: Based on the current implementation, browsers won't actually read the bytestream of a file to determine its media type.
                        // It is assumed based on the file extension; a PNG image file renamed to .txt would give "text/plain" and not "image/png".
                        // Moreover, file.type is generally reliable only for common file types like images, HTML documents, audio and video.
                        // Uncommon file extensions would return an empty string.
                        // Client configuration (for instance, the Windows Registry) may result in unexpected values even for common types.
                        // Developers are advised not to rely on this property as a sole validation scheme.
                        // https://developer.mozilla.org/en-US/docs/Web/API/File/type
                        this.uploadPhoto(blob,
                          {
                            name: file.name,
                            type: 'image/jpg' // png is the default output format of image cropper (also the only one supported in all browsers - according to its specification)
                          },
                          this.renderer.parentNode(event.target)
                        ).then(result => {
                          this.logger.info('Image upload completed', result);
                          if (result.successful.length==1) {
                            const response = (result.successful[0] as any).response || {};
                            this.onComplete.emit(response.body);
                          } else {
                            this.onError.emit();
                          }
                        }).catch(error => {
                          this.logger.error('Failed to upload image', error);
                          this.onError.emit(error);
                        }).then(() => {
                          target.value = '';
                        });
                      } else {
                        this.onError.emit({ error: "imageTooSmall", width: width, height: height });
                        target.value = '';
                      }
                    });
                },
                header: 'media.actions.uploadCover'
              }});
            overlayRef.onClose.pipe(take(1)).subscribe(() => target.value = '');
          }
        });
      } else {
        const error = { error: "imageTooBig", fileSize: file.size, maxFileSize: ImageUploadComponent.MAX_FILE_SIZE };
        this.logger.error('Failed to upload image', error);
        this.onError.emit(error);
      }
    }
  }

  // uploadPhoto(file: File, target: any, blob?: Blob): Promise<UploadResult> {
  uploadPhoto(blob: Blob, meta: {  name: string, type: string}, target: any): Promise<UploadResult> {
    try {
      this.uppy.addFile({
        source: 'imageInput',
        name: meta.name,
        type: meta.type,
        data: blob,
      });
      return this.uppy.upload();
    } catch (e) {
      return Promise.reject(e);
    }
  }

  get uppy(): Uppy {
    //lazy creation for photo upload uppy
    //as we do not seek for resume-after-reload here
    if (!this._uppy) {
      this._uppy = this.createUppy();
    }
    return this._uppy;
  }

  createUppy(): Uppy {
    const uppy = new Uppy({
      id: 'image',  // used for local storage isolation in order to avoid collisions with other uppy instances
      autoProceed: false,
      debug: true,
      restrictions: {
        // maxFileSize is maximum file size in bytes for each individual file (total max size has been requested, and is planned)
        // maxFileSize: ImageUploadComponent.MAX_FILE_SIZE * 2.5,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*']
      }
    });
    // console.debug(`UPPY to ${environment.serverUrl}/v1.0/contacts/upload/photo`);
    //for details: https://uppy.io/docs/xhr-upload/
    uppy.use(XHRUpload,{
      endpoint: this.sessionTokenService.rewrite(this.endpoint),
      formData: true,
      fieldName: 'image',
      withCredentials: true
    });
    // replace XHRUpload with tus once we have compatible server
    // uppy.use(Tus, {
    //   endpoint: 'https://master.tus.io/files/'
    // })

    // uppy.use(FileInput, { target: this.photoInput.nativeElement, replaceTargetContent: true });
    // uppy.use(ProgressBar, {
    //   id: 'progressBar',
    //   target: this.photoUploadProgress.nativeElement, // where to mount the progress bar (default is 'body')
    //   fixed: false,
    //   hideAfterFinish: true
    // });

    let progressElement = this.progress.nativeElement;
    let progressContainer = this.renderer.parentNode(progressElement);
    uppy
      .on('upload', (data: { id: string, fileIDs: string[] }) => {
        this.renderer.addClass(progressContainer, 'progress');
        this.renderer.setStyle(progressElement, 'width', 0);
      })
      .on('upload-progress', (file: UppyFile, progress: FileProgress) => {
        this.logger.debug('PHOTO UPLOAD PROGRESS', { progress });
        /*
         *   In case of XHR upload the progress is calculated purely by tracking the transferred bytes
         *   (e.g. the size of tcp packet payloads) sent (and acknowledged by the server) trough the tcp connection.
         *   Progress on the level of the HTTP layer is not available in this case
         *   (in contrast with Tus where it is supported by the core Tus protocol)
         *
         *   (see https://github.com/transloadit/uppy/blob/master/packages/%40uppy/xhr-upload/src/index.js)
         *
         *   Note on Angular: seems this code runs outside of angular zone
         *   so if we use data binding in template we need to make angular aware of the change in data model:
         *   1. use change detector - this.changeDetector.markForCheck(); or
         *   2. run in angular zone - this.zone.run(...);
         */
        const percentage = progress.bytesUploaded/progress.bytesTotal * 100;
        this.renderer.setStyle(progressElement, 'width', percentage+'%');
      })
      .on('upload-success', (file: UppyFile, response: SuccessResponse) => {
        this.logger.debug('PHOTO UPLOAD SUCCESS', { file, response });
        this.renderer.setStyle(progressElement, 'width', 100+'%');
      })
      .on('complete', (result: UploadResult) => {
        this.logger.debug('PHOTO UPLOAD COMPLETED', { result });
        this.renderer.removeClass(progressContainer, 'progress');
        // reset to clear all added files. uppy will complain if the same file is added twice
        // which effectively prevents re-upload of the same file
        uppy.reset();
      });
    return uppy;
  }

  onImageLoad(event: Event) {
    this.renderer.setStyle(event.target, 'opacity', '1');
  }

  getImageSize(file: File, callback:(size:number[]) => void) {
    let image = new Image();
    image.src = window.URL.createObjectURL(file);
    image.onload = () => {
      const width = image.naturalWidth, height = image.naturalHeight;
      window.URL.revokeObjectURL(image.src);
      (callback || (() => {}))([width, height]);
      image = null;
    }
  }
}
