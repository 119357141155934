import moment from "moment";

export function compareStringArrays(array1 : Array<string>, array2 : Array<string>) : boolean {
  if (!array1 || !array2) {
    return !array1 && !array2;
  } else if (array1.length == array2.length) {

  }
  return false;
}

export interface UrlParameters {
  add(name: string, value: string, needsValue: boolean): UrlParameters;
  toString(): string;
}

function internalAddUrlParameter(name: string, value: string, needsValue: boolean, previous: string): UrlParameters {
  var trimmedValue = value ? value.trim() : '';
  //console.debug("name:"+name+" value:"+value+" needsValue:"+needsValue);
  return new class implements UrlParameters {
    add(name1: string, value1: string, needsValue1: boolean): UrlParameters {
      //console.debug("name1:"+name1+" value1:"+value1+" needsValue1:"+needsValue1+" toString():"+this.toString());
      return internalAddUrlParameter(name1, value1, needsValue1, this.toString());
    }
    toString(): string {
      var param = needsValue && trimmedValue.length>0 ? name+'='+trimmedValue : !needsValue ? name : "";
      //console.debug("name:"+name+" value:"+value+" needsValue:"+needsValue+" trimmedValue:"+trimmedValue);
      return param.length>0 ? previous.length>0 ? previous+"&"+param : "?"+param : previous;
    }
  }
}

export function addUrlParameter(name:string,value:string,needsValue:boolean) : UrlParameters {
  return internalAddUrlParameter(name,value,needsValue,'');
}

export function prettyDateDiff(start: number | string | Date, end?: number | string | Date): [number, string] {
  const second = 1000;
  const minute = 60  * second;
  const hour   = 60  * minute;
  const day    = 24  * hour;
  const week   = 7   * day;
  const month  = 30  * day;  // approximately
  const year   = 365 * day;  // approximately
  const definitions = [{ year }, { month }, { week }, { day }, { hour }, { minute }, { second }];

  const time = (date: any) => {
    let time = 0;
    if (!date) {
      time = new Date().getTime()
    } else if (typeof date === 'number') {
      time = date;
    } else if (typeof date === 'string') {
      time = new Date(date).getTime();
    } else if (date instanceof Date) {
      time = date.getTime();
    }
    return time;
  };
  const difference = time(end) - time(start);
  let units = 0;
  for (let i = 0; i < definitions.length; i++) {
    const [[unitName, unit]] = Object.entries(definitions[i]);
    units = difference / unit | 0;
    if (Math.abs(units) > 1) {
      return [units, unitName];
    }
  }
  return [difference/1000 | 0, 'second'];
}

export function secondsToTime(seconds: number) {
  let minute, hour, day;
  minute = Math.floor(seconds / 60);
  seconds = seconds % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  // day = Math.floor(hour / 24);
  // hour = hour % 24;

  return [ hour, minute, seconds ];
  // return [ day, hour, minute, seconds ];
}

export function ymdToDate(value: number): Date {
  try {
    const day   = value%100;
    const month = ~~(value/100) % 100;
    const year  = ~~(value/10000);
    if (day>0 && day<=31 && month>0 && month<=12 && year<=9999) {
      if (year<=1000) {
        return ymdToDate(value % 10000 + 10000000);
      } else {
        const day   = value%100;
        const month = ~~(value/100) % 100;
        const year  = ~~(value/10000);
        return new Date(year, month-1, day);
      }
    }
  } catch (e) {
    this.logger.error(e);
  }
  return undefined;
}

export function dateToYmd(value: Date | moment.Moment) {
  if (value) {
    const date = value instanceof Date ? value : value.toDate();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return parseInt(`${year}${month}${day}`);
  } else {
    return 0;
  }
}

export function loadScript(document: Document, src: string): Promise<any> {
  let resolveLoad: () => void;
  let rejectLoad: (error: string | Event) => void;
  const promise = new Promise<void>((resolve, reject) => {
    resolveLoad = resolve;
    rejectLoad  = reject;
  });
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = src;
  script.onload  = () => resolveLoad();
  script.onerror = (error: any) => rejectLoad(error);
  document.body.appendChild(script);
  return promise;
}

export function rgb2hex(input: string) {
  const rgb: string[] = input.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
  let alpha: string = rgb && rgb[4]
    ? (parseInt(rgb[4].trim()) * 255 | 1 << 8).toString(16).slice(1)
    : '';
  const hex =  (rgb && rgb.length >= 4) ? '#' +
    ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
    ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
    ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : input;
    return hex + alpha;
}

export function once(func, context?: any) {
  let result;
  return function() {
    if (func) {
      result = func.apply(context || this, arguments);
      func = null;
    }
    return result;
  };
}

export function isValidNumber(number:number):boolean {
  return number === null ? false : !isNaN(number);
}

export function secondLevelDomain(): string {
  let path  = window.location.hostname || '';
  let parts = path.split('\.');
  return parts[Math.max(0, parts.length - 2)]
}
