import {DetachedRouteHandle} from "@angular/router";
import {ComponentRef} from "@angular/core";

// import {Tree} from "@angular/router/router";

/**
 * A route strategy allowing for explicit route reuse.
 * Used as a workaround for https://github.com/angular/angular/issues/18374
 * To reuse a given route, add `data: { reuse: true }` to the route definition.
 *
 * see:
 *  https://stackblitz.com/github/thorn0/angular-router-experiments?file=src%2Fapp%2Fcustom-reuse-strategy.ts
 *  https://medium.com/@gerasimov.pk/how-to-reuse-rendered-component-in-angular-2-3-with-routereusestrategy-64628e1ca3eb
 */

// interface RouteCacheRecord {
//   handle: DetachedRouteHandleExt;
//   //
//   // * For unclear reasons, when the navigation starts, `retrieve` is called
//   // * without calling `shouldAttach` first (from `createRouterState`).
//   // * This flag is used to ignore those calls. :-\
//   // *
//   shouldAttachCalled: boolean;
// }

export interface DetachedRouteHandleExt extends DetachedRouteHandle {
  componentRef: ComponentRef<any>;
  route: any; //TreeNode<ActivatedRoute>;
}

export class RouteReusableStrategyCache {

  protected cache = new Map<string, DetachedRouteHandleExt>();

  set(key: string, tree: DetachedRouteHandleExt) {
    this.cache.set(key, tree);
  }

  get(key: string): DetachedRouteHandleExt {
    return this.cache.get(key);
  }

  delete(key: string) {
    this.delete(key);
  }

  clear() {
    this.cache.clear();
  }
}
