import {animate, animateChild, group, query, style, transition, trigger} from '@angular/animations';

// Routable animations
export const sidebarAnimations =
  trigger('sidebarAnimation', [
    transition('level-1 => level-0', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
        })
      ], { optional: true }),
      query(':enter', [
        style({ left: '-100%', zIndex: 1, backgroundColor: "white", boxShadow: "0px 1px 8px 0px rgba(0,0,0,.2), 0px 3px 4px 0px rgba(0,0,0,.14), 0px 3px 3px -2px rgba(0,0,0,.12)"  })
      ], { optional: true }),
      query(':leave', [
        style({ zIndex: 0 })
      ], { optional: true }),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(':leave', [
          animate('300ms linear', style({ left: '30%' /*100%*/}))
        ], { optional: true }),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%'}))
        ], { optional: true })
      ]),
      query(':enter', animateChild(), { optional: true }),
    ]),

    transition('level-0 => level-1', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: '0px',
          left: '0px',
          width: '100%',
          height: '100%',
        })
      ], { optional: true }),
      query(':enter', [
        style({ left: '100%', zIndex: 1, backgroundColor: "white", boxShadow: "0px 1px 8px 0px rgba(0,0,0,.2), 0px 3px 4px 0px rgba(0,0,0,.14), 0px 3px 3px -2px rgba(0,0,0,.12)" })
      ], { optional: true }),
      query(':leave', [
        style({ zIndex: 0 })
      ], { optional: true }),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(':leave', [
          animate('300ms linear', style({ left: '-30%' /*-100%*/}))
        ], { optional: true }),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%'}))
        ], { optional: true })
      ]),
      query(':enter', animateChild(), { optional: true }),
    ]),
  ]);

export const enterLeaveAnimations = [
  trigger('enterLeaveHeightAnimation', [
    transition(':enter', [
      style({maxHeight:0, overflow:'hidden', opacity: 0.5}),
      animate('300ms', style({maxHeight:'{{height}}', opacity: 1}))
    ], {params : { height: "10px" }}),
    transition(':leave', [
      style({maxHeight:'{{height}}', opacity: 1, overflow:'hidden'}),
      animate('300ms', style({maxHeight:0, opacity: 0, overflow:'hidden'}))
    ], {params : { height: "10px" }})
  ]),
  trigger('enterLeaveOpacityAnimation', [
    transition(':enter', [
      style({overflow:'hidden', opacity: 0, zIndex: 1}),
      animate('300ms', style({opacity: 1, zIndex: 1}))
    ], {params : { height: "10px" }}),
    transition(':leave', [
      style({opacity: 1, zIndex: 0, overflow:'hidden'}),
      animate('300ms', style({opacity: 0, zIndex: 0}))
    ])
  ]),
]

/*
export const thumbAnimations =
  // THIS ANIMATION DOES NOT WORK AS EXPECTED.
  // It is intended to avoid displaying previous and next ThumbStatusRightComponent (at the same time)
  // during page transition. This seems to be achieved with empty animation but there is no explanation why it works!
  // UPDATE: The animation has been removed because it causes the following error after login:
  // "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
  //  Previous value for '@thumbStatusRightAnimation': 'content'. Current value: 'inside'.. "
  // In order to prevent two instances of ThumbStatusRightComponent to be displayed
  // ThumbNavigationComponent has been changed to apply proper styles to the corresponding thumb status controls
  trigger('thumbStatusRightAnimation', [
    transition('* <=> *', [
      // style({ position: 'relative' }),
      // query(':enter, :leave', [
      //   style({
      //     position: 'absolute',
      //     top: 0,
      //     left: 0,
      //     width: '100%'
      //   })
      // ], {optional: true})
    ]),
  ]);
*/

export const contentAnimations =
  trigger('contentAnimation', [
    transition('* <=> *', [
      // query(':enter, :leave', [
      //   style({
      //     position: 'absolute',
      //     top: '0px',
      //     left: '0px',
      //     right: '0px',
      //     bottom: '0px',
      //     overflow: 'hidden',
      //   })
      // ], { optional: true }),
      query(':enter', [
        style({  opacity: '0', zIndex: 1 }), // offset = 0
      ], { optional: true }),
      query(':leave', [
        style({  opacity: '1', zIndex: 0 }), // offset = 0
      ], { optional: true }),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(':leave', [
          animate('400ms ease-out', style({ opacity: '1' }))
        ], { optional: true }),
        query(':enter', [
          animate('400ms ease-out', style({ opacity: '1' }))
        ], { optional: true }),
      ]),
      query(':enter', animateChild(), { optional: true }),
    ]),
  ]);

export const authenticationAnimations =
  trigger('authenticationAnimation', [
    transition('outside <=> inside', [
      // https://github.com/angular/angular/issues/15477
      // start: prevent child outlets to be emptied...
      query('router-outlet ~ *', [style({}), animate(1, style({}))], { optional: true }),
      // end
      style({
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        perspective: '1200px',
        transformStyle: 'preserve-3d',
        backgroundColor: 'black'
      }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: '0px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          overflow: 'hidden',
          backfaceVisibility: 'hidden',
          transform: 'translate3d(0px, 0px, 0px)'
        })
      ], { optional: true }),
      query(':enter', [
        style({ zIndex: 999, transform: "scale(0.5)", opacity: '0' }), // offset = 0
      ], { optional: true }),
      query(':leave', [
        style({ opacity: '1' }), // offset = 0
      ], { optional: true }),
      group([
        query(':leave', [
          animate('500ms ease-out', style({ transform: "scale(0.5)", opacity: '0.1' }))
        ], { optional: true }),
        query(':enter', [
          animate('500ms ease-out', style({ transform: "scale(1)", opacity: '1'}))
        ], { optional: true })
      ]),
      query(':enter', animateChild()),
    ]),
  ]);
