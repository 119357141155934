import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {TabBarComponent, TabDefinition} from "../tab-bar/tab-bar.component";
import {DragEvent} from "../../models/touch/drag-event";
import {animate, AnimationEvent, state, style, transition, trigger} from "@angular/animations";
import {Subscription} from "rxjs";
import {FilterCarouselComponent} from "../filter-carousel/filter-carousel.component";
import {ResizeService} from "../../directives/resize/resize.service";
import {PressEvent, PressStartEvent} from "../../directives/press/press.directive";
import {Overlay, OverlayConfig, OverlayRef} from "@angular/cdk/overlay";
import {TemplatePortal} from "@angular/cdk/portal";
import {Properties, PropertiesService} from "properties";
import {getPreferredThemeMode, setPreferredThemeMode, ThemeMode} from "core";

const snapAnimation = trigger('snapAnimation',
  [
    state('flow',
      style({ height: '{{ from }}' }),
      { params: { from: '0px'}}
    ),
    state('snap',
      style({ height: '{{ to }}' }),
      { params: { to: '0px'}}
    ),
    transition('flow <=> snap',animate('{{ time }}')),
    transition('snap <=> flow',animate('0ms'))
  ]
);

export class ExpandFiltersEvent {
  component: ThumbNavigationComponent;
  distance: number;       // distance between max visible and current y dragging position
  cancelDrag: boolean;    // set to true if drag should be cancelled
}

@Component({
  selector: 'thumb-navigation',
  templateUrl: './thumb-navigation.component.html',
  styleUrls: ['./thumb-navigation.component.scss'],
  animations: [
    snapAnimation
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThumbNavigationComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input()  touchGrow: boolean = false;
  @Input()  tabDefinitions:TabDefinition[] = [];
  @Input()  filterLabels:string[] = [];
  @Input()  autoCollapseTimeout: number = undefined;
  @Input()  autoCollapseOnSelection: boolean = false;
  @Input()  open: boolean = false;
  @Input()  debug: boolean = false;
  @Input()  filterArrow: boolean = true;
  @Input()  extendedDragArea: boolean = true;
  @Input()  filterButton: boolean = false;    // currently selcted has primary color background

  @Output() onHome: EventEmitter<void> = new EventEmitter<void>();
  @Output() onShowTabs: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSelectTab: EventEmitter<number> = new EventEmitter<number>();
  @Input()  selectedTab: number = undefined;
  @Output() onSelectFilter: EventEmitter<number> = new EventEmitter<number>();
  @Output() onExpandFilters: EventEmitter<ExpandFiltersEvent> = new EventEmitter<ExpandFiltersEvent>();
  @Input()  selectedFilter: number = undefined;
  @Input()  verticalThreshold: number = 0;
  @Input()  expandFiltersThreshold: number = 0;
  @Input()  homeLabel: string = undefined;    // no home button if not defined
  @Input()  extensionMenuTemplate: TemplateRef<any>;

  @ViewChild('tabBarContainer', {read: ElementRef, static: true})
  tabBarContainerElementRef: ElementRef;
  @ViewChild('tabBarContent', {read: ElementRef, static: true})
  tabBarContentElementRef: ElementRef;
  @ViewChild('filterCarousel', {read: ElementRef, static: true})
  filterCarouselElementRef: ElementRef;

  @ViewChild('filterCarousel', {read: FilterCarouselComponent, static: true})
  filterCarousel: FilterCarouselComponent;
  @ViewChild('tabBar', {read: TabBarComponent, static: true})
  tabBar: TabBarComponent;

  public snapAnimationState:string = 'flow';
  public snapAnimationFrom:string  = '0px';
  public snapAnimationTo:string    = '0px';
  public snapAnimationTime:string  = '0ms';

  public dragging = false;
  public expandFiltersEventFired = false;

  protected overlayRef: OverlayRef;
  protected pressFilterStartEvent:PressStartEvent;
  protected subscriptions = new Subscription();

  @ContentChild('thumbStatusLeft') thumbStatusLeft: TemplateRef<any>;
  @ContentChild('thumbStatusRight') thumbStatusRight: TemplateRef<any>;
  @ContentChild('tabBarExtension') tabBarExtension: TemplateRef<any>;

  @ViewChild('filterMenuTemplate') filterMenuTemplate: TemplateRef<any>;

  constructor(
    protected changeDetector: ChangeDetectorRef,
    protected resizeService: ResizeService,
    protected propertiesService: PropertiesService,
    protected overlay: Overlay,
    protected viewContainerRef: ViewContainerRef,
    protected elementRef: ElementRef) { }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.onSelectTab.complete();
    this.onSelectFilter.complete();
  }

  public ngAfterViewInit(): void {
    this.subscriptions.add(this.onSelectTab.subscribe((index:any) => this.onSelectedTab(index)));
    this.subscriptions.add(this.onSelectFilter.subscribe((index:any) => this.onSelectedFilter(index)));
    this.subscriptions.add(this.onHome.subscribe(() => this.onSelectedHome()));
    this.initializeTabBar();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    //console.log("ngOnChanges",changes,"open",this.open);
    this.initializeTabBar();
    if (this.tabBar && this.tabBar.selected!=this.selectedTab) {
      //console.log("ngOnChanges>tabBar.onSelect.emit",this.selectedTab);
      try {
        this.tabBar.onSelect.emit(this.selectedTab);
      } catch(e) {}
    }
    if (this.filterCarousel && this.filterCarousel.selected!=this.selectedFilter) {
      //console.log("ngOnChanges>filterCarousel.onSelect.emit",this.selectedFilter);
      try {
        this.filterCarousel.onSelect.emit(this.selectedFilter);
      } catch(e) {}
    }
    if (this.tabDefinitions.length>0 && !this.initialized) {
      //console.info("ngOnChanges try initialize");
      window.setTimeout(()=> {
        //console.info("ngOnChanges try initialize timed");
        this.ngOnChanges(changes);
      });
    }
  }

  protected initializeTabBar() {
    //console.log("initializeTabBar()","tabDefinitions.length",this.tabDefinitions.length,"tabBarHeight",this.tabBarHeight);
    if (this.tabDefinitions.length>=1 && this.tabBarHeight<=1) {
      let tabBarHeight  = this.tabBarContentElementRef.nativeElement.offsetHeight;
      if (tabBarHeight>1) {
        this.tabBarHeight = tabBarHeight;
        this.initialized  = true;
        //this.changeDetector.detectChanges();
        //console.log("this.tabBarHeight",this.tabBarHeight,"this.open",this.open,"tabBar",this.tabBar.tabDefinitions,"tabDefinitions",this.tabDefinitions);
        if (this.open) {
          this.visibleHeight      = this.tabBarHeight;
          this.snapAnimationFrom  = this.snapAnimationTo = this.visibleHeight+'px';
          this.snapAnimationState = 'flow';
          this.triggerCollapse(false);
        }
        //this.onSelectTab.emit(this.selectedTab);
        //this.onSelectFilter.emit(this.selectedFilter);
      }
    }
  }

  protected onSelectedTab(index : number) {
    //console.info("onSelectedTab(index:"+index+") "+this.selectedTab);
    if (this.selectedTab != index) {
      this.selectedTab = index;
      this.triggerCollapse(true);
    } else {
      this.triggerCollapse(this.initialized);
    }
  }

  public onSelectedFilterEmit(index:number) {
    if (index>=0 && index<this.filterLabels?.length && this.selectedFilter!=index) {
      this.onSelectFilter.emit(index);
    }
  }

  protected onSelectedFilter(index : number) {
    if (index>=0 && index<this.filterLabels?.length) {
      let changed = this.selectedFilter!=index;
      this.selectedFilter = index;
      if (this.overlayRef && changed) {
        this.overlayRef.detach();
      }
    }
  }

  protected onSelectedHome() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  protected setHideTabBar$(hide:boolean):Promise<Properties> {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    return this.propertiesService.setHideTabBar$(hide);
  }

  setPreferredThemeMode(mode: ThemeMode|undefined) {
    setPreferredThemeMode(mode);
  }

  isPreferredThemeMode(mode: string|undefined): boolean {
    let preferredMode = getPreferredThemeMode(false);
    return mode == preferredMode;
  }

  protected tabBarHeight:number = 0;
  protected visibleHeight:number = 0;
  protected initialized:boolean = false;
  protected verticalGlue:boolean = false;
  protected showTabs:boolean = false;
  protected verticalDragFreezed:boolean = false;

  public isVerticalDragFreezed():boolean {
    return this.verticalDragFreezed;
  }

  public setVerticalDragFreezed(freezed:boolean):void {
    this.verticalDragFreezed = freezed;
  }

  public onDrag(event:DragEvent) {
    // console.debug("y:"+event.y);
    if (event.type=='drag') {
      this.dragging = true;
      let height = Math.max(0, Math.min(this.tabBarHeight,this.visibleHeight-event.deltaY));
      if (!this.isVerticalDragFreezed() && !this.filterCarousel.isHorizontalDragFreezed() && this.verticalThreshold>0) {
        let absoluteX = Math.abs(event.deltaX);
        let absoluteY = Math.abs(event.deltaY);
        let max       = Math.max(absoluteX,absoluteY);
        if (max>=5) {
          if (absoluteX>absoluteY) {
            this.onDrag({...event, type:'stop'});
            this.setVerticalDragFreezed(true);
          } else {
            this.filterCarousel.setHorizontalDragFreezed(true);
          }
          if (this.pressFilterStartEvent) {
            this.pressFilterStartEvent.cancel();
          }
        }
      }
      if (!this.isVerticalDragFreezed()) {
        this.verticalGlue = this.verticalGlue && height<this.verticalThreshold;
        this.tabBarContainerElementRef.nativeElement.style.height =
        this.snapAnimationFrom = this.verticalGlue ? '0px' : height+'px';
        this.resizeService.triggerAsync();
        if (!this.verticalGlue && !this.showTabs) {
          this.showTabs = true;
          try {
            this.onShowTabs.emit(true);
          } catch(e) {}
        }
      }
      if (!this.expandFiltersEventFired && height==this.tabBarHeight) {
        let overdrag = this.visibleHeight-event.deltaY-this.tabBarHeight;
        if (overdrag>this.expandFiltersThreshold) {
          this.expandFiltersEventFired = true;
          let expandEvent:ExpandFiltersEvent = {
            component: this,
            distance: overdrag,
            cancelDrag: false
          };
          this.onExpandFilters.emit(expandEvent);
          event.cancel = expandEvent.cancelDrag;
          //console.log("onDrag::event.cancel",expandEvent.cancelDrag);
        }
        //console.log("drag y",event.y,"overdrag",overdrag,"height",height,"verticalGlue",this.verticalGlue,"tabBarHeight",this.tabBarHeight,"visibleHeight",this.visibleHeight);
      }
      this.preventCollapse();
    } else if (event.type=='start') {
      this.dragging = true;
      this.preventCollapse();
      this.verticalDragFreezed = false;
      this.expandFiltersEventFired = false;
      this.tabBarHeight  = this.tabBarContentElementRef.nativeElement.offsetHeight;
      this.visibleHeight = this.tabBarContainerElementRef.nativeElement.offsetHeight;
      this.snapAnimationState = 'flow';
      this.verticalGlue  = this.visibleHeight==0 && this.verticalThreshold>0;
    } else if (event.type=='stop') {
      this.dragging = false;
      this.triggerCollapse(false);
      this.verticalDragFreezed = false;
      let visibleHeight = this.tabBarContainerElementRef.nativeElement.offsetHeight;
      if (visibleHeight==this.tabBarHeight || visibleHeight==0) {
        this.snapAnimationFrom = this.snapAnimationTo = visibleHeight+'px';
        this.snapAnimationState = 'flow';
        if (visibleHeight==0 && this.showTabs) {
          this.showTabs = false;
          try {
            this.onShowTabs.emit(false);
          } catch(e) {}
        }
      } else if (visibleHeight<=(this.tabBarHeight/2)) {
        this.snapAnimationFrom  = visibleHeight+'px';
        this.snapAnimationTo    = '0px';
        this.snapAnimationTime  = '300ms';
        this.snapAnimationState = 'snap';
      } else {
        this.snapAnimationFrom  = visibleHeight+'px';
        this.snapAnimationTo    = this.tabBarHeight+'px';
        this.snapAnimationTime  = '300ms';
        this.snapAnimationState = 'snap';
      }
      this.visibleHeight = visibleHeight;
    }
  }

  onSnapDoneEvent(event:AnimationEvent) {
    //console.log("onSnapDoneEvent",event);
    if (event.toState == 'snap') {
      this.visibleHeight      = this.tabBarContainerElementRef.nativeElement.offsetHeight;
      this.snapAnimationFrom  = this.snapAnimationTo = this.visibleHeight+'px';
      this.snapAnimationState = 'flow';
      this.resizeService.triggerAsync();
      this.verticalDragFreezed = false;
      if (this.visibleHeight==0 && this.showTabs) {
        this.showTabs = false;
        try {
          this.onShowTabs.emit(false);
        } catch(e) {}
      }
    }
  }

  onPressFilter(event:PressEvent,index?:number) {
    //console.log("onPressFilter()",index,event);
    if ((this.filterLabels?.length>1 || !!this.homeLabel) && !this.overlayRef) {
      let rect   = this.filterCarouselElementRef.nativeElement.getBoundingClientRect();
      let offset = rect.right-window.innerWidth + rect.left;
      let bottom = window.innerHeight-rect.top+10;
      //console.log("rect.top",rect.top, "right",rect.right, "bottom",rect.bottom, "left",rect.left,"window.width",window.innerWidth,"window.height",window.innerHeight);
      let config = new OverlayConfig({
        hasBackdrop: true,
        backdropClass: 'cdk-overlay-transparent-backdrop',
        positionStrategy: this.overlay.position().global().bottom(bottom+"px").centerHorizontally(offset+"px")
      });
      // delay the opening. else on android it gets a backdrop clicked
      // which closes it immediately on opening
      window.setTimeout(()=>{
        this.overlayRef = this.overlay.create(config);
        this.overlayRef.attach(new TemplatePortal(this.filterMenuTemplate,this.viewContainerRef));
        this.elementRef.nativeElement.getAttributeNames().forEach(name => {
          if (!this.elementRef.nativeElement.getAttributeNode(name).value) {
            this.overlayRef.overlayElement.setAttribute(name,'');
          }
        });
        //console.trace("ATTACHED");
        this.overlayRef.backdropClick().subscribe(() => {
          //console.log("onPressFilter.backdropClicked",index,event);
          this.overlayRef.detach();
        });
        this.overlayRef.detachments().subscribe(()=> {
          this.overlayRef = undefined;
          //console.trace("DETACHED");
        })
      },100);
    }
  }

  onPressFilterStart(event:PressStartEvent,index?:number) {
    //console.log("onPressFilterStart()",index,event);
    if (index==this.filterCarousel.selected || index==undefined) {
      //console.log("onPressFilterStart");
      this.pressFilterStartEvent = event;
    } else {
      event.cancel();
      //console.log("onPressFilterStart::event.cancel()");
      this.pressFilterStartEvent = undefined;
    }
  }

  protected triggerTimer:number = undefined;
  preventCollapse() {
    //console.log("preventCollapse()",!!this.triggerTimer,"this.visibleHeight",this.visibleHeight,"this.tabBarHeight",this.tabBarHeight);
    if (this.triggerTimer) {
      window.clearTimeout(this.triggerTimer);
      this.triggerTimer = undefined;
    }
  }

  triggerCollapse(selected:boolean) {
    //console.info("triggerCollapse(selected:"+selected+") "+this.selectedTab);
    this.preventCollapse();
    let time = this.autoCollapseOnSelection && selected ? 0 :
               this.autoCollapseTimeout;
    //console.log("triggerCollapse("+selected+") time",time,"this.visibleHeight",this.visibleHeight,"this.tabBarHeight",this.tabBarHeight);
    if (time==0 && this.visibleHeight>0) {
      //console.log("COLLAPSE() before this.snapAnimationFrom",this.snapAnimationFrom,"this.snapAnimationTo",this.snapAnimationTo,"this.snapAnimationTime",this.snapAnimationTime,"this.snapAnimationState",this.snapAnimationState);
      this.triggerTimer = window.setTimeout(()=>{
        this.snapAnimationFrom  = this.visibleHeight+'px';
        this.snapAnimationTo    = '0px';
        this.snapAnimationTime  = '300ms';
        this.snapAnimationState = 'snap';
        this.triggerTimer       = undefined;
        this.changeDetector.markForCheck();
        if (this.overlayRef && this.visibleHeight>0) {
          this.overlayRef.detach();
        }
        //console.log("COLLAPSE() after this.snapAnimationFrom",this.snapAnimationFrom,"this.snapAnimationTo",this.snapAnimationTo,"this.snapAnimationTime",this.snapAnimationTime,"this.snapAnimationState",this.snapAnimationState);
      });
    } else if (time>0) {
      this.triggerTimer = window.setTimeout(()=>{
        this.snapAnimationFrom  = this.visibleHeight+'px';
        this.snapAnimationTo    = '0px';
        this.snapAnimationTime  = '300ms';
        this.snapAnimationState = 'snap';
        this.triggerTimer       = undefined;
        this.changeDetector.markForCheck();
        if (this.overlayRef && this.visibleHeight>0) {
          this.overlayRef.detach();
        }
      },time);
    }
  }
}
