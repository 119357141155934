import {Injectable} from "@angular/core";
import {RouteReusableStrategyCache} from "./route-reusable-strategy-cache";

@Injectable()
export class RouteReusableStrategyHandler {

  constructor(protected routeCache: RouteReusableStrategyCache) {}

  reset(path?: string): void {
    console.log('RouteReusableStrategyHandler.reset', path);
    if (path) {
      this.routeCache.delete(path)
    } else {
      this.routeCache.clear();
    }
  }
}
