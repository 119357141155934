import {
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {OverlayRef} from '@angular/cdk/overlay';
import {IMAGE_CROPPER_OVERLAY_DATA} from "./image-cropper-overlay.tokens";
import {take} from "rxjs/operators";
import {Logger} from "core";
import {ImageCroppedEvent, ImageCropperComponent} from "../image-cropper.component";
import {Observable} from "rxjs";

export class ImageCropperOverlayRef {
  _close   = new EventEmitter<void>();
  _crop = new EventEmitter<void>();

  constructor(private overlayRef: OverlayRef) { }

  crop(): void {
    this._crop.emit();
  }

  close(): void {
    this.overlayRef.dispose();
    this._close.emit();
  }

  get onCrop(): Observable<void> {
    return this._crop.asObservable();
  }

  get onClose(): Observable<void> {
    return this._close.asObservable();
  }
}

@Component({
  selector: 'image-cropper-overlay',
  template: `
    <mat-toolbar color="primary" >
      <span *ngIf="data?.header; let header;" translate>{{header}}</span>
      <!--
      rotate has issues - the cropper is not resized properly!
      <button mat-flat-button color="primary" (click)="imageCropper.rotate()" aria-label="rotate">
        <mat-icon >autorenew</mat-icon>
        <span translate>actions.rotate</span>
      </button>
      -->
      <button *ngIf="data?.canChangeMaintainAspectRatio"
              mat-icon-button
              color="primary"
              [disableRipple]="true"
              [class.selected]="imageCropper.maintainAspectRatio"
              (click)="onTapMaintainAspectRatio()"
              aria-label="aspect ratio">
        <mat-icon>aspect_ratio</mat-icon>
      </button>
    </mat-toolbar>
    <div class="center">
      <app-image-cropper #imageCropper
                         [fileChangeEvent]="data?.fileChangeEvent"
                         [imageFile]="data?.imageFile"
                         [imageURL]="data?.imageURL"
                         [imageBase64]="data?.imageBase64"
                         [format]="data?.format"
                         [aspectRatio]="!!data?.aspectRatio ? data?.aspectRatio : 1"
                         (cropped)="cropped($event)">
      </app-image-cropper>
    </div>
    <div class="footer">
      <div #actions class="actions">
        <button mat-raised-button color="primary" (click)="close();"><span translate>actions.close</span></button>
        <button mat-raised-button color="primary" (click)="crop($event)"><span translate>actions.save</span></button>
      </div>
    </div>
  `,
  styleUrls: ['./image-cropper-overlay.component.scss']
})
export class ImageCropperOverlayComponent implements OnInit {

  @ViewChild('imageCropper', { static: true }) imageCropper: ImageCropperComponent;

  protected logger = new Logger('ImageCropperOverlayComponent');

  constructor(public overlayRef: ImageCropperOverlayRef,
              @Inject(IMAGE_CROPPER_OVERLAY_DATA) public data: any) {
    this.overlayRef.onClose.pipe(take(1)).subscribe(() => {
    })
  }

  ngOnInit(): void {
    if (this.data) {
      this.logger.debug('data', this.data);
      Object.keys(this.data).forEach(key => {
        if (key == 'aspectRatio') {
          this.imageCropper.aspectRatio = this.data.aspectRatio;
        } else if (key == 'maintainAspectRatio') {
          this.imageCropper.maintainAspectRatio = this.data.maintainAspectRatio;
        } else if (key == 'containWithinAspectRatio') {
          this.imageCropper.containWithinAspectRatio = this.data.containWithinAspectRatio;
        }
      })
    }
  }

  close() {
    this.overlayRef.close();
  }

  cropped(event: ImageCroppedEvent) {
    this.overlayRef.crop();
    if (this.data && typeof this.data.onCrop == 'function') {
      this.data.onCrop(event);
    }
  }

  crop(event: MouseEvent) {
    this.imageCropper.crop(event);
  }

  onTapMaintainAspectRatio() {
    const maintainAspectRatio = !this.imageCropper.maintainAspectRatio;
    if (maintainAspectRatio) {
      const position = this.imageCropper.cropperPosition;
      const aspectRatio = Math.abs(position.x2 - position.x1) / Math.abs(position.y2 - position.y1);
      if (aspectRatio != this.imageCropper.aspectRatio) {
        this.imageCropper.cropper.aspectRatio = aspectRatio;
      }
    }
    this.imageCropper.maintainAspectRatio = maintainAspectRatio;
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      event.stopPropagation();
      event.preventDefault();
      this.close();
    }
  }
}
