import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {HandlerInterceptor} from "./handler.interceptor";
import {ErrorHandlerInterceptor} from "./error-handler.interceptor";
import {ApiPrefixInterceptor} from "./api-prefix.interceptor";
import {HttpService} from "./http.service";
import {HttpCacheService} from "./http-cache.service";
import {CacheInterceptor} from "./cache.interceptor";
import {CorsInterceptor} from "./cors.interceptor";


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    HttpCacheService,
    ApiPrefixInterceptor,
    ErrorHandlerInterceptor,
    CacheInterceptor,
    CorsInterceptor,
    HandlerInterceptor,
    { provide:  HttpClient, useClass: HttpService }
  ],
  exports: [
  ]
})
export class CoreHttpModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreHttpModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import CoreHttp module only once.`);
    }
  }
/*
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreHttpModule,
      providers: [
        HttpCacheService,
        ApiPrefixInterceptor,
        ErrorHandlerInterceptor,
        CacheInterceptor,
        CorsInterceptor,
        {provide: HttpClient, useClass: HttpService},
      ]
    }
  }
*/
}
