import {Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {Logger, Platform} from "core";
import {QRScanner, QRScannerStatus} from "@ionic-native/qr-scanner/ngx";

@Injectable({
  providedIn: 'root'
})
export class QrScanner {

  renderer: Renderer2;
  qrScannerChrome: HTMLElement = null;

  logger = new Logger('QrScanner');

  constructor(//private renderer: Renderer2,   // injection not available in services see https://github.com/angular/angular/issues/17824
              protected qrScanner: QRScanner,
              protected platform: Platform,
              protected rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public scanQrCode(appRootTagName: string = 'app-root'): Promise<string> {
    return this.platform.ready().then(readySource => {
      if (this.platform.is('hybrid')) {
        let resolve: (text: string) => void, reject: (reason: any) => void;
        let promise = new Promise<string>((resolveScan, rejectScan) => { resolve=resolveScan; reject=rejectScan; });
        let tryScan = () => {
          let documentBackgroundColor = document.documentElement.style.backgroundColor;
          let bodyBackgroundColor = document.body.style.backgroundColor;
          let bodyWidth = document.body.style.width;
          let content = document.body.getElementsByTagName(appRootTagName)[0] as HTMLElement;
          let contentWidth = content && content.style.width;
          let cancelScan = (callback?: (status: any) => void) => {
            document.documentElement.style.backgroundColor = documentBackgroundColor;
            document.body.style.backgroundColor = bodyBackgroundColor;
            // document.body.style.width = bodyWidth;
            content && (content.style.width = contentWidth);
            if (document.body.contains(this.qrScannerChrome)) {
              document.body.removeChild(this.qrScannerChrome);
            }
            this.qrScanner.destroy()  // runs hide, cancelScan, stops video capture, removes the video preview, and deallocates as much as possible.
              .then((status: QRScannerStatus) => callback && callback(status));
          };
          this.qrScanner.prepare().then((status: QRScannerStatus) => {
            // console.log('QR SCANNER STATUS', status);
            if (status.authorized) {
              /*
               * from cordova-plugin-qrscanner spec:
               *
               * "...Instead, this plugin appends the <video> element as the final child of the <body> element,
               * and applies styling to cover the entire background."
               *
               * "To see the video preview, your application background must be transparent
               * in the areas through which the preview should show."
               */
              document.documentElement.style.backgroundColor = 'transparent';
              document.body.style.backgroundColor = 'transparent';
              // document.body.style.width = '0px';
              content && (content.style.width = '0px');
              document.body.appendChild(this.qrScannerChrome = this.createQrScannerChrome(() => {
                cancelScan();
              }));
              let subscription = this.qrScanner.scan().subscribe((text: string) => {
                //console.debug('Scanned text', text);
                // this.qrScanner.hide();   // hide camera preview
                subscription.unsubscribe(); // stop scanning
                cancelScan((status: any) => { console.debug(status); });
                resolve(text);
              });
              this.qrScanner.show();
            } else if (status.denied) {
              // camera permission was permanently denied
              // use QRScanner.openSettings() method to guide the user to the settings page
              // then they can grant the permission from there
              reject('QR Scan Denied Permanently');
            } else {
              // permission was denied, but not permanently. possible to for permission again later.
              reject('QR Scan Denied');
            }
          })
          .catch((error: any) => {
            console.error('scanQrCode. Error', error);
            reject(error);
          });
        };
        this.qrScanner.getStatus().then((status: QRScannerStatus) => {
          if(!status.scanning || this.platform.is('ios')) { // on ios the status.scanning stays true forever after first scan even after destroy - clear bug!
            tryScan();
          } else {
            reject('Already scanning');
          }
        });
        return promise;
      } else {
        throw new Error('QR Scanning not supported on '+this.platform.platforms()+' platforms');
      }
    }).catch(error => {
      this.logger.error('scanQrCode. Error:', error);
      return null;
    });
  }
/*
  public scanQrCode2(): Promise<string> {
    return this.platform.ready().then(readySource => {
      let qrScanner = (<any>window).QRScanner;
      if (this.platform.is('mobile') && qrScanner) {
        return new Promise<string>((resolve, reject) => {
          console.log('Scanning QR code!');
          let documentBackgroundColor = document.documentElement.style.backgroundColor;
          let bodyBackgroundColor = document.body.style.backgroundColor;
          let bodyWidth = document.body.style.width;
          let content = document.body.getElementsByTagName('app-realizer')[0] as HTMLElement;
          let contentWidth = content && content.style.width;
          qrScanner.scan((error: any, text: string) => {
            console.log('Scanning QR code - done!');
            if (error) {
              console.error('QR scan error: ' + JSON.stringify(error));
              reject(error);
            } else {
              console.info('QR scan success: ' + text);
              resolve(text);
            }
            document.documentElement.style.backgroundColor = documentBackgroundColor;
            document.body.style.backgroundColor = bodyBackgroundColor;
            // document.body.style.width = bodyWidth;
            content && (content.style.width = contentWidth);
            document.body.removeChild(this.qrScannerChrome);
            qrScanner.destroy((status: any) => {
              console.log(status);
              if (error) {
                console.error('QR scan destroy error: ' + JSON.stringify(error));
                reject(error);
              } else {
                console.info('QR scan destroy success: ' + text);
                resolve(text);
              }
            });
          });
          qrScanner.show();
          qrScanner.getStatus((status: any) => {
            console.log('QRScanner status' + JSON.stringify(status));
            if (status.scanning === true) {
              document.documentElement.style.backgroundColor = 'transparent';
              document.body.style.backgroundColor = 'transparent';
              // document.body.style.width = '0px';
              content && (content.style.width = '0px');
              document.body.appendChild(this.qrScannerChrome = this.createQrScannerChrome(qrScanner));
            }
          });
        });
      } else {
        throw new Error('QR Scanning not supported on '+platform+' platform');
      }
    }).catch(error => {
      this.logger.error("scanQrCode. Error:", error);
      return null;
    });
  }
*/
  protected createQrScannerChrome(onCancel: () => void) {
    let qrScannerChrome = this.renderer.createElement('div');
    let close = this.renderer.createElement('div');
    this.renderer.setProperty(close,'innerText', 'Close');
    this.renderer.appendChild(qrScannerChrome, close);
    this.renderer.addClass(qrScannerChrome, 'qr-scanner-chrome');
    this.renderer.addClass(close, 'close-button');
    this.renderer.listen(close, 'click', () => onCancel && onCancel());
    return qrScannerChrome;
  }

// copyToClipboard: function(text) {
//   var defer = $q.defer();
//   var registration = this.register(copy);
//   function copy(platform) {
//     if (platform.isNative()) {
//       var injector = angular.injector(['ng', 'ngCordova']);
//       var $cordovaClipboard = injector.get('$cordovaClipboard');
//       console.assert($cordovaClipboard);
//       $cordovaClipboard
//         .copy(text)
//         .then(function() {
//           console.log('Clipboard copy done!');
//           defer.resolve();
//         }, function() {
//           console.error('Clipboard copy failed!');
//           defer.reject();
//         });
//     } else if (platform.isReady()) {
//       var el = document.createElement('textarea');    // Create a <textarea> element
//       el.value = text;                                // Set its value to the string that you want copied
//       el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
//       el.style.position = 'absolute';
//       el.style.left = '-9999px';                      // Move outside the screen to make it invisible
//       document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
//       el.select();                                    // Select the <textarea> content
//       document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
//       document.body.removeChild(el);                  // Remove the <textarea> element
//       defer.resolve();
//     }
//   }
//   return defer.promise;
// }
//
// initializeNotifications: function(callback) {
//   var defer = $q.defer();
//   var registration = this.register(initialize);
//   function initialize(platform) {
//     if (platform.isNative()) {
//       // use cordova onesignal plugin for native notifications
//       function initialize() {
//         /*
//             https://documentation.onesignal.com/docs/cordova-sdk#section--setloglevel-
//             0 = None
//             1 = Fatal
//             2 = Errors
//             3 = Warnings
//             4 = Info
//             5 = Debug
//             6 = Verbose
//         */
//         window.plugins.OneSignal.setLogLevel({ logLevel: 4, visualLevel: 4 });
//         window.plugins.OneSignal.enableVibrate(true); // default: true
//         window.plugins.OneSignal.startInit("153b21a2-9583-4388-b943-78e9b7ec3265")
//           .handleNotificationOpened(callback.onNotificationOpened)
//           .handleNotificationReceived(callback.onNotificationReceived)
//           .endInit();
//         window.plugins.OneSignal.addSubscriptionObserver(function(state) {
//           // if (!state.from.subscribed && state.to.subscribed) {
//           //         console.log("Subscribed for OneSignal push notifications!");
//           //         state.to.userId
//           // }
//           callback.onSubscriptionChange(state);
//         });
//         defer.resolve();
//       }
//       waitFor('plugins', initialize);
//     } else {
//       // use default web app api for in-borwser notificatins
//       Notificator.get().registerCallback(callback);
//       defer.resolve();
//     }
//   }
//   return defer.promise;
// }
}
