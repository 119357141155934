import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';
import { MediaDetailsShareDataService } from '../../service/media-details-share-data.service';
import { combineLatest, takeUntil } from 'rxjs';
import { MediaDetailsSectionComponent } from '../media-details/media-details-section.component';

@Component({
  selector: 'media-details-rating',
  templateUrl: './media-details-rating.component.html',
  styleUrls: ['./media-details-rating.component.scss']
})
export class MediaDetailsRatingComponent extends MediaDetailsSectionComponent {

  // @ViewChild('actionsTemplate', { static: true }) mediaActions: TemplateRef<any>;

  viewsLabels:string[] = ['media.viewed.all','90-99%','80-89%','70-79%','60-69%','50-59%','40-49%','30-39%','20-29%','10-19%','1-9%'];

  constructor(
    protected mediaDetailsShareDataService: MediaDetailsShareDataService
  ) {
    super(mediaDetailsShareDataService);
  }

  ngOnInit(): void {
    combineLatest([
      this.mediaDetailsShareDataService.getEditMode$,
      this.mediaDetailsShareDataService.getOptions$
    ])
      .pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(([editMode, options]) => {
        this.isHidden.emit(editMode || options.mode == 'basic');
      });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // this.actionsTemplate.emit(this.mediaActions);
  }

  onSaveRating(rating: number) {
    if (this.media.rating != rating) {
      const previous = { ...this.media };
      this.media.rating = rating;
      this.mediaDetailsShareDataService.setMediaUpdate({ source: 'rating', mediaUpdate: [this.media, previous] });
      // this.changed.emit([this.media, previous]);
    }
  }

  trackByRow(index: number, data: any) {
    return `${index}.${data}`;
  }
}
