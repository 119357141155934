export interface StorageEncoder<T> {
  encode(value: T): string;
}

export interface StorageDecoder<T> {
  decode(value: string): T | undefined;
}

export type StorageCodec<T> = StorageEncoder<T> & StorageDecoder<T>;

export class JsonStorageCodec implements StorageCodec<any> {

  public encode(value: any): string {
    return JSON.stringify(value);
  }

  public decode(value: string): any {
    try {
      return JSON.parse(value);
    } catch (error) {
      return undefined;
    }
  }
}

export class StringStorageCodec implements StorageCodec<string> {
  public encode(value: string): string {
    return value;
  }

  public decode(value: string): string {
    return value;
  }
}

export const StorageCodecs = {
  JSON: new JsonStorageCodec() as StorageCodec<any>,
  STRING: new StringStorageCodec() as StorageCodec<string>,
};
