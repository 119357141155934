import {
  CachedState,
  EMPTY_ARRAY,
  EntityState,
  IoState,
  SearchableState1,
  SelectableState,
  SubscribableState,
  TypedFilterableState,
  VersionedId
} from "core";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {Media} from "./models";
import {isValidNumber} from "shared";

export interface MediaListState extends
  SearchableState1,
  TypedFilterableState,
  IoState,
  CachedState,
  SelectableState,
  EntityState<Media>,
  SubscribableState<Media> {
  parent?:Media
}

export const initialMediaListState : MediaListState = {
  term: '',
  filters: {},
  entities: [],
  indices: {},
  subscriptions: {},
  currentCacheId: null,
  previousCacheId: null,
  loading: false,
  error: null
};


export const initialMediaOnboardingListState : MediaListState = {
  term: '',
  filters: {},
  entities: [],
  indices: {},
  subscriptions: {},
  currentCacheId: null,
  previousCacheId: null,
  loading: false,
  error: null
};

export interface MediaState {
  mediaList: MediaListState;
  mediaOnboardingList: MediaListState;
}

export interface State {
  readonly media: MediaState;
}

export const selectMediaState = createFeatureSelector<State,MediaState>('media');

export const selectMediaListState = createSelector(
    selectMediaState,
    state => state.mediaList
);

export const selectMediaOnboardingState = createSelector(
    selectMediaState,
    (state):MediaListState => state.mediaOnboardingList
);

export const selectMediaEntities = createSelector(
    selectMediaListState,
  state => state.entities
);

export const selectMediaCacheId = createSelector(
    selectMediaListState,
  state => state.currentCacheId ?? ''
);

export const selectLoadedMediaEntities = createSelector(
    selectMediaListState,
  state => {
    if (state.entities.length>0) {
      let loaded = state.entities['loaded'];
      if (loaded && loaded<state.entities.length) {
        let backingArray : Media[] = (<any>state.entities).backingArray;
        let loadedArray = backingArray.slice(0,loaded);
        loadedArray['next'] = (): void => {
          console.info("load "+loaded+" element:"+state.entities[loaded]);
          if (state.entities[loaded]) {   // access next...
            console.warn("should not happen");
          }
        };
        return loadedArray;
      }
    }
    return state.entities;
  }
);

export const selectMediaEntitiesLength = createSelector(
  selectMediaEntities,
  state => state.length
);

export const selectMediaLoading = createSelector(
  selectMediaListState,
  state => state.loading
);

export const selectSelectedMediaIndex = createSelector(
    selectMediaListState,
  state => state.selectedIndex
);

export const selectMediaFilters = createSelector(
    selectMediaListState,
  (state):{[key:string]:string[]} => state.filters
);

/*
export const selectCombinedMediaFilters = createSelector(
  selectMediaFilters,
  (filters):string[] => <string[]>[].concat(Object.keys(filters).map(type=>(filters[type]??[])))
);

export const selectTypedMediaFilters = createSelector(
  selectMediaFilters,
  (filters:{[key:string]:string[]},param:{type:string, exclude:boolean}):string[] => {
    const paramType = param?.type;
    const exclude = !!param?.exclude;
    if (!paramType || exclude) {
      return <string[]>[].concat(Object.keys(filters).map(type=>(type==paramType?EMPTY_ARRAY:(filters[type]??EMPTY_ARRAY))));
    }
    return <string[]>(filters[paramType]??EMPTY_ARRAY);
  }
);*/

export const selectMediaSearchTerm = createSelector(
    selectMediaListState,
  state => state.term
);

export const selectMedia = createSelector(
    selectMediaListState,
  (state: MediaListState, mediaId: string): Media => {
    let index = state.indices[mediaId];
    if (isValidNumber(index) && index >= 0 && index < state.entities.length && !!state.entities[index]) {
      return state.entities[index];
    } else {
      const subscribed = state.subscriptions[mediaId]?.entity;
      if (subscribed?.timeUpdated) {
        return subscribed;
      } else if (state.parent?.id == mediaId) {
        return state.parent;
      }
    }
    return undefined;
  }
);

export const selectMediaError = createSelector(
    selectMediaListState,
  state => state.error
);

export const selectSubscribed = createSelector(
    selectMediaListState,
  state => Object
      .keys(state.subscriptions)
      .map(id => <VersionedId> { id,  version: state.subscriptions[id]?.entity?.version ?? 0 })
);

// export const selectSelectedMedia = createSelector(
//   selectMediaEntities,
//   selectSelectedMediaIndex,
//   (entities, selectedIndex) => {
//     return selectedIndex >=0 && selectedIndex < entities.length ? entities[selectedIndex] : null;
//   }
// );

export const selectMediaOnboardingEntities = createSelector(
    selectMediaOnboardingState,
    state => state.entities
);

export const selectMediaOnboardingLoading = createSelector(
    selectMediaOnboardingState,
    state => state.loading
);

export const selectMediaOnboarding = createSelector(
    selectMediaOnboardingState,
    (state: MediaListState, mediaId: string) => state.entities[mediaId] ?? EMPTY_ARRAY
);
