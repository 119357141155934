<div class="privacy-selector" [matMenuTriggerFor]="privacyMenu"
     (menuOpened)="button.disabled = true"
     (menuClosed)="button.disabled = false"
     [class.disabled]="disabled || readonly">
  <!-- https://github.com/angular/components/issues/14440 -->
  <span>{{(selector?.shortcut || selector?.label) | translate}}</span>
  <button #button
          mat-icon-button
          type="button"
          aria-haspopup="dialog"
          [attr.tabindex]="disabled ? -1 : tabIndex"
          [disabled]="disabled || readonly"
          [disableRipple]="disableRipple">
    <mat-icon class="icon">{{selector?.icon}}</mat-icon>
  </button>
  <mat-menu #privacyMenu="matMenu">
    <ng-container *ngFor="let selector of selectors">
      <button mat-menu-item (click)="select(selector)">
        <mat-icon matPrefix>{{selector.icon}}</mat-icon>
        <span>{{selector.label | translate}}</span>
      </button>
    </ng-container>
  </mat-menu>
</div>
