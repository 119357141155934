/* projects/group/src/lib/config/group-configuration-page/group-configuration-page.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
}
mat-vertical-stepper {
  min-width: 200px;
  max-width: 500px;
}
.json-container {
  flex: 1;
  height: 100%;
  max-width: 800px;
  padding: 24px;
}
.json-container form {
  height: 100%;
}
.json-container form mat-form-field {
  width: 100%;
  height: 90%;
}
.json-container form mat-form-field textarea {
  font-family: Roboto Mono, monospace;
  font-size: 12px;
}
.content {
  position: relative;
  flex: 1;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0px;
  overflow-y: auto;
}
.content .step-content {
  margin: 10px 0;
}
.content .step-content .separator-configuration {
  padding: 20px 0 20px 0;
}
.content .step-content .group-form-field {
  flex: 1;
  width: 100%;
}
.content media-card {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #ddd;
  margin-bottom: 8px;
}
.content media-card button.mat-mdc-icon-button {
  margin-right: 5px;
  background-color: rgba(255, 255, 255, 0.7);
}
.content media-card button.mat-mdc-icon-button.delete {
  color: var(--color-accent);
}
.content media-card button.mat-mdc-icon-button.edit {
  color: var(--color-primary);
}
.group-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.group-form > div:first-child {
  flex: 1;
}
.group-form div.location {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.group-form div.location mat-form-field {
  margin-right: 8px;
  max-width: 80px;
}
.group-form div.location mat-form-field:last-child {
  margin-right: 0;
  flex: 1;
  max-width: inherit;
}
.color-form {
  max-width: 300px;
  overflow: hidden;
}
.color-form .color-picker {
  display: inline-block;
}
.color-form .color-input {
  margin-top: -23px;
  vertical-align: top;
  display: inline-block;
}
.color-form .field-label {
  display: block;
  padding: 0;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.54);
}
.summary-text {
  padding-bottom: 24px;
  font-family:
    Roboto,
    "Helvetica Neue",
    sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.group-button-bar {
  position: absolute;
  bottom: 16px;
  right: 24px;
}
.group-button-bar button.mat-mdc-mini-fab {
  margin: 0 4px;
}
:host-context(.color-scheme-dark) {
  background-color: #181818;
}
:host-context(.color-scheme-dark) .field-label {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) ::ng-deep ngx-colors .app-color-picker .preview {
  background-color: #E5E5E5 !important;
}
/*# sourceMappingURL=group-configuration-page.component-A5BQAM62.css.map */
