<app-toolbar #toolbar
             *ngIf="hasToolbar"
             (onLeftClicked)="leftButtonAction()"
             (onRightClicked)="rightButtonAction()">
  <span *ngIf="title$ | async as title" class="title breadcrumb" [innerHTML]="title | safeHtml"></span>
</app-toolbar>
<div class="content">
  <ng-container *ngTemplateOutlet="pageContentTemplate; context: {}"></ng-container>
</div>
<ng-container *ngTemplateOutlet="primaryButtonTemplate"></ng-container>
