/* angular:styles/component:css;40fb4cbebe55379eb8f84007855d0a2aa15e64cb764ad12344011b7366369afd;/Users/mbratoev/Project/fbgroup/client.ng/projects/shared/src/lib/components/test/test.component.ts */
.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c2eff5;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}
.main::-webkit-scrollbar {
  display: none;
}
/*# sourceMappingURL=test.component-BAPUJU7O.css.map */
