/* projects/filter/src/lib/components/topic-filter/topic-tree/topic-tree.component.scss */
:host {
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
div.content {
  flex: 1;
  overflow-y: auto;
}
mat-tree {
  padding: 8px 8px 16px;
  background-color: transparent;
}
.hidden {
  display: none;
}
::ng-deep .mat-checkbox-label {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
/*# sourceMappingURL=topic-tree.component-HIN32HM7.css.map */
