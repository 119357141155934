<div (onAttach)="ngOnAttach()" (onDetach)="ngOnDetach()" (swipedown)="onClose()">
  <template #viewerContainer></template>
  <div *ngIf="toolbarDisplayed()"
       class="toolbar"
       [class.blur]="visibleActionIds.length>0"
       [class.hide_action_background]="hideActionBackground">
    <div class="actions">
<!--      <div class="action">-->
<!--        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>-->
<!--      </div>-->
      <ng-container *ngFor="let action of viewerMedia?.actions; trackBy: trackByMediaAction">
        <div [action]="action"
             (onAction)="onAction(action)"
             [@animation]="animation(action.id)"
             class="action">
          <ng-container *ngTemplateOutlet="action.template || defaultActionTemplate; context: { action: action }"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #defaultActionTemplate let-action="action">
    <button mat-raised-button color="primary action-button">
      <span *ngIf="action.label" translate>{{action.label}}</span>
      <span *ngIf="!action.label" translate>media.actions.{{action?.id}}</span>
    </button>
  </ng-template>
  <ng-template #snapshotActionTemplate>
    <button mat-icon-button class="snapshot action-button" (click)="onTakeSnapshot()">
      <mat-icon>photo_camera</mat-icon>
    </button>
  </ng-template>
  <ng-template #downloadActionTemplate>
<!--    <div #download class="download-host">-->
      <button #download mat-icon-button class="download action-button" (click)="onDownload(download)">
        <mat-icon>download</mat-icon>
      </button>
<!--    </div>-->
  </ng-template>
  <ng-template #closeActionTemplate>
    <button mat-icon-button class="cancel action-button" (click)="onClose()">
      <mat-icon>cancel</mat-icon>
    </button>
  </ng-template>
</div>
