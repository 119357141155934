import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'asEmojiFlag'
})
export class EmojiFlagPipe implements PipeTransform {

  constructor() {}

  transform(value:string, args?: any): any {
    let flag = this.flags[(value || '').toUpperCase()];
    return !!flag ? flag : value;
  }

  // taken from angularjs project https://github.com/zwacky/angular-emoji-flags/blob/master/src/emoji-flags.service.ts
  flags = {

    /* country: 'Afghanistan' */
    AF: '🇦🇫',

    /* country: 'Albania' */
    AL: '🇦🇱',

    /* country: 'Algeria' */
    DZ: '🇩🇿',

    /* country: 'American Samoa' */
    AS: '🇦🇸',

    /* country: 'Andorra' */
    AD: '🇦🇩',

    /* country: 'Angola' */
    AO: '🇦🇴',

    /* country: 'Anguilla' */
    AI: '🇦🇮',

    /* country: 'Antarctica' */
    AQ: '🇦🇶',

    /* country: 'Argentina' */
    AR: '🇦🇷',

    /* country: 'Armenia' */
    AM: '🇦🇲',

    /* country: 'Aruba' */
    AW: '🇦🇼',

    /* country: 'Australia' */
    AU: '🇦🇺',

    /* country: 'Austria' */
    AT: '🇦🇹',

    /* country: 'Azerbaijan' */
    AZ: '🇦🇿',

    /* country: 'Bahamas' */
    BS: '🇧🇸',

    /* country: 'Bahrain' */
    BH: '🇧🇭',

    /* country: 'Bangladesh' */
    BD: '🇧🇩',

    /* country: 'Barbados' */
    BB: '🇧🇧',

    /* country: 'Belarus' */
    BY: '🇧🇾',

    /* country: 'Belgium' */
    BE: '🇧🇪',

    /* country: 'Belize' */
    BZ: '🇧🇿',

    /* country: 'Benin' */
    BJ: '🇧🇯',

    /* country: 'Bermuda' */
    BM: '🇧🇲',

    /* country: 'Bhutan' */
    BT: '🇧🇹',

    /* country: 'Bolivia' */
    BO: '🇧🇴',

    /* country: 'Botswana' */
    BW: '🇧🇼',

    /* country: 'Bouvet Island' */
    BV: '🇧🇻',

    /* country: 'Brazil' */
    BR: '🇧🇷',

    /* country: 'British Indian Ocean Territory' */
    IO: '🇮🇴',

    /* country: 'Bulgaria' */
    BG: '🇧🇬',

    /* country: 'Burkina Faso' */
    BF: '🇧🇫',

    /* country: 'Burundi' */
    BI: '🇧🇮',

    /* country: 'Cambodia' */
    KH: '🇰🇭',

    /* country: 'Cameroon' */
    CM: '🇨🇲',

    /* country: 'Canada' */
    CA: '🇨🇦',

    /* country: 'Cape Verde' */
    CV: '🇨🇻',

    /* country: 'Cayman Islands' */
    KY: '🇰🇾',

    /* country: 'Central African Republic' */
    CF: '🇨🇫',

    /* country: 'Chad' */
    TD: '🇹🇩',

    /* country: 'Chile' */
    CL: '🇨🇱',

    /* country: 'China' */
    CN: '🇨🇳',

    /* country: 'Christmas Island' */
    CX: '🇨🇽',

    /* country: 'Colombia' */
    CO: '🇨🇴',

    /* country: 'Comoros' */
    KM: '🇰🇲',

    /* country: 'Cook Islands' */
    CK: '🇨🇰',

    /* country: 'Costa Rica' */
    CR: '🇨🇷',

    /* country: 'Cuba' */
    CU: '🇨🇺',

    /* country: 'Cyprus' */
    CY: '🇨🇾',

    /* country: 'Czech Republic' */
    CZ: '🇨🇿',

    /* country: 'Denmark' */
    DK: '🇩🇰',

    /* country: 'Djibouti' */
    DJ: '🇩🇯',

    /* country: 'Dominica' */
    DM: '🇩🇲',

    /* country: 'Dominican Republic' */
    DO: '🇩🇴',

    /* country: 'Ecuador' */
    EC: '🇪🇨',

    /* country: 'Egypt' */
    EG: '🇪🇬',

    /* country: 'El Salvador' */
    SV: '🇸🇻',

    /* country: 'Equatorial Guinea' */
    GQ: '🇬🇶',

    /* country: 'Eritrea' */
    ER: '🇪🇷',

    /* country: 'Estonia' */
    EE: '🇪🇪',

    /* country: 'Ethiopia' */
    ET: '🇪🇹',

    /* country: 'Faroe Islands' */
    FO: '🇫🇴',

    /* country: 'Fiji' */
    FJ: '🇫🇯',

    /* country: 'Finland' */
    FI: '🇫🇮',

    /* country: 'France' */
    FR: '🇫🇷',

    /* country: 'French Guiana' */
    GF: '🇬🇫',

    /* country: 'French Polynesia' */
    PF: '🇵🇫',

    /* country: 'French Southern Territories' */
    TF: '🇹🇫',

    /* country: 'Gabon' */
    GA: '🇬🇦',

    /* country: 'Gambia' */
    GM: '🇬🇲',

    /* country: 'Georgia' */
    GE: '🇬🇪',

    /* country: 'Germany' */
    DE: '🇩🇪',

    /* country: 'Ghana' */
    GH: '🇬🇭',

    /* country: 'Gibraltar' */
    GI: '🇬🇮',

    /* country: 'Greece' */
    GR: '🇬🇷',

    /* country: 'Greenland' */
    GL: '🇬🇱',

    /* country: 'Grenada' */
    GD: '🇬🇩',

    /* country: 'Guadeloupe' */
    GP: '🇬🇵',

    /* country: 'Guam' */
    GU: '🇬🇺',

    /* country: 'Guatemala' */
    GT: '🇬🇹',

    /* country: 'Guinea' */
    GN: '🇬🇳',

    /* country: 'Guinea-Bissau' */
    GW: '🇬🇼',

    /* country: 'Guyana' */
    GY: '🇬🇾',

    /* country: 'Haiti' */
    HT: '🇭🇹',

    /* country: 'Honduras' */
    HN: '🇭🇳',

    /* country: 'Hong Kong' */
    HK: '🇭🇰',

    /* country: 'Hungary' */
    HU: '🇭🇺',

    /* country: 'Iceland' */
    IS: '🇮🇸',

    /* country: 'India' */
    IN: '🇮🇳',

    /* country: 'Indonesia' */
    ID: '🇮🇩',

    /* country: 'Iraq' */
    IQ: '🇮🇶',

    /* country: 'Ireland' */
    IE: '🇮🇪',

    /* country: 'Israel' */
    IL: '🇮🇱',

    /* country: 'Italy' */
    IT: '🇮🇹',

    /* country: 'Jamaica' */
    JM: '🇯🇲',

    /* country: 'Japan' */
    JP: '🇯🇵',

    /* country: 'Jordan' */
    JO: '🇯🇴',

    /* country: 'Kazakhstan' */
    KZ: '🇰🇿',

    /* country: 'Kenya' */
    KE: '🇰🇪',

    /* country: 'Kiribati' */
    KI: '🇰🇮',

    /* country: 'South Korea' */
    KR: '🇰🇷',

    /* country: 'Kuwait' */
    KW: '🇰🇼',

    /* country: 'Kyrgyzstan' */
    KG: '🇰🇬',

    /* country: 'Latvia' */
    LV: '🇱🇻',

    /* country: 'Lebanon' */
    LB: '🇱🇧',

    /* country: 'Lesotho' */
    LS: '🇱🇸',

    /* country: 'Liberia' */
    LR: '🇱🇷',

    /* country: 'Liechtenstein' */
    LI: '🇱🇮',

    /* country: 'Lithuania' */
    LT: '🇱🇹',

    /* country: 'Luxembourg' */
    LU: '🇱🇺',

    /* country: 'Macau' */
    MO: '🇲🇴',

    /* country: 'Madagascar' */
    MG: '🇲🇬',

    /* country: 'Malawi' */
    MW: '🇲🇼',

    /* country: 'Malaysia' */
    MY: '🇲🇾',

    /* country: 'Maldives' */
    MV: '🇲🇻',

    /* country: 'Mali' */
    ML: '🇲🇱',

    /* country: 'Malta' */
    MT: '🇲🇹',

    /* country: 'Marshall Islands' */
    MH: '🇲🇭',

    /* country: 'Martinique' */
    MQ: '🇲🇶',

    /* country: 'Mauritania' */
    MR: '🇲🇷',

    /* country: 'Mauritius' */
    MU: '🇲🇺',

    /* country: 'Mayotte' */
    YT: '🇾🇹',

    /* country: 'Mexico' */
    MX: '🇲🇽',

    /* country: 'Monaco' */
    MC: '🇲🇨',

    /* country: 'Mongolia' */
    MN: '🇲🇳',

    /* country: 'Montserrat' */
    MS: '🇲🇸',

    /* country: 'Morocco' */
    MA: '🇲🇦',

    /* country: 'Mozambique' */
    MZ: '🇲🇿',

    /* country: 'Myanmar' */
    MM: '🇲🇲',

    /* country: 'Namibia' */
    NA: '🇳🇦',

    /* country: 'Nauru' */
    NR: '🇳🇷',

    /* country: 'Nepal' */
    NP: '🇳🇵',

    /* country: 'Netherlands' */
    NL: '🇳🇱',

    /* country: 'New Caledonia' */
    NC: '🇳🇨',

    /* country: 'New Zealand' */
    NZ: '🇳🇿',

    /* country: 'Nicaragua' */
    NI: '🇳🇮',

    /* country: 'Niger' */
    NE: '🇳🇪',

    /* country: 'Nigeria' */
    NG: '🇳🇬',

    /* country: 'Niue' */
    NU: '🇳🇺',

    /* country: 'Norfolk Island' */
    NF: '🇳🇫',

    /* country: 'Northern Mariana Islands' */
    MP: '🇲🇵',

    /* country: 'Norway' */
    NO: '🇳🇴',

    /* country: 'Oman' */
    OM: '🇴🇲',

    /* country: 'Pakistan' */
    PK: '🇵🇰',

    /* country: 'Palau' */
    PW: '🇵🇼',

    /* country: 'Panama' */
    PA: '🇵🇦',

    /* country: 'Papua New Guinea' */
    PG: '🇵🇬',

    /* country: 'Paraguay' */
    PY: '🇵🇾',

    /* country: 'Peru' */
    PE: '🇵🇪',

    /* country: 'Philippines' */
    PH: '🇵🇭',

    /* country: 'Poland' */
    PL: '🇵🇱',

    /* country: 'Portugal' */
    PT: '🇵🇹',

    /* country: 'Puerto Rico' */
    PR: '🇵🇷',

    /* country: 'Qatar' */
    QA: '🇶🇦',

    /* country: 'Romania' */
    RO: '🇷🇴',

    /* country: 'Russia' */
    RU: '🇷🇺',

    /* country: 'Rwanda' */
    RW: '🇷🇼',

    /* country: 'Samoa' */
    WS: '🇼🇸',

    /* country: 'San Marino' */
    SM: '🇸🇲',

    /* country: 'Saudi Arabia' */
    SA: '🇸🇦',

    /* country: 'Senegal' */
    SN: '🇸🇳',

    /* country: 'Seychelles' */
    SC: '🇸🇨',

    /* country: 'Sierra Leone' */
    SL: '🇸🇱',

    /* country: 'Singapore' */
    SG: '🇸🇬',

    /* country: 'Slovakia' */
    SK: '🇸🇰',

    /* country: 'Slovenia' */
    SI: '🇸🇮',

    /* country: 'Solomon Islands' */
    SB: '🇸🇧',

    /* country: 'Somalia' */
    SO: '🇸🇴',

    /* country: 'South Africa' */
    ZA: '🇿🇦',

    /* country: 'Spain' */
    ES: '🇪🇸',

    /* country: 'Sri Lanka' */
    LK: '🇱🇰',

    /* country: 'Sudan' */
    SD: '🇸🇩',

    /* country: 'Suriname' */
    SR: '🇸🇷',

    /* country: 'Swaziland' */
    SZ: '🇸🇿',

    /* country: 'Sweden' */
    SE: '🇸🇪',

    /* country: 'Switzerland' */
    CH: '🇨🇭',

    /* country: 'Taiwan' */
    TW: '🇹🇼',

    /* country: 'Tajikistan' */
    TJ: '🇹🇯',

    /* country: 'Thailand' */
    TH: '🇹🇭',

    /* country: 'Timor-Leste' */
    TL: '🇹🇱',

    /* country: 'Togo' */
    TG: '🇹🇬',

    /* country: 'Tokelau' */
    TK: '🇹🇰',

    /* country: 'Tonga' */
    TO: '🇹🇴',

    /* country: 'Tunisia' */
    TN: '🇹🇳',

    /* country: 'Turkey' */
    TR: '🇹🇷',

    /* country: 'Turkmenistan' */
    TM: '🇹🇲',

    /* country: 'Tuvalu' */
    TV: '🇹🇻',

    /* country: 'Uganda' */
    UG: '🇺🇬',

    /* country: 'Ukraine' */
    UA: '🇺🇦',

    /* country: 'United Arab Emirates' */
    AE: '🇦🇪',

    /* country: 'United Kingdom' */
    GB: '🇬🇧',

    /* country: 'United States' */
    US: '🇺🇸',

    /* country: 'Uruguay' */
    UY: '🇺🇾',

    /* country: 'Uzbekistan' */
    UZ: '🇺🇿',

    /* country: 'Vanuatu' */
    VU: '🇻🇺',

    /* country: 'Venezuela' */
    VE: '🇻🇪',

    /* country: 'Vietnam' */
    VN: '🇻🇳',

    /* country: 'Western Sahara' */
    EH: '🇪🇭',

    /* country: 'Yemen' */
    YE: '🇾🇪',

    /* country: 'Zambia' */
    ZM: '🇿🇲',

    /* country: 'Zimbabwe' */
    ZW: '🇿🇼'
  };
}
