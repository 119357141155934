import {ChangeDetectionStrategy, Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ENVIRONMENT, loadScript, Logger, Platform} from "core";
import {PropertiesService} from "properties";
import Hammer from 'hammerjs';
import {DOCUMENT} from "@angular/common";
import {SessionTokenService} from "session";
import {MediaService} from "../../../../service/media.service";
import {MediaViewer} from "../../media-viewer";

// declare var Hammer: any;

// https://github.com/mozilla/pdf.js/wiki/setup-pdf.js-in-a-website
// https://blog.angularindepth.com/gestures-in-an-angular-application-dde71804c0d0

@Component({
  selector: 'pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerComponent extends MediaViewer {

  protected static HAMMERJS_SRC = 'https://cdnjs.cloudflare.com/ajax/libs/hammer.js/2.0.8/hammer.min.js';
  @Input() public openFile: boolean = false;
  @Input() public download: boolean = false;
  @Input() public viewBookmark: boolean = false;
  @Input() public pdfJsPath = `${location.origin}/assets/pdfjs`;

  @ViewChild('iframe', { static: true }) iframe: ElementRef<HTMLIFrameElement>;
  @ViewChild('pinchZone') pinchZone: ElementRef<HTMLDivElement>;

  protected tracked: boolean;
  protected logger = new Logger('PdfViewerComponent');

  constructor(protected elementRef: ElementRef,
              protected http: HttpClient,
              protected mediaService : MediaService,
              protected platform: Platform,
              protected sessionTokenService: SessionTokenService,
              public propertiesService: PropertiesService,
              @Inject(DOCUMENT) protected document: any,
              @Inject(ENVIRONMENT) protected environment: any) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() { window.setTimeout(() => this.load()) }

  /*
  @HostListener('pinch')
  onPinch(event: any) {
    console.info('PINCH', event)
  }
  */

  load() {
    const fileUrl = this.media && this.media.links && this.media.links.length>0 ? this.media.links[0].link : null;
    // const fileUrl = 'compressed.tracemonkey-pldi-09.pdf'; // for test!
    if (!(this.iframe.nativeElement.hidden = !fileUrl)) {
      const file = `${this.sessionTokenService.rewrite(`${this.environment.serverUrl}${fileUrl}`)}`;
      let src = `${this.pdfJsPath}/web/viewer.html?file=${encodeURIComponent(file)}`;
      if (typeof this.openFile !== 'undefined') {
        src += `&openFile=${this.openFile}`;
      }
      if (typeof this.download !== 'undefined') {
        src += `&download=${this.download}`;
      }
      if (typeof this.viewBookmark !== 'undefined') {
        src += `&viewBookmark=${this.viewBookmark}`;
      }
      this.iframe.nativeElement.src = src;
      this.loaded().then((result) => {
        if (result) {
          this.mountActionsDisplayTrigger(this.iframe.nativeElement.contentWindow.document.body);
          if (!this.tracked && this.media && !this.media.touched) {
            this.tracked = true;
            // this.logger.debug('Tracking...');
            this.track().catch((error) => {
              this.logger.error('Tracking Error', error);
              this.tracked = false;
            });
          }
          this.mountActionsDisplayTrigger(this.iframe.nativeElement.contentWindow.document.body);
          const promise = typeof window.Hammer == 'undefined' ? loadScript(document, PdfViewerComponent.HAMMERJS_SRC) : Promise.resolve();
          return promise.then(() => {
            // Pinch to zoom on touch screen devices is not supported by PDFJS Viewer
            // https://github.com/mozilla/pdf.js/issues/2582
            // https://gist.github.com/squallstar/1d720e93eabe7f60dc61b547d2c19228
            // https://github.com/mozilla/pdf.js/issues/10297
            // Events stop on pdf reload:
            // https://stackoverflow.com/questions/53450543/pdf-js-reloadeing-re-rendering-due-to-zoom-stops-js-events
            const iframe = this.iframe.nativeElement;
            const hammerTime = (element: any = iframe.contentWindow.document.body, previousScale = 1, hammerId = new Date().getTime()) => {
              const logger = new Logger(`Hammer.${hammerId}`);
              logger.debug('START [ previousScale=' + previousScale + ' ]');
              let hammer = new Hammer(element, {domEvents: true});
              const subscription = this.onDestroy$.subscribe(() => hammer.destroy());
              hammer.get('pinch').set({enable: true});
              hammer.on('pinchin pinchout', function onPinch(event: any) {
                logger.debug('PINCH EVENT', event, event.type);
                event.preventDefault();
                const scale = event.scale * previousScale;
                const delta = scale - previousScale;
                logger.debug('PINCH SCALE', 'scale', scale, 'previous', previousScale, 'delta', delta);
                if (Math.abs(delta) > 0.1) {
                  subscription.unsubscribe();
                  hammer.off('pinchin pinchout', onPinch);
                  hammer.destroy();
                  const type = delta > 0 ? 'zoomin' : 'zoomout';
                  logger.debug('ZOOM TYPE', type, scale);
                  iframe.contentWindow.postMessage({type: type, data: {}}, '*');
                  this.loaded().then(() => { // zoom triggers load sometimes
                    window.setTimeout(() => hammerTime(element, scale));
                  });
                }
              }.bind(this));
            };

            // on ios browser pdf zoom works too but default browser gesture handling
            // destroys user experience because the whole page is zoomed also -
            // this is by design and not related with hammer pinch detection.
            // ios cordova app is working properly
            if (!this.platform.is('ios') || this.platform.is('hybrid')) {
              hammerTime();
              if (this.pinchZone) { // used for testing only!
                hammerTime(this.pinchZone.nativeElement);
              }
            }
          });
        }
      })
      .catch((error) => this.logger.error('iframe load detection error', error))
    }
  }

  loaded(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let iframe = this.iframe.nativeElement;
      let contentWindow = iframe.contentWindow;
      let complete = contentWindow.document.readyState === 'complete'; // possible CORS problem with cordova apps if we load pdfjs from server: Blocked a frame with origin "https://www.groupmanager.info" from accessing a cross-origin frame.
      let blank = 'about:blank';
      const isHrefValid = () => {
        let src  = iframe.src;
        let href = contentWindow.location.href;
        return href !== blank || src === blank || src === '';
      };
      const onIframeLoaded = () => {
        if (isHrefValid()) {
          iframe.removeEventListener('load', onIframeLoaded);
          try {
            let width = parseInt(iframe.style.width, 10);
            resolve(width !== 0);
          } catch (error) {
            reject(error);
          }
        } else {
          reject('invalid href!');
        }
      };
      if (complete && isHrefValid()) { // chrome reports complete but href can be blank
        onIframeLoaded();
      } else {
        iframe.addEventListener('load', onIframeLoaded);
      }
    });
  }

  track(): Promise<void> {
    return this.mediaService.markPlayed(this.media.id);
    /*
    const request = this.http.post(
      `/v1.0/media/reaction/${this.media.id}`,
      { played: '[]' }
    );
    request.subscribe((response: Media) => {
      if (response) {
        this.mediaService.played(this.media.id, {
          played: response.played,
          playedTime: response.playedTime,
          completed: response.completed,
          touched: response.touched
        });
      } else {
        throw new Error('Empty server response');
      }
    });
    return request.toPromise().then(() => void 0);*/
  }
}
