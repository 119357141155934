import {Component, Inject, InjectionToken, OnInit, ViewChild} from '@angular/core';
import {Logger} from "core";
import {MediaViewerContext} from "../media-viewer-context";
import {MediaViewerComponent} from "../containers/media-viewer/media-viewer.component";
import {Media, MediaAction} from "../../../store/models";
import {MediaViewerOverlayRef} from "./media-viewer-overlay-ref";

export const MEDIA_VIEWER_OVERLAY_DATA = new InjectionToken<any>('MEDIA_VIEWER_OVERLAY_DATA');

@Component({
  selector: 'media-viewer-overlay',
  template: `
    <media-viewer (closed)="onClose()"
                  (completed)="onCompleted($event)"
                  (action)="onAction($event)">
      <!--
      <ng-template #controls>
        <button mat-raised-button color="primary">
          <span translate>actions.contact</span>
        </button>
      </ng-template>
      -->
    </media-viewer>
   `,
  styles: [`
    :host {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  `]
})
export class MediaViewerOverlayComponent implements OnInit {

  @ViewChild(MediaViewerComponent, { static: true }) protected _viewer: MediaViewerComponent;
  protected logger = new Logger('MediaViewerOverlayComponent');

  constructor(public overlayRef: MediaViewerOverlayRef,
              @Inject(MEDIA_VIEWER_OVERLAY_DATA)
              public data: MediaViewerContext = { media: () => null }) {
  }

  ngOnInit(): void {
    if (this.data && this.data.actionsTemplate) {
      this._viewer.actionsTemplate = this.data.actionsTemplate;
    }
  }

  onClose() {
    this.overlayRef.close();
  }

  onCompleted(event: { media: Media }) {
    this.overlayRef.complete = true;
    if (this.data && this.data.onComplete) {
      this.data.onComplete(event.media);
    }
  }

  onAction(event: { media: Media, action: MediaAction }) {
    if (this.data && this.data.onAction) {
      this.data.onAction(event.action);
    }
  }

  get viewer(): MediaViewerComponent {
    return this._viewer;
  }
}
