<mat-action-list>
  <ng-container *ngFor="let section of (menuService.filterSections$ | async); trackBy: trackSection.bind(this)">
    <div *ngIf="section.visible && section.enabled">
      <mat-list-item tabindex="-1"
                     [ngClass]="section.id==menuService.menuSelection.section ? 'selected' : ''"
                     [routerLink]="sectionLink(section)">
        <mat-icon matListItemIcon>{{section.icon}}</mat-icon>
        <div mat-line>{{section.title | translate}}</div>
        <mat-icon end *ngIf="isSectionSelected(section)" matListItemIcon>chevron_right</mat-icon>
      </mat-list-item>
      <div *ngIf="badge" class="badge-position">
<!--          <div class="badge">{{badge}}</div>-->
        <ng-container *ngIf="section.badge$ as badge$"
                      [ngTemplateOutlet]="badgeTemplate"
                      [ngTemplateOutletContext]="{ badge$ }">
        </ng-container>
      </div>
    </div>
    <div *ngIf="areSectionFiltersAvailable(section)">
      <div class="filter-section" *ngIf="areSectionFiltersVisible(section)"
           [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '100px' }}">
        <div>
          <ng-container *ngFor="let filter of section.filters.all; trackBy: trackFilter.bind(this)">
            <ng-container *ngIf="filter.visible && filter.enabled">
              <div class="filter"
                [class.selected]="isFilterSelected(section,filter)"
                [class.unselectable]="!filter.visible || !filter.enabled"
                (click)="filterSelected(section,filter)">{{filter.label | translate}}
                <ng-container *ngTemplateOutlet="badgeTemplate; context: {badge$: badge(section, filter)}"></ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <!--
    <mat-list-item
      [routerLink]="'/start'">
      <mat-icon matListIcon>play_circle_outline</mat-icon>
      <div mat-line translate>module.start</div>
    </mat-list-item>
    -->
  </ng-container>
  <ng-content></ng-content>
  <!--
  <mat-list-item
    [routerLink]="'/start'">
    <mat-icon matListItemIcon>play_circle_outline</mat-icon>
    <div mat-line translate>module.start</div>
  </mat-list-item>
  -->
</mat-action-list>
<ng-template #badgeTemplate let-badge$="badge$">
  <ng-container *ngIf="(badge$ | async) as badge">
    <ng-container [ngSwitch]="badgeType(badge)">
        <mat-icon *ngSwitchCase="'icon'" class="badge">{{badge.icon}}</mat-icon>
        <div *ngSwitchDefault class="badge" [ngClass]="{ circle: badge.label=='!' }">{{badge.label}}</div>
    </ng-container>
  </ng-container>
</ng-template>
