import {Inject, Injectable} from '@angular/core';
import {APP_ID} from "../../app/app.provider";
import {BaseStorage} from "./base.storage";
import {StorageCodecs} from "../storage.codec";

export class IsolatedStorage extends BaseStorage<string> {

  protected namespace: string;

  constructor(protected readonly storage: Storage,
              @Inject(APP_ID) protected appId: number) {
    super(storage, StorageCodecs.STRING);
    this.namespace = this.appId+'.';
  }

  public has(key: string): boolean {
    return super.has(this.namespace+key);
  }

  public get(key: string): string {
    return super.get(this.namespace+key);
  }

  public set(key: string, value: string): void {
    return super.set(this.namespace+key, value);
  }

  public remove(key: string): void {
    super.remove(this.namespace+key);
  }
}
