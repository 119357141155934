import {AfterContentInit, Directive, ElementRef, Input} from '@angular/core';

// https://www.codementor.io/yomateo/auto-focus-with-angular-7-the-directive-osfcl7rrv
// see search-bar.component.ts & html
@Directive({
  selector: '[autoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {
  protected _focus: boolean;
  protected _autoFocus = true;
  @Input() public set autoFocus(focus: boolean) {
    this._focus = focus;
    if (!this._autoFocus) {
      this.focus();
    }
  }

  public constructor(private elementRef: ElementRef) {
  }

  public ngAfterContentInit() {
    window.setTimeout(() => {
      this._autoFocus = false;
      this.focus();
    }, 500);
  }

  protected focus() {
    const element = this.elementRef.nativeElement;
    if (element) {
      if (this._focus) {
        element.focus()
      } else  {
        element.blur();
      }
    }
  }
}
