<ng-template #videoTemplate let-id="id" let-controls="controls">
  <!--<video class="video-js vjs-default-skin vjs-big-play-centered  vjs-16-9"-->
  <!--
  <video class="video-js vjs-default-skin vjs-big-play-centered fill"
         id="{{id}}"
         controls="{{controls}}"
         autoplay="{{autoPlay}}"
         [attr.playsinline]="inline"
         [attr.webkit-playsinline]="inline"
         preload="auto">
  </video>
  -->
  <video #video class="video-js vjs-default-skin vjs-big-play-centered fill"
         [id]="id"
         [preload]="preload"
         [controls]="controls"
         [muted]="muted"
         [attr.playsinline]="inline"
         [attr.webkit-playsinline]="inline"
         [attr.crossorigin]="crossOrigin">
  </video>
</ng-template>
