import {Action, createAction, props} from "@ngrx/store";
import {Media} from "./models";

export const mediaLoadRequestAction = createAction(
  'media.load.request'
);

export const mediaSubscribeAction = createAction(
  'media.subscribe',
  props<{
    id: string,
    media?: Media // provided by reducer
  }>()
);

export const mediaUnsubscribeAction = createAction(
  'media.unsubscribe',
  props<{
    id: string,
    media?: Media // provided by reducer
  }>()
);

export const mediaUpdateAction = createAction(
  'media.update',
  props<{
    correlationId?: string,
    media: Media,
    mediaPath?: string[],
    deleteOrphans?: boolean,
    previous?: Media
  }>()
);

export const mediaUpdateDoneAction = createAction(
  'media.update.done',
  props<{
    correlationId?: string,
    media: Media
  }>()
);

export const mediaUpdateFailedAction = createAction(
  'media.update.failed',
  props<{
    correlationId?: string,
    media: Media,
    error: any
  }>()
);

export const mediaSelectAction = createAction(
  'media.select',
  props<{
    selectedIndex: number
  }>()
);

export const mediaSetTypedFiltersAction = createAction(
  'media.set.typed.filters',
  props<{
    filters: {[type:string]:string[]},
    remove?:(type:string,filters:string[])=>boolean
  }>()
);

export const mediaUpdateSearchTermAction = createAction(
  'media.update.searchTerm',
  props<{
    term: string
  }>()
);

export const mediaUpdateUserAction = createAction(
  'media.update.user',
  props<{
    userId: string,
    userVersion: number
  }>()
);

export const mediaLoadPageAction = createAction(
  'media.load.page',
  props<{
    cacheId : string,
    index : number,
    size : number
  }>()
);

export const mediaLoadPageDoneAction = createAction(
  'media.load.page.done',
  props<{
    cacheId : string,
    index : number,
    data : Media[],
    total : number,
    parent?: Media,
    dispatch : (action:Action)=>void
  }>()
);

export const mediaLoadPageFailedAction = createAction(
  'media.load.page.failed',
  props<{
    cacheId : string,
    index : number,
    size : number,
    error: any
  }>()
);

export const mediaCacheUpsertAction = createAction(
  'media.cache.upsert',
  props<{
    cacheId: string;
    media: Media;
    afterId?: string;
    beforeId?: string;
  }>()
);

export const mediaCacheRemoveAction = createAction(
  'media.cache.remove',
  props<{
    cacheId: string;
    media?: Pick<Media,"id">;
  }>()
);

export const mediaOnboardingLoadAction = createAction(
    'media.onboarding.load',
    props<{
     language: string,
     contactId?: string
    }>()
);

export const mediaOnboardingLoadDoneAction = createAction(
    'media.onboarding.load.done',
    props<{
      //cacheId : string,
      language: string
      contactId?: string
      data : Media[],
      dispatch : (action:Action)=>void
    }>()
);

export const mediaOnboardingLoadFailedAction = createAction(
    'media.onboarding.load.failed',
    props<{
      //cacheId : string,
      language: string,
      contactId?: string
      error: any
    }>()
);

export const mediaOnboardingUpdateAction = createAction(
  'media.onboarding.update',
  props<{
    correlationId?: string,
    media: Media,
    previous?: Media
  }>()
);
export const mediaOnboardingUpdateDoneAction = createAction(
  'media.onboarding.update.done',
  props<{
    correlationId?: string,
    media: Media
  }>()
);

export const mediaOnboardingUpdateFailedAction = createAction(
  'media.onboarding.update.failed',
  props<{
    correlationId?: string,
    media: Media,
    error: any
  }>()
);


