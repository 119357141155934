import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ENVIRONMENT, GroupMembership, Logger, Platform} from "core";
import {InAppBrowser, InAppBrowserObject} from "@ionic-native/in-app-browser/ngx";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-partner-id',
  templateUrl: './partner-id.component.html',
  styleUrls: ['./partner-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerIdComponent implements OnInit, OnChanges {

  @Input() membership: GroupMembership;
  @Input() hasCloneLink = false;
  @Input() hasMenu = false;
  @Output() authTokenRequest: EventEmitter <{ onResponse: (token: Promise<string>) => any }> = new EventEmitter();
  partnerId: string;
  cssClass: string;

  partnerUrl: string;
  shopUrl: string;

  @HostBinding('style') style: string;

  browsers: InAppBrowserObject[] = [];

  logger = new Logger('PartnerIdComponent');

  constructor(public platform: Platform,
              protected inAppBrowser: InAppBrowser,
              @Inject(ENVIRONMENT)
              public environment: any) {}

  ngOnInit() {
    this.update(this.membership);
  }

  ngOnDestroy() {
    this.browsers.forEach(browser => browser && browser.close());
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.debug('onNgChanges:'+JSON.stringify(changes));
    this.update(changes.membership.currentValue);
  }

  update(membership: GroupMembership) : void {
    if (this.membership) {
      this.style = '';
      this.partnerId = this.membership.manual_partner_id || '';
      let temporary = this.membership.temporary_partner_id;
      let leader = this.membership.role == 'leader' || this.membership.role == 'admin';
      let extended_index = this.partnerId.indexOf(':');
      if (extended_index > 0) {
        this.partnerId = this.partnerId.substr(0, extended_index);
      }
      this.partnerUrl = `http://${this.partnerId}.pm-quickstart.com`;
      this.shopUrl = `http://${this.partnerId}.well24.com`;
      this.cssClass = extended_index > 0 ? 'extended' : temporary ? 'temporary' : leader ? 'leader' : '';
    } else {
      this.style = 'display: none';
    }
  }

  browser(url: string): InAppBrowserObject {
    const target  = '_system'; // _system: load in system's web browser, _blank - load in inappbrowser, _self - load in cordova webview;
    // const options = `location=${this.environment.production ? 'no' : 'yes'},clearsessioncache='yes'`;  // no location bar in production, clear session cookie cache
    const options = `location=${this.environment.production ? 'no' : 'yes'}`;  // no location bar in production
    const browser = this.inAppBrowser.create(url, target, options);
    return browser;
  }

  goToLink(url: string) {
    this.logger.debug('goToLink()', url);
    this.platform.ready().then(readySource => {
      if (this.platform.is('hybrid')) {
        let browser = this.browser(url);
        let index = this.browsers.push(browser);
        browser.on('exit').pipe(take(1)).subscribe(event => delete this.browsers[index])
      } else {
        window.open(url, "_blank");
      }
    });
  }

  goToClone() {
    // const href = `${window.location.protocol}//${window.location.hostname}`;
    this.authTokenRequest.emit({
      onResponse: (token: Promise<String>) => {
        token.then((token)  => this.goToLink(`${this.environment.serverUrl}/?authenticationToken=${token}`))
             .catch((error) => this.logger.error('token request failed', error));
      }
    })
  }
}
