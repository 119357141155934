import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {fromEvent, Subscription} from "rxjs";
import {LegalInfoComponent} from "./legal-info.component";

@Component({
  selector: 'legal-info-bottom-sheet',
  template: `
    <button mat-icon-button class="close" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
    <legal-info [infoLabel]="data?.label" [sectionId]="data?.sectionId"></legal-info>
    <button *ngIf="showScrollTop" mat-icon-button class="scrollTop" (click)="onScrollTop($event)">
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
  `,
  styles: [`
    :host {
      display: flex;
      position: relative;
      max-height: 100%;
    }
    legal-info {
      margin-top: 30px;
      overflow-y: scroll;
    }
    button.close {
      position: absolute;
      top: 0;
      right: 0;
    }
    button mat-icon {
      color: #aaa;
    }
    button.scrollTop {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        background-color: rgba(192, 192, 192, 0.5);
        border: 1px solid transparent;
        border-radius: 6px;
        cursor: pointer;
    }
    button.scrollTop:hover {
      background-color: rgba(192, 192, 192, 0.7);
      border-color: rgba(128, 128, 128, 0.5);
    }
    button.scrollTop:hover mat-icon {
      color: #888;
    }
  `]
})
export class LegalInfoBottomSheetComponent implements OnInit, OnDestroy {

  @ViewChild(LegalInfoComponent, { static: true, read: ElementRef }) legalInfo: ElementRef;
  public showScrollTop = false;
  protected subscription: Subscription;

  constructor(private bottomSheetRef: MatBottomSheetRef<LegalInfoBottomSheetComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}

  ngOnInit() {
    this.subscription = fromEvent(this.legalInfo.nativeElement, 'scroll')
      .subscribe(() => this.showScrollTop = this.legalInfo.nativeElement.scrollTop > 20)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  onScrollTop(event: Event) {
    event.stopPropagation();
    this.legalInfo.nativeElement.scrollTop = 0;
  }
}
