import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Contact, Logger} from 'core';
import {BasicContainerComponent} from "shared";
import {MatCheckbox} from "@angular/material/checkbox";
import {MediaReview, MediaReviewState} from "media";

@Component({
  selector: 'media-review',
  templateUrl: './media-review.component.html',
  styleUrls: ['./media-review.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaReviewComponent),
      multi: true,
    }
  ]
})
export class MediaReviewComponent extends BasicContainerComponent implements ControlValueAccessor, AfterViewInit, OnChanges {

  @Input() set value(value: MediaReview) {
    // clone to prevent change propagation to the form initial value
    // which will compromise the change detection machinery.
    value = value ? {...value} : value;
    this.current.set(value);
  };

  @Input() disabled: boolean;
  @Input() contact: Contact;
  @Output() onMediaReviewChange = new EventEmitter<MediaReview>();

  // @ViewChild('downlineIncludeCheckbox') downlineIncludeCheckbox: TriStateCheckboxComponent;
  @ViewChild('approveCheckbox') approveCheckbox: MatCheckbox;
  @ViewChild('declineCheckbox') declineCheckbox: MatCheckbox;

  // canChangeDownlineInclude: boolean;
  // canReview: boolean;
  current = signal<MediaReview>(undefined);
  MediaReviewState = MediaReviewState;

  private onChange: (value: MediaReview) => void;
  private onTouched: () => void;
  protected logger = new Logger('MediaReviewComponentComponent');

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (!this.current()) {
      this.current.set({
        state: MediaReviewState.Pending,
        time: new Date().getTime(),
        contact: this.contact,
        reason: ''
      });
    }
  }

  ngAfterViewInit() {
    // this.downlineIncludeCheckbox.registerOnChange((value: boolean) => this.onDownlineIncludeChange(value));
    // this.downlineIncludeCheckbox.registerOnTouched(() => this.onTouched?.());
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  writeValue(value: MediaReview) {
    this.value = value;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  onDownlineIncludeChange(checked?: boolean) {}

  onReviewChange(sourceControl: MatCheckbox) {
    const current = this.current();
    const checked = sourceControl.checked;
    current.state = checked
      ? sourceControl == this.approveCheckbox
        ? MediaReviewState.Approved
        : sourceControl == this.declineCheckbox
          ? MediaReviewState.Declined
          : MediaReviewState.Pending
      : MediaReviewState.Pending;
    if (current.state == MediaReviewState.Approved) {
      this.declineCheckbox.checked = false;
    } else if (current.state == MediaReviewState.Declined) {
      this.approveCheckbox.checked = false;
    }
    this.triggerMediaReviewChange(current);
  }

  onReasonChange(event: Event) {
    const reason = (event.currentTarget as any).value;
    const current = this.current();
    current.reason = current.reason || undefined;
    this.triggerMediaReviewChange(current);
  }

  protected triggerMediaReviewChange(value: MediaReview) {
    value = { ...value };
    value.contact = this.contact ? (({id, name}) => ({ id, name }))(this.contact) : undefined;
    value.reason = value.state == MediaReviewState.Declined && value.reason ? value.reason : undefined;
    value.time = new Date().getTime();
    this.current.set(value);
    this.onChange?.(value);
    this.onMediaReviewChange.emit(value);
  }
}
