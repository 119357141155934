import {inject, InjectionToken} from '@angular/core';
import {DOCUMENT} from "@angular/common";

export const WINDOW = new InjectionToken<Window>(
  'global window object abstraction',
  {
    factory: () => {
      const { defaultView } = inject(DOCUMENT);
      if (!defaultView) {
        throw new Error('Window is not available');
      }
      return defaultView;
    }
  }
);
