import {Component, Inject, OnInit} from '@angular/core';
import {ENVIRONMENT} from "core";

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  version: string;

  constructor(@Inject(ENVIRONMENT) protected environment: any) {
    this.version = environment.version;
  }

  ngOnInit() {
  }
}
