import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { MediaChangeConsent } from '../containers/media-details-container/media-change-consent';
import { Media } from '../store/models';

export interface MediaUpdateEvent {
  source: string;
  mediaUpdate: [Media, Media];
}

@Injectable({
  providedIn: 'root'
})
export class MediaDetailsShareDataService {

  private mediaChangeConsentSubject: ReplaySubject<MediaChangeConsent> = new ReplaySubject<MediaChangeConsent>(1);
  getMediaChangeConsent$: Observable<MediaChangeConsent> = this.mediaChangeConsentSubject.asObservable();

  setMediaChangeConsent(value: MediaChangeConsent) {
    this.mediaChangeConsentSubject.next(value);
  }

  private optionsSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  getOptions$: Observable<any> = this.optionsSubject.asObservable();

  setOptions(options: any): void {
    this.optionsSubject.next(options);
  }

  private mediaTagsSubject: ReplaySubject<string[]> = new ReplaySubject<any>(1);
  getMediaTags$: Observable<string[]> = this.mediaTagsSubject.asObservable();

  setMediaTags(mediaTags: string[]): void {
    this.mediaTagsSubject.next(mediaTags);
  }

  private editModeSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  getEditMode$: Observable<boolean> = this.editModeSubject.asObservable();

  setEditMode(editMode: boolean) {
    this.editModeSubject.next(editMode);
  }

  private mediaSubject: ReplaySubject<Media> = new ReplaySubject<Media>(1);
  getMedia$: Observable<Media> = this.mediaSubject.asObservable();

  setMedia(media: Media) {
    this.mediaSubject.next(media);
  }

  private reviewFormSubject: ReplaySubject<FormGroup> = new ReplaySubject<FormGroup>(1);
  getReviewForm$: Observable<FormGroup> = this.reviewFormSubject.asObservable();

  setReviewForm(reviewForm: FormGroup) {
    this.reviewFormSubject.next(reviewForm);
  }

  private mediaFormSubject: ReplaySubject<FormGroup> = new ReplaySubject<FormGroup>(1);
  getMediaForm$: Observable<FormGroup> = this.mediaFormSubject.asObservable();

  setMediaForm(mediaForm: FormGroup) {
    this.mediaFormSubject.next(mediaForm);
  }

  private updateMediaSubject: ReplaySubject<MediaUpdateEvent> = new ReplaySubject(1);
  mediaUpdate$: Observable<MediaUpdateEvent> = this.updateMediaSubject.asObservable();

  setMediaUpdate(event: MediaUpdateEvent) {
    this.updateMediaSubject.next(event);
  }

}
