import {Component, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Self, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgControl} from "@angular/forms";
import {Observable} from "rxjs";

export type Item = {
  code: string,
  name: string
}

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: forwardRef(() => MultiSelectComponent),
  //     multi: true
  //   }
  // ]
})
export class MultiSelectComponent implements OnInit, ControlValueAccessor {

  @Input() items: Observable<Item[]>;
  @Input() label = '';
  @Input() disabled = false;
  @Input() selector: (code: string) => Observable<string>;
  @Output() onAdd = new EventEmitter();

  @ViewChild('select') select: ElementRef;

  value: string[] = null;
  selected: string[] = [];
  protected onChangeFunction = (value: string[]) : void => {};

  constructor(@Self() @Optional() public control: NgControl) {
    this.control && (this.control.valueAccessor = this);
  }

  ngOnInit() {
  }

  onSelectChange(event: Event) {
    const target = event.target as HTMLSelectElement;
    const options = target.selectedOptions;
    if (options.length>0) {
      const countryCode = options.item(0).value;
      if (!this.selected.includes(countryCode)) {
        this.selected.push(countryCode);
        this.onChange(this.selected);
      }
    }
  }

  onTapDelete(countryCode: string) {
    const index = this.selected.indexOf(countryCode);
    if (index>=0) {
      this.selected.splice(index, 1);
      this.onChange(this.selected);
    }
  }

  get dirty(): boolean {
    return this.selected.length!==this.value?.length || !this.selected.every((c) => this.value?.includes(c));
  }

  writeValue(value: string[]): void {
    if (this.value != value) {
      this.value = value;
      this.selected = value ? [...value] : [];
    }
  }

  onChange(value : string[]) : void {
    this.onChangeFunction(this.dirty ? value : this.value);
  }

  registerOnChange(fn: (countries: string[]) => void): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: () => void): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onTapItemAdd(event: Event) {
    this.onAdd.emit();
  }
}
