<ng-container *ngFor="let code of selected">
  <div class="item">
    <span>{{selector ? (selector(code) | async) : code}}</span>
    <button mat-icon-button (click)="onTapDelete(code)"><mat-icon>cancel</mat-icon></button>
  </div>
</ng-container>

<div matRipple class="item add" (click)="onTapItemAdd($event)">
  <mat-icon>add</mat-icon>
  <span translate>actions.add</span>
  <ng-container *ngIf="items | async; let items">
    <select #select
            *ngIf="!!items?.length"
            matNativeControl
            [placeholder]="label | translate"
            (change)="onSelectChange($event)">
      <option *ngFor="let item of items" [value]="item.code">{{item.name}}</option>
    </select>
  </ng-container>
</div>

