/* projects/shared/src/lib/components/slide-panel-deprecated/slide-panel.component.scss */
:host {
  display: block;
  overflow: hidden;
}
.container {
  height: 100%;
  width: 200%;
  display: flex;
}
.container div {
  flex: 1;
  position: relative;
}
/*# sourceMappingURL=slide-panel.component-UX3I6V7W.css.map */
