import {Inject, Injectable} from "@angular/core";
import {Action, select, Store} from "@ngrx/store";
import {debounceTime, filter, map, mergeMap, tap} from "rxjs/operators";
import isEqual from "lodash/isEqual";
import {
  asyncScheduler,
  BehaviorSubject,
  firstValueFrom,
  from,
  lastValueFrom,
  Observable,
  Subscription,
  throwError
} from "rxjs";
import {
  selectProperties,
  selectPropertiesPartner,
  selectPropertiesSession,
  selectPropertiesUser,
  selectPropertiesViewed
} from "./store/reducers";
import {HttpClient} from "@angular/common/http";
import {CountryInfo} from "./models/country-info";
import {Partner} from "./models/partner";
import {Properties} from "./models/properties";
import {
  App,
  AppIdentifierProvider,
  Challenge,
  CorrelationIdGenerator,
  EMPTY_ARRAY,
  Logger,
  NULL_USER,
  Referrer,
  Topic,
  User
} from "core";
import {
  propertiesLoadFailure,
  propertiesLoadRequest,
  propertiesLoadSuccess,
  propertiesUpdateInterests
} from "./store/actions";
import {Group} from "./models/group";

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {

  protected _user: User;
  protected _group: Group;
  protected _properties: Properties;
  protected _invitationReferrers:Referrer[] = [];//[{id:'sasas',name:'Lydia Troyer'},{id:'wqw',name:'Miriam Troyer'}];
  protected _groupId$  = new BehaviorSubject<string>(undefined);

  protected requested: {
    user: User;
    identifier: string;
  };
  protected initialized: boolean = false;
  protected initializedSubscription: Subscription;

  protected logger = new Logger('PropertiesService');

  constructor(protected store$: Store<any>,
              protected http: HttpClient,
              protected appIdentifier: AppIdentifierProvider,
              protected correlationIdGenerator: CorrelationIdGenerator,
              @Inject(NULL_USER) protected nullUser: User) {
  }

  get properties$() : Observable<Properties> {
    // this.initialize();
    return this.store$.pipe(select(selectProperties)); /*.pipe(
      tap(properties => {
        console.debug("PROPERTIES TAP 1:"+properties);
      }),
      distinctUntilChanged((a,b) => {
        console.debug("PROPERTIES CHANGED a:"+a+" b:"+b);
        return _.isEqual(a,b);
      }),
      tap(properties => {
        console.debug("PROPERTIES TAP 2:"+properties);
      }),
    );*/
  }

  get groupId() : string {
    return this._groupId$.value;
  }

  get groupId$() : Observable<string> {
    return this._groupId$.asObservable();
  }

  get session$() : Observable<CountryInfo> {
    // this.initialize();
    return this.store$.pipe(select(selectPropertiesSession));
  }

  get user$() : Observable<User> {
    // this.initialize();
    return this.store$.pipe(select(selectPropertiesUser), map((user) => user ? user : this.nullUser));
  }

  get viewed$() : Observable<{
    id: string;
    name: string;
    public?: boolean;
  }> {
    this.initialize();
    return this.store$.select(selectPropertiesViewed);
  }

  get app$() : Observable<App> {
    // this.initialize();
    return this.user$.pipe(map((user)=> user.app));
  }

  get interests$() : Observable<Topic[]> {
    // this.initialize();
    return this.app$.pipe(map((app) => app?.interests || []));
  }

  get surveys$() : Observable<Topic[]> {
    // this.initialize();
    return this.app$.pipe(map((app) => app?.interests?.filter(topic=>topic.type=='survey') || []));
  }

  get topics$() : Observable<Topic[]> {
    // this.initialize();
    return this.app$.pipe(map((app)=>app ? app.topics || []:[]));
  }

  get challenges$() : Observable<Challenge[]> {
    // this.initialize();
    return this.user$.pipe(
      map(user => !!user.app && !!user.app.challenges ? <Challenge[]>Object.values(user.app.challenges) : <Challenge[]>[])
    );
  }

  get debug$() : Observable<boolean> {
    // this.initialize();
    return this.user$.pipe(
      map(user => !!user && !!user.app && !!user.app.tags && user.app.tags.includes('debug'))
    );
  }

  get partner$() : Observable<Partner> {
    // this.initialize();
    return this.store$.pipe(select(selectPropertiesPartner));
  }

  get hideTabBar$() : Observable<boolean> {
    return this.user$.pipe(
      map(user => !!user && user.app && user.app.tags && user.app.tags.includes('hide_tabbar'))
    );
  }

  get stayAnonymous$() : Observable<boolean> {
    return this.user$.pipe(
      map(user => !!user && user.app && user.app.tags && user.app.tags.includes('stayAnonymous'))
    );
  }

  get canInvite$() : Observable<boolean> {
    return this.user$.pipe(
      map(user => !!user && user.canInvite==true)
    );
  }

  get canConnect$() : Observable<boolean> {
    return this.user$.pipe(
      map(user => !!user && user.canConnect==true)
    );
  }

  get beta$() : Observable<boolean> {
    return this.user$.pipe(
      map(user => !!user && user.isBeta)
    );
  }

  setBeta$(done:boolean) : Promise<Properties> {
    return this.setAppTag$({'beta':done});
  }

  setStayAnonymous$(hide:boolean) : Promise<Properties> {
    return this.setAppTag$({'stayAnonymous':hide});
  }

  setHideTabBar$(hide:boolean) : Promise<Properties> {
    return this.setAppTag$({'hide_tabbar':hide});
  }

  setInterests$(interests: string[]): Promise<void | never> {
    interests = interests?.map(interest => 'interest.'+interest) ?? EMPTY_ARRAY;
    return new Promise((resolve, reject) => {
      if (!!this.user?.id) {
        firstValueFrom(this.http.post<any>(
          `/v1.0/contacts/update/${this.user.id}`,
          { interestTags: interests }
        ).pipe(
          //tap(response=>console.log("X.setInterests$.done",interests,response)),
          tap(response=>this.store$.dispatch(propertiesUpdateInterests({interests}))),
          debounceTime(300, asyncScheduler)))
          .then(response=>resolve())
          .catch(reject);
      } else {
        reject("no user");
      }
    });
  }

  setAppTag$(tags:{[key: string]: boolean }) : Promise<Properties> {
    if (this.user && this.user.id) {
      return firstValueFrom(this.http.post<any>(
        `/v1.0/contacts/update/${this.user.id}`,
        { setAppTags: tags }
      ).pipe(
        mergeMap((response) => (response && response.done)
          // TODO: properties should be auto reloaded as reaction of store events
          ? from(this.reload())
          : throwError(()=>new Error('Failed to update contact. Server reported error code')))
      ));
    }
    return Promise.reject('no user');
  }

  get properties() : Properties {
    // this.initialize();
    return this._properties;
  }

  get serverVersion() : string {
    // this.initialize();
    return this._properties && this._properties.server;
  }

  get user(): User {
    return this._user ?? this.nullUser;
  }

  get invitationReferrers(): Referrer[] {
    return this._invitationReferrers ?? EMPTY_ARRAY;
  }

  get group(): Group {
    return this._group;
  }

  /*
  get goals() : string[] {
    return this.getGoals(this.appIdentifier.getAppId(), this.properties);
  }

  public getGoals(appId:number,properties:Properties) : string[] {
    const tags: string[] = get(properties.user, `app.tags`, []);
    return tags.filter((tag) => tag && tag.startsWith('goal.'));
  }
  */

  get interests() : string[] {
    return this.getInterests(this.appIdentifier.getAppId(), this.properties);
  }

  public getInterests(appId:number,properties:Properties) : string[] {
    const  tags: string[] = properties.user?.interestTags ?? [];
    return tags.filter((tag) => tag && tag.startsWith('interest.'));
  }

  protected initialize(): Promise<void> {
    if (!this.initialized) {
      this.initializedSubscription?.unsubscribe();
      return this.load(this.appIdentifier.getIdentifier())
        .then(() => {
          const propertiesSubscription = this.store$
            .pipe(
              select(selectProperties)
            ).subscribe(properties => {
              this._properties = properties;
              this._user = properties?.user;
              this._group = properties?.group;
              const group = properties?.group?.id;
              if (this._groupId$.value != group) {
                this._groupId$.next(group);
              }
              this._invitationReferrers = properties?.user?.invitationReferrers ?? EMPTY_ARRAY;
            });

          const reloadSubscription = this.store$
            .pipe(
              select(selectPropertiesUser),  //select(selectAuthenticationUser),
              filter(user => !!user)
            ).subscribe(user => {
              this._user = user;
              let reload = !this.requested || !this._properties;
              if (!reload && user && user.id) {
                reload = !this._properties      ||
                         !this.properties       ||
                         !this.properties.user  ||
                          this.properties.user.id != user.id;
              } else if (!reload) {
                reload = !!(this._properties.user && this.properties.user.id);
              }
              // this.logger.debug("selectPropertiesUser > reload", reload);
              if (reload) {
                this.load(this.appIdentifier.getIdentifier());
              } else if (this.requested) {
                this.requested.user = user;
              }
            });
          this.initializedSubscription = new Subscription();
          this.initializedSubscription.add(() => propertiesSubscription.unsubscribe());
          this.initializedSubscription.add(() => reloadSubscription.unsubscribe());
          this.initialized = true;
        });
    }
    return Promise.resolve();
  }

  /*
  loaded(force = false): Observable<Properties> {
    //console.log("LOAD PROPERTIES",force,"path",window.location.pathname);
    if (force) {
      this.initialized = false;
      this.requested   = null;
    }
    return this.store$.pipe(
      select(selectPropertiesLoaded),
      switchMap(loaded =>
        this.store$.select(selectPropertiesState)
          .pipe(
            filter(state => !!(loaded || state.error)),
            mergeMap((state) => {
              if (loaded) {
                //return of(state.properties);
                return this.store$.select(selectProperties);
              } else if (state.error) {
                return throwError(state.error);
              }
            })
          )
      )
    );
  }
  */

  reload(): Promise<Properties> {
    this.initialized = false;
    this.requested   = null;
    return this.initialize().then(() => firstValueFrom(this.store$.select(selectProperties)));
  }

  /**
   * load the properties for a given identifier.
   * if since last loading the identifier changed, or the login
   * state, then the properties are reloaded.
   * see: getPathAppAndIdentifier() global function, how to detect
   * the identifier.
   * @param identifier
   */
  protected load(identifier?: string): Promise<void> {
      if (!this.requested ||
          this.requested.identifier != identifier ||
          !isEqual(this.requested.user, this.user)) {
        this.requested = { user: this.user, identifier: identifier };
        const correlationId = this.correlationIdGenerator.next();
        const promise = lastValueFrom(
          new Observable<any>(subscriber => {
            const reducer = `loadProperties_${correlationId}`;
            this.store$.addReducer(reducer, (
              state,
              action: { correlationId: string, identifier: string, properties?: Properties, error?: string} & Action
            ) => {
              if (action.type == propertiesLoadSuccess.type  || action.type == propertiesLoadFailure.type) {
                console.debug(reducer, state, action);
                if (action.correlationId == correlationId) {
                  if (action.type == propertiesLoadSuccess.type) {
                    subscriber.next(action.properties);
                  } else {
                    subscriber.error(action.error);
                  }
                  subscriber.complete();
                  this.store$.removeReducer(reducer);
                }
              }
              return state;
            });
          })
        );
      this.store$.dispatch(propertiesLoadRequest({ correlationId, identifier }));
      return promise;
    } else {
      return Promise.resolve();
    }
  }

  update(properties: Properties) {
    /*
    this.store$.dispatch(
      new PropertiesLoadSuccessAction(
        this.appIdentifier.getIdentifier(),
        properties
      )
    );
    */
    this.store$.dispatch(propertiesLoadSuccess({ identifier: this.appIdentifier.getIdentifier(), properties }));
  }
}

/*
  mihails implementation was originally:
  app-properties.service.ts:

    export const APP_PROPERTIES = new InjectionToken<AppProperties>('appProperties');

    @Injectable({
      providedIn: 'root'
    })
    export class AppPropertiesService {
      private appProperties: AppProperties;

      static logger = new Logger('PropertiesService');

      constructor(private httpClient: HttpClient) {}

      load(): Promise<AppProperties> {
        AppPropertiesService.logger.info('loading...');
        let path = window.location.pathname;
        // const httpOptions = {
        //   headers: new HttpHeaders({
        //     'Accept':  'application/javascript',
        //   }),
        //   // explicit cast required: https://github.com/angular/angular/issues/18586
        //   responseType: 'text' as 'text',
        //   withCredentials: true
        // };
        let promise = this.httpClient.get<AppProperties>('/bootstrap'+path).toPromise();
        promise.then(result => this.appProperties = result);
        return promise;
      }

      get properties() {
        return this.appProperties;
      }
    }

 */
