import {ChangeDetectorRef, Component, signal} from '@angular/core';
import {MediaDetailsSectionComponent} from "../media-details/media-details-section.component";
import {MediaDetailsShareDataService} from "../../service/media-details-share-data.service";
import {PropertiesService} from "properties";
import {combineLatest, takeUntil} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MediaAction} from "media";
import {TranslateService} from "@ngx-translate/core";
import {FormChangeDetector, SlideEvent, SlideState} from "shared";

@Component({
  selector: 'app-media-actions',
  templateUrl: './media-actions.component.html',
  styleUrls: ['./media-actions.component.scss']
})
export class MediaActionsComponent extends MediaDetailsSectionComponent {

  mediaForm: FormGroup;
  actionForm: FormGroup;
  actions = signal<MediaAction[]>(undefined);
  // action = signal<MediaAction>(undefined);
  translations: { [key: string]: string };

  panel: 'list' | 'edit' = 'list';

  constructor(protected mediaDetailsShareDataService: MediaDetailsShareDataService,
              private propertiesService: PropertiesService,
              private translateService: TranslateService,
              protected formBuilder: FormBuilder,
              protected formChangeDetector: FormChangeDetector,
              protected changeDetector: ChangeDetectorRef) {
    super(mediaDetailsShareDataService);
  }

  ngOnInit(): void {
    combineLatest([
      this.mediaDetailsShareDataService.getMedia$,
      this.mediaDetailsShareDataService.getMediaForm$,
      this.mediaDetailsShareDataService.getEditMode$,
      this.mediaDetailsShareDataService.getOptions$
    ]).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(([media, mediaForm, editMode, options]) => {
      this.media = media;
      this.mediaForm = mediaForm;
      this.panel = 'list';
      this.actions.set(mediaForm?.get('actions').value || []);
      // this.action.set(undefined);
      this.editMode = editMode;
      const hide = this.editMode ? options.mode == 'basic' : true;
      this._isHidden.emit(hide);
    });
  }

  protected createActionForm(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      type: [''],
      label: [''],
      icon: [''],
      parameter: [''],
      promotion: false,
      primary: false
    });
  }

  /*
  onActionSelect(action: MediaAction) {
    action = this.action()?.id != action?.id ? action : undefined;
    if (action) {
      const language = this.translateService.currentLang;
      const translation: object | string = this.translateService.translations[language];
      const translate = (translationKey: string) => {
        const value = translationKey
          .split('.')
          .reduce((t, k) => t[k] || {}, translation || {});
        return !!(typeof value=='object' ? Object.keys(value)?.length : value) ? value : undefined;
      }
      this.translations = Object
        .entries(action)
        .reduce((result, [key, value]) => {
          const translationKey = `media.actions.properties.${key}s.${value}`;
          const translation = translate(translationKey);
          if (translation) {
            result[key] = translation;
          }
          return result;
        }, {});
    } else {
      this.translations = undefined;
    }
    this.action.set(action);
  }
   */

  onActionRemove(action: MediaAction) {
    const actions = this.actions()?.filter(a => a.id != action.id)
    this.actions.set(actions);
    this.mediaForm.get('actions').setValue(actions);
    // if (this.action()?.id == action.id) {
    //   this.action.set(undefined);
    // }
  }

  onActionEdit(action?: MediaAction) {
    if (!action || action.editable!=false) {
      if (!this.actionForm) {
        this.actionForm = this.createActionForm();
        this.formChangeDetector
          .detect(this.actionForm, { log: false })
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(pristine => {
            this.logger.debug('pristine', pristine)
            if (this.actionForm.pristine !== pristine) {
              if (pristine && !this.actionForm.pristine) this.actionForm.markAsPristine();
              else if (!pristine &&  this.actionForm.pristine) this.actionForm.markAsDirty();
              this.changeDetector.detectChanges();
            }
          });
      }
      this.actionForm.reset({
        ...action,
        promotion: action?.promotion ?? false,
        primary: action?.primary ?? false});
      this.panel = 'edit';
    }
  }

  onActionSave(event: MouseEvent) {
    const action = this.actionForm.getRawValue() as MediaAction;
    const actions = this.actions();
    const index = actions.findIndex(a => a.id == action.id)
    if (index >= 0) {
      actions[index] = {...action,
        promotion: !action.promotion && !actions[index].promotion
              ? actions[index].promotion
              : action.promotion,
        primary: !action.primary && !actions[index].primary
          ? actions[index].primary
          : action.primary};
    } else {
      actions.push(action);
    }
    this.actions.set(actions);
    this.mediaForm.get('actions').setValue(actions);
    this.onBack(event);
  }

  onBack(event: MouseEvent) {
    this.panel = 'list';
  }

  onSlide(event: SlideEvent) {
    if (event.state == SlideState.START && event.slideOut == 'edit') {
      this.actionForm?.reset({});
    }
  }
}
