import {select, Store} from "@ngrx/store";
import {Injectable} from "@angular/core";
import {EntityService, SearchService, SimpleFilterService, TypedFilterService} from "core";
import {Message, MessageEnvelope, MessagingService} from "messaging";
import {firstValueFrom, Observable, of} from "rxjs";
import * as fromSession from "./store/state";
import {PropertiesService} from "properties";
import {Session, SessionUpdateMessage, SessionUpdateMessageType} from "./store/models";
import has from "lodash/has";
import {
  sessionCloseAction,
  sessionCloseDoneAction,
  sessionListSubscribeAction,
  sessionListUnsubscribeAction,
  sessionSelectAction,
  sessionUpdateAction
} from "../session/store/actions";
import isEqual from "lodash/isEqual";
import {contactSetTypedFiltersAction} from "contact";
import * as fromContact from "contact/lib/store/state";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SessionService implements SearchService, SimpleFilterService, /*SelectableService,*/ EntityService<Session>, TypedFilterService {

  messageHandlers: { [key: string]: (message: Message) => void };

  constructor(protected store$: Store<any>,
              protected propertiesService: PropertiesService,
              protected messagingService: MessagingService) {
    this.messageHandlers = {};
    this.messageHandlers[SessionUpdateMessageType] = this.onSessionUpdateMessage;
    messagingService.register(envelope => !!this.messageHandlers[envelope.message?.type])
      .subscribe((envelope: MessageEnvelope) => {
        console.debug('RECEIVED MESSAGE', envelope);
        const handler = !!envelope?.message?.type ? this.messageHandlers[envelope.message.type] : null;
        handler && handler.bind(this)(envelope.message);
      });
  }

  protected onSessionUpdateMessage(message: SessionUpdateMessage) {
    if (!!message.session?.id) {
      this.store$.dispatch(sessionUpdateAction({session:message.session}));
    } else if (!!message.sessionId) {
      this.store$.dispatch(sessionCloseDoneAction({sessionId:message.sessionId}));
    }
  }

  subscribeRequest() : void {
    this.store$.dispatch(sessionListSubscribeAction({send:true}));
  }

  unsubscribeRequest() : void {
    this.store$.dispatch(sessionListUnsubscribeAction({send:true}));
  }

  get entities$() : Observable<Session[]> {
    return this.store$.pipe(select(fromSession.selectSessionEntities));
  }

  get cacheId$() : Observable<string> {
    return this.store$.pipe(select(fromSession.selectSessionCacheId));
  }

  get size$() : Observable<number> {
    return this.store$.pipe(select(fromSession.selectSessionEntitiesLength));
  }

  get filters$() : Observable<string[]> {
    return of([]);
  }

  get searchTerm$() : Observable<string> {
    return of(null);
  }

  updateFilter(filters : string[]) : void {
  }

  updateSearchTerm(term : string) : void {
  }

  select(selectedIndex: number) : void {
    this.store$.dispatch(sessionSelectAction({selectedIndex:selectedIndex}));
  }

  close(session: Session) {
    has(session, 'id') && this.store$.dispatch(sessionCloseAction({sessionId:session.id}));
    // this.store$.pipe(
    //   select(selectSessionIndex, session.id),
    //   take(1)
    // )
    // .subscribe(index => this.store$.dispatch(new SessionCloseAction(session, index)));
  }

  setTypedFilters(typedFilters:{[key:string]:string[]},remove?:(type:string,filters:string[])=>boolean): Promise<boolean> {
    return Promise.resolve(true);
  }

  getTypedFilters$(exclude?:(type:string,filters:string[])=>boolean): Observable<{[key:string]:string[]}> {
   return of({});
  }

  getCombinedFilters$(exclude?:(type:string,filters:string[])=>boolean) : Observable<string[]> {
    return of([]);
  }
}

