import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {State} from "./state";
import {
  sessionCloseAction,
  sessionCloseDoneAction,
  sessionCloseFailedAction,
  sessionEmptyAction,
  sessionListSubscribeAction,
  SessionListSubscribeMessage,
  SessionListSubscribeMessageType,
  sessionListUnsubscribeAction,
  SessionListUnsubscribeMessage,
  SessionListUnsubscribeMessageType
} from "auth";
import {MessagingService} from "messaging";

@Injectable()
export class SessionEffects {

  sessionListSubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sessionListSubscribeAction),
      mergeMap((action) => {
        if (action.send) {
          console.log("SEND.SessionListSubscribeMessage");
          this.messagingService.sendMessage(this.messagingService.initializeMessage(<SessionListSubscribeMessage>{
            type: SessionListSubscribeMessageType
          }));
        }
        return of(sessionEmptyAction());
      })));

  sessionListUnsubscribe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sessionListUnsubscribeAction),
      mergeMap((action) => {
        if (action.send) {
          console.log("SEND.SessionListUnsubscribeMessage");
          this.messagingService.sendMessage(this.messagingService.initializeMessage(<SessionListUnsubscribeMessage>{
            type: SessionListUnsubscribeMessageType
          }));
        }
        return of(sessionEmptyAction());
      })));

  closeSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sessionCloseAction),
      mergeMap(action  => {
        if (action.sessionId) {
          let path = `/v1.0/session/close/${action.sessionId}`;
          return this.http.post(path, {}).pipe(
            map(() => sessionCloseDoneAction({sessionId:action.sessionId})),
            catchError((error) => of(sessionCloseFailedAction({sessionId:action.sessionId, error})))
          )
        } else {
          return of(sessionCloseFailedAction({sessionId:action.sessionId, error:'Invalid session'}));
        }
      })));

  constructor(private http: HttpClient, private actions$: Actions, private store$: Store<State>, private messagingService: MessagingService) {}
}
