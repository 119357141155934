import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ImageCropperComponent as NgxImageCropperComponent, ImageTransform} from "ngx-image-cropper"
import {Logger, Platform} from "core";

export interface Position {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface ImageCroppedEvent {
  base64?: string | null;
  width: number;
  height: number;
  cropperPosition: Position;
  imagePosition: Position;
  offsetImagePosition?: Position;
}

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  @Input() fileChangeEvent: any;
  @Input() imageFile: File;
  @Input() imageURL: string;
  @Input() imageBase64: string;
  @Input() aspectRatio = 1; // square by default
  @Input() maintainAspectRatio = true;
  @Input() containWithinAspectRatio = true;
  // Output format (png, jpeg, webp, bmp, ico)
  // (not all browsers support all types, png is always supported, others are optional)
  // https://github.com/Mawi137/ngx-image-cropper
  @Input() format: 'png' | 'jpeg' | 'webp' | 'bmp' | 'ico' = 'png';
  @Output() cropped = new EventEmitter<ImageCroppedEvent>();
  @ViewChild('cropper') cropper: NgxImageCropperComponent;

  transform: ImageTransform = {};
  protected logger = new Logger('ImageCropperComponent');

  constructor(public platform: Platform) { }

  ngOnInit(): void {
  }

  crop(event: MouseEvent) {
    this.cropper.crop();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.logger.debug('imageCropped', event);
    this.cropped.emit(event);
  }

  imageLoaded() {
    this.logger.debug('imageLoaded');
  }

  cropperReady() {
    this.logger.debug('cropperReady');
  }

  loadImageFailed() {
    this.logger.debug('loadImageFailed');
  }

  rotate() {
    const rotate = (this.transform.rotate || 0) + 90 % 360;
    this.transform = { ...this.transform, rotate: rotate };
  }

  get cropperPosition(): Position {
    return this.cropper.cropper;
  }
}
