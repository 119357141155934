'use strict';

/**
 * Manages communications with Companion
 */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Socket = exports.SearchProvider = exports.RequestClient = exports.Provider = void 0;
const _0 = require("./RequestClient.js");
exports.RequestClient = _0;
const _1 = require("./Provider.js");
exports.Provider = _1;
const _2 = require("./SearchProvider.js");
exports.SearchProvider = _2;
const _3 = require("./Socket.js");
exports.Socket = _3;