/* projects/shared/src/lib/components/card-container/card-container.component.scss */
:host {
  overflow-y: auto;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  margin: 0px;
  min-width: 100%;
  max-width: 100%;
}
:host > .container {
  margin: auto;
  text-align: center;
  width: 100%;
}
/*# sourceMappingURL=card-container.component-MOJ6U3V5.css.map */
