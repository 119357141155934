import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {MatMenuTrigger} from "@angular/material/menu";
import {animate, style, transition, trigger} from "@angular/animations";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {PrivacySelector} from "core";

@Component({
  selector: 'privacy-selector',
  templateUrl: './privacy-selector.component.html',
  styleUrls: ['./privacy-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrivacySelectorComponent),
      multi: true
    }
  ],
  animations: [
    trigger('ngIfAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('300ms', style({opacity: 0}))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacySelectorComponent implements ControlValueAccessor, OnInit, OnChanges {

  @Input() tabIndex: number | null;
  @Input() disableRipple: boolean;
  @Input()
  set selectors(selectors: PrivacySelector[]) {
    this._selectors = selectors ?? this._selectors;
  }
  get selectors(): PrivacySelector[] {
    return this._selectors;
  }
  @Input()
  set field(field: string) {
    this._field = field;
  }
  get field(): string {
    return this._field;
  }
  @Input() @Output() selectorId: string;
  @Output() selector: PrivacySelector;
  @Output() selected = new EventEmitter<PrivacySelector>();
  @Input() input: HTMLInputElement;
  @Input() readonly: boolean = false;

  @ViewChild('button') button: MatButton;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  protected _selectors: PrivacySelector[] = [];
  protected _field: string;
  protected _propagateChange = (_: any) => {};
  protected _propagateTouched = (_: any) => {};
  protected _formulaValue:string = undefined;
  protected _disabledValue:boolean = undefined;
  protected _initialRawValue:string = undefined;
  protected _initialValue:string = undefined;
  protected _savedFormulaValue:string = undefined;

  constructor() {
  }

  get formula(): string {
    return this._formulaValue;
  }

  set formula(value:string) {
    this._formulaValue = this._savedFormulaValue = value;
  }

  get formulaReadonly():boolean {
    return this.selectorId != '#userdefined'
  }

  ngOnInit(): void {
  }

  writeValue(value: string): void {
    console.log("PrivacySelector.field", this.field, this.selectors?.length, "writeValue", value);
    if (this.selectors?.length > 0 && !!this.field) {
      this._initialRawValue = value;
      let nextSelector: PrivacySelector = undefined;
      if (value?.startsWith('#')) {
        nextSelector = this.selectors.find(selector => selector.id == value);
      } else if (value?.length > 0) {
        nextSelector = this.selectors.find(selector => selector.id == '#userdefined');
        this._savedFormulaValue = value;
      }
      if (!nextSelector) {
        this.selectors.forEach(selector => {
          if (selector.defaultFields?.includes(this.field)) {
            nextSelector = selector;
          } else if (!!selector.default && !nextSelector) {
            nextSelector = selector;
          }
        });
      }
      this.selector = nextSelector;
      this.selectorId = this._initialValue = this.selector.id;
      console.log("PrivacySelector.field", this.field, "resetTo", nextSelector);
    }
  }

  registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log("PrivacySelectorComponent.ngOnChanges",changes);
    if (changes['selectorId']) {
      this.writeValue(changes['selectorId'].currentValue);
    }
  }

  get disabled(): boolean {
    if (this.input && this.input.disabled) {
      return true;
    }
    return !!this._disabledValue;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabledValue = value;
  }

  /*
  open(event: Event): void {
    console.log("OPEN",this.readonly);
    if (!this.disabled && !this.readonly) {
      event.stopPropagation();
      if (!this.trigger.menuOpen) {
        this.button.disabled = true;
        this.trigger.openMenu();
      }
    }
  }*/

  select(selector:PrivacySelector): void {
    console.log("PrivacySelector.field",this.field,"select",selector);
    //console.log("event",event,"selector",selector);
    //event.stopPropagation();
    //console.log("formula",this.formula);
    this.selected.emit(selector);
    this.selectorId = selector.id;
    this.selector   = selector;
    if (this.selectorId!='#userdefined') {
      this._formulaValue = undefined;
    } else if (this.input) {
      this._formulaValue = this._savedFormulaValue;
      this.input.focus();
    }
    if (this._propagateChange) {
      let value = this.selectorId==this._initialValue ? this._initialRawValue : this.selectorId;
      //console.log('propagate',value);
      this._propagateChange(this.selectorId=='#userdefined'
        ? this._savedFormulaValue
        : value);
    }
    //if (this.propagateTouched && this.selectorId!='#userdefined') {
      //this.propagateTouched();
    //}
  }
}
