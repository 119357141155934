import {BehaviorSubject, combineLatest, fromEvent, Observable, of} from "rxjs";
import {Media, MediaAction} from "../../store/models";
import {BasicContainerComponent} from "shared";
import {MediaPlayer} from "./media-player";
import {map, scan, startWith, takeUntil} from "rxjs/operators";
import {MediaEditor} from "media/lib/components/media-viewer/media-editor";
import { Logger } from "core";

export class MediaViewer extends BasicContainerComponent {

  protected _media: Media;
  protected _download$ = new BehaviorSubject<boolean>(false);
  protected logger = new Logger('MediaViewer');

  set media(media: Media) { this.setMedia(media).catch(error => this.logger.error('Failed to set media', {error})); }
  get media(): Media { return this._media; }
  setMedia(media: Media): Promise<Media>  {
    this._media = media;
    this._download$.next(!!media?.tags?.includes('conversation'));
    return Promise.resolve(media);
  };

  displayToolbar: (display: boolean)=> void = () => {};
  displayAction: (actionId: string, display?: boolean) => void = () => {};
  defaultActions: Observable<MediaAction[]>;

  get canTakeSnapshot():Observable<boolean> { return of(false); }
  get canDownload():Observable<boolean> { return this._download$; }

  mountActionsDisplayTrigger(element: Element, event: string = 'click') {
    const displayActions$ = fromEvent(element, event).pipe(
      scan((display: boolean, event: object) => {
        return !display;
      }, true),
    );
    combineLatest([
      displayActions$.pipe(startWith(true)),
      this.defaultActions.pipe(map(defaultActions => defaultActions.concat(this.media.actions || [])))
    ]).pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(([display, actions]:[boolean, MediaAction[]]) => {
      actions.forEach((action) => this.displayAction(action.id, display));
    });
  };

  onAction(action: MediaAction): Promise<any> { return Promise.resolve() };
  beforeClose(): Promise<void> { return Promise.resolve() };

  isMediaPlayer(): this is MediaPlayer {
    return 'play' in this;
  }

  isMediaEditor(): this is MediaEditor {
    return 'onMediaChange' in this;
  }
}
