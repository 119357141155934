import {Directive, EventEmitter, HostBinding, HostListener, Input, OnDestroy, Output} from '@angular/core';
import {merge, Subscription} from "rxjs";

export type PressHoldEvent = MouseEvent | TouchEvent;
@Directive({
  selector: '[pressTriggerMillis],[onPressStart],[onPressHold],[onPressEnd],[onPressing]'
})
export class PressHoldDirective implements OnDestroy {

  @Input('pressTriggerMillis') triggerMillis = 500;

  protected disabled = false;
  @Input() set pressDisabled(disabled: boolean) {
    disabled = !!disabled;
    if (this.disabled!=disabled) {
      disabled && this.endPress();
      this.disabled = disabled;
    }
  };

  @Output() onPressStart = new EventEmitter<PressHoldEvent>();
  @Output() onPressHold  = new EventEmitter<PressHoldEvent>();
  @Output() onPressEnd   = new EventEmitter<PressHoldEvent>();
  @Output() onPressing   = new EventEmitter<PressHoldEvent>();

  private press: boolean;
  private hold: boolean;
  private timeout: number;
  private interval: number;
  private probeMillis = 50;

  private pressSubscription: Subscription;

  constructor() {
    this.pressSubscription = merge(
      this.onPressStart,
      this.onPressHold
    )
    .subscribe((event) => this.onPressing.emit(event));
  }

  ngOnDestroy(): void {
    this.pressSubscription?.unsubscribe();
  }

  @HostBinding('class.press')
  get pressing() { return this.press; }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMouseDown(event) {
    if (this.disabled) return;
    this.press = true;
    this.hold = false;
    this.onPressStart.emit(event);
    this.timeout = window.setTimeout(() => {
      this.hold = true;
      this.onPressHold.emit(event);
      this.interval = window.setInterval(() => {
        this.onPressHold.emit(event);
      }, this.probeMillis);
    }, this.triggerMillis);
  }

  @HostListener('touchend')
  @HostListener('mouseup')
  @HostListener('mouseleave')
  endPress() {
    clearTimeout(this.timeout);
    clearInterval(this.interval);
    this.press = false;
    this.hold = false;
    this.onPressEnd.emit();
  }
}
