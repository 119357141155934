import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThemePalette} from "@angular/material/core";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() leftIcon : string = "menu";
  @Input() rightIcon : string = "more_vert";
  @Input() color : ThemePalette = "primary";
  @Input() leftButton : boolean = false;
  @Input() rightButton : boolean = false;
  @Input() rightIcon2 : string = undefined;
  @Input() rightText2 : string = undefined;

  @Output() onLeftClicked: EventEmitter<any> = new EventEmitter();
  @Output() onTitleClicked: EventEmitter<any> = new EventEmitter();
  @Output() onRightClicked: EventEmitter<any> = new EventEmitter();
  @Output() onRightClicked2: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  emitLeftClicked() : void {
    this.onLeftClicked.emit();
  }

  emitTitleClicked() : void {
    this.onTitleClicked.emit();
  }

  emitRightClicked() : void {
    this.onRightClicked.emit();
  }

  emitRightClicked2() : void {
    this.onRightClicked2.emit();
  }

  get titleWidth() {
    const buttonWidth = 40;
    const buttons = this.leftButton && this.rightButton ? 2 : this.leftButton || this.rightButton ? 1 : 0;
    return `calc(100% - ${buttons*buttonWidth}px)`;
  }
}
