import {Component, ElementRef, HostBinding, Input, OnChanges} from '@angular/core';

import {animate, style, transition, trigger} from "@angular/animations";
import {Logger} from "core";

@Component({
  selector: 'adjustable-container',
  template: `<ng-content></ng-content>`,
  styles: [`
    :host {
      display: block;
      overflow: hidden;
    }
  `],
  animations: [
    trigger('adjust', [
      transition('void <=> *', []),
      transition('* <=> *', [
        style({ height: '{{height}}px', opacity: 0}),
        animate('0.5s ease'),
      ], { params: { height: 0 } })
    ])
  ]
})
export class AdjustableContainerComponent implements OnChanges {

  @Input() trigger: string;
  protected height: number;
  protected logger = new Logger('AdjustableContainerComponent');

  constructor(private element: ElementRef) {
  }

  @HostBinding('@adjust') get adjust() {
    return { value: this.trigger, params: { height: this.height } };
  }

  ngOnChanges() {
    this.update();
  }

  update() {
    this.height = this.element.nativeElement.clientHeight;
    this.logger.debug('update [height:'+this.height+']');
  }
}
