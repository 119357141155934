<div #view
     (onResize)="triggerResize($event)"
     (onAttach)="ngOnAttach()"
     (onDetach)="ngOnDetach()"
     class="media-grid-container">

  <virtual-grid #grid
                class="media-grid"
                [log]="false"
                [startFromTop]="true"
                [columns]="columns$ | async"
                [bufferSize]="600"
                [minimumBufferSize]="100"
                [sideGap]="4"
                [startGap]="6"
                [endGap]="100"
                [itemDefaultSize]="300"
                [items$]="gridEntities$"
                [itemsId$]="cacheId$">
      <ng-container *virtualFor="let media of grid.viewportItems$ | async
                        offset grid.viewportOffset$ | async
                        total grid.items.length;
                        let index = index;
                        trackBy: trackByMedia;">
        <div #mediaTile
             [class]="media==dragTargetMedia &&
                        dragTargetMedia!=dragMedia &&
                      (dragTargetPosition == 'before' ? 'drop-before'
                      : dragTargetPosition == 'after'  ? 'drop-after'
                      : dragTargetPosition == 'self'   ? media==dropTargetMedia && 'drop-self' || null
                      : null ) || null"
             [draggable]="dragDrop"
             (dragstart)="onDragStart($event, media, mediaTile)"
             (dragover)="onDragOver($event, media)"
             (dragend)="onDragEnd($event, media)"
             (drop)="onDrop($event, media)"
             class="media-tile">
          <div [class.selected]="selectedIndex==index">
            <!-- *ngIf="shwoGrid" -->
            <div *ngIf="!!media"
                 (click)="onSelectMedia(index, media, $event)"
                 class="media">
              <ng-container *ngLet="mediaService.isFolderMedia(media) as folder">
                <div *ngIf="mediaDetails" class="details-container">
                  <ng-container *ngTemplateOutlet="mediaDetails; context: { index: index, media: media, folder: folder }"></ng-container>
                </div>
                <div class="media-card-container">
                  <media-card #mediaCard
                              [class.cardOnly]="media.tags?.includes('hide.details')"
                              [media]="media"
                              [cover]="mediaCovers?.[media.mediaType]"
                              [rounded]="false"
                              [elevated]="false"
                              [indicator]="false"
                              [selectable]="false"
                              [invisible]="!media.author?.visible"
                              [defaultCoverHeight]="defaultCoverHeight"
                              [forcedCoverHeight]="forcedCoverHeight"
                              (play)="onPlayMedia(index, media)">
                  </media-card>
                  <ng-container *ngTemplateOutlet="mediaBadges; context: { index: index, media: media, folder:folder }"></ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
  </virtual-grid>

</div>
<div #dragMediaImage class="drag-image">
  <img [src]="(mediaService.getMediaCoverSrc(dragMedia, 320, false) | sessionLink)"/>
<!--  <media-card [media]="dragMedia"-->
<!--              [rounded]="false"-->
<!--              [elevated]="false"-->
<!--              [selectable]="false"-->
<!--              [indicator]="false"-->
<!--              [defaultCoverHeight]="defaultCoverHeight"-->
<!--              [forcedCoverHeight]="forcedCoverHeight">-->
<!--  </media-card>-->
</div>

