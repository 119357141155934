import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ENVIRONMENT, Platform } from 'core';
import { MediaDetailsShareDataService } from '../../service/media-details-share-data.service';
import { MediaService } from '../../service/media.service';
import { combineLatest, take, takeUntil } from 'rxjs';
import { BytesPipe, MessageBoxComponent } from 'shared';
import { ImageUploadActionEvent } from 'upload';
import { MediaDetailsSectionComponent } from '../media-details/media-details-section.component';
import { MediaChangeConsent } from '../../containers/media-details-container/media-change-consent';

@Component({
  selector: 'media-details-cover',
  templateUrl: './media-details-cover.component.html',
  styleUrls: ['./media-details-cover.component.scss']
})
export class MediaDetailsCoverComponent extends MediaDetailsSectionComponent {

  mediaCoverMinWidth = 100;
  mediaCoverMinHeight = 100;
  mediaCoverUploadUrl: string;
  mediaChangeConsent: MediaChangeConsent;

  constructor(
    private translateService: TranslateService,
    public platform: Platform,
    protected dialog: MatDialog,
    protected mediaDetailsShareDataService: MediaDetailsShareDataService,
    protected mediaService: MediaService,
    @Inject(ENVIRONMENT) protected environment: any) {
    super(mediaDetailsShareDataService);
  }

  ngOnInit(): void {
    combineLatest([
      this.mediaDetailsShareDataService.getMedia$,
      this.mediaDetailsShareDataService.getEditMode$,
      this.mediaDetailsShareDataService.getMediaChangeConsent$
    ]).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(([media, editMode, mediaChangeConsent]) => {
      this.isHidden.emit(!editMode);
      this.mediaChangeConsent = mediaChangeConsent;
      this.mediaCoverUploadUrl = media && media.id ? `${this.environment.serverUrl}/v1.0/media/upload/cover/${media.id}` : null;
    });
  }

  get coverSrc(): string {
    return this.mediaService.getMediaCoverSrc(this.media);
  }

  get hasCustomCover(): boolean {
    // if we do not have an old cover, it must be the original...
    if (!!this.media.cover) {
      if (!!this.media?.properties?.cover) {
        return true;
      }
    }
    return false;
    /*
    // custom media covers have relative links i.e. starting with slash
    return has(this.media, 'cover.link') &&
           this.media.cover.link.startsWith('/');
     */
  }

  onMediaCoverUploadComplete(event: any) {
    this.logger.debug('UPLOAD COMPLETE', event);
    const media = event.media;
    if (media) {
      /*
      let link = _.get(media, 'cover.link', '');
      if (link) {
        const version = media.version;
        const regex = new RegExp("([?&])" + 'version' + "=.*?(&|$)", "i");
        const separator = link.indexOf('?') !== -1 ? "&" : "?";
        if (link.match(regex)) {
          media.cover.link = link.replace(regex, '$1version=${version}$2');
        } else {
          media.cover.link = `${link}${separator}version=${version}`;
        }
      }
      */
      this.mediaDetailsShareDataService.setMediaUpdate({ source: 'cover', mediaUpdate: media });
    }
  }

  onMediaCoverUploadError(event: any) {
    const error = event.error;
    this.logger.error('COVER UPLOAD ERROR', event);
    let label, params;
    if (error == 'imageTooSmall') {
      label = 'media.validation.coverImageTooSmall';
      params = {
        width: event.width + 'px',
        height: event.height + 'px',
        minimumWidth: this.mediaCoverMinWidth + 'px',
        minimumHeight: this.mediaCoverMinHeight + 'px'
      };
    } else if (error == "imageTooBig") {
      label = 'media.validation.coverImageTooBig';
      params = { maxSize: new BytesPipe().transform(event.maxFileSize, 1) };
    } else {
      label = 'media.uploadResult.mediaError';
      params = {};
    }
    this.translateService
      .get(label, params)
      .pipe(take(1))
      .subscribe((message) => {
        this.dialog.open(MessageBoxComponent, { data: { message: message } });
      });
  }

  onMediaCoverAction(event: ImageUploadActionEvent) {
    const handleAction = (proceed: boolean, action?: string) => {
      event.prevent(!proceed);
      if (proceed && action == 'delete') {
        this.mediaDetailsShareDataService.setMediaUpdate({
          source: 'cover',
          mediaUpdate: [
          {
            ...this.media,
            properties: Object.keys(this.media.properties)
              .reduce((properties, [key, value]) => {
                key != 'cover' && (properties[key] = value);
                return properties
              }, {})
          },
          this.media
        ]});
      }
    };
    const requiesReapprove = this.mediaService.requiresReapprove(
      // force a media difference by changing dateUpdated
      // mediaService.requiresReapprove checks for there are any changes in media
      { ...this.media, timeUpdated: new Date().getTime() },
      this.media
    );
    if (requiesReapprove && this.mediaChangeConsent) {
      // this.context.mediaChangeConsent.display(this.media)
      this.mediaChangeConsent.display(this.media)
        .then((proceed) => handleAction(proceed, event.action))
        .catch((error) => handleAction(false));
    } else {
      handleAction(true, event.action);
    }
  }

}
