import {createAction, props} from '@ngrx/store';
import {Topic} from "core";
import {FilterHandler} from "./models";

export const registerFilterHandlerAction = createAction(
  'filter.type.handler.register',
  props<{
    readonly filterType : string,
    readonly filterHandler: FilterHandler}>()
);

export const setFilterTemplatesAction = createAction(
  'filter.templates.set',
  props<{
    readonly templates : Topic[]}>()
);

export const setFilterValuesAction = createAction(
  'filter.values.set',
  props<{
    readonly path: string,
    readonly view?: string,
    readonly merge: boolean,
    values : string[]}>()   // values to be set/merged ... returns current values...
);
