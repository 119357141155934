import {ChangeDetectionStrategy, Component, ElementRef, Inject, signal, ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ENVIRONMENT, Logger, LogMessage, LogMessageType, Platform, RoutePathService} from "core";
import {PropertiesService} from "properties";
import {DOCUMENT} from "@angular/common";
import {SessionTokenService} from "session";
import {MediaService} from "../../../../service/media.service";
import {MediaViewer} from "../../media-viewer";
import {Media, MEDIA_CONTACT_SERVICE, MediaAction, MediaContactService} from "media";
import {ContactPropertiesAccessor, timer, TimerHandler} from 'shared';
import cloneDeep from "lodash/cloneDeep";
import {distinctUntilChanged, map, takeUntil} from "rxjs/operators";
import {firstValueFrom} from "rxjs";
import isEqual from "lodash/isEqual";
import {TranslateService} from '@ngx-translate/core';
import {MessagingService} from 'messaging';

type Scope = 'group' | 'app' | 'global';

enum RESPONSE_CODE {
  SERVER_ERROR = -4,
  BAD_REQUEST,
  ILLEGAL_ARGUMENT,
  INVALID_MESSAGE_SOURCE,
  OK
}
@Component({
  selector: 'interactive-presentation-viewer',
  templateUrl: './interactive-presentation-viewer.component.html',
  styleUrls: ['./interactive-presentation-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteractivePresentationViewerComponent extends MediaViewer {

  @ViewChild('iframe', {read: ElementRef}) iframe: ElementRef<HTMLIFrameElement>;
  htmlUrl = signal(undefined);
  protected _globalProperties: Promise<ContactPropertiesAccessor>;
  protected _appProperties: Promise<ContactPropertiesAccessor>;
  protected _groupProperties: Promise<ContactPropertiesAccessor>;
  protected contactId: string;
  protected messageListener = this.handleMessage.bind(this);
  protected displayToolbarTimerHandler: TimerHandler;

  protected logger = new Logger('InteractivePresentationViewerComponent');

  constructor(protected elementRef: ElementRef,
              protected http: HttpClient,
              protected mediaService : MediaService,
              @Inject(MEDIA_CONTACT_SERVICE)
              protected mediaContactService: MediaContactService,
              protected propertiesService: PropertiesService,
              protected messagingService: MessagingService,
              protected platform: Platform,
              protected sessionTokenService: SessionTokenService,
              protected translateService: TranslateService,
              protected routePathService: RoutePathService,
              @Inject(DOCUMENT) protected document: any,
              @Inject(ENVIRONMENT) protected environment: any) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    // this.mountActionsDisplayTrigger(this.elementRef.nativeElement);
    this.propertiesService.user$.pipe(
      map(user => user.id),
      distinctUntilChanged()
    ).subscribe(userId => {
      this.contactId = userId;
      this._globalProperties =
      this._appProperties =
      this._groupProperties =
      undefined;
    });

    //render all actions in the toolbar
    this.defaultActions
      .pipe(
        takeUntil(this.onDestroy$),
        map(defaultActions => defaultActions.concat(this.media.actions || []))).
    subscribe((actions: MediaAction[]) => {
      actions.forEach((action) => this.displayAction(action.id, true));
    });

    // hide the toolbar for 1 second
    this.displayToolbar(false);
    this.displayToolbarTimerHandler = timer(() => this.displayToolbar(true), 1000);
  }

  ngAfterViewInit(){
    window.addEventListener('message', this.messageListener, false);
    /*
    window.addEventListener('message', message => {
      //if (message.source !== this.iframe.nativeElement) {

      //}
      //if (message.origin !== 'http://expected-origin.com') {
        //return; // Not the expected origin: Reject the message!
      //}
      // Respond back to the parent
      //message.source.postMessage({ message: 'Hello from iframe' });
      this.handleMessage(message);
    },false);*/

    // next line is for testing purposes only
    //setTimeout(() => this.onAction({ id: 'progress', parameter: '100' }), 10000);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    window.removeEventListener('message', this.messageListener, false);
  }

  protected handleMessage(event: MessageEvent) {
    const { messageId, bridgeId, action, data } = event.data;
    this.logger.debug('handleMessage', { ...event });
    // Security check: Verify event.origin here
    if (event.source !== this.iframe?.nativeElement?.contentWindow ||
        event.origin !== this.environment.serverUrl) {
        // origin : "https://home.microfranchise.app:8443"
        // Not the expected source or origin: Reject the message!
        this.logger.warn('Invalid message source or origin', (({source, origin}) => ({source, origin}))(event))
        this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.INVALID_MESSAGE_SOURCE });
        return;
    }
    console.log("ACTION$",action,event);
    switch (action) {
        case 'initialize':
            this.displayToolbarTimerHandler?.cancel();
            this.displayToolbar(false);
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK });
        break;

        case 'getContactFirstName':
            // Fetch and respond with contact's first name
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: this.propertiesService.user?.firstName ?? '?' });
        break;

        case 'setContactFirstName':
            (data?.name
              ? new Promise((resolve, reject) => {
                  const contact = cloneDeep(this.propertiesService.user);
                  contact.firstName = data?.name;
                  this.mediaContactService
                    .update(contact)
                    .then(contact => this.propertiesService.reload())
                    .then(() => ({code: RESPONSE_CODE.OK, result: this.propertiesService.user}))
                    .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, error}))
                    .then(resolve)
                })
              :  Promise.resolve({ code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'contact name cannot be empty' })
              ).then(response => this.respond(event.source, messageId, bridgeId, response));
        break;

        case 'setContactTpNumber':
          (data?.tpNumber?.length > 0 //&& /^\d{5,8}$/.test(data.tpNumber)
              ? new Promise((resolve, reject) => {
                this.mediaContactService
                  .update({id: this.propertiesService.user.id, partnerId: data.tpNumber})
                  .then(contact => this.propertiesService.reload())
                  .then(() => ({code: RESPONSE_CODE.OK, result: this.propertiesService.user}))
                  .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, error}))
                  .then(resolve)
              })
              :  Promise.resolve({ code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'invalid tpNumber' })
          ).then(response => this.respond(event.source, messageId, bridgeId, response));
        break;

        case 'hasContactTag':
            (data?.tag
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                                const hasTag = !!contactProperties.getTags()?.find((tag) => tag===data.tag);
                                return { code: RESPONSE_CODE.OK, result: hasTag };
                            },
                            error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error })
                        )
                    : Promise.resolve({ response: { code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'tag not specified' }})
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
            break;

        case 'hasAnyContactTag':
            (data?.tags instanceof Array && data.tags.length>0
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                                const hasAnyTag = !!contactProperties.getTags()?.find((tag) => data.tags.includes===data.tag)
                                return { code: RESPONSE_CODE.OK, result: hasAnyTag };
                            },
                            error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error })
                        )
                    : Promise.resolve({ response: { code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'tags not specified' }})
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
            break;

        case 'hasAllContactTags':
            (data?.tags instanceof Array && data.tags.length>0
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                            const contactTags = contactProperties.getTags();
                            const hasAllTags = data.tags.filter(tag => !!tag).every(tag => contactTags.includes(tag));
                            return { code: RESPONSE_CODE.OK, result: hasAllTags };
                        })
                        .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error })
                        )
                    : Promise.resolve({ code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'tags not specified' })
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
            break;

        case 'setContactTag':
            (data?.tag
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                            if (data.value === false) {
                                contactProperties.removeTag(data.tag);
                            } else {
                                if (!data.value) {
                                    this.logger.warn('Usage of deprecated api version: value parameter of type boolean is not specified, defaulting to true.');
                                }
                                contactProperties.setTag(data.tag);
                            }
                            return contactProperties.save();
                        })
                        .then(() => ({ code: RESPONSE_CODE.OK }))
                        .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error }))
                    : Promise.resolve({ code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'tag not specified' })
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
            break;

        case 'setContactTags':
            (data?.tags instanceof Array && data.tags.length>0
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                            this.logger.warn('Usage of deprecated api version: tags parameter of type array is deprecated, use object instead!');
                            data.tags.forEach(tag => contactProperties.setTag(tag));
                            return contactProperties.save();
                        })
                        .then(() => ({ code: RESPONSE_CODE.OK }))
                        .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error }))
                    :  typeof data?.tags === 'object' && Object.entries(data.tags).length > 0
                        ? this.contactProperties(data.scope)
                            .then(contactProperties => {
                                Object.entries(data.tags).forEach(([key, value]) =>
                                    !!value ? contactProperties.setTag(key) : contactProperties.removeTag(key)
                                );
                                return contactProperties.save();
                            })
                            .then(() => ({ code: RESPONSE_CODE.OK }))
                            .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error }))
                        : Promise.resolve({ code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'tags not specified' })
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
            break;

        case 'removeContactTag':
            (data?.tag
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                            if (contactProperties.getTags()?.includes(data.tag)) {
                                contactProperties.removeTag(data.tag);
                                return contactProperties.save();
                            }
                        })
                        .then(() => ({ code: RESPONSE_CODE.OK }))
                        .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error }))
                    : Promise.resolve( { code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'tag not specified' })
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
            break;

        case 'removeContactTags':
            (data?.tags instanceof Array && data.tags.length>0
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                            const contactTags = contactProperties.getTags();
                            data.tags.forEach(tag => {
                                if (contactTags.includes(tag)) {
                                    contactProperties.removeTag(tag);
                                }
                            })
                            if (!isEqual(contactTags, contactProperties.getTags())) {
                                return contactProperties.save();
                            }
                        })
                        .then(() => ({ code: RESPONSE_CODE.OK }))
                        .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error }))
                    : Promise.resolve( { code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'tag not specified' })
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
            break;

        case 'getContactTags':
          this.contactProperties(data?.scope)
                .then(contactProperties => ({ code: RESPONSE_CODE.OK, result: contactProperties.getTags() }))
                .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error }))
                .then(result => this.respond(event.source, messageId, bridgeId, result));
            break;


        case 'hasContactProperty':
            (data?.key
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                                const hasProperty = !!Object.keys(contactProperties.getProperties() || {})?.find((key) => key===data.key);
                                return { code: RESPONSE_CODE.OK, result: hasProperty };
                            },
                            error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error })
                        )
                    : Promise.resolve( { code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'property key not specified' })
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
            break;

        case 'getContactProperty':
            (data?.key
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                                const value = (contactProperties.getProperties() || {})?.[data.key];
                                return { code: RESPONSE_CODE.OK, result: value };
                            },
                            error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error })
                        )
                    : Promise.resolve({ code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'property key not specified' })
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
            break;


        case 'setContactProperty':
            (data?.key && data.value
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                            contactProperties.setProperty(data.key, data.value);
                            return contactProperties.save();
                        })
                        .then(() => ({ code: RESPONSE_CODE.OK }))
                        .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error }))
                    : Promise.resolve({ response: { code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'property not specified' }})
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
        break;

        case 'removeContactProperty':
            (data?.key
                    ? this.contactProperties(data.scope)
                        .then(contactProperties => {
                            if (Object.keys(contactProperties.getProperties() || {})?.includes(data.key)) {
                                contactProperties.removeProperty(data.key);
                                return contactProperties.save();
                            }
                        })
                        .then(() => ({ code: RESPONSE_CODE.OK }))
                        .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error }))
                    : Promise.resolve({ code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'property key not specified' })
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
        break;

        case 'getGroupName':
            // Fetch and respond with group's name
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: this.propertiesService.group?.name ?? '?' });
        break;

        case 'getProgress':
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: this.media.properties?.progress });
        break;

        case 'setProgress':
            this.onAction({ id: 'progress', parameter: data })
                .then(() => this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK }));
        break;

        case 'setManualProgress':
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK });
        break;

        // case 'getPlatform':
        //    the folowing code has CORS related issues:
        //    contentWindow properties can only be accessed if iframe is froma same origin
        //     const contentWindow = this.iframe.nativeElement.contentWindow;
        //     if (contentWindow['platform'] != this.platform) {
        //
        //       contentWindow['platform'] = this.platform;
        //     }
        //     this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: 'window.platform'});
        // break;

        case 'hasPlatform':
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: this.platform.is(data)});
        break;

        case 'getPlatforms':
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: this.platform.platforms()});
        break;

        case 'getHlsLink':
        // case 'getHlsLinkFactory':
            const hlsLink = this.media.links?.find(link => link.quality=='hls')?.link;
            const hlsPlaylistLinkFactory = (playlist: string[])  => {
              const value = playlist instanceof Array && playlist.length>0 ? playlist.join(',') : '';
              const query = playlist
                ? `${hlsLink?.startsWith('?') ? '&' : '?'}playlist=${encodeURIComponent(value)}`
                : '';
              const hlsPlaylistLink = hlsLink
                ? `${this.environment.serverUrl}${hlsLink}${query}`
                : '';
              // e.g:  https://app.lifechanger.pro/v1.0/media/link/1715350564420/4aff491d18f62dc4be8/videos/index.m3u8?playlist=punkt_01.mp4%2Cpunkt_02.mp4%2Cpunkt_03.mp4
              return this.sessionTokenService.rewrite(hlsPlaylistLink);
            }
            if (action=='getHlsLink') {
              this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: hlsPlaylistLinkFactory(data)});
            } else {
              // factory could be used for direct synchronous link access
              // in the same browser context which is executing a handler for user interaction
              // this way we can avoid e.g. ios safari restrictions on playing audio/video.
              // not fully implemented (maybe not needed anyway).
              // in order to make it working hlsPlaylistLinkFactory should be coded as plain java function \
              // and hlsLink sould be injected inside the string representation of this function
              this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: encodeURI(hlsPlaylistLinkFactory.toString())});
            }
        break;

        case 'getBlankVideoLink':
          const blankVideoLink = '/assets/video/blank.mp4';
          this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: blankVideoLink});
        break;

        case 'getMediaLink':
          let mediaLink = this.media.links?.find(link => link.link?.endsWith(data))?.link;
          if (mediaLink) {
            mediaLink = this.sessionTokenService.rewrite(
              mediaLink?.startsWith("/")
                ? `${this.environment.serverUrl}${mediaLink}`
                : mediaLink
            )
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: mediaLink});
          } else {
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'invalid path'});
          }
        break;

        case 'getMediaContext':
          this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result: this.media.context });
        break;

        case 'getAppStoreLink':
            const googlePlayLinkKey = 'app.links.googlePlay',
                  appleStoreLinkKey = 'app.links.appleStore';
            const appStoreLinkKey = this.platform.is('android')
                ? googlePlayLinkKey
                : this.platform.is('ios')
                    ? appleStoreLinkKey
                    : `${!this.platform.is('hybrid')
                            ? this.document.window.location.origin
                            : this.environment.serverUrl
                        }${this.routePathService.getDefaultUrl()}`;
                    //: [ googlePlayLinkKey, appleStoreLinkKey ]; //both links for desktop

            firstValueFrom(this.translateService.get(appStoreLinkKey))
                .then(links =>
                  ({ code: RESPONSE_CODE.OK, result: typeof links==='string' ? links : Object.values(links)})
                )
                .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error }))
                .then(response => this.respond(event.source, messageId, bridgeId, response));
        break;

        case 'setDisplayToolbar':
            this.displayToolbarTimerHandler?.cancel();
            this.displayToolbar(!!data);
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK });
        break;

        case 'performAction':
            const promise = data.id == 'link' && this.platform.is('mobile')
                ? this.onAction({ id: 'copyInvitationToken' })
                : Promise.resolve();
            promise
                .then(() => this.onAction(data))
                .then(
                    result => ({ code: RESPONSE_CODE.OK, result }),
                    error => ({ code: RESPONSE_CODE.BAD_REQUEST, result: error })
                )
                .then(response => {
                    this.respond(event.source, messageId, bridgeId, response)
                })
        break;

        case 'close':
            this.onAction({ id: 'close' })
              .then(result => this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.OK, result }));
        break;

        case 'log':
            (data
                ? this.messagingService.sendMessage(
                    this.messagingService.initializeMessage(<LogMessage>{
                        type: LogMessageType,
                        object: { system:'iap', mediaId: this.media.id, message: data }
                    }))
                    .then(() => ({ code: RESPONSE_CODE.OK }))
                    .catch(error => ({code: RESPONSE_CODE.SERVER_ERROR, result: error }))
                : Promise.resolve({ code: RESPONSE_CODE.ILLEGAL_ARGUMENT, result: 'empty message' })
            ).then(result => this.respond(event.source, messageId, bridgeId, result));
        break;

        default:
            this.respond(event.source, messageId, bridgeId, { code: RESPONSE_CODE.BAD_REQUEST });
        break;
    }
  }

  protected respond(target: MessageEventSource, messageId: string, bridgeId: string, response: any) {
    target.postMessage({ messageId, bridgeId, response }, { targetOrigin: '*' }); // Adjust target origin as needed
  }

  setMedia(media: Media): Promise<Media>  {
    if (media?.links?.length>=2) {
      const rootPath = media.rootPath ?? this.environment.serverUrl;
      const htmlPath = media.links[1].link;
      this.logger.debug('media', media, media.links[1].link);
      this.htmlUrl.set(htmlPath?.startsWith('/') ? `${rootPath}${htmlPath}` : htmlPath);
    } else {
      this.htmlUrl.set(undefined);
    }
    return super.setMedia(media);
  }

  track(): Promise<void> {
    return this.mediaService.markPlayed(this.media.id);
    /*
    const request = this.http.post(
      `/v1.0/media/reaction/${this.media.id}`,
      { played: '[]' }
    );
    request.subscribe((response: Media) => {
      if (response) {
        this.mediaService.played(this.media.id, {
          played: response.played,
          playedTime: response.playedTime,
          completed: response.completed,
          touched: response.touched
        });
      } else {
        throw new Error('Empty server response');
      }
    });
    return request.toPromise().then(() => void 0);*/
  }

  protected contactProperties(scope: Scope = 'group') {
    switch (scope) {
      case 'group':
        return this.groupProperties;
      case 'app':
        return this.appProperties;
      default:
        return this.gloalProperties;
    }
  }

  protected get gloalProperties() {
    if (!this._globalProperties) {
      this._globalProperties = this.mediaContactService.contactProperties$(this.contactId)
    }
    return this._globalProperties;
  }

  protected get appProperties() {
    if (!this._appProperties) {
      this._appProperties = this.mediaContactService.contactAppProperties$(this.contactId)
    }
    return this._appProperties;
  }

  protected get groupProperties() {
    if (!this._groupProperties) {
      this._groupProperties = this.mediaContactService.contactGroupProperties$(this.contactId)
    }
    return this._groupProperties;
  }
}
