<!--
https://stackblitz.com/angular/dqalyvxedqe?file=app%2Fsidenav-open-close-example.ts
https://github.com/MainaWycliffe/Responsive-Navbar-with-Angular-Material-and-Angular-Flex-Layout/tree/master
-->

<mat-sidenav-container (onAttach)="ngOnAttach()" (onDetach)="ngOnDetach()">
  <!--<mat-sidenav position="end" #sidenav mode="over" [(opened)]="opened" (opened)="events.push('open!')" (closed)="events.push('close!')">-->
  <mat-sidenav position="end" #sidenav mode="over" class="info">
    <div class="content">
      <mat-toolbar color="primary">
        <button mat-icon-button (click)="sidenav.toggle()" aria-label="close"><mat-icon>close</mat-icon></button>
        <span class="spacer"></span>
        <span translate>module.rules</span>
      </mat-toolbar>
      <div class="legal-info-container" [innerHTML]="'login.legal_info' | translate | safeHtml"></div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="card-container">
      <card-container>
        <div class="login-container last" fxLayout="row" fxLayoutAlign="center">
          <mat-card appearance="outlined" class="login-box">
            <!--<img src="{{(properties | async)?.group.image_url}}" mat-card-image/>-->
            <!--<mat-card-title>{{(properties | async)?.group.name}}</mat-card-title>-->
            <div class="logo-container">
              <!--
              <img src="{{'/assets'+propertiesService.properties?.group?.image_url}}" />
              -->
              <group-logo #grouplogo id="login" [log]="false" [group]="group$ | async" [theme]="themeMode$ | async"></group-logo>
              <ng-container *ngIf="!(signRequired() || signing())">
                <ng-container *ngLet="token$ | async as token">
                  <div *ngIf="token" class="qr-code">
                    <qr-code-all  #qrCode
                                  [qrCodeValue]="token"
                                  qrCodeECLevel="L"
                                  [qrCodeColorDark]="qrCodeColorDark"
                                  [qrCodeColorLight]="qrCodeColorLight"
                                  [width]="200"
                                  [margin]="0"
                                  [scale]="4"
                                  [scanQrCode]="false"
                                  (click)="displayLoginQrCodeDialog($event)">
                    </qr-code-all>
                    <!--                <button mat-icon-button class="mat-primary" (click)="displayLoginQrCodeDialog($event)"><mat-icon>search</mat-icon></button>-->
                  </div>
                  <div *ngIf="!token" class="spinner">
                    <mat-progress-spinner diameter="64" strokeWidth="4" mode="indeterminate"></mat-progress-spinner>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <mat-card-content *ngIf="signRequired() || signing(); then signContract else systemLogin">
            </mat-card-content>
            <div class="reference-bar" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2rem">
              <button  *ngIf="canInstallApp | async" mat-button (click)="onTapInstallApp()">
                <span [innerHTML]="'login.installApp' | translate | safeHtml"></span>
              </button>
<!--              <button mat-button (click)="sidenav.toggle()">-->
              <button mat-button (click)="displayLegalInfo()">
                <span [innerHTML]="'login.rules' | translate | safeHtml"></span>
              </button>
              <app-language-button [primary]="false" [raised]="false"></app-language-button>
            </div>
          </mat-card>
        </div>
      </card-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


<ng-template #systemLogin>
  <div class="page-container" [class.error]="memberLoginError || guestLoginError || passcodeRequestError || passcodeLoginError">
<!--    <div class="page" [ngClass]="{ 'selected': isRecoveryMode, 'leave': !isRecoveryMode }">-->
    <div class="page" *ngIf="isRecoveryMode" [@pageActivation]>
      <ng-container *ngTemplateOutlet="recovery"></ng-container>
    </div>
  <!--    <div class="page" [ngClass]="{ 'selected': !isRecoveryMode, 'leave': isRecoveryMode }">-->
    <div class="page" *ngIf="!isRecoveryMode" [@pageActivation]>
      <ng-container *ngTemplateOutlet="guestOrMemberLogin"></ng-container>
    </div>
  </div>
</ng-template>


<ng-template #recovery>
  <div class="recovery-container" [@recoverySlide]="recoveryPanel">
    <form [formGroup]="passcodeRequestForm" novalidate>
      <div fxLayout="column">
        <h4 [innerHTML]="'login.recovery.requestInfo' | translate | safeHtml"></h4>
        <mat-form-field>
          <mat-icon matPrefix>device_hub</mat-icon>
          <mat-label translate>contact.memberId</mat-label>
          <input type="text"
            matInput
            formControlName="member_id"
            name="member_id"
            required
            uncorrected-input/>
          <mat-error *ngIf="passcodeRequestForm.controls.member_id.invalid && passcodeRequestForm.controls.member_id.touched">
            <span translate>contact.validation.memberIdRequired</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-icon matPrefix>phone</mat-icon>
          <mat-label translate>contact.phone</mat-label>
          <input type="tel"
                 unmodified-input
                 matInput
                 formControlName="phone"
                 name="phone"
                 required/>
          <mat-error *ngIf="passcodeRequestForm.controls.phone.invalid && passcodeRequestForm.controls.phone.touched">
            <span translate>contact.validation.phoneRequired</span>
          </mat-error>
        </mat-form-field>
        <div class="actions">
          <button mat-raised-button
            color="primary"
            type="button"
            (click)="passcodeRequest(false)">
            <span translate>actions.cancel</span>
          </button>
          <button mat-raised-button
                  color="primary"
                  type="button"
                  (click)="passcodeRequest(true)"
                  [disabled]="passcodeRequestForm.invalid || passcodeRequestForm.pristine || (loading$ | async)">
            <span translate>actions.request</span>
          </button>
        </div>
        <div class="login-error" [hidden]="!passcodeRequestError || (loading$ | async)">
          {{'login.recovery.requestError' | translate}}
        </div>
      </div>
    </form>
    <form [formGroup]="passcodeLoginForm" novalidate>
      <div fxLayout="column">
        <h4 [innerHTML]="'login.recovery.loginInfo' | translate | safeHtml"></h4>
        <mat-form-field class="no-underline">
          <mat-icon matPrefix>lock</mat-icon>
          <input #passcode
                 unmodified-input
                 type="text"
                 maxlength="4"
                 matInput
                 name="passcode"
                 formControlName="passcode"
                 required/>
          <mat-error *ngIf="passcodeLoginForm.controls.passcode.invalid && passcodeLoginForm.controls.passcode.touched">
            <span translate>login.recovery.passcodeRequired</span>
          </mat-error>
        </mat-form-field>
        <div class="actions">
          <button mat-raised-button
                  color="primary"
                  type="button"
                  (click)="passcodeLogin(false)">
            <span translate>actions.cancel</span>
          </button>
          <button mat-raised-button
                  color="primary"
                  type="button"
                  (click)="passcodeLogin(true)"
                  [disabled]="passcodeLoginForm.invalid || passcodeLoginForm.pristine || (loading$ | async)">
            <span translate>login.login</span>
          </button>
          <div class="login-error" [hidden]="!passcodeLoginError || (loading$ | async)">
            {{'login.recovery.loginError' | translate}}
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #facebookLogin>
  <!--
    TODO
    FACEBOOK BUTTON IS NOT CURRENTLY IN USE!
    Shared lib does not expose it because there is a building issue which has to be resolved first!
  -->
<!--  <app-facebook-login-button mode="full"></app-facebook-login-button>-->
</ng-template>

<ng-template #signContract>
  <div class="signature-container">
    <div [innerHTML]="'login.sign.info' | translate | safeHtml"></div>
    <div fxLayoutGap="8px">
      <button fxFlex="auto" mat-raised-button color="primary" (click)="logout()"><span translate>login.sign.decline</span></button>
<!--      <button fxFlex="auto" mat-raised-button color="primary" (click)="sidenav.toggle()"><span translate>login.sign.read</span></button>-->
      <button fxFlex="auto" mat-raised-button color="primary" [disabled]="signing()" (click)="sign()"><span translate>login.sign.accept</span></button>
    </div>
  </div>
</ng-template>

<ng-template #memberLogin let-memberId="memberId" let-onBack="onBack">
  <form (ngSubmit)="login()" [formGroup]="memberLoginForm" novalidate>
    <mat-form-field #memberIdField [hidden]="memberId==false">
      <mat-icon matPrefix>device_hub</mat-icon>
      <mat-label translate>contact.memberId</mat-label>
      <input matInput
             formControlName="member_id"
             type="text"
             name="member_id"
             uncorrected-input>
      <mat-error *ngIf="!memberLoginForm.controls.member_id.valid && !memberLoginForm.controls.member_id.untouched">
        <span translate>contact.validation.memberIdRequired</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="password">
      <mat-icon matPrefix>lock</mat-icon>
      <mat-label translate>security.password</mat-label>
      <!--<input type="password" matInput formControlName="password" autocomplete="new-password"-->
      <input #password
             matInput
             type="password"
             formControlName="password"
             name="password"
             uncorrected-input
             autocomplete="current-password"
             (change)="onPasswordChange($event)"/>
      <mat-hint align="end">
        <a [innerHTML]="'login.recovery.forgotPassword' | translate" (click)="onTapForgotPassword($event)"></a>
      </mat-hint>
      <mat-error *ngIf="!memberLoginForm.controls.password.valid && !memberLoginForm.controls.password.untouched">
        <span translate>password is required</span>
      </mat-error>
    </mat-form-field>
    <div fxLayout="row wrap">
      <button *ngIf="onBack"
              mat-raised-button
              color="primary"
              type="button"
              (click)="onBack($event)">
        <span translate>{{'actions.back'}}</span>
      </button>
      <button fxFlex
              mat-raised-button
              color="primary"
              type="submit"
              [disabled]="memberLoginForm.invalid ||
                          !memberLoginForm.controls.member_id.value ||
                          !memberLoginForm.controls.password.value || (loading$ | async)">
        <!--<app-busy class="inline-loader" [busy]="loading$ | async"></app-busy>-->
        <span translate>login.login</span>
      </button>
    </div>
    <!--<div class="login-error" [hidden]="!error  || busy">-->
    <div class="login-error" [hidden]="!memberLoginError || (loading$ | async)">
      {{(('contact.validation.' + memberLoginError) | translate) ||
         ('contact.validation.memberIdOrPasswordWrong' | translate) }}
    </div>
  </form>
</ng-template>

<ng-template #guestOrMemberLogin>
  <input type="text" name="focus" class="hidden"/>
  <slide-panel [id]="guestOrMemberLoginPanel" [animate]="!disableSlideAnimation" (onSlide)="onGuestOrMemberLoginPanelSlide($event)">
    <div *slidePanelLeft="'invitationCodeOrMemberId'" class="panel invitationCodeOrMemberId">
      <!--
          the following wrapper div for invitationCodeHint p element is used as a workaround
          to eliminate weird height animation interruption when the text is longer
      -->
      <div>
        <p *ngIf="invitationCodeHint$ | async"
           [@enterLeaveHeightAnimation]="{ value:':enter,:leave', params : { height: '150px' }}"
           class="invitationCodeHint" translate>login.invitationCodeHint</p>
      </div>
      <form (ngSubmit)="continue()" [formGroup]="invitationCodeOrMemberIdForm" novalidate>
        <mat-form-field>
          <mat-icon matPrefix>device_hub</mat-icon>
          <mat-label translate>login.invitationCodeOrMemberId</mat-label>
          <input type="text" matInput
                 formControlName="invitationCodeOrMemberId"
                 name="invitationCodeOrMemberId"
                 unmodified-input
                 (paste)="onPasteInvitationCode($event)"
                 (input)="onChangeInvitationCode($event)"/>
          <mat-error *ngIf="!invitationCodeOrMemberIdForm.controls.invitationCodeOrMemberId.valid &&
                            !invitationCodeOrMemberIdForm.controls.invitationCodeOrMemberId.untouched">
            <span translate>login.incorrectInvitationCodeOrMemberId</span>
          </mat-error>
        </mat-form-field>
        <div>
          <button fxFlex mat-raised-button color="primary"
                  type="submit"
                  [disabled]="invitationCodeOrMemberIdForm.invalid ||
                             !invitationCodeOrMemberIdForm.controls.invitationCodeOrMemberId.value ||
                              (loading$ | async)">
            <span translate>{{'actions.continue'}}</span>
          </button>
        </div>
        <div class="login-error" [hidden]="!guestLoginError || (loading$ | async)">
          {{"login.incorrectInvitationCode" | translate}}
        </div>
      </form>
    </div>
    <!--
      ATTENTION:
      If the panel is not lazy and it contains a control with autofocus=true
      the browser (at least chrome) will bring it into view ignoring completely the declared positioning/layout rules>
    -->
    <div *slidePanelRightLazy="'passwordLogin'" class="panel passwordLogin">
      <ng-container *ngTemplateOutlet="memberLogin; context: { memberId: false, onBack: onPasswordLoginBack.bind(this) }"></ng-container>
    </div>
  </slide-panel>
</ng-template>
