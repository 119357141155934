<virtual-section-scroller #scroller
                          [items$]="entities$"
                          [itemsId$]="cacheId$"
                          [startFromTop]="true"
                          [itemDefaultSize]="76"
                          [startGap]="8"
                          [endGap]="8"
                          (onResize)="onResize($event)">
  <div *virtualFor="let session of scroller.viewportItems offset scroller.viewportOffset total scroller.items?.length;
                              let index=index;
                              let total=total;
                              let last = last;
                              trackBy: trackSession"
                              class="session">
    <ng-container *ngTemplateOutlet="sessionTemplate; context: { index: index, session: safeSession(session), last: last }"></ng-container>
  </div>
</virtual-section-scroller>

<ng-template #sessionTemplate let-session="session" let-index="index" let-last="last">
  <div *ngLet="iconSrc(session) as icon">
    <img *ngIf="icon.src" matListItemAvatar [src]="icon.src" onload="this.style.opacity=1"/>
    <mat-icon *ngIf="icon.icon">{{icon.icon}}</mat-icon>
  </div>
  <div class="content">
    <div class="line">
        <span *ngIf="session.device; let device;">
        <span *ngIf="device.manufacturer; let manufacturer;">{{manufacturer}} </span>
        <span *ngIf="device.model; let model;">{{model}}</span>
      </span>
      <span>{{session.agentParsed?.OperatingSystemNameVersion}}</span>
      <span *ngIf="session.localization; let localization;" class="location">
        <span *ngIf="localization.city; let city;">{{city}}, </span>
        <span *ngIf="localization.country_name; let country;">{{country}}</span>
      </span>
    </div>
    <div class="line">
      <span>{{ agent(session) }}</span>
      <ng-container [ngSwitch]="!!session.current">
        <span *ngSwitchCase="true"  class="current" translate>session.current</span>
        <ng-container *ngSwitchCase="false">
          <ng-container *ngIf="lastActiveTime(session); let lastActiveTime"
                        [ngTemplateOutlet]="lastTimeActiveTemplate"
                        [ngTemplateOutletContext]="{ units: lastActiveTime[0], unitName: lastActiveTime[1] }">
            <ng-template #lastTimeActiveTemplate let-units="units" let-unitName="unitName">
              <span [class.active]="isCurrentlyActive(units, unitName)">{{lastActiveTimeText(units, unitName) | async}}</span>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="controls">
    <ng-container *ngTemplateOutlet="controlsTemplate; context: { index: index, session: session }"></ng-container>
  </div>
  <mat-divider *ngIf="!last"></mat-divider>
</ng-template>
