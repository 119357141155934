import {ComponentRef, Injectable, Injector} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {MEDIA_VIEWER_OVERLAY_DATA, MediaViewerOverlayComponent} from "./media-viewer-overlay.component";
import {MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ComponentPortal, PortalInjector} from "@angular/cdk/portal";
import {MediaViewerBottomSheetComponent} from "./media-viewer-bottom-sheet.component";
import {MediaViewerContext} from "../media-viewer-context";
import {MediaViewerContainer} from "../media-viewer-container";
import {MediaViewerOverlayRef} from "./media-viewer-overlay-ref";
import {lastValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MediaViewerOverlayService {

  constructor(private injector: Injector,
              private overlay: Overlay,
              private bottomSheet: MatBottomSheet) {
  }

  public open(context: MediaViewerContext = null): Promise<MediaViewerOverlayRef> {
    const overlayRef = this.createOverlay();
    const dialogRef = new MediaViewerOverlayRef(overlayRef);
    const overlayComponent = this.attachDialogContainer(overlayRef, context , dialogRef);
    return overlayComponent.viewer
      .setMedia(context?.media?.())
      .then(() => dialogRef)
  }

  private createOverlay(): OverlayRef {
    const overlayConfig = this.createOverlayConfig();
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef,
                                context: MediaViewerContext,
                                dialogRef: MediaViewerOverlayRef): MediaViewerOverlayComponent {
    const injector = this.createInjector(context, dialogRef);
    const containerPortal = new ComponentPortal(MediaViewerOverlayComponent, null, injector);
    const containerRef: ComponentRef<MediaViewerOverlayComponent> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createInjector(context: MediaViewerContext, dialogRef: MediaViewerOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(MediaViewerOverlayRef, dialogRef);
    injectionTokens.set(MEDIA_VIEWER_OVERLAY_DATA, context);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private createOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayConfig = new OverlayConfig({
      panelClass: ['media-viewer', 'overlay'],
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy,
      disposeOnNavigation: true
    });
    return overlayConfig;
  }

  public openBottomSheet(context: MediaViewerContext = null): Promise<MediaViewerOverlayRef> {
    let result: MediaViewerOverlayRef = {} as MediaViewerOverlayRef;
    const bottomSheetRef: MatBottomSheetRef = this.bottomSheet.open(MediaViewerBottomSheetComponent, {
      data: {...context || {},
        onComplete: (complete) => {
          result && (result.complete = complete);
          context?.onComplete && context.onComplete(complete);
        }
      },
      //panelClass: ['media-viewer', 'bottom-sheet', 'transparent'],
      panelClass: ['media-viewer', 'bottom-sheet'],
      backdropClass: 'strong-blur-backdrop'
    });
    // MatBottomSheetRef _overlayRef: OverlayRef is private but we can (safely?) assume it will not be changed over the time
    // if we start to experience problems another solution will be considered e.g.
    // we can make MediaViewerOverlayRef ctor to accept also MatBottomSheetRef and then handle it accordingly.
    result = new MediaViewerOverlayRef(bottomSheetRef['_ref'].overlayRef);
    bottomSheetRef.afterDismissed().subscribe(() => result.close());
    return lastValueFrom(bottomSheetRef.afterOpened())
      .then(() => {
        const viewer: MediaViewerContainer = bottomSheetRef.instance?.viewer;
        return new Promise<MediaViewerOverlayRef>((resolve, reject) => {
          if (typeof context?.media == 'function') {
            const total = typeof context.total == 'function' ? context.total() : 0;
            const index = typeof context.index == 'function' ? context.index() : 0; // invoking data.media(idx) moves the current index to idx i.e. data.index() will return idx
            const promise = total > 0
              ? Promise.all(Array.from({ length: total })
                .map((element, index) => context.media(index)))
              : Promise.resolve([context.media()])
            promise.then(entities => {
              // iteration has changed the original index (bug?).
              // now it points to the last media => reset it to original value
              typeof context.index == 'function' && context.index(index);
              viewer.entities = entities.filter(media => !!media?.id);
              viewer.mediaStream = context.mediaStream;
              viewer.index = index;
              resolve(result);
            }).catch(error => reject(error));
          } else {
            resolve(result);
          }
        });
      });
  }
}
