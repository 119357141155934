<app-search-bar #searchBar color="primary"
                [rightIcon]="rightIcon"
                [rightButton]="true"
                [searchButton]="false"
                [search]="false"
                (onLeftClicked)="layoutService.navigation.toggle()"
                [rightButtonTemplateRef]="rightButtonTemplate">
    <span *ngIf="!!title" class="title breadcrumb" [innerHTML]="title | safeHtml"></span>
    <ng-template #rightButtonTemplate>
        <button mat-icon-button class="rightButton" (click)="onRightClicked()">
            <mat-icon>{{rightIcon}}</mat-icon>
        </button>
    </ng-template>
</app-search-bar>

<div [ngSwitch]="state" class="content">
    <ng-container *ngSwitchCase="STATE_JSON" [ngTemplateOutlet]="json"></ng-container>
    <ng-container *ngSwitchCase="STATE_WIZARD" [ngTemplateOutlet]="wizard"></ng-container>
    <!-- <ng-container *ngSwitchCase="STATE_TEMPLATE" [ngTemplateOutlet]="template"></ng-container> -->
</div>
<div class="group-button-bar">
    <button
            mat-mini-fab
            [disabled]="!isSaveable()"
            color="primary"
            (click)="saveGroup()">
        <mat-icon aria-label="invite">done</mat-icon>
    </button>
    <button
            mat-mini-fab
            [disabled]="!isChanged()"
            color="primary"
            (click)="cancel()">
        <mat-icon aria-label="invite">close</mat-icon>
    </button>
    <button
            mat-mini-fab
            color="primary"
            (click)="deleteGroup()">
        <mat-icon aria-label="invite">delete_forever</mat-icon>
    </button>
</div>

<ng-template #json>
    <div class="json-container">
        <form [formGroup]="jsonForm">
            <mat-form-field appearance="fill" class="jsonFormfield">
                <mat-label translate>groups.definition</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="10"
                          formControlName="content">
                </textarea>
            </mat-form-field>
        </form>
    </div>
</ng-template>

<!-- <ng-template #template>
    <div class="template-selection">
        <mat-label translate>groups.template.select</mat-label>
        <mat-select [(ngModel)]="templateGroup">
            <mat-option *ngFor="let templateGroupEntry of templates" [value]="templateGroupEntry">
                {{templateGroupEntry.label | translate}}
            </mat-option>
        </mat-select>
        {{templateGroup}}
        <topic-tree [group]="templateGroup" [category]="'topics'">
        </topic-tree>
    </div>
</ng-template> -->

<ng-template #wizard>
    <mat-vertical-stepper #stepper *ngIf="group">
        <mat-step *ngLet="wizardSteps[0] as step" [stepControl]="step.formGroup!" [editable]=true>
            <ng-template matStepLabel class="stepper-label">{{step.label | translate}}</ng-template>
            <div class="step-content">
                <form [formGroup]="step.formGroup!" class="group-form">
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['id'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['name'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['languages'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['textLanguage'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['textStyle'] }">
                    </ng-container>
                </form>
            </div>

            <div class="step-footer">
                <button mat-button (click)="openTermsOfUseDialog()">{{'groups.termsOfUse.label' |
                    translate}}</button>
                <button mat-button (click)="openPrivacyPolicyDialog()">{{'groups.privacyPolicy.label' |
                    translate}}</button>
                <button mat-button matStepperNext color="primary">{{'actions.next' | translate}}</button>
            </div>
        </mat-step>

       <mat-step *ngLet="wizardSteps[1] as step" [stepControl]="step.formGroup!" [editable]=true>
            <ng-template matStepLabel class="stepper-label">{{step.label | translate}}</ng-template>
            <div class="step-content">
                <form [formGroup]="step.formGroup!" class="group-form">
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['owner'] }">
                    </ng-container>
                   <ng-container
                                  *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['organization'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['phone'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['email'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['link'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['address'] }">
                    </ng-container>
                    <div class="location">
                        <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['zip'] }">
                        </ng-container>
                        <ng-container
                                      *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['community'] }">
                        </ng-container>
                    </div>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['country'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['duns'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['vatid'] }">
                    </ng-container>
                </form>
            </div>

            <div class="step-footer">
                <button mat-button matStepperPrevious>{{'actions.back' | translate}}</button>
                <button mat-button matStepperNext color="primary">{{'actions.next' | translate}}</button>
            </div>
        </mat-step>

       <mat-step *ngLet="wizardSteps[2] as step" [stepControl]="step.formGroup!" [editable]=true>
            <ng-template matStepLabel class="stepper-label">{{step.label | translate}}</ng-template>
            <div class="step-content">
                <form [formGroup]="step.formGroup!" class="color-form">
                    <ng-container
                                  *ngTemplateOutlet="colorPicker; context: { formComponent: step.componentMap['primary'] }">
                    </ng-container>
                    <ng-container
                                  *ngTemplateOutlet="colorPicker; context: { formComponent: step.componentMap['primaryContrast'] }">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="colorPicker; context: { formComponent: step.componentMap['accent'] }">
                    </ng-container>
                    <ng-container
                                  *ngTemplateOutlet="colorPicker; context: { formComponent: step.componentMap['accentContrast'] }">
                    </ng-container>
                </form>
            </div>

            <div class="step-footer">
                <button mat-button matStepperPrevious>{{'actions.back' | translate}}</button>
                <button mat-button matStepperNext color="primary">{{'actions.next' | translate}}</button>
            </div>
        </mat-step>

        <mat-step *ngLet="wizardSteps[3] as step" [editable]=true>
            <ng-template matStepLabel>{{'groups.logo.label' | translate}}</ng-template>

            <div class="step-content">
                <mat-slide-toggle [checked]="darkLogo" (change)="toggleDarkLogo($event)">
                    {{'groups.logo.separate' | translate}}
                </mat-slide-toggle>
                <form [formGroup]="step.formGroup!" class="group-form">

                    <mat-tab-group #logoGroup>
                        <mat-tab [label]="'groups.logo.light' | translate">
                            <ng-container
                                          *ngTemplateOutlet="mediaCardTemplate; context: { media: getLogoLight(), edit: true, style: 'background-color: #FFF' }">
                            </ng-container>

                            <ng-container
                                          *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['lightAppVersionStyle'] }">
                            </ng-container>
                            <ng-container
                                          *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['lightLogoSeparatorStyle'] }">
                            </ng-container>

                        </mat-tab>
                        <mat-tab [disabled]="!darkLogo" [label]="'groups.logo.dark' | translate">
                            <ng-container
                                          *ngTemplateOutlet="mediaCardTemplate; context: { media: getLogoDark(), edit: true, style: 'background-color: #000'  }">
                            </ng-container>

                            <ng-container
                                          *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['darkAppVersionStyle'] }">
                            </ng-container>
                            <ng-container
                                          *ngTemplateOutlet="formField; context: { formComponent: step.componentMap['darkLogoSeparatorStyle'] }">
                            </ng-container>

                        </mat-tab>
                    </mat-tab-group>
                </form>

                <mat-form-field class="group-form-field">
                    <mat-label>{{'groups.logo.version' | translate}}</mat-label>
                    <mat-select [(value)]="group.appVersionLabel">
                        <mat-option [value]="option.value" *ngFor="let option of selectOptions.get('version')">
                            {{option.value | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="step-footer">
                <button mat-button matStepperPrevious>{{'actions.back' | translate}}</button>
                <button mat-button matStepperNext color="primary">{{'actions.next' | translate}}</button>
            </div>
        </mat-step>

        <mat-step *ngFor="let entityGroup of ENTITY_GROUPS; last as isLast" [editable]=true>
            <ng-template matStepLabel>{{'groups.' + entityGroup + '.label' | translate}}</ng-template>
            <div class="step-content">
                <topic-tree [group]="group" [category]="entityGroup" [templates]="templates">
                </topic-tree>
            </div>
            <div class="step-footer" *ngIf="!isLast">
                <button mat-button matStepperPrevious>{{'actions.back' | translate}}</button>
                <button mat-button matStepperNext color="primary">{{'actions.next' | translate}}</button>
            </div>
        </mat-step>

        <!-- <mat-step [editable]=true>
            <ng-template matStepLabel>{{'groups.summary.label' | translate}}</ng-template>
            <div class="step-content">
                <div class="summary-text">
                    {{'groups.summary.content' | translate}}
                </div>
            </div>
            <div class="step-footer">
                <button mat-button matStepperPrevious>{{'actions.back' | translate}}</button>
                <button mat-button (click)="cancel()">{{'actions.cancel' | translate}}</button>
                <button mat-button (click)="saveGroup()" color="primary">{{'actions.save' |
                    translate}}</button>
            </div>
        </mat-step> -->
    </mat-vertical-stepper>
</ng-template>

<ng-template #formField let-formComponent="formComponent">
    <mat-form-field class="group-form-field">
        <!-- <mat-icon *ngIf="{{formComponent.icon}}" matPrefix>{{formComponent.icon}}</mat-icon> -->
        <mat-label>{{formComponent.label | translate}}</mat-label>

        <input matInput [id]="formComponent.name" *ngIf="formComponent.type === 'input'"
               [formControl]="formComponent.formControl"
               [required]="!!formComponent.required">

        <mat-select [id]="formComponent.name" *ngIf="formComponent.type === 'select' && !formComponent.multiple"
                    [formControl]="formComponent.formControl" [placeholder]="formComponent.label | translate"
                    [required]="!!formComponent.required">
            <mat-option [value]="option.value" *ngFor="let option of selectOptions.get(formComponent.name)">
                {{option.viewValue | translate}}</mat-option>
        </mat-select>

        <mat-select [id]="formComponent.name" *ngIf="formComponent.type === 'select' && !!formComponent.multiple"
                    [formControl]="formComponent.formControl" [placeholder]="formComponent.label | translate"
                    multiple
                    [required]="!!formComponent.required">
            <mat-option [value]="option.value" *ngFor="let option of selectOptions.get(formComponent.name)">
                {{option.viewValue | translate}}</mat-option>
        </mat-select>

        <textarea matInput
                  [id]="formComponent.name"
                  *ngIf="formComponent.type === 'text'"
                  [formControl]="formComponent.formControl"
                  [required]="formComponent.required ? formComponent.required : false"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="2"
                  cdkAutosizeMaxRows="8">
            </textarea>
        <mat-error *ngIf="formComponent.formControl.invalid && formComponent.formControl.touched">
            <span>{{formComponent.errorText | translate}}</span>
        </mat-error>
    </mat-form-field>
</ng-template>

<ng-template #colorPicker let-formComponent="formComponent">
    <span class="field-label" translate>{{formComponent.label}}</span>
    <ngx-colors ngx-colors-trigger
                style="display: inline-block; margin-right: 10px; margin-top: 7px; height: 30px;"
                [format]="hex"
                [hideTextInput]="true"
                [acceptLabel]="translateService.instant('actions.ok')"
                [cancelLabel]="translateService.instant('actions.cancel')"
                [overlayClassName]="'ngx-color-picker-overlay'"
                [(ngModel)]="formComponent.formControl.value">
    </ngx-colors>
    <mat-form-field [floatLabel]="'never'" class="color-input">
        <input matInput [id]="formComponent.name"
               maxlength="9"
               [formControl]="formComponent.formControl"
               [value]="formComponent.formControl.value">
    </mat-form-field>
</ng-template>

<ng-template #mediaCardTemplate let-media="media" let-style="style">
    <media-card *ngIf="media || true" [media]="media" [elevated]="false" [selectable]="false"
                (play)="onMediaPlay(media)"
                [style]="style">
        <div mediaCardElement [position]="['bottom', 'right']">
            <button *ngIf="media!=(defaultMedia | async)" mat-icon-button (click)="onMediaRemove(media)"
                    class="delete mat-accent">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button (click)="onUploadMedia(media)" class="edit mat-primary">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </media-card>
</ng-template>
