/* projects/media/src/lib/components/video/video.component.scss */
:host {
  display: block;
  padding: 0;
}
.video-js.fill {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
}
.vjs-control-bar {
  background-color: rgba(43, 51, 63, 0.7);
}
@supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
  .vjs-control-bar {
    background-color: rgba(43, 51, 63, 0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}
/*# sourceMappingURL=video.component-ALCYB6IU.css.map */
