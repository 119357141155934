import {ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {CountryFilterComponent} from './country/country-filter/country-filter.component';
import {HierarchicalFilterComponent} from './hierarchical-filter/hierarchical-filter.component';
import {
  AccordionSectionComponentFactory, AccordionSectionContext,
  AccordionSectionFactoryService
} from "shared";
import {BasicFilterComponent} from './core/basic-filter.component';
import { TagFilterComponent } from './tag-filter/tag-filter.component';
import { TopicFilterComponent } from './topic-filter/topic-filter.component';
import {Topic} from "core";
import {Observable} from "rxjs";

export interface AccordionFilterSectionContext extends AccordionSectionContext {
  topics:Topic[];         // 0..n topics
  search?:boolean;        // default: false
  multiselect?:boolean;   // default: false
  updateFilters:(filters:string[])=>Promise<boolean>;
  selectedFilters$: Observable<string[]>;  // all selected filters for this section...
}

export abstract class FilterComponentFactory implements AccordionSectionComponentFactory {
  abstract isSingleTopicView():boolean;
  abstract canHandle(context: any): boolean;
  abstract createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<BasicFilterComponent>;
}

@Injectable({
  providedIn: 'root'
})
export class FilterComponentFactoryService implements AccordionSectionFactoryService {

  private filterFactories: { [key: string]: FilterComponentFactory | FilterComponentFactory[] } = {};

  constructor() {
    this.registerFactory('tags',this.createTagsComponentFactory());
    this.registerFactory('visibility',this.createTagsComponentFactory());
    this.registerFactory('country',this.createCountryComponentFactory());
    this.registerFactory('topics',this.createTopicComponentFactory());
    //this.registerFactory('list',this.createFilterListComponentFactory());
    this.registerFactory('hierarchical',this.createHierarchicalComponentFactory());
  }

  public registerFactory(key:string, factory:FilterComponentFactory) {
    this.filterFactories[key] = factory;
  }

  getViewerFactory(type: string, context?: any): FilterComponentFactory {
    return this.getFactory(type, context, this.filterFactories);
  }

  protected getFactory(type:string, context?:any,
    factories?:{[key:string]:FilterComponentFactory|FilterComponentFactory[]}): FilterComponentFactory {
    const sameTypeFactories = factories[type];
    return Array.isArray(sameTypeFactories) ?
      sameTypeFactories.find(factory=>factory.canHandle(context)) :
      sameTypeFactories.canHandle(context) ? sameTypeFactories : undefined;
  }

  /*
  createFilterListComponentFactory(): FilterComponentFactory {
    return new class extends FilterComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<BasicFilterComponent> {
        return resolver
          .resolveComponentFactory(FilterListComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
      isSingleTopicView(): boolean {
        return true;
      }
    }
  }*/

  createHierarchicalComponentFactory(): FilterComponentFactory {
    return new class extends FilterComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<BasicFilterComponent> {
        return resolver
          .resolveComponentFactory(HierarchicalFilterComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
      isSingleTopicView(): boolean {
        return true;
      }
    }
  }

  createFilterTagComponent(): FilterComponentFactory {
    return new class extends FilterComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<BasicFilterComponent> {
        return resolver
          .resolveComponentFactory(CountryFilterComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
      isSingleTopicView(): boolean {
        return false;
      }
    }
  }

  createCountryComponentFactory(): FilterComponentFactory {
    return new class extends FilterComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<BasicFilterComponent> {
        return resolver
          .resolveComponentFactory(CountryFilterComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
      isSingleTopicView(): boolean {
        return true;
      }
    }
  }

  createTopicComponentFactory(): FilterComponentFactory {
    // mb: do not use new class FilterComponentFactory - it leaks the constructor function
    // ( i.e. the caller can say: new (FilterComponentFactory.constructor)(); ) which is not desirable
    // better use literal object like the one below
    return  {
      createComponent: (injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<BasicFilterComponent> => {
        return resolver
          .resolveComponentFactory(TopicFilterComponent)
          .create(injector);
      },
      canHandle: (context: any): boolean => {
        return true;
      },
      isSingleTopicView: (): boolean => {
        return true;
      }
    } as FilterComponentFactory;
  }

  createTagsComponentFactory(): FilterComponentFactory {
    return new class extends FilterComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<BasicFilterComponent> {
        return resolver
          .resolveComponentFactory(TagFilterComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
      isSingleTopicView(): boolean {
        return false;
      }
    }
  }
}
