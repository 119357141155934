import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  OnDestroy,
  Output
} from '@angular/core';
import {DragEvent} from "../../models/touch/drag-event";
import {TouchTracker} from "../../models/touch/touch-tracker";

/**
 * add press support to component
 */
@Directive({
  selector: '[unmodified-input]',
  host: {
    "autocomplete":"off",
    "autocorrect":"off",
    "autocapitalize":"off",
    "spellcheck":"false",
  }
})
export class UnmodifiedInputDirective {
}
