<!--<mat-list class="mat-accent">-->
<!--  <ng-container *ngFor="let timezone of timezones | async;-->
<!--                                let index = index;-->
<!--                                let first = first;-->
<!--                                let last = last;-->
<!--                                let even = even;-->
<!--                                let odd = odd;">-->
<!--    <ng-container [ngTemplateOutlet]="timezoneTemplate"-->
<!--                  [ngTemplateOutletContext]="{ timezone: timezone, index: index }" (click)="onSelectionChange(index, timezone, $event)">-->
<!--    </ng-container>-->
<!--    <mat-divider *ngIf="!last"></mat-divider>-->
<!--  </ng-container>-->
<!--</mat-list>-->

<!--<ng-template #timezoneTemplate let-timezone="timezone">-->
<!--  <mat-list-item *ngFor="let zone of timezone?.zones">-->
<!--    <span class="name">{{timezone.countryCode | country}} - {{timezoneName(zone)}}</span>-->
<!--    <span class="offset">{{timezoneOffset(zone)}}</span>-->
<!--  </mat-list-item>-->
<!--</ng-template>-->

<app-timezone-list [timezones$]="timezones$"
                   [lru]="lru$ | async"
                   (selectionChange)="onSelectionChange($event)">
  <ng-template #controlsTemplate let-index="index" let-timezone="timezone" let-isLru="isLru">
    <button tabindex="-1" *ngIf="isLru && timezone.id!=currentTimezone"
            mat-icon-button
            (click)="onRemoveLru(index, timezone, $event)"
            class="lru-remove">
      <mat-icon>clear</mat-icon>
    </button>
  </ng-template>
</app-timezone-list>
