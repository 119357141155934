import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  SimpleChange,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {OnDomModified} from "../../directives/dom/dom_hooks";
import {Logger, manualSlice, Platform} from "core";
import {Alignment} from "./virtual-scroll.enums";
import {SubscribableValue} from "./virtual-scroll.handler";
import {BehaviorSubject, Observable} from "rxjs";
import {BasicContainerComponent, ObservableSubscription} from "../basic-container/basic-container.component"
import {VirtualRenderEvent, VirtualScrollerComponent, VirtualScrollEvent} from "./virtual-scroller";
import {getElementSize} from "../../util/element.util";
import {Size} from "../../types/size";

@Component({
  selector: 'virtual-grid',
  template: `
    <virtual-scroller class="scroller" #scroller
        [log]="log"
        [items$]="rows$"
        [itemsId$]="itemsId$"
        [vertical]="vertical"
        [itemDefaultSize]="itemDefaultSize"
        [bufferSize]="bufferSize"
        [minimumBufferSize]="minimumBufferSize"
        [startFromTop]="startFromTop"
        [sideGap]="sideGap"
        [startGap]="startGap"
        [endGap]="endGap"
        [getRenderedItemCount]="getRenderedItemCount"
        [getRenderedItemSize]="getRenderedItemSize"
        [compareItem]="compareItem"
        (onViewportUpdated)="onRowsViewportChanged()"
        (onViewportChanged)="onRowsViewportChanged()"
        [viewportStartComponentTemplateRef]="viewportStartComponentTemplateRef"
        [viewportEndComponentTemplateRef]="viewportEndComponentTemplateRef"
        [renderStartComponentTemplateRef]="renderStartComponentTemplateRef"
        [renderEndComponentTemplateRef]="renderEndComponentTemplateRef">
      <div class="tile-container" #elements>
        <ng-content></ng-content>
      </div>
    </virtual-scroller>
  `,
  host: {
    '[class.horizontal]': "!vertical",
    '[class.vertical]': "vertical"
  },
  styleUrls: ['./virtual-grid.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualGridComponent extends BasicContainerComponent implements OnDomModified {

  @ViewChild('scroller', {read: VirtualScrollerComponent, static: true}) scrollerRef: VirtualScrollerComponent;
  @ViewChild('elements', {read: ElementRef, static: true}) elementsRef: ElementRef;

  public items:any[] = [];
  public itemsId:string = undefined;
  public rows:any[] = [];
  public rows$ = new BehaviorSubject<any[]>([]);
  public viewportItems$ = new BehaviorSubject<any[]>([]);
  public viewportOffset$ = new BehaviorSubject<number>(0);

  protected rowsViewportChanged():void {
    const rowsOffset      = this.scrollerRef.viewportOffset;
    const rowsCount       = this.scrollerRef.viewportItems.length;
    const viewportColumns = this.columns;
    const viewportOffset  = Math.min(this.items.length,rowsOffset * viewportColumns);
    const viewportEnd     = Math.min(this.items.length,viewportOffset+(rowsCount*viewportColumns));
    if (this.viewportOffset$.value!=viewportOffset) {
      this.viewportOffset$.next(viewportOffset);
    }
    this.viewportItems$.next(manualSlice(this.items,viewportOffset,viewportEnd));
    //console.log("DISPLAY:rowsViewportChanged",this.viewportItems,this.viewportItems.length,"offset",viewportOffset,"items",this.items,this.items.length,
    //  "viewportColumns",viewportColumns,"rowsOffset",rowsOffset,"rowsCount",rowsCount,
    //  "\n-------------------------");
    //console.log("rowsViewportChanged",this.columns,"scroller.offset",this.scrollerRef.viewportOffset,
    //  "scroller.items",this.scrollerRef.viewportItems.length,
    //  "viewportOffset",viewportOffset,"viewportEnd",viewportEnd,"viewportItems",this.viewportItems);
  }

  @Input()
  public set items$(value: Observable<any[]>) {
    this.itemsOS.set(value);
  }
  public get items$(): Observable<any[]> {
    return this.itemsOS.get();
  }
  protected itemsOS:ObservableSubscription<any[]> = this.createObservableSubscription((previousValue,currentValue, first)=> {
    this.triggerChange('items',new SimpleChange(previousValue,currentValue,first));
    const items = currentValue || [];
    const rows  = Math.ceil(items.length / this.columns);
    const array = new Array(rows);
    const random = Math.round(Math.random()*1_000_000);
    for (let i=0; i<rows; i++) {
      array[i] = random+i;
    }
    if (this.log) console.log("ITEMS",items.length,"ROWS",rows,array.length,"items",currentValue,previousValue,"\nsame",this.items === items);
    this.items = items;
    this.rows$.next(array);
    this.rowsViewportChanged();
  },[]);

  @Input()
  public set itemsId$(value: Observable<string>) {
    //console.log("ITEMS.ID$.equals",this.itemsIdOS.get() === value);
    this.itemsIdOS.set(value);
  }
  public get itemsId$(): Observable<string> {
    return this.itemsIdOS.get();
  }
  protected itemsIdOS:ObservableSubscription<string> = this.createObservableSubscription((previousValue,currentValue, first)=> {
    //console.log("ITEMS.ID$.previous",previousValue,"current",currentValue,"equals",previousValue === currentValue);
    this.triggerChange('itemsId',new SimpleChange(previousValue,currentValue,first));
    //console.log("rows.itemsId.previous",previousValue,"current",currentValue);
    this.itemsId = currentValue;
    if (previousValue!=currentValue) {
      this.changeDetectorRef.markForCheck();
    }
  });

  @Input()
  public vertical:boolean = true;
  @Input()
  public scrollbar:boolean = true;
  @Input()
  public startFromTop:boolean = true;
  @Input()
  public sideGap:number = 0;
  @Input()
  public startGap:number = 0;
  @Input()
  public endGap:number = 0;
  @Input()
  public viewportStartComponentTemplateRef: TemplateRef<any>;
  @Input()
  public viewportEndComponentTemplateRef: TemplateRef<any>;
  @Input()
  public renderStartComponentTemplateRef: TemplateRef<any>;
  @Input()
  public renderEndComponentTemplateRef: TemplateRef<any>;
  @Input()
  public bufferSize:number = 600;
  @Input()
  public minimumBufferSize:number = 10;
  @Input()
  public itemDefaultSize:number = 80;

  private static counter: number = 0;
  protected logger:Logger = new Logger('VirtualGridComponent.'+VirtualGridComponent.counter++);

  public onRowsViewportChanged: () => void = this.rowsViewportChanged.bind(this);
  @Input()
  public getRenderedItemCount: (container:HTMLElement) => number = this.renderedItemCount.bind(this);
  @Input()
  public getRenderedItemSize: (container:HTMLElement,index:number) => ClientRect = this.renderedItemSize.bind(this);

  protected renderedItemCount(container:HTMLElement):number {
    const columns = this.columns;
    const length  = container?.children?.item(0)?.children?.length || 0;
    const count   = Math.ceil(length / columns);
    //const count = container?.children?.item(0)?.children?.length ?? 0;
    //console.log("renderedItemCount",container?.tagName,"columns",columns,this._columns,"length",length,"count",count,"this",this);
    return count;
  }

  protected renderedItemSize(container:HTMLElement,index:number):Size {
    const columns = this.columns;
    const element = <HTMLElement>container?.children?.item(0)?.children?.item(index*columns);
    if (!!element) {
      return getElementSize(element);
    } else {
      this.logger.error("renderedItemSize.container",container,"index",index,"columns",columns,"at",index*columns,"items",this.items,"rows",this.rows);
    }
    return {width:0,height:0};
  }

  protected _columns: number = 0;
  @Input()
  public get columns(): number {
    return this._columns;
  }
  public set columns(value: number) {
    //console.log("set column renderedItem",value);
    // 1...10 columns allowed.... see scss...
    value = Math.min(10,Math.max(1,value));
    if (value!=this._columns) {
      const oldClassName = "c"+this._columns;
      const newClassName = "c"+value;
      this._columns = value;
      this.elementRef?.nativeElement.classList.remove(oldClassName);
      this.elementRef?.nativeElement.classList.add(newClassName);
      const rows  = Math.ceil(this.items.length / this.columns);
      const array = new Array(rows);
      for (let i=0; i<rows; i++) {
        array[i] = i;
      }
      //console.log("ITEMS",items.length,"ROWS",rows,array.length,"items",currentValue,previousValue);
      //console.log("COLUMNS",value,"ROWS",rows,array);
      this.rows$.next(array);
      //this.scrollerRef.onResize(undefined);
    }
    //console.log("set column renderedItem",value);
  }

  @Output()
  public onViewportChanged = new EventEmitter<void>();

  protected _viewportItems:any[] = [];
  protected _viewportOffset:number = 0;

  @Input()
  public scrollAnimationTime: number = 750;

  protected _scrollThrottlingTime: number = 0;
  @Input()
  public get scrollThrottlingTime(): number {
    return this._scrollThrottlingTime;
  }
  public set scrollThrottlingTime(value: number) {
    this._scrollThrottlingTime = value;
    //this.updateOnScrollFunction();
  }

  protected _scrollDebounceTime: number = 0;
  @Input()
  public get scrollDebounceTime(): number {
    return this._scrollDebounceTime;
  }
  public set scrollDebounceTime(value: number) {
    this._scrollDebounceTime = value;
    //this.updateOnScrollFunction();
  }

  protected _scrollPosition$:SubscribableValue<number> = SubscribableValue.of(0);

  @Input()
  public compareItem: (item1: any, item2: any) => boolean = (item1: any, item2: any) => item1 === item2;

  @Output()
  public onScrolled: EventEmitter<VirtualScrollEvent> = new EventEmitter<VirtualScrollEvent>();

  @Output()
  public onRendered: EventEmitter<VirtualRenderEvent> = new EventEmitter<VirtualRenderEvent>();

  @Output()
  public afterDomModified: EventEmitter<void> = new EventEmitter<void>();

  public onAttach(): void {
    /*
    const scrollPosition = this.getScrollStartPosition();
    if (this._previousViewport?.scrollStartPosition!==undefined &&
      this._previousViewport.scrollStartPosition!=scrollPosition) {
      this.setScrollStartPosition(this._previousViewport.scrollStartPosition);
    }
    this.triggerRefreshInternal(false, false, false);
    //this.virtualScrollHandler.resetPosition();*/
  }

  public ngOnChanges(changes: any): void {
    if (this.log) console.log("DISPLAY:rows.items.changed!!!",this.itemsId,changes);
    if (!!changes.items && !changes.itemsId &&
          changes.items.currentValue?.length==this.items.length) {
      this.rowsViewportChanged();
    }
  }

  public onDomModified(): void {
    //console.log("SCROLLER.DOMLOG.ON_DOM_MODIFIED",this.viewportItems?.length);
    this.afterDomModified.emit();
  }

  public scrollToIndex(index: number, alignment: Alignment = Alignment.start, additionalOffset: number = 0, animationMilliseconds: number = undefined, animationCompletedCallback: () => void = undefined): void {
  }

  public scrollToPosition(scrollPosition: number, animationMilliseconds: number = undefined, animationCompletedCallback: () => void = undefined): void {
  }

  constructor(
    public readonly elementRef: ElementRef,
    protected readonly platform: Platform,
    protected readonly renderer: Renderer2,
    protected changeDetectorRef: ChangeDetectorRef) {
    super();
    //this.logger.debug("ctor()");
  }

  ngOnDestroy() {
    this.logger.debug("destroy()");
    super.ngOnDestroy();
  }

  protected needsTouchScrollHandler():boolean {
    return this.platform.is('ios') || (this.platform.is('tablet'));
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.scrollerRef.synchronizeOnUpdate = false;
  }

  public refresh() {
    //this.triggerRefreshInternal(false,true, false);
  }
}
