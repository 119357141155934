import {NgModule} from "@angular/core";
import {SafeHtmlPipe} from './safe-html.pipe';
import {EllipsisPipe} from './ellipsis.pipe';
import {YearMonthDayFormatPipe} from './year-month-day-format.pipe';
import {PhoneNumberFormatPipe} from "./phone-number-format.pipe";
import {TranslateFragmentPipe} from "./translate-fragment.pipe";
import {DatePipe} from "./date.pipe";
import {FilterPipe} from "./filter.pipe";
import {BytesPipe} from "./bytes.pipe";
import {PluckPipe} from "./pluck.pipe";
import {EmojiFlagPipe} from "./emojiflag.pipe";
import {BirthdayPipe} from "./birthday.pipe";
import {ParameterPipe} from "./parameter.pipe";
import {CountryPipe} from "./country.pipe";
import {MarkupPipe} from "./markup.pipe";
import {SafeUrlPipe} from "./safe-url.pipe";
import {SafeResourceUrlPipe} from "./safe-resource-url.pipe";

export const PIPES = [
  SafeHtmlPipe,
  SafeUrlPipe,
  SafeResourceUrlPipe,
  EllipsisPipe,
  ParameterPipe,
  YearMonthDayFormatPipe,
  PhoneNumberFormatPipe,
  TranslateFragmentPipe,
  DatePipe,
  BirthdayPipe,
  FilterPipe,
  PluckPipe,
  BytesPipe,
  EmojiFlagPipe,
  CountryPipe,
  MarkupPipe
];

@NgModule({
  declarations: PIPES,
  exports: PIPES,
})
export class PipesModule {}
