/* projects/shared/src/lib/components/timezone/timezone-list/timezone-list.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: block;
  position: relative;
}
virtual-section-scroller {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
div.timezone-container {
  position: relative;
}
div.timezone-container > div {
  position: relative;
  height: auto;
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 16px 3px 8px;
}
div.timezone-container div.country {
  background-color: var(--color-primary-300);
  color: white;
  font-weight: bold;
}
div.timezone-container div.timezone {
  cursor: pointer;
}
div.timezone-container div.timezone span.name {
  flex: 1;
}
div.timezone-container div.timezone .controls {
  margin-right: 0;
  margin-left: 12px;
  flex: 0;
}
div.timezone-container mat-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: block;
  margin: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
}
div.timezone-container mat-divider.section {
  border-top-color: var(--color-primary-300);
  border-top-width: 2px;
}
div.timezone-container mat-divider.section.strong {
  border-top-color: var(--color-primary-700);
}
div.timezone-container.multi .timezone {
  padding-left: 20px;
  font-weight: lighter;
}
div.timezone-container.multi mat-divider {
  right: 0;
  left: unset;
  width: calc(100% - 20px);
}
:host-context(.color-scheme-dark) mat-list-item:hover {
  background: rgba(0, 0, 0, 0.12);
  color: #bdbdbd;
}
/*# sourceMappingURL=timezone-list.component-J6NJLW47.css.map */
