import {Injectable} from "@angular/core";
import {lastValueFrom} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {isValidNumber} from "../util/number.util";
import {Platform} from "core";
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@awesome-cordova-plugins/file-opener/ngx';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private httpClient: HttpClient,
              private file: File,
              private fileOpener: FileOpener,
              private platform: Platform) {
  }

  download(url: string, host: Element, options?: Partial<{ filename: string, unblockDelay:number, open: boolean }>): Promise<Blob> {
    const DOWNLOADING = "downloading";
    if (!host.classList.contains(DOWNLOADING)) {
        host.classList.add(DOWNLOADING);
        return new Promise<Blob>((resolve, reject) => {
            this.downloadBlob(url)
              .then(async([blob, filename]) => {
                filename = filename || options?.filename || url.substring(url.lastIndexOf('/')+1);
                if (!this.platform.is('hybrid')) {
                    var anchor = document.createElement("a");
                    host.appendChild(anchor);
                    anchor.style.display = "none";
                    const blobUrl = window.URL.createObjectURL(blob);
                    anchor.href = blobUrl;
                    anchor.download = filename;
                    anchor.click();
                    window.URL.revokeObjectURL(blobUrl);
                    anchor.remove();
                } else {
                    const directory = this.file.dataDirectory;
                    await this.file.writeFile(directory, filename, blob, {replace: true});
                    if (options?.open) {
                        await this.fileOpener.open(`${directory}/${filename}`, 'text/calendar');
                    }
                }
                resolve(blob);
              })
              .catch(reject)
              .finally(()=>{
                window.setTimeout(
                    () => host.classList.remove(DOWNLOADING),
                    isValidNumber(options?.unblockDelay)
                              ? Math.min(10_000,Math.max(100, options?.unblockDelay))
                              : 2000   // 2 seconds delay by default
                );
              });
        });
    }
    return Promise.reject("download in progress");
  }

  downloadBlob(url: string): Promise<[Blob, string]> {
    if (url.length > 0) {
      return lastValueFrom(this.httpClient.get(url,{ responseType: 'blob', observe: 'response' }))
          .then((response) => {
              const result: [Blob, string] = [response.body, undefined];
              const contentDisposition = response.headers.get('"Content-Disposition"');
              if (contentDisposition &&
                 (contentDisposition.indexOf('attachment') !== -1 ||
                  contentDisposition.indexOf('inline') !== -1)) {
                  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                  const matches = filenameRegex.exec(contentDisposition);
                  if (matches != null && matches[1]) {
                      result[1] = matches[1].replace(/['"]/g, '');
                  }
              }
              return result;
            })
    } else {
      return Promise.reject("failed to download blob");
    }
  }
}
