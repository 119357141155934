import {AsyncStorage, AsyncStorageFactory} from "../async/storage";
import {Injectable} from "@angular/core";
import * as database from "idb-keyval";
import {Logger} from "../../logger.service";

@Injectable({
  providedIn: 'root'
})
export class IndexedDBStorageFactory extends AsyncStorageFactory {

  protected indexedDB$:Promise<boolean>;
  protected dbs = {};

  protected logger = new Logger('IndexedDBStorageFactory').setSilent(true);
  constructor() {
    super();
    this.logger.info("IndexedDBStorageFactory");
    this.indexedDB$ = this.testIndexedDB();
  }

  request<T=any>(dbName:string,storeName:string):AsyncStorage<T> {
    const indexedDB$ = this.indexedDB$;
    this.dbs[dbName] = this.dbs[dbName] ?? {};
    let store:Map<string,any> = this.dbs[dbName][storeName] = this.dbs[dbName][storeName] ?? new Map<string,any>();
    return new class implements AsyncStorage<T> {
      protected logger = new Logger('AsyncStorage').setSilent(true);
      protected _indexedDBStore:database.UseStore;
      protected indexedDBStore() {
        return this._indexedDBStore ?? (this._indexedDBStore = database.createStore(dbName, storeName));
      }
      clear(): Promise<void> {
        return new Promise((resolve, reject) => {
          indexedDB$.then(ok=>{
            if (ok) {
              this.logger.info("IDB.clear");
              database.clear(this.indexedDBStore())
                .then(resolve)
                .catch(reject);
            } else {
              store.clear();
              resolve();
            }
          })
        });
      }

      get(key: string): Promise<T> {
        return new Promise<T>((resolve, reject) => {
          indexedDB$.then(ok=>{
            if (ok) {
              this.logger.info("IDB.get",key);
              database.get(key,this.indexedDBStore())
                .then(resolve)
                .catch(reject);
            } else {
              resolve(store.get(key));
            }
          })
        });
      }

      has(key: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
          indexedDB$.then(ok=>{
            if (ok) {
              database.get(key,this.indexedDBStore())
                .then(value=>resolve(!!value))
                .catch(reject);
            } else {
              resolve(store.has(key));
            }
          })
        });
      }

      remove(keys?: string | string[]): Promise<void> {
        return new Promise((resolve, reject) => {
          indexedDB$.then(ok=>{
            if (ok && Array.isArray(keys)) {
              this.logger.info("IDB.remove",keys);
              database.delMany(keys,this.indexedDBStore())
                .then(resolve)
                .catch(reject);
            } else if (ok) {
              this.logger.info("IDB.remove",keys);
              database.del(keys,this.indexedDBStore())
                .then(resolve)
                .catch(reject);
            } else if (Array.isArray(keys)) {
              keys.forEach(key=>store.delete(key));
              resolve();
            } else {
              store.delete(keys);
              resolve();
            }
          })
        });
      }

      set(key: string, value: T): Promise<void> {
        return new Promise((resolve, reject) => {
          indexedDB$.then(ok=>{
            if (ok) {
              this.logger.info("IDB.set",key,value);
              database.set(key,value,this.indexedDBStore())
                .then(resolve)
                .catch(reject);
            } else {
              store.set(key,value);
              resolve();
            }
          })
        });
      }

      values(keys?: string[]): Promise<T[]> {
        return new Promise<T[]>((resolve, reject) => {
          indexedDB$.then(ok=>{
            this.logger.info("IDB.values",keys);
            if (ok && Array.isArray(keys)) {
              database.getMany(keys,this.indexedDBStore())
                .then(resolve)
                .catch(reject);
            } else if (ok) {
              database.values(this.indexedDBStore())
                  .then(resolve)
                .catch(reject);
            } else if (!!keys) {
              resolve([...store.values()]);
            } else {
              const array:T[] = [];
              keys.forEach(key=>array.push(store.get(key)));
              resolve(array);
            }
          })
        });
      }
    }
  }

  protected report(ok:boolean):boolean {
    this.logger.info("IDB.DATABASE",ok);
    return ok;
  }

  protected testIndexedDB():Promise<boolean> {
    return new Promise<boolean>(resolve => {
      try {
        let testStore:database.UseStore = database.createStore('test', 'test');
        database.set('test','test')
          .then(()=>resolve(this.report(true)))
          .catch(error=>resolve(this.report(false)));
      } catch(error) {
        resolve(this.report(false));
      }
    });

      /*
    protected syncStore:database.UseStore = database.createStore('sync', 'key_segments');
    protected sendStore:database.UseStore = database.createStore('send', 'key_messages');
      const indexedDB = window.indexedDB;
      if (!indexedDB) {
        resolve(false);
      } else {
        const request = indexedDB.open("app", 1);
        request.onerror = function (error) {
          resolve(false);
        };
        request.onupgradeneeded = function () {
          const db = request.result;
          db.createObjectStore("app", { keyPath: "id" });
        };
        request.onsuccess = function () {
          const db = request.result;
          const transaction = db.transaction("app", "readwrite");
          const store = transaction.objectStore("app");
          store.put({ id: 1, test: 'done'});
          transaction.oncomplete = function () {
            db.close();
            resolve(true);
          };
        };
      }
    });*/

    /*
    return new Promise<boolean>(resolve => {
      let done = false;
      return new Promise<boolean>(resolve => {
        try {
          const indexedDB = window.indexedDB;
          const self = this;
          if (!indexedDB) {
            resolve(this.report(false));
          } else {
            const request = indexedDB.open("app", 1);
            request.onerror = function (error) {
              this.logger.info("IDB.error",error);
              resolve(self.report(false));
            };
            request.onupgradeneeded = function () {
              const db = request.result;
              try {
                db.createObjectStore("app", { keyPath: "id" });
              } catch(error) {
                this.logger.info("IDB.error",error);
                resolve(self.report(false));
              }
            };
            request.onsuccess = function () {
              const db = request.result;
              const transaction = db.transaction("app", "readwrite");
              const store = transaction.objectStore("app");
              store.put({ id: 1, test: 'done'});
              transaction.oncomplete = function () {
                db.close();
                resolve(self.report(true));
              };
            };
          }
        } catch(error) {
          this.logger.info("IDB.error",error);
          resolve(this.report(false));
        }
      }).then(result=>{
        if (!done) {
          done = true;
          resolve(result);
        }
      });
    });*/
  }
}
