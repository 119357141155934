<ng-container *ngIf="filter$ | async; let filter">
  <ng-container *ngTemplateOutlet="filterTagsTemplateRef; context: { filters: filter.topics, level: 0, selected:selected??EMPTY_SELECTION, readOnly:readOnly }"></ng-container>
  <!--<ng-container *ngTemplateOutlet="filterTagsTemplate; context: { filters: filter.topics, level: 0 }"></ng-container>-->
</ng-container>

<ng-template #filterTagsTemplate let-filters="filters" let-selected="selected" let-level="level" let-readOnly="readOnly">
  <ng-container *ngFor="let filter of filters;">
    <div class="filter level{{level}}"
         [class.editable]="filter.editable"
         [class.selected]="selected.includes(filter.id)"
         [pressTriggerTime]="1000"
         (onPress)="onTapFilter(filter,$event.auto)">
      {{filter.label | translate}}
    </div>
    <ng-container *ngTemplateOutlet="filterTagsTemplateRef; context: { filters: filter.topics, level: level+1, selected: selected, readOnly: readOnly }"></ng-container>
  </ng-container>
</ng-template>
