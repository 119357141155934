export class Matrix {

  constructor(
    public scaleX:number = 1,
    public skewX:number = 0,
    public skewY:number = 0,
    public scaleY:number = 1,
    public translateX:number = 0,
    public translateY:number = 0
  ) {}

  public static fromString(matrixString : string) {
    let parsed = (matrixString || 'matrix(1,0,0,0,0,0)').match(/(-?[0-9\.]+)/g);
    let matrix = new Matrix();
    if (parsed && parsed.length==6) {
      //console.log(parsed);
      //console.log(matrix);
      matrix.scaleX = parseFloat(parsed[0] || '0');
      matrix.skewX  = parseFloat(parsed[1] || '0');
      matrix.skewY  = parseFloat(parsed[2] || '0');
      matrix.scaleY = parseFloat(parsed[3] || '0');
      matrix.translateX = parseFloat(parsed[4] || '0');
      matrix.translateY = parseFloat(parsed[5] || '0');
      //console.log(matrix);
    }
    return matrix;
  }
}
