/* projects/shared/src/lib/components/badge/badge.container.component.scss */
:host {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.content {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}
/*# sourceMappingURL=badge.container.component-LZUQKPH3.css.map */
