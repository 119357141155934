<div *ngIf="media && mediaForm" [ngSwitch]="!!editMode" class="container">
    <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="view"></ng-container>
    <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="edit"></ng-container>
</div>

<ng-template #view>
    <ng-container *ngIf="media.countryCodes && media.countryCodes.length>0">
        <h4 class="header" matSubheader disabled translate>
            actions.filter.{{media.countryCodesExcluded ? 'notFor' : 'onlyFor'}}
        </h4>
        <div class="section filters">
            <div *ngFor="let countryCode of media.countryCodes" class="filter" [class.selected]="false" translate>
                {{countryCode}}
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #edit>
    <form [formGroup]="mediaForm">
        <mat-checkbox formControlName="countryCodesExcluded" aria-label="excluded countries">
            <span translate>actions.filter.exclude</span>
        </mat-checkbox>
        <app-country-select formControlName="countryCodes" [countries]="countries"></app-country-select>
    </form>
</ng-template>
