import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {I18nService, themeMode$} from "core";
import {TranslateService} from "@ngx-translate/core";
import {map, takeUntil} from "rxjs/operators";

@Component({
  selector: 'emoji-picker',
  template: `
    <emoji-mart [set]="'apple'"
                [isNative]="true"
                [color]="primaryColor"
                [style]="emojiContainerStyle"
                [showPreview]="emojiShowPreview"
                [darkMode]="darkMode$ | async"
                [title]="''"
                [emoji]="''"
                [i18n]="i18n"
                (emojiSelect)="this.emojiSelect.emit($event)"
                (emojiClick)="this.emojiClick.emit($event)">
    </emoji-mart>
  `,
  styles: [`
    .emoji-mart .emoji-mart-emoji {
      outline: none;
    }
  `],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmojiPicker implements OnDestroy {

  @Output() emojiClick   = new EventEmitter<any>();
  @Output() emojiSelect  = new EventEmitter<any>();
  @Input()  primaryColor = "gray";

  protected onDestroy$ = new Subject<void>();
  darkMode$:Observable<boolean>;

  emojiShowPreview:boolean = false;
  emojiContainerStyle:any = {
    width: '100%',
    border: 'none',
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  };

  i18n: any = {
    search: 'Search',
    emojilist: 'List of emoji',
    notfound: 'No Emoji Found',
    clear: 'Clear',
    categories: {
      search: 'Search Results',
      recent: 'Frequently Used',
      people: 'Smileys & People',
      nature: 'Animals & Nature',
      foods: 'Food & Drink',
      activity: 'Activity',
      places: 'Travel & Places',
      objects: 'Objects',
      symbols: 'Symbols',
      flags: 'Flags',
      custom: 'Custom',
    },
    skintones: {
      1: 'Default Skin Tone',
      2: 'Light Skin Tone',
      3: 'Medium-Light Skin Tone',
      4: 'Medium Skin Tone',
      5: 'Medium-Dark Skin Tone',
      6: 'Dark Skin Tone',
    },
  };

  constructor(private i18nService: I18nService,
              private translateService : TranslateService) {
    this.translate("emoji", this.i18n);
    this.darkMode$ = themeMode$.pipe(
      takeUntil(this.onDestroy$),
      map(mode=>mode=='dark'));
  }

  translate(stem:string, object: any) {
    if (object) {
      Object.keys(object).forEach(key => {
        const i18nKey = stem+'.'+key;
        if (typeof object[key] === 'string') {
          object[key] = this.translateService.instant(i18nKey) || object[key];
        } else {
          this.translate(i18nKey,object[key]);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.emojiClick.complete();
    this.emojiSelect.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      //this.dialogRef.close();
    }
  }

  public static create(emojiHost:ViewContainerRef):ComponentRef<EmojiPicker> {
    const emojiPickerComponentRef = emojiHost.createComponent(EmojiPicker);
    emojiPickerComponentRef.instance.primaryColor = getComputedStyle(emojiHost.element.nativeElement).backgroundColor;
    return emojiPickerComponentRef;
  }
}
