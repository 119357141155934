import { Component, EventEmitter, Input } from '@angular/core';
import { ChannelInformation } from '../channel-details/channel-details.component';
import { BasicComponent } from 'shared';

@Component({
  selector: 'channel-info',
  templateUrl: './channel-info.component.html',
  styleUrls: ['./channel-info.component.scss']
})
export class ChannelInfoComponent extends BasicComponent {

  @Input() editMode: boolean;
  @Input() channel: ChannelInformation;
  
}
