import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-busy',
  templateUrl: './busy.component.html',
  styleUrls: ['./busy.component.scss']
})
export class BusyComponent implements OnInit {

  @Input() busy = false;
  @Input() size = 1;
  @Input() message: string;

  @HostBinding('class.app-busy-fill') fill: boolean = false;

  constructor() { }

  ngOnInit() { }
}
