import {NgModule} from '@angular/core';
import {ImageCropperModule as NgxImageCropperModule} from "ngx-image-cropper";
import {ImageCropperOverlayService} from "./overlay/image-cropper-overlay.service";
import {ImageCropperOverlayComponent} from "./overlay/image-cropper-overlay.component";
import {ImageCropperComponent} from "./image-cropper.component";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "material";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    NgxImageCropperModule
  ],
  declarations: [
    ImageCropperComponent,
    ImageCropperOverlayComponent
  ],
  providers: [
    ImageCropperOverlayService
  ]/*,
  entryComponents: [
    ImageCropperOverlayComponent
  ]
  */
})
export class ImageCropperModule {}
