/* projects/media/src/lib/components/media-details-rating/media-details-rating.component.scss */
:host {
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.container {
  position: relative;
  padding: 16px 24px;
}
.rating {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rating star-rating {
  display: inline-block;
  width: 50%;
  margin-right: auto;
  margin-left: -5px;
}
.rating star-rating ::ng-deep div.rating {
  outline: none;
  cursor: pointer;
}
.rating button {
  margin: 0 5px;
}
rating-summary {
  margin-left: -3px;
}
button.mat-mdc-mini-fab {
  align-self: flex-end;
  margin-top: -10px;
}
.header {
  display: block;
  padding: 0;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 6px;
}
div.all-views {
  display: table;
}
div.all-views > div.row {
  display: table-row;
}
div.all-views > div.row > div {
  display: table-cell;
}
div.all-views > div.row > div:first-child {
  text-align: right;
  padding-right: 8px;
}
div.all-views > div.row > div:last-child {
  color: rgba(0, 0, 0, 0.54);
}
:host-context(.color-scheme-dark) .header,
:host-context(.color-scheme-dark) .media-id,
:host-context(.color-scheme-dark) div.all-views > div.row > div:last-child {
  color: #aeafb0;
}
/*# sourceMappingURL=media-details-rating.component-3DA44CVP.css.map */
