import {Component, Input, OnInit} from '@angular/core';
import {I18nService} from "core";

@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.scss']
})
export class LanguageButtonComponent implements OnInit {

  @Input() public raised  : boolean = true;
  @Input() public primary : boolean = true;

  constructor(
    private i18nService: I18nService) { }

  ngOnInit() {
  }

  getLanguageLabel(language: string) : string {
    return 'languages.'+language;
  }

  setLanguage(language: string) {
    this.i18nService.setLanguage(language);
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }
}
