<div class="hierarchical-filter-container" fxLayout="column" fxLayoutGap="16px" fxFill>
    <!-- <div class="breadcrumb-container" *ngIf="items.length > 0">
        <div class="breadcrumb">
            <div class="breadcrumb-header" *ngFor="let item of items; let index = index">{{getLevelName(index)}}</div>
        </div>
        <div class="breadcrumb">
            <div class="breadcrumb-value" *ngFor="let item of items" (click)="onItemClick(item)">
                <span translate>{{item.name}}</span>
            </div>
        </div>
    </div> -->

    <mat-tab-group fxFlex="100" fxFill style="height: 100%">
        <mat-tab label="First"> 
            <ng-template mat-tab-label>
                Book <br>
                First
              </ng-template>
              <div [style.height]="getHeight()" style="flex-grow: 1;" fxFlex>
            <virtual-grid #grid [log]="false" 
            [startFromTop]="true" 
            [columns]="largeEntries ? 2 : 4" 
            [bufferSize]="600"
            [minimumBufferSize]="100" 
            [startGap]="startGap"
            [endGap]="100" 
            [itemDefaultSize]="300" 
            [items$]="options" 
            [itemsId$]="cacheId$"
            [style.top]="getGridStyle()"
                class="entry-grid">
                <div class="entry" *virtualFor="let entry of grid.viewportItems$ | async
            offset grid.viewportOffset$ | async
            total grid.items.length;
            let index = index;
            trackBy: trackByMedia;" (click)="onListboxClick(entry)">
                    {{entry.label}}
                </div>
            </virtual-grid> 
        </div>
        </mat-tab>
        <mat-tab label="Second"> Content 2 </mat-tab>
        <mat-tab label="Third"> Content 3 </mat-tab>
      </mat-tab-group>
   
</div>
<!-- 
<mat-selection-list [multiple]="false" [style]="getListstyle()" #list>
    <mat-list-option *ngFor="let option of options" [value]="option.id" 
        (click)="onListboxClick(list.selectedOptions.selected[0]?.value)">
        {{option.name}}
    </mat-list-option>
</mat-selection-list> -->