import {Directive, ElementRef, HostBinding, HostListener, Input, Renderer2} from '@angular/core';
import {animate, AnimationBuilder, AnimationMetadata, style} from "@angular/animations";
import {Logger} from "core";
import {SessionTokenService} from "session";

// animations in directives: https://www.iditect.com/how-to/57605199.html

@Directive({
  selector: 'img[source]'
})
export class ImageDirective {

  // @HostBinding('src')
  @Input()
  set source(source: string) {
    if (source != this._source) {
      this.renderer.setAttribute(this.elementRef.nativeElement, 'src', this._source = this.sessionTokenService.rewrite(source));
    }
  };

  @Input() default: string;

  // @HostBinding('style.display') display = 'block';
  @HostBinding('style.opacity') opacity = 0;

  protected _source: string;
  protected changes: MutationObserver;
  protected logger = new Logger('ImageDirective');

  constructor(
    protected renderer: Renderer2,
    protected elementRef: ElementRef,
    protected builder: AnimationBuilder,
    protected sessionTokenService: SessionTokenService) {
    this.changes = new MutationObserver((mutations: MutationRecord[]) =>
      mutations.filter(m => m.attributeName === 'src').forEach(() => {
        // this.opacity = 0.2;
        this.playAnimation(this.getFadeOutAnimation());
      })
    );

    this.changes.observe(this.elementRef.nativeElement, {
      attributes: true,
      childList: false,
      characterData: false
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  @HostListener('load')
  onLoad() {
    // this.opacity = 1;
    this.playAnimation(this.getFadeInAnimation());
  }

  @HostListener('error')
  onError() {
    this.default && (this.source = this.default);
  }

  protected playAnimation(animationMetaData: AnimationMetadata[]): void {
    const animation = this.builder.build(animationMetaData);
    const player = animation.create(this.elementRef.nativeElement);
    player.play();
  }

  protected getFadeInAnimation(): AnimationMetadata[] {
    return [animate('300ms ease-in', style({ opacity: 1 }))];
  }

  protected getFadeOutAnimation(): AnimationMetadata[] {
    return [animate('300ms ease-in', style({ opacity: 0.2 }))];
  }
}
