import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';

@Component({
  selector: 'tri-state-checkbox',
  templateUrl: './tri-state-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TriStateCheckboxComponent),
      multi: true,
    },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop'} }
  ]
})
export class TriStateCheckboxComponent implements ControlValueAccessor {

  tape = [null, true, false];

  @Input() value: boolean|null;
  @Input() disabled: boolean;
  @Output() changed = new EventEmitter<boolean|null>();

  private onChange: (val: boolean) => void;
  private onTouched: () => void;

  writeValue(value: boolean) {
    this.value = value;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  next() {
    let value = this.value;
    if (!this.disabled) {
      this.value = this.tape[(this.tape.indexOf(this.value) + 1) % this.tape.length];
      if (!!this.onChange && value!=this.value) {
        this.onChange(this.value);
      }
    }
    if (!!this.onTouched) {
      this.onTouched();
    }
    if (value!=this.value) {
      this.changed.emit(this.value);
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
