import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable} from "rxjs";
import {MultiSelectComponent} from "../multi-select/multi-select.component";
import {Country} from "../../models/country";

@Component({
  selector: 'app-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountrySelectComponent),
      multi: true
    }
  ]
})
export class CountrySelectComponent implements ControlValueAccessor {

  @ViewChild('multiSelect', { static: true }) multiSelect: MultiSelectComponent;

  @Input() countries: Observable<Country[]>;

  constructor() {  }

  get dirty(): boolean {
    return this.multiSelect.dirty;
  }

  writeValue(value: string[]): void {
    this.multiSelect.writeValue(value);
  }

  onChange(value : string[]) : void {
    this.multiSelect.onChange(value);
  }

  registerOnChange(fn: (countries: string[]) => void): void {
    this.multiSelect.registerOnChange(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.multiSelect.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean): void {
    this.multiSelect.setDisabledState(isDisabled);
  }
}
