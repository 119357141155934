<h2 mat-dialog-title>{{'groups.termsOfUse.label' |
  translate}}</h2>
<mat-dialog-content class="mat-typography">
  <h3>Lorem Ipsum</h3>
  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce finibus ut mi quis tempus. Cras rutrum, leo vel
    dignissim tempus, urna augue pellentesque tellus, vitae imperdiet ligula augue et eros. In hac habitasse platea
    dictumst. Maecenas vitae fermentum dolor. Sed mollis blandit lobortis. Vivamus ac feugiat orci. Pellentesque tempus,
    elit eu varius blandit, tortor nulla eleifend est, eget consectetur dolor lectus ut arcu. Nunc urna ligula,
    ultricies ut maximus eu, iaculis sit amet eros. Aliquam a congue dui. Fusce id tempus massa. Integer vel libero
    urna. Mauris consequat nunc eget volutpat dapibus. Morbi sodales quam felis, non blandit quam accumsan vel.</p>
  <p>
    Ut sit amet nunc nulla. Donec sit amet nulla placerat, placerat ligula at, porttitor eros. Mauris a ipsum purus.
    Fusce condimentum in massa scelerisque accumsan. Nulla bibendum est vel felis semper ornare eu nec nisl. Donec in
    varius magna, at accumsan nisi. Suspendisse nec suscipit nisi. Curabitur congue at justo vel semper. Ut semper magna
    non libero congue rhoncus. Nam porta purus id tincidunt fringilla. Quisque dignissim, metus ac mollis porta, felis
    mi egestas sem, ac vehicula eros nulla et ipsum. Nullam posuere sagittis mauris. Aliquam condimentum euismod tellus,
    et feugiat erat tempus eget. Ut posuere aliquet orci. Aenean porttitor risus lacus, nec faucibus enim euismod at.
    Nullam rutrum enim ante.
  </p>
  <p>
    Donec cursus elit et maximus tempor. Curabitur at dictum neque, ut tempor risus. Nam accumsan gravida tempor. Fusce
    tempus dictum elit, et vulputate sem rhoncus eu. Aenean bibendum urna et felis pretium lacinia. Nam auctor elit nec
    urna tincidunt, vitae dignissim mi pretium. Donec eget ex molestie, vestibulum tortor eget, viverra purus. Sed
    vestibulum nisl id interdum tincidunt. Vivamus sodales nec magna vitae cursus.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- <button mat-button mat-dialog-close>Cancel</button> -->
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button>
</mat-dialog-actions>