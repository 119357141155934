import {NgModule} from '@angular/core';
import {RouterModule, ROUTES, Routes} from '@angular/router';

import {LoginPageComponent} from './containers/login/login-page.component';
import {AuthenticationRoutingGuard} from "./services/authentication.routing.guard";
import {Clipboard} from "@ionic-native/clipboard/ngx";
import {AuthenticationRoutingConfig, LOGIN_OBSERVER} from "auth/lib/authentication/authentication.module.config";
import {LoginObserver} from "common";

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [AuthenticationRoutingGuard],
    providers: [Clipboard],
    data: { reuse: true, cacheId: 'LoginPageComponent', animation: 'outside' },
    children: [
      {
        path: ':type',
        component: LoginPageComponent,
        children: [
          {
            path: ':type/:token',
            component: LoginPageComponent,
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: (loginObserver: LoginObserver) => {
        const authRoutes = routes;
        authRoutes[0].providers.push({ provide: LOGIN_OBSERVER, useValue: loginObserver });
        return authRoutes;
      },
      multi: true,
      deps: [LOGIN_OBSERVER]
    }
  ]
})
export class AuthenticationRoutingModule { }
