<div class="media-container" *ngLet="{
    height: forcedCoverHeight ?? mediaService.getMediaCoverHeight(media,defaultCoverHeight),
    folder: mediaService.isFolderMedia(media),
    primaryAction: mediaService.getPrimaryAction(media) || !!mediaService.getMediaInfoIcon(media)
  } as context" [class.fullCover]="fullCover">
  <div class="media" [style.backgroundColor]="backgroundColor" [class.folder]="context.folder">
    <div class="cover-container" [style.padding-top.%]="fullCover ? 0 : context.height">
      <div class="cover" [class.default]="context.height==0" [class.primaryAction]="!!context.folder || !!context.primaryAction">
        <ng-container [ngSwitch]="!!cover">
          <div *ngSwitchCase="true" (click)="onClick()">
            <ng-container *ngTemplateOutlet="cover; context: {media}"></ng-container>
          </div>
          <ng-container *ngSwitchDefault>
            <img #cover
                 *ngIf="getMediaCover(media,imageMaxWidth,defaultCover) as context"
                 [class.transitioned]="context.transitioned"
                 [src]="(context.src | sessionLink)"
                 (load)="onLoad($event,cover,context.src)"
                 (transitionend)="onTransitionEnd(context.src)"
                 (click)="onClick()" />
          </ng-container>
        </ng-container>
        <ng-container *ngIf="indicator && ((!context.folder && !context.primary) || locked)">
          <div class="indicator" [ngSwitch]="mediaService.isMediaReady(media)">
            <ng-container *ngSwitchCase="true">
              <play-button *ngIf="mediaService.isMediaViewable(media)"
                           [icon]="mediaService.getMediaViewIcon(media)"
                           [color]="environment.primaryColor"
                           [locked]="locked"
                           (play)="onPlay($event)">
              </play-button>
            </ng-container>
            <mat-spinner #spinner [diameter]="spinnerDiameter()" *ngSwitchCase="false"></mat-spinner>
          </div>
        </ng-container>
        <span *ngIf="!context.folder && showDuration && mediaDuration(media); let duration" class="badge control bottom right">{{duration}}</span>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
