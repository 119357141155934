<filter-searchbar (searchChange)="onSearch($event)" (selectAllChange)="onSelectAll($event)" *ngIf="!disabled"></filter-searchbar>
<div class="content">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" dense>
        <mat-tree-node *matTreeNodeDef="let node" 
                        matTreeNodePadding 
                        matTreeNodePaddingIndent="20"
                        [class.hidden]="!node.visible">
            <button mat-icon-button disabled *ngIf="!node.expandable"></button>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item" *ngIf="node.expandable">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>

            <span *ngIf="disabled" translate>{{node.getDisplayName()}}</span>
            <mat-checkbox class="checklist-leaf-node" 
                        [checked]="checklistSelection.isSelected(node)"
                        [indeterminate]="descendantsPartiallySelected(node)"
                        (change)="itemSelectionToggle(node)" *ngIf="!disabled">
                          {{node.getDisplayName() | translate}}</mat-checkbox>
        </mat-tree-node>
    </mat-tree>
</div>