import {createAction, props} from "@ngrx/store";
import {Properties} from "../models/properties";

export const propertiesLoadRequest = createAction(
  'properties.load.request',
  props<{
    correlationId?: string,
    identifier: string
  }>()
);

export const propertiesLoadSuccess = createAction(
  'properties.load.success',
  props<{
    correlationId?: string,
    identifier: string,
    properties: Properties
  }>()
);

export const propertiesLoadFailure = createAction(
  'properties.load.failure',
  props<{
    correlationId?: string,
    identifier: string,
    error: any
  }>()
);

export const propertiesUpdateInterests = createAction(
  'properties.update.interests',
  props<{interests: string[]}>()
);
