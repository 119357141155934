<image-cropper #cropper
  [imageChangedEvent]="fileChangeEvent"
  [imageFile]="imageFile"
  [imageURL]="imageURL"
  [imageBase64]="imageBase64"
  [aspectRatio]="aspectRatio"
  [maintainAspectRatio]="maintainAspectRatio"
  [containWithinAspectRatio]="containWithinAspectRatio"
  [cropperMinWidth]="120"
  [cropperMinHeight]="120"
  [autoCrop]="false"
  [alignImage] ="'center'"
  [hideResizeSquares]="false"
  [transform]="transform"
  (imageCropped)="imageCropped($event)"
  (imageLoaded)="imageLoaded()"
  (cropperReady)="cropperReady()"
  (loadImageFailed)="loadImageFailed()"
  [format]="format">
</image-cropper>
