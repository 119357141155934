import {Logger} from "core";
import {Action, ActionsSubject, Store} from "@ngrx/store";
import {filter, first} from "rxjs/operators";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class StoreService {

  protected logger = new Logger('StoreService').setSilent(true);

  public dispatch<A extends Action>(action:A): Promise<A> {
    //console.log("sync:dispatch1",action);
    return new Promise<A>((resolve,reject)=> {
      this.action$.pipe(
        filter(dispatchedAction => dispatchedAction===action),
        first()
        //tap(dispatchedAction => console.log("sync:dispatch3",action))
      ).subscribe(dispatchedAction => {
        resolve(action);
      });
      //console.log("sync:dispatch2",action);
      this.store$.dispatch(<Action>action);
    });
  }

  constructor(protected store$ : Store,
              protected action$: ActionsSubject) {
  }
}
