<div class="image" (click)="onTap($event)">
  <input #input
         type="file"
         name="imageInput"
         class="hidden"
         accept="image/*"
         multiple=false
         (change)="onInputChange($event)"/>
  <img #image [src]="imageSrc" (load)="onImageLoad($event)"/>
  <div class="actions">
    <button *ngIf="hasEditButton"   mat-icon-button class="mat-primary" (click)="onTapEdit($event)"><mat-icon>edit</mat-icon></button>
    <button *ngIf="hasDeleteButton" mat-icon-button class="mat-accent"  (click)="onTapDelete($event)"><mat-icon>delete</mat-icon></button>
  </div>
</div>
<div class="progress-container">
  <div #progress></div>
</div>

