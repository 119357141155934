import {ElementRef, EventEmitter, Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {Uppy, UppyOptions} from '@uppy/core';
import {select, Store} from "@ngrx/store";
import {Vimeo, VimeoOptions} from "./plugin/upload-vimeo.plugin";
import {ENVIRONMENT, Logger, Platform} from "core";
import {TranslateService} from "@ngx-translate/core";
import {HttpClient} from "@angular/common/http";
import Tus, {TusOptions} from "@uppy/tus";
import XHRUpload, {XHRUploadOptions} from "@uppy/xhr-upload";
// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_chunk
// import * as fp from "lodash/fp";
import has from "lodash/has";
import set from "lodash/set";
import reduce from "lodash/reduce";
import {createUploadStore} from "./store/store";
import {BehaviorSubject, combineLatest, Observable, of} from "rxjs";
import {UploadFile, UploadFiles} from "./store/models";
import {ImageCroppedEvent, ImageCropperOverlayService, UploadStats} from "shared";
import {map, switchMap, take} from "rxjs/operators";
import {selectUploadFiles, selectUploadState} from "./store/reducers";
import {SessionTokenService} from "session";

// https://stackblitz.com/edit/angular-uppy
// import { set, lensProp, compose, reduce, __ } from 'ramda'

// import { createNgrxStore, INgrxStore, IUppy, UppyFile, UppyFiles, FileType } from 'uppy-store-ngrx';
// import {NgrxStore} from "uppy-store-ngrx/dist/src/store";

// https://uppy.io/docs/uppy/#Events
export const EventTypes = [
    'file-added',          // fired each time a file is added.
    'file-removed',        // fired each time a file is removed
    'upload',              // fired when upload starts
    'upload-progress',     // fired each time file upload progress is available.
    'upload-success',      // fired each time a single upload is completed.
    'complete',            // fired when all uploads are complete.
    'error',               // fired when there is a failure to upload/encode the entire upload.
    'upload-error',        // fired each time a single upload has errored.
    'upload-retry',        // fired when an upload has been retried (after an error, for example)
    'info-visible',        // fired when “info” message should be visible in the UI
    'info-hidden',         // fired when “info” message should be hidden in the UI
    'cancel-all',          // all uploads are canceled, files removed and progress is reset
    'restriction-failed',  // fired when a file violates certain restrictions when added,
    'reset-progress'       // fired when each file has its upload progress reset to zero (via explicit call to certain api)
  ];
export type ValuesOf<T extends any[]> = T[number];
export type EventType = ValuesOf<typeof EventTypes>;
export type UploadEvent = [EventType, any];

export type Meta<T> = {
  [key: string]: T
  [key: number]: T
}

export type CroppingConfigs = {
  active: boolean,
  minWidth: number,
  minHeight: number,
  aspectRatio: number
}

export type UploadOptions = {
  fileType?: string
  allowedFileTypes?: string[],
  cropping?: CroppingConfigs,
  endpoint?: string,
  pluginConfigs?: string[]
  meta?: Meta<any>
}

interface UploadedFile extends UploadFile { uploadURL: string; }
interface FailedFile extends UploadFile { error: string; }

interface UploadResult{
  successful: UploadedFile[];
  failed: FailedFile[]
}

export class UploadRef {
  upload:  () => Promise<UploadResult>;
  pause:  (fileId: string)=> void;
  resume: (fileId: string) => void;
  cancel: (fileId: string) => void;
  isPaused: (fileId: string) => boolean;
  isEmpty: () => boolean;
  release: () => void;
  on: (type: EventType, callback: (args: any) => void) => void;
  off: (type: EventType, callback: (args: any) => void) => void;
}

abstract class UploadRefDelegate {

  protected logger = new Logger('UploadRefDelegate.'+this.id);

  constructor(public id: string,
              protected uppy: Uppy,
              protected uploadService: UploadService) {

    const events: any = ['upload', /*'upload-progress',*/ 'upload-success',
                    'complete', 'error', 'upload-error', 'upload-retry']; // EventTypes
    events.forEach(type => this.uppy.on(type, (args) => {
      this.logger.debug('UPLOAD EVENT', type, args);
    }));
  }

  upload(uploadRef: UploadRef): Promise<UploadResult> {
    this.logger.debug('upload');
    return this.uppy.upload();
  }

  pause(uploadRef: UploadRef, fileId: string) {
    this.logger.debug('pause', fileId);
    !this.isPaused(uploadRef, fileId) && this.uppy.pauseResume(fileId);
  }

  resume(uploadRef: UploadRef, fileId: string) {
    this.logger.debug('resume', fileId);
    this.isPaused(uploadRef, fileId) && this.uppy.pauseResume(fileId);
  }

  cancel(uploadRef: UploadRef, fileId: string) {
    this.logger.debug('cancel', fileId);
    this.uppy.removeFile(fileId);
  }

  isPaused(uploadRef: UploadRef, fileId: string): boolean {
    return !!this.uppy.getFile(fileId).isPaused;
  }

  isEmpty(uploadRef: UploadRef) {
    const empty = (this.uppy.getFiles() || []).length==0;
    this.logger.debug('isEmpty', empty);
    return empty;
  }

  on(uploadRef: UploadRef, type: EventType, callback: (args: any) => void): void {
    // NOTE: Do not wrap the original callback as this will make impossible to deregister it later directly by the caller!
    // If an intermediate function is registered in the uppy the same function should be deregistered!
    // Later we can have a more sophisticated implementation which maintains mapping between caller callback and
    // the "proxy" callback actually registered. We can also keep internal structure with registered client callbacks
    // and limit the number of uppy event registration callbacks to max 1 per type which will be created on demand!
    // This will effectively mimic the uppy registration management logic but will give a possibility to have a "service" layer inside the delegate.
    this.uppy.on(type as any, callback);
    // this.uppy.on(type, (args) => {
    //   // this.logger.debug('onEvent', type, args);
    //   try {
    //     callback(type, args);
    //   } catch(e) {
    //     this.logger.warn('callback execution failed', type, args);
    //   }
    // });
  }

  off(uploadRef: UploadRef, type: EventType, callback: (args: any) => void): void {
    this.uppy.off(type as any, callback);
  }

  abstract release(uploadRef: UploadRef): void;
}

class UploadRefImpl implements UploadRef {
  constructor(protected delegate: UploadRefDelegate) {}
  upload(): Promise<UploadResult>   { return this.delegate.upload(this);  }
  pause(fileId: string): void       { this.delegate.pause(this, fileId);  }
  resume(fileId: string): void      { this.delegate.resume(this, fileId); }
  cancel(fileId: string): void      { this.delegate.cancel(this, fileId); }
  isPaused(fileId: string): boolean { return this.delegate.isPaused(this, fileId); }
  isEmpty(): boolean                { return this.delegate.isEmpty(this); }
  release(): void                   { this.delegate.release(this);        }
  on(type: EventType, callback: (args: any) => void): void {
    this.delegate.on(this, type, callback);
  }
  off(type: EventType, callback: (args: any) => void): void {
    this.delegate.off(this, type, callback);
  }
}

class UploadRefStore extends UploadRefDelegate {

  private uploadRefs: UploadRef[] = [];
  protected emptyEmitter = new EventEmitter<void>();

  constructor(public id: string,
              public uppy: Uppy,
              protected uploadService: UploadService) {
    super(id, uppy, uploadService);
  }

  get(): UploadRef {
    const uploadRef = new UploadRefImpl(this);
    this.uploadRefs.push(uploadRef);
    return uploadRef;
  }

  release(uploadRef: UploadRef) {
    const index = this.uploadRefs.findIndex((ref) => ref == uploadRef );
    if (index >= 0) {
      this.uploadRefs.splice(index, 1);
      // TODO: make removed uploadRef noop
      if (this.uploadRefs.length == 0) {
        this.emptyEmitter.emit();
        this.uppy.close();
      }
    }
  }

  get empty(): Observable<void> {
    return this.emptyEmitter.asObservable();
  }
}

export type UploadBatch = { uploadId: string, uploadFiles: UploadFiles }

class PluginDefinition {

  private locales: {[key: string]: object} = {};
  static stringBundles: {[key: string]: object} = {};

  constructor(public cls: any,
              private optionsProvider: () => any,
              private httpClient: HttpClient,
              private translateService: TranslateService,
              private deps: string[] = []) {
  }

  async locale(locale: string): Promise<object> {
    if (!this.locales[locale]) {
      // first miss triggers server load for all-in-one translation strings
      let bundle = PluginDefinition.stringBundles[locale] ||
               (
                PluginDefinition.stringBundles[locale] =
                    this.translateService.parser.getValue(this.translateService.translations[locale], 'uppy') ||
                    await this.httpClient.get<object>(`/v1.0/i18n/${locale}/upload`).toPromise()
               );
      let strings = bundle[this.cls.name.toLowerCase()];
      this.deps.forEach((dep) => strings = {...strings, ...bundle[dep]});
      this.locales[locale] = {
        strings: strings,
        pluralize: (n:number) => n <= 1 ? 0 : 1
      }
    }
    return this.locales[locale];
  };

  async options(locale: string): Promise<object> {
    let options = this.optionsProvider() || {};
    if (options instanceof Promise) {
      options = await options;
    }
    if (!options.locale) {
      options.locale = await this.locale(locale);
    }
    return options;
  }
}

@Injectable({
 providedIn: "root"
})
export class UploadService {

  protected plugins: {[pluginName: string]: PluginDefinition} = {};
  protected uploadRefStores: {[uploadId: string]: UploadRefStore} = {};
  protected uploadIds$ = new BehaviorSubject<string[]>([]);

  protected logger = new Logger('UploadService');

  protected _renderer: Renderer2;
  protected input: HTMLInputElement;

  constructor(protected store$: Store<any>,
              protected translateService: TranslateService,
              protected httpClient: HttpClient,
              private imageCropperOverlayService: ImageCropperOverlayService,
              protected platform: Platform,
              protected rendererFactory: RendererFactory2,
              protected sessionTokenService: SessionTokenService,
              @Inject(ENVIRONMENT) protected environment: any) {
    this.initialize();
  }

  protected initialize(){
    this.plugins.Tus        = new PluginDefinition(Tus, this.tusOptions.bind(this), this.httpClient, this.translateService);
    this.plugins.XHRUpload  = new PluginDefinition(XHRUpload, this.xhrUploadOptions.bind(this), this.httpClient, this.translateService);
    //this.plugins.Webcam     = new PluginDefinition(Webcam, this.webcamOptions.bind(this), this.httpClient, this.translateService);
    this.plugins.Vimeo      = new PluginDefinition(Vimeo, this.vimeoOptions.bind(this), this.httpClient, this.translateService);
  }

  get uploadIds(): Observable<string[]> {
    return this.uploadIds$.asObservable();
  }

  getUploadRef(uploadId: string): UploadRef {
    const  store = this.uploadRefStores[uploadId];
    return store ? store.get() : null;
  }

  getUploadStats(uploadId?: string): Observable<UploadStats> {
    const empty: UploadStats = { count: 0, percentage: 0, remainingTime: 0 };
    return this.uploadIds.pipe(
      switchMap((uploadIds: string[]) => {
        return uploadIds.length == 0
          ? of(empty)
          : combineLatest(
              uploadIds.reduce((observables, id) => {
                if (!uploadId || uploadId==id) {
                  observables.push(
                    this.store$.pipe(
                      select(selectUploadState, id),
                      map(state => {
                        const currentFiles = Object.keys(state.currentUploads || []).reduce((currentFiles, key ) => {
                          const currentFileIds = state.currentUploads[key]['fileIDs'] || [];
                          currentFileIds.forEach((fileId) => currentFiles.push(state.files[fileId]));
                          return currentFiles;
                        }, [] as UploadFile[]);
                        return currentFiles;
                      })
                    )
                  );
                }
                return observables;
              }, [] as Observable<UploadFile[]>[])
            )
            .pipe(switchMap((uploadFiles: UploadFile[][]) => {
              const speed = (progress) => {
                if (!progress.bytesUploaded) return 0;
                const timeElapsed = new Date().getTime() - progress.uploadStarted;
                return progress.bytesUploaded / (timeElapsed / 1000);
              };
              const totalProgress = ([] as UploadFile[])
                .concat(...uploadFiles)
                .reduce((total, current) => {
                    const progress = current && current.progress;
                    if (progress && progress.uploadStarted) {
                      total.count++;
                      total.bytesUploaded += progress.bytesUploaded;
                      total.bytesTotal += progress.bytesTotal;
                      total.speed += speed(progress);
                    }
                    return total;
                  },
                  { count: 0, bytesUploaded: 0, bytesTotal: 0, speed: 0 }
                );
              const isEmpty = totalProgress.count == 0;
              return isEmpty ? of(empty) : of({
                count: totalProgress.count,
                percentage: totalProgress.bytesTotal == 0 ? 0 : Math.floor(totalProgress.bytesUploaded / totalProgress.bytesTotal * 100),
                remainingTime: Math.round((totalProgress.bytesTotal - totalProgress.bytesUploaded)/ totalProgress.speed * 10 / 10)
              });
            }));
      })
    )
  }

  getUploadBatches(uploadId?: string): Observable<UploadBatch[]>  {
    if (uploadId) {
      return this.store$.pipe(selectUploadFiles(uploadId)).pipe(map(files =>
        [{ uploadId: uploadId, uploadFiles: files }]
      ));
    } else {
      return this.uploadIds.pipe(
        switchMap((uploadIds: string[]) => {
          return combineLatest(
            uploadIds.reduce((observables, id) => {
              if (!uploadId || uploadId==id) {
                observables.push(
                  this.store$
                    .pipe(selectUploadFiles(id))
                    .pipe(map((uploadFiles: UploadFiles) => [id, uploadFiles]))
                );
              }
              return observables;
            }, [] as Observable<[string, UploadFiles]>[])
          )
        })
      ).pipe(map((result: [string, UploadFiles][]) => {
        return result.reduce((uploadFiles, files) => {
          uploadFiles.push({uploadId: files[0], uploadFiles: files[1]});
          return uploadFiles;
        }, [] as UploadBatch[])
      }));
    }
  }

  async vimeoDashboard(id: string, target: string, allowedFileTypes = ['*']): Promise<UploadRef> {
    return this.dashboard(id, target, allowedFileTypes, null,['Tus'/*, 'Webcam'*/, 'Vimeo']);
  }

  async tusDashboard(id: string, target: string, allowedFileTypes = ['*']): Promise<UploadRef> {
    return this.dashboard(id, target, allowedFileTypes, null,['Tus']);
  }

  async dashboard(id: string, target: string, allowedFileTypes = ['*'], endpoint?: string,
                  pluginConfigs = ['XHRUpload']): Promise<UploadRef> {
    const dashboardPluginConfigs = [...pluginConfigs, ['Dashboard', { target: target }] as [string, object]];
    const store = await this.getUploadRefStore(id, () => this.createUppy(id, allowedFileTypes, dashboardPluginConfigs, endpoint));
    return store.get();
  }

  async upload(host: ElementRef, options?: UploadOptions): Promise<UploadRef> {
    const fileType = options.fileType || '*';
    const allowedFileTypes = options.allowedFileTypes || (options.allowedFileTypes = this.resolveAllowedFileTypes(fileType));
    const files: FileList = await this.selectFiles(host.nativeElement, allowedFileTypes);
    return this.uploadFiles(files, options).catch(error => {
      if (error.msg == 'Empty files') {
        throw new Error('No files selected');
      } else { throw error; }
    })
  }

  async uploadFiles(files: FileList, options?: UploadOptions): Promise<UploadRef> {
    if (files.length > 0) {
        const uploadType = options.fileType || '*';
        const allowedFileTypes = options.allowedFileTypes || this.resolveAllowedFileTypes(uploadType);
        const endpoint = options.endpoint || (uploadType == 'video' || uploadType == '*' ? null : `${this.environment.serverUrl}/v1.0/media/upload/${uploadType}`);
        const pluginConfigs = options.pluginConfigs || this.resolvePlugins(uploadType);
        this.logger.debug('pluginConfigs', pluginConfigs);
        const uploadId = uploadType;
        const createUppy = async () => await this.createUppy(uploadId, allowedFileTypes, pluginConfigs, endpoint);
        const store = await this.getUploadRefStore(uploadId, createUppy);
        const uppy = store.uppy;

        const addFileToUppy = async (file: File) => {
            uppy.addFile({
                source: 'uploadFile',
                name: file.name,
                type: file.type,
                data: file,
                meta: Object.entries(options?.meta || {}).reduce((meta, [key, value]) => {
                    if (value && !Array.isArray(value) && typeof value === 'object') {
                        meta[key] = JSON.stringify(value);
                    } else {
                        meta[key] = value;
                    }
                    return meta;
                }, {} as Meta<any>)
            });
        };

        const tasks = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (options?.cropping?.active) {
                const task = new Promise(async (resolve, reject) => {
                    const overlayRef = this.imageCropperOverlayService.open({
                        data: {
                            imageFile: file,
                            aspectRatio: options?.cropping.aspectRatio,
                            onCrop: async (imageCroppedEvent: ImageCroppedEvent) => {
                                try {
                                    const croppedBlob = await fetch(imageCroppedEvent.base64)
                                        .then(response => response.blob());
                                    this.logger.debug('CROPPED IMAGE BLOB', croppedBlob);
                                    const croppedFile = new File([croppedBlob], file.name, { type: file.type });
                                    overlayRef.close();
                                    const width = imageCroppedEvent.width, height = imageCroppedEvent.height;
                                    const minWidth = options!.cropping!.minWidth, minHeight = options!.cropping!.minHeight;
                                    if (width < minWidth || height < minHeight) {
                                        reject(new Error('Image too small'));
                                    }
                                    await addFileToUppy(croppedFile);
                                    resolve(croppedFile);
                                } catch (error) {
                                    reject(error);
                                }
                            }
                        }
                    });
                });

                tasks.push(task);
            } else {
                tasks.push(addFileToUppy(file));
            }
        }

        try {
            await Promise.all(tasks);

            if (uppy.getFiles().length > 0) {
                return store.get();
            } else {
                throw new Error('Failed to add at least one file to the uploader');
            }
        } catch (error) {
            throw error;
        }
    } else {
        throw new Error('Empty files');
    }
}


  async uploadFile(file: Blob | File, endpoint: string): Promise<UploadRef> {
    const uploadType = this.resolveUploadType(file.type);
    const uppy = await this.createUppy(
      file.type,
      this.resolveAllowedFileTypes(uploadType),
      this.resolvePlugins(uploadType),
      endpoint
    );
    const store = new UploadRefStore(null, uppy, this);
    try {
      uppy.addFile({
        source: 'uploadFile',
        name: file instanceof File ? file.name : 'blob',
        type: file.type,
        data: file
      });
    } catch (e) {
      // Uppy.addFile() throws an Error if file is already added, file does not match allowed types, etc
      this.logger.error('Failed to add file for upload', e);
    }
    return store.get();
  }

  protected async getUploadRefStore(id: string, createUppy: () => Promise<Uppy>): Promise<UploadRefStore> {
    let store = this.uploadRefStores[id];
    if (!store) {
      const uppy = await createUppy();
      store = new UploadRefStore(id, uppy, this);
      store.empty.pipe(take(1)).subscribe(() => {
        // no more references - immediately clean for now.
        // we can keep it in the pool for some time before releasing but should not be returned it to the clients
        this.logger.debug('clean', this.uploadRefStores[id]);
        delete this.uploadRefStores[id];
        //store = null; // safe - no references in the store
        this.uploadIds$.next(this.uploadIds$.getValue().filter(uploadId => uploadId != id));
      });
      this.uploadRefStores[id] = store;
      this.uploadIds$.next([...this.uploadIds$.getValue(), id]);
    }
    return store;
  }

  protected async createUppy(id: string, allowedFileTypes: string[],
                             pluginConfigs: any[], endpoint?: string): Promise<Uppy> {
    const plugins = await Promise.all(pluginConfigs.map(async (pluginConfig): Promise<[string, any]> => {
      let [plugin, config] = pluginConfig.constructor === Array ? pluginConfig : [pluginConfig, {}];
      const defaultConfig = await this.plugins[plugin].options(this.translateService.currentLang);
      if (defaultConfig) {
        config = {...defaultConfig, ...config};
      }
      if (has(config, 'endpoint')) {
        if (endpoint) { // override default endpoints for Tus and XHRUpload
          set(config, 'endpoint', this.sessionTokenService.rewrite(endpoint));
        }
        if (has(config, 'withCredentials')) {
          // credentials are required when uploading to main application server.
          // vimeo uploads are authenticated by access token (see Vimeo plugin) and therefore should have withCredentials: false
          set(config, 'withCredentials', config.endpoint.startsWith(this.environment.serverUrl));
        }
      }
      return [plugin, config];
    }));
    const addPlugin = (uppy: any, [plugin, config]: [string, any]) => {
      console.debug('UPLOAD PLUGIN CONFIG', plugin, config);
      return uppy.use(this.plugins[plugin].cls, config);
    };
    const options = {
      ...await this.uppyOptions(),
      ...{ id: id, store: createUploadStore({id: id, store: this.store$}) },
      onError: (error) => {this.logger.error('Uppy.OnError', { error })}
    };
    if (!allowedFileTypes.includes('*')) {
      options.restrictions['allowedFileTypes'] = allowedFileTypes;
    }
    const uppy = new Uppy(options);
    // console.log('PLUGINS', plugins);
    return reduce(plugins, addPlugin, uppy);
  }

  /*protected*/ resolveAllowedFileTypes(uploadType: string): string[] {
    switch (uploadType) {
      case 'image': return ['image/*'];
      case 'pdf'  : return ['application/pdf'];
      // add video/mp4 and video/x-m4v explicitly
      // because safari thinks they are not video/* ...
      // but both are registered by IANA: http://www.iana.org/assignments/media-types/media-types.xhtml
      // case 'video': return ['video/mp4','video/x-m4v','video/webm','video/ogg','video/x-msvideo','video/quicktime','video/mpeg','video/*'];
      case 'video': return ['video/mp4','video/x-m4v','video/*'];
      case 'audio': return ['audio/*'];
      case 'iap': return ['.iap','application/zip'];
      default: return ['*'];
    }
  }

  /*protected*/ resolveUploadType(fileType: string): string {
    if (fileType == 'application/pdf') {
      return 'pdf';
    } else {
      const type = fileType?.split('/')[0];
      if (type == 'image' || type == 'audio' || type == 'video') {
        return type;
      }
      return '*';
    }
  }

  protected resolvePlugins(uploadType: string): string[] {
    return uploadType=='*' || uploadType=='video' ? ['Tus'] : ['XHRUpload']
  }

  protected async selectFiles(host: ElementRef, allowedFileTypes: string[]): Promise<FileList> {
    let resolveFiles, rejectFiles;
    const  promise = new Promise<FileList>((resolve, reject) => {
      resolveFiles = resolve;
      rejectFiles  = reject;
    });
    const renderer = this.getRenderer(host);
    // there is no real support to get notified when file select
    // box is cancelled. so we just remove the input when we next
    // time open an upload dialog.
    this.input?.parentElement?.removeChild(this.input);
    this.input = this.createFileInput(renderer, allowedFileTypes);
    renderer.appendChild(host, this.input);
    const unlisten = renderer.listen(this.input, 'change', (event) => {
      unlisten();
      //renderer.removeChild(host, this.input);
      resolveFiles(this.input.files);
    });
    this.input.click();
    return promise;
  }

  protected createFileInput(renderer: Renderer2, allowedFileTypes: string[]): HTMLInputElement {
    /*
      <input type="file"
             name="uploadFile"
             style="width: 0.1px; height: 0.1px; opacity: 0; overflow: hidden; z-index: -1;"
             accept="*"
             multiple=true
             (change)="onInputChange($event)"/>
    */
    const input: HTMLInputElement = renderer.createElement('input');
    renderer.setProperty(input, 'id', 'fileUpload');
    renderer.setProperty(input, 'type', 'file');
    renderer.setProperty(input, 'name', 'fileUpload');
    renderer.setStyle(input, 'width', '0.1px');
    renderer.setStyle(input, 'height', '0.1px');
    renderer.setStyle(input, 'left', '0');
    renderer.setStyle(input, 'top', '0');
    renderer.setStyle(input, 'opacity', '0');
    renderer.setStyle(input, 'overflow', 'hidden');
    renderer.setStyle(input, 'z-index', '-1');
    renderer.setStyle(input, 'position','absolute');
    renderer.setProperty(input, 'accept', (allowedFileTypes || []).join(','));
    renderer.setProperty(input, 'multiple', 'true');
    return input;
  }

  protected getRenderer(host: any) {
    return this._renderer ? this._renderer : this.rendererFactory.createRenderer(host, null);
  }

  protected async uppyOptions(): Promise<Partial<UppyOptions>> {
    const options = {
      // If multiple Uppy instances are being used an id should be specified.
      // This allows Uppy to store information in localStorage without colliding with other Uppy instances.
      // https://uppy.io/docs/uppy/
      // id: 'uppy',
      autoProceed: false,
      /*
       * "This means multiple calls to .upload(), or a user adding more files after already uploading some.
       * An upload batch is made up of the files that were added since the previous .upload() call"
       *
       * https://uppy.io/docs/uppy/#allowMultipleUploads-true
       */
      allowMultipleUploads: true,

      /*
       * ATTENTION: enabling debug mode leads to runtime exception inside uppy.js.
       * "TypeError: Failed to set an indexed property on 'Window'"
       *
       * pseudocode which causes the exception:
       * if debug then window['property'] = value;
       *
       * Upload works if debug is set to false!
       * Very likely this is a bug in uppy/core (v1.7.1)!
       */
      debug: false, //!this.environment.production,
      restrictions: {
        maxFileSize: null,
        maxNumberOfFiles: null,
        minNumberOfFiles: null,
        allowedFileTypes: null
      },
      meta: {},
      onBeforeFileAdded: (currentFile, files) => currentFile,
      onBeforeUpload: (files) => files,
      locale: {strings: {}}
    };
    if (this.sessionTokenService.shouldRewrite) {
      options.meta = { ...options.meta, ...{ sessionToken: this.sessionTokenService.token }};
    }
    return options;
  }

  protected async tusOptions(): Promise<TusOptions> {
    // https://github.com/tus/tus-js-client
    const endpoint = `${this.environment.serverUrl}${this.sessionTokenService.shouldRewrite ? `/v1.1/${this.sessionTokenService.token}` : '/v1.0'}/files`;
    /*
       * IMPORTANT: With default chunkSize = Infinity the Cordova FileReader (which is used internally by tus-js-client lib
       * to read file in chunks when running on cordova/phonegap platforms) fails with an error
       * when uploading large files (maybe because of webview memory limitations as the chunk is actually the whole file)
       * and causes uppy dashboard to exit immediately.
       * (see tips from vimeo here: https://developer.vimeo.com/api/upload/videos#resumable-approach-step-2)
       *
       * we specify 64MB chunk size for cordova apps and this works properly on both android and ios webviews
       * Note that chunkSize is also limited on production system by our nginx config: client_max_body_size 1024m;
       */
   const chunkSize = (this.platform.is('hybrid') ? 64 : 512) * Math.pow(1024, 2);
   const options: TusOptions = {
      //resume        : true,                   // attempt to resume already started upload - file upload url is stored in localstorage
      endpoint        : endpoint,               // default: 'https://master.tus.io/files/',
      chunkSize       : chunkSize,              // maximum size of a chunk in bytes which will be uploaded in a single request
      retryDelays     : [0, 1000, 3000, 5000, 10000, 15000, 20000, 25000],  // when uploading a chunk fails, automatically try again after the millisecond intervals specified in this array
      // autoRetry       : true,                // continue after network outage - this options has been removed in uppy 2.0
      storeFingerprintForResuming: true,
      removeFingerprintOnSuccess:  true,        // if the resume option is enabled, it will store some data in localStorage for each upload. With removeFingerprintOnSuccess, this data is removed once an upload has completed.
      withCredentials : false,                  // could be will be overriden later depending on actual endpoint specified when creating the uploader instance
      limit           : 5,                      // max number of concurrent uploads. 0 means no limit.
      addRequestId    : true,
     // useFastRemoteRetry: true
      onShouldRetry   : (error) => {
        this.logger.debug('onShouldRetry', {error});
        return true;
      }
    };
    // return Promise.resolve(options);
    // bypass header is not removed by service worker - it goes to the server and could lead to CORS violation
    // if it is not allowed by Access-Control-Allow-Headers
    return this.addBypassHeaderIfRequired(options);
  }

/*
  protected webcamOptions(): WebcamOptions {
    return {
      target: Dashboard, //DOM element, CSS selector, or plugin to mount Webcam into.
      onBeforeSnapshot: () => Promise.resolve(),
      countdown: false,
      modes: [
        'video-audio',
        'video-only',
        'audio-only',
        'picture'
      ],
      mirror: true,
      facingMode: 'user', // video source is facing toward the user. other modes: environment, left, right
      // locale: {}
    };
  }*/

  protected async vimeoOptions(): Promise<VimeoOptions> {
    return {};
    // ngsw-bypass header can lead to CORS related issues
    // return this.addBypassHeaderIfRequired({});
  }

  protected async xhrUploadOptions(): Promise<XHRUploadOptions> {
    // https://uppy.io/docs/xhr-upload/
    const options: any = {
      endpoint: this.sessionTokenService.rewrite(`${this.environment.serverUrl}/v1.0/media/upload`),
      formData: true,
      fieldName: 'file',
      withCredentials: true,
      metaFields: null // null = send all meta fields, [] = none, ['name'] = only name meta field
    };
    return this.addBypassHeaderIfRequired(options);
  }

  protected async addBypassHeaderIfRequired(options: any): Promise<any> {
    const serviceWorkerRegistraion = 'serviceWorker' in navigator &&
      await navigator.serviceWorker.getRegistration(window.location.href);
    if (serviceWorkerRegistraion) {
      options.headers = {
        // supply the following header to bypass the angular service worker
        // as reporting progress on uploaded files is not supported
        // https://angular.io/guide/service-worker-devops#bypassing-the-service-worker
        // the header value is ignored by ngsw. presence of header is enough to bypass service worker handling
        'ngsw-bypass': true
      }
    }
    return options;
  }
}
