import {Directive, forwardRef, Input, TemplateRef, ViewContainerRef} from "@angular/core";

export enum Side {
  LEFT  = 'left',
  RIGHT = 'right'
}

// structural directives:
// https://angular.io/guide/structural-directives#the-asterisk--prefix
// microsyntax:
// https://netbasal.com/understanding-angular-structural-directives-659acd0f67e
// https://github.com/zetsnotdead/ng-carousel/blob/master/src/app/carousel/carousel.directive.ts

@Directive({
  selector: 'ng-template[slidePanel]'
})
export class SlidePanelDirective {

  @Input('slidePanel') set id(id: string) {
    this._id = id;
  }

  get id(): string {
    return this._id;
  }

  @Input('slidePanelSide') set side(side: Side) {
    this._side = side;
  }
  get side(): Side {
    return this._side;
  }

  @Input('slidePanelLazy') set lazy(lazy: boolean) {
    this._lazy = lazy;
  }

  get lazy(): boolean {
    return this._lazy;
  }

  private _id: string;
  private _side: Side = Side.LEFT;
  private _lazy = false;

  constructor(protected viewContainer: ViewContainerRef,
              public templateRef: TemplateRef<any>) {
  }
}

// https://github.com/Tangerine-Community/Tangerine/issues/369
// https://github.com/angular/angular/issues/9989
// http://www.mcrook.com/2016/10/angular-2-contentchildren-with-abstract.html
// angular ngIf directive impl:
// https://github.com/angular/angular/blob/master/packages/common/src/directives/ng_if.ts

@Directive({
  selector: 'ng-template[slidePanelLeft]',
  providers: [{ provide: SlidePanelDirective, useExisting: forwardRef(() => SlidePanelLeftDirective) }]
})
export class SlidePanelLeftDirective extends SlidePanelDirective{

  @Input('slidePanelLeft') set id(id: string) {
    super.id = id;
  }

  get id(): string {
    return super.id;
  }

  constructor(protected viewContainerRef: ViewContainerRef,
              public templateRef: TemplateRef<any>,) {
    super(viewContainerRef, templateRef);
    this.side = Side.LEFT;
  }
}

@Directive({
  selector: 'ng-template[slidePanelRight]',
  providers: [{ provide: SlidePanelDirective, useExisting: forwardRef(() => SlidePanelRightDirective) }]
})
export class SlidePanelRightDirective extends SlidePanelDirective {

  @Input('slidePanelRight') set id(id: string) {
    super.id = id;
  }

  get id(): string {
    return super.id;
  }

  constructor(protected viewContainerRef: ViewContainerRef,
              public templateRef: TemplateRef<any>,) {
    super(viewContainerRef, templateRef);
    this.side = Side.RIGHT;
  }
}

@Directive({
  selector: 'ng-template[slidePanelLeftLazy]',
  providers: [{ provide: SlidePanelDirective, useExisting: forwardRef(() => SlidePanelLeftLazyDirective) }]
})
export class SlidePanelLeftLazyDirective extends SlidePanelDirective {

  @Input('slidePanelLeftLazy') set id(id: string) {
    super.id = id;
  }

  get id(): string {
    return super.id;
  }

  constructor(protected viewContainerRef: ViewContainerRef,
              public templateRef: TemplateRef<any>,) {
    super(viewContainerRef, templateRef);
    this.lazy = true;
  }
}

@Directive({
  selector: 'ng-template[slidePanelRightLazy]',
  providers: [{ provide: SlidePanelDirective, useExisting: forwardRef(() => SlidePanelRightLazyDirective) }]
})
export class SlidePanelRightLazyDirective extends SlidePanelRightDirective{

  @Input('slidePanelRightLazy') set id(id: string) {
    super.id = id;
  }

  get id(): string {
    return super.id;
  }

  constructor(protected viewContainerRef: ViewContainerRef,
              public templateRef: TemplateRef<any>,) {
    super(viewContainerRef, templateRef);
    this.lazy = true;
  }
}
