import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

// https://www.codementor.io/yomateo/auto-focus-with-angular-7-the-directive-osfcl7rrv
// see search-bar.component.ts & html
@Directive({
  selector: '[anyFocus],input,textarea'
})
export class InputFocusDirective {
  private static _anyFocus: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public get anyFocus():EventEmitter<boolean> { return InputFocusDirective._anyFocus; }

  public constructor(private elementRef: ElementRef) {
  }

  @HostListener('focus')
  onFocusEvent() {
    //console.log("onFocusEvent()",this.elementRef.nativeElement);
    this.anyFocus.emit(true);
  }

  @HostListener('blur')
  onBlurEvent() {
    //console.log("onBlurEvent()",this.elementRef.nativeElement);
    this.anyFocus.emit(false);
  }
}
