import {Injectable} from '@angular/core';
import {PropertiesService} from "properties";
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {Topic} from "core";
import {map} from "rxjs/operators";
import {getFilterTopics} from "./store/state";
import {StoreService} from "store";
import {ActionsSubject, Store} from "@ngrx/store";
import {setFilterTemplatesAction, setFilterValuesAction} from "./store/actions";
import * as fromFilters from "./store/state";
import includes from "lodash/includes";
import partition from 'lodash/partition';
import * as fromShared from "shared";

@Injectable({
  providedIn: 'root'
})
export class FilterService extends StoreService implements fromShared.FilterService {

  protected rootFilterTopics: BehaviorSubject<Topic[]>;

  constructor(
    protected store$ : Store,
    protected action$: ActionsSubject,
    public propertiesService: PropertiesService
  ) {
    super(store$,action$);
    //console.log("FILTER_SECTIONS.rootMediaFilterDefinitions$");
    this.rootFilterTopics = new BehaviorSubject<Topic[]>([]);
    this.propertiesService.app$.pipe(
      map(app => app?.filters || []),
      map(filterSections => {
        //console.log("FILTER_SECTIONS",filterSections);
        //this.logger.debug("rootMediaFilterDefinitions$",filterSections);
        let toFilterDefinition = (topic: Topic): Topic =>  {
          return {
            id: topic.id,
            type: topic.type,
            label: topic.label,
            editable: topic.editable==undefined || topic.editable,
            tags: topic.tags,
            properties: topic.properties,
            topics: !!topic.topics && topic.topics.length ? <Topic[]>[
              ...topic.topics.map(child => toFilterDefinition(child))
            ] : undefined
          };
          //return toFilterDefinition(topic);
        };
        return <Topic[]> [...filterSections.map(filterSection => toFilterDefinition(filterSection))];
      })
    ).subscribe((templates) => {
      this.store$.dispatch(setFilterTemplatesAction({templates:templates}));
    });
    this.store$.select(fromFilters.selectFilterTemplates).subscribe(templates=>{
      this.rootFilterTopics.next(templates);
    });
  }

  mergeFilterValues$(values:string[],path:string,view?:string):Promise<string[]> {
    return new Promise(((resolve, reject) => {
      this.dispatch(setFilterValuesAction({path,view,values,merge:true}))
        .then(action=>{
          resolve(action.values);
        })
        .catch(reject);
    }));
  }

  setFilterValues$(values:string[],path:string,view?:string):Promise<string[]> {
    return new Promise(((resolve, reject) => {
      this.dispatch(setFilterValuesAction({path,view,values,merge:false}))
        .then(action=>{
          resolve(action.values);
        })
        .catch(reject);
    }));
  }

  getFilterValues$(path:string,view?:string):Observable<string[]> {
    return this.store$.select(fromFilters.selectFilterValues(path,view)).pipe();
  }

  getFilterTopics$(path:string,view?:string,predicate:(filter:Topic,root:Topic /*can be undefined*/,level:number,path:string,view?:string)=>boolean = undefined):Observable<Topic[]> {
    return this.store$.select(fromFilters.selectFilterState)
      .pipe(map(state=>
        getFilterTopics(state.templates,undefined,0,path,view,{},state.registry,predicate)));
  }

  getRootFilterTopics$(): Observable<Topic[]> {
    return this.rootFilterTopics.asObservable();
  }

  setFilter(selected:string[], filter: string, exclusive = false, group?: string | string[]):string[] {
    console.log("SET FILTER\nselected",selected,"filter",filter);
    if (exclusive) {
      const isString = typeof group === 'string';
      const isArray  = isString ? false : Array.isArray(group);
      const prefix   = isString ? `${group}.` : undefined;
      selected = selected.filter(f =>
        f==filter ||
        (isString ? !f.startsWith(prefix)  :
          isArray ? !group.includes(f) : false)
      );
    }
    selected = includes(selected, filter)
      ? selected.filter(f => f!=filter)
      : [...selected || [], filter];
    return selected;
  }

  // Temporary
  filteredItemsSubject = new BehaviorSubject<any[]>([]);
  filteredItems$ = this.filteredItemsSubject.asObservable();
  items: any[][] = [];
  getFilterItems$(topic: Topic, searchString?: string, selectedFilterIds?: string[]): Promise<any[]> {
    return new Promise(resolve => {
      if(!this.items[topic.id]) {
        const topicItems = []
        for (let i = 0; i < 100; i++) {
          topicItems.push({ id: topic.id + "." + i, label: "Test " + i + Math.random() });
        }
        this.items[topic.id] = topicItems;
      }
      if(!!searchString){
        const filteredItems = this.items[topic.id].filter(option => option.label.toLowerCase().includes(searchString.toLowerCase()));
        resolve(filteredItems);
      } else {
        if (!!selectedFilterIds && selectedFilterIds.length > 0) {
          const result = partition(this.items[topic.id], option => selectedFilterIds.includes(option.id));
          resolve([...result[0], ...result[1]]);
        } else {
          resolve(this.items[topic.id]);
        }
      }
    });
  }
}
