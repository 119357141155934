import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

export type HttpRequestHandler = (req: HttpRequest<any>) => HttpRequest<any>;

@Injectable()
export class HandlerInterceptor implements HttpInterceptor {
  private handlers: Array<HttpRequestHandler> = [];

  addHandler(handler: HttpRequestHandler): void {
    this.handlers.push(handler);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.handlers.forEach((handler: HttpRequestHandler) => {
      try {
        request = handler(request);
      } catch(e) { console.error (e); }
    });
    return next.handle(request);
  }
}
