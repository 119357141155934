import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  protected _tlds = ['.de','.com','.net','.eu','.org','.info','.tips','.at','.berlin','.ch','.alsace','.amsterdam',
    '.at','.co.at','.or.at','.ba','.barcelona','.bayern','.be','.berlin','.bg','.brussels','.by','.bzh','.cat','.ch',
    '.cologne','.com.cy','.cymru','.cz','.de','.de.com','.com.de','.dk','.ee','.es','.com.es','.org.es','.eu','.eu.com',
    '.fi','.fo','.fr','.frl','.gent','.gg','.gr','.com.gr','.hamburg','.hr','.com.hr','.hu','.co.hu','.ie','.im','.irish',
    '.is','.ist','.istanbul','.it','.je','.koeln','.li','.london','.lt','.lu','.lv','.com.lv','.madrid','.md','.me','.mk',
    '.com.mk','.net.mk','.moscow','.mt','.com.mt','.nl','.no','.nrw','.paris','.pl','.biz.pl','.com.pl','.info.pl','.net.pl',
    '.org.pl','.pt','.com.pt','.ro','.com.ro','.org.ro','.rs','.ru','.com.ru','.ru.com','.ruhr','.saarland','.scot','.se',
    '.si','.sk','.tirol','.biz.tr','.com.tr','.info.tr','.ua','.com.ua','.uk','.co.uk','.me.uk','.org.uk','.uk.com','.vlaanderen',
    '.wales','.wien','.al','.com.gi','.ac','.africa','.bi','.capetown','.cf','.cm','.dj','.durban','.ga','.gq','.joburg',
    '.ly','.mg','.co.mg','.com.mg','.net.mg','.org.mg','.ml','.mu','.com.mu','.org.mu','.net.mu','.ng','.com.ng','.re','.sc',
    '.com.sc','.net.sc','.org.sc','.sh','.sl','.so','.st','.tf','.ug','.yt','.co.za','.net.za','.org.za','.web.za','.co.zw',
    '.org.zw','.ag','.com.ag','.net.ag','.org.ag','.ai','.com.ai','.off.ai','.ar','.com.ar','.net.ai','.org.ai','.bo','.com.bo',
    '.boston','.com.br','.net.br','.bz','.ca','.cl','.co','.com.co','.net.co','.cr','.dm','.do','.com.do','.net.do','.org.do','.ec',
    '.com.ec','.gd','.gl','.co.gl','.com.gl','.net.gl','.org.gl','.gs','.com.gt','.gy','.co.gy','.com.gy','.net.gy','.hn','.ht','.lat','.lc',
    '.com.lc','.net.lc','.org.lc','.miami','.ms','.co.ms','.com.ms','.org.ms','.mx','.com.mx','.com.ni','.org.ni','.net.ni','.nom.ni',
    '.com.pa','.pe','.com.pe','.pm','.pr','.biz.pr','.com.pr','.info.pr','.name.pr','.net.pr','.org.pr','.pro.pr','.com.py','.quebec',
    '.com.sv','.sx','.tc','.us','.us.com','.uy','.com.uy','.vc','.com.ve','.vegas','.vg','.co.vi','.ae','.ae.org','.af','.am','.as',
    '.asia','.cc','.cn','.cn.com','.co.il','.co.in','.co.jp','.co.kr','.co.nz','.com.au','.com.cn','.com.fj','.com.hk','.com.ki',
    '.com.my','.com.nf','.com.ph','.com.pk','.com.ps','.com.sa','.com.sb','.com.sg','.com.tw','.com.vn','.cx','.desi','.firm.in',
    '.fm','.gen.in','.hk','.hm','.id','.co.id','.or.id','.web.id','.idv.tw','.in','.ind.in','.io','.ir','.jp','.ki','.kiwi','.kr','.la',
    '.mn','.my','.nagoya','.net.au','.net.cn','.net.in','.net.ki','.net.nf','.net.pk','.net.ps','.net.sa','.net.sb','.nf','.nu','.nz',
    '.okinawa','.org.cn','.org.il','.org.in','.org.ki','.org.pk','.org.ps','.org.sa','.org.sb','.org.tw','.osaka','.ph','.pk','.ps',
    '.pw','.qa','.radio.am','.radio.fm','.ryukyu','.sa','.sg','.taipei','.tk','.tl','.to','.tokyo','.tv','.tw','.vn','.vu','.wf','.ws',
    '.yokohama','.id.au','.ru.net','.tw.cn','.tj','.ge','.uz','.aero','.africa','.airforce','.apartments','.army','.art','.best','.biz',
    '.black','.blue','.boston','.care','.casa','.center','.city','.claims','.clinic','.codes','.com','.condos','.country','.date','.day',
    '.design','.diamonds','.direct','.directory','.earth','.eco','.estate','.exposed','.express','.feedback','.fly','.fun','.furniture',
    '.fyi','.glass','.global','.gold','.group','.help','.here','.hospital','.hot','.house','.info','.ing','.ink','.international',
    '.jetzt','.kitchen','.land','.link','.live','.living','.love','.maison','.map','.men','.name','.navy','.net','.network',
    '.now','.org','.organic','.parts','.ping','.pink','.place','.plus','.pro','.properties','.radio','.red','.review','.rich',
    '.rip','.school','.schule','.search','.services','.shiksha','.show','.software','.solar','.space','.study','.support',
    '.systems','.talk','.team','.tech','.tips','.tires','.today','.tools','.top','.tours','.town','.training','.tube','.video',
    '.vin','.vip','.vision','.watch','.wedding','.world','.xxx','.xyz','.zone','.contact','.quest','.abogado','.accountant',
    '.accountants','.adult','.agency','.archi','.associates','.attorney','.auction','.autos','.bar','.bargains','.beauty',
    '.boutique','.build','.builders','.business','.capital','.careers','.cash','.ceo','.cheap','.cleaning','.clothing',
    '.company','.compare','.construction','.consulting','.contact','.contractors','.coupon','.coupons','.credit','.creditcard',
    '.deals','.delivery','.dental','.dentist','.dev','.discount','.doctor','.energy','.engineer','.engineering','.enterprises',
    '.exchange','.expert','.farm','.feedback','.finance','.financial','.flights','.florist','.forsale','.fund','.gallery','.gift',
    '.gifts','.gmbh','.graphics','.gratis','.hair','.haus','.health','.holdings','.homes','.immo','.immobilien','.inc','.industries',
    '.institute','.insure','.investments','.jewelry','.jobs','.kaufen','.law','.lawyer','.lease','.legal','.lighting','.limited','.llc',
    '.loan','.loans','.ltd','.ltda','.makeup','.management','.market','.marketing','.menu','.money','.mortgage','.physio','.plumbing',
    '.press','.productions','.promo','.qpon','.rehab','.rent','.rentals','.repair','.report','.rest','.restaurant','.sale','.salon',
    '.sarl','.science','.select','.shoes','.shop','.shopping','.skin','.solutions','.srl','.storage','.store','.supplies','.supply',
    '.surgery','.tax','.taxi','.tel','.tickets','.tienda','.trade','.ventures','.versicherung','.vet','.villas','.work','.works','.art',
    '.auto','.baby','.band','.basketball','.beer','.bet','.bid','.bike','.bingo','.boats','.cab','.cafe','.cam','.camera','.camp','.car',
    '.cards','.cars','.casino','.catering','.club','.coach','.coffee','.cooking','.cricket','.cruises','.dad','.dance','.drive','.equipment',
    '.family','.fashion','.film','.fish','.fishing','.fit','.fitness','.football','.fun','.futbol','.game','.games','.garden','.golf','.health',
    '.hockey','.holiday','.horse','.limo','.lol','.mom','.movie','.ninja','.party','.pet','.photo','.photography','.photos','.pics',
    '.pictures','.pizza','.poker','.pub','.racing','.recipes','.reise','.reisen','.rodeo','.run','.ski','.soccer','.sport','.studio','.style',
    '.surf','.tattoo','.tennis','.theater','.theatre','.toys','.travel','.vacations','.viajes','.vodka','.voyage','.win','.wine','.yoga','.monster',
    '.academy','.actor','.app','.bio','.blog','.buzz','.charity','.chat','.church','.click','.cloud','.college','.community','.computer','.cool',
    '.courses','.dating','.degree','.democrat','.dev','.digital','.docs','.dog','.domains','.download','.eat','.eco','.education','.email','.eus',
    '.events','.fail','.faith','.fan','.fans','.forum','.foundation','.gal','.gay','.gives','.green','.gripe','.guide','.guru','.healthcare',
    '.hiv','.host','.icu','.kid','.kim','.lgbt','.life','.luxe','.luxury','.mba','.media','.meme','.memorial','.mobi','.moda','.moe','.news',
    '.observer','.one','.onl','.online','.page','.partners','.porn','.republican','.reviews','.rocks','.sex','.sexy','.singles','.site',
    '.social','.stream','.sucks','.technology','.university','.uno','.vote','.voto','.web','.webcam','.website','.wiki','.wtf','.you',
    '.zip','.abogado','.monster'];

  get tlds(): string[] {
    return this._tlds;
  }
}
