/* projects/shared/src/lib/components/rating/rating-summary/rating-summary.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 270px;
}
.average {
  display: inline-block;
  flex: 0.3;
  text-align: center;
  vertical-align: top;
  min-width: 120px;
  max-width: 50%;
}
.average .rating {
  color: #616161;
  font-size: 2.5em;
  font-weight: 100;
  line-height: 1.1em;
}
.average .stars {
  margin: 5px 0;
}
.average .total {
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
}
.average .total mat-icon {
  font-size: 14px;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 3px;
}
.segments {
  display: inline-block;
  flex: 0.7;
  box-sizing: border-box;
  padding-left: 30px;
  vertical-align: top;
}
.segments > div {
  color: #616161;
  font-weight: 400;
  height: 20px;
  margin-bottom: 4px;
  position: relative;
  width: 100%;
}
.segments > div > span:first-child {
  font-size: 11px;
  left: -16px;
  letter-spacing: 1px;
  position: absolute;
}
.segments > div > span:nth-child(2) {
  display: inline-block;
  height: 100%;
  opacity: 0.8;
}
.segments > div.rating-1 > span:nth-child(2) {
  background: #ff6f31;
}
.segments > div.rating-2 > span:nth-child(2) {
  background: #ff9f02;
}
.segments > div.rating-3 > span:nth-child(2) {
  background: #ffcf02;
}
.segments > div.rating-4 > span:nth-child(2) {
  background: #9ace6a;
}
.segments > div.rating-5 > span:nth-child(2) {
  background: #57bb8a;
}
:host-context(.color-scheme-dark) .average .rating {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .average .total {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .segments > div {
  color: #AEAFB0;
}
/*# sourceMappingURL=rating-summary.component-NLOCC2W6.css.map */
