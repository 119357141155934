import {ContactPropertiesAccessor} from "./contact-properties.accessor";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export abstract class ContactPropertiesService {
  abstract contactProperties$(contactId:string, prefix?:string):Promise<ContactPropertiesAccessor>;
  abstract contactAppProperties$(contactId:string, prefix?:string):Promise<ContactPropertiesAccessor>;
  abstract contactGroupProperties$(contactId:string, prefix?:string):Promise<ContactPropertiesAccessor>;
}
