/*
SESSION RELATED
*/
import {Message} from "messaging";
import {Session} from "../../session/store/models";
import {GroupMembership} from "core";

export interface SessionRelatedMessage extends Message {
}

export const SessionAssignmentMessageType: string = "sessionAssignment";
export interface SessionAssignmentMessage extends SessionRelatedMessage {
  userId: string;
  session?: Session;
  groupMembership?: GroupMembership;
}

export const SessionTokenMessageType : string = "sessionToken";
export interface SessionTokenMessage extends SessionRelatedMessage {
  token: string;
}

export const SessionTokenInviteMessageType : string = "sessionTokenInvite";
export interface SessionTokenInviteMessage extends SessionTokenMessage {
  invitationToken: string;
}

