import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {getPreferredThemeMode, rgb2hex} from "core";

export interface LoginQrCodeDialogData {
  token: Observable<string>;
}

@Component({
  selector: 'app-loginqr-code-dialog',
  templateUrl: './login-qr-code-dialog.component.html',
  styleUrls: ['./login-qr-code-dialog.component.scss']
})
export class LoginQrCodeDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LoginQrCodeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: LoginQrCodeDialogData) { }

  ngOnInit() {
  }

  get qrCodeColorDark(): string {
    return getPreferredThemeMode()=='dark' ? '#DDDDDD' : '#000000';

  }

  get qrCodeColorLight(): string {
    return getPreferredThemeMode()=='dark' ? '#333333' : '#FFFFFF';
  }
}
