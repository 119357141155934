/* projects/shared/src/lib/components/basic-main-container/basic-main-container.component.scss */
:host {
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
}
mat-sidenav-container {
  position: relative;
  display: flex;
  flex: 1;
  background-color: lavenderblush;
  width: 100%;
}
mat-sidenav-content {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
mat-sidenav,
mat-sidenav.md-locked-open-add-active,
mat-sidenav.md-closed.md-locked-open-add-active,
mat-sidenav.md-locked-open {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  border-right: 1px solid lightgray;
}
mat-sidenav.mat-drawer-over,
mat-sidenav.md-locked-open-add-active.mat-drawer-over,
mat-sidenav.md-closed.md-locked-open-add-active.mat-drawer-over,
mat-sidenav.md-locked-open.mat-drawer-over {
  border-right-width: 0;
}
mat-sidenav[position=start] {
  left: 0;
}
mat-sidenav[position=end] {
  right: 0;
}
/*# sourceMappingURL=basic-main-container.component-ZLKPBYFA.css.map */
