<div *ngIf="media" class="container">
  <div #rating class="section">
    <h4 class="header" matSubheader translate>rating.own</h4>
    <div class="rating">
      <star-rating #starRating
        [rating]="media.rating"
        [space]="'between'"
        [hoverEnabled]="false">
      </star-rating>
      <button [disabled]="starRating.rating==media.rating ||
                        starRating.rating==0 && !media.rating"
        (click)="onSaveRating(starRating.rating)"
        mat-mini-fab
        type="button"
        aria-label="save rating">
        <mat-icon>done</mat-icon>
      </button>
      <button [disabled]="!starRating.rating"
        (click)="onSaveRating(undefined)"
        mat-mini-fab
        type="button"
        aria-label="remove rating">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div #ratingSummary *ngIf="media?.reactionSummary?.ratings; let ratings" class="section">
    <h4 class="header" matSubheader translate>rating.summary</h4>
    <rating-summary [ratings]="ratings"></rating-summary>
  </div>
  <div *ngIf="media?.reactionSummary?.views?.length>10" class="section">
    <h4 class="header" matSubheader translate>media.viewed.label</h4>
    <div class="all-views" *ngLet="media.reactionSummary.views.slice().reverse() as reversed">
      <ng-container *ngFor="let views of reversed; let index=index; trackBy:trackByRow;">
        <div class="row" *ngIf="views>0"><div>{{views.toLocaleString()}}</div><div class="label" translate>{{viewsLabels[index]}}</div></div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #actionsTemplate>
  <star-rating #starRating>
    [rating]="media.rating"
    [space]="'between'"
    [hoverEnabled]="false">
  </star-rating>
</ng-template>
