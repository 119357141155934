import {ChangeDetectionStrategy, Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {Observable} from "rxjs";
import {UploadFile, UploadFiles} from "../../store/models";
import {UploadBatch, UploadRef} from "../../upload.service"
import {ConfirmDialogComponent} from "shared";
import {MatDialog} from "@angular/material/dialog";

@Pipe({ name: 'uploadFiles' })
export class UploadFilesPipe implements PipeTransform {
  public constructor() {}
  transform(uploadFiles: UploadFiles, uploadBatchId: string): { uploadBatchId: string, uploadFile: UploadFile }[] {
      return Object.values(uploadFiles).filter(uploadFile => {
        const  progress = uploadFile && uploadFile.progress;
        return progress.uploadStarted && !progress.uploadComplete;
      }).map(uploadFile => ({ uploadBatchId: uploadBatchId, uploadFile: uploadFile }))
  }
}

@Component({
  selector: 'upload-list',
  templateUrl: './upload-list.component.html',
  styleUrls: ['./upload-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadListComponent implements OnInit {

  @Input() uploadBatches: Observable<UploadBatch[]>;
  @Input() uploadRef: (id: string) => UploadRef;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
  }

  onAction(uploadId: string, uploadFile: UploadFile, action: string) {
    const uploadRef = this.uploadRef(uploadId);
    if (uploadRef) {
      switch(action) {
        case 'pause' : uploadRef.pause(uploadFile.id);  uploadRef.release(); break;
        case 'resume': uploadRef.resume(uploadFile.id); uploadRef.release(); break;
        case 'cancel': {
          const paused = uploadRef.isPaused(uploadFile.id);
          !paused && uploadRef.pause(uploadFile.id);
          this.dialog.open(ConfirmDialogComponent,{
            data: {
              label: 'upload.confirmCancel',
              parameters: {
                file: uploadFile.name
              },
              confirmAction: 'yes',
              cancelAction: 'no'
            },
            closeOnNavigation: true
          }).afterClosed().subscribe(result => {
            if (result) {
              uploadRef.cancel(uploadFile.id);
            } else {
              !paused && uploadRef.resume(uploadFile.id);
            }
            uploadRef.release();
          });
          break;
        }
      }
    }
  }

  trackByUploadBatchId(index: number, uploadBatch: UploadBatch) {
    return uploadBatch.uploadId;
  }

  trackByUploadFileId(index: number, uploadFile: {uploadBatchId: string, uploadFile: UploadFile}) {
    return `${uploadFile.uploadBatchId}.${uploadFile.uploadFile.id}`;
  }
}
