import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {BasicContainerComponent} from ".././basic-container/basic-container.component";
import {MenuService} from "./menu.service";
import {FilterDefinition} from "./models/filter";
import {FilterSection} from "./models/section";
import {MenuSelection} from "./models/selection";
import {takeUntil} from "rxjs/operators";
import {Router} from "@angular/router";
import {enterLeaveAnimations} from "../../animations/animations";
import {Observable} from "rxjs";
import {Badge, badgeType} from "../../models/badge";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [ enterLeaveAnimations ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent extends BasicContainerComponent {

  //filterValues : { [key:string]: string[] } = {};
  //selection = MenuSelection.EMPTY;

  @Output() onSelectionChange: EventEmitter<MenuSelection> = new EventEmitter();
  badgeType = badgeType;

  constructor(
    public menuService: MenuService,
    public router: Router) {
    super();
    //console.log("MENU.COMPONENT.ctor()");
    this.menuService.menuSelection$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((menuSelection) => {
        //console.debug("menuSelection$",event);
        //console.debug("this.menuService.menuSelection$",menuSelection);
        this.onSelectionChange.emit(menuSelection);
      });
  }

  /*sectionLink(filterSection: FilterSection): string {
    const link = this.menuService.sectionLink(filterSection);
    console.log("XY.sectionLink",filterSection,"link",link);
    return link;
  }*/
  sectionLink(filterSection: FilterSection): string {
    return this.menuService.sectionLink(filterSection);
  }

  filterLink(filterSection: FilterSection, filterDefinition: FilterDefinition): string {
    return this.menuService.filterLink(filterSection,filterDefinition);
  }

  filterSelected(filterSection: FilterSection, filterDefinition: FilterDefinition): void {
    /*
            <div *ngIf="filter.visible"
                 class="filter{{isFilterSelected(section,filter) ? ' selected' : ''}}"
                 [routerLink]="filterLink(section,filter)"
                 (click)="filterSelected(section,filter)"
                 translate>{{filter.label}}</div>

     */
    let menuSelection = this.menuService.menuSelection;
    this.router.navigateByUrl(this.filterLink(filterSection,filterDefinition));
    //console.debug("filterSelected",filterSection,filterDefinition,"menu",menuSelection);
    if (menuSelection?.section == filterSection.id) {
      //console.debug("filterSelected.2",filterSection,filterDefinition);
      menuSelection.done = !filterSection.submenu;
    }
    this.onSelectionChange.emit(menuSelection);
  }

  isSectionSelected(filterSection: FilterSection): boolean {
    return filterSection.id==this.menuService.menuSelection.section;
  }

  isFilterSelected(filterSection: FilterSection, filterDefinition: FilterDefinition): boolean {
    return this.isSectionSelected(filterSection) && this.menuService.selectedFilters(filterSection).includes(filterDefinition.filter);
  }

  trackSection(index: number, filterSection: FilterSection): string {
    return filterSection ? filterSection.id : null;
  }

  trackFilter(index: number, filterDefinition: FilterDefinition): string {
    //return filterDefinition ? filterDefinition.id + ':' + filterDefinition.section.id + ':' + this.isFilterSelected(filterDefinition.section,filterDefinition) : null;
    //const track = filterDefinition ? filterDefinition.section.id + ':' + filterDefinition.id : null;
    //console.debug("trackFilter "+track);
    //return track;
    return filterDefinition ? filterDefinition.section.id + ':' + filterDefinition.id : null;
  }

  areSectionFiltersAvailable(filterSection:FilterSection):boolean {
    return filterSection.visible && filterSection.enabled && this.menuService.hasSectionFilters(filterSection);
  }

  areSectionFiltersVisible(filterSection:FilterSection):boolean {
    let hasFilters  = this.areSectionFiltersAvailable(filterSection);
    let showFilters = hasFilters && filterSection.id==this.menuService.menuSelection.section;
    //console.log("MENU.areSectionFiltersVisible",hasFilters,showFilters,filterSection);
    return showFilters;
  }

  badge(filterSection: FilterSection, filterDefinition: FilterDefinition): Observable<Badge> {
    const badgeId = `${filterSection.id}#${filterDefinition.id}`;
    //console.log("XY.badge",badgeId);
    return this.menuService.getBadgeObservable$(badgeId);
  }
}
