import {Component} from '@angular/core';
import {combineLatest, takeUntil} from 'rxjs';
import {MediaDetailsSectionComponent} from '../media-details/media-details-section.component';
import {FilterService} from 'filter';
import {MediaDetailsShareDataService} from '../../service/media-details-share-data.service';
import {PropertiesService} from 'properties';

@Component({
  selector: 'media-details-user-role',
  templateUrl: './media-details-user-role.component.html',
  styleUrls: ['./media-details-user-role.component.scss']
})
export class MediaDetailsUserRoleComponent extends MediaDetailsSectionComponent {

  roles = [{ id: 'role.user', label: 'userRoles.user', leader: false },
  { id: 'role.leader', label: 'userRoles.leader', leader: true }];
  isLeader: boolean;
  mediaTags: string[];

  constructor(
    private filterService: FilterService,
    protected mediaDetailsShareDataService: MediaDetailsShareDataService,
    private propertiesService: PropertiesService
  ) {
    super(mediaDetailsShareDataService);
  }

  ngOnInit(): void {
    combineLatest([
      this.mediaDetailsShareDataService.getEditMode$,
      this.mediaDetailsShareDataService.getMediaTags$,
      this.mediaDetailsShareDataService.getOptions$,
      this.propertiesService.user$
    ])
      .pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(
        ([editMode, mediaTags, options, user]) => {
          this.mediaTags = mediaTags;
          this.isHidden.emit(!editMode || options.mode == 'basic' || !(user.isMember || user.isAdmin));
          this.isLeader = user.isLeader || user.isAdmin;
        });
  }

  toggleFilter(filterId: string) {
    const newTags = this.filterService.setFilter(this.mediaTags, filterId, false);
    this.mediaDetailsShareDataService.setMediaTags(newTags);
  }

}
