/* projects/media/src/lib/components/media-report/media-report.component.scss */
mat-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  align-items: flex-start;
}
mat-radio-group mat-radio-button {
  margin: 5px;
}
/*# sourceMappingURL=media-report.component-4OABGVVH.css.map */
