import {NgModule} from '@angular/core';
import {AuthenticationModule} from "./authentication/authentication.module";
import {SessionModule} from "./session/session.module";

@NgModule({
  imports: [
    AuthenticationModule,
    SessionModule
  ],
  exports: [
    AuthenticationModule,
    SessionModule
  ],
})
export class AuthModule {

}
