import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, fromEvent, mapTo, merge, ReplaySubject} from "rxjs";
import {auditTime, distinctUntilChanged, switchMap, takeUntil} from "rxjs/operators";
import {isEqual} from "lodash";
import {BasicContainerComponent} from "../basic-container/basic-container.component";
import {Logger} from "core";

@Component({
  selector: 'legal-info',
  templateUrl: './legal-info.component.html',
  styleUrls: ['./legal-info.component.scss']
})
export class LegalInfoComponent extends BasicContainerComponent implements OnInit, AfterViewInit {

  private static defaultInfoLabel = 'login.legal_info';

  @Input() set infoLabel(label: string) {
    this.logger.debug('infoLabel', label);
    this.infoLabel$.next(label || LegalInfoComponent.defaultInfoLabel);
  };

  @Input() set sectionId(sectionId: string) {
    this.logger.debug('sectionId', sectionId);
    this.sectionId$.next(sectionId);
  }

  protected initialized$ = new ReplaySubject(1);
  public infoLabel$ = new BehaviorSubject<string>(LegalInfoComponent.defaultInfoLabel);
  protected sectionId$ = new BehaviorSubject<string>(undefined);

  protected logger = new Logger('LegalInfoComponent');

  constructor(protected elementRef: ElementRef) {
    super();
  }

  ngOnInit(): void {
    const element = this.elementRef.nativeElement;
    this.initialized$.pipe(
      takeUntil(this.onDestroy$),
      switchMap(() => combineLatest([
        this.infoLabel$,
        merge(
          this.sectionId$,
          fromEvent(element, 'scroll').pipe(mapTo(undefined))
        )
      ])),
      auditTime(100),
      distinctUntilChanged((v1, v2) => isEqual(v1, v2))
    ).subscribe(([infoLabel, sectionId]) => {
      this.logger.debug([infoLabel, sectionId]);
      setTimeout(() => {
        if (sectionId) {
          const target = element.querySelector(`#${sectionId}`);
          target?.scrollIntoView();
        } else if (this.sectionId$.getValue()!=sectionId) {
          this.sectionId$.next(sectionId)
        }
      });
    })
  }

  ngAfterViewInit() {
    this.initialized$.next(void 0);
    this.initialized$.complete();
  }
}
