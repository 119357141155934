import {ActionsSubject, Store} from "@ngrx/store";
import {Inject, Injectable, NgZone} from "@angular/core";
import {ENVIRONMENT, Logger, once, Platform} from "core";
import {BehaviorSubject, interval, Observable, Subscription, tap} from "rxjs";
import {StoreService} from "store";
import {Diagnostic} from '@ionic-native/diagnostic/ngx';
import {RecorderService} from "./audio-recording.service";
import {File, FileEntry} from "@ionic-native/file/ngx";

export interface MediaUploadOptions {
  media: {
    tags:string[];
    properties: {
      'conversationId':string;
    }
  }
}

export interface MediaRecording {
  recordingTime$:Observable<number>; // seconds....
  // stream is not available when the recording is performed with cordova plugin
  // in this case getUserMedia() is not invoked at all
  stream?: MediaStream;
  mimeType:string;
  recordingTime():number;
  stop():Promise<Blob[]>;
}

declare var cordova: any;

@Injectable({
  providedIn: 'root'
})
export class MediaRecordingService extends StoreService {

  protected logger = new Logger('MediaRecordingService');

  constructor(protected store$: Store<any>,
              protected action$: ActionsSubject,
              protected platform: Platform,
              protected diagnostic: Diagnostic,
              protected file: File,
              protected zone: NgZone,
              @Inject(ENVIRONMENT)
              protected environment: any) {
    super(store$,action$);
    if (this.platform.is('hybrid')) {
      const resolvePermissionStatus = (status): PermissionState => {
        const permissionStatus = this.diagnostic.permissionStatus;
        let result: PermissionState;
        switch (status) {
          case permissionStatus.GRANTED:
          case permissionStatus.GRANTED_WHEN_IN_USE:
            result = 'granted';
            break;
          case permissionStatus.NOT_REQUESTED:
          case permissionStatus.DENIED_ONCE:
          case permissionStatus.RESTRICTED:
            result = 'prompt';
            break;
          case permissionStatus.DENIED_ALWAYS:
            result = 'denied';
            break;
          default:
            result = 'granted'
        }
        return result;
      }

      Promise.all([
        this.diagnostic.getMicrophoneAuthorizationStatus().catch(error => {
          this.logger.warn('Failed to get microphone authorization status', error);
          return this.diagnostic.permissionStatus.NOT_REQUESTED;
        }),
        this.diagnostic.isCameraPresent()
          .then(present => present ? this.diagnostic.getCameraAuthorizationStatus(true) : this.diagnostic.permissionStatus.DENIED_ALWAYS)
          .catch(error => {
            this.logger.warn('Failed to get camera authorization status', error);
            return this.diagnostic.permissionStatus.NOT_REQUESTED;
          })
      ])
      .then(([microphoneAuthorizationStatus, cameraAuthorizationStatus]) => {
        const shouldRequestAuthorization = (status): boolean =>
          status !== this.diagnostic.permissionStatus.GRANTED &&
          status !== this.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE &&
          (
            // from plugin doc:
            // DENIED_ALWAYS - "User denied access to this permission. App can never ask for permission again.
            // The only way around this is to instruct the user to manually change the permission in Settings."
            // https://github.com/dpa99c/cordova-diagnostic-plugin#requestruntimepermissions

            // !this.platform.is('ios') ||
            status != this.diagnostic.permissionStatus.DENIED_ALWAYS
          )
        const requestMicrophoneAuthorization = shouldRequestAuthorization(microphoneAuthorizationStatus);
        const     requestCameraAuthorization = shouldRequestAuthorization(cameraAuthorizationStatus);
        if (this.platform.is('android') && requestMicrophoneAuthorization && requestCameraAuthorization) {
          this.diagnostic.requestRuntimePermissions([
            this.diagnostic.permission.RECORD_AUDIO,
            this.diagnostic.permission.CAMERA
          ]).then((result) => {
            return Object.entries(result).reduce((permissionStatuses, [permission, status]) => {
              permissionStatuses.splice(permission==this.diagnostic.permission.RECORD_AUDIO ? 0 : 1, 0, status);
              return permissionStatuses;
            }, []);
          });
        } else {
          const requestMicrophoneAuthorizationPromise = requestMicrophoneAuthorization
            ? this.diagnostic.requestMicrophoneAuthorization()
              .catch(error => {
                this.logger.error('Failed microphone authorization', error);
                return this.diagnostic.permissionStatus.NOT_REQUESTED;
              })
            : Promise.resolve(microphoneAuthorizationStatus);
          return requestMicrophoneAuthorizationPromise.then(microphoneAuthorizationStatus => {
            const requestCameraAuthorizationPromise = requestCameraAuthorization
              ? this.diagnostic.requestCameraAuthorization()
                .catch(error => {
                  this.logger.error('Failed camera authorization', error);
                  return this.diagnostic.permissionStatus.NOT_REQUESTED;
                })
              : Promise.resolve(cameraAuthorizationStatus);
              return requestCameraAuthorizationPromise.then(cameraAuthorizationStatus => [
                microphoneAuthorizationStatus,
                cameraAuthorizationStatus
              ]);
            }
          )
        }
      })
      .then(([microphoneAuthorizationStatus, cameraAuthorizationStatus]) => {
        this.microphonePermission$.next(resolvePermissionStatus(microphoneAuthorizationStatus));
        this.cameraPermission$.next(resolvePermissionStatus(cameraAuthorizationStatus));
      })

      /*
      this.diagnostic.getMicrophoneAuthorizationStatus()
        .then(status => shouldRequestAuthorization(status)
                      ? this.diagnostic.requestMicrophoneAuthorization()
                      : status
        )
        .then(status => this.microphonePermission$.next(resolvePermissionStatus(status)))
        .catch(error => {
          this.logger.error('Failed microphone permission request', error);
          this.microphonePermission$.next('denied');
        });

      this.diagnostic.isCameraPresent()
        .then(present => present ? this.diagnostic.getCameraAuthorizationStatus(true) : this.diagnostic.permissionStatus.DENIED_ALWAYS)
        .then(status => shouldRequestAuthorization(status)
                     ? this.diagnostic.requestCameraAuthorization()
                     : status
        )
        .then(status => this.cameraPermission$.next(resolvePermissionStatus(status)))
        .catch(error => {
          this.logger.error('Failed camera presence detection or permission request', error);
          this.cameraPermission$.next('denied');
        });
       */
    } else if (!!navigator.permissions?.query) {
      const microphonePermission$ = this.microphonePermission$;
      // TODO: microphone not yet standard... supported on all chromium based & firefox...
      navigator.permissions.query({name:<PermissionName>'microphone'}).then(function(result) {
        microphonePermission$.next(result?.state);
        //console.log("PERMISSION.microphone",result?.state);
        result.onchange = function() {
          microphonePermission$.next(result?.state);
          //console.log("PERMISSION.microphone",result?.state);
        };
      });
      const cameraPermission$ = this.cameraPermission$;
      // TODO: camera not yet standard... supported on all chromium based & firefox...
      navigator.permissions.query({name:<PermissionName>'camera'}).then(function(result) {
        cameraPermission$.next(result?.state);
        //console.log("PERMISSION.camera",result?.state);
        result.onchange = function() {
          cameraPermission$.next(result?.state);
          //console.log("PERMISSION.camera",result?.state);
        };
      });
    }
  }

  /**
   * RECORDING API
   */
  microphonePermission$ = new BehaviorSubject<PermissionState>('prompt');
  cameraPermission$ = new BehaviorSubject<PermissionState>('prompt');

  record(audio:boolean, video?:boolean): Promise<MediaRecording> {
    return new Promise((resolve, reject) => {
      if (!audio && !video) {
        reject("wrong parameter");
      } else {
        const microphonePermission = this.microphonePermission$.getValue();
        const     cameraPermission = this.cameraPermission$.getValue();
        if ((!audio || (microphonePermission == 'prompt' || microphonePermission == 'granted')) &&
            (!video || (cameraPermission == 'prompt' || cameraPermission == 'granted'))) {
          const isIosWebView = this.platform.is('hybrid') && this.platform.is('ios');
          // on ios MediaRecorder incorporated in webkit engine asks for permission after each app restart!
          const hasMediaRecorder = typeof MediaRecorder != undefined && !isIosWebView
          const canRecordMedia = hasMediaRecorder || typeof cordova.plugins.audioRecorder != 'undefined';
          if (canRecordMedia) {
            const logger = new Logger('MediaRecorder');
            const timeTracker = {
              _subscription: undefined,
              currentTime$: new BehaviorSubject<number>(0),
              startTime: 0,
              start: function(): Subscription {
                if (!this.startTime) {
                  this.startTime = Date.now();
                  logger.debug('RECORDING.START', this.startTime);
                  this._subscription = interval(200)
                    .subscribe({
                      next: (period) => {
                        if (this.startTime > 0) {
                          const seconds = Math.floor((Date.now() - this.startTime) / 1000);
                          if (this.currentTime$.getValue() != seconds) {
                            //logger.debug('RECORDING.TIME', seconds);
                            //console.log("RECORDING.TIME",seconds);
                            this.currentTime$.next(seconds);
                          }
                        }
                      },
                      error: (error) => logger.error(error),
                      complete: () => this.stop()
                    });
                }
                return this._subscription;
              },
              stop: function () {
                logger.debug('RECORDING.STOP', this.startTime);
                if (this.startTime>0) {
                  this._subscription?.unsubscribe();
                  this._subscription = undefined;
                }
              }
            };
            let startPromise: Promise<void>;
            let stop: () => Promise<Blob[]>;
            let mimeType: string;
            if (hasMediaRecorder) {
              startPromise = navigator.mediaDevices
                .getUserMedia({ audio: true, video: false })
                .then(stream => {
                  stream.getTracks().forEach(track => {
                    logger.debug("Recording stream track", { track });
                  });
                  const options = {}; //{mimeType: 'audio/mpeg'};
                  const recordedChunks: Blob[] = [];
                  let resolveChunks: (blobs: Blob[]) => void = (blobs) => {};
                  const mediaRecorder: MediaRecorder = new MediaRecorder(stream, options);
                  mimeType = mediaRecorder.mimeType;
                  stop = () => new Promise<Blob[]>((resolve, reject) => {
                    if (mediaRecorder.state == 'recording') {
                      resolveChunks = (blobs) => {
                        logger.debug('resolveChunks', blobs);
                        resolve(blobs);
                      };
                      mediaRecorder.stop();
                    } else {
                      mediaRecorder.stop();
                      reject("illegal state")
                    }
                  });
                  mediaRecorder.ondataavailable = (event) => {
                    logger.debug("ondataavailable", event.data?.size, mediaRecorder.state);
                    if (event.data.size > 0) {
                      recordedChunks.push(event.data);
                    }
                    if (mediaRecorder.state == 'inactive') {
                      resolveChunks(recordedChunks);
                    }
                  };
                  mediaRecorder.onerror = (error) => {
                    logger.debug("onerror", error);
                    resolveChunks(recordedChunks);
                  }
                  mediaRecorder.onstop = (event) => {
                    logger.debug("onstop", event);
                    timeTracker.stop();
                    stream.getTracks().forEach(track => {
                      track.stop();
                      stream.removeTrack(track);
                    })
                  }
                  mediaRecorder.onstart = (event) => {
                    logger.debug("onstart", event);
                    timeTracker.start();
                  }
                  mediaRecorder.start();
                })
            } else {
              // ios app -> use cordova plugin because wkwebview does not allow audio recording
              // https://github.com/mohamad-wael/cordova-audio-recorder-plugin
              const audioRecorder = cordova.plugins.audioRecorder;
              mimeType = 'audio/m4a'; // standard format defined by the plugin
              const readRecordedFile: (path: string) => Promise<Blob> = (path: string) =>
                this.file.resolveLocalFilesystemUrl(path)
                  .then(entry => {
                    if (entry.isFile) {
                      return new Promise((resolve, reject) => {
                        (entry as FileEntry).file((file) => {
                          const reader = new FileReader();
                          reader.onloadend = (event) => {
                            try {
                              const result = event.target.result as ArrayBuffer;
                              const blob = new Blob([new Uint8Array(result)], {type: mimeType});
                              logger.debug('audio onloadend', blob.size);
                              resolve(blob);
                            } catch (error) {
                              reject(error);
                            }
                          };
                          reader.onerror = (error) => reject(error);
                          reader.readAsArrayBuffer(file);
                        })
                      })
                    } else {
                      reject('wrong file path')
                    }
                  });
              let stopResolve, stopReject;
              const stopPromise = new Promise<Blob[]>((resolve, reject) => {
                stopResolve = once((result) =>  this.zone.run(() => resolve(result)));
                stopReject  = once((error) =>  this.zone.run(() => reject(error)));
              });
              stopPromise.catch(error => {
                logger.error('audioCapture -> stop', error);
                throw error;
              });
              const handleAudioCapture = async (event) => {
                let blobs: Blob[] = [];
                try {
                  const blob = await readRecordedFile(event);
                  blobs.push(blob)
                } catch (error) {
                  logger.error('failed to read recorded file', error);
                } finally {
                  logger.debug("handleAudioCapture.STOP");
                  timeTracker.stop();
                  stopResolve(blobs);
                }
              };
              stop = (): Promise<Blob[]> => {
                audioRecorder.audioCapture_Stop(
                  async (event) => {
                    logger.debug('audioCapture_Stop()', event);
                    await handleAudioCapture(event);
                  },
                  stopReject
                )
                return stopPromise;
              };
              timeTracker.start();
              audioRecorder.audioCapture_Start(
                async (event) => {
                  logger.debug('audioCapture_Start()', event);
                  await handleAudioCapture(event);
                },
                stopReject,
                60 * 60 * 5 // max duration in seconds default - 60
              )
              startPromise = Promise.resolve();
            }
            startPromise
              .then(() => {
                resolve({
                  stop, mimeType, /*stream,*/
                  recordingTime(): number {
                    return timeTracker.currentTime$.getValue();
                  },
                  recordingTime$: timeTracker.currentTime$//.pipe(tap (time => logger.debug('recordingTime$', time)))
                });
              })
            .catch(error => {
              logger.error('Failed to start audio recording', error);
              reject(error);
            });
          } else {
            reject("not supported");
          }
        } else {
          reject("no permission");
        }
      }
    });
  }
}

