import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, cordova } from '@ionic-native/core';
var Clipboard = /** @class */function (_super) {
  __extends(Clipboard, _super);
  function Clipboard() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Clipboard.prototype.copy = function (text) {
    return cordova(this, "copy", {}, arguments);
  };
  Clipboard.prototype.paste = function () {
    return cordova(this, "paste", {}, arguments);
  };
  Clipboard.prototype.clear = function () {
    return cordova(this, "clear", {}, arguments);
  };
  Clipboard.pluginName = "Clipboard";
  Clipboard.plugin = "cordova-clipboard";
  Clipboard.pluginRef = "cordova.plugins.clipboard";
  Clipboard.repo = "https://github.com/ihadeed/cordova-clipboard";
  Clipboard.platforms = ["Android", "iOS", "Windows Phone 8"];
  Clipboard.decorators = [{
    type: Injectable
  }];
  return Clipboard;
}(IonicNativePlugin);
export { Clipboard };
