<div *ngIf="media" class="container">
  <accordion-container #accordion
                       [sections]="sections"
                       [startGap]="startGap"
                       [endGap]="endGap"
                       [target]="target">
  </accordion-container>
</div>

<!--
<mat-toolbar *ngIf="!editMode && isTemporaryMigrationAdmin" color="primary" fxLayout="row" #buttonBar>
  <button mat-button (click)="onMigrate()" aria-label="migrate">
    {{'actions.migrate' | translate}}
  </button>
</mat-toolbar>
-->
<mat-toolbar class="buttonBar" *ngIf="editMode" color="primary" #buttonBar>
  <!--<button mat-mini-fab (click)="onSaveMedia()" [disabled]="mediaForm.invalid || (mediaForm.pristine && !tagsDirty && !countriesChanged)" aria-label="save"> -->
  <button mat-mini-fab (click)="onSaveMedia()" [disabled]="isMediaFormUnchanged()" aria-label="save">
    <mat-icon>done</mat-icon>
  </button>
  <button mat-mini-fab color="primary" (click)="onCancel()" aria-label="cancel">
    <mat-icon>close</mat-icon>
  </button>
  <button *ngIf="media?.mediaType==MediaType.iap"
          mat-mini-fab color="primary"
          (click)="onPatch()"
          aria-label="delete">
    <mat-icon>upgrade</mat-icon>
  </button>
  <button mat-mini-fab color="primary" (click)="onDelete()" aria-label="delete">
    <mat-icon>delete_forever</mat-icon>
  </button>
</mat-toolbar>
