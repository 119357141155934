import {AfterViewInit, Component, ElementRef, NgZone, OnDestroy, Renderer2, ViewChild} from '@angular/core';
import {VirtualScrollbarComponent} from "../virtual-scroller/virtual-scroll.bar";
import {VirtualScrollHandler} from "../virtual-scroller/virtual-scroll.handler";
import {TouchScrollHandler} from "../virtual-scroller/touch-scroll.handler";
import {StandardScrollHandler} from "../virtual-scroller/standard-scroll.handler";
import {Platform} from "core";

@Component({
  selector: 'test',
  template: `
    <div class="main" (scroll)="onScroll()" (onResize)="onScroll()" #container>
      <div class="content" #content>
        <div *ngFor="let element of elements; let index=index">
          <span>Test {{index}}</span>
        </div>
      </div>
    </div>
    <virtual-scrollbar #scrollbar [virtualScrollHandler]="virtualScrollHandler">
    </virtual-scrollbar>`,
  styles: [
    `:host {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
             }`,
    `.main {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #c2eff5;
                overflow-x: hidden;
                overflow-y: auto;
                -ms-overflow-style: none !important;
                scrollbar-width: none !important;
             }
             .main::-webkit-scrollbar {
                display: none;
             }`,
    `.content {
                padding: 10px;
                background-color: wheat;
                color: #333;
             }`,
     `virtual-scrollbar {
               }`]
})
export class TestComponent implements OnDestroy, AfterViewInit {

  static counter = 0;
  protected instance:number;
  public elements = [];

  virtualScrollHandler: VirtualScrollHandler;

  @ViewChild('scrollbar') scrollbar: VirtualScrollbarComponent;
  @ViewChild('container', { read: ElementRef, static: true })
  protected container: ElementRef;
  @ViewChild('content', { read: ElementRef, static: true })
  protected content: ElementRef;

  constructor(public readonly elementRef: ElementRef,
              protected readonly platform: Platform,
              protected readonly renderer: Renderer2,
              protected readonly zone: NgZone) {
    this.instance = ++TestComponent.counter;
    console.log("TestComponent.ctor."+this.instance);
    this.elements.length = 100;
  }

  ngAfterViewInit(): void {
    this.virtualScrollHandler =
      this.platform.is('ios') ?
        new TouchScrollHandler(this.container,this.renderer,this.zone) :
        new StandardScrollHandler(this.container,this.renderer,this.zone);
    this.virtualScrollHandler.initialize(true, this.platform.is('ios'));
    this.updateScrollHandler();
    this.virtualScrollHandler.scrollPosition = 0;
    this.virtualScrollHandler.doScroll.subscribe(event=>{
      const scrollPosition = Math.round(this.virtualScrollHandler.scrollPosition);
      console.log("scrollPosition",scrollPosition);
      const container = <HTMLDivElement>this.container.nativeElement;
      container.scrollTop = scrollPosition;
      //(<HTMLDivElement>this.container.nativeElement).scrollTo(0,scrollPosition);
    });
  }

  updateScrollHandler() {
    this.virtualScrollHandler.scrollSize = (<HTMLDivElement>this.content.nativeElement).offsetHeight;
    this.virtualScrollHandler.viewportSize = (<HTMLDivElement>this.container.nativeElement).offsetHeight;
    this.virtualScrollHandler.scrollTo((<HTMLDivElement>this.container.nativeElement).scrollTop);
    console.log("updateScrollHandler",this.virtualScrollHandler);
  }

  ngOnDestroy(): void {
    console.log("TestComponent.destroy."+this.instance);
  }

  onScroll() {
    // (scroll)="onScroll($event)"
    const container      = <HTMLDivElement>this.container.nativeElement;
    const content        = <HTMLDivElement>this.content.nativeElement;
    const scrollPosition = container.scrollTop;
    const viewportSize   = container.offsetHeight;
    const scrollSize     = content.offsetHeight;
    if (this.virtualScrollHandler.scrollPosition!=scrollPosition ||
        this.virtualScrollHandler.scrollSize!=scrollSize ||
        this.virtualScrollHandler.viewportSize!=viewportSize) {
      console.log("onScroll.viewportSize",viewportSize,"scrollSize",scrollSize,"scrollPosition",scrollPosition);
      this.virtualScrollHandler.update(viewportSize,scrollSize,scrollPosition,true);
    }
  }
}
