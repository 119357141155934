import {Observable} from "rxjs";
import {Action} from "core";

export class RouteContext extends Observable<Action> {
  count$: Observable<number>;
  filters$: Observable<boolean>;
  search$: Observable<boolean>;
  menuAction$: Observable<Action>;
  action?: (action: Action) => void;
}
