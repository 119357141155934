/* projects/shared/src/lib/components/language/language-selector/language-selector.component.scss */
:host {
  display: block;
  position: relative;
}
app-language-list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
app-language-list .mat-mdc-icon-button {
  width: 18px;
  height: 18px;
  line-height: 18px;
}
app-language-list .mat-mdc-icon-button .mat-icon {
  line-height: 18px;
  height: 18px;
  width: 18px;
  font-size: 18px;
}
/*# sourceMappingURL=language-selector.component-63Z2I6AJ.css.map */
