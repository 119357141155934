import {Subject} from "rxjs";
import {isValidNumber} from "shared";

export type Segment = {
  start: number;
  end: number;
  toString?: () => string;
};

export class VideoTracker {

  tracking: { [key: number]:  Segment} = {};
  duration = 0; // seconds
  played$  = new Subject<number>();
  currentTime$ = new Subject<number>();

  reset(duration: number, initialSegments?:Segment[]) {
    this.tracking = {};
    this.currentTime$.next(undefined);
    this.duration = duration && duration>0 ? Math.floor(duration) : 0;
    if (initialSegments && initialSegments.length>0) {
      initialSegments.forEach(segment => {
        //console.log("VideoTracker.reset segment",segment);
        if (isValidNumber(segment.start) &&
            isValidNumber(segment.end) &&
            segment.start>=0 &&
            segment.start<=segment.end) {
          this.duration = Math.max(this.duration,Math.floor(segment.end));
          if (!this.tracking[segment.start] &&
              !this.tracking[segment.end]) {
            let segmentToString = this.segmentToString;
            let newSegment = <Segment>{
              start: segment.start,
              end: segment.end,
              toString: function() {
                return segmentToString(this);
              }
            };
            for (let i=segment.start; i<=segment.end; i++) {
              this.tracking[i] = newSegment;
            }
            let prevSegment = this.tracking[segment.start-1];
            let nextSegment = this.tracking[segment.end+1];
            if (prevSegment) {
              newSegment.start = prevSegment.start;
              for (let i=prevSegment.start; i<segment.start; i++) {
                this.tracking[i] = newSegment;
              }
            }
            if (nextSegment) {
              newSegment.end = nextSegment.end;
              for (let i=segment.end+1; i<=nextSegment.end; i++) {
                this.tracking[i] = newSegment;
              }
            }
          }
        }
      });
    }
    //console.log("VideoTracker.reset duration",duration,"initialSegments",initialSegments,"toString()",this.toString());
    this.played$.next(this.total());
  }

  parseSegments(s:string, duration:number):Segment[] {
    if (s && s.length) {
      try {
        let segments:{
          start?: number;
          end?: number;
        }[] = JSON.parse(s);
        if (Array.isArray(segments)) {
          let segmentToString = this.segmentToString;
          return segments.filter(segment => {
            return isValidNumber(segment.start) &&
                   isValidNumber(segment.end) &&
                   segment.start<=segment.end &&
                   segment.end<=duration;
          }).map(segment => <Segment>{
            start: segment.start,
            end: segment.end,
            toString: function() {
              return segmentToString(this);
            }
          });
        }
      } catch(e) {
      }
    }
    return [];
  }

  clone(segments:Segment[]): Segment[] {
    if (segments && Array.isArray(segments)) {
      let segmentToString = this.segmentToString;
      return segments.map(segment => <Segment>{
        start: segment.start,
        end: segment.end,
        toString: function() {
          return segmentToString(this);
        }
      });
    }
    return [];
  }

  track(second: number) {
    let curr = this.tracking[second];
    //console.debug("CURR "+curr+" second:"+second+" total:"+this.total());
    if (curr === undefined) {
      if (second==1) {
        this.track(0);
      }
      let prev = this.tracking[second-1];
      let next = this.tracking[second+1];
      let segmentToString = this.segmentToString;
      //console.debug("PREV "+prev+" NEXT "+next);
      if (prev === undefined && next === undefined) {
        let segment = {
          start: second,
          end: second,
          to2Digit: function(value: number) {
            return value>=10 ? value.toString() : '0'+value;
          },
          toHourString: function(seconds: number) {
            let s = seconds % 60;
            let v = (seconds-s)/60;
            let m = v % 60;
            let h = (v-m)/60;
            if (h>0) {
              return h+':'+this.to2Digit(m)+':'+this.to2Digit(s);
            } else {
              return m+':'+this.to2Digit(s);
            }
          },
          toString: function() {
            return segmentToString(this);
          }
        };
        this.tracking[second] = segment;
      } else if (prev === undefined) {
        this.tracking[second] = next;
        next.start = second;
      } else if (next === undefined) {
        this.tracking[second] = prev;
        prev.end = second;
      } else {    // both defined -> merge
        next.start = prev.start;
        for (let i=prev.start; i<=second; i++) {
          this.tracking[i] = next;
        }
      }
      this.played$.next(this.total());
    }
    this.currentTime$.next(second);
    //console.debug("SECONDS "+this.total());
  }

  segmentToString(segment:Segment):string {
    return '{"start":'+segment.start+',"end":'+segment.end+"}";
  }

  ordered(): Segment[] {
    let compressed = {};
    let array = [];
    for (let key in this.tracking) {
      let value = this.tracking[key];
      //console.debug("key:"+key+" value:"+value);
      compressed[value.start] = value;
    }
    //console.debug("SECONDS "+this.total()+" SEGMENTS "+Object.keys(compressed).length);
    for (let key in compressed) {
      array.push(compressed[key]);
    }
    return array.sort(function(a,b) { return a.start-b.start });
  }

  segments(): Segment[] {
    return this.ordered();
  }

  total(): number {
    return Object.keys(this.tracking).length;
  }

  toString(): string {
    return "["+this.ordered().toString()+"]";
  }
}
