<div class="average">
  <!--
    Using *ngLet="averageRating.toFixed(1) as rating" causes RECREATION
    of rating-stars component on every rating change which makes leads to flickering issues!
    For this reason instead of *ngLet we use ng-container which provides the rating value as part of the template context
  -->
  <ng-container  [ngTemplateOutlet]="ratingTemplete"
                 [ngTemplateOutletContext]="{ rating: averageRating.toFixed(1) }">
  </ng-container>
  <ng-template #ratingTemplete let-rating="rating">
    <div class="rating" aria-label="rating">{{rating}}</div>
    <div class="stars">
      <rating-stars [rating]="averageRating" [starCount]="maxRating"></rating-stars>
    </div>
    <span class="total">
      <mat-icon>person</mat-icon>
      <span class="ratings">{{totalRatingCount | number}}</span>
      {{'rating.total' | translate }}
    </span>
  </ng-template>
</div>
<div class="segments">
  <ng-container *ngFor="let segment of segments; let index = index">
    <div [class]="'rating-'+segment">
      <span>{{segment}}</span>
      <span [@segmentAnimation]="{
              value : segmentAnimationStates[index],
              params: {
                from: segmentRatio(ratingSegmentsFrom[segment], maxSegmentFrom)+'%',
                to:   segmentRatio(ratingSegments[segment], maxSegment)+'%',
                time: segmentAnimationTime(segment, index)
              }
            }"
            (@segmentAnimation.done)="onAnimationDone(segment, index, $event)"
            [@.disabled]="segmentAnimationDisabled">
      </span>
      <!--
      <span [style.width]="(maxSegment==0 ? 0 : 100*ratingSegments[segment]/maxSegment)+'%'"></span>
      -->
    </div>
  </ng-container>
</div>
