import {Inject, Injectable} from '@angular/core';
import {map, mapTo, merge, Observable, shareReplay, startWith, Subject} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {Logger} from "../logger.service";
import {getPlatforms, isBrowser, isPlatform, Platforms} from "./utils";

export type AppGround = 'foreground' | 'background';

@Injectable({
  providedIn: 'root'
})
export class Platform {

  window: any;

  protected _pause$  = new Subject<void>();
  protected _resume$ = new Subject<void>();
  protected _appGround$: Observable<AppGround>;
  protected _appGround: AppGround;

  private readonly readyPromise: Promise<string>;

  logger = new Logger('Platform');

  constructor(@Inject(DOCUMENT) private document: any) {
    this._appGround$ = merge(
      this._pause$.pipe(mapTo('background')),
      this._resume$.pipe(startWith(void 0), mapTo('foreground'))
    ).pipe(
      map((ground: AppGround) => { this._appGround = ground; return ground; }),
      shareReplay(1)
    );

    this._appGround$.subscribe(appGround => this.logger.debug("app ground", appGround));

    this.window = document.defaultView;
    proxyEvent(this._pause$,  this.document, 'pause');
    proxyEvent(this._resume$, this.document, 'resume');

    let readyResolve: (value: string) => void;
    this.readyPromise = new Promise(res => { readyResolve = res; });
    if (this.window && this.window['cordova']) {
      /*
       * Form cordova docs:
       *
       * "The deviceready event behaves somewhat differently from others.
       * Any event handler registered after the deviceready event fires
       * has its callback function called immediately."
       *
       * see https://cordova.apache.org/docs/en/4.0.0/cordova/events/events.deviceready.html
       */
      document.addEventListener('deviceready', () => {
        readyResolve('cordova');
      }, { once: true });
    } else {
      readyResolve!('dom');
    }
  }

  get pause$(): Observable<void> {
    return this._pause$.asObservable();
  }

  get resume$(): Observable<void> {
    return this._resume$.asObservable();
  }

  get appGround$(): Observable<AppGround> {
    return this._appGround$;
  }

  get isAppForeground(): boolean {
    return this._appGround == 'foreground';
  }

  get isAppBackground(): boolean {
    return this._appGround == 'background';
  }

  /**
   * @returns returns true/false based on platform.
   * @description
   * Depending on the platform the user is on, `is(platformName)` will
   * return `true` or `false`. Note that the same app can return `true`
   * for more than one platform name. For example, an app running from
   * an iPad would return `true` for the platform names: `mobile`,
   * `ios`, `ipad`, and `tablet`. Additionally, if the app was running
   * from Cordova then `cordova` would be true, and if it was running
   * from a web browser on the iPad then `mobileweb` would be `true`.
   *
   * ```
   * import { Platform } from 'ionic-angular';
   *
   * @Component({...})
   * export MyPage {
   *   constructor(public platform: Platform) {
   *     if (this.platform.is('ios')) {
   *       // This will only print when on iOS
   *       console.log('I am an iOS device!');
   *     }
   *   }
   * }
   * ```
   *
   * | Platform Name   | Description                        |
   * |-----------------|------------------------------------|
   * | android         | on a device running Android.       |
   * | cordova         | on a device running Cordova.       |
   * | ios             | on a device running iOS.           |
   * | ipad            | on an iPad device.                 |
   * | iphone          | on an iPhone device.               |
   * | phablet         | on a phablet device.               |
   * | tablet          | on a tablet device.                |
   * | phone           | on a phone device.                 |
   * | electron        | in Electron on a desktop device.   |
   * | pwa             | as a PWA app.   |
   * | mobile          | on a mobile device.                |
   * | desktop         | on a desktop device.               |
   * | hybrid          | is a cordova or capacitor app.     |
   * | macos           | on a device running MacOS.         |
   *
   */
  is(platformName: Platforms): boolean {
    return isPlatform(this.window, platformName);
  }

  /**
   * @returns the array of platforms
   * @description
   * Depending on what device you are on, `platforms` can return multiple values.
   * Each possible value is a hierarchy of platforms. For example, on an iPhone,
   * it would return `mobile`, `ios`, and `iphone`.
   *
   * ```
   * import { Platform } from 'ionic-angular';
   *
   * @Component({...})
   * export MyPage {
   *   constructor(public platform: Platform) {
   *     // This will print an array of the current platforms
   *     console.log(this.platform.platforms());
   *   }
   * }
   * ```
   */
  platforms(): string[] {
    return getPlatforms(this.window);
  }

  isSafari(): boolean {
    return isBrowser(this.window, 'safari');
  }

  ready(): Promise<string> {
    return this.readyPromise;
  }

  get isRTL(): boolean {
    return document.dir === 'rtl';
  }

  isLandscape(): boolean {
    return !this.isPortrait();
  }

  isPortrait(): boolean {
    return this.window.matchMedia('(orientation: portrait)').matches;
  }

  testUserAgent(expression: string): boolean {
    return navigator.userAgent.indexOf(expression) >= 0;
  }

  url() {
    return this.window.location.href;
  }

  width() {
    return this.window.innerWidth;
  }

  height(): number {
    return this.window.innerHeight;
  }
}

const proxyEvent = <T>(emitter: Subject<T>, target: EventTarget, eventName: string) => {
  if (target) {
    target.addEventListener(eventName, (event: Event | undefined | null) => {
      emitter.next(event != null ? (event as any).detail as T : undefined);
    });
  }
};
