import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {NULL_USER, User} from "core";
import {
  AuthenticationActionIds,
  AuthenticationGuestLoginAction,
  AuthenticationLoginAction,
  authenticationLoginFailure,
  AuthenticationLoginSuccessAction,
  authenticationLogout,
  authenticationLogoutFailure,
  authenticationLogoutSuccess,
  AuthenticationPasscodeLoginAction,
  signContract,
  signContractFailure,
  signContractSuccess
} from "./actions";

@Injectable()
export class AuthenticationEffects {

  login = createEffect(() =>
    this.actions$.pipe(
      ofType<AuthenticationLoginAction>(AuthenticationActionIds.LOGIN),
      mergeMap((action) => {
        return this.http.post('/v1.0/auth/login', action.credentials).pipe(
          map((result:any) => {
            console.log('LOGIN SUCCESS',result);
            const status = result['status'];
            if (status == 'expired') {
              throw 'invitationCodeExpired'; //status;
            } else {
              action.onSuccess({ status });
              return new AuthenticationLoginSuccessAction(
                action.credentials,
                result['user'] && new User(result['user']) || this.nullUser,
                false,
                result['signed']);
              // return authenticationLoginSuccess({
              //   credentials: action.credentials,
              //   user: result['user'] && new User(result['user']) || this.nullUser,
              //   autoLogin: false,
              //   signed: result['signed']
              // });
            }
          }),
          catchError(reason => {
            console.error('LOGIN ERROR', reason);
            action.onFailure({reason});
            return of(authenticationLoginFailure({
              credentials: action.credentials,
                   reason: {
                        type: action instanceof AuthenticationGuestLoginAction    ? 'guest'
                            : action instanceof AuthenticationPasscodeLoginAction ? 'passcode' : 'member',
                      reason: reason
                    }
            }));
          })
        );
      })
    )
  );

  logout = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticationLogout),
      mergeMap(action => {
        return this.http.post('/v1.0/auth/logout',{}).pipe(
          map(result => {
            action.onSuccess({status:result['status']});
            return authenticationLogoutSuccess();
          }),
          catchError(reason => {
            action.onFailure({reason});
            return of(authenticationLogoutFailure({ reason }))
          })
        );
      }),
    )
  );

  sign = createEffect(() =>
    this.actions$.pipe(
      ofType(signContract),
      mergeMap(action => {
        return this.http.post('/v1.0/contacts/sign',{}).pipe(
          map(result => {
            action.callback?.(true);
            return signContractSuccess();
          }),
          catchError(reason => {
            action.callback?.(false);
            return of(signContractFailure({ reason }));
          })
        )
      })
    )
  );

  /*
  @Effect()
  login: Observable<Action> = this.actions$.pipe(
    ofType<AuthenticationLoginAction>(AuthenticationActionIds.LOGIN),
    mergeMap(action => {
      return this.http.post('/v1.0/auth/login', action.credentials).pipe(
        map(result => {
          //console.log('LOGIN SUCCESS',result);
          return new AuthenticationLoginSuccessAction(
            action.credentials,
            result['user'] && new User(result['user']) || this.nullUser,
            false,
            result['signed']);
        }),
        catchError(reason => {
          console.error('LOGIN ERROR', reason);
          return of(new AuthenticationLoginFailureAction(action.credentials,{
            type: action instanceof AuthenticationGuestLoginAction ? 'guest'
                : action instanceof AuthenticationPasscodeLoginAction ? 'passcode'
                : 'partner',
            reason: reason
          }));
        })
      );
    }),
  );

  @Effect()
  logout: Observable<Action> = this.actions$.pipe(
    ofType<AuthenticationLogoutAction>(AuthenticationActionIds.LOGOUT),
    mergeMap(action => {
      return this.http.post('/v1.0/auth/logout',{}).pipe(
        map(result => new AuthenticationLogoutSuccessAction()),
        catchError(reason => of(new AuthenticationLogoutFailureAction(reason)))
      );
    }),
  );

  // @Effect()
  // passcodeRequest: Observable<Action> = this.actions$.pipe(
  //   ofType<PasscodeRequestAction>(AuthenticationActionIds.PASSCODE_REQUEST),
  //   mergeMap(action => {
  //     return this.http.post('/v1.0/auth/password/code',{"partnerId": action.partnerId}).pipe(
  //       map(result => new PasscodeRequestSuccessAction()),
  //       catchError(reason => of(new PasscodeRequestFailureAction(reason)))
  //     );
  //   }),
  // );

  @Effect()
  sign: Observable<Action> = this.actions$.pipe(
    ofType<SignContractAction>(AuthenticationActionIds.SIGN_CONTRACT),
    mergeMap(action => {
      return this.http.post('/v1.0/contacts/sign',{}).pipe(
        map(result => new SignContractSuccessAction()),
        catchError(reason => of(new SignContractFailureAction(reason)))
      );
    }),
  );
  */

  constructor(
    private http: HttpClient,
    private actions$: Actions,
    @Inject(NULL_USER) protected nullUser: User) {}
}
