import {
    ChangeDetectorRef,
    Component,
    EventEmitter, HostBinding,
    Inject,
    Input,
    Output,
    QueryList,
    signal,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {Action, ENVIRONMENT, Logger} from "core";
import {ImageLink, Media, MediaAction, MediaReview, MediaType} from "../../../../store/models";
import {MediaViewer} from "../../media-viewer";
import {MediaViewerContainer} from "../../media-viewer-container";
import {MediaViewerComponent} from "../media-viewer/media-viewer.component";
import SwiperCore, {Keyboard, Navigation, Virtual} from "swiper";
import {NavigationOptions, Swiper, SwiperEvents} from "swiper/types";
import {SwiperComponent} from "swiper/angular";
import {
  distinctUntilChanged,
  filter,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from "rxjs/operators";
import {concatMap, EMPTY, from, Observable, of, Subject, Subscription, tap} from "rxjs";
import {animate, style, transition, trigger} from "@angular/animations";
import {MatMenuTrigger} from "@angular/material/menu";
import {MediaReviewComponent} from "../../../media-review/media-review.component";
import isEqual from 'lodash/isEqual';
import {cloneDeep, isObject, transform} from "lodash";

SwiperCore.use([Virtual, Navigation, Keyboard]);

declare type AnimationTiming = { enterTime: string, leaveTime: string };
declare type AnimationType = 'fadeInOut' | 'slideInOut';

@Component({
  selector: 'media-slider',
  templateUrl: './media-slider.component.html',
  styleUrls: ['./media-slider.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ maxHeight: 0, opacity: 0 }),
        animate('{{ enterTime }}', style({ maxHeight: '3em', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ maxHeight: '3em', opacity: 1 }),
        animate('{{ leaveTime }}', style({ maxHeight:0, opacity: 0 }))
      ])
    ]),
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('{{ enterTime }}', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('{{ leaveTime }}', style({ opacity: 0 }))
      ])
    ])
  ]
  // encapsulation: ViewEncapsulation.None
})
export class MediaSliderComponent extends MediaViewer implements MediaViewerContainer {

  protected _size: number;
  get size(): number {
    return this._size;
  };

  @Input() set size(size: number) {
    this.logger.debug('Size', size);
    if (this._size != size) {
      this._size = size;
      this._entities = Array.from({ length: size }, () => undefined)
    }
  }

  protected _entities: Media[];
  get entities(): Media[] {
    return this._entities;
  }
  @Input() set entities(entities: Media[]) {
    this.logger.debug('ENTITIES', entities);
    this._entities = entities;
  }

  @Input() mediaStream: (mediaId: string) => Observable<Media>;

  @Input()  index     = 0;
  @Input()  autoplay  = false;
  @Output() changed   = new EventEmitter<{media: Media, index?: number}>();
  @Output() completed = new EventEmitter<{media: Media, index?: number}>();
  @Output() action    = new EventEmitter<{media: Media, index?: number, action: MediaAction, data?: any}>();
  @Output() closed    = new EventEmitter<void>();

  @ViewChild(SwiperComponent, { static: true }) swiper: SwiperComponent;
  // @ViewChildren(MediaViewerComponent) viewers: QueryList<MediaViewerComponent>

  @ViewChild('mediaReviewMenuTrigger', { read: MatMenuTrigger }) mediaReviewMenuTrigger: MatMenuTrigger;
  @ViewChild('mediaReview', { read: MediaReviewComponent }) mediaReview: MediaReviewComponent;
  // @ViewChild('mediaReviewSaveButton', { read: MatFabButton }) mediaReviewSaveButton: MatFabButton;

  hasToolbar = signal(true);
  mediaActions$ = new Subject<MediaAction[]>();
  navigationActions: Action[] = [{ id: 'previous', icon: 'arrow_back' }, { id: 'next', icon: 'arrow_forward' }];
  mediaReviewDirty = signal<boolean>(false);

  protected static animationTimingDefinitions: { [animationType in AnimationType]: AnimationTiming }
  protected static disabledAnimationTiming = {enterTime: '0ms', leaveTime: '0ms'};
  protected static defaultAnimationTimings: { [animationType: string]: AnimationTiming };
  protected static playerAnimationTimings: { [animationType: string]: AnimationTiming };
  protected static initialized = false;
  static initialize () {
    if (!this.initialized) {
      this.animationTimingDefinitions = {
        slideInOut: {
          enterTime: '300ms',
          leaveTime: '300ms'
        },
        fadeInOut: {
          enterTime: '0.5s',
          leaveTime: '1s'
        }
      }
      this.defaultAnimationTimings = {
        slideInOut: MediaSliderComponent.animationTimingDefinitions.slideInOut,
        fadeInOut: MediaSliderComponent.disabledAnimationTiming,
      }
      this.playerAnimationTimings = {
        slideInOut: MediaSliderComponent.disabledAnimationTiming,
        fadeInOut: MediaSliderComponent.animationTimingDefinitions.fadeInOut
      }
      this.initialized = true;
    }
  }

  animationTimings = MediaSliderComponent.defaultAnimationTimings;

  protected _viewer: MediaViewer;
  protected mediaSubscriptions: Subscription[] = [];
  protected mediaActionIds: { [mediaId: string]: string[] } = {};
  protected viewerMedia: Media;

  readonly ACTION_PREVIOUS = 0;
  readonly ACTION_NEXT     = 1;

  navigationOptions: NavigationOptions = {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  };

  trackByMediaAction = (index: number, mediaAction: MediaAction) => {
    // this.logger.debug('trackByMediaAction', `${index}.${mediaAction.id}`);
    // return `${index}.${mediaAction.id}`;
    return `${mediaAction.id}`;
  }

  protected logger = new Logger('MediaSliderComponent').setSilent(false);

  constructor(protected changeDetector: ChangeDetectorRef,
              @Inject(ENVIRONMENT) protected environment: any) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    // if (!this.entities?.length) {
    //   Promise
    //     .all(Array.from({ length: 30 }).map((element, index) => this.createMedia(index)))
    //     .then(result => this.entities = result);
    // }
    this.swiper.initialSlide = this.index;
    this.mediaActions$.pipe(takeUntil(this.onDestroy$)).subscribe((actions) => {
      this.logger.debug('MEDIA ACTIONS', actions);
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.logger.debug('ngAfterViewInit');
    // this.viewers.changes.pipe(
    //   takeUntil(this.onDestroy$),
    //   filter(changes => changes?.length),
    //   take(1)
    // ).subscribe(changes => {
    //   this.logger.debug('ngAfterViewInit -> VIEWERS CHANGES', { changes, activeIndex: this.swiper?.swiperRef?.activeIndex });
    //   this.update(this.index); // trigger once after init and then once the active index is changed.
    // });
    // this.update(); // too early viewers are not available yet
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.mediaSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  onClose() {
    this.closed.emit();
  }

  get viewer(): MediaViewer {
    return this._viewer;
  }

  protected update(index: number, previous?: number) {
    this.logger.debug('update -> START', { index });
    const removeIndex = previous && index + (previous > index ? 1 : -1);
    removeIndex>=0 && this.mediaSubscriptions[removeIndex]?.unsubscribe();
    const previousViewer = this.viewer;
    const initializeViewer: (idx: number) => MediaViewer =  (idx: number) => {
      const count = this.entities?.length;
      if (idx >= 0 && idx < count) {
        const viewer = undefined;//idx < this.viewers.length ? this.viewers.get(idx) : null;
        if (viewer) {
          const media = this.entities[idx];
          const media$ = (this.mediaStream?.(media.id) || EMPTY)
            .pipe(
                tap(media => this.logger.debug('MEDIA STREAM.0', media)),
                startWith(media),
                distinctUntilChanged((m1, m2) => {
                  // const diff = function difference(object, base) {
                  //   function changes(object, base) {
                  //     return transform(object, function(result, value, key) {
                  //       if (!isEqual(value, base[key])) {
                  //         result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
                  //       }
                  //     });
                  //   }
                  //   return changes(object, base);
                  // }
                  // console.log('MEDIA STREAM.DIFF', m1, m2, diff(m1, m2));
                  return isEqual(m1?.review, m2?.review); // TODO: task updates generated by subscriptions do not fill target survey media selectedTags
                }),
                concatMap((media) => {
                  this.logger.debug('MEDIA STREAM.1', media);
                  // set viewer media imperatively instead of in the template (declaratively) because it is an async operation.
                  // The returned media will be enriched with default actions, etc.
                  // We need to update the internal state only after this media has been delivered by the viewer.
                  return from(viewer.setMedia(media)
                                    .then((media) => {
                                      viewer.index = idx;
                                      this.logger.debug('MEDIA STREAM.2 [VIEWER MEDIA SET]', {index, media});
                                      return media;
                                    })).pipe(withLatestFrom(of(media)));
                }),
                tap(media => this.logger.debug('MEDIA STREAM.3', media)),
                takeUntil(this.onDestroy$),
                shareReplay(1)
            );
          this.mediaSubscriptions[idx] = index != idx
              ? media$.subscribe()
              : media$.subscribe(([viewerMedia, media]) => {
                  this._viewer = viewer;
                  this.index = index;
                  this.logger.debug('MEDIA STREAM.4 -> VIEWER CHANGED', { index });
                  this.media = media;
                  this.viewerMedia = null; //viewerMedia;
                  this.mediaReviewDirty.set(false);
                  if (viewer) {
                    if ((!previousViewer || previousViewer.isMediaPlayer()) && !viewer.isMediaPlayer()) {
                      this.animationTimings = MediaSliderComponent.defaultAnimationTimings;
                    } else if ((!previousViewer || !previousViewer.isMediaPlayer()) && viewer.isMediaPlayer()) {
                      this.animationTimings = MediaSliderComponent.playerAnimationTimings;
                    }
                    this.viewerMedia && this.updateMediaActions();
                    viewer.isMediaPlayer() && viewer.play();
                    // NOTE: imperative control over current slide index as the direct swiper property binding leads to unexpected results
                    // (i.e. initial slide is always the first one - index=0)
                    this.swiper.swiperRef.slideTo(index);
                  }
                  this.changed.emit({ media, index });
                });
          this.mediaSubscriptions[idx].add(() => {
            this.mediaSubscriptions.splice(idx, 1);
          });
          return viewer;
        }
      }
      return undefined;
    };
    // queue a task to pause the previous player if it is still playing
    // We avoid doing this immediately because when the user moves to another slide
    // using swipe gesture the videojs player will switch its playing state.
    // The reason for this is that the player by design registers a click event listener which implements the aforementioned state switch.
    // Swipe gesture obviously generates a click event which ultimately triggers the described behaviour.
    // However, the player receives the click event after this update() method is already executed. For this reason we need to postpone the pausing code below.
    // When the slides are switched using the navigation controls the player does not interferes as it does not receive any event.
    window.setTimeout(() => {
      if (previousViewer?.isMediaPlayer() && previousViewer.playing) {
        previousViewer.pause();
      }
    });
    initializeViewer(index);
    initializeViewer(index-1);
    initializeViewer(index+1);
  }

  protected updateMediaActions() {
    this.logger.debug("updateMediaActions", {
      viewerMedia: this.viewerMedia,
      viewerMediaActions: this.viewerMedia?.actions,
      actionIds: this.mediaActionIds[this.media.id]
    });
    this.mediaActions$.next(
      this.viewerMedia?.actions.filter(action =>
        this.mediaActionIds[this.media.id]?.includes(action.id)
      )
    );
    // required because when the event which triggers updateMediaActions invocation
    // is sourced from the pdf viewer (i.e. an iframe which is outside angular context)
    // the angular change detection cycle is not executed.
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }


  onAfterInit(event: SwiperEvents['afterInit']) {
    this.logger.debug('onAfterInit', event);
  }

  onRealIndexChange([swiper]: [swiper: Swiper]) {
    const index  = swiper.realIndex;
    this.logger.debug('onRealIndexChange', index);
    this.update(index);

  }

  onActiveIndexChange([swiper]: [swiper: Swiper]) {
    this.logger.debug('onActiveIndexChange', swiper);
  }

  onSlideChange([swiper]: [swiper: Swiper]) {
    this.logger.debug('onSlideChange', swiper);
  }

  onSlideChangeTransitionStart([swiper]: [swiper: Swiper]) {
    this.logger.debug('onSlideChangeTransitionStart', swiper);
  }

  onSlideChangeTransitionEnd([swiper]: [swiper: Swiper]) {
    this.logger.debug('onSlideChangeTransitionEnd', swiper);
  }

  onSlideNextTransitionStart([swiper]: [swiper: Swiper]) {
    this.logger.debug('onSlideNextTransitionStart', swiper);
  }

  onSlideNextTransitionEnd([swiper]: [swiper: Swiper]) {
    this.logger.debug('onSlideNextTransitionEnd', swiper);
  }

  onSlidePrevTransitionStart([swiper]: [swiper: Swiper]) {
    this.logger.debug('onSlidePrevTransitionStart', swiper);
  }

  onSlidePrevTransitionEnd([swiper]: [swiper: Swiper]) {
    this.logger.debug('onSlidePrevTransitionEnd', swiper);
  }

  onTransitionStart([swiper]: [swiper: Swiper]) {
    this.logger.debug('onTransitionStart', swiper);
  }

  onTransitionEnd([swiper]: [swiper: Swiper]) {
    this.logger.debug('onTransitionEnd', swiper);
  }

  onTouchStart([swiper, event]: [swiper: Swiper, event: PointerEvent | MouseEvent | TouchEvent]) {
    this.logger.debug('onTouchStart', event);
  }

  onTouchMove([swiper, event]: [swiper: Swiper, event: PointerEvent | MouseEvent | TouchEvent]) {
    // this.logger.debug('onTouchMove', event);
  }

  onTouchEnd([swiper, event]: [swiper: Swiper, event: PointerEvent | MouseEvent | TouchEvent]) {
    this.logger.debug('onTouchEnd', event);
  }

  onMediaActionIds(event: { media: Media, index?: number, actionIds: string[] }) {
    this.logger.debug('onMediaActionIds', event);
    const {media, actionIds} = {...event};
    if (media?.id) {
      this.mediaActionIds[media.id] = actionIds;
      media.id==this.media?.id && this.updateMediaActions();
    }
  }

  onMediaAction(action: Action) {
    this.logger.debug('onMediaAction', action);
    if (!this.navigationActions.includes(action)) {
      if (action.id=='review') {
        this.mediaReviewMenuTrigger.openMenu();
      } else {
        this.action.emit({ media: this.media, index: this.index, action });
      }
    } else {
      const swiper = this.swiper.swiperRef;
      if (action == this.navigationActions[this.ACTION_PREVIOUS] && swiper.allowSlidePrev) {
        swiper.slidePrev();
      } else if (action == this.navigationActions[this.ACTION_NEXT] && swiper.allowSlideNext) {
        swiper.slideNext();
      }
    }
  }

  onMediaViewerAction(action: MediaAction) {
    this.onMediaAction(action);
  }

  onMediaChanged(event: { media: Media; index?: number }) {
    this.logger.debug('onMediaChanged', event);
    this.changed.emit(event);
  }

  onMediaReviewUpdate(action: MediaAction, mediaReview: MediaReview) {
    this.logger.debug('onMediaReviewUpdate', { media: this.media, index: this.index, action, data: mediaReview })
    const { media, index} = {...this};
    this.action.emit({ media, index, action, data: mediaReview });
    this.mediaReviewMenuTrigger.closeMenu();
  }

  onMediaReviewClose() {
    this.mediaReview.value = this.media.review; // reset
    this.mediaReviewMenuTrigger.closeMenu();
  }

  onMediaReviewChange(mediaReview: MediaReview) {
    const currentMediaReview = this.media.review;
    const mediaReviewDirty = currentMediaReview?.state != mediaReview?.state
      || currentMediaReview?.reason !== currentMediaReview?.reason
      && !!currentMediaReview.reason && !!mediaReview.reason;
    this.mediaReviewDirty.set(mediaReviewDirty);
  }
  protected createMedia(index: number): Promise<Media> {
    const image = new Image();
    const imageUrl = `/assets/images/logo-light.png`;
    image.src = imageUrl;
    return new Promise<Media>((resolve, reject) => {
      image.onload = () => {
        const link:ImageLink = {
          type: 'image',
          link: imageUrl,
          contentType: 'image/png',
          width: image.width,
          height: image.height,
          size:0  // unknown
        };
        resolve ({
          type  : 'media',
          links : [ link ],
          cover : link,
          ready : true,
          valid : true,
          mediaType: MediaType.image,
          properties: { cover: link },
          rootPath: ''
        } as Media);
      };
      image.onerror = (error) => reject(error);
    });
  }

  onMediaViewerPointerDown(event: PointerEvent) {
    this.logger.debug('onMediaViewerPointerDown', {event, target: event.target});
    if (this.viewer.isMediaPlayer() && this.viewer.isControlEvent(event)) {
      event.stopPropagation();
    }
  }

  imageUrl(image: string): string {
    return image?.startsWith('/') ? `${this.environment.serverUrl}${image}` : image;
  }

  onToolbarDisplay = (display: boolean) => {
    this.logger.debug('onToolbar', display);
    this.hasToolbar.set(display);
  }
}

MediaSliderComponent.initialize();
