import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { BasicContainerComponent } from '../basic-container/basic-container.component';
import { Icon, IconService } from './icon.service';

@Component({
  selector: 'app-icon-list',
  templateUrl: './icon-list.component.html',
  styleUrls: ['./icon-list.component.scss']
})
export class IconListComponent extends BasicContainerComponent {

  icons: Icon[];

  constructor(
    private iconService: IconService
  ) {
    super();
  }

  ngOnInit(): void {
    this.iconService.getIcons().pipe(takeUntil(this.onDestroy$))
      .subscribe(icons => {
        this.icons = icons;
        // this.updateContext();
      });

  }

}
