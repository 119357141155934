import {InjectionToken, TemplateRef} from "@angular/core";
import {Action, HasTags, secondsToTime, Topic, VersionedId} from "core";
import padStart from "lodash/padStart";
import {Message} from "messaging";
import {Reaction} from "shared";

export enum MediaType {
  audio  = 'audio',
  video  = 'video',
  image  = 'image',
  iap    = 'iap',
  binary = 'binary',
  survey = 'survey'
}

export interface MediaLink {
  readonly type : string;
  readonly contentType : string;
  readonly size : number;
  readonly link : string;
}


export interface ImageLink extends MediaLink {
  readonly width : number;
  readonly height : number;
}

export interface AudioLink extends MediaLink {
  readonly duration : number;
  readonly bitrate : number;
  readonly quality : string;
}

export interface VideoLink extends ImageLink, AudioLink {
}

// SurveyLink should extend MediaLink directly
// Currently it extends ImageLink & AudioLink as a workaround
// for "This expression is not callable" ts compiler issue
// see: https://github.com/microsoft/TypeScript/issues/36390
export interface SurveyLink extends ImageLink, AudioLink {
  readonly survey: Topic;
  readonly selectedTags: string[];
}

export interface Data {
  readonly _id : string;
  readonly id : string;
  readonly type : string;
  readonly version : number;
  readonly timeCreated : number | null;
  timeUpdated : number | null;
  timeDeleted : number | null;
  readonly author : { id: string };
  readonly valid?: boolean;     // no errors on transmit/convert
  readonly ready?: boolean;     // transmitted and converted
}

export enum MediaReviewState {
  Pending   = 'pending',
  Approved  = 'approved',
  Declined  = 'declined',
}

export interface MediaReview  {
  state     : MediaReviewState,
  reason   ?: string,
  contact  ?: { id: string, name:string },
  time      : number
}

export interface MediaAction extends Action {
  from?: number,
  to?: number,
  promotion?: boolean,
  primary?: boolean,
  editable?: boolean,
  template?: TemplateRef<any>;
  image?: string;
}

export interface MediaReactionSummary  {
  ratings?: {[key: number]: number};
  reactions?: {[key: string]: number};
  views?: number[];
  version?: number;
}

export type MediaAuthorSource = 'upline' | 'downline' | 'crossline' | 'self'
export interface Media extends Data, HasTags {
  size : number;
  mediaType : MediaType;
  links : ImageLink[] | AudioLink[] | VideoLink[] | SurveyLink[];
  cover : ImageLink;
  source : string;
  name: string;
  uploader: { id: string, name: string },
  author: {
    id: string,
    name: string,
    source?: MediaAuthorSource,
    root?: string     // name of the first leader in upline,
    visible: boolean, // is visible for downline (media could come from e.g. a crossline - see source)
    decision: boolean // pending decision on downline visibility
  };
  // author_name: string;
  // manual_author_name?: string;
  info: string;
  language: string;
  readonly editable: boolean;
  readonly approvable: boolean;
  published: any;
  protected: any;
  review?: MediaReview;
  actions: MediaAction[];
  timeProduced: number | null;
  timeDisplayed?: number | null; // in case a different date/time should be displayed! e.g. "invite.intro" or "invite.default" get the date when viewer was invited...
  properties: any;
  countryCodes: string[];
  countryCodesExcluded?: boolean;
  // author_root_name?: string;  // name of the first leader in upline
  // author_source: 'upline' | 'downline' | 'crossline' | 'self';
  // author_visible: boolean;
  // author_decision: boolean;
  downline_include?: boolean; // permit downline access
  readOnly?: boolean; // suppress reactions, save etc
  readOnlyReact?: boolean; // if readOnly, is reaction allowed (played, touched etc, delovered for current user)
  rating?: number;
  reactionSummary?: MediaReactionSummary;
  reaction?: Reaction,
  /*
   * user specific data
   * merged in from access tracking on delivery
   */
  touched: boolean;       // touched by user (else new)
  completed: boolean;     // completed by user
  played?: string;        // played ranges
  playedTime?: number;    // played time,
  lock?: { formula?: string, message?: string, readonly value?: boolean };
  upload?: { formula?: string, readonly value?: boolean, types?:string[] };
  duration?: number;      // media duration,
  rootPath?: string      // root path to use when resolving all relative media links - currently only respected by cover links (see MediaService.getMediaCoverSrc) but later we can also use it for source links
  viewedAsId?: string;   // contactId for the impersonated user if any otherwise same as current userId
  path?: string;         // server media path
  readonly context?: any;
}

export class NullMedia implements Media {
  _id = '0';
  id = '0';
  name = '';
  cover = null;
  info = '';
  mediaType = MediaType.binary;
  timeProduced = 0;
  links = [];
  actions = [];
  approvable = false;
  uploader = { id: '0', name: '' };
  author = {
    id: '0',
    name: '',
    source: 'self' as MediaAuthorSource,
    decision: false,
    visible: false
  }
  completed = true;
  play: { enabled: false };
  countryCodes = [];
  properties = {};
  timeCreated = null;
  timeDeleted = null;
  timeUpdated = null;
  editable = false;
  language: string;
  published = false;
  protected = false;
  size = 0;
  source = '';
  touched = false;
  type = '';
  version = 0;

  constructor(init?: Partial<Media>) {
    Object.assign(this, init);
  }
}

export const NULL_MEDIA = new InjectionToken('NullMedia');

export function getMediaDuration(media:Partial<Media>) : number {
  if (media && media.links && media.links.length) {
    for (let i=0; i<media.links.length; i++) {
      if ((<any>media.links[i]).duration) {
        return (<any>media.links[i]).duration;
      }
    }
  }
  return undefined;
}

export function getMediaDurationString(media: Partial<Media>): string {
  let duration = getMediaDuration(media);
  if (duration) {
    duration = Math.max(0, Math.round(getMediaDuration(media)));
    return secondsToTime(duration)
      .filter((value, index, time) => {
        let ok = index >= time.length - 2 || value != 0 || (index > 0 && time[index - 1] != undefined);
        if (!ok) {
          time[index] = undefined;
        }
        return ok;
      })
      .map((value, index, time) => {
        return index > 0 ? padStart(value.toString(), 2, '0') : value.toString();
      })
      .join(':');
  }
  return undefined;
}

/*
  MEDIA RELATED
*/
export interface MediaRelatedMessage extends Message {
}

export const MediaSubscriptionMessageType : string = "mediaSubscription";
export interface MediaSubscriptionMessage extends MediaRelatedMessage {
  subscribed: VersionedId[];
}

export const MediaMessageType : string = "media";
export interface MediaMessage extends MediaRelatedMessage {
  media: Media;
}

export interface MediaCacheUpdateMessage extends MediaRelatedMessage {
  cacheId: string;
}

export const MediaCacheUpsertMessageType : string = "mediaCacheUpsert";
export interface MediaCacheUpsertMessage extends MediaCacheUpdateMessage {
  media: Media;
  afterId?: string;
  beforeId?: string;
}

export const MediaCacheRemoveMessageType : string = "mediaCacheRemove";
export interface MediaCacheRemoveMessage extends MediaCacheUpdateMessage {
  media?: Pick<Media,"id">; // media.id
}

// DEPRICATED!!
export const MediaUpdateMessageType : string = "media_update";
export interface MediaUpdateMessage extends MediaMessage {
  media: Media
}
