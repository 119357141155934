import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector
} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Logger, Resource} from "core";
import {BusyComponent} from "./busy.component";

const log = new Logger('BusyService');

@Injectable({
  providedIn: 'root'
})
export class BusyService {

  private lockCounter : number = 0;
  private busyRef     : ComponentRef<BusyComponent>;
  private busyElement : HTMLElement;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private translation: TranslateService) {

    this.busyRef = this.componentFactoryResolver
      .resolveComponentFactory(BusyComponent)
      .create(this.injector);
  }

  lock(message? : string) : Resource {
    let released : boolean = false;
    let self = this;
    if (this.lockCounter++ == 0) {
      this.appRef.attachView(this.busyRef.hostView);
      this.busyElement = (this.busyRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      this.busyRef.instance.fill = true;
      this.busyRef.instance.size = 2;
      this.busyRef.instance.busy = true;
      if (message) {
        this.busyRef.instance.message = message;
      } else {
        this.translation.get('loading').toPromise().then(message => {
          log.info("translated to "+message);
          this.busyRef.instance.message = message;
        });
      }
      //this.loaderRef.instance.message = (message || this.translation.get('loading').);
      document.body.appendChild(this.busyElement);
    }
    return new class implements Resource {
      release(): void {
        if (!released && --self.lockCounter <= 0) {
          released = true;
          self.appRef.detachView(self.busyRef.hostView);
          document.body.removeChild(self.busyElement);
        }
      }
    }
  }
}
