import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {uploadFeatureKey, uploadReducer} from './store/reducers';
import {MaterialModule} from "material";
import {ImageUploadComponent} from './components/image-upload/image-upload.component';
import {UploadBottomSheetComponent} from './components/upload-bottom-sheet/upload-bottom-sheet.component';
import {TranslateModule} from "@ngx-translate/core";
import {SharedModule} from "shared";
import {UploadFilesPipe, UploadListComponent} from './components/upload-list/upload-list.component';
import {UploadListItemComponent} from './components/upload-list-item/upload-list-item.component';
import {UploadTypeListComponent} from './components/upload-type-list/upload-type-list.component';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(uploadFeatureKey, uploadReducer),
    SharedModule,
    MaterialModule,
    TranslateModule
  ],
  declarations: [
    ImageUploadComponent,
    UploadBottomSheetComponent,
    UploadListComponent,
    UploadListItemComponent,
    UploadFilesPipe,
    UploadTypeListComponent
  ],
  exports: [
    ImageUploadComponent
  ],
  providers: [
  ]/*,
  entryComponents: [
    UploadBottomSheetComponent
  ]
  */
})
export class UploadModule {
}
