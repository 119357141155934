<mat-sidenav-container #container fxFlexFill (backdropClick)="onBackdropClicked()">
  <mat-sidenav
    #navigationSidenav
    position="start"
    [opened]="(navigationState$ | async)?.open || false"
    [mode]="(navigationState$ | async)?.mode || 'over'"
    [disableClose]="true"
    (onResize)="triggerResize($event)"
    (openedChange)="onNavigationOpenStateChanged($event)"
    (openedStart)="onNavigationOpenStateChanged(true)"
    (closedStart)="onNavigationOpenStateChanged(false)"
    [@sidebarAnimation]="getAnimationData(navigationOutlet)">
    <router-outlet #navigationOutlet="outlet" name="navigation"></router-outlet>
  </mat-sidenav>
  <mat-sidenav
    #detailsSidenav
    position="end"
    [opened]="(detailsState$ | async)?.open || false"
    [mode]="(detailsState$ | async)?.mode || 'over'"
    [disableClose]="true"
    (openedChange)="onDetailsOpenStateChanged($event)"
    (openedStart)="onDetailsOpenStateChanged(true)"
    (closedStart)="onDetailsOpenStateChanged(false)"
    [@sidebarAnimation]="getAnimationData(detailsOutlet)">
    <router-outlet #detailsOutlet="outlet" name="details"></router-outlet>
  </mat-sidenav>
  <mat-sidenav-content #content [@contentAnimation]="getAnimationData(primaryOutlet)">
    <router-outlet #primaryOutlet="outlet"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
