import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { getWizardSteps } from './group-configuration.wizard-steps';
import { GroupService as GroupServiceTemp } from './group.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { filter, map, shareReplay, takeUntil, tap } from "rxjs/operators";
import { LayoutService } from "layout";
import { Logger } from "core";
import { BasePageComponent, ConfirmDialogComponent, ImageCropperOverlayService, MenuService } from "shared";
import { UploadService, UPLOAD_TYPE } from 'upload';
import { Group, PropertiesService } from 'properties';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageLink, Media, MediaAction, MediaService, MediaType, MediaViewerOverlayService } from "media";
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatTabGroup } from '@angular/material/tabs';
import { FilterService } from 'filter';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Location } from '@angular/common';
import { GroupService } from 'group/public-api';
import { Domain } from 'properties/lib/models/domain';

export interface ConfigurationGroup  extends Group, Domain {

}

@Component({
  selector: 'lib-group-configuration-page',
  templateUrl: './group-configuration-page.component.html',
  styleUrls: ['./group-configuration-page.component.scss']
})
export class GroupConfigurationPageComponent extends BasePageComponent {

  get STATE_JSON() { return "json" };
  get STATE_WIZARD() { return "wizard" };
  // get STATE_TEMPLATE() { return "template" };
  get ENTITY_GROUPS() { return ["topics", "filters", "interests"] };

  @Input() hasToolbar: boolean = true;

  state: string = this.STATE_WIZARD;
  wizardSteps = getWizardSteps();
  selectOptions = new Map<string, { "value": string, "viewValue": string }[]>();
  group?: ConfigurationGroup;
  initialGroup: ConfigurationGroup;
  templates: ConfigurationGroup[];
  // templateGroup?: Group;
  protected tracking = false;
  protected mediaFilters: string[];
  entities$ = new BehaviorSubject<Media[]>([]);
  loading$: Observable<boolean>;

  stepsCompleted: boolean = false;
  @ViewChild('logoGroup') private logoGroup?: MatTabGroup;

  darkLogo: boolean = false;

  private _defaultMedia: Promise<Media>;

  jsonForm: FormGroup;

  rightIcon = 'code';


  version = "";

  protected logger = new Logger('GroupConfigurationPageComponent');

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private location: Location,
    public translateService: TranslateService,
    public layoutService: LayoutService,
    protected menuService: MenuService,
    protected filterService: FilterService,
    protected snackBar: MatSnackBar,
    protected propertiesService: PropertiesService,
    protected uploadService: UploadService,
    protected renderer: Renderer2,
    protected imageCropperOverlayService: ImageCropperOverlayService,
    private groupServiceTemp: GroupServiceTemp,
    private groupService: GroupService,
    protected elementRef: ElementRef,
    protected mediaService: MediaService,
    protected mediaViewerOverlayService: MediaViewerOverlayService
  ) {
    super(layoutService, menuService, translateService);
    this.wizardSteps.forEach(step => {
      step.formGroup = this.formBuilder.group({});
      step.components.forEach(component => {
        step.formGroup!.addControl(component.name, component.formControl);
      });
    });

    this.jsonForm = this.formBuilder.group({
      content: ['']
    });

    const emptyGroup: ConfigurationGroup = {
      id: '',
      name: '',
      language: '',
      topics: [],
      viewAs: {
        accounts: []
      }
    }
    this.templates = [emptyGroup, ...this.groupServiceTemp.getTemplates()];
    // this.templateGroup = emptyGroup;

    // this.selectOptions.set("version", [{
    //   "value": '',
    //   "viewValue": "empty"
    // }, {
    //   "value": 'version#',
    //   "viewValue": this.translateService.instant('app.version#', { version: this.propertiesService.serverVersion })
    // }, {
    //   "value": 'app.poweredByApp#',
    //   "viewValue": this.translateService.instant('app.poweredByApp#', { version: this.propertiesService.serverVersion })
    // }, {
    //   "value": 'app.poweredByApp#2',
    //   "viewValue": this.translateService.instant('app.poweredByApp#2', { version: this.propertiesService.serverVersion })
    // }]);

    this.selectOptions.set("version", [{
      "value": '',
      "viewValue": ""
    }, {
      "value": 'version#',
      "viewValue": 'app.version#'
    }, {
      "value": 'app.poweredByApp#',
      "viewValue": 'app.poweredByApp#'
    }, {
      "value": 'app.poweredByApp#2',
      "viewValue": 'app.poweredByApp#2'
    }]);

    this.selectOptions.set("textStyle", [{
      "value": 'f',
      "viewValue": 'Formal'
    },{
      "value": 'i',
      "viewValue": 'Informal'
    }]);

    this.mediaService.filters$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(filters => {
        this.tracking = this.mediaFilters && this.mediaFilters.every(f => filters?.includes(f));
        this.logger.debug('TRACKING', this.tracking, 'filters', filters);
      });
    this.mediaService.entities$
      .pipe(takeUntil(this.onDestroy$), filter(entities => this.tracking))
      .subscribe(entities => this.entities$.next(entities));
    this.loading$ = this.mediaService.loading$.pipe(
      takeUntil(this.onDestroy$),
      tap(loading => this.logger.debug("LOADING", loading)),
      filter(loading => this.tracking)
    );

    const languages$ = this.translateService.stream('languages').pipe(shareReplay(1));
    languages$.pipe(map(result => {
      const language = this.translateService.currentLang;
      return values(mapValues(result, (value, key) => { return { value: key, viewValue: value } }))
        .sort((l1, l2) => l1.value.localeCompare(l2.value, language));
    })).subscribe(languages => {
      this.selectOptions.set("languages", languages);
      this.selectOptions.set("textLanguage", languages);
    });
  }

  ngOnInit(): void {
    combineLatest([
      this.route.params,
      this.translateService.stream('countries')
    ])
      .pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(
        ([
          params,
          countries
        ]) => {
          this.group = this.propertiesService.user.selectableGroups?.find(g => g.id == params.id);
          if (this.group) {
            if (params.id == this.propertiesService.groupId) {
              this.propertiesService.user$.pipe(takeUntil(this.onDestroy$)).subscribe(user => {
                this.group.topics = user.app.topics;
                this.group.filters = user.app.filters;
                this.group.interests = user.app.interests;
              });
            }
            if (!this.group.languages) {
              this.group.languages = [this.translateService.currentLang];
            }

            let language = this.translateService.currentLang;
            this.selectOptions.set("country", values(mapValues(countries, (value, key) => {
              return { value: key, viewValue: value };
            })).sort((a, b) => a.value.localeCompare(b.value, language)));

            this.updateWizard();
            this.stepsCompleted = true;

            this.initialGroup = cloneDeep(this.group);

            this.wizardSteps.forEach(step => step.formGroup.markAsPristine());
            // this.translateService.stream('countries')
            //   .pipe(takeUntil(this.onDestroy$))
            //   .subscribe(countries => {
            //     let language = this.translateService.currentLang;
            //     this.selectOptions.set("country", values(mapValues(countries, (value, key) => {
            //       return { value: key, viewValue: value };
            //     })).sort((a, b) => a.value.localeCompare(b.value, language)));
            //     if (this.group) {
            //       this.updateWizard();
            //       this.stepsCompleted = true;
            //     }
            //   });

          }
          else {
            this.navigateBack();
          }
        });
  }

  private updateWizard() {
    this.wizardSteps.forEach(step => {
      step.formGroup.reset();
      step.formGroup?.patchValue(this.group);
    });

    // this.wizardSteps[0].componentMap.get("language").formControl.patchValue([this.group.language]);
    this.wizardSteps[1].formGroup.patchValue(this.group.legalContact || []);
    this.wizardSteps[2].formGroup.patchValue(this.group.colors || []);
    // this.wizardSteps[2].formGroup.patchValue(this.group.properties);
    this.stepsCompleted = true;

    if (!this.group.appLogoInfos) {
      this.group.appLogoInfos = {};
    }

    if (!('light' in this.group.appLogoInfos && this.group.appLogoInfos['light']?.logoMedia)) {
      // Ensure there is always at least a light logo
      this.defaultMedia.then(logo => {
        this.group.appLogoInfos.light = { logoMedia: logo };
      });
    }

    this.darkLogo = ('dark' in this.group.appLogoInfos && this.group.appLogoInfos['dark'].logoMedia);

  }

  loadLogo(id: string) {
    if (id) {
      return this.mediaService.getMedia$(id);
    }
    return from(this.defaultMedia);
  }

  private setLogo(media: Media, dark = false) {
    const key = dark ? 'dark' : 'light';
    if (!(key in this.group.appLogoInfos)) {
      this.group.appLogoInfos[key] = {};
    }
    this.group.appLogoInfos[key].logoMedia = media;
  }

  toggleDarkLogo($event: MatSlideToggleChange) {
    this.darkLogo = $event.checked;
    if (!this.darkLogo) {
      this.logoGroup.selectedIndex = 0;
      delete this.group.appLogoInfos.dark;
    } else {
      from(this.defaultMedia).pipe(
        takeUntil(this.onDestroy$)
      ).subscribe((media) => {
        this.setLogo(media, true);
      });
    }
  }

  isLogoLight() {
    return this.logoGroup.selectedIndex === 0;
  }

  isSaveable() {
    return this.isChanged();
    // return this.wizardSteps.every(step => step.formGroup.valid && step.formGroup.dirty);
  }

  isChanged() {
    if(this.group.appVersionLabel !== this.initialGroup.appVersionLabel) {
      return true;
    }
    if(!isEqual(this.group.appLogoInfos, this.initialGroup.appLogoInfos)) {
      return true;
    }
    return this.wizardSteps.some(step => step.formGroup.dirty) || this.areEntitiesChanged();
  }

  areEntitiesChanged() {
    return this.ENTITY_GROUPS.some(entityGroup => !isEqual(this.group[entityGroup], this.initialGroup[entityGroup]));
  }

  onUploadMedia($event: MouseEvent) {
    const language = this.translateService.currentLang;
    const media = { name: this.isLogoLight() ? "logo light" : "logo dark", tags: ['group.logo'], language: language };
    const options = { uploadTypes: [UPLOAD_TYPE.VIDEO, UPLOAD_TYPE.IMAGE], media: media };
    this.mediaService
      .uploadMedia(this.elementRef, options)
      .subscribe(mediaUploadRef => {
        const { uploadId, uploadRef, complete } = mediaUploadRef;
        complete.then(mediaUploads => {
          const error = mediaUploads.find(mediaUpload => mediaUpload.error || !mediaUpload.media);
          if (error) {
            this.translateService.get('media.uploadResult.mediaError').subscribe((translatedMessage) => {
              this.snackBar.open(translatedMessage, this.translateService.instant('actions.close'), {
                duration: 2000,
                horizontalPosition: 'right',
                // verticalPosition: 'bottom'
              });
            });
          } else {
            this.setLogo(mediaUploads[0].media, !this.isLogoLight());
          }
        });
      });
  }

  getLogoLight() {
    if ('light' in this.group.appLogoInfos && this.group.appLogoInfos.light.logoMedia) {
      return this.group.appLogoInfos.light.logoMedia;
    }
    return null;
  }

  getLogoDark() {
    if ('dark' in this.group.appLogoInfos && this.group.appLogoInfos.dark.logoMedia) {
      return this.group.appLogoInfos.dark.logoMedia;
    }
    return null;
  }

  get defaultMedia(): Promise<Media> {
    if (!this._defaultMedia) {
      const image = new Image();
      const imageUrl = `/assets/images/logo-light.png`;
      image.src = imageUrl;
      this._defaultMedia = new Promise<Media>((resolve, reject) => {
        image.onload = () => {
          const link: ImageLink = {
            type: 'image',
            link: imageUrl,
            contentType: 'image/png',
            width: image.width,
            height: image.height,
            size: 0  // unknown
          };
          resolve({
            type: 'media',
            links: [link],
            cover: link,
            ready: true,
            valid: true,
            mediaType: MediaType.image,
            properties: { cover: link },
            rootPath: ''
          } as Media);
        };
        image.onerror = (error) => reject(error);
      });
    }
    return this._defaultMedia;
  }

  onMediaPlay(media: Media) {
    this.mediaViewerOverlayService.openBottomSheet({
      media: () => media,
      onAction: (action: MediaAction) => {
      },
      onComplete: (media: Media) => { }
    });
  }

  onMediaRemove(media) {
    if (media != this.defaultMedia) {
      this.defaultMedia.then(defaultMedia => {
        const key = (this.logoGroup.selectedIndex === 0) ? "light" : "dark";
        this.mediaService.deleteMedia(this.group.appLogoInfos[key].logoMedia).then(media => {
          this.group.appLogoInfos[key].logoMedia = defaultMedia;
        })
          .catch(error => {
            this.snackBar.open(this.translateService.instant('groups.error.cannotDeleteLogo'), this.translateService.instant('actions.close'), {
              duration: 2000,
              horizontalPosition: 'right',
            });
          });
      });
    }
  }

  openTermsOfUseDialog() {
    const dialogRef = this.dialog.open(TermsOfUseDialog);
  }

  openPrivacyPolicyDialog() {
    const dialogRef = this.dialog.open(PrivacyPolicyDialog);
  }

  saveGroup() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        label: "groups.confirmSave",
        icon: "none"
      },
      closeOnNavigation: true
    }).afterClosed().subscribe(result => {
      if (result) {
        this.formToGroup();
        this.groupService.save(this.group!).then(() => {
            console.log('Saved!');
            this.navigateBack();
          });
      }
    });
  }

  private formToGroup() {
    this.group.name = this.wizardSteps[0].formGroup.get("name").value;
    this.group.languages = this.wizardSteps[0].formGroup.get("languages").value;
    if (!this.group.legalContact) {
      this.group.legalContact = {};
    }
    const contactFormGroup = this.wizardSteps[1].formGroup;
    this.group.legalContact.organization = contactFormGroup.get("organization").value;
    this.group.legalContact.address = contactFormGroup.get("address").value;
    this.group.legalContact.community = contactFormGroup.get("community").value;
    this.group.legalContact.country = contactFormGroup.get("country").value;
    this.group.legalContact.duns = contactFormGroup.get("duns").value;
    this.group.legalContact.email = contactFormGroup.get("email").value;
    this.group.legalContact.link = contactFormGroup.get("link").value;
    this.group.legalContact.phone = contactFormGroup.get("phone").value;
    this.group.legalContact.vatid = contactFormGroup.get("vatid").value;
    this.group.legalContact.zip = contactFormGroup.get("zip").value;
    if (!this.group.colors) {
      this.group.colors = {
        pure: true,
        primary: "",
        primaryContrast: "",
        accent: "",
        accentContrast: "",
        entry: {
          button: "",
          buttonContrast: "",
          buttonDisabled: "",
          link: "",
          linkHover: ""
        }
      };
    }
    this.group.colors.accent = this.wizardSteps[2].formGroup.get("accent").value;
    this.group.colors.primary = this.wizardSteps[2].formGroup.get("primary").value;
    this.group.colors.accentContrast = this.wizardSteps[2].formGroup.get("accentContrast").value;
    this.group.colors.primaryContrast = this.wizardSteps[2].formGroup.get("primaryContrast").value;
  }

  cancel() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        label: "groups.confirmCancel",
        icon: "none"
      },
      closeOnNavigation: true
    }).afterClosed().subscribe(result => {
      if (result) {
        this.navigateBack();
      }
    });
  }

  deleteGroup() {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        label: "groups.confirmDeletion",
        icon: "none"
      },
      closeOnNavigation: true
    }).afterClosed().subscribe(result => {
      if (result) {
        // this.groupService.delete(this.group);
        this.navigateBack();
      }
    });
  }

  navigateBack() {
    this.location.back();
    // this.router.navigateByUrl("/settings/preferences");
  }

  onRightClicked() {
    if (this.state == this.STATE_WIZARD) {
      this.formToGroup();
      this.jsonForm.controls['content'].setValue(JSON.stringify(this.group, null, 4));
      this.state = this.STATE_JSON;
    } else {
      this.group = JSON.parse(this.jsonForm.controls['content'].value);
      this.updateWizard();
      this.state = this.STATE_WIZARD;
    }
  }

  onVersionChange(newVersion) {
    this.group.appVersionLabel = newVersion;
  }

}

@Component({
  selector: 'terms-of-use-dialog',
  templateUrl: 'terms-of-use-dialog.html',
})
export class TermsOfUseDialog {
}

@Component({
  selector: 'privacy-policy-dialog',
  templateUrl: 'privacy-policy-dialog.html',
})
export class PrivacyPolicyDialog {
}
