import {ChangeDetectorRef, Injectable, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {Subscription} from "rxjs";
import {CountryService} from "../services/country.service";

@Pipe({
  name: 'country',
  pure: false
})
@Injectable()
export class CountryPipe implements PipeTransform, OnDestroy {

  protected country: string;
  protected countryCode: string;
  protected onCountryChange: Subscription;

  constructor(private countryService: CountryService,
              private changeDetector: ChangeDetectorRef) {}

  transform(countryCode: string): string {
    if (countryCode != this.countryCode) {
      this.unsubscribe();
      this.onCountryChange = this.countryService.country(countryCode).subscribe(country => {
        this.countryCode = countryCode;
        this.country = country;
        window.setTimeout(() => this.changeDetector.markForCheck());
      });
    }
    return this.country;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  protected unsubscribe() {
    if (this.onCountryChange) {
      this.onCountryChange.unsubscribe();
      this.onCountryChange = undefined;
    }
  }
}
