import { FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

export enum FormComponentType {
  Select = "select",
  Input = "input",
  Text = "text",
  Tags = "tags",
  Check = "check"
}

export class FormComponent {
  name: string;
  label: string;

  type: FormComponentType;
  icon?: string;
  disabled?: boolean;
  required?: boolean;
  validators?: Validator[];
  formControl?: FormControl;
  errorText?: string;
  valueList?: string[];
  multiple?: boolean;
}

export interface Validator {
  name: string;
  value?: string;
}

export function createFormComponent(component: { name: string; label: string; type: FormComponentType; }) {
  let formComponent: FormComponent = undefined;
  switch (component.type) {
    case FormComponentType.Tags:
      formComponent = new TagFormComponent(component);
      break;
    default:
      formComponent = new GenericFormComponent(component);
      break;
  }
  return formComponent;
}

export class GenericFormComponent implements FormComponent {
  name: string;
  label: string;
  type: FormComponentType;
  icon?: string;
  disabled?: boolean;
  required?: boolean;
  validators?: Validator[];
  formControl?: FormControl;
  errorText?: string;
  multiple?: boolean;

  constructor(component: FormComponent) {
    Object.assign(this, component);
    this.formControl = new FormControl({ value: '', disabled: this.disabled });
    if (this.validators) {
      this.validators.forEach(validator => {
        this.addValidator(validator);
      });
    }
  }

  addValidator(validator: Validator) {
    switch (validator.name) {
      case "email":
        this.formControl.setValidators(this.formControl.validator ? [this.formControl.validator, Validators.email] : Validators.email);
        break;
      case "pattern":
        {
          const validatorFn = Validators.pattern(validator.value!);
          this.formControl.setValidators(this.formControl.validator ? [this.formControl.validator, validatorFn] : validatorFn);
        }
        break;
      case "max":
        {
          const validatorFn = Validators.maxLength(Number.parseInt(validator.value!));
          this.formControl.setValidators(this.formControl.validator ? [this.formControl.validator, validatorFn] : validatorFn);
        }
        break;
      case "min":
        {
          const validatorFn = Validators.minLength(Number.parseInt(validator.value!));
          this.formControl.setValidators(this.formControl.validator ? [this.formControl.validator, validatorFn] : validatorFn);
        }
        break;
      default:
        break;
    }

  }
}

export class TagFormComponent extends GenericFormComponent {

  valueList: string[] = [];

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.valueList.push(value);
    }
    event.chipInput!.clear();
    this.formControl!.setValue(null);
  }

  removeTag(tag: string): void {
    const index = this.valueList.indexOf(tag);

    if (index >= 0) {
      this.valueList.splice(index, 1);
    }
  }
}