import {InjectionToken} from "@angular/core";
import {App} from "./app";
import {Contact, NullContact} from "./contact";
import {Group} from "properties";
import {Referrer} from "./referrer";
import {GroupMembership} from "./group-membership";

export type ReferrerId = Partial<Pick<GroupMembership, 'override_referrer_id' | 'sync_referrer_id' | 'manual_referrer_id'>>;

//https://github.com/ng-packagr/ng-packagr/issues/641
// @dynamic
export class User extends Contact {
  hasLv?: boolean;              // verified (by email) for current session
  hasSl?: boolean;             // stealth login
  root?: boolean;               // is this a root user of this group
  plus?: boolean;               // role is leader+ or above (admin)
  firstlineSize?: number;
  sign?: boolean;
  // manual_referrer_id below is involved in the invitation token processing
  // it is used to fond out if the invitation comes from upline or not
  // which drives the ui behaviour (i.e. connection dialog)
  selectableGroups?: (Group & ReferrerId)[];
  invitationId: number;
  invitationReferrers: Referrer[];
  canViewDomains?: boolean;   // can view own domain settings
  canEditDomains?: boolean;   // can edit own domain settings
  canEditGroup?: boolean;     // can edit this group
  canEditLanguages?: boolean; // can edit languages for a group
  canCreateGroup?: boolean;   // can create a new group
  canUseUnapprovedInviteIntro?: boolean; // can this user alread use an unapproved into media
  editDownlineVisibility?: boolean;
  canEditChannels?: boolean; // can this user edit channels?
  canSetInviteIntro?: boolean; // can this user set the invite into flag?
  canInvite?: boolean;         // can this user invite guests?
  canConnect?: boolean;        // can this user connect to others?
  canSearch?: boolean;        // can this user search in media / contacts?
  canFilter?: boolean;        // can this user filter media / contacts?
  showCount?: boolean;        // show the number of elements in the view
  showMemberId?: boolean;     // should be shown. defaults to false
  showPartnerId?: boolean;    // for current group
  editPartnerId?: boolean;
  token?: string;               // invitation token for login (if login possible)
  login?: boolean;              // can user login with token? no password set yet!
  partnerId?: string;          // the partner id of this user in the current group (exclude avatar ending)
  suggestedMemberId?:string;   // suggested member id suggested by server
  verifiedId?: string;         // the current user's id if member_id is verifiable, else the first verified uplines user id.
  products: {[key: string]: {
    source: 'stripe' | 'google' | 'apple',
    created_time: number,
    expiry_time: number,
    cancel_time?: number
  }};

  constructor(init?: Partial<User>) {
    super();
    Object.assign(this, init);
  }

  static get NULL(): User {
    return NullUser.get();
  }

  get isAuthenticated(): boolean {
    return !!(this.id && this.name);
  }

  get isAdmin(): boolean {
    return this.isAuthenticated && this.role=='admin';
  }

  get isSL(): boolean { // stealth login
    return this.isAuthenticated && this.hasSl;
  }

  get isLeaderPlus(): boolean {
    return this.isAuthenticated && (this.plus || this.isAdmin);
  }

  get isRoot(): boolean {
    return this.isAuthenticated && this.root;
  }

  get isLeader(): boolean {
    return this.isAuthenticated && (this.role=='leader' || this.isLeaderPlus);
  }

  get isMember(): boolean {
    return this.isAuthenticated && (this.role=='user' || this.isLeader);
  }

  get isGuest(): boolean {
    return this.isAuthenticated && (this.role=='guest' || this.isMember);
  }

  get isVerifiedMember(): boolean {
    return this.isMember && this.verifiedId==this.id;
  }

  get isVerified(): boolean {
    return this.isAuthenticated && this.hasLv;
  }

  get isBeta(): boolean {
    //return !environment.production || ((this.app || <App>{}).tags || []).includes('beta');
    //return ((this.app || <App>{}).tags || []).includes('beta');
    let beta =  ((this.app || <App>{}).tags || []).includes('beta');
    // console.log("BETA",beta,"tags",((this.app || <App>{}).tags || []));
    return beta;
  }

  get isUnknown(): boolean {
    //return !this.isLeader;
    return this.isAuthenticated && ((!this.firstName && !this.lastName) || !this.phone);
  }
}

export const NULL_USER = new InjectionToken('NullUser');

// @Injectable({
//   providedIn: 'root'
// })
// class NullUser extends User {

// @dynamic
export class NullUser extends User {

  private static instance: User;

  private constructor() {
    super({...new NullContact()});
  }

  static get(): User {
    if (!NullUser.instance) {
      NullUser.instance = new NullUser();
    }
    return NullUser.instance;
  }
}
