<div class="container">
  <ng-template #host></ng-template>
</div>
<!-- from survey-page for reference-->
<!--<app-survey [survey]="survey$ | async"-->
<!--            [contactId]="(user$ | async)?.id"-->
<!--            [selectedTags]="surveyTags$ | async"-->
<!--            [badgeData]="badgeData$ | async"-->
<!--            [defaultInfo]="true"-->
<!--            (selectionChanged)="updateSurvey($event)">-->
<!--  <ng-template #actionsTemplate let-survey="survey" let-topic="topic" let-tags="tags">-->
<!--    <div *ngIf="interestsService.resolveLink(topic, tags) as link" class="actions">-->
<!--      <a [routerLink]="link" translate>actions.view</a>-->
<!--    </div>-->
<!--  </ng-template>-->
<!--</app-survey>-->
