import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output, signal,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Contact, FilterTypes, Logger, prettyDateDiff, Topic, User} from "core";
import {LayoutService} from "layout";
import {PropertiesService} from "properties";
import {BasicContainerComponent, MenuService, SearchBarComponent} from "shared";
import {MediaService} from "../../service/media.service";
import {getMediaDurationString, Media, MediaType} from "../../store/models";
import {MediaGridComponent} from "../../components/media-grid/media-grid.component";
import {MediaDetailsContainerComponent} from "../media-details-container/media-details-container.component";
import {TranslateService} from "@ngx-translate/core";
import {takeUntil} from "rxjs/operators";
import {BehaviorSubject, Observable, of} from "rxjs";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";

export declare type MediaTransformer = (media: Media) => Media;

@Component({
  selector: 'media-grid-container',
  templateUrl: './media-grid-container.component.html',
  styleUrls: ['./media-grid-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaGridContainerComponent extends BasicContainerComponent {

  @ViewChild('mediaGrid') mediaGrid: MediaGridComponent;

  entities$      = new BehaviorSubject<Media[]>([]);
  cacheId$       = new BehaviorSubject<string>(undefined);
  size$          = new BehaviorSubject<number>(0);
  selectedIndex$ = new BehaviorSubject<number>(undefined);
  searchTerm:string;
  @Input()  hasToolbar   = true;
  @Input()  hasSettings  = false;
  @Input()  hasStatusbar = false;
  @Input()  leftButton   = false;
  @Input()  leftIcon     = 'menu';
  @Input()  rightIcon    = 'more_vert';
  @Input()  hasFilters   = false;
  @Input()  title: string;
  @Input()  mediaPath: string[];
  @Input()  mediaDetailOptions: any;
  @Input()  defaultCoverHeight:number = undefined;  // default cover height in percent of width
  @Input()  forcedCoverHeight:number = undefined;   // forced cover height in percent of width
  @Input()  dragDrop = true;
  @Input() @HostBinding('class.dropOver') dropOver = false;
  @Input()  canDrop: (media: Media, target: Media) => boolean = () => true;
  @Input() author$: Observable<Contact>;
  @Output() onTitleClicked: EventEmitter<any> = new EventEmitter();
  @Output() onLeftClicked:  EventEmitter<any> = new EventEmitter();
  @Output() onRightClicked: EventEmitter<any> = new EventEmitter();
  @Output() selectedMediaChanged = new EventEmitter<{media: Media, index: number, ready?: boolean, action?: string}>();
  @Output() mediaDrop = new EventEmitter<{media: Media, target: Media}>();
  @Output() mediaDragStart = new EventEmitter<Media>();
  @Output() mediaDragEnd = new EventEmitter<Media>();
  @Output() selectedTopic = new EventEmitter<Topic>();

  @Input() mediaCovers: { [type: string]: TemplateRef<any>};
  @ViewChild('details', { static: true }) details: TemplateRef<any>;
  @Input() mediaDetailsRef: TemplateRef<any>;
  @ViewChild('actions', { static: true }) actions: TemplateRef<any>;
  @Input() mediaActionsRef: TemplateRef<any>;
  @ViewChild('extensionBar', { static: true }) extensionBar: TemplateRef<any>;
  @Input() extensionBarTemplate: TemplateRef<any>;

  selectedMedia: Media;
  user: User;
  viewed$ = signal<{
    id: string,
    name: string,
    public?: boolean
  }>(undefined);

  @ViewChild('searchBar') searchBar: SearchBarComponent;
  @ViewChild('rightButtonTemplate', {static: true}) rightButtonTemplate: TemplateRef<any>;

  protected _fullViews$ = new BehaviorSubject<string>('');
  protected _fullViews:number = undefined;
  protected _publishTime$ = new BehaviorSubject<string>('');

  protected logger = new Logger('MediaGridContainerComponent');

  constructor(
    public route: ActivatedRoute,
    public menuService: MenuService,
    public mediaService: MediaService,
    public layoutService: LayoutService,
    protected changeDetectorRef: ChangeDetectorRef,
    public propertiesService: PropertiesService,
    protected router: Router,
    protected translateService: TranslateService) {
    super();
    this.mediaService.cacheId$.pipe(takeUntil(this.onDestroy$))
        .subscribe(cacheId=>this.cacheId$.next(cacheId));
    this.mediaService.entities$.pipe(takeUntil(this.onDestroy$))
        .subscribe(entities=>this.entities$.next(entities));
    this.mediaService.size$.pipe(takeUntil(this.onDestroy$))
        .subscribe(size=>this.size$.next(size));
    this.mediaService.selectedIndex$.pipe(takeUntil(this.onDestroy$))
        .subscribe(selectedIndex=>this.selectedIndex$.next(selectedIndex));
    this.selectedIndex$.pipe(takeUntil(this.onDestroy$))
        .subscribe(selectedIndex=>{
            this.onSelectionChanged(selectedIndex);
        });
  }

  ngOnInit() {
    if (this.route.snapshot.data && this.route.snapshot.data.source) {
      // this.mediaService.setSource(this.route.snapshot.data.source);
    }
    super.ngOnInit();
    this.propertiesService.user$.pipe(takeUntil(this.onDestroy$)).subscribe((user) => this.user = user);
    this.propertiesService.viewed$.pipe(takeUntil(this.onDestroy$)).subscribe((viewed) => this.viewed$.set(viewed));

    /*
    this.data$ = this.route.snapshot.data;
    this.data$.pipe(take(1)).subscribe(data => {
      if (data && data.source) {
        console.debug("SOURCE IS SET "+data.source);
        this.mediaService.setSource(data.source);
      }
    })*/
    if (!this.title && this.route.snapshot.data) {
      this.title = this.route.snapshot.data.title;
    }
    this.mediaDetailsRef = this.details;
    this.mediaActionsRef = this.actions;
    this.extensionBarTemplate = this.extensionBarTemplate ?? this.extensionBar;
    // navigationFilters.clear()
    this.mediaService.getCombinedFilters$((type,filters)=>type==FilterTypes.NAVIGATION)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(filters=>{
        const hasFilters = filters?.length>0;
        if (this.hasFilters!=hasFilters) {
          this.hasFilters = hasFilters;
          this.changeDetectorRef.markForCheck();
        }
        console.log("SELECTION.MGCC",this.menuService.menuSelection,"FILTERS",filters,"HAS",this.hasFilters);
      });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    //console.log("XYZ.rightIcon",this.rightIcon);
    if (this.hasToolbar && this.searchBar) {
      this.searchBar.rightButtonTemplateRef = this.rightButtonTemplate;
    }
    this.mediaGrid.selectedMediaChanged.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(data => {
      this.logger.debug('selectedMediaChanged', data);
      this.selectedMedia = data.media;
      this.mediaService.select(data.index);
      switch (data.action) {
        case 'selected': {
          this.layoutService.details.getState((state) => {
            if (state.open) {
              // this.onTapDetails(data.index, data.media, null);
            } else {
              this.selectedMediaChanged.emit(data);
            }
          });
          break;
        }
        default: this.selectedMediaChanged.emit(data);
      }
    });

    // combineLatest([
    //   this.mediaService.loadedEntities$,
    //   this.mediaService.selectedIndex$
    // ]).pipe(
    //   takeUntil(this.onDestroy$)
    // ).subscribe(([entities,index]) => {
    //   console.log("SELECTED START");
    //   if (index!=this.mediaGrid.selectedIndex) {
    //     let selectedMedia = !!entities && !!index && index>=0 && index<entities.length ? entities[index] : undefined;
    //     let selectedIndex = !!selectedMedia ? index : undefined;
    //     this.mediaGrid.selectMedia(selectedIndex,selectedMedia);
    //     console.log("SELECTED",selectedIndex,selectedMedia);
    //   }
    // });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.debug('MediaGridContainerComponent.ngOnChanges()', changes);
    // if options are changed and there is a media already displayed in details window - display it with new options
    const currentOptions = get(changes, 'mediaDetailOptions.currentValue');
    const previousOptions = get(changes, 'mediaDetailOptions.previousValue');
    if (currentOptions && !isEqual(currentOptions, previousOptions)) {
      this.layoutService.details.getState((state) => {
        if (state.open &&
          get(state, 'content.type') == MediaDetailsContainerComponent &&
          this.selectedMedia) {
          window.setTimeout(() => {
            this.displayMediaDetails(this.mediaGrid.selectedIndex, this.selectedMedia, currentOptions);
          });
        }
      });
    }
  }

  trackMedia(index:number, media:Media) : string {
    //console.debug("MEDIA TRACK BY "+index+" media:"+(media?media.id:null));
    return media ? media.id+'.'+media.version+'.'+media.touched+'.'+media.completed : null;
  }

  updateSearchTerm(term : string) : void {
    //console.debug("SEARCH FOR "+term);
    this.mediaService.updateSearchTerm(term || '');
  }

  // selectMedia(index:number, media: Media, action?: string) {
  //   this.mediaGrid.selectMedia(index,media,action);
  // }

  onTapDetails(index: number, media: Media, event: Event) {
    // event.stopPropagation();
    console.debug('onTapDetails', index, media.id);
    //this.displayMediaDetails(index, media, { target: 'info'});
    this.displayMediaDetails(index, media); // no target means, keep current
    this.selectedMediaChanged.emit({
      index:  index,
      media:  media,
      action: 'details'
    });
  }

  onTapRating(index: number, media: Media, event: Event) {
    this.displayMediaDetails(index, media, { target: 'rating' });
    this.selectedMediaChanged.emit({
      index:  index,
      media:  media,
      action: 'rating'
    });
  }

  // "Calling the preventDefault() method during both a dragenter and dragover event
  // will indicate that a drop is allowed at that location"
  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#droptargets
  onMediaDropContainerDragEnter(event: DragEvent) {
    this.logger.debug('onMediaDropContainerDragEnter', { event });
    this.allowMediaDrop(event);
  }

  onMediaDropContainerDragOver(event: DragEvent) {
    // this.logger.debug('onMediaDropContainerDragOver', event);
    this.allowMediaDrop(event);
  }

  onMediaDropContainerDragLeave(event: DragEvent) {
    this.logger.debug('onMediaDropContainerDragLeave', { event });
    this.allowMediaDrop(event);
  }

  protected allowMediaDrop(event: DragEvent) {
    const isMedia = event.dataTransfer.types.includes("media");
    isMedia && event.preventDefault();
  }

  onMediaDrop(event: {media: Media; target: Media}) {
    this.logger.debug('onMediaDrop', event);
    this.mediaDrop.emit(event);
  }

  onMediaDragStart(media: Media) {
    this.mediaDragStart.emit(media);
  }

  onMediaDragEnd(media: Media) {
    this.mediaDragEnd.emit(media);
  }

  displayMediaDetails(index:number, media: Media, options?: any) {
    console.debug('displayMediaDetails', index, media.id);
    const context: any = {
      media: {...media},
      index: index,
      mediaPath: this.mediaPath,
      onMediaUpdate: (media: Media) => {
//        this.mediaGrid.masonry.layout();  // this.mediaGrid.update(index);
      }
    };
    if (this.mediaDetailOptions || options) {
      context.options = { ...(this.mediaDetailOptions ?? {}), ...(options ?? {}) };
    }
    const content = {
      type: MediaDetailsContainerComponent,
      context: context
    };
    this.layoutService.details.getState((state) => {
      // the same instance of grid is used on different pages/urls
      // and on url change the BasicMainContainerComponent switches to the initial (i.e. empty) detailsSidenav state
      // ensure to always set the content when details component is not MediaDetailsContainerComponent
      // or a different media is selected or options were changed
      if (!isEqual(pick(content, ['type', 'context.media.id', 'context.options', 'context.mediaPath']),
                   pick(state.content, ['type', 'context.media.id', 'context.options', 'context.mediaPath']))) {
        this.layoutService.details.setContent('component',{
          type: MediaDetailsContainerComponent,
          context: context
        });
      }
      this.layoutService.details.ensure(true);
    })
  }

  views(media: Media) {
    return '42K'; // media.views
  }

  duration(media: Media): string {
    return getMediaDurationString(media);
  }

  publishTime$(media: Media): Observable<string> {
    let time = media.timeDisplayed || media.timeProduced || media.timeCreated;
    //if (!time && media.timeCreated) {
    //  time = media.timeCreated;
    //}
    if (time) {
      const [units, unitName] = prettyDateDiff(time, new Date());
      if (units >= 0) {
        const key = units > 0 ? `moment.past.${unitName}${units>1 ? 's' : ''}` : 'moment.now';
        return this.translateService.get(key, {units: units});
      }
    }
    return of('');
  }

  fullViews$(views: number): Observable<string> {
    if (this._fullViews != views) {
      this._fullViews = views;
      if (views<10_000) {
        this._fullViews$.next(views.toLocaleString());
      } else if (views<100_000) {
        this._fullViews$.next((Math.round(views/100)/10).toLocaleString(undefined,{minimumFractionDigits:1, maximumFractionDigits:1})+'k');
      } else if (views<1_000_000) {
        this._fullViews$.next(Math.round(views/1_000).toLocaleString(undefined,{maximumFractionDigits:0})+'k');
      } else if (views<10_000_000) {
        this._fullViews$.next((Math.round(views/100_000)/10).toLocaleString(undefined,{minimumFractionDigits:1, maximumFractionDigits:1})+'m');
      } else {
        this._fullViews$.next(Math.round(views/1_000_000).toLocaleString(undefined,{maximumFractionDigits:0})+'m');
      }
    }
    return this._fullViews$;
  }

  sourceBadgeClass(media: Media) {
    const source = media.author?.source;
    const result = `source ${source}`;
    return media.author?.decision ? result : `${result} open`;
  }

  rating(media: Media) : number {
    return this.mediaService.getMediaRating(media);
  }

  playedBadgeInfo(media: Media) {
    return this.mediaService.getMediaPlayedBadgeInfo(media);
  }

  onAuthorClick(id: string) {
    if(!!this.propertiesService.group.channelsActive || this.propertiesService.properties?.group.name == "BibleTube") {
      this.router.navigate(['/channels/' + id]);
    } else {
      this.mediaService.setTypedFilters({ ['authors']: ['author:' + id] });
    }
  }

  protected onSelectionChanged(selectedIndex: number) {
    this.layoutService.details.getState((state) => {
      if (state.open &&
        get(state, 'content.type') == MediaDetailsContainerComponent &&
        this.selectedMedia) {
        this.mediaDetailOptions
        window.setTimeout(() => {
          this.displayMediaDetails(this.mediaGrid.selectedIndex, this.selectedMedia, this.mediaDetailOptions ?? {});
        });
      }
    })
  }
}
