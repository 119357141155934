export class Timer {
  protected _triggered:number = undefined;
  protected _timeout:number = 1000;

  constructor(protected handler:(time:number)=>void,timeout?:number) {
    this._timeout = timeout ?? this._timeout;
    //console.log("timer.timeout",timeout,this._timeout,timeout??this._timeout);
  }

  public set timeout(timeout:number) {
    this._timeout = timeout ?? 1000;
  }
  public get timeout():number {
    return this._timeout;
  }

  public trigger() {
    if (!this._triggered) {
      const time = Date.now();
      this._triggered = window.setTimeout(()=>this.handler(Date.now()-time), this.timeout);
    }
  }
  public cancel() {
    if (!!this._triggered) {
      window.clearTimeout(this._triggered);
      this._triggered = undefined;
    }
  }
}

export class TimerHandler {
  protected timer:number = undefined;
  constructor(protected handler?:(x?:TimerHandler)=>void,protected timeout?:number) {
    this.set(handler,timeout);
  }
  public set(handler?:(x?:TimerHandler)=>void,timeout?:number) {
    this.cancel();
    this.handler = handler;
    this.timeout = timeout;
    if (!!this.handler) {
      if (timeout>0) {
        this.timer = window.setTimeout(()=>this.handler(this), timeout);
      } else {
        this.timer = window.requestAnimationFrame(()=>this.handler(this));
      }
    }
  }
  public cancel(): boolean {
    if (!!this.timer) {
      if (this.timeout>0) {
        window.clearTimeout(this.timer);
      } else {
        window.cancelAnimationFrame(this.timer);
      }
      this.timer = undefined;
      return true;
    }
    return false;
  }
}

export function timer(handler?:(x?:TimerHandler)=>void,timeout?:number):TimerHandler {
  return new TimerHandler(handler,timeout);
}
