import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Output, ViewChild} from '@angular/core';
import {Platform as AngularPlatform} from '@angular/cdk/platform';
import {FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Contact, ENVIRONMENT, Platform} from 'core';
import get from 'lodash/get';
import {Media, MediaReviewState} from '../../store/models';
import {MediaDetailsShareDataService} from '../../service/media-details-share-data.service';
import {MediaService} from '../../service/media.service';
import {PropertiesService} from 'properties';
import {combineLatest} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {MediaDetailsSectionComponent} from '../media-details/media-details-section.component';
import {Reaction} from 'shared';
import {animate, style, transition, trigger} from "@angular/animations";
import {FilterService} from "filter";
import {LayoutService} from 'layout';
import {Router} from '@angular/router';

@Component({
  selector: 'media-details-info',
  templateUrl: './media-details-info.component.html',
  styleUrls: ['./media-details-info.component.scss'],
  animations: [
    trigger('reaction', [
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.5)', opacity: 0,
            height: '0px', margin: '0px'
          }))
      ]),
    ])
  ]
})
export class MediaDetailsInfoComponent extends MediaDetailsSectionComponent {

  @Output() onReactionRemove = new EventEmitter();

  @ViewChild('infoText', { read: ElementRef }) infoText: ElementRef;

  infoExpanded = false;
  infoOverflows = false;
  mediaForm: FormGroup;
  mediaTags: string[];

  mediaCoverUploadUrl: string;
  mediaCoverMinWidth = 100;
  mediaCoverMinHeight = 100;
  mode: 'basic' | 'advanced' = 'advanced';
  isVerifiedMember: boolean;
  isAdmin: boolean;
  mediaInfoRows: { content: string, type: string }[][] = [];

  Reaction = Reaction;

  constructor(
    @Inject(ENVIRONMENT) protected environment: any,
    public angularPlatform: AngularPlatform,
    public changeDetector: ChangeDetectorRef,
    public platform: Platform,
    protected dialog: MatDialog,
    protected mediaDetailsShareDataService: MediaDetailsShareDataService,
    public mediaService: MediaService,
    private filterService: FilterService,
    private layoutService: LayoutService,
    private propertiesService: PropertiesService,
    private router: Router) {
    super(mediaDetailsShareDataService);
    this.logger.debug('.ctor');
  }

  ngOnInit(): void {
    this.logger.debug('ngOnInit');
    combineLatest([
      this.mediaDetailsShareDataService.getEditMode$,
      this.mediaDetailsShareDataService.getMediaForm$,
      this.mediaDetailsShareDataService.getOptions$,
      this.mediaDetailsShareDataService.getMediaTags$,
      this.propertiesService.user$
    ])
      .pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(
        ([editMode, mediaForm, options, mediaTags, user]) => {
          this.infoExpanded = false;
          this.editMode = editMode;
          this.mediaForm = mediaForm;
          this.mode = options.mode;
          this.mediaTags = mediaTags;
          this.isVerifiedMember = user.isVerifiedMember || user.isAdmin;
          this.isAdmin = user.isAdmin;
          this.changeDetector.markForCheck();
        });

    this.mediaDetailsShareDataService.getMedia$.pipe(takeUntil(this.onDestroy$)).subscribe(media => {
      this.createtMediaInfo(media);
    });
  }

  private createtMediaInfo(media: Media) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const rows = media.info.split(/\r?\n/);
    this.mediaInfoRows = [];
    rows.forEach(row => {
      let mediaInfoRow = [];
      if (row.length === 0) {
        mediaInfoRow.push({ content: '', type: 'empty' });
      } else {
        const matches = [...row.matchAll(urlRegex)];

        if (matches.length > 0) {
          let startIndex = 0;
          matches.forEach(match => {
            if (match.index > startIndex) {
              mediaInfoRow.push({ content: row.substring(startIndex, match.index), type: 'text' });
            }
            const endIndex = row.indexOf(" ", match.index);
            const content = endIndex === -1 ? row.substring(match.index) : row.substring(match.index, endIndex);
            mediaInfoRow.push({ content: content, type: 'link' });
            startIndex = endIndex;
          });
        } else {
          mediaInfoRow.push({ content: row, type: 'text' });
        }
      }
      this.mediaInfoRows.push(mediaInfoRow);
    });

    this.changeDetector.markForCheck();
  }

  isInfoOverflowActive() {
    if(this.angularPlatform.FIREFOX) {
      // Firefox does not calcualte the scrollHeight correctly after it got expanded once
      // Therefore use a workaround to always display the button when info is longer than 45
      return this.media.info && this.media.info.length > 45;
    }
    if (this.infoExpanded) {
      return true;
    }
    if (this.infoText) {
      const tolerance = 2; // In px. Depends on the font you are using
      const element = this.infoText.nativeElement;
      const overflows = element.offsetHeight + tolerance < element.scrollHeight;
      if (overflows !== this.infoOverflows) {
        this.infoOverflows = overflows;
        this.changeDetector.markForCheck();
      }
    }
    return this.infoOverflows;
  }

  timeProduced(media: Media): Date {
    const timeProduced = media ? media.timeProduced || media.timeCreated : null;
    if (timeProduced) {
      return new Date(timeProduced);
    }
    return new Date();
  }

  onMediaAuthorSelect(event: MouseEvent) {
    this.context.authorSelect.emit((contact: Contact) => {
      if (contact?.id) {
        this.logger.debug('mediaAuthorSelect.callback', { contact });
        const authorId = this.mediaForm.get('author').value?.id;
        if (contact.id != authorId) {
          const pick = ({ id, name }) => ({ id, name });
          this.mediaForm.get('author').setValue(pick(contact));
        }
      } else {
        this.mediaForm.get('author').setValue({ id: this.media.uploader.id, name: this.media.uploader.name });
      }
    });
  }

  onClearTimeProduced() {
    this.mediaForm.controls.timeProduced.setValue(this.timeProduced(this.media));
  }

  get stateInfoTemplateContext() {
    return get(this.media, 'review.state', MediaReviewState.Pending) != MediaReviewState.Pending
      ? {
        data: {
          name: get(this.media.review, 'contact.name'),
          text: `media.${this.media.review.state}By`,
          class: this.media.review.state
        }
      }
      : this.media.published
        ? {
          data: {
            name: get(this.media.published, 'contact.name'),
            text: 'media.publishedBy',
            class: 'published'
          }
        }
        : null;
  }

  get isEditor(): boolean {
    return this.media.editable;
  }

  get isApprover(): boolean {
    return this.media.approvable;
  }

  get isReader(): boolean {
    return !this.isEditor && !this.isApprover;
  }

  onTapReactionRemove() {
    this.onReactionRemove.emit();
    this.mediaService.react(this.media?.id, Reaction.none);
  }

  toggleFilter(filterId: string) {
    const newTags = this.filterService.setFilter(this.mediaTags, filterId, false);
    this.mediaDetailsShareDataService.setMediaTags(newTags);
  }

  onAuthorClick() {
    if(!!this.propertiesService.group.channelsActive) {
      this.router.navigate(['/channels/'+ this.media.author.id]);
    } else {
      this.mediaService.setTypedFilters({ ['authors']: ['author:' + this.media.author.id] });
      this.layoutService.details.toggle();
    }
  }

  getAvatarUrl(): string {
    // this.contactService.getContact$(this.contactId).pipe(take(1)).subscribe(contact => {
    //   return `${this.environment.serverUrl}/v1.0/content/avatar/${contact.version || 0}/${contact.id}.jpg`;
    // });
    // TODO contact service is not available here, so ideally the contact version of the author should be saved in the media
    return `${this.environment.serverUrl}/v1.0/content/avatar/${this.media.author['version'] || 0}/${this.media.author.id}.jpg`;
  }

}
