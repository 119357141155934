import {Injectable, InjectionToken} from "@angular/core";
import {Contact} from "core";
import {ContactPropertiesAccessor, ContactPropertiesService} from "shared";

export const MEDIA_CONTACT_SERVICE = new InjectionToken<MediaContactService>('mediaContactService');

export interface MediaContactService extends ContactPropertiesService {
  update(contact: Contact | Partial<{id: string, partnerId: string}>): Promise<Contact>;
}

@Injectable()
export class MediaContactDefaultService implements MediaContactService {

  constructor(protected contactPropertiesService: ContactPropertiesService) {}

  update(contact: Contact | Partial<{partnerId: string}>): Promise<Contact> {
    return Promise.reject("Not Implemented");
  }

  contactProperties$(contactId: string, prefix?: string): Promise<ContactPropertiesAccessor> {
    return this.contactPropertiesService.contactProperties$(contactId, prefix);
  }

  contactAppProperties$(contactId: string, prefix?: string): Promise<ContactPropertiesAccessor> {
    return this.contactPropertiesService.contactAppProperties$(contactId, prefix);
  }

  contactGroupProperties$(contactId: string, prefix?: string): Promise<ContactPropertiesAccessor> {
    return this.contactPropertiesService.contactGroupProperties$(contactId, prefix);
  }
}
