@charset "UTF-8";

/* projects/media/src/lib/containers/media-grid-container/media-grid-container.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
  display: flex;
  flex-direction: column;
}
:host > .drop-container {
  position: relative;
  flex: 1;
  display: flex;
}
:host > .drop-container > .drop-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  display: none;
  overflow: hidden;
}
:host.dropOver > .drop-container > .drop-overlay {
  display: flex;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(6px);
  justify-content: center;
  align-items: center;
}
:host.dropOver > .drop-container > .drop-overlay > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  padding: 32px;
  background-color: white;
  border: 1px solid #ddd;
  color: var(--color-primary);
}
:host.dropOver > .drop-container > .drop-overlay > div > div {
  display: flex;
}
:host.dropOver > .drop-container > .drop-overlay > div > mat-icon {
  width: 128px;
  height: 128px;
  display: flex;
  font-size: 128px;
}
app-status-bar {
  z-index: 1;
  justify-content: flex-end;
}
.pending {
  font-size: 0.7em;
  padding: 0 10px;
  background-color: var(--color-primary);
  color: white;
}
.declined {
  font-size: 0.7em;
  padding: 0 10px;
  background-color: var(--color-accent);
  color: white;
}
media-grid {
  flex: 1;
}
media-grid .media-details {
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
media-grid .media-details > div:first-child {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 8px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
}
media-grid .media-details > div:first-child h3 {
  font-size: 1em !important;
  line-height: normal;
  margin: 0 0 0 0;
}
media-grid .media-details > div:first-child h4 {
  font-size: 0.75em;
  line-height: normal;
  margin: 5px 0 0 0;
}
media-grid .media-details > div:first-child .line > span {
  display: inline-block;
  font-size: 0.75em;
  word-break: break-all;
}
media-grid .media-details > div:first-child .line > span:not(:last-of-type):after {
  content: "\2022";
  margin: 0 4px;
}
media-grid .media-details > div:first-child .line > span mat-icon {
  display: inline;
  font-size: 1em;
  height: 0.75em;
  margin-right: 3px;
  vertical-align: middle;
  line-height: 0.75em;
}
media-grid .media-details > div:first-child .line > span.nowrap {
  white-space: nowrap;
}
media-grid .media-details.invisible > div:first-child {
  color: rgba(0, 0, 0, 0.38);
}
media-grid button.settings {
  position: absolute;
  right: 2px;
  bottom: 2px;
  background-color: white;
  color: dimgray;
}
media-grid .badge.more {
  position: absolute;
  left: -4px;
  top: -10px;
  color: white;
}
media-grid .badge.more mat-icon {
  position: relative;
  text-shadow: 0 0 2px black;
  z-index: 1;
}
media-grid .badge.more mat-icon:before {
  position: absolute;
  content: "";
  left: 0;
  top: 6px;
  right: 0;
  bottom: 6px;
  border-radius: 6px;
  z-index: -1;
}
media-grid .badge.more:hover mat-icon {
  text-shadow: 0 0 1px black;
}
media-grid .badge.more:hover mat-icon:before {
  background-color: inherit;
}
media-grid .badge.more:active mat-icon {
  text-shadow: none;
}
media-grid .badge:not(.more) {
  position: absolute;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  min-height: 28px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2666666667);
  color: white;
  text-transform: uppercase;
  font-size: 0.7em;
}
media-grid .badge:not(.more).light {
  background-color: white;
  color: var(--color-primary);
}
media-grid .badge:not(.more).played {
  top: -4px;
  right: -4px;
  padding: 2px 8px;
}
media-grid .badge:not(.more).played.new {
  background-color: var(--color-accent);
  color: white;
}
media-grid .badge:not(.more).played.touched {
  background-color: #ddd;
  color: var(--color-accent);
}
media-grid .badge:not(.more).played.touched > span {
  margin-left: 4px;
}
media-grid .badge:not(.more).played.touched > mat-icon {
  color: var(--color-accent);
}
media-grid .badge:not(.more).played.completed {
  background-color: var(--color-primary);
  color: white;
}
media-grid .badge:not(.more).played.completed > mat-icon {
  color: white;
}
media-grid .badge:not(.more).played > mat-icon {
  font-size: 18px;
  width: 18px;
  height: 18px;
}
media-grid .badge:not(.more).played > mat-icon.eye {
  font-size: 1.3em;
  width: 1.3em;
  height: 1.3em;
}
media-grid .badge:not(.more).top {
  top: 2px;
}
media-grid .badge:not(.more).right {
  right: 2px;
}
media-grid .badge:not(.more).bottom {
  bottom: 2px;
}
media-grid .badge:not(.more).left {
  left: 2px;
}
media-grid .badge:not(.more) > mat-icon {
  margin-right: 3px;
}
media-grid .badge:not(.more).source:after {
  content: "";
  display: block;
  color: inherit;
  font-weight: normal;
  font-style: normal;
  margin-left: 3px;
  text-decoration: none;
  width: 12px;
  height: 12px;
  line-height: 100%;
}
media-grid .badge:not(.more).source.upline:after {
  content: "U";
}
media-grid .badge:not(.more).source.downline:after {
  content: "D";
}
media-grid .badge:not(.more).source.crossline:after {
  content: "C";
}
media-grid .badge:not(.more).source.open {
  background-color: var(--color-accent);
}
media-grid .badge:not(.more).rating {
  color: rgba(0, 0, 0, 0.5333333333);
  background-color: var(--color-primary);
  min-height: 16px;
  cursor: pointer;
  margin: 0;
}
media-grid .badge:not(.more).rating ::ng-deep > .star svg {
  fill: var(--color-primary);
}
button.inverted {
  background-color: white;
  color: var(--color-primary);
}
button.inverted.warn {
  color: #f44336;
}
.media-name {
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.author {
  cursor: pointer;
}
:host-context(.color-scheme-dark) media-grid .media-details {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
:host-context(.color-scheme-dark) media-grid .media-details > div:first-child {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) media-grid .media-details > div:first-child h3 {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) media-grid .badge.more {
  color: white;
}
:host-context(.color-scheme-dark) media-grid .badge.more mat-icon {
  text-shadow: 0 0 2px black;
}
:host-context(.color-scheme-dark) media-grid .badge.more:hover mat-icon {
  text-shadow: 0 0 1px black;
}
:host-context(.color-scheme-dark) media-grid .badge.more:hover mat-icon:before {
  background-color: inherit;
}
:host-context(.color-scheme-dark) media-grid .badge:not(.more) {
  background-color: rgba(0, 0, 0, 0.2666666667);
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) media-grid .badge:not(.more).light {
  background-color: white;
  color: var(--color-primary);
}
:host-context(.color-scheme-dark) media-grid .badge:not(.more).played.new {
  background-color: var(--color-accent);
  color: white;
}
:host-context(.color-scheme-dark) media-grid .badge:not(.more).played.touched {
  background-color: #E5E5E5;
  color: var(--color-accent);
}
:host-context(.color-scheme-dark) media-grid .badge:not(.more).played.touched > mat-icon {
  color: var(--color-accent);
}
:host-context(.color-scheme-dark) media-grid .badge:not(.more).played.completed {
  background-color: var(--color-primary);
  color: white;
}
:host-context(.color-scheme-dark) media-grid .badge:not(.more).played.completed > mat-icon {
  color: white;
}
:host-context(.color-scheme-dark) media-grid .badge:not(.more).source.open {
  background-color: var(--color-accent);
}
:host-context(.color-scheme-dark) media-grid .badge:not(.more).rating {
  color: rgba(0, 0, 0, 0.5333333333);
  background-color: var(--color-primary);
}
:host-context(.color-scheme-dark) media-grid .more-plain {
  text-shadow: black 0 0 2px;
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) :host.dropOver .drop-container > .drop-overlay > div {
  background-color: #282828;
  color: #AEAFB0;
  border-color: #AEAFB0;
}
/*# sourceMappingURL=media-grid-container.component-Q5NKI3Z6.css.map */
