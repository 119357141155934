import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewContainerRef
} from '@angular/core';
import {animate, AnimationEvent, state, style, transition, trigger} from "@angular/animations";
import {TemplatePortal} from "@angular/cdk/portal";
import {Side, SlidePanelDirective} from "./slide-panel.directive";
import {BasicContainerComponent} from "../basic-container/basic-container.component";
import {takeUntil} from "rxjs/operators";
import {Logger} from "core";

export enum SlideState {
  START,
  END,
}

export type SlideEvent = { slideIn: string, slideOut: string, state: SlideState };

// portals and portal outlets:
// https://material.angular.io/cdk/portal/overview
// https://itnext.io/checking-out-angulars-cdk-portals-d34bfd8a5647
// https://github.com/angular/components/blob/a9a0a09b182c8115b1312940a08910a581b69c84/src/material/expansion/expansion-panel.ts

@Component({
  selector: 'slide-panel',
  templateUrl: './slide-panel-container.component.html',
  styleUrls: ['./slide-panel-container.component.scss'],
  animations: [
    trigger('slide', [
      state('left',  style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX({{offset}}%)' }), { params: { offset: '0'}}),
      transition('* => *', animate('{{ time }}' ))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlidePanelContainerComponent extends BasicContainerComponent implements OnInit {

  @ContentChildren(SlidePanelDirective, { descendants: false }) slidePanels: QueryList<SlidePanelDirective>;

  Side = Side;

  @Input() set id(id: string) {
    if (id != this.id) {
      this._id = id;
      this.setId = true;
      this.slidePanel = this.findSlidePanel(id);
      if (this.slidePanel?.templateRef) {
        this.updateOrCreatePortal(this.slidePanel);
      }
      this.changeDetector.markForCheck();
    }
  }

  get id(): string {
    return this._id;
  }

  @Input() animate  = true;
  @Output() onSlide = new EventEmitter<SlideEvent>();

  public _id: string;
  protected setId = false;

  public slidePanel: SlidePanelDirective;
  public portals: { [side: string]: TemplatePortal<SlidePanelDirective> } = {};
  public sliding = false;

  protected logger = new Logger('SlidePanelContainerComponent');

  constructor(private viewContainerRef: ViewContainerRef,
              private changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterContentInit() {
    super.ngAfterViewInit();
    const update = (slidePanels) => {
      let fallback: SlidePanelDirective = undefined;
      let current: SlidePanelDirective  = undefined;
      slidePanels
        ?.filter(slidePanel => slidePanel?.templateRef)
        .forEach((slidePanel, index) => {
          let render = !slidePanel.lazy;
          if (!this.setId && index==0) {  // if id was not explicitly assigned yet
            this._id = slidePanel.id;     // take the id of the first slide
          }
          if (!fallback && slidePanel.id=='*') {
            fallback = slidePanel;
          }
          if (this.id && !current) {
            const last = index==this.slidePanels.length-1;
            current = this.id==slidePanel.id ? slidePanel : last ? fallback || slidePanels.first : undefined;
            if (current) {
              render = true;
              // modification of template bound property SlidePanelContainerComponent.slidePanels
              // is scheduled to run  after the current change detection cycle has completed
              // in order to avoid ExpressionChangedAfterItHasBeenCheckedError
              // this also helps to prevent rendering until the component is fully initialized
              window.setTimeout(() => {
                this.slidePanel = current;
                this.changeDetector.detectChanges();
              });
            }
          }
          if (render) {
            this.updateOrCreatePortal(slidePanel);
          }
        });
    };
    update(this.slidePanels);
    this.slidePanels.changes
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(changes => {
        // this.logger.info('SLIDE PANEL CHANGES', changes);
        update(changes);
      })
  }

  protected findSlidePanel(id) {
    if (this.slidePanels?.length) {
      const find = (id: string) => {
        const slidePanel = this.slidePanels.find(slidePanel => slidePanel.id == id);
        return !slidePanel && id!='*' ? find('*') : slidePanel;
      };
      return find(id);
    }
    return undefined;
  }

  protected updateOrCreatePortal(slidePanel) {
    const side = slidePanel.side || Side.LEFT;
    const portal = this.portals[side];
    if (portal) {
      portal.templateRef = slidePanel.templateRef;
      this.changeDetector.detectChanges();
    } else {
      this.portals[side] = new TemplatePortal(slidePanel.templateRef, this.viewContainerRef);
    }
  }

  is(id: string): boolean {
    return this.id == id;
  }

  toggle() {
    this.id = this.slidePanels?.toArray()?.find(slidePanel => slidePanel.id!=this.id).id;
  }

  get slideState(): any {
    const state = {
      value: this.slidePanel.side,
      params: {
        time: this.animate ? '300ms' : '0ms'
      } as any
    };
    if (state.value==Side.RIGHT) {
      state.params.offset = 100/Object.keys(this.portals).length - 100;
    }
    return state;
  }

  onSlideStart(event: AnimationEvent) {
    this.sliding = true;
    const  slidePanelIdIn = this.slidePanels.find(slidePanel => slidePanel.side == event.toState)?.id   || event.toState;
    const slidePanelIdOut = this.slidePanels.find(slidePanel => slidePanel.side == event.fromState)?.id || event.fromState;
    this.onSlide.emit({ slideIn: slidePanelIdIn, slideOut: slidePanelIdOut, state: SlideState.START });
  }

  onSlideEnd(event: AnimationEvent) {
    this.sliding = false;
    const  slidePanelIdIn = this.slidePanels.find(slidePanel => slidePanel.side == event.toState)?.id   || event.toState;
    const slidePanelIdOut = this.slidePanels.find(slidePanel => slidePanel.side == event.fromState)?.id || event.fromState;
    this.onSlide.emit({ slideIn: slidePanelIdIn, slideOut: slidePanelIdOut, state: SlideState.END });
  }
}
