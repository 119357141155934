import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Contact, ENVIRONMENT, Logger, Platform, Topic } from 'core';
import { Media } from 'media/public-api';
import { MediaService } from "../../service/media.service";
import { PropertiesService } from 'properties';
import { map, Observable, Subscription, take } from 'rxjs';
import { BasicContainerComponent, ContactPropertiesAccessor, ContactPropertiesService } from 'shared';
import { UPLOAD_TYPE } from 'upload';
import { ChannelInfoComponent } from '../channel-info/channel-info.component';

export interface ChannelInformation {
  bio?: string;
  links?: Link[];
  background?: Media;
}

interface Link {
  label: string;
  url: string;
}

@Component({
  selector: 'channel-details',
  templateUrl: './channel-details.component.html',
  styleUrls: ['./channel-details.component.scss']
})
export class ChannelDetailsComponent extends BasicContainerComponent {

  @Input() editMode: boolean;
  // author1$ = new BehaviorSubject<string[]>(undefined);

  get contact() {
    return this._contact;
  }
  @Input() set contact(contact: Contact) {
    if (!!contact) {
      this._contact = contact;
      this.avatarUrl$ = new Promise((resolve) => {
        resolve(`${this.environment.serverUrl}/v1.0/content/avatar/${contact.version || 0}/${contact.id}.jpg`);
      });
    }
  }

  get contactPropertiesAccessor() {
    return this._contactPropertiesAccessor;
  }
  @Input() set contactPropertiesAccessor(contactPropertiesAccessor: ContactPropertiesAccessor) {
    if (!!contactPropertiesAccessor) {
      this._contactPropertiesAccessor = contactPropertiesAccessor;
      this.cancel();
    }
  }
  @Output() selectedTopic = new EventEmitter<Topic>();

  private logger = new Logger('ChannelDetails');

  authorSubscription: Subscription;
  channel: ChannelInformation = {};
  _contact: Contact;
  _contactPropertiesAccessor: ContactPropertiesAccessor;
  channelTopics$: Observable<Topic[]> = null;
  avatarUrl$: Promise<string>;
  currentIndex: number = 0;

  @ViewChild(ChannelInfoComponent) infoRef;

  constructor(
    private router: Router,
    protected route: ActivatedRoute,
    private propertiesService: PropertiesService,
    protected elementRef: ElementRef,
    protected snackBar: MatSnackBar,
    private translateService: TranslateService,
    private contactPropertiesService: ContactPropertiesService,
    public mediaService: MediaService,
    protected platform: Platform,
    @Inject(ENVIRONMENT) protected environment: any) {
    super();
  }

  ngOnInit(): void {
    // Register to Angular navigation events to detect navigating away (so we can revert the author filter)
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (!!this.authorSubscription) {
          this.mediaService.setTypedFilters({ ['authors']: [] });
        }
      }
    });

    this.channelTopics$ = this.propertiesService.user$.pipe(take(1)).pipe(map(user => {
      const projectTopic = user.app.topics.find(topic => topic.id == 'project');
      const sermonTopic = projectTopic.topics.find(topic => topic.id == 'sermons');
      if (!!sermonTopic) {
        this.selectedTopic.emit(sermonTopic);
        return [sermonTopic];
      }
      return [];
    }));

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.contactPropertiesAccessor?.unsubscribe();
  }

  getBackgroundUrl() {
    const media = this.channel.background;
    if (!!media) {
      return this.mediaService.getMediaCoverSrc(media);
    }
    // Return default background
    // TODO specify default media
    return this.environment.serverUrl + "/v1.0/media/link/1689715975731/12799e2c1896aeba5cc/7359bc56-d5b3-43f0-b669-661facb84a4a.jpeg";
  }

  getBackgroundMedia() {
    const media = this.channel.background;
    if (!!media) {
      return media;
    }
    // Return default background
    // TODO specify default media
    return null;
  }

  onChannelsClick() {
    this.router.navigate(['/channels/']);
  }

  drop(event: CdkDragDrop<Link[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  onUploadMedia($event: MouseEvent, author: Contact) {
    const media = { tags: ['channel.background'] };
    const croppingOptions = { active: true, minHeight: 200, minWidth: 1200, aspectRatio: 6 };
    const options = { uploadTypes: [UPLOAD_TYPE.IMAGE], media: media, cropping: croppingOptions };
    this.mediaService
      .uploadMedia(this.elementRef, options)
      .subscribe(mediaUploadRef => {
        const { uploadId, uploadRef, complete } = mediaUploadRef;
        complete.then(mediaUploads => {
          const error = mediaUploads.find(mediaUpload => mediaUpload.error || !mediaUpload.media);
          if (error) {
            this.translateService.get('media.uploadResult.mediaError').subscribe((translatedMessage) => {
              this.snackBar.open(translatedMessage, this.translateService.instant('actions.close'), {
                duration: 2000,
                horizontalPosition: 'right'
              });
            });
          } else {
            this.channel.background = mediaUploads[0].media;
          }
        });
      });
  }

  // onChangegPhotoInput(event: Event) {
  //   const target: HTMLInputElement = <HTMLInputElement>event.target;
  //   const files: FileList = target.files;
  //   if (files.length > 0) {
  //     const overlayRef = this.imageCropperOverlayService.open({
  //       data: {
  //         fileChangeEvent: event,
  //         onCrop: (imageCroppedEvent: ImageCroppedEvent) => {
  //           fetch(imageCroppedEvent.base64)
  //             .then(response => response.blob())
  //             .then((blob) => {
  //               this.logger.debug('CROPPED IMAGE BLOB', blob);
  //               const photo = files[0];
  //               overlayRef.close();
  //               const width = imageCroppedEvent.width, height = imageCroppedEvent.height;
  //               const minWidth = 120, minHeight = 120;
  //               if (width >= minWidth && height >= minHeight) {
  //                 this.logger.debug('UPLOAD CONTACT PHOTO');
  //                 this.uploadPhoto(photo, this.renderer.parentNode(event.target), blob).catch(error => {
  //                   this.logger.error('Failed to upload photo', error);
  //                   this.showSnackBar('contact.updateResult.photoUploadError');
  //                 })
  //               } else {
  //                 this.translateService.get('contact.validation.photoTooSmall', {
  //                   width: width + 'px',
  //                   height: height + 'px',
  //                   minimumWidth: minWidth + 'px',
  //                   minimumHeight: minHeight + 'px'
  //                 })
  //                   .pipe(take(1))
  //                   .subscribe((message) => {
  //                     this.dialog.open(MessageBoxComponent, { data: { message: message } });
  //                   });
  //               }
  //             });
  //         },
  //         header: 'contact.createProfilePicture'
  //       }
  //     });
  //     overlayRef.onClose.pipe(take(1)).subscribe(() => target.value = '');
  //   }
  // }


  // onTapPhoto(event: Event) {
  //   this.photoInput.nativeElement.click();
  // }

  // onChangePhotoInput(event: Event) {
  //   const target: HTMLInputElement = <HTMLInputElement>event.target;
  //   const files: FileList = target.files;
  //   if (files.length > 0) {
  //     const overlayRef = this.imageCropperOverlayService.open({
  //       data: {
  //         fileChangeEvent: event,
  //         onCrop: (imageCroppedEvent: ImageCroppedEvent) => {
  //           fetch(imageCroppedEvent.base64)
  //             .then(response => response.blob())
  //             .then((blob) => {
  //               this.logger.debug('CROPPED IMAGE BLOB', blob);
  //               const photo = files[0];
  //               overlayRef.close();
  //               const width = imageCroppedEvent.width, height = imageCroppedEvent.height;
  //               const minWidth = 120, minHeight = 120;
  //               if (width >= minWidth && height >= minHeight) {
  //                 this.logger.debug('UPLOAD CONTACT PHOTO');
  //                 this.uploadPhoto(photo, this.renderer.parentNode(event.target), blob).catch(error => {
  //                   this.logger.error('Failed to upload photo', error);
  //                   this.showSnackBar('contact.updateResult.photoUploadError');
  //                 })
  //               } else {
  //                 this.translateService.get('contact.validation.photoTooSmall', {
  //                   width: width + 'px',
  //                   height: height + 'px',
  //                   minimumWidth: minWidth + 'px',
  //                   minimumHeight: minHeight + 'px'
  //                 })
  //                   .pipe(take(1))
  //                   .subscribe((message) => {
  //                     this.dialog.open(MessageBoxComponent, { data: { message: message } });
  //                   });
  //               }
  //             });
  //         },
  //         header: 'contact.createProfilePicture'
  //       }
  //     });
  //     overlayRef.onClose.pipe(take(1)).subscribe(() => target.value = '');
  //   }
  // }

  // uploadPhoto(file: File, target: any, blob?: Blob): Promise<UploadResult> {
  //   this.photoUppy.addFile({
  //     source: 'photoInput',
  //     name: file.name,
  //     type: file.type,
  //     data: blob ? blob : file
  //   });
  //   return this.photoUppy.upload();
  // }

  // get photoUppy(): Uppy {
  //   //lazy creation for photo upload uppy
  //   //as we do not seek for resume-after-reload here
  //   if (!this._photoUppy) {
  //     this._photoUppy = this.createPhotoUppy();
  //   }
  //   return this._photoUppy;
  // }
  // createPhotoUppy(): Uppy {
  //   const uppy = new Uppy({
  //     id: 'photo',  // used for local storage isolation in order to avoid collisions with other uppy instances
  //     autoProceed: false,
  //     debug: true,
  //     restrictions: {
  //       // maxFileSize: 10_000_000, // maximum file size in bytes for each individual file (total max size has been requested, and is planned)
  //       maxNumberOfFiles: 5,
  //       minNumberOfFiles: 1,
  //       allowedFileTypes: ['image/*']
  //     }
  //   });

  //   uppy.use(XHRUpload, {
  //     endpoint: this.sessionTokenService.rewrite(`${this.environment.serverUrl}/v1.0/contacts/upload/photo`),
  //     formData: true,
  //     fieldName: 'photo',
  //     withCredentials: true
  //   });

  //   let photoUploadProgressElement = this.photoUploadProgress.nativeElement;
  //   let photoUploadProgressElementContainer = this.renderer.parentNode(photoUploadProgressElement);
  //   uppy
  //     .on('upload', (data: { id: string, fileIDs: string[] }) => {
  //       this.renderer.addClass(photoUploadProgressElementContainer, 'progress');
  //       this.renderer.setStyle(photoUploadProgressElement, 'width', 0);
  //     })
  //     .on('upload-progress', (file: UppyFile, progress: FileProgress) => {
  //       this.logger.debug('PHOTO UPLOAD PROGRESS', { progress });
  //       const percentage = progress.bytesUploaded / progress.bytesTotal * 100;
  //       this.renderer.setStyle(photoUploadProgressElement, 'width', percentage + '%');
  //     })
  //     .on('upload-success', (file: UppyFile, response: SuccessResponse) => {
  //       this.logger.debug('PHOTO UPLOAD SUCCESS', { file, response });
  //       this.renderer.setStyle(photoUploadProgressElement, 'width', 100 + '%');
  //     })
  //     .on('complete', (result: UploadResult) => {
  //       this.logger.debug('PHOTO UPLOAD COMPLETED', { result });
  //       this.renderer.removeClass(photoUploadProgressElementContainer, 'progress');
  //       window.setTimeout(() => {
  //         this.propertiesService.reload();
  //         // this.photoUppy.reset();
  //       })
  //     });
  //   return uppy;
  // }

  showSnackBar(message: string, action = 'actions.close') {
    this.translateService.get(message).subscribe((translatedMessage) => {
      this.snackBar.open(translatedMessage, this.translateService.instant(action), {
        duration: 2000,
        horizontalPosition: 'right',
        // verticalPosition: 'bottom'
      });
    })
  }

  save() {
    this.contactPropertiesAccessor.setProperty("channelInformation", this.infoRef.channel.bio);
    this.contactPropertiesAccessor.setProperty("channelLinks", JSON.stringify(this.infoRef.channel.links));

    const oldBackgroundJson = this.contactPropertiesAccessor?.getProperties()["channelBackground"];
    const oldBackground = !!oldBackgroundJson ? JSON.parse(oldBackgroundJson) : null;

    if (oldBackground?.id != this.channel.background?.id) {
      if (!!this.channel.background) {
        this.contactPropertiesAccessor.setProperty("channelBackground", JSON.stringify(this.channel.background));
      }
      if (!!oldBackground) {
        this.mediaService.deleteMedia(oldBackground).then(
          null,
          (error) => {
            this.logger.warn("CHANNEL.mediaDeletion.error", error);
          });
        this.contactPropertiesAccessor.removeProperty("channelBackground");
      }
    }
    this.contactPropertiesAccessor.save()
      .then(accessor => {
      })
      .catch(error => this.logger.warn("CHANNEL.error", error));
  }

  cancel() {
    this.channel.bio = this.contactPropertiesAccessor?.getProperties()["channelInformation"];

    const linksJson = this.contactPropertiesAccessor?.getProperties()["channelLinks"];
    this.channel.links = !!linksJson ? JSON.parse(linksJson) : [];

    const background = this.contactPropertiesAccessor?.getProperties()["channelBackground"];
    const newBackground = this.channel.background;
    if (!!newBackground && newBackground?.id != background?.id) {
      this.mediaService.deleteMedia(newBackground).then(
        null,
        (error) => {
          this.logger.warn("CHANNEL.mediaDeletion.error", error);
        });
    }

    if (!!background) {
      const mediaObject: Media = JSON.parse(background);
      this.channel.background = mediaObject;
      // this.mediaService.getMedia$(mediaId).subscribe(media => {
      //   this.channel.background = media;
      // });
    } else {
      this.channel.background = null;
    }
  }

}
