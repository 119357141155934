import {InjectionToken, Provider} from "@angular/core";

export interface AuthenticationModuleConfig {
  routing: AuthenticationRoutingConfig;
  loginObserver?: Provider;
}

export class AuthenticationRoutingConfig {
  rootAppPath: string;
}

export const LOGIN_OBSERVER = new InjectionToken<LoginObserver>('loginObserver');
export interface LoginObserver {
  onInvitationCodeChange: (code: string) => void;
  onInstallAppRequest: () => void;
  onSign: (sign: boolean) => void;
}
