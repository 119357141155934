<!-- @.disabled disables also child animations which means that we cannot nest slide panels -->
<!--<div [@.disabled]="!animate" class="container" [@slide]="panel">-->
<div *ngIf="slidePanel"
     [@slide]="slideState"
     (@slide.start)="onSlideStart($event)"
     (@slide.done)="onSlideEnd($event)"
     class="container">
  <div *ngIf="portals[Side.LEFT]  as portal"><ng-template [cdkPortalOutlet]="portal"></ng-template></div>
  <div *ngIf="portals[Side.RIGHT] as portal"><ng-template [cdkPortalOutlet]="portal"></ng-template></div>
</div>
