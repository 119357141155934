<div *ngIf="mediaTags" [ngSwitch]="!!editMode" class="container">
  <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="view"></ng-container>
  <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="edit"></ng-container>
</div>

<ng-template #view>
  <ng-container *ngFor="let filter of filters; let index = index">
    <ng-container
        *ngTemplateOutlet="filterTemplate; context: { filter: filter, selected: mediaTags, readOnly: true, index: index }">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #edit>
  <ng-container *ngFor="let filter of filters; let index = index">
    <ng-container
        *ngTemplateOutlet="filterTemplate; context: { filter: filter, selected: mediaTags, readOnly: false, index: index }">
    </ng-container>
  </ng-container>
  <ng-container>
    <ng-container
      *ngTemplateOutlet="userRoleTemplate; context: { filter: userRoleFilter, selected: mediaTags, readOnly: false, index: filters?.length }">
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #filterTemplate let-filter="filter" let-selected="selected" let-readOnly="readOnly" let-index="index">
  <filter-tag-section [filter]="filter" [selected]="selected" [readOnly]="readOnly"
      (onFilter)="onTapFilter($event)" (onUpdate)="filterCheck($event, index)" class="section">
  </filter-tag-section>
</ng-template>

<ng-template #userRoleTemplate let-filter="filter" let-selected="selected" let-readOnly="readOnly" let-index="index">
  <filter-tag-section [filter]="filter" [selected]="selected" [readOnly]="readOnly"
                      [visibleFilter]="userRoleFilterVisibilityFunction"
                      (onFilter)="onTapFilter($event)" (onUpdate)="filterCheck($event, index)" class="section">
  </filter-tag-section>
</ng-template>
