import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy, OnInit,
  Renderer2, signal,
  SimpleChanges,
  ViewChild
} from "@angular/core";
import {Platform} from "core";
import {Subscription} from "rxjs";
import {DragEvent} from "../../models/touch/drag-event";
import {ScrollEvent, ScrollUpdateEvent, VirtualScrollHandler} from "./virtual-scroll.handler";

@Component({
  selector: 'virtual-scrollbar',
  template: `
    <div (onDrag)="onDrag($event)">
      <div class="thumb" #thumb><div></div></div>
    </div>
  `,
  host: {
    '[class.horizontal]': "!vertical$()",
    '[class.vertical]': "vertical$()",
    '[class.visible]': "visible$()",
  },
  styleUrls: ['./virtual-scroll.bar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualScrollbarComponent implements OnInit, OnDestroy, OnChanges {

  protected _offsetSizeProperty = 'offsetHeight';
  protected _sizeProperty       = 'height';
  protected _positionProperty   = 'translateY';
  protected _deltaProperty      = 'deltaY';

  protected _virtualScrollHandler:VirtualScrollHandler;
  protected _subscriptions:Subscription;
  protected _startGap:number = 0;
  protected _endGap:number = 0;

  protected subscriptions = new Subscription();

  vertical$ = signal(true);
  visible$ = signal(false);    // visible==false and _visibilityTimer undefined -> invisible
                              // visible==true and _visibilityTimer undefined -> visible permanently
                              // visible==true and _visibilityTimer set -> visible with timeout

  @Input()
  public get startGap(): number {
    return this._startGap;
  }
  public set startGap(gap:number) {
    this._startGap = gap;
  }

  @Input()
  public get endGap(): number {
    return this._endGap;
  }
  public set endGap(gap:number) {
    this._endGap = gap;
  }

  @Input()
  public get virtualScrollHandler(): VirtualScrollHandler {
    return this._virtualScrollHandler;
  }

  public set virtualScrollHandler(virtualScrollHandler:VirtualScrollHandler) {
    this._subscriptions?.unsubscribe();
    this._subscriptions = undefined;
    if (virtualScrollHandler && (!this._virtualScrollHandler || this._virtualScrollHandler.vertical==virtualScrollHandler.vertical)) {
      this.vertical$.set(virtualScrollHandler.vertical);
      if (!virtualScrollHandler.vertical) {
        this._offsetSizeProperty = 'offsetWidth';
        this._sizeProperty       = 'width';
        this._positionProperty   = 'translateX';
        this._deltaProperty      = 'deltaX';
      }
      this._virtualScrollHandler = virtualScrollHandler;
      this._subscriptions   = virtualScrollHandler.onScroll.subscribe(event => this.onScroll(event));
      this._subscriptions.add(virtualScrollHandler.doUpdate.subscribe(event => this.doUpdate(event)));
      //this._subscriptions.add(virtualScrollHandler.onScroll.subscribe(event => this.onScroll(event)));
    } else {
      this._virtualScrollHandler = undefined;
    }
  }

  @ViewChild('thumb', { read: ElementRef, static: false })
  protected thumbElementRef: ElementRef;

  protected _elementSize:number;
  protected _thumbSize:number;
  protected _thumbPosition:number;
  protected _dragStartPosition:number
  protected _dragging:boolean = false;
  protected _dragScrollTimer:number;
  protected _visibilityTimer:number;    // setTimeout(...) handler
  protected _visibilityTrigger:number;  // last time it was triggered....

  constructor(
    public readonly elementRef: ElementRef,
    protected readonly platform: Platform,
    protected readonly renderer: Renderer2,
    protected readonly zone: NgZone,
    protected changeDetectorRef: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.subscriptions.add(this.registerElementListener('mouseenter',(event:MouseEvent) => this.onMouseEnter(event)));
      this.subscriptions.add(this.registerElementListener('mouseleave',(event:MouseEvent) => this.onMouseLeave(event)));
    });
  }

  protected registerElementListener(event:string,handler:(event:DragEvent|KeyboardEvent|TouchEvent|MouseEvent)=>void):()=>void {
    return this.renderer.listen(this.elementRef.nativeElement,event,(event:DragEvent|KeyboardEvent|TouchEvent|MouseEvent)=>this.zone.run(()=>handler(event)));
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.virtualScrollHandler) {
      this.update(
        this.virtualScrollHandler.scrollSize,
        this.virtualScrollHandler.viewportSize,
        this.virtualScrollHandler.scrollPosition);
    }
  }

  onDrag(event:DragEvent) {
    if (event.type=='drag') {
      const dragMax        = (this._elementSize-this._thumbSize-this.startGap-this.endGap);
      const dragPosition   = Math.max(0, Math.min(dragMax,this._dragStartPosition+event[this._deltaProperty]));
      const scrollMax      = Math.max(0,this.virtualScrollHandler.scrollSize-this.virtualScrollHandler.viewportSize);
      const scrollPosition = scrollMax*dragPosition/dragMax;
      window.clearTimeout(this._dragScrollTimer);
      this._dragScrollTimer = window.setTimeout(()=>{
        this._dragScrollTimer = undefined;
        this.virtualScrollHandler.scrollTo(scrollPosition,0);
        this.update(
          this.virtualScrollHandler.scrollSize,
          this.virtualScrollHandler.viewportSize,
          scrollPosition);
      });
      //event.stopPropagation = true;
      //console.log("DRAG",event.deltaY,"dragMax",dragMax,"dragPosition",dragPosition,"scrollMax",scrollMax,"scrollPosition",scrollPosition);
    } else if (event.type=='start') {
      const rect   = this.thumbElementRef.nativeElement.firstElementChild.getBoundingClientRect();
      const inside = event.startPageX>=rect.left && event.startPageX<=rect.right &&
                     event.startPageY>=rect.top  && event.startPageY<=rect.bottom;
      //event.stopPropagation = true;
      event.cancel = !inside;
      this._dragging = inside;
      this._dragStartPosition = this._thumbPosition;
      //console.log("START",rect,"event",event,"inside",inside,this.virtualScrollHandler);
    } else {
      window.clearTimeout(this._dragScrollTimer);
      //event.stopPropagation = true;
      this._dragging = false;
      this.update(
        this.virtualScrollHandler.scrollSize,
        this.virtualScrollHandler.viewportSize,
        this.virtualScrollHandler.scrollPosition);
      //console.log("STOP",event);
    }
  }

  doUpdate(event:ScrollUpdateEvent) {
    if (!this._dragging) {
      this.update(event.scrollSize,event.viewportSize,event.scrollPosition);
    }
  }

  onScroll(event:ScrollEvent) {
    if (!this._dragging) {
      this.update(event.scrollSize,event.viewportSize,event.scrollPosition);
    }
  }

  onMouseEnter(event:MouseEvent) {
    this.setVisible(true);
  }

  onMouseLeave(event:MouseEvent) {
    this.setVisible(false);
  }

  setVisible(visible:boolean) {
    if (!!this._visibilityTimer) {
      window.clearTimeout(this._visibilityTimer);
    }
    this._visibilityTimer   = undefined;
    this._visibilityTrigger = undefined;
    this.visible$.set(visible);
  }

  triggerVisible() {
    if (!this.visible$() || !!this._visibilityTimer || !!this._visibilityTrigger) {
      this._visibilityTrigger = Date.now();
      if (!this.visible$()) {
        this.visible$.set(true);
        this.changeDetectorRef.markForCheck();
      }
      if (!this._visibilityTimer) {
        const trigger = (timeout:number)=>{
          this._visibilityTimer = window.setTimeout(()=>{
            const now  = Date.now();
            const last = this._visibilityTrigger ?? 0;
            if ((now-last)>800) {
              this._visibilityTimer = undefined;
              this.setVisible(false);
            } else {
              trigger(1_000-(now-last));
            }
          },timeout);
        };
        trigger(1_000);
      }
    }
  }

  update(scrollSize?:number,viewportSize?:number,scrollPosition?:number) {
    this.triggerVisible();
    //console.log("update.scrollSize",scrollSize,"viewportSize",viewportSize,"scrollPosition",scrollPosition,"updatePosition");
    if (!!this.thumbElementRef?.nativeElement) {
      this._elementSize = this.elementRef.nativeElement[this._offsetSizeProperty];
      const show = viewportSize<scrollSize && this._elementSize>24;
      const thumbMin  = Math.min(this._elementSize/2,24);
      const thumbSize = show ? Math.round(Math.max(thumbMin,((this._elementSize-this.startGap-this.endGap)/scrollSize)*viewportSize)) : 0;
      const thumbPosition = show ? this.startGap+(this._elementSize-thumbSize-this.startGap-this.endGap)*(scrollPosition/(scrollSize-viewportSize)) : 0;
      //const currentPosition = this.getComputedPosition();
      if (this._thumbSize != thumbSize) {
        //console.log("thumbSize",this._thumbSize,thumbSize);
        this._thumbSize = thumbSize;
        this.renderer.setStyle(this.thumbElementRef.nativeElement, this._sizeProperty, thumbSize + 'px');
      }
      if (this._thumbPosition != thumbPosition) {
        //console.log("thumbPosition",this._thumbPosition,thumbPosition);
        this._thumbPosition = thumbPosition;
        this.renderer.setStyle(this.thumbElementRef.nativeElement, 'transform', `${this._positionProperty}(${thumbPosition}px)`);
      }
      //console.log("update","thumbSize",thumbSize,"thumbPosition",thumbPosition);
    } else {
      window.setTimeout(()=>this.update(scrollSize,viewportSize,scrollPosition));
    }
  }

  /*
  getComputedPosition(): number {
    const cssStyle = window.getComputedStyle(
      this.thumbElementRef.nativeElement,
      null
    ) as CSSStyleDeclaration;
    const matrix = cssStyle[this._transformProperty].split(')')[0].split(', ');
    const x = -(matrix[12] || matrix[4]); // - (minus) as we internally use opposite direction than translate
    const y = -(matrix[13] || matrix[5]);
    const vertical = this.virtualScrollHandler ? this.virtualScrollHandler.vertical : true;
    return vertical ? y : x;
  }*/
}

