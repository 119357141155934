import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import videojs from 'video.js';
import {BasicContainerComponent} from "shared";
import {ENVIRONMENT, loadScript, Logger, Platform} from "core";
import {Media, MediaType} from "../../store/models";
// TODO: move VideoLink to core or media lib
import {VideoLink} from "properties";
import {Segment, VideoTracker} from "./video-tracker";
import {DOCUMENT} from "@angular/common";
import {TranslateService} from "@ngx-translate/core";
import {MediaService} from "../../service/media.service";
import {SessionTokenService} from "session";

export interface VideoTemplateContext {
  id: string;
  controls: boolean;
}

// https://www.w3.org/2010/05/video/mediaevents.html
// https://gist.github.com/alecsgone/a6db03bade4dc405a61c63294a64f97a

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoComponent extends BasicContainerComponent implements OnChanges {

  @Input()  inline     = true;
  @Input()  autoplay   = false;
  @Input()  controls   = true;
  @Input()  muted      = false;
  @Input()  preload    = 'auto';
  @Input()  tracker    = new VideoTracker();
  @Input()  initialSegments:Segment[] = [];
  protected seconds    = 0;
  protected tracked: any;
  protected _media: Media;
  protected pendingPlay = false;

  @Output() track           = new EventEmitter<Segment[]>();
  @Output() fullscreen      = new EventEmitter<boolean>();
  @Output() userActive      = new EventEmitter<boolean>();
  @Output() playing         = new EventEmitter<boolean>();
  @Output() loadedData      = new EventEmitter<boolean>();
  @Output() loadedMetaData  = new EventEmitter<boolean>();
  @Output() canTakeSnapshot = new EventEmitter<boolean>();

  @ViewChild('videoTemplate') videoTemplate: TemplateRef<VideoTemplateContext>;
  protected video: HTMLVideoElement;

  protected player: videojs.Player;
  protected currentPlayerOptions: any;


  /*
   * NOTE: video element crossorigin='anonymous' attribute is required by Safari when calling HTMLCanvasElement.toBlob() (used for taking snapshots)
   * when current page origin is different than video src origin i.e. in dev mode, vimeo videos, etc.
   * However this means that video requests sent to our server in dev mode will fail because the session cookie will not be sent.
   * In this case we cannot add crossorigin and therefore the snapshot cannot be taken (still the video will play).
   */
  crossOrigin: string;

  protected logger = new Logger('VideoComponent').setSilent(true);

  static youtubeTechLoaded = false;

  constructor(protected platform: Platform,
              protected element: ElementRef,
              protected viewContainerRef: ViewContainerRef,
              protected changeDetector: ChangeDetectorRef,
              protected mediaService: MediaService,
              protected translateService: TranslateService,
              protected sessionTokenService: SessionTokenService,
              protected elementRef: ElementRef,
              @Inject(ENVIRONMENT) protected environment: any,
              @Inject(DOCUMENT) protected document: Document) {
    super();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    // https://docs.videojs.com/docs/guides/removing-players.html
    this.logger.debug('onDestroy', this.player);
    if (this.player) {
      this.player.dispose();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.logger.debug('ngOnChanges', changes.media);
  }

  createVideoJsPlayer(element: any, options?: videojs.PlayerOptions): Promise<videojs.Player> {
    let resolvePlayer: (value: videojs.Player) => void;
    const promise: Promise<videojs.Player> = new Promise(resolve => resolvePlayer = resolve);
    let player = videojs(element, options);

    player.on('timeupdate', (event) => {
      this.logger.debug('player -> timeupdate', event);
      this.update(Math.floor(player.currentTime()));
    });
    player.on('fullscreenchange', () => {
      this.logger.debug('player -> fullscreenchange '+player.isFullscreen());
      this.fullscreen.emit(player.isFullscreen());
    });
    // player controlBar visibility is driven by user active/inactive state
    // https://docs.videojs.com/docs/api/player.html#Eventsuseractive
    player.on('useractive', (event) => {
      this.logger.debug('player -> useractive', event);
      this.userActive.emit(true);
    });
    player.on('userinactive', (event) => {
      this.logger.debug('player -> userinactive', event);
      this.userActive.emit(false);
    });
    player.on('loadeddata', (event) => {
      this.logger.debug('player -> loadeddata', event);
      let seconds  = Math.round(player.currentTime());
      let duration = Math.round(player.duration());
      this.logger.debug("loaded second:"+seconds+" of:"+duration);
      // angular.element(containerElement).removeClass("loading");
      if (this.autoplay || this.pendingPlay) {
        window.setTimeout(() => {
          if (player.paused()) {
            this.
            player.play()
              .catch(error => this.logger.error('autoplay', error))
              .finally(() => this.pendingPlay = false)
          }
        },200);
      }
      this.loadedData.emit(true);
    });
    player.on('loadedmetadata', (event) => {
      this.logger.debug('player -> loadedmetadata', event);
      this.loadedMetaData.emit(true);
    });
    // when video ends it is also inherently paused
    player.on('pause', (event) => {
      this.logger.debug('player -> pause', event);
      this.playing.emit(false);
    });
    player.on('play', (event) => {
      this.logger.debug('player -> play', event);
      this.playing.emit(true);
    });
    resolvePlayer(player);
    return promise;
  }

  playerOptions(withCredentials = false, youtube = false): any { // videojs.PlayerOptions { videojs type defs are not youtube-friendly
    const options: videojs.PlayerOptions  = {
      // Youtube IFrame Player API (managed by videojs youtube tech)
      // autoplay 0/1 while regular html5 video expects these params to be booleans
      // this discrepancy is not handled by the youtube tech so we have to adapt the values here.
      autoplay: youtube ? (this.autoplay ? 1 : 0 as any): this.autoplay,
      bigPlayButton: true,
      responsive: true,
      controlBar: {
        subsCapsButton: false,
        subtitlesButton: false,
        fullscreenToggle: false
      }
    };
    if (this.platform.is('desktop')) {
      (<videojs.ControlBarOptions>options.controlBar).fullscreenToggle = true;
    }
    options['playsinline'] = youtube ? (this.inline ? 1 : 0 as any) : this.inline;
    if (youtube) {
      options.techOrder = ['youtube'];
      options['youtube'] = {
        ytControls: 0,
        hl: this.translateService.currentLang.replace('_','-'),
      };
    }
    // fix for frame jumping when playing hls video on android
    // https://github.com/videojs/videojs-contrib-hls/issues/1434
    // still use native hls safari support as some videos fail to start when
    // the videojs polyfill is used on these platforms

    // if (this.platform.is('ios') ||
    //    (this.platform.is('macos') && this.isSafari)) {
    if (this.platform.isSafari()) {
      // only safari (and edge) have native hls for now
      return options;
    } else {
      options.techOrder = options.techOrder || [];
      options.techOrder.push('html5');
      options.html5 = {
        hls: {
          withCredentials: withCredentials,
          overrideNative: true
        },
        nativeAudioTracks: false,
        nativeVideoTracks: false,
      };
    }
    return options;
  }

  update(seconds: number) {
    this.logger.debug("update:(new:"+seconds+",prev:"+this.seconds+") paused",this.player.paused(),"ended",this.player.ended());
    if (seconds!=this.seconds || this.player.paused() || this.player.ended()) {
      this.seconds = seconds;
      let track = this.player.paused() || this.player.ended();
      if (!this.tracked.duration) {
        this.tracked.duration = true;
        this.tracker.reset(this.player.duration(),this.initialSegments);
      }
      this.tracker.track(seconds);
      if (!this.tracked.open && this.seconds > this.tracker.duration - 5) {
        this.tracked.open = true;
        // $scope.openSidenav('right');
      }
      if (!this.tracked.started) {
        this.tracked.started = track = true;
      }
      if (!this.tracked.ended && this.seconds >= this.tracker.duration - 1) {
        this.tracked.ended = track = true;
      }
      var played = this.tracker.total();
      if (played>=0 && played % 5 == 0 && !this.tracked[played]) {
        this.tracked[played] = track = true;
      }
      this.logger.debug("update:seconds:"+seconds+" track:"+track+"total",this.tracker.total());
      if (track) {
        this.track.emit(this.tracker.segments());
      }
    }
  }

  @Input()
  set media(media: Media) {
    let sources = [];
    let poster = null;
    this.pendingPlay = false;
    if (media) {
      this._media = media;
      const normalizeLink = (link: string): string => {
        link = `${link.startsWith('/') ? `${this.environment.serverUrl}${link}` : link}`;
        const url = new URL(link);
        const hasParameters = url.searchParams.toString().length>0;
        console.log("LINK$1",link, hasParameters);
        link = `${link}${hasParameters ? '&' : '?'}ngsw-bypass`;
        console.log("LINK$2",link, hasParameters);
        return this.sessionTokenService.rewrite(link);
      };

      if (media.mediaType == MediaType.video) {
        let links: VideoLink[] = media.links;
        let hlsLink = null, fallbackLink = null, youtubeLink = null;
        for (let i=links.length-1; i>=0; i--) {
          let link: VideoLink = links[i] || {};
          if (link.link_secure || link.link) {
            // for youtube videos we use x- prefix to indicate its non-standard.
            // see RFC2045 - https://tools.ietf.org/html/rfc2045
            if (!youtubeLink && link.contentType.toLowerCase() == 'video/x-youtube') {
              /*
              try {
                let url = new URL(link.link);
                let searchParams = url.searchParams;
                // https://developers.google.com/youtube/player_parameters#playsinline
                searchParams.set('playsinline', '1');
                searchParams.set('autoplay', '1');
                youtube = {...link, link: url.href};
              } catch (e) {
                this.logger.error('Failed to set youtube player parameters', e);
                youtube = link;
              }
              */
              youtubeLink = link;
            } else if (!hlsLink && link.quality=='hls') {
              hlsLink = link;
            } else if (!fallbackLink || (link.quality=='sd' && fallbackLink.quality!=link.quality) || fallbackLink.width < link.width) {
              fallbackLink = link;
            }
          }
        }
        // sources.push({src: 'https://www.youtube.com/watch?v=xjS6SftYQaQ', type: 'video/youtube'});
        if (youtubeLink) { sources.push({src: youtubeLink.link, type: 'video/youtube'}); }
        if (hlsLink) { sources.push({src: normalizeLink(hlsLink.link_secure || hlsLink.link), type: 'application/x-mpegURL'}); }
        if (fallbackLink) { sources.push({src: normalizeLink(fallbackLink.link_secure || fallbackLink.link), type: fallbackLink.contentType}); }
      } else if (media.mediaType == MediaType.audio) {
        const   mediaLink = media.links[0];
        const contentType = mediaLink.contentType;
        sources.push({src: normalizeLink(`${mediaLink.link}?contentType=${encodeURIComponent(contentType)}`), type: contentType});
      }
      // poster = get(media, 'cover.link');
      // if ((poster || '').startsWith('/')) {
      //   poster = `${this.environment.serverUrl}${poster}`;
      // }
      poster = this.mediaService.getMediaCoverSrc(media);
    }
    this.seconds = 0;
    this.tracked = {};

    if (sources.length > 0) {
      const youtube = sources[0].type == 'video/youtube';
      const loadYoutubeTechIfRequired: () => Promise<boolean> = () => {
        if (youtube && !VideoComponent.youtubeTechLoaded) {
          // return import('/assets/Youtube.js'); // not possible to import Youtube.js as it is not an es module
          (window as any)['videojs'] = videojs; // expose module scoped videojs to youtube tech lib
          return loadScript(this.document, '/assets/Youtube.js').then(() => {
            VideoComponent.youtubeTechLoaded = true;
            return true;
          });
        } else {
          return Promise.resolve(false);
        }
      };
      const withCredentials = !!sources.find(source => source.src.startsWith(this.environment.serverUrl));
      const crossOrigin     = /*(this.platform.is('ios') ||
                               this.platform.is('macos')) &&
                               this.isSafari && */
                              this.platform.isSafari() &&
                              !withCredentials ? 'anonymous' : null;
      const canTakeSnapshot = !this.mediaService.isYoutubeMedia(this.media) && // plays in iframe served from a different origin -> snapshot not possible
                              !this.platform.is('ios') &&         // ios safari cannot take the blob (toBlob() callback called with null see takeSnapshot()) out from the drawing canvas
                             (!this.platform.isSafari() /*this.isSafari*/ || crossOrigin=='anonymous');     // desktop safari needs crossorigin property on video elem if content is served from a different origin than the page but then we cannot authenticate with cookies as they are not sent
      loadYoutubeTechIfRequired()
        .then((configYoutubeTech) => {
          const playerOptions = this.playerOptions(withCredentials, youtube);
          const compatible: boolean = this.isPlayerCompatible(playerOptions, this.currentPlayerOptions);
          if (!this.player || !compatible || this.crossOrigin != crossOrigin) {
            // https://github.com/videojs/video.js/issues/4397
            if (this.player) {
              this.player.dispose();
              this.viewContainerRef.clear();
            }
            const embeddedViewRef = this.viewContainerRef.createEmbeddedView(
              this.videoTemplate,
              { id: `${this.instanceId}`, controls: this.controls }
            );
            this.crossOrigin = crossOrigin;
            this.changeDetector.markForCheck();
            return new Promise<void>((resolve, reject) => {
              window.setTimeout(() => { resolve() }, 0)
            }).then(() => {
                playerOptions.sources = sources;
                /* setting the poster in options does not set the poster property on the video element itself.
                 * What is does is to set it as background of a div element in videojs dom structure.
                 * However this div element is shown only when video/audio is not playing.
                 * When we have audio media we want the cover to be always displayed (even when playing)
                 * Using videojs player() api to set the poster solves the problem because
                 * in this case the video element poster property is also set.
                 */
                // options.poster  = poster;

                // this.logger.debug('VIDEOJS PLAYER OPTIONS', playerOptions);

                // return this.createVideoJsPlayer(embeddedViewRef.rootNodes[0], options)
                // search for video element because at least on ios production build it is not always the first node
                const videoNode = this.video = embeddedViewRef.rootNodes.find(node => 'video'==node.nodeName.toLowerCase());
                return this.createVideoJsPlayer(videoNode, this.currentPlayerOptions = playerOptions)
                  .then((player) => {
                    player.poster(poster);
                    if (youtube) {
                      // show poster on end to avoid youtube video suggestions
                      // player.on('ended', () => {
                      //   this.logger.debug('VIDEO ENDED!');
                      //   player.hasStarted(false);
                      // });
                    }
                    this.player = player;
                    return player;
                  });
            })
          } else {
            this.player.pause();
            this.player.src(sources);
            this.player.poster(poster);
            return this.player;
          }
        }).then((player) => {
          // player is ready - notify listeners if snapshots are possible
          this.canTakeSnapshot.emit(canTakeSnapshot);
        })
    }
  }

  isFullscreen() : boolean {
    return this.player && this.player.isFullscreen();
  }

  pause() {
    this.player && !!this.player.pause();
  }

  play(muted = false) {
    if (this.player) {
      // https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error
      const playing =
         this.player.currentTime() > 0 &&
        !this.player.paused() &&
        !this.player.ended()  &&
         this.player.readyState() == videojs.ReadyState?.HaveCurrentData;
      if (!playing) {
        if (muted) {
          this.player.volume(0);
        }
        this.player.play().catch(error => this.logger.error(error));
      }
    } else {
      this.pendingPlay = true;
    }
  }

  currentTime(time?:number):number {
    if (time!==undefined && time>=0) {
      this.player?.player().currentTime(time);
    }
    return this.player?.player().currentTime();
  }

  paused() : boolean {
    return this.player && this.player.paused();
  }

  ended() : boolean {
    return this.player && this.player.ended();
  }

  isPlayerCompatible(requiredPlayerOptions: any, currentPlayerOptions): boolean {
    return false; //isEqual(requiredPlayerOptions, currentPlayerOptions); // todo: if used later - omit sources in comparison!
  }

  // get isSafari(): boolean {
  //   const   userAgent = navigator.userAgent || '';
  //   return  userAgent.indexOf('Safari') != -1 &&
  //           userAgent.indexOf('Chrome') == -1;
  // }

  // takeSnapshot(): Promise<File> {
  takeSnapshot(): Promise<string> {
    if (this.video) {
      // ATTENTION: Problem on ios:
      // Identical code for taking blob out of image element works properly on ios safari (both normal browser and cordova webview)
      // but when trying to do the same for video element the blob provided by canvas.toBlob() is null.
      // We haven't found any workaround for this issue and for now the snapshots are disabled for when running on ios.
      const canvas = document.createElement('canvas');
      canvas.width  = this.video.videoWidth;
      canvas.height = this.video.videoHeight;
      canvas.getContext('2d').drawImage(this.video, 0, 0, canvas.width, canvas.height);
      // return new Promise<File>((resolve, reject) => {
      return new Promise<string>((resolve, reject) => {
        canvas.toBlob((blob) => {
          this.logger.debug('takeSnapshot -> blob', blob);
          if (blob) {
            // NOTE: If we resolve the promise with the File object created here
            // then in cordova apps it cannot be converted later in MediaViewerComponent to base64 (which will be passed to the cropper)
            // The reason for this is unclear! We still can convert to base64 directly here.
            // const file = new File(
            //   [ blob ],
            //   `snapshot_${this.media?.id}.png`, // image/png is the default output type (https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob)
            //   { lastModified: new Date().getTime() }
            // );
            // resolve(file);
            const toBase64 = file => new Promise<string>((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadstart  = (event) => this.logger.debug('takeSnapshot -> base64 onloadstart', event);
              reader.onprogress   = (event) => this.logger.debug('takeSnapshot -> base64 onloadprogress', event);
              reader.onabort      = (event) => this.logger.debug('takeSnapshot -> base64 onabort', event);
              reader.onloadend    = (event) => resolve(reader.result as string);
              reader.onerror      = (error) => reject(error);
              reader.readAsDataURL(file);
            });
            toBase64(blob).then((base64) => {
               this.logger.debug('takeSnapshot -> base64, blob', base64);
               resolve(base64);
            }).catch((error) => {
              this.logger.error('takeSnapshot -> base64, blob', error);
              reject(error);
            });
          } else {
            reject('Snapshot blob is empty');
          }
        });
      });
    } else {
      return Promise.reject();
    }
  }
}
