import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map, Observable, takeUntil } from 'rxjs';
import values from "lodash/values";
import mapValues from "lodash/mapValues";
import { Country } from 'shared';
import { MediaDetailsSectionComponent } from '../media-details/media-details-section.component';
import { MediaDetailsShareDataService } from '../../service/media-details-share-data.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'media-details-country-filter',
  templateUrl: './media-details-country-filter.component.html',
  styleUrls: ['./media-details-country-filter.component.scss']
})
export class MediaDetailsCountryFilterComponent extends MediaDetailsSectionComponent {

  countries: Observable<Country[]>;
  mediaForm: FormGroup;

  constructor(
    private translateService: TranslateService,
    protected mediaDetailsShareDataService: MediaDetailsShareDataService
  ) {
    super(mediaDetailsShareDataService);
  }

  ngOnInit(): void {
    combineLatest([
      this.mediaDetailsShareDataService.getMedia$,
      this.mediaDetailsShareDataService.getMediaForm$,
      this.mediaDetailsShareDataService.getEditMode$,
      this.mediaDetailsShareDataService.getOptions$
    ]).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(([media, mediaForm, editMode, options]) => {
      this.media = media;
      this.mediaForm = mediaForm;
      this.editMode = editMode;

      if (!this.media) {
        this._isHidden.emit(true);
      } else {
        if ((options.mode != 'basic' && this.editMode) || (!this.editMode && this.media.countryCodes && this.media.countryCodes.length > 0)) {
          this._isHidden.emit(false);
        } else {
          this._isHidden.emit(true);
        }
      }
    });

    this.countries = this.translateService
      .stream('countries')
      .pipe(
        map((result) => {
          const language = this.translateService.currentLang;
          return values(mapValues(result, (value, key) => { return { code: key, name: value } }))
            .sort((c1, c2) => c1.name.localeCompare(c2.name, language));
        })
      );
  }

}
