/* projects/shared/src/lib/components/qr-code/QrCodeAllModule/qrCodeCreate.component.scss */
:host {
  position: relative;
}
:host > div {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  height: 0;
}
:host > div > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=qrCodeCreate.component-3LADXOPU.css.map */
