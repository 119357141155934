import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "material";
import {TranslateModule} from "@ngx-translate/core";
import {SessionListComponent} from './components/session-list/session-list.component';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {SessionEffects} from "./store/effects";
import {sessionReducer} from "./store/reducers";
import {nullSession, NullSession, Session} from "./store/models";
import {SharedModule} from "shared";
import {ScrollingModule} from "@angular/cdk/scrolling";

export function nullSessionFactory(): Session {
  return new NullSession();
}

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    ScrollingModule,
    StoreModule.forFeature('session', sessionReducer),
    EffectsModule.forFeature([SessionEffects])
  ],
  declarations: [
    SessionListComponent
  ],
  exports: [
    SessionListComponent
  ],
  providers: [
    { provide: nullSession, useFactory: nullSessionFactory }
  ]
})
export class SessionModule {}
