import { Contact } from './contact';

export class Translation {
  path: string;
  locale: string;
  source_locale?: string;
  author?: Contact;
  text?: string;
  description?: string;
  visible?: boolean;
  modified: number;

  constructor(init?: Partial<Translation>) {
    Object.assign(this, init);
  }
}


