import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Injectable({providedIn: "root"})
@Pipe({name: 'phoneNumberFormat'})
export class PhoneNumberFormatPipe implements PipeTransform {

  transform(phoneNumber: any, args?: any): any {
    let {callingCode, countryCode} = args;
    phoneNumber = phoneNumber.trim();
    if (phoneNumber.startsWith('+') || callingCode=='?') {
      return phoneNumber;
    } else if (phoneNumber.startsWith('00')) {
      return '(+'+callingCode+')'+phoneNumber.substring(2);
    } else if (phoneNumber.startsWith('0')) {
      return '(+'+callingCode+')'+phoneNumber.substring(1);
    }
    return '(+'+callingCode+')'+phoneNumber;
  }
}
