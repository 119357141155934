import {Action, createFeatureSelector, createReducer, createSelector, on, select} from "@ngrx/store";
import {uploadUpdate} from "./actions";
import {pipe} from "rxjs";
import {map} from "rxjs/operators";
import {UploadStates, UploadState} from "./models";
import clone from 'lodash/clone';

export const uploadFeatureKey = 'upload';

export const initialUploadStates : UploadStates = {};

export const initialUploadState: UploadState = {
  plugins: {},
  files: {},
  currentUploads: {},
  capabilities: {
    resumableUploads: false
  },
  totalProgress: 0,
  meta: {},
  info: {
    isHidden: true,
    type: 'info',
    message: '',
    details: ''
  }
};

/*
 Sample upload state ("video" is Uppy id specified when the Uppy uploader is created):
{
  "video": {
    "plugins": {},
    "files": {
      "uppy-gopr2467/mp4-1e-video/mp4-191138223-1537598131000": {
        "source": "uploadFile",
        "id": "uppy-gopr2467/mp4-1e-video/mp4-191138223-1537598131000",
        "name": "GOPR2467.MP4",
        "extension": "MP4",
        "meta": {
          "name": "GOPR2467.MP4",
          "type": "video/mp4"
        },
        "type": "video/mp4",
        "data": {},
        "progress": {
          "uploadStarted": 1586964976312,
          "uploadComplete": false,
          "percentage": 28,
          "bytesUploaded": 53510144,
          "bytesTotal": 191138223
        },
        "size": 191138223,
        "isRemote": false,
        "remote": "",
        "tus": {
          "uploadUrl": "https://mb.goodnews.social/files/0106cd8738de824fdb5d46d161566a29"
        },
        "isPaused": false
      }
    },
    "currentUploads": {
      "ck91hz0kw00013h9mnzzsc6u2": {
        "fileIDs": [
          "uppy-gopr2467/mp4-1e-video/mp4-191138223-1537598131000"
        ],
        "step": 0,
        "result": {}
      }
    },
    "allowNewUpload": true,
    "capabilities": {
      "uploadProgress": true,
      "individualCancellation": true,
      "resumableUploads": true
    },
    "totalProgress": 28,
    "meta": {},
    "info": {
      "isHidden": true,
      "type": "info",
      "message": ""
    },
    "error": null
  }
}
*/

interface State {
  readonly [uploadFeatureKey]: UploadStates;
}

export const reducer = createReducer(
  initialUploadStates,
  on(uploadUpdate, (state, {payload}) => {
    const nextState = {
      ...state ,
      ...{ [payload.id]: {...state[payload.id] || {}, ...payload.patch }}
    };
    console.debug('NEXT STATE', state, payload, nextState);
    return nextState;
  })
);

/* The exported uploadReducer function is necessary as function calls are not supported by the AOT compiler. */
export function uploadReducer(state: UploadStates | undefined, action: Action) {
  return reducer(state, action);
}

export const selectUploadStates = createFeatureSelector<State, UploadStates>(uploadFeatureKey);

export const selectUploadState = createSelector(
  selectUploadStates,
  (states: UploadStates, id?: string): UploadState => {
    return states[id] || clone(initialUploadState);
  }
);

export const selectUploadFiles = (id?: string) => pipe(
  select(selectUploadState, id),
  map(state => state && state.files)
);

export const selectCurrentUploads = (id?: string) => pipe(
  select(selectUploadState, id),
  map(state => state && state.currentUploads)
);
