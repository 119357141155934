import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PropertiesService} from "properties";
import {combineLatest, Observable, Subject, Subscription} from "rxjs";
import {map, startWith, take, takeUntil} from "rxjs/operators";
import reduce from "lodash/reduce";
import {getMediaDuration, Media, MediaAction, MediaType} from "../../../../store/models";
import {MediaService} from "../../../../service/media.service";
import {VideoComponent} from "../../../video/video.component";
import {Segment, VideoTracker} from "../../../video/video-tracker";
import {MediaViewer} from "../../media-viewer";
import {MediaPlayer} from "../../media-player";
import {Logger} from "core";

@Component({
  selector: 'video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoViewerComponent extends MediaViewer implements MediaPlayer {

  @Input()  autoplay = false;
  @Output() completed = new EventEmitter();
  @ViewChild('videoPlayer', { static: true }) videoPlayer: VideoComponent;

  tracker = new VideoTracker();
  trackerPlayedSubscription: Subscription = null;
  trackerCurrentTimeSubscription: Subscription = null;
  segments: Segment[];

  forceDisplayActions = true;
  canTakeSnapshot$ = new Subject<boolean>();

  protected logger = new Logger('VideoViewerComponent').setSilent(true);
  protected loggerActions = new Logger('VideoViewerComponent.actions');

  constructor(private http: HttpClient,
              private changeDetector: ChangeDetectorRef,
              private propertiesService: PropertiesService,
              private mediaService : MediaService,
              private elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    combineLatest([
      this.videoPlayer.userActive,
      this.videoPlayer.playing,
      this.defaultActions
    ]).pipe(
      takeUntil(this.onDestroy$),
    )
    .subscribe(([userActive, playing, defaultActions]: [boolean, boolean, MediaAction[]]) => {
      this.forceDisplayActions = userActive || !playing;
      defaultActions.concat(this.media.actions || []).forEach((action) => {
        this.loggerActions.debug('DISPLAY ACTION', {
          action,
          mediaId: this.media.id,
          name: this.media.name,
          display: this.forceDisplayActions,
          userActive,
          playing
        });
        this.displayAction(action.id, this.forceDisplayActions);
      });
      // this.changeDetector.markForCheck();
      if (!playing) {
        const playedDuration = reduce(
          this.segments,
          (total: number, segment: Segment) => total + segment.end - segment.start,
          0
        );
        const duration = Math.max(getMediaDuration(this.media), playedDuration);
        if (duration > 0) {
          const playedPercentage = playedDuration / duration * 100;
          if (playedPercentage >= 80) {
            this.completed.emit();
          }
        }
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.trackerPlayedSubscription && this.trackerPlayedSubscription.unsubscribe();
    this.trackerCurrentTimeSubscription && this.trackerCurrentTimeSubscription.unsubscribe();
  }

  setMedia(media: Media): Promise<Media> {
    return new Promise<Media>((resolve, reject) => {
      this.propertiesService.properties$.pipe(
        takeUntil(this.onDestroy$),
        map(properties => properties && properties.user ? properties.user.id : null),
        take(1)
      ).subscribe( userId => {
        if (media && media.mediaType === MediaType.video || media.mediaType === MediaType.audio) {
          // create or get a training task which will be later tagged with each segment tracked during video play
          let duration = getMediaDuration(media);
          // this.http.post("/v1.0/tasks/contact/"+userId, {
          //   type: 'training',
          //   category_id: null, //$scope.videoFilter.length>0 ? $scope.videoFilter[0] : null,
          //   resource_id: media.id,
          //   resource_name: media.name,
          //   duration: duration
          // }).subscribe((response: any) => {
          //   //this.logger.debug("task result "+JSON.stringify(response.data));
          //   if (response && response.done && response.task) {
          //     this.task = response.task;
              this.segments = [];
              this._media = media;
              this.segments = this.tracker.parseSegments(media.played,duration);
              this.trackerPlayedSubscription && this.trackerPlayedSubscription.unsubscribe();
              this.trackerPlayedSubscription = this.tracker.played$.pipe(
                takeUntil(this.onDestroy$)
              ).subscribe(seconds => {
                // on switching media, null is sent as second, so we unsubscribe...
                if (!media.readOnly) {
                  media.played     = this.tracker.toString();
                  media.playedTime = seconds;
                  media.touched    = duration<=0 ? true : media.playedTime>=(Math.min(10,(duration/3)));
                  media.completed  = (duration<=0 ? true :
                                      media.playedTime<=0 ? false :
                                      duration<=1 ? media.playedTime>0 :
                                      duration<100 ?	// 90% on short videos, else 99%
                                            (media.playedTime/(duration-1))>=0.90 :
                                            (media.playedTime/(duration-1))>=0.99);
                  let status:any = {
                    played:     media.played,
                    playedTime: media.playedTime,
                    touched:    media.touched,
                    completed:  media.completed
                  };
                  this.logger.debug('PLAYED', media.id, status);
                  this.mediaService.played(media.id,status);
                }
              });
              this.trackerCurrentTimeSubscription && this.trackerCurrentTimeSubscription.unsubscribe();
              if (media.actions?.length > 0) {
                this.trackerCurrentTimeSubscription = this.tracker.currentTime$.pipe(
                  takeUntil(this.onDestroy$),
                ).subscribe(currentTime => {
                  const duration = getMediaDuration(media);
                  media.actions.forEach((action) => {
                    const display = !action.from ? undefined :  // from not specified -> apply common logic
                      Math.max(0, Math.min(action.from, duration))     <= currentTime &&
                      Math.min(duration, Math.max(0, action.to || 0))  >= currentTime;
                    this.displayAction(action.id, this.forceDisplayActions || display);
                  });
                });
              } else if (this.trackerCurrentTimeSubscription) {
                this.trackerCurrentTimeSubscription = null;
              }
              // this.videoPlayer.play();
              this.changeDetector.markForCheck();
              resolve(media);
            } else {
              reject(new Error("Invalid media"));
            }
          // },function(error) {
          //   this.logger.error("Task save ERROR", error);
          //   reject(error);
          // });
        // } else {
        //   resolve(media);
        // }
      });
    });
  }

  onTrackMedia(segments: Segment[]): Promise<void> {
    // this.logger.debug('SEGMENTS', segments.toString());
    this.logger.debug("update:onTrackMedia",segments,"this:",this.segments.toString(),"other:",segments.toString());
    if ((!this.media.readOnly || !!this.media.readOnlyReact) &&
         this.segments.toString()!=segments.toString()) {
      //this.logger.debug("onTrackMedia !equal",[...this.segments],[...segments]);
      this.segments = this.tracker.clone(segments);
      return new Promise<void>((resolve,reject)=> {
        // do the actual tracking by tagging the current task (the one created on media selection change)
        // this.http.post("/v1.0/tasks/tag/" + this.task.id + "/started/add", {
        this.http.post(`/v1.0/media/reaction/${this.media.id}`, {
          played: segments.toString()
        }).subscribe({
          next: (response: Media) => {
            console.log("RESULT", response);
            if (response.type == 'media') {
              this.mediaService.syncMedia(response);
            }
            resolve(void 0);
          },
          error: (error) => {
            //this.logger.error("Task Tag ERROR", error);
            reject(error);
          }
        });
      });
    } else {
      return Promise.resolve();
    }
  }

  beforeClose(): Promise<void> {
    return this.onTrackMedia(this.tracker.segments());
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.code == 'Space') {
      this.logger.debug("ended:"+this.videoPlayer.ended());
      if (!this.videoPlayer.ended()) {
        this.logger.debug("ended:"+this.videoPlayer.paused());
        if (this.videoPlayer.paused()) {
          this.videoPlayer.play();
        } else {
          this.videoPlayer.pause();
        }
      }
    }
  }

  paused(): boolean {
    return this.videoPlayer.paused();
  }

  pause() {
    this.videoPlayer.pause();
  }

  play() {
    this.videoPlayer.play();
  }

  get playing() {
    return !this.videoPlayer.paused();
  }

  isControlEvent(event: Event): boolean {
    if (event instanceof PointerEvent) {
      if (event.type=='pointerdown') {
        const target = event.target as any;
        const isControl = (element) => {
          const selector = '.vjs-volume-panel, .vjs-progress-control';
          let match = false;
          while (element && !(match ||
                 element.className?.includes('vjs-control-bar') ||
                 element == this.elementRef.nativeElement)) {
            match = (element.matches || element.matchesSelector)?.call(element, selector);
            element = element.parentElement;
          }
          return match;
        }
        return isControl(target);
      }
    }
    return false;
  }

  get canTakeSnapshot(): Observable<boolean> {
    return this.canTakeSnapshot$.asObservable();
  }

  // takeSnapshot(): Promise<File> {
  takeSnapshot(): Promise<string> {
    return this.videoPlayer.takeSnapshot();
  }
}
