import {DragEvent} from "../../models/touch/drag-event";
import {ScrollRequestEvent, ScrollUpdateEvent, SubscribableValue, VirtualScrollHandler} from "./virtual-scroll.handler";
import {ElementRef, EventEmitter, NgZone, Renderer2} from "@angular/core";
import * as tween from '@tweenjs/tween.js'
import {isValidNumber} from "../../util/number.util";

export class StandardScrollHandler extends VirtualScrollHandler {

  protected _scrollPositionProperty = 'scrollTop';
  protected _containerSizeProperty  = 'height';

  protected _viewportElement:Element;
  protected _scrollContainerElement:Element;
  protected _currentTween: tween.Tween<any>;

  constructor(elementRef:ElementRef,protected readonly renderer: Renderer2, protected readonly zone: NgZone) {
    super(elementRef,renderer);
  }

  public attachToDragEventEmitter(dragEventEmitter:EventEmitter<DragEvent>) {
    //console.log("attachToDragEventEmitter");
    this._viewportElement = this.elementRef.nativeElement.firstElementChild;
    this._scrollContainerElement = this._viewportElement.firstElementChild;
    super.attachToDragEventEmitter(dragEventEmitter);
    const viewportElement = this.elementRef.nativeElement.firstElementChild;
    if (!this.vertical) {
      this._scrollPositionProperty = 'scrollLeft';
      this._containerSizeProperty  = 'width';
      viewportElement.style.overflowX = 'auto';
      viewportElement.style.overflowY = 'hidden';
    } else {
      viewportElement.style.overflowX = 'hidden';
      viewportElement.style.overflowY = 'auto';
    }
    this.doUpdate.subscribe((event)=>this.handleUpdate(event));
    this.doScroll.subscribe((event)=>this.handleScroll(event));
    this.renderer.listen(this._viewportElement, 'scroll', event=> {
      const scrollPosition = this._viewportElement[this._scrollPositionProperty] ?? 0;
      //console.log("scroll.event",scrollPosition);
      if (this.scrollToPosition!=scrollPosition) {
        this.scrollPosition  = scrollPosition;
        //console.log("SCROLL",event);
        this.onScroll.emit({
          viewportSize:this.viewportSize,
          scrollSize:this.scrollSize,
          scrollPosition:scrollPosition,
          vertical:this.vertical
        });
      }
    });
  }

  afterRender() { // called directly after rendering, but before adjustments done!
    this._viewportElement[this._scrollPositionProperty] = this.scrollPosition;
    //console.log("viewportUpdated.scrollTop.1",this.viewportElementRef.nativeElement['scrollTop'],this.virtualScrollHandler.scrollPosition);
    //this.viewportElementRef.nativeElement['scrollTop'] = this.virtualScrollHandler.scrollPosition;
    //console.log("viewportUpdated.scrollTop.2",this.viewportElementRef.nativeElement['scrollTop'],this.virtualScrollHandler.scrollPosition);
  }

  protected handleUpdate(event:ScrollUpdateEvent) {
    //console.log("handleUpdate",event);
    this.renderer.setStyle(this._scrollContainerElement, this._containerSizeProperty, `${event.scrollSize}px`);
    this._viewportElement[this._scrollPositionProperty] = event.scrollPosition;
  }

  protected handleScroll(event:ScrollRequestEvent) {
    //console.log("handleScroll",event);
    if (event.time<=0) {
      this._viewportElement[this._scrollPositionProperty] = event.scrollPosition$.value;
      event.scrollCompletedCallback?.();
    } else {
      this.scrollToPosition(event.scrollPosition$,event.time,event.scrollCompletedCallback);
    }
  }

  resetPosition(bounceTime:number = this.bounceTime) {
    const startPosition = this.getComputedPosition();
    const endPosition = Math.max(0, Math.min(startPosition, Math.max(0,this.scrollSize-this.viewportSize)));
    if (startPosition!=endPosition) {
      stop();
      requestAnimationFrame(()=>this.scrollTo(endPosition,bounceTime));
    }
  }

  getComputedPosition(): number {
    return this._viewportElement[this._scrollPositionProperty];
  }

  onAttach() {
    if (!!this._viewportElement) {
      this._viewportElement[this._scrollPositionProperty] = this.scrollPosition;
    }
  }

  public scrollToPosition(scrollPosition$:SubscribableValue<number>, animationMilliseconds: number, scrollCompletedCallback:()=>void=undefined): void {
    //console.log("scrollToPosition",scrollPosition,"animationMilliseconds",animationMilliseconds);
    if (animationMilliseconds==0) {
      this._viewportElement[this._scrollPositionProperty] = scrollPosition$.value;
      scrollCompletedCallback && scrollCompletedCallback();
      return;
    }
    let animationRequest: number;
    if (this._currentTween) {
      this._currentTween.stop();
      this._currentTween = undefined;
    }
    const scrollPosition = scrollPosition$.value;
    const tweenConfigObj = { scrollPosition: this._viewportElement[this._scrollPositionProperty] };
    let newTween = new tween.Tween(tweenConfigObj)
      .to({ scrollPosition }, animationMilliseconds)
      .easing(tween.Easing.Quadratic.Out)
      .onUpdate((data) => {
        if (!isValidNumber(data.scrollPosition)) {
          return;
        }
        this.renderer.setProperty(this._viewportElement, this._scrollPositionProperty, data.scrollPosition);
        //this.refresh_internal(false, false, true);
      })
      .onStop(() => {
        cancelAnimationFrame(animationRequest);
      })
      .start();

    const animate = (time?: number) => {
      if (!newTween["isPlaying"]()) {
        scrollCompletedCallback?.();
        return;
      }
      newTween.update(time);
      if (tweenConfigObj.scrollPosition === scrollPosition) {
        //this.refresh_internal(false, false, true, {pass:2,refreshCompleted:new Future(animationCompletedCallback)});
        scrollCompletedCallback?.();
        return;
      }
      this.zone.runOutsideAngular(() => {
        animationRequest = requestAnimationFrame(animate);
      });
    };
    animate();
    this._currentTween = newTween;
  }
}
