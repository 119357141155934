import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatSidenav, MatSidenavContainer} from "@angular/material/sidenav";
import {Platform} from "core";
import {initialLayoutState, LayoutService, SidenavMode, SidenavState} from "layout";
import {concatMap, delay, filter, map, takeUntil} from "rxjs/operators";
import {BehaviorSubject, combineLatest, fromEvent, Observable, of, OperatorFunction, Subject} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from "@angular/router";
import isEqual from "lodash/isEqual";
import {ScreenOrientation} from "@ionic-native/screen-orientation/ngx";
import {BasicContainerComponent} from "../basic-container/basic-container.component";
import {ResizeEvent} from "../../directives/resize/resize.event";
import {ResizeService} from "../../directives/resize/resize.service";

@Component({
  selector: 'basic-main-container',
  templateUrl: './basic-main-container.component.html',
  styleUrls: ['./basic-main-container.component.scss']
})
export class BasicMainContainerComponent extends BasicContainerComponent {

  @ViewChild('navigationSidenav')
  navigationSidenav: MatSidenav;
  @ViewChild('detailsSidenav')
  detailsSidenav: MatSidenav;
  @ViewChild('container')
  container: MatSidenavContainer;
  @ViewChild('content', {read: ElementRef})
  contentElementRef: ElementRef;

  navigationState$: Observable<SidenavState>;
  detailsState$: Observable<SidenavState>;

  protected navigationAnimation$ = new BehaviorSubject<boolean>(false);
  protected navigationLockWidth : number = undefined;
  protected detailsAnimation$ = new BehaviorSubject<boolean>(false);
  protected detailsLockWidth : number = undefined;
  protected activatedRoute$ : BehaviorSubject<ActivatedRoute>;
  //protected initialLayout : boolean = true;

  protected routeDetails : { [key: string]: SidenavState } = {};
  protected routeUrl : string;
  protected detailsGroup: string;

  constructor(
    protected elementRef: ElementRef,
    protected layoutService: LayoutService,
    protected screenOrientation: ScreenOrientation,
    protected resizeService : ResizeService,
    protected platform: Platform,
    protected router: Router) {
    super();
    this.navigationState$ = layoutService.navigation.state$;
    this.detailsState$    = layoutService.details.state$;
  }

  protected registerAnimationTracking(sidenav:MatSidenav,animation$:Subject<boolean>) : void {
    sidenav.closedStart.pipe(takeUntil(this.onDestroy$)).subscribe(() => animation$.next(true));
    sidenav.openedStart.pipe(takeUntil(this.onDestroy$)).subscribe(() => animation$.next(true));
    sidenav.openedChange.pipe(takeUntil(this.onDestroy$)).subscribe(() => animation$.next(false));
    animation$.pipe(takeUntil(this.onDestroy$)).subscribe(() => window.dispatchEvent(new Event('resize')));
  }

  getActivatedRoute() : ActivatedRoute {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  ngOnAttach(): void {
    //console.debug("NAV.ngOnAttach()");
    super.ngOnAttach();
  }

  ngOnDetach(): void {
    this.layoutService.navigation.update(initialLayoutState.navigation);
    this.layoutService.details.update(initialLayoutState.details);
    //console.debug("NAV.ngOnDetach()");
    super.ngOnDetach();
  }

  ngAfterViewInit() : void {
    //console.debug("NAV.ngAfterViewInit() 0");
    super.ngAfterViewInit();
    //console.debug("NAV.ngAfterViewInit() 1");
    /*

    Remove this code as it causes initial layout disorders while its purpose is not clear!!!

    this.layoutService.navigation.state$.pipe(take(1)).subscribe(state => {
      //console.debug("NAV.ngAfterViewInit() 2");
      let nav : any = this.navigationSidenav;
      let ctn : any = this.container;
      //console.debug("NAV1._enableAnimations:"+nav._enableAnimations+" nav.opened:"+this.navigationSidenav.opened+" state.opened:"+state.open);
      ctn._element.nativeElement.classList.add('mat-drawer-transition');
      ctn._element.nativeElement.classList.add('block');
      let previousFunction = nav.ngAfterContentChecked;
      nav.ngAfterContentChecked = function() {};
      nav.opened = state.open;
      nav._animationState = 'void';
      nav._enableAnimations = false;
      //console.debug("NAV2._enableAnimations:"+nav._enableAnimations+" opened:"+this.navigationSidenav.opened+" state.opened:"+state.open);
      window.setTimeout(()=> {
        ctn._element.nativeElement.classList.remove('block');
        nav.ngAfterContentChecked = previousFunction;
        nav._enableAnimations = true;
      },500);
    });
     */
    //console.debug("NAV.ngAfterViewInit() 3");
    this.activatedRoute$ = new BehaviorSubject<ActivatedRoute>(this.getActivatedRoute());
    this.router.events.pipe(
      takeUntil(this.onDestroy$),
      filter(event => event instanceof NavigationEnd),
      map(event => this.getActivatedRoute())
    ).subscribe(route => this.activatedRoute$.next(route));
    let start = Date.now();
    this.registerAnimationTracking(this.navigationSidenav,this.navigationAnimation$);
    this.registerAnimationTracking(this.detailsSidenav,this.detailsAnimation$);

    // calling onUpdate when the combined observable emits can trigger a change in layout state
    // if the component template uses the layout state properties to render its content an exception will be thrown:
    // ExpressionChangedAfterItHasBeenCheckedError as we modify some data used in the template during change detection cycle
    // to overcome this issue we pipe the values trough delayFirst operator function
    combineLatest([
        this.activatedRoute$,
        this.onResize$,
        this.navigationState$,
        this.navigationAnimation$,
        this.detailsState$,
        this.detailsAnimation$,
        new Observable<string>(subscriber => {
          if (this.platform.is('hybrid')) {
            fromEvent(window, 'orientationchange')
            // this.screenOrientation.onChange()  // does not work (see https://github.com/apache/cordova-plugin-screen-orientation/issues/54)
              .pipe(takeUntil(this.onDestroy$))
              .subscribe(
                () => subscriber.next(this.screenOrientation.type),
                (error) => subscriber.error(error),
                () => subscriber.complete()
              );
            subscriber.next(this.screenOrientation.type);
          } else {
            subscriber.next(undefined);
            subscriber.complete();
          }
        })
      ])
      .pipe(
        takeUntil(this.onDestroy$),
        this.delayFirst()
      )
      .subscribe((
        [route, resizeEvent, navigationState, navigationAnimation, detailsState, detailsAnimation, orientation]:
        [ActivatedRoute, ResizeEvent, SidenavState, boolean, SidenavState, boolean, string]) =>
          this.onUpdate(route, resizeEvent, navigationState, navigationAnimation, detailsState, detailsAnimation, orientation)
      );
  }

  delayFirst<T>(time?: number): OperatorFunction<T, T> {
    return function(source$: Observable<T>): Observable<T> {
      return source$.pipe(concatMap((value: T, index: number) => {
        return index===0 ? of(value).pipe(delay(time || 0)) : of(value)
      }));
    }
  }

  onUpdate(route:ActivatedRoute,resizeEvent:ResizeEvent,navigationState:SidenavState,navigationAnimation:boolean,detailsState:SidenavState,detailsAnimation:boolean,orientation:string) : void {
    //console.debug("UPDATE SIZE 0:"+JSON.stringify(sizes[0])+" 1:"+JSON.stringify(sizes[1])
    //  +"\n\tnavigation a("+navigationAnimation+"):"+JSON.stringify(navigationState)
    //  +"\n\tdetails a("+detailsAnimation+"):"+JSON.stringify(detailsState));
    //console.debug("onNavigationEnd: "+JSON.stringify(route.snapshot.data));

    //if (!sizes || sizes.length!=2 || !sizes[0] || !sizes[1] || sizes[1].width<=0 || sizes[1].height<=0) {
    if (!resizeEvent) {
      console.debug("\tfailed");
    } else {
      let expand = resizeEvent.currentSize.width > resizeEvent.previousSize.width ? true : (this.navigationLockWidth===undefined && this.detailsLockWidth===undefined);
      let size   = resizeEvent.currentSize;
      let width  = size.width;

      let navigationOpen = navigationState.open;
      let navigationMode = navigationState.mode;
      let navigationLock = (this.navigationLockWidth == width);
      let detailsOpen    = detailsState.open;
      let detailsMode    = detailsState.mode;
      let detailsLock    = (this.detailsLockWidth == width);
      let detailsGroup   = route.snapshot.data.detailsGroup;

      //let nav : any = this.navigationSidenav;
      //console.debug("NAV3._enableAnimations:"+nav._enableAnimations+" mode:"+this.navigationSidenav.mode+".pref:"+navigationState.preferredMode+" animation:"+navigationAnimation);

      //this.navigationLockWidth = this.detailsLockWidth = null;
      //console.debug("ROUTE current:"+this.router.url+" previous:"+this.routeUrl);
      if (this.routeUrl != this.router.url) {
        this.routeUrl = this.router.url;
        let previousDetailsState = this.routeDetails[this.routeUrl];
        let nextDetailsState = previousDetailsState;
        if (detailsGroup && this.detailsGroup==detailsGroup) {
          // if (!nextDetailsState) {
            nextDetailsState = {...detailsState};
          // } else {
          //   nextDetailsState.mode = detailsState.mode;
          //   nextDetailsState.open = detailsState.open;
          // }
        }
        this.detailsGroup = detailsGroup;
        if (!nextDetailsState) {
          nextDetailsState = {...initialLayoutState.details, mode: detailsState.mode};
          this.routeDetails[this.routeUrl] = nextDetailsState;
        } else {
          nextDetailsState.mode = detailsState.mode;
        }
        if (!isEqual(detailsState,nextDetailsState)) {
          if (nextDetailsState.open && nextDetailsState.mode==SidenavMode.OVER) {
            nextDetailsState.open = false;
          }
          this.layoutService.details.update(nextDetailsState);
          return;
        }
      } else if (!isEqual(this.routeDetails[this.routeUrl],detailsState)) {
        this.routeDetails[this.routeUrl] = detailsState;
        //console.debug("ROUTE set details\n\t"+JSON.stringify(detailsState));
      }

      // update open state change
      if (this.navigationSidenav.opened && !navigationOpen) {
        this.navigationSidenav.close();
        this.navigationLockWidth = width;
        navigationLock = navigationAnimation = true;
      } else if (!this.navigationSidenav.opened && navigationOpen) {
        //this.navigationSidenav.open();
        //this.navigationSidenav._animationState = 'open-instant';
        this.navigationLockWidth = width;
        navigationLock = navigationAnimation = true;
      }

      if (this.detailsSidenav.opened && !detailsOpen) {
        this.layoutService.details.close(); // this.detailsSidenav.close();
        this.detailsLockWidth = width;
        detailsLock = detailsAnimation = true;
      } else if (!this.detailsSidenav.opened && detailsOpen) {
        this.layoutService.details.open(); // this.detailsSidenav.open();
        this.detailsLockWidth = width;
        detailsLock = detailsAnimation = true;
      }

      // update modes...
      if (width<700) {  // no sidebar open
        navigationMode = SidenavMode.OVER;
        navigationOpen = false;
        detailsMode    = SidenavMode.OVER;
        detailsOpen    = false;
      } else if (width<1000) { // one sidebar max open
        if (navigationState.preferredMode && navigationState.preferredMode==SidenavMode.SIDE && navigationState.contentType) {
          navigationMode = SidenavMode.SIDE;
          navigationOpen = true;
          detailsMode    = SidenavMode.OVER;
          detailsOpen    = false;
        } else if (detailsState.preferredMode && detailsState.preferredMode==SidenavMode.SIDE && detailsState.contentType) {
          navigationMode = SidenavMode.OVER;
          navigationOpen = false;
          detailsMode    = SidenavMode.SIDE;
          detailsOpen    = true;
        } else if (navigationState.open && detailsState.open) {
          detailsMode    = detailsState.preferredMode;
          detailsOpen    = false;
        }
      } else {
        navigationMode = navigationState.preferredMode;
        detailsMode    = detailsState.preferredMode;
        if (navigationState.preferredMode && navigationState.preferredMode==SidenavMode.SIDE && navigationState.contentType) {
          navigationOpen = true;
        }
        if (detailsState.preferredMode && detailsState.preferredMode==SidenavMode.SIDE && detailsState.contentType) {
          detailsOpen    = true;
        }
      }

      if (!navigationLock) {
        if (navigationState.open && !navigationOpen) {
          this.layoutService.navigation.close();
          navigationLock = true;
        } else if (!this.navigationSidenav.opened && navigationOpen && expand) {
          this.layoutService.navigation.open();
          navigationLock = true;
        }
      }

      if (!detailsLock) {
        if (this.detailsSidenav.opened && !detailsOpen) {
          this.layoutService.details.close();
          detailsLock = true;
        } else if (!this.detailsSidenav.opened && detailsOpen && expand) {
          this.layoutService.details.open();
          detailsLock = true;
        }
      }

      let nav : any = this.navigationSidenav;
      let det : any = this.detailsSidenav;
      //console.debug("NAV4._enableAnimations:"+nav._enableAnimations+
      //  "\n\tmode:"+this.navigationSidenav.mode+" new:"+navigationMode+
      //  "\n\tnavigationAnimation:"+navigationAnimation+" navigationLock:"+navigationLock+" navigationOpen:"+navigationOpen);
      //console.debug("DET4._enableAnimations:"+det._enableAnimations+
      //  "\n\tmode:"+this.detailsSidenav.mode+" new:"+detailsMode+
      //  "\n\tnavigationAnimation:"+detailsAnimation+" detailsLock:"+detailsLock+" detailsOpen:"+detailsOpen);

      // immediate details mode update leads to initial layout disorders
      // manifested as delayed slide in of navigation panel.
      // queuing a timeout task for mode updates resolves this issue
      // if (navigationState.mode!=navigationMode) {
      //   if ((!navigationAnimation && (!navigationLock || !navigationAnimation)) || navigationOpen) {
      //     this.layoutService.navigation.setMode(navigationMode);
      //     //console.debug("NAV5.navigationMode:"+navigationMode);
      //   }
      // }
      // if (detailsState.mode!=detailsMode) {
      //   if (detailsState.mode!=detailsMode) {
      //     this.layoutService.details.setMode(detailsMode);
      //   }
      // }

      const updateNavigationMode = navigationState.mode!=navigationMode && ((!navigationAnimation && (!navigationLock || !navigationAnimation)) || navigationOpen);
      const updateDetailsMode = detailsState.mode!=detailsMode && ((!detailsAnimation && (!detailsLock || !detailsAnimation)) || detailsOpen);
      if (updateNavigationMode || updateDetailsMode) {
        window.setTimeout(() => {
          updateNavigationMode && this.layoutService.navigation.setMode(navigationMode);
          updateDetailsMode && this.layoutService.details.setMode(detailsMode);
        });
      }
      if (navigationState.animation && !navigationAnimation) {
        this.layoutService.navigation.clearAnimation();
      }
      if (detailsState.animation && !detailsAnimation) {
        this.layoutService.details.clearAnimation();
      }

      // workaround: no animation for initial openening...
      /*if (this.initialLayout) {
        if (navigationOpen) {
          this.navigationSidenav._animationState = 'open-instant';
        }
        if (detailsOpen && !this.detailsSidenav.opened) {
          this.detailsSidenav._animationState = 'open-instant';
        }
        window.setTimeout(()=> {
          this.initialLayout = false;
        },500);
      }*/
      this.resizeService.triggerAsync();
    }
  }

  getAnimationData(outlet: RouterOutlet) {
    return (outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation']) || 'content';
  }

  onNavigationOpenStateChanged(open: boolean) {
    // console.debug("onNavigationOpenStateChanged "+open+" says:"+this.navigationSidenav.opened);
    this.layoutService.navigation.ensure(open);
  }

  onDetailsOpenStateChanged(open: boolean, eventName?: string) {
    console.debug(`onDetailsOpenStateChanged: [ eventName=${eventName}, open=${open}, sidenavOpen=${this.detailsSidenav.opened} ]`);
    // this.layoutService.details.ensure(open);
    this.resizeService.triggerAsync();
  }

  onBackdropClicked(): void {
    if (this.navigationSidenav.opened &&
        this.navigationSidenav.mode == SidenavMode.OVER) {
      this.layoutService.navigation.close();
    }
    if (this.detailsSidenav.opened &&
        this.detailsSidenav.mode == SidenavMode.OVER) {
      this.layoutService.details.close();
    }
  }
}
