import {Action, createAction, props} from "@ngrx/store";
import {User} from "core";
import {Credentials, LoginResult, LogoutResult} from "../models/credentials";

export enum AuthenticationActionIds {
  LOGIN = 'auth.login',
  LOGIN_SUCCESS = 'auth.login.success',
  LOGIN_FAILURE = 'auth.login.failure',
  LOGOUT = 'auth.logout',
  LOGOUT_SUCCESS = 'auth.logout.success',
  LOGOUT_FAILURE = 'auth.logout.failure',
  PASSCODE_REQUEST = 'passcode.request',
  PASSCODE_REQUEST_SUCCESS = 'passcode.request.success',
  PASSCODE_REQUEST_FAILURE = 'passcode.contract.failure',
  SIGN_CONTRACT = 'sign.contract',
  SIGN_CONTRACT_SUCCESS = 'sign.contract.success',
  SIGN_CONTRACT_FAILURE = 'sign.contract.failure',
  UPDATE_USER = 'auth.update.user',
}

export class AuthenticationLoginAction implements Action {
  readonly type = AuthenticationActionIds.LOGIN;
  constructor(public credentials: Credentials, public onSuccess:(credentials:LoginResult)=>void, public onFailure:(reason:any)=>void) {}
}
export class AuthenticationGuestLoginAction    extends AuthenticationLoginAction {}
export class AuthenticationPasscodeLoginAction extends AuthenticationLoginAction {}

// export const authenticationLogin = createAction(AuthenticationActionIds.LOGIN, props<AuthenticationLoginAction>());
// export const authenticationGuestLogin = createAction(AuthenticationActionIds.LOGIN, props<typeof AuthenticationGuestLoginAction>());
// export const authenticationPasscodeLogin = createAction(AuthenticationActionIds.LOGIN, props<typeof AuthenticationPasscodeLoginAction>());

export class AuthenticationLoginSuccessAction implements Action {
  readonly type = AuthenticationActionIds.LOGIN_SUCCESS;
  constructor(public credentials: Credentials,
              public user: User,
              public autoLogin: boolean = false,
              public signed: boolean = false) {}
}


// export const authenticationLoginSuccess = createAction(AuthenticationActionIds.LOGIN_SUCCESS, props<{credentials: Credentials, user: User, autoLogin?: boolean, signed?: boolean}>());
export const authenticationLoginFailure = createAction(AuthenticationActionIds.LOGIN_FAILURE, props<{credentials: Credentials, reason: any}>());
export const authenticationLogout = createAction(AuthenticationActionIds.LOGOUT, props<{onSuccess:(credentials:LogoutResult)=>void, onFailure:(reason:any)=>void}>());
export const authenticationLogoutSuccess = createAction(AuthenticationActionIds.LOGOUT_SUCCESS);
export const authenticationLogoutFailure = createAction(AuthenticationActionIds.LOGOUT_FAILURE, props<{reason: any}>());

export const signContract = createAction(AuthenticationActionIds.SIGN_CONTRACT,
  props<{
    signed: boolean,
    callback?: (signed?:boolean)=>void
  }>());
export const signContractSuccess = createAction(AuthenticationActionIds.SIGN_CONTRACT_SUCCESS);
export const signContractFailure = createAction(AuthenticationActionIds.SIGN_CONTRACT_FAILURE, props<{reason: any}>());
export const authenticationUpdateUser = createAction(AuthenticationActionIds.UPDATE_USER, props<{user: User}>());

export type AuthenticationActionTypes = AuthenticationLoginSuccessAction
