// ATTENTION:
// On ios src attribute is consulted to decide if the audio should be displayed in the lock screen.
// for this reason we have to remove it when audio is paused and set it back again when resumed!
// ISSUES / WORKAROUNDS:
// ConferenceService in its ctor (see call to ConferenceService.updateSounds()) makes access and even invokes to sounds which are not yet initialized!!!
// ConferenceService.sounds.initialize(); is invoked once in the whole app lifecycle during bootstrap but ConferenceService instance is already created

// stop() api is modified to perform the action only when the sound is initialized otherwise when ConferenceService calls Sound.stop()
// it effectively will clear sound audio src property which in turn will prevent it from being initialized later when initialize() is called
// (initialization involves playing the default initialize.mp3 i.e. silence and stop it immediately)

// play() api is overridden to initialize the Sound if not yet initialized and to assign corresponding values to src and loop properties
// (maybe temporary removed due to aforementioned ios lockscreen behaviour).

export class Sound {

  protected _audio: HTMLAudioElement;
  protected audioPlay: () => Promise<void>;
  protected initializing = false;
  protected initialized = false;
  protected initialSrc = '/assets/sounds/initialize.mp3';

  constructor(public audioSrc: string, public loop= false) {
    console.debug('SOUND.ctor', {audioSrc});
    this._audio = new Audio(this.initialSrc);
    this._audio.autoplay = false;
    this.audioPlay  = this._audio.play.bind(this._audio);
    const self = this;
    this._audio.play = async function() {
      console.log(`SOUND [${self.audioSrc}] -> PLAY START`, {
        self,
        'this': this,
        initialized: self.initialized,
        current: self.current
      });
      if (!self.initialized) {  // ConferenceService abuses sounds by not ensuring that they are initialized first
        this.src = self.initialSrc;
        this.loop = false;
        await self.initialize();
      }

      if (this.loop != self.loop) {
        this.loop = self.loop;
      }
      if (this.src != self.audioSrc) {
        // preload could have been set to none in order to prevent undesired server request (to path /undefined)
        // when assigning this._audio.src = undefined; which is required to cleanup ios lock screen
        this.preload = 'auto';
        this.src = self.audioSrc;
      }
      console.debug(`SOUND [${self.audioSrc}] -> PLAY`, {initialized: self.initialized, current: self.current });
      await self.audioPlay();
      console.debug(`SOUND [${self.audioSrc}] -> PLAYED`, {initialized: self.initialized, current: self.current });
    }.bind(this._audio);
    // .bind(this); // binding to this produces runtime error:
    // Error: Uncaught (in promise): TypeError: Can only call HTMLMediaElement.play on instances of HTMLMediaElement play@[native code] @ionic://localhost/main.e6d898b73bf63164.js:81507:30 generatorResume@[native code] asyncGeneratorStep@ionic://localhost/main.e6d898b73bf63164.js:498840:24 _next@ionic://localhost/main.e6d898b73bf63164.js:498862:27 @ionic://localhost/main.e6d898b73bf63164.js:498869:12 ZoneAwarePromise@ionic://localhost/polyfills.5de6a1a16f9839a0.js:1731:31 @ionic://localhost/main.e6d898b73bf63164.js:498858:23 @ionic://localhost/main.e6d898b73bf63164.js:23595:63 generatorResume@[native code] asyncGeneratorStep@ionic://localhost/main.e6d898b73bf63164.js:498840:24 _next@ionic://localhost/main.e6d898b73bf63164.js:498862:27 @ionic://localhost/main.e6d898b73bf63164.js:498869:12 ZoneAwarePromise@ionic://localhost/polyfills.5de6a1a16f9839a0.js:1731:31 @ionic://localhost/main.e6d898b73bf63164.js:498858:23 @ionic://localhost/749.95df22b813eace1e.js:1423:47 onInvokeTask@ionic://localhost/main.e6d898b73bf63164.js:370717:35 @ionic://localhost/polyfills.5de6a1a16f9839a0.js:338:49 @ionic://localhost/polyfills.5de6a1a16f9839a0.js:683:35 invokeTask@ionic://localhost/polyfills.5de6a1a16f9839a0.js:2201:20 globalCallback@ionic://localhost/polyfills.5de6a1a16f9839a0.js:2238:31
    //console.debug("SOUND.created",this.audioSrc,"loop",loop,this.audio.loop);
    // this.audio.loop = loop;
    this._audio.load();
  }

  public async initialize(): Promise<void> {
    console.debug(`SOUND [${this.audioSrc}] -> INITIALIZE START`, {current: this.current});
    if (!this.initialized && !this.initializing) {
      this.initializing = true;
      const previous = this._audio.onplaying;
      this._audio.onplaying = function () {
        console.debug(`SOUND [${this.audioSrc}] -> INITIALIZE ONPLAYING`, {'this': this, current: this.current});
        this._audio.onplaying = previous;
        window.setTimeout(() => {
          console.debug(`SOUND [${this.audioSrc}] -> INITIALIZE STOP`, {'this': this, current: this.current});
          this.initializing = false;
          this.initialized = true;
          this.stop();
          console.debug(`SOUND [${this.audioSrc}] -> INITIALIZE END`, {current: this.current});
        });
      }.bind(this);
      this._audio.addEventListener('ended', () => {
        if (!this._audio.loop) {
          // cleanup for ios lock screen (see also stop())
          this._audio.loop = false;
          this._audio.preload = 'none'; // prevent server request caused by src assignment below
          this._audio.src = undefined;
        }
      })
      // return new Promise<void>((resolve) => {
      //   this._audio.onloadedmetadata = (event) => {
      //     console.debug(`SOUND [${this._audio.src}] -> INITIALIZE METADATA LOADED`, this.audioSrc);
      //     resolve();
      //   };
      // }).then(async () => {
        console.debug(`SOUND [${this.audioSrc}] -> INITIALIZE PLAY`, {current: this.current});
        await this.audioPlay();
        console.debug(`SOUND [${this.audioSrc}] -> INITIALIZE PLAYED`, {current: this.current});
      // })
    }
  }

  public async play(): Promise<void> {
    return this._audio.play();
  }

  public stop() {
    console.debug(`SOUND [${this.audioSrc}] -> STOP`, {current: this.current});
    if (this.initialized && (!this.ended() || this._audio.loop)) {
      this._audio.pause();
      this._audio.currentTime = 0;

      // cleanup for ios lock screen
      this._audio.loop = false;
      this._audio.preload = 'none'; // prevent server request caused by src assignment below
      this._audio.src = undefined;
      console.debug(`SOUND [${this.audioSrc}] -> STOPPED`, {current: this.current});
    } else {
      console.debug(`SOUND [${this.audioSrc}] -> STOP SKIP`, {initialized: this.initialized, ended: this.ended(), current: this.current});
    }
  }
  public ended():boolean {
    return this._audio.ended;
  }

  get audio(): HTMLAudioElement {
    console.debug(`SOUND [${this.audioSrc}] -> GET AUDIO`, {current: this.current});
    return this._audio;
  }

  set audio(audio: HTMLAudioElement) {
    console.debug(`SOUND [${this.audioSrc}] -> SET AUDIO`,  {current: this.current, value: audio});
    this._audio = audio;
  }

  get current(): string {
    return this._audio?.src;
  }
}
