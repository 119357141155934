import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Media, MediaReviewState, MediaType} from "../../store/models";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {Contact, EMPTY_ARRAY, ENVIRONMENT, Logger, Platform, Topic, User} from "core";
import {PropertiesService} from "properties";
import {from, Subject, Subscription} from "rxjs";
import {map, takeUntil} from "rxjs/operators";
import {
  AccordionContainerComponent,
  AccordionSectionDefinition,
  BasicContainerComponent,
  FormChangeDetector
} from "shared";
import {TranslateService} from "@ngx-translate/core";
import {MediaService, MediaUpload, MediaUploadRef} from "../../service/media.service";
import moment from "moment";
import {MediaChangeConsent} from "../../containers/media-details-container/media-change-consent";
import has from "lodash/has";
import uniq from "lodash/uniq";
import get from "lodash/get";
import set from "lodash/set";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import isArray from "lodash/isArray";
import mergeWith from "lodash/mergeWith";
import cloneDeep from "lodash/cloneDeep";
import {HttpClient} from "@angular/common/http";
import {FilterService} from "filter";
import {MediaDetailsComponentFactoryService} from './media-details-component-factory.service';
import {MediaDetailsShareDataService} from '../../service/media-details-share-data.service';
import {UploadRef, UploadService} from 'upload';

@Component({
  selector: 'app-media-details',
  templateUrl: './media-details.component.html',
  styleUrls: ['./media-details.component.scss'],
  // animations: [
  //   enterLeaveAnimations,
  //   contentAnimations,
  //   trigger('content', [
  //     transition(':enter', [
  //       style({ opacity: 0 }),
  //       animate('0ms ease-in', style({ opacity: 1 }))
  //     ]),
  //     transition(':leave', [
  //       style({ opacity: 1 }),
  //       animate('0ms ease-out', style({ opacity: 0 }))
  //     ])
  //   ])
  // ]
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaDetailsComponent extends BasicContainerComponent {

  sections: AccordionSectionDefinition[] = [];
  _startGap: number = 0;

  @Input()  mediaChangeConsent: MediaChangeConsent;
  @Output() mediaChange       = new EventEmitter<Media[] | Media>();
  @Output() mediaDelete       = new EventEmitter<Media>();
  @Output() editModeChange    = new EventEmitter<boolean>();
  @Output() mediaAuthorSelect = new EventEmitter<(contact: Contact) => void>();

  @ViewChild(AccordionContainerComponent) accordionContainer;
  private buttonBar: ElementRef;
  @ViewChild('buttonBar', { read: ElementRef })  set content(content: ElementRef) {
    if(content) { // initially setter gets called with undefined
      this.buttonBar = content;
    }
  }

  private _target: string = '';
  private _media: Media;
  private mediaClone: Media;
  private _user: User;
  private _editMode = false;
  private changeSubscription: Subscription;
  private dirtyForms: FormGroup[] = [];

  reviewForm  : FormGroup;
  mediaForm   : FormGroup;

  mediaTags: string[];
  mediaCountries: string[];

  isMember          = false;
  isVerifiedMember  = false;
  isLeader           = false;
  isLeaderPlus       = false;
  isAdmin            = false;
  isVisibilityEditor = false;
  isLockEditor       = false;
  isPriorityEditor   = false;

  mode: 'basic' | 'advanced' = 'advanced';
  resetHandler: (media: Media) => Promise<Media>;
  formChangeReset: Subject<void>;

  MediaType = MediaType;

  producedDateFilter = (moment: moment.Moment): boolean =>
      moment && has(this.media, 'timeCreated')
      ? moment.toDate().getTime() <= this.media.timeCreated
      : true;

  displayFilter = (filter: Topic): boolean => {
    return this.editMode
         ? filter.editable==undefined || filter.editable
         : this.isSelected(filter.id);
  }

  protected logger = new Logger('MediaDetailsComponent');

  constructor(protected formBuilder: FormBuilder,
              protected formChangeDetector: FormChangeDetector,
              protected propertiesService: PropertiesService,
              protected translateService: TranslateService,
              protected mediaService: MediaService,
              protected filterService: FilterService,
              private factoryService: MediaDetailsComponentFactoryService,
              protected http: HttpClient,
              protected zone: NgZone,
              protected elementRef: ElementRef,
              protected uploadService: UploadService,
              // protected dateAdapter: DateAdapter<moment.Moment>,
              public platform: Platform,
              protected dialog: MatDialog,
              public changeDetector: ChangeDetectorRef,
              private mediaDetailsShareDataService: MediaDetailsShareDataService,
              @Inject(ENVIRONMENT) private environment: any) {
    super();
    this.propertiesService.user$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((user: User) => {
        this._user              = user;
        this.isAdmin            = user.isAdmin;
        this.isLeader           = user.isLeader     || user.isAdmin;
        this.isLeaderPlus       = user.isLeaderPlus || user.isAdmin;
        this.isMember           = user.isMember    || user.isAdmin;
        this.isVerifiedMember   = user.isVerifiedMember || user.isAdmin;
        this.isVisibilityEditor = user.isAdmin || (user.app?.tags || []).includes('visibilityEditor');
        this.isLockEditor       = user.isAdmin || (user.app?.tags || []).includes('lockEditor');
        this.isPriorityEditor   = user.isAdmin || (user.app?.tags || []).includes('priorityEditor');
      });
    this.reviewForm = this.createReviewForm();
    this.mediaForm = this.createMediaForm();
    // this.mediaDetailsShareDataService.getMedia$.pipe(takeUntil(this.onDestroy$)).subscribe(media => {
    //   this.media = media;
    //   this.changeDetector.detectChanges();
    // });
    this.mediaDetailsShareDataService.getMediaTags$.pipe(takeUntil(this.onDestroy$)).subscribe(mediaTags => {
      if(!isEqual(this.mediaTags, mediaTags)) {
        this.mediaTags = mediaTags;
        this.applyTagsState(this.mediaForm);
      }
    });
    this.mediaDetailsShareDataService.mediaUpdate$.pipe(takeUntil(this.onDestroy$)).subscribe(event => {
      this.target = event.source;
      this.onMediaChange(event.mediaUpdate);
    });
  }

  ngOnInit() {
      //this.filterService.getRootFilterTopics$();
      //.filter((definition) => definition.id=='project' || definition.id=='training');
    this.mediaDetailsShareDataService.setEditMode(false); // Initialize always in read only mode
    this.updateView();
    this.mediaDetailsShareDataService.getOptions$.pipe(takeUntil(this.onDestroy$)).subscribe(options => {
        this.target = options.target;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.mediaChangeConsent){
      this.mediaDetailsShareDataService.setMediaChangeConsent(changes.mediaChangeConsent.currentValue);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.changeSubscription?.unsubscribe();
  }

  updateView() {
    //console.log("XY.updateView()");
    const sectionsUpdate = [];
    const info = {
      id: 'info',
      type: 'info',
      factoryService: this.factoryService,
      context: {
        authorSelect: this.mediaAuthorSelect
      },
      label: 'media.info'
    };
    sectionsUpdate.push(info);

    const sectionDefinition = [
      {type: 'cover', label: 'media.cover'},
      {type: 'rating', label: 'rating.title'},
      {type: 'review', label: 'media.review'},
      {type: 'settings', label: 'module.settings'},
      //{type: 'lock', label: 'media.lock.label'},
      //{type: 'topics', label: 'groups.topics.label'},
      //{type: 'bible', label: 'bible.books.title'},
      {type: 'tags', label: 'media.filters.tags'},
      {type: 'country', label: 'media.countries'},
      //{type: 'role', label: 'media.userRoles'}
      {type: 'actions', label: 'media.actions.label'}
    ]

    sectionDefinition.forEach(definition => {
      const section = {
        id: definition.type,
        type: definition.type,
        label: definition.label,
        factoryService: this.factoryService
      };
      sectionsUpdate.push(section);
    });

    this.sections = sectionsUpdate;
  }

  protected createReviewForm(): FormGroup {
    return this.formBuilder.group({
      downlineInclude: [ null ],
      approved: [ false ],
      declined: [ false ],
      reason:   ['']
    });
  }

  protected createMediaForm(): FormGroup {
    return this.formBuilder.group({
      name:         [ '', Validators.required ],
      author: this.formBuilder.group({
        // id is not required for backwards compatibility with media which has manually keyed in author names.
        // i.e. author was not selected from contact list and therefore there is no corresponding author.id
        id     : [ undefined/*, Validators.required() */ ],
        name   : [ '',        Validators.required ]
      }),
      // author:       [ { value: '', disabled: true } ],
      visible:      [ '' ],
      lock: this.formBuilder.group({
        formula : [ '' ],
        message : [ '' ]
      }),
      info:         [ '' ],
      timeProduced: [ { value: Date.now(), disabled: false }, Validators.required ],
      priority:     [ 0 ],
      published:    [ false ],
      protected:    [ false ],
      countryCodes: [],
      countryCodesExcluded: [ false ],
      actions: []
    });
  }

  @Input()
  set media(media: Media) {
    //console.log("XY.SET_MEDIA",media);
    this.logger.debug('SET MEDIA', media);
    // async updates of the current media could be triggered by server initiated updates
    // in this case we should keep the current changes in the forms
    // as long there is no conflict with the properties of the updated media.
    const update = !!media && !!this.media &&
                     media.id == this.media.id &&
                     media != this.media;
    const updateMediaTags = (initial: string[], current: string[], next: string[]) => {
      const tags:string[] = [];
      const allTags = uniq([...initial, ...current, ...next]);
      allTags.forEach((tag) => {
        const isInitial = initial.includes(tag);
        const isCurrent = current.includes(tag);
        const    isNext = next.includes(tag);
        if ((isNext==isInitial && isCurrent) || (isNext && !isInitial)) {
          tags.push(tag);
        }
      });
      return tags;
    };
    //console.log("MEDIA",media,"UPDATE",update);
    this.mediaTags = update
      ? updateMediaTags(this.media.tags ?? EMPTY_ARRAY, this.mediaTags, media.tags ?? EMPTY_ARRAY)
      : (media?.tags ? [...media.tags] : []);
    //console.log("MEDIA-TAGS",this.mediaTags);
    this.mediaCountries = media?.countryCodes ?? [];
    if (media) {
      const mediaFormValueProvider = (() => {
        const provider = (media: Media) => {
          return {
            'name'        : media.name,
            'author'      : (({id, name}) => ({id, name}))(media.author),
            'visible'     : get(media, 'properties.visible', ''),
            'lock'        : (({formula, message}) => ({formula, message}))(get(media, 'lock', { formula: '', message: '' })),
            'info'        : media.info ?? '',
            'timeProduced': this.timeProduced(media),
            'priority'    : get(media, 'properties.priority', 0),
            'published'   : !!media.published,
            'protected'   : !!media.protected,
            'countryCodes': media.countryCodes ? [...media.countryCodes] : [],
            'countryCodesExcluded': !!media.countryCodesExcluded,
            'actions'     : media.actions ? [...media.actions] : [],
          };
        };
        return {
          initial: () => provider(this.media),
          next: () => provider(media),
        }
      })();

      const reviewFormValueProvider = (() => {
        const provider = (media: Media) => {
          return {
            'downlineInclude': get(media, 'downline_include', null),
            'approved': get(media, 'review.state') == MediaReviewState.Approved,
            'declined': get(media, 'review.state') == MediaReviewState.Declined,
            'reason'  : get(media, 'review.reason', null)
          };
        };
        return {
          initial: () => provider(this.media),
          next: () => provider(media),
        }
      })();

      const updateForm = (form: FormGroup,
        formValueProvider: { initial: () => any, next: () => any },
        dependsOnTags = false, updateMediaOnly = false)
        // : Promise<void> => {
        : Promise<boolean> => {
        const nextFormValue = formValueProvider.next();
        if (update) {
          // let promise = Promise.resolve();
          let promise = Promise.resolve(false);
          const initialFormValue = formValueProvider.initial();
          // when the same media is set again because of e.g. because of edit->cancel->edit
          // initialFormValue and nextFormValue will be equal but if the user has changed a form control value then
          // we still must update/reset its value to reflect the media properties
          // if (!isEqual(initialFormValue, nextFormValue)) {
          from(Object.keys(form.controls)).pipe(
            map(key => [form.controls[key], initialFormValue[key], nextFormValue[key]])
          ).subscribe(([control, initialValue, nextValue]: [AbstractControl, any, any]) => {
            // this.logger.debug(control, initialValue, nextValue);
            // update field only when there is a change
            // if (initialValue !== nextValue) {
            if (control.value !== nextValue && (!updateMediaOnly || !control.dirty)) {
              // promise = promise.then(() => lastValueFrom(
              //   control.valueChanges.pipe(
              //     takeUntil(this.onDestroy$),
              //     take(1)
              //   ))
                // .then(() => control.markAsPristine())
              // );
              // control.reset(nextValue);
              try {
                control.setValue(nextValue);
              } catch (error) {
                this.logger.error('Failed to update control value', { control, nextValue, form });
              }
            } else if (control.dirty || control.touched) {
              control.markAsPristine();
              if(control.valid) {
                control.markAsUntouched();
              }
            }
          });
          // }
          if (dependsOnTags) {
            // reflect tags dirty/pristine state to form state
            // later we can make tags as a separate component implementing ControlValueAccessor
            this.applyTagsState(form);
          }
          this.logger.debug('PRESTINE.update', form.pristine,form);
          return promise;
        } else {
          // const promise = lastValueFrom(form.valueChanges.pipe(takeUntil(this.onDestroy$), take(1)));
          form.reset(nextFormValue);
          this.logger.debug('PRESTINE.reset', form.pristine,form);
          // return promise;
          return Promise.resolve(true);
        }
      };

      if (this.mediaForm.get('name').invalid) {
        this.mediaForm.get('name').markAsTouched(); // force name field to immediately show error if not valid
      }

      const formsUpdatePromise = Promise.all([
        updateForm(this.mediaForm, mediaFormValueProvider,true, this.media?.id==media?.id)
          .then((result) => {
            this.logger.debug('MEDIA FORM RESET REQUESTED', { result });
            return result;
          }),
        updateForm(this.reviewForm, reviewFormValueProvider)
          .then((result) => {
            this.logger.debug('REVIEW FORM RESET REQUESTED', { result });
            return result;
          })
      ]);

      if (!this.changeSubscription) {
        this.formChangeReset = new Subject();
        const handlePristine = (form: FormGroup, pristine: boolean, externalState?: boolean) => {
          this.logger.debug('handlePristine', {form, pristine, externalState});
          if (!pristine && !this.dirtyForms.includes(form)) {
            this.dirtyForms.push(form);
          } else if (pristine && this.dirtyForms.includes(form)){
            this.dirtyForms = this.dirtyForms.filter((f) => f!=form);
          }
          if (pristine && !externalState && !form.pristine)       { form.markAsPristine(); }
          else if ((!pristine || externalState) && form.pristine) { form.markAsDirty();    }
          // In theory detectChanges() call should not be needed
          // but in some cases e.g. when changing downline visibility, approve/reject status,
          // priority, published flag, etc. view and backing state can go out of sync
          // If the mouse cursor is slightly moved the changes are reflected.
          // Initially this issue was not present but started to appear maybe after angular or material lib upgrade
          this.changeDetector.detectChanges();
        };
        const detectorOptions = { resetter: this.formChangeReset, log: true };
        const mediaFormChangeSubscription = this.formChangeDetector.detect(this.mediaForm, detectorOptions)
          .subscribe((pristine: boolean) => {
            handlePristine(this.mediaForm,
              pristine,                     // author input is disabled and changes do not affect the form value
              this.tagsDirty || this.mediaForm.get('author').value?.id!=this.media.author?.id);
          });
        const reviewFormChangeSubscription = this.formChangeDetector.detect(this.reviewForm, detectorOptions)
          .subscribe((pristine: boolean) => {
            handlePristine(this.reviewForm, pristine);
          });
        this.changeSubscription = new Subscription();
        this.changeSubscription.add(() => {
          mediaFormChangeSubscription.unsubscribe();
          reviewFormChangeSubscription.unsubscribe();
          this.formChangeReset.complete();
        });
      }

      formsUpdatePromise.then(([mediaFormResetRequired, reviewFormResetRequired]) => {
        if (this.editMode && mediaFormResetRequired ||
          !this.editMode && reviewFormResetRequired) {
          this.logger.debug('RESET FORMS');
          this.formChangeReset.next();
        }
        this.mediaDetailsShareDataService.setMediaForm(this.mediaForm);
        this.mediaDetailsShareDataService.setReviewForm(this.reviewForm);
      });
    }
    const editMode = (this._media?.id != media?.id) ? false : this.editMode;
    if (this._media != media) {
      // setting the same media instance again is considered a component reset (and is used e.g. when switching the views)
      // media object passed in as argument is cloned to avoid external interferences in reset detection.
      this._media = media;
      this.mediaClone = media ? cloneDeep(media) : media;
      //if (this._media?.id !== media?.id) {
      //  this.editMode = false; // switch to view mode when another media is set
      //}
      this.mediaDetailsShareDataService.setMedia(media);
    }
    this.mediaDetailsShareDataService.setMediaTags(this.mediaTags);
    if (this.accordionContainer) {
      this.accordionContainer.target = this.target;
    }
    this.editMode = editMode;
    this.changeDetector.markForCheck();
  }

  get media(): Media {
    return this._media;
  }

  get user(): User {
    return this._user;
  }

  set editMode(editMode: boolean) {
    //console.log("XY.SET_EDITMODE",editMode);
    if (this._editMode!=editMode) {
      this._editMode = editMode;
      this.mediaDetailsShareDataService.setEditMode(editMode);
      this.editModeChange.emit(editMode)
    }
  }

  get editMode(): boolean {
    return this._editMode;
  }

  onSaveMedia() {
    const previous = {...this.media};
    this.media.name = this.mediaForm.get('name').value;
    this.media.info = this.mediaForm.get('info').value;
    this.media.timeProduced = new Date(this.mediaForm.get('timeProduced').value).getTime();
    const author = this.mediaForm.get('author').value;
    if (this.media.author?.id != author?.id) { // if same author -> keep source, visible, decision, root properties.
      this.media.author = author;
    }
    if (this.mediaForm.get('visible').dirty) {
      const visible = this.mediaForm.get('visible').value;
      if (visible) {
        set(this.media, 'properties.visible', visible);
      } else {
        this.media.properties = omit(this.media.properties, 'visible')
      }
    }
    if (this.mediaForm.get('priority').dirty) {
      set(this.media, 'properties.priority', this.mediaForm.get('priority').value);
    }
    // if (this.tagsDirty) {
      this.media.tags = this.mediaTags;
    // }
    this.media.countryCodes = this.mediaForm.get('countryCodes').value;
    this.media.countryCodesExcluded = this.mediaForm.get('countryCodesExcluded').value;
    this.media.actions = this.mediaForm.get('actions').value;

    const published = this.mediaForm.get('published').value;
    if (!!this.media.published != published) {
      this.media.published = published ? this.stateInfo : null;
    }
    const protect = this.mediaForm.get('protected').value;
    if (!!this.media.protected != protect) {
      this.media.protected = protect ? this.stateInfo : null;
    }
    //TODO: review: strange visibility condition for this checkbox - isApproved && !isMaster
    // if (_.get(this.media, 'review.state') == MediaReviewState.Approved != approved) {
    //   this.media.review = approved ? {state: MediaReviewState.Approved, time: new Date().getTime()} : null;
    // }
    const lock = this.mediaForm.get("lock").value;
    this.media.lock = this.media.lock ? Object.assign(this.media.lock, lock) : lock;
    this.logger.debug([this.media, previous]);
    this.mediaChange.emit([this.media, previous]);
    this.editMode = false;
  }

  onSaveReview() {
    const previous = {...this.media};
    const approved = this.reviewForm.get('approved').value;
    const declined = this.reviewForm.get('declined').value;
    const reason   = this.reviewForm.get('reason').value;
    const state  = approved ? MediaReviewState.Approved : declined ? MediaReviewState.Declined : MediaReviewState.Pending;
    if (state!==MediaReviewState.Pending || previous.review) { // do not save pending state if media does have a review yet
      const stateInfo = previous.review || this.stateInfo;
      const review: any = {...stateInfo, state: state};
      if (declined) {
        review.reason = reason;
      } else if (has(previous, 'review.reason')) {
        review.reason = previous.review.reason;
      }
      this.media.review = review;
    }
    this.media.downline_include = this.reviewForm.get('downlineInclude').value;
    // form reset is needed in order to start a new form change detection cycle after save
    // if the internal form change detector state is not re-initialized the form dirty state (and therefore the save button visibility)
    // will continue to be resolved based on the original form value.
    // TODO: use separate form change detection resetter for each form
    // this.resetForm(this.reviewForm);
    this.formChangeReset.next();
    this.mediaChange.emit([this.media, previous]);
  }

  onEdit() {
    this.dirtyForms = [];
    this.media = this.mediaClone;
    this.editMode = true;
    this.target = '';
  }

  onCancel() {
    this.dirtyForms = [];
    this.mediaTags = this.media.tags;
    this.mediaCountries = this.media.countryCodes;
    this.media = this.mediaClone;
    this.editMode = false;
  }

  onDelete() {
    this.mediaDelete.emit(this.media);
  }

  onMigrate() {
    // /v1.0/media/clone/:mediaId/:appId
    let url = `/v1.0/media/clone/${this.media.id}/3`;
    this.logger.debug("post",url);
    this.http.post(url,{}).subscribe();
  }

  onPatch() {
    const fileType = this.media.mediaType;
    const endpoint= `${this.environment.serverUrl}/v1.0/media/upload/${fileType}/${this.media.id}`;
    this.uploadService
      .upload(this.elementRef, { fileType, /*meta: { media: this.media },*/ endpoint })
      .then(uploadRef => {
        return uploadRef.upload()
          .then(result => {
            uploadRef.release();
            this.logger.debug('Media patch completed', result);
            if (result.successful.length == 1) {
              return (result.successful[0] as any).response || {};
            } else {
              throw new Error('uploadCover failed');
            }
          })
          .catch(error => this.logger.error('Media patch failed', error))
      });
  }

  onReset() {
    if (this.resetHandler) {
      const current = cloneDeep(this.media);
      current.name = this.mediaForm.get('name').value;
      this.resetHandler(current).then(reset => {
        this.logger.debug('onReset', current, reset);
        if (!isEqual(current, reset)) {
          const previous = {...this.media};
          this.logger.debug('onReset > dirty');
          this.mediaForm.get('name').setValue(this.media.name = reset.name);
          this.mediaForm.get('name').markAsTouched();
          this.mediaForm.get('name').markAsDirty();
          //this.mediaChange.emit([reset, previous]);
        }
      });
    }
  }

  onClearTimeProduced() {
    this.mediaForm.controls.timeProduced.setValue(this.timeProduced(this.media));
  }

  onMediaAuthorSelect(event: MouseEvent) {
    this.mediaAuthorSelect.emit((contact: Contact) => {
      if (contact?.id) {
        this.logger.debug('mediaAuthorSelect.callback', {contact});
        const authorId = this.mediaForm.get('author').value?.id;
        if (contact.id!=authorId) {
          const pick = ({id, name}) => ({id, name});
          this.mediaForm.get('author').setValue(pick(contact));
        }
      }
    });
  }

  protected resetForm(...forms: FormGroup[]) {
    forms.forEach(form => {
      const index = this.dirtyForms.indexOf(form);
      if (index >= 0) {
        this.dirtyForms.splice(index, 1);
      }
      form.reset(form.value);
    });
  }

  protected applyTagsState(form: FormGroup) {
    const tagsDirty = this.tagsDirty;
    const formDirty = this.dirtyForms.includes(form);
    if (!tagsDirty && !formDirty && !form.pristine) {
      form.markAsPristine();
    } else if ((tagsDirty || formDirty) && !form.dirty){
      form.markAsDirty();
    }
  }

  get tagsDirty() {
    /*
       At this moment this.media can be null or undefined!
       tagsDirty() is used for change detection in mediaForm!
       The change detector is initialized when media is first set but is used until the component is not destroyed.
       As long as set media() can be invoked multiple times for the same component
       we cannot safely assume that in the context of tagsDirty() this.media is always defined and not null.
    */
    const mediaTags = this.media && this.media.tags || [];
    const  pristine = this.mediaTags.length==mediaTags.length &&
                      this.mediaTags.every((tag)=> mediaTags.includes(tag));
    return !pristine;
  }

  get countriesChanged() {
    if (this.media) {
      const formCountries = this.mediaForm.get("countryCodes").value ?? EMPTY_ARRAY;
      const mediaCounties = this.mediaCountries ?? EMPTY_ARRAY;
      return !isEqual(formCountries, mediaCounties);
    }
    return false;
  }

  timeProduced(media: Media): Date {
    const timeProduced = media ? media.timeProduced || media.timeCreated : null;
    if (timeProduced) {
      return new Date(timeProduced);
    }
    return new Date();
  }

  isSelected(filterId: string): boolean {
    // return this.media.tags.some((tag) => tag.startsWith(filterId));
    return this.media.tags.some((tag) => tag == filterId);
  }

  get stateInfo() {
    return { contact: {id: this.user.id, name: this.user.name}, time: Date.now() }
  }

  get stateInfoTemplateContext() {
    return  get(this.media, 'review.state', MediaReviewState.Pending) != MediaReviewState.Pending
          ? { data: { name: get(this.media.review, 'contact.name'),
                      text: `media.${this.media.review.state}By`,
                      class: this.media.review.state }}
          : this.media.published
            ? { data: { name: get(this.media.published, 'contact.name'),
                        text:'media.publishedBy',
                        class:'published' }}
            : null;
  }

  get isTemporaryMigrationAdmin() : boolean {
    return !!this.user && (this.user.partnerId=='547092' || this.user.partnerId=='264723');
  }

  get isEditor() : boolean {
    return !!this.media?.editable;
  }

  scrollTo(target: string, retry = 0) {
    // Use target instead
  }

  protected merge(source1: any, source2: any): any {
    const customizer = (objValue: any, srcValue: any, key: any, object: any, source: any, stack: any) => {
      if (isArray(objValue)) {
        return uniq(objValue.concat(srcValue));
      }
    };
    // sources are applied to destination from left to right
    return mergeWith({}, source1, source2, customizer);
  }

  onMediaChange(event) {
    this.mediaChange.emit(event);
  }

  @Input()
  set target(target: string) {
    //console.trace("ACCORDION.target",target);
    if (target === 'rating') {
      this.editMode = false;
    }
    this._target = target;
  }

  get target(): string {
    return this._target;
  }

  @Input()
  set startGap(gap: number) {
    if(this._startGap !== gap) {
     this._startGap = gap;
    }
  }

  get startGap() {
    return this._startGap;
  }

  get endGap() {
    return this.editMode ? this._startGap : 0; // Toolbars always have the same height
    // return this.buttonBar?.nativeElement?.offsetHeight;
  }

  isMediaFormUnchanged(): boolean {
    // const allControlsPristine = Object.keys(this.mediaForm.controls).every(key => {
    //   return this.mediaForm.controls[key].pristine;
    // });
    // if(this.mediaForm.get('author').value) {
    //   const authorChanged = this.mediaForm.get('author').value.id != this.media.author?.id;
    //   if(authorChanged) {
    //     return false;
    //   }
    // }
    // return this.mediaForm.invalid || (allControlsPristine && !this.tagsDirty && !this.countriesChanged);
    return this.mediaForm.invalid || this.mediaForm.pristine;
  }
}
