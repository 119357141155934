<input #input
       type="text"
       unmodified-input
       numberOnly
       maxlength="15"
       matInput
       value="{{value}}"
       [placeholder]="placeholder | translate"
       [disabled]="disabled"
       [required]="required"
       [attr.aria-labelledby]="formField?.getLabelId()"
       (input)="onInput($event)"/>
