import {Topic} from "core";
import {EMPTY_ARRAY} from "core";
import {FilterHandler} from "./models";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {FilterData, FilterState, State} from "./reducers";

export function createFilterDataKey(path:string,view?:string):string {
  return `${path}:${view}`;
}

// export function isMatchingFilterTopic(id:string, path:string): boolean {
//   return !!id && !!path
//     ? id.length==path.length ? id==path : id.startsWith(path) && id.charAt(path.length)=='.'
//     : false;
// }
//
// export function getFilterTopics(filters:Topic[],root:Topic /*can be undefined*/,level:number,path:string/*can be undefined*/,view:string /*can be undefined*/,alternativePaths:{[key:string]:any},
//                                 registry: {[type:string]:FilterHandler},
//                                 predicate:(filter:Topic,root:Topic /*can be undefined*/,level:number,path:string,view?:string)=>boolean = undefined): Topic[] {
//   console.log("getFilterTopics",filters,"root",root,"level",level,"path",path);
//   const regexs:{[key:string]:RegExp} = {};
//   const result = (filters ?? <Topic[]>EMPTY_ARRAY)
//     // prepare filter...
//     .map(filter => {
//       filter = {
//         ...filter,
//         properties: !!filter.properties ? {...filter.properties} : {}
//       }
//       filter.properties.paths = {...alternativePaths, ...filter.properties?.paths??{}};
//       console.log("FILTER",filter,"ALTERNATIVES",alternativePaths,"A",(!filter.type || (level==0 && !!registry[filter.type]?.canHandleFilter(filter))),"B",(!!filter.type || level>0 || filter?.topics?.length>0),"C",(!path || isMatchingFilterTopic(filter.id,path) ||
//         // or if paths are specified, it must match exactly or by pattern to one of them...
//         !!Object.keys(filter.properties.paths)?.find(alternative => {
//           console.log("ALTERNATIVE",alternative);
//           if (path==alternative) {
//             return true;
//           } else {
//             let regex = regexs[alternative];
//             if (!regex) {
//               regexs[alternative] = regex = new RegExp(alternative);
//             }
//             return regex.test(path);
//           }
//         })));
//       return filter;
//     })
//     .filter(filter => (
//         // level 0 needs topics specified or has type and can be handled by registered FilterType...
//         !filter.type || (level==0 && !!registry[filter.type]?.canHandleFilter(filter))
//       ) && (
//         !!filter.type || level>0 || filter?.topics?.length>0
//       ) && (
//         // either no path or match path by id ...
//         !path || isMatchingFilterTopic(filter.id,path) ||
//         // or if paths are specified, it must match exactly or by pattern to one of them...
//         !!Object.keys(filter.properties.paths)?.find(alternative => {
//           if (path==alternative) {
//             return true;
//           } else {
//             let regex = regexs[alternative];
//             if (!regex) {
//               regexs[alternative] = regex = new RegExp(alternative);
//             }
//             return regex.test(path);
//           }
//         })
//       )
//     )
//     // no views specified or we have to match...
//     .filter(filter => {
//       const views  = !!path ? Object.keys(filter.properties.paths[path]?.views || {}) : EMPTY_ARRAY;
//       if ((
//         views.length==0 || (!!view && views.includes(view))
//       ) && (
//         // filter now by passed predicate...
//         !!predicate ? predicate(filter,root??filter,level,path,view) : true
//       )) {
//         if (filter.topics?.length>0) {
//           filter.topics = getFilterTopics(filter.topics,root??filter,level+1,path,view,filter.properties.paths,registry,predicate);
//         }
//         return true;
//       }
//       return false;
//     });
//   console.log("RESULT",result);
//   return result;
// }



export function isMatchingFilterTopic(id:string, path:string): boolean {
  const result = !!id && !!path
    ? id.length==path.length ? id==path : id.startsWith(path) && id.charAt(path.length)=='.'
    : false;
  //console.log("IS_MATCHING_FILTER.id",id,"path",path,"result",result);
  return result;
}

export function isParentFilterTopic(id:string, path:string): boolean {
  const result = !!id && !!path
    ? id.length==path.length ? id==path : path.startsWith(id) && path.charAt(id.length)=='.'
    : false;
  //console.log("IS_MATCHING_PARENT.id",id,"path",path,"result",result);
  return result;
}

export function getFilterTopics(filters:Topic[],root:Topic /*can be undefined*/,level:number,path:string/*can be undefined*/,view:string /*can be undefined*/,alternativePaths:{[key:string]:any},
                                registry: {[type:string]:FilterHandler},
                                predicate:(filter:Topic,root:Topic /*can be undefined*/,level:number,path:string,view?:string)=>boolean = undefined, resultArray:Topic[] = []): Topic[] {
  //console.log("getFilterTopics",level,filters,[...resultArray]);
  const regexs:{[key:string]:RegExp} = {};
  const excluded:{[key:string]:Topic} = {};
  const exclude:(topic:Topic)=>boolean = (topic)=>{
    if (!!parent && isParentFilterTopic(topic.id,path)) {
      excluded[topic.id] = topic;
      return true;
    }
    return false;
  }
  (filters ?? <Topic[]>EMPTY_ARRAY).forEach(filter=>{
    filter = {
      ...filter,
      properties: !!filter.properties ? {...filter.properties} : {}
    }
    filter.properties.paths = {...alternativePaths, ...filter.properties?.paths??{}};
    //console.log("FILTER",filter,"ALTERNATIVES",alternativePaths);
    //console.log("getFilterTopics.FILTER.0",filter,"registry",registry,"path",path);
    if ((
        // level 0 needs topics specified or has type and can be handled by registered FilterType...
        !filter.type || (level==0 && !!registry[filter.type]?.canHandleFilter(filter))
      ) && (
        !!filter.type || level>0 || filter?.topics?.length>0
      ) && (
        // either no path or match path by id ...
        !path || isMatchingFilterTopic(filter.id,path) ||
        // or if paths are specified, it must match exactly or by pattern to one of them...
        !!Object.keys(filter.properties.paths)?.find(alternative => {
          if (path==alternative) {
            return true;
          } else {
            let regex = regexs[alternative];
            if (!regex) {
              regexs[alternative] = regex = new RegExp(alternative);
            }
            return regex.test(path);
          }
        }) ||
        exclude(filter)
      )) {
      //console.log("STATE.FILTER",filter,"excluded",excluded[filter.id]==filter);
      const views = !!path ? Object.keys(filter.properties.paths[path]?.views || {}) : EMPTY_ARRAY;
      if (excluded[filter.id]==filter) {
        //console.log("getFilterTopics.excluded",filter,level,[...resultArray]);
        if (filter.topics?.length>0) {
          getFilterTopics(filter.topics,undefined,0,path,view,filter.properties.paths,registry,predicate,resultArray);
        }
      } else if ((
        views.length==0 || (!!view && views.includes(view))
      ) && (
        // filter now by passed predicate...
        !!predicate ? predicate(filter,root??filter,level,path,view) : true
      )) {
        //console.log("STATE.FILTER.added",filter);
        if (filter.topics?.length>0) {
          filter.topics = getFilterTopics(filter.topics,root??filter,level+1,path,view,filter.properties.paths,registry,predicate);
        }
        resultArray.push(filter);
      }
    }
  });
  //console.log("getFilterTopics.RESULT",level,[...resultArray]);
  return resultArray;
}

export function getFilterValues(values:string[],
                                filters:Topic[],path:string,view:string /*can be undefined*/,
                                registry: {[type:string]:FilterHandler},
                                temporary:Map<string,string> = new Map()): string[] {
  if (values?.length>0) {
    temporary.clear();
    const result:string[] = [];
    values.forEach(value=>{
      const index = value.indexOf('|');
      const type  = index>0 ? value.substring(0,index) : null;
      if (!temporary.has(value)) {
        result.push(value);
        temporary.set(value,type);
      }
    });
    temporary.delete(path);
    const recursive = (filters:Topic[],level:number)=>{
      filters?.forEach(filter=>{
        if (!!filter.type) {
          const handler = registry[filter.type];
          if (!!handler) {
            temporary.forEach((type,value)=>{
              if (type==filter.type && handler.canHandleValue(value)) {
                temporary.delete(value);
              }
            })
          }
        } else if (level>0) {
          temporary.delete(filter.id);
        }
        if (filter?.topics?.length>0) {
          recursive(filter.topics,level+1);
        }
      });
    }
    recursive(filters,0);
    return result.filter(value=>!temporary.has(value));
  }
  return values??[];
}

export const filterFeatureKey = 'filter';
export const selectFilterState = createFeatureSelector<State,FilterState>(filterFeatureKey);

export const selectFilterTemplates = createSelector(
  selectFilterState,
  state => state.templates
);

export const selectAllFilterData = createSelector(
  selectFilterState,
  state => state.data
);

export const selectFilterData = (path: string, view?:string) => createSelector(
  selectAllFilterData,
  (data: {[key:string]:FilterData}):FilterData => data[createFilterDataKey(path, view)]
);

export const selectFilterValues = (path: string, view?:string) => createSelector(
  selectAllFilterData,
  (data):string[] => <string[]>data[createFilterDataKey(path, view)]?.values ?? <string[]>EMPTY_ARRAY
);
