<div>
  <span class="text file">{{uploadFile.name}}</span>
  <ng-container [ngSwitch]="uploadFile.isPaused">
    <button *ngSwitchCase="true" class="play mat-mdc-icon-mini-button" mat-icon-button (click)="onTapAction('resume')" aria-label="resume">
      <mat-icon>play_circle_filled</mat-icon>
    </button>
    <button *ngSwitchDefault class="pause mat-mdc-icon-mini-button" mat-icon-button (click)="onTapAction('pause')" aria-label="pause">
      <mat-icon>pause_circle_filled</mat-icon>
    </button>
  </ng-container>
  <button class="cancel mat-mdc-icon-mini-button" mat-icon-button (click)="onTapAction('cancel')" aria-label="cancel">
    <mat-icon>cancel</mat-icon>
  </button>
</div>
<div *ngIf="uploadFile.progress as progress"
     class="progress-container"
     [class.progress]="progress.bytesUploaded < progress.bytesTotal">
  <div [style.width.%]="progress.percentage"></div>
</div>
<div>
  <span class="text">{{uploadFile.size | bytes }}</span>&nbsp;
  <span class="text">{{uploadFile.progress?.percentage}}%</span>
</div>
