<div *ngIf="!!context.search" class="search-container">
    <mat-checkbox [(ngModel)]="selectAll" (change)="setSelectAll($event.checked)">
    </mat-checkbox>
    <input class="search-field" [autoFocus]="searchFocus" [(ngModel)]="searchValue"
           (ngModelChange)="onSearch(searchValue)" type="text">
    <button *ngIf="searchValue" class="clear-icon" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div *ngIf="!!context.search" class="separator"></div>

<mat-selection-list dense>
  <virtual-section-scroller #scroller [items$]="items$" [itemsId$]="cacheId$" [startFromTop]="true"
                            [itemDefaultSize]="itemDefaultSize" [startGap]="startGap" [endGap]="endGap" (onResize)="onResize($event)">
    <ng-container *virtualFor="let item of scroller.viewportItems offset scroller.viewportOffset total scroller.items?.length;
                              let index=index;
                              let total=total;
                              let last = last;">
      <mat-list-option
        [value]="item.id" [selected]="isFilterSelected(item.id)"
        [checkboxPosition]="'before'"
        (click)="setFilter(item.id,!context?.multiselect); selectAll=false;">
        <span translate class="overflow-ellipsis">{{item.label}}</span>
      </mat-list-option>
    </ng-container>
  </virtual-section-scroller>
</mat-selection-list>
