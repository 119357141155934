import {Size} from "shared";
import {Point} from "shared";

export interface Rectangle extends Point, Size {
}

export function isPointWithinRectangle(rectangle:Rectangle,point:Point):boolean {
  return point.top>=rectangle.top && point.top<(rectangle.top+rectangle.height) &&
         point.left>=rectangle.left && point.left<(rectangle.left+rectangle.width);
}
