import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'translateFragment'
})
export class TranslateFragmentPipe implements PipeTransform {

  constructor() {}

  transform(value: string, index: number, defaultValue = ''): string {
    // const fragmentIndex = Number(index);
    // if (!isNaN(fragmentIndex)) {
    //  const parts = value.split('|');
    //  return fragmentIndex <= parts.length ? parts[fragmentIndex] : defaultValue;
    // }
    const parts = value.split('|');
    return index <= parts.length ? parts[index] : defaultValue;
  }
}
