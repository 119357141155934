import {BasePlugin, PluginOptions, Uppy} from '@uppy/core';
import {from} from "rxjs";
import {mergeMap, take, tap} from 'rxjs/operators';
import {UploadOptions} from "tus-js-client";

type VimeoUploadOptions = Pick<UploadOptions, Extract<keyof UploadOptions, 'metadata' | 'headers'>>
export interface VimeoOptions extends PluginOptions, VimeoUploadOptions {
  limit?: number
}

export class Vimeo extends BasePlugin<VimeoOptions> {
  private static API_ROOT = 'https://api.vimeo.com';
  private static ACCESS_TOKEN = '2130fbaf7c1604d6fc3b6bb9d1f9be26';
  private concurrent = 5;
  private authorization: any;
  private expireInterval = Number.MAX_SAFE_INTEGER;

  constructor(public uppy: Uppy, public opts?: VimeoOptions) {
    super(uppy, opts);
    // this.name = 'Vimeo';
    this.id = 'Vimeo';
    this.type = 'uploader';
    this.authorization = null;
    this.opts = Object.assign({ limit: 100 }, this.opts);
    this.prepareUpload = this.prepareUpload.bind(this);
    this.afterUpload = this.afterUpload.bind(this);
  }

  async prepareUpload(fileIds:string[]) {
    const { accessToken } = await this.authorize();
    fileIds.forEach((fileId) => {
       this.emit('preprocess-progress', fileId, {
        mode: 'indeterminate',
        message: 'Creating videos...'
      })
    });

    return from(fileIds).pipe(
      tap(fileId => console.log(`fetching request ${fileId}`)),
      take(fileIds.length),
      mergeMap(
        async (fileId) => {
          const file = this.uppy.getFile(fileId);
          let response = await fetch(`${Vimeo.API_ROOT}/me/videos`, {
            method: 'post',
            headers: {
              'authorization': `Bearer ${accessToken}`,
              'content-type': 'application/json',
              'accept': 'application/vnd.vimeo.*+json;version=3.4'
            },
            body: JSON.stringify({
              upload: {
                approach: 'tus',
                size: file.size
              },
              name: 'undefined', //file.meta.name.replace(/\.\w+$/, ''),
              // description: file.meta.description,
              description: file.meta.type,
              privacy: {
                "download"  : false,
                "embed"     : "public" , // private | public | whitelist
                // "view"   : "disable"  // anybody | contacts | disable | nobody | password | unlisted | users
              }
            })
          });
          const { upload, link, uri } = await response.json();
          // ISSUES:
          // 1. Service worker should be bypassed for vimeo upload requests - service worker js modification can be integrated in build process
          // 2. Resuming is now working: the HEAD request made after resume gives Upload-Offset: 0 and the file is transmitted from then beginning. - reported an issue to Vimeo (ticket #4947790)
          const tusOptions: any = Object.assign({}, file['tus'] || {}, {
            endpoint: `https://files.tus.vimeo.com/files/`, // HACK this is to appease tus-js-client
            // NOTE: This is uploadUrl instead of endpoint, different from what you might expect;
            // Vimeo pre-creates the Tus upload.
            uploadUrl: upload.upload_link,
            // with default chunkSize = Infinity the Cordova FileReader (which is used internally by tus-js-client lib
            // to read file in chunks when running on cordova/phonegap platforms) fails with an error
            // when uploading large files (maybe because of webview memory limitations as the chunk is actually the whole file)
            // and causes uppy dashboard to exit immediately.
            // (see tips from vimeo here: https://developer.vimeo.com/api/upload/videos#resumable-approach-step-2)
            chunkSize:  128000000,  // default is Infinity,
            withCredentials: false
          });
          if (this.opts.headers) {
            Object.assign(tusOptions.headers || {}, this.opts.headers);
          }
          this.uppy.setFileState(fileId, {
            uploadURL: link,
            vimeo: {
              link,
              id: uri.split('/').pop()
            },
            tus: tusOptions,
            // HACK because the Tus plugin doesn't send upload_link yet
            remote: Object.assign({}, file.remote, {
              body: Object.assign({}, file.remote.body, {
                uploadUrl: upload.upload_link
              })
            })
          });
          this.emit('preprocess-complete', fileId);
        },
        this.concurrent
      )
    ).toPromise();
  }

  async afterUpload(fileIDs:string[]) {
    const { accessToken } = this.authorization;
    fileIDs.forEach((fileID) => {
      const file = this.uppy.getFile(fileID);
      const video = (<any>file).vimeo;
      this.uppy.setFileState(fileID, {
        uploadURL: video.link
      })
    })
  }

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
    this.uppy.addPostProcessor(this.afterUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
    this.uppy.removePostProcessor(this.afterUpload);
  }

  async authorize() {
    if (this.authorization && this.authorization.expiration < Date.now()) {
      return this.authorization;
    }
    return new Promise((resolve, reject) => {
      this.authorization = {
        accessToken: Vimeo.ACCESS_TOKEN,
        expiration: Date.now() + this.expireInterval * 1000
      };
      resolve(this.authorization);
    });
    /*
    const self = this;
    // const { clientID, redirectUrl } = this.opts;
    return new Promise((resolve, reject) => {
      const localStorageKey = 'uppyVimeo' + Math.random().toString(32).slice(2)

      const query = stringify({
        client_id: clientID,
        response_type: 'token',
        redirect_uri: redirectUrl,
        state: localStorageKey,
        scope: [
          'upload',
          'edit'
        ].join(' ')
      })

      window.addEventListener('storage', onstorage)
      const vimeoWindow = window.open(`${API_ROOT}/oauth/authorize?${query}`)

      whenWindowClosed(vimeoWindow)
        .then(() => delay(2000))
        .then(() => reject(new Error('Vimeo: Login closed without getting upload permissions.')))

      function onstorage (event) {
        if (event.key === localStorageKey) {
          window.removeEventListener('storage', onstorage)
          delete localStorage[localStorageKey]

          const result = JSON.parse(event.newValue)
          ontoken(result)
        }
      }

      function ontoken ({ token, scopes, expires }) {
        if (!scopes.includes('upload')) {
          return reject(new Error('Vimeo: Did not get upload permissions.'))
        }

        self.authorization = {
          accessToken: token,
          expiration: Date.now() + expires * 1000
        }
        resolve(self.authorization)
      }
    })
    */
  }

  emit(event:string, ...args: any[]) {
    (<any>this.uppy).emit(event, ...args);
  }
}
