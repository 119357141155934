/* projects/shared/src/lib/components/topic/topic-selection-list/topic-selection-list.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host.readonly mat-selection-list mat-list-option {
  pointer-events: none;
  outline: none;
  background-color: transparent;
}
:host.readonly mat-selection-list mat-list-option.mdc-list-item:focus::before {
  display: none;
}
:host.readonly mat-selection-list mat-list-option.mdc-list-item--disabled ::ng-deep .mdc-list-item__primary-text {
  opacity: 1;
}
:host.readonly mat-selection-list mat-list-option.mdc-list-item--disabled ::ng-deep .mdc-list-item__primary-text .topic {
  opacity: var(--mdc-list-list-item-disabled-label-text-opacity);
}
:host.readonly .options .option {
  cursor: default;
}
:host.readonly .options .option.selected {
  background-color: var(--color-primary-alpha-07);
}
mat-selection-list {
  width: 100%;
  min-width: 200px;
  max-width: 100%;
  outline: none;
}
mat-list-option {
  height: auto !important;
  min-height: 48px;
  display: flex;
  align-items: center;
  padding: 6px 6px 2px;
}
mat-list-option:not(.selectable).survey::before {
  display: none;
}
mat-list-option:not(.selectable).survey .topic-container > .topic-interactive {
  border: 1px solid #ddd;
  border-radius: 8px !important;
}
mat-list-option:not(.selectable).survey .topic-container > .topic-interactive ::ng-deep .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.18) !important;
}
mat-list-option:not(.selectable).survey .topic-container > .topic-interactive .topic {
  padding-left: 48px;
}
mat-list-option:not(.selectable).survey .topic-container > .topic-interactive::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: "";
  opacity: 0;
  pointer-events: none;
}
mat-list-option:not(.selectable).survey .topic-container > .topic-interactive:hover::before {
  background-color: var(--mdc-list-list-item-hover-state-layer-color);
  opacity: var(--mdc-list-list-item-hover-state-layer-opacity);
}
mat-list-option:not(.selectable).survey .topic-container > .topic-interactive:after {
  font-family: "Material Icons";
  content: "\f1c2";
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  font-size: 24px;
  line-height: 1em;
  color: gray;
}
mat-list-option:not(.selectable) ::ng-deep .mdc-list-item__start {
  margin: 0;
}
mat-list-option:not(.selectable) ::ng-deep .mdc-list-item__start .mdc-checkbox {
  display: none;
}
mat-list-option:not(.selectable) ::ng-deep .mdc-list-item__content {
  overflow: visible;
}
mat-list-option:not(.selectable) + .options {
  margin-left: 0;
}
mat-list-option:not(:first-child) {
  margin-top: 10px;
}
mat-list-option::ng-deep .mdc-list-item__start {
  margin-right: 0;
}
.topic-container {
  overflow: visible;
  position: relative;
}
.topic-container .topic-interactive {
  align-items: center;
  display: flex;
  overflow: hidden;
}
.topic-container .topic-interactive .topic {
  flex: 1;
}
.topic-container .topic-interactive .topic::ng-deep > div > div > div {
  display: block;
  font-size: 80%;
  line-height: 1.1em;
}
.topic {
  display: flex;
  align-items: center;
}
.topic div {
  flex: 1 1 auto;
  white-space: normal;
  word-break: break-word;
  margin-top: 2px;
  margin-bottom: 2px;
}
.topic button {
  pointer-events: initial;
}
.options {
  max-height: 0;
  transition: max-height 250ms ease-in;
  overflow: hidden;
  margin-left: 48px;
}
.options.open {
  max-height: 600px;
  margin-top: 4px;
}
.options .option {
  background-color: #ddd;
  color: gray;
  padding: 3px 6px;
  border-radius: 3px;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 3px;
  cursor: pointer;
}
.options .option.selected {
  background-color: var(--color-primary);
  color: var(--color-primaryContrast);
}
:host-context(.color-scheme-dark) :host.readonly mat-list-option {
  background-color: transparent;
}
:host-context(.color-scheme-dark) :host.readonly .options .option.selected {
  background-color: #777;
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .options .option {
  background-color: #333;
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .options .option.selected {
  background-color: var(--color-primary);
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) mat-list-option.survey:not(.selectable) {
}
:host-context(.color-scheme-dark) mat-list-option.survey:not(.selectable) ::ng-deep .mat-list-item-ripple {
  border-color: rgba(221, 221, 221, 0.5333333333);
}
:host-context(.color-scheme-dark) mat-list-option.survey:not(.selectable) ::ng-deep .mat-list-item-ripple:after {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) mat-list-option:not(.selectable).survey .topic-container > .topic-interactive {
  border-color: #777;
}
:host-context(.color-scheme-dark) mat-list-option:not(.selectable).survey .topic-container > .topic-interactive ::ng-deep .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
:host-context(.color-scheme-dark) mat-list-option:not(.selectable).survey .topic-container > .topic-interactive:hover::before {
  background-color: var(--mdc-list-list-item-hover-state-layer-color);
  opacity: var(--mdc-list-list-item-hover-state-layer-opacity);
}
:host-context(.color-scheme-dark) mat-list-option:not(.selectable).survey .topic-container > .topic-interactive:after {
  color: gray;
}
/*# sourceMappingURL=topic-selection-list.component-FS3J6KYR.css.map */
