/* projects/upload/src/lib/components/upload-list/upload-list.component.scss */
:host {
  display: block;
}
mat-selection-list {
  padding: 0;
}
mat-selection-list mat-list-option {
  height: auto;
  overflow: hidden;
}
mat-selection-list mat-list-option ::ng-deep .mdc-list-item__end .mdc-checkbox {
  display: none;
}
mat-selection-list mat-list-option upload-list-item {
  display: block;
  padding: 5px 0;
}
/*# sourceMappingURL=upload-list.component-SPMU632E.css.map */
