/* projects/shared/src/lib/components/thumb-navigation/grip.component.scss */
:host > div {
  height: 1em;
  display: inline-block;
  width: 56px;
}
:host > div > div {
  position: absolute;
  display: block;
  top: 0;
  left: 4px;
  right: 4px;
  bottom: 0;
}
:host > div > div > div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
:host > div > div > div > div {
  width: 100%;
  height: 0px;
  margin: 4px 0px;
  box-sizing: border-box;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #ccc;
}
:host > div > div > div > div:first-child {
  margin-top: 0;
}
:host > div > div > div > div:last-child {
  margin-bottom: 0;
}
/*# sourceMappingURL=grip.component-JXGJFQVF.css.map */
