import {Pipe, PipeTransform} from "@angular/core";

@Pipe({ name: 'bytes' })
export class BytesPipe implements PipeTransform {

  protected units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  public constructor() {}

  transform(bytes: number, decimals = 2): any {
    if (bytes === 0) return '0 Bytes';
    decimals = decimals < 0 ? 0 : decimals;
    const k = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + this.units[i];
  }
}
