import {AfterContentInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output} from '@angular/core';

export interface ModifiedEvent {
  element: HTMLElement;
  mutations: MutationRecord[];
}

/** michael sigmund ... a support....
 *
 * add following attributes to any component element you want to survail:
 * <div (onAttach)="ngOnAttach()" (onDetach)="ngOnDetach()">
 *
 * ngOnAttach is fired when the component is already attached to the dom
 * tree or at the moment it is attached.
 *
 * ngOnDetach is fired when the component is detached. but not if
 * the component is initially detached.
 */
@Directive({
  selector: '[onDomModified]'
})
export class DomModifiedDirective implements AfterContentInit, OnDestroy {
  @Output() onDomModified: EventEmitter<ModifiedEvent> = new EventEmitter();
  @Input() subtreeDomMutations:boolean = false;
  protected mutationObserver : MutationObserver;
  protected element:HTMLElement;

  public constructor(protected elementRef: ElementRef) {
  }

  public ngOnDestroy(): void {
    this.mutationObserver?.disconnect();
    this.onDomModified.complete();
  }

  public ngAfterContentInit(): void {
    this.element = this.elementRef.nativeElement;
    this.onDomModified.emit({element:this.element,mutations:[]});
    this.mutationObserver = new MutationObserver((mutations : MutationRecord[], observer : MutationObserver) => {
      this.onEvent(mutations,observer);
    });
    this.mutationObserver.observe(this.element,this.options);
  }

  protected onEvent(mutations : MutationRecord[], observer : MutationObserver): void {
    let event:ModifiedEvent = {element:this.element,mutations};
    this.onDomModified.emit(event);
    if (this.element!=event.element && event.element) {
      //console.log("ModifiedDirective.onEvent.CHANGE",event);
      this.element = event.element;
      this.mutationObserver.disconnect();
      this.mutationObserver.observe(this.element,this.options);
    }
  }

  public get options():MutationObserverInit {
    return {
      characterData: true,
      subtree: this.subtreeDomMutations,
      childList: true,
      attributes: true,
    };
  }
}
