/* projects/media/src/lib/components/media-actions/media-actions.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  flex: 1;
  position: relative;
}
slide-panel {
  position: absolute;
  width: 100%;
  height: 100%;
}
slide-panel .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  overflow-y: auto;
}
mat-list {
  position: relative;
  padding: 0 0 16px;
}
mat-list mat-list-item {
  padding: 0;
  height: auto;
}
mat-list mat-list-item .row {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 36px;
  height: auto;
}
mat-list mat-list-item .row.editable {
  cursor: pointer;
}
mat-list mat-list-item .row .action {
  flex: 1;
  display: flex;
  position: relative;
}
mat-list mat-list-item .row .action mat-icon {
  position: absolute;
  left: 0;
}
mat-list mat-list-item .row .action .label {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  padding-left: 32px;
}
mat-list mat-list-item mat-divider {
  top: 0;
}
mat-list > .action {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.details {
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.54);
  font-size: 13.5px;
}
.details > span {
  display: block;
  word-break: break-all;
}
a.action {
  font-size: 0.8rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
a.action:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: underline;
}
form {
  display: flex;
  flex-direction: column;
}
form mat-form-field:first-of-type {
  margin-top: 24px;
}
form mat-form-field.mat-mdc-form-field-has-icon-prefix ::ng-deep .mat-mdc-form-field-infix .mdc-floating-label {
  transform: translateX(-26px) translateY(-150%) scale(0.75) !important;
}
form button.back {
  position: absolute;
  top: 0;
  left: 0;
}
form button.save {
  align-self: flex-end;
}
:host-context(.color-scheme-dark) .details {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) a.action {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) a.action:hover {
  color: rgba(174, 175, 176, 0.8);
}
/*# sourceMappingURL=media-actions.component-3RLJHJYL.css.map */
