import {forwardRef, InjectionToken, NgModule} from '@angular/core';
import {isStorageAvailable, SyncStorage} from "./sync/storage";
import {IsolatedStorage} from "./sync/isolated.storage";
import {APP_ID} from "../app/app.provider";
import {AsyncStorageFactory} from "./async/storage";
import {IndexedDBStorageFactory} from "./async/idb.storage.factory";

export const SESSION_STORAGE = new InjectionToken<SyncStorage>('SESSION_STORAGE');
export const LOCAL_STORAGE = new InjectionToken<SyncStorage>('LOCAL_STORAGE');

export function sessionStorageFactory(appId: number): SyncStorage {
  try {
    if (typeof sessionStorage as any !== 'undefined' && isStorageAvailable(sessionStorage)) {
      return new IsolatedStorage(sessionStorage, appId);
    }
  } catch {}
  return null;
}

export function localStorageFactory(appId: number): SyncStorage {
  try {
    if (typeof localStorage as any !== 'undefined' && isStorageAvailable(localStorage)) {
      return new IsolatedStorage(localStorage, appId);
    }
  } catch {}
  return null;
}

@NgModule({
  providers: [
    { provide: SESSION_STORAGE, deps: [APP_ID], useFactory: sessionStorageFactory },
    { provide: LOCAL_STORAGE, deps: [APP_ID], useFactory: localStorageFactory },
    { provide: AsyncStorageFactory, useClass: IndexedDBStorageFactory }
  ]
})
export class StorageModule {
}
