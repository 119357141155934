import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  Renderer2,
  signal,
  SimpleChange,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import {take} from "rxjs/operators";
import {OnDomModified} from "../../directives/dom/dom_hooks";
import {EMPTY_ARRAY, IndexedArrayHooks, Logger, manualSlice, Platform} from "core";
import {Alignment, Direction} from "./virtual-scroll.enums";
import {SubscribableValue, VirtualScrollHandler} from "./virtual-scroll.handler";
import {TouchScrollHandler} from "./touch-scroll.handler";
import {StandardScrollHandler} from "./standard-scroll.handler";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {BasicContainerComponent, ObservableSubscription} from "../basic-container/basic-container.component"
import {getElementSize} from "../../util/element.util";
import {ResizeEvent} from "../../directives/resize/resize.event";
import {Size} from "../../types/size";
import {isValidNumber} from "../../util/number.util";
import {ResizeService} from "shared/lib/directives/resize/resize.service";

export class ViewportUpdateAutoscroll {
  constructor() {
  }
}

export class Future<T> {
  protected _callback:(T)=>void;
  public parameter:T;
  constructor(callback?:(T)=>void) {
    this.then(callback);
  }
  then(callback:((T)=>void)|Future<T>):Future<T> {
    if (callback instanceof Future) {
      return this.then((parameter:T)=>callback._callback?.(callback.parameter ?? parameter));
    } else if (callback) {
      let   done     = false;
      const previous = this._callback;
      this._callback = (parameter:T)=> {
        if (!done) {
          done = true;
          this.call(previous);
          this.call(callback);
        }
      }
    }
    return this;
  }
  get callback():(T)=>void {
    return this._callback || ((T)=>{});
  }
  done() {
    this.call(this._callback);
    this._callback = undefined;
  }
  protected call(callback:(T)=>void) {
    if (callback) {
      try {
        callback(this.parameter);
      } catch (e) {
      }
    }
  }
}

export interface Dimensions {
  itemCount: number;
  itemOffsets: number[];  // end offset from start. (eg 15,45 means first item height 15, next 30)
  viewportSize: number;
  scrollSize: number;
  scrollMaxPosition: number;
  renderedIndex: number;
  renderedCount: number;
  renderedSize: number;
  padding: number;
  anchorPosition: number;     // center of render expansion/shrink
}

interface BasicPageInfo {
  // viewport info
  viewportSize: number;
  bufferedStartIndex: number;
  visibleStartIndex: number;
  visibleEndIndex: number;
  bufferedEndIndex: number;
  // pixel infos of visible area
  scrollStartPosition: number;    // top/left position
  scrollEndPosition: number;      // bottom/right position
  scrollMaxPosition: number;      // max top/left position (scrollSize - visible area size)
  scrollSize: number;             // total pixle size of list (all items estimated or measured)
  startGap: number;               // gap before list
  endGap: number;                 // gap after list
}

export interface VirtualScrollEvent extends BasicPageInfo {
}

export interface VirtualRenderEvent extends BasicPageInfo {
  renderedIndex: number;
  renderedCount: number;
  renderedSize: number;
}

export interface PageInfo extends BasicPageInfo {
  expectedCount: number;
  expectedSize: number;       // expected size of bufferedStartIndex to bufferedEndIndex (before rendering
}

export interface Viewport extends PageInfo {
  // viewport rendering infos
  renderedIndex: number;      // current rendered index
  renderedCount: number;      // current rendered count
  renderedSize: number;       // current rendered size (real rendered size, expectedSize must be adapted then)
  renderedItems: any[];       // current rendered viewport items, undefined if invalid!!
  padding: number;
  anchorPosition: number;     // center of render expansion/shrink
  anchorFixation: boolean;
  anchorRenderRelease: boolean; // release fixation after render, true by default, false if we scrollTo...
  scrollPosition: number;
  scrollDirection: Direction;
  itemCount: number;
  itemOffsets: number[];
}

export interface ViewportUpdateInfo {
  pass:number;
  itemsChanged?:boolean;
  itemsUpdated?:boolean;
  resized?:boolean;
  scrolled?:boolean;
  rendered?:boolean;
  finished:Future<Viewport>;
  items:any[];
  itemsId:string;
}

@Component({
  selector: 'virtual-scroller',
  template: `
    <div class="viewport" #viewport (onResize)="onResize($event)">
      <ng-container *ngTemplateOutlet="viewportStartComponentTemplateRef; context: { gap: startGap }"></ng-container>
      <div (onAttach)="ngOnAttach()" (onDetach)="ngOnDetach()"
           [dragHandler]="virtualScrollHandler"
          class="scroll-container" #scrollContainer>
        <div class="render-container" #renderContainer [style.padding]="padding$()">
          <ng-container *ngTemplateOutlet="renderStartComponentTemplateRef; context: { gap: startGap }"></ng-container>
          <div (onDomModified)="onDomModified()" class="item-container" #itemContainer>
            <ng-content></ng-content>
          </div>
          <ng-container *ngTemplateOutlet="renderEndComponentTemplateRef; context: { gap: endGap }"></ng-container>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="viewportEndComponentTemplateRef; context: { gap: startGap }"></ng-container>
    </div>
    <ng-container *ngIf="scrollbar">
      <virtual-scrollbar [virtualScrollHandler]="virtualScrollHandler"></virtual-scrollbar>
    </ng-container>
    <ng-container
      *ngTemplateOutlet="navigationTemplate; context: { virtualScrollHandler: virtualScrollHandler }">
    </ng-container>
  `,
  host: {
    '[class.horizontal]': "!vertical",
    '[class.vertical]': "vertical"
  },
  styleUrls: ['./virtual-scroller.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualScrollerComponent extends BasicContainerComponent implements OnDomModified {

  protected _translatePaddingProperty = 'translateY';
  protected _marginPaddingProperty    = 'margin-top';
  protected _containerSizeProperty    = 'height';
  protected _contentStartFixProperty  = 'top';
  protected _contentEndFixProperty    = 'bottom';
  protected _sizeProperty             = 'offsetHeight';

  public items:any[] = [];
  public itemsId:string;

  protected itemsOS:ObservableSubscription<any[]> = this.createObservableSubscription((previousValue,currentValue, first)=> {
    this.triggerChange('items',new SimpleChange(previousValue??EMPTY_ARRAY,currentValue??EMPTY_ARRAY,first));
  },EMPTY_ARRAY);
  protected itemsIdOS:ObservableSubscription<string> = this.createObservableSubscription((previousValue,currentValue, first)=> {
    //console.log("ITEMS.ID$.previous",previousValue,"current",currentValue,"equals",previousValue === currentValue);
    if (previousValue!=currentValue) {
      this.triggerChange('itemsId',new SimpleChange(previousValue,currentValue,first));
    }
  });

  @Input()
  public set items$(value: Observable<any[]>) {
    //console.log("ITEMS$.equals",this.itemsOS.get() === value);
    this.itemsOS.set(value);
  }
  public get items$(): Observable<any[]> {
    return this.itemsOS.get();
  }
  @Input()
  public set itemsId$(value: Observable<string>) {
    this.itemsIdOS.set(value);
  }
  public get itemsId$(): Observable<string> {
    return this.itemsIdOS.get();
  }

  protected _vertical:boolean = true;
  @Input()
  public get vertical():boolean {
    return this._vertical;
  };
  public set vertical(value:boolean) {
    this._vertical = value;
    this.updatePadding();
  }
  @Input()
  public scrollbar:boolean = true;
  @Input()
  public startFromTop:boolean = true;
  @Input()
  public itemDefaultSize:number = 80;

  private static counter: number = 0;
  protected logger:Logger = new Logger('VirtualScrollerComponent.'+VirtualScrollerComponent.counter++);

  protected _bufferSize: number = 400;
  @Input()
  public get bufferSize(): number {
    return this._bufferSize;
  }
  public set bufferSize(value: number) {
    this._bufferSize = Math.max(10,value);
  }

  protected _minimumBufferSize: number = 10;
  @Input()
  public get minimumBufferSize(): number {
    return this._minimumBufferSize;
  }
  public set minimumBufferSize(value: number) {
    this._minimumBufferSize = Math.max(10,value);
  }

  protected _startGap:number = 0;
  protected _endGap:number = 0;
  protected _sideGap:number = 0;

  public padding$ = signal<string>('');

  @Input()
  public get sideGap(): number {
    return this._sideGap;
  }
  public set sideGap(gap:number) {
    this._sideGap = gap;
    this.updatePadding();
  }
  protected updatePadding() {
    const vertical = this.vertical;
    const sideGap = this.sideGap;
    if (sideGap>0) {
      this.padding$.set(vertical ? `0 ${sideGap}px` : `${sideGap}px 0`);
    } else {
      this.padding$.set('');
    }
  }

  @Input()
  public get startGap(): number {
    return this._startGap;
  }
  public set startGap(gap:number) {
    this._startGap = gap;
  }

  @Input()
  public get endGap(): number {
    return this._endGap;
  }
  public set endGap(gap:number) {
    this._endGap = gap;
  }

  @Input()
  public viewportStartComponentTemplateRef: TemplateRef<any>;
  @Input()
  public viewportEndComponentTemplateRef: TemplateRef<any>;
  @Input()
  public renderStartComponentTemplateRef: TemplateRef<any>;
  @Input()
  public renderEndComponentTemplateRef: TemplateRef<any>;

  @Output()
  public onAttached = new EventEmitter<boolean>();

  @Output()
  public onViewportChanged = new EventEmitter<void>();

  @Output()
  public onViewportUpdated = new EventEmitter<{current:Viewport,previous:Viewport}>();

  public synchronizeOnUpdate = true;
  protected rendering = false;
  protected _viewportItems: any[] = [];
  protected _viewportOffset: number = 0;
  public get viewportItems(): any[] {
    return this._viewportItems;
  }
  protected setViewportItems(viewportItems:any[], index:number, items:any[]) {
    if (this.log) console.log("SCROLLER.setViewportItems",viewportItems?.length,"index",index,"total",items?.length);
    //console.log("DISPLAY:rowsViewportChanged.=============================================="
    //  ,viewportItems,viewportItems.length,"offset",index,"items",items,items.length,"\nviewport",{...this.previousViewport});
    //if (this._viewportItems != viewportItems && (this._viewportItems.length>0 || viewportItems.length>0)) {
    //  this.triggerChange('viewportItems',new SimpleChange(this._viewportItems,viewportItems,false));
    //}
    //if (this._viewportOffset != index) {
    //  this.triggerChange('viewportOffset',new SimpleChange(this._viewportOffset,index,false));
    //}
    this._viewportItems = viewportItems;
    this._viewportOffset = index;
    this.changeDetectorRef.markForCheck();
    this.onViewportChanged.emit();
    //this.changeDetectorRef.detectChanges();
  }
  public get viewportOffset(): number {
    return this.previousViewport?.bufferedStartIndex || this._viewportOffset;
  }

  @Input()
  public scrollAnimationTime: number = 750;

  protected _scrollThrottlingTime: number = 0;
  @Input()
  public get scrollThrottlingTime(): number {
    return this._scrollThrottlingTime;
  }
  public set scrollThrottlingTime(value: number) {
    this._scrollThrottlingTime = value;
    this.updateOnScrollFunction();
  }

  protected _scrollDebounceTime: number = 0;
  @Input()
  public get scrollDebounceTime(): number {
    return this._scrollDebounceTime;
  }
  public set scrollDebounceTime(value: number) {
    this._scrollDebounceTime = value;
    this.updateOnScrollFunction();
  }

  virtualScrollHandler: VirtualScrollHandler;
  protected scrollFunction: () => void;
  protected updateOnScrollFunction(): void {
    if (this.scrollDebounceTime) {
      //console.log("SCROLLER.ONSCROLL.debounce",this.getScrollStartPosition());
      this.scrollFunction = <any>this.debounce(this.onScroll.bind(this), this.scrollDebounceTime);
    } else if (this.scrollThrottlingTime) {
      //console.log("SCROLLER.ONSCROLL.throttleTrailing",this.getScrollStartPosition());
      this.scrollFunction = <any>this.throttleTrailing(this.onScroll.bind(this), this.scrollThrottlingTime);
    } else {
      this.scrollFunction = this.onScroll.bind(this);
    }
  }

  @Input()
  public compareItem: (item1: any, item2: any) => boolean = (item1: any, item2: any) => item1 === item2;

  @Input()
  public getRenderedItemCount: (container:HTMLElement) => number = (container:HTMLElement) => container?.children?.length ?? 0;

  @Input()
  public getRenderedItemSize: (container:HTMLElement,index:number) => Size = (container:HTMLElement,index:number) => getElementSize(<HTMLElement>container?.children.item(index));

  @Output()
  public onScrolled: EventEmitter<VirtualScrollEvent> = new EventEmitter<VirtualScrollEvent>();

  @Output()
  public onRendered: EventEmitter<VirtualRenderEvent> = new EventEmitter<VirtualRenderEvent>();

  @Output()
  public afterDomModified: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('itemContainer', { read: ElementRef, static: true })
  protected itemContainerElementRef: ElementRef;

  @ViewChild('renderContainer', { read: ElementRef, static: false })
  protected renderContainerElementRef: ElementRef;

  @ViewChild('scrollContainer', { read: ElementRef, static: false })
  protected scrollContainerElementRef: ElementRef;

  @ViewChild('viewport', { read: ElementRef, static: false })
  protected viewportElementRef: ElementRef;

  @ContentChild('navigationTemplate', { static: true })
  navigationTemplate: TemplateRef<any>;

  attached:boolean = false;

  public get viewportSize():number {
    return this.previousViewport?.viewportSize ?? 0;
  }
  public isVisibleIndex(index:number):boolean {
    //console.log("VISIBLE_INDEX?",index,"start",this.previousViewport.visibleStartIndex,"end",this.previousViewport.visibleEndIndex,"vp",this.previousViewport.viewportSize);
    if (this.isValidIndex(index) &&
        this.previousViewport.visibleStartIndex<=index &&
        this.previousViewport.visibleEndIndex>=index) {
      return true;
    }
    return false;
  }

  public isValidIndex(index:number):boolean {
    if (index>=0 && index<this.items?.length) {
      return true;
    }
    return false;
  }

  public ngOnAttach(): void {
    this.attached = true;
    this.triggerChanges();
    this.virtualScrollHandler.onAttach();
    const scrollPosition = this.getScrollStartPosition();
    if (this.previousViewport?.scrollStartPosition!==undefined &&
      this.previousViewport.scrollStartPosition!=scrollPosition) {
      this.setScrollStartPosition(this.previousViewport.scrollStartPosition);
    }
    this.updateViewport(false, false, true,true)
      .then((previousViewport)=>this.triggerViewportUpdated(this.previousViewport,previousViewport,"onAttach"));
    this.onAttached.emit(true);
  }

  public ngOnDetach(): void {
    this.virtualScrollHandler.onDetach();
    this.attached = false;
    this.onAttached.emit(false);
  }

  protected _resizeAnimationFrame:number = undefined;
  public onResize(event:ResizeEvent): void {
    //const viewportElement      = this.getViewportElement();
    //const viewportSize         = viewportElement[this._sizeProperty];
    //console.log("SCROLLER.onResize",viewportSize,event);
    if (this._resizeAnimationFrame) {
      window.cancelAnimationFrame(this._resizeAnimationFrame);
    }
    this._resizeAnimationFrame = window.requestAnimationFrame(()=>{
      if (this.log) console.log("SCROLLER.onResize.triggered","\nprev.width",event.previousSize.width,"height",event.previousSize.height,"\ncurr.width",event.currentSize.width,"height",event.currentSize.height);
      if ((event.previousSize.width==0 || event.previousSize.height==0) &&
          (event.currentSize.width>0 || event.currentSize.height>0)) {
        this.resetViewport();
        this.triggerChange('items',new SimpleChange(EMPTY_ARRAY,this.items??EMPTY_ARRAY,true));
        //this.items$ = this.items$;
      }
      this.updateViewport(false,false,true, true)
          .then((previousViewport)=>this.triggerViewportUpdated(this.previousViewport,previousViewport,"onResize"));
      window.requestAnimationFrame(()=>{
        this.resizeService.triggerSync();
      });
    });
    //this.virtualScrollHandler.resetPosition();
  }

  protected renderedItemCount(container:HTMLElement):number {
    return container?.children?.length ?? 0;
  }

  protected renderedItemSize(container:HTMLElement,index:number):Size {
    return getElementSize(<HTMLElement>container?.children.item(index));
  }

  protected isViewportUpdated(items:any[],viewportOffset:number,viewportItems:any[],previousIndex:number,previousSize):boolean {
    previousIndex = previousIndex ?? 0;
    previousSize  = previousSize  ?? 0;
    viewportOffset = viewportOffset ?? 0;
    const length  = items?.length ?? 0;
    return previousIndex!=viewportOffset ||
           previousSize!=viewportItems?.length ||
           previousIndex<0 ||
          (previousIndex+previousSize)>length ||
           viewportItems.findIndex((value,index)=>value!==items[viewportOffset+index])>=0;
  }

  protected resetViewport() {
    //console.log("SCROLLER.resetViewport");
    this._viewportItems = [];
    this.previousViewport = <any>{};
  }

  public onDomModified(): void {
    //console.log("SCROLLER.DOMLOG.ON_DOM_MODIFIED",this.viewportItems?.length);
    this.afterDomModified.emit();
  }

  public scrollToIndex(index: number, alignment: Alignment = Alignment.start, animationMilliseconds: number = undefined, animationCompletedCallback: () => void = undefined): void {
    //console.trace("scrollToIndex",index,"alignment",alignment,"animationMilliseconds",animationMilliseconds);
    if (index>=0 && index<this.items.length && !!this.previousViewport) {
      animationMilliseconds = animationMilliseconds === undefined ? this.scrollAnimationTime : animationMilliseconds;
      const viewportStart = this.virtualScrollHandler.getComputedPosition();
      const viewportSize  = this.previousViewport.viewportSize;
      const viewportEnd   = viewportStart+viewportSize;
      const itemStart     = index==0 ? this.startGap : this.previousViewport.itemOffsets[index-1];
      const itemEnd       = this.previousViewport.itemOffsets[index];
      const itemSize      = itemEnd-itemStart;
      const inside        = itemStart>=viewportStart && itemEnd<=viewportEnd;
      const visible       = itemEnd>viewportStart && itemStart<viewportEnd;
      if ((alignment==Alignment.inside && inside) ||
          (alignment==Alignment.start && viewportStart==itemStart) ||
          (alignment==Alignment.end && viewportEnd==itemEnd) ||
          (alignment==Alignment.center && (itemStart-viewportStart)==(viewportEnd-itemEnd))) {
        // if (this.log) console.log("X.vscroll.scrollToIndex",index,viewportStart);
        this.virtualScrollHandler.scrollTo(viewportStart,0,animationCompletedCallback);
        return;
      } else if (alignment==Alignment.inside) {
        alignment = itemStart<viewportStart ? Alignment.start : Alignment.end;
      }
      //const scrollAnchor   = alignment==Alignment.start ? 0 :
      //                       alignment==Alignment.end ? viewportSize :
      //                       viewportSize/2;
      const targetPosition = (alignment==Alignment.start ? itemStart :
                              alignment==Alignment.end ? itemEnd-viewportSize :
                              itemStart+((itemSize-viewportSize)/2))
                            - this.previousViewport.scrollStartPosition + viewportStart;
      this.previousViewport.anchorRenderRelease = false;
      this.virtualScrollHandler.scrollTo(targetPosition,animationMilliseconds,()=>{
        //console.log("scroll.animationEnd");
        this.previousViewport.anchorRenderRelease = true;
        animationCompletedCallback?.();
      });
    }
  }

  public scrollToPosition(scrollPosition: number, animationMilliseconds: number = undefined, animationCompletedCallback: () => void = undefined): void {
    animationMilliseconds = animationMilliseconds === undefined ? this.scrollAnimationTime : animationMilliseconds;
    //console.log("scrollToPosition",scrollPosition,"animationMilliseconds",animationMilliseconds);
    // if (this.log) console.log("X.vscroll.scrollToPosition",scrollPosition);
    this.previousViewport.anchorRenderRelease = false;
    this.virtualScrollHandler.scrollTo(scrollPosition,animationMilliseconds,()=>{
      console.log("scroll.animationEnd");
      this.previousViewport.anchorRenderRelease = true;
      animationCompletedCallback?.();
    });
    //this.updateViewport(false, false, true);
  }

  public scrollToStart(animationMilliseconds: number = undefined, animationCompletedCallback: () => void = undefined): void {
    //console.log("scrollToStart");
    this.previousViewport.anchorRenderRelease = false;
    this.virtualScrollHandler.scrollTo(0,animationMilliseconds,()=>{
      //console.log("scroll.animationEnd");
      this.previousViewport.anchorRenderRelease = true;
      animationCompletedCallback?.();
    });
  }

  public scrollToEnd(animationMilliseconds: number = undefined, animationCompletedCallback: () => void = undefined): void {
    const scrollPosition = this.previousViewport?.scrollSize-this.previousViewport?.viewportSize;
    //console.log("scrollToEnd",scrollPosition);
    if (isValidNumber(scrollPosition)) {
      this.previousViewport.anchorRenderRelease = false;
      this.virtualScrollHandler.scrollTo(scrollPosition,animationMilliseconds,()=>{
        //console.log("scroll.animationEnd");
        this.previousViewport.anchorRenderRelease = true;
        animationCompletedCallback?.();
      });
    }
  }

  protected addScrollEventHandlers(): void {
    this._scrollHandlerSubscription?.unsubscribe();
    this.addSubscription(this._scrollHandlerSubscription = this.virtualScrollHandler.onScroll.subscribe(event => {
      this.scrollFunction();
    }));
  }

  protected getScrollStartPosition(): number {
    return this.virtualScrollHandler.scrollPosition;
  }

  protected setScrollStartPosition(position:number) {
    if (this.log) console.log("SCROLLER.setScrollStartPosition",position);
    // if (this.log) console.log("X.vscroll.setScrollStartPosition",position);
    this.virtualScrollHandler.scrollTo(position);
  }

  constructor(
    public readonly elementRef: ElementRef,
    protected readonly platform: Platform,
    protected readonly renderer: Renderer2,
    protected readonly zone: NgZone,
    protected changeDetectorRef: ChangeDetectorRef,
    protected resizeService: ResizeService) {
    super();
    //this.logger.debug("ctor()");
    this.virtualScrollHandler =
      this.platform.is('ios') ?
      new TouchScrollHandler(elementRef,renderer,zone) :
      new StandardScrollHandler(elementRef,renderer,zone);
    this.virtualScrollHandler.initialize(true, this.platform.is('ios'));
    this.updateOnScrollFunction();
  }

  runInZone(code:()=>void) {
    this.zone.run(code);
  }

  ngOnDestroy() {
    this.logger.debug("destroy()");
    super.ngOnDestroy();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    //console.log("this.platform",this.platform);
    this.addScrollEventHandlers();
  }

  ngAfterViewInit(): void {
    if (!this.vertical) {
      this._translatePaddingProperty = 'translateX';
      this._marginPaddingProperty    = 'margin-left';
      this._containerSizeProperty    = 'width';
      this._contentStartFixProperty  = 'left';
      this._contentEndFixProperty    = 'right';
      this._sizeProperty             = 'offsetWidth';
    }
    super.ngAfterViewInit();
    // if (this.log) console.log("SCROLLER.ngAfterViewInit");
  }

  protected needsTouchScrollHandler():boolean {
    return this.platform.is('ios') || (this.platform.is('tablet'));
  }

  protected viewportUpdated() {
  }

  protected getViewportElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  protected debounce(func: Function, wait: number): Function {
    const throttled = this.throttleTrailing(func, wait);
    const result = function () {
      throttled['cancel']();
      throttled.apply(this, arguments);
    };
    result['cancel'] = function () {
      throttled['cancel']();
    };
    return result;
  }

  protected throttleTrailing(func: Function, wait: number): Function {
    let timeout = undefined;
    let _arguments = arguments;
    const result = function () {
      const _this = this;
      _arguments = arguments
      if (timeout) {
        return;
      }
      if (wait <= 0) {
        func.apply(_this, _arguments);
      } else {
        timeout = window.setTimeout(function () {
          timeout = undefined;
          func.apply(_this, _arguments);
        }, wait);
      }
    };
    result['cancel'] = function () {
      if (timeout) {
        clearTimeout(timeout);
        timeout = undefined;
      }
    };
    return result;
  }

  protected previousViewport: Viewport = <any>{};
  protected previousViewportUpdateInfo : ViewportUpdateInfo;
  protected viewInitializedRaf: number;

  protected _scrollHandlerSubscription:Subscription|undefined;
  protected _delayedDebugInfoTimer:number;
  protected _scrollPosition:SubscribableValue<number> = SubscribableValue.of(0);

  public refresh() {
    this.updateViewport(false,true, false,false)
        .then((previousViewport)=>this.triggerViewportUpdated(this.previousViewport,previousViewport,"refresh"));
  }

  protected get canOverscroll():boolean {
    return !!this.virtualScrollHandler.bounces;
  }

  protected _triggerScroll:number = undefined;
  public scrollPosition$ = new BehaviorSubject<number>(0)
  public updateMetrics = {
    scrollEventsTotal:0,
    scrollEventsMeasured:0,
    scrollEventsDelayed:0,
    scrollMeasurements:0,
    scrollMeasurementTimes:0,
    scrollViewportUpdates:0,
    scrollViewportUpdatePrepareTimes:0,
    scrollViewportUpdateTimes:0,
    scrollViewportAdjustTimes:0,
    scrollIdles:0,
    scrollIdleTimes:0,
    scrollIdleStart:0,
  };
  protected onScroll() {
    //const scrollPosition = this.virtualScrollHandler.getComputedPosition();
    const scrollPosition = Math.round(this.virtualScrollHandler.scrollPosition);
    //console.log("onScroll",scrollPosition,"max",this.previousViewport?.scrollMaxPosition,"scrollSize",this.previousViewport.scrollSize);
    this.updateMetrics.scrollEventsTotal++;
    if (scrollPosition!=this.scrollPosition$.value) {
      //if (this.log) console.log("SCROLLER.onScroll",scrollPosition,"max",this.previousViewport?.scrollMaxPosition,"scrollSize",this.previousViewport.scrollSize);
      this.updateMetrics.scrollEventsMeasured++;
      this.scrollPosition$.next(scrollPosition);
      if (this.rendering) {
        this.updateMetrics.scrollEventsDelayed++;
        //console.log("onScroll():rendering....")
        if (this._triggerScroll) {
          window.cancelAnimationFrame(this._triggerScroll);
        }
        this._triggerScroll = window.requestAnimationFrame(()=>{
          this._triggerScroll = undefined;
          this.onScroll();
        });
      } else {
        if (this.updateMetrics.scrollIdleStart>0) {
          this.updateMetrics.scrollIdles++;
          this.updateMetrics.scrollIdleTimes += (window.performance.now()-this.updateMetrics.scrollIdleStart);
          this.updateMetrics.scrollIdleStart = 0;
        }
        const millisStart = window.performance.now();
        this.updateMetrics.scrollMeasurements++;
        if (!this.adjustOnScroll()) {
          this.updateMetrics.scrollViewportUpdates++;
          this.updateMetrics.scrollMeasurementTimes += (window.performance.now()-millisStart);
          //console.log("SCROLLER.onScroll",scrollPosition);
          this.updateViewport(false,false,false, true)
              .then((previousViewport)=>{
                this.updateMetrics.scrollViewportUpdateTimes += (window.performance.now()-millisStart);
                this.triggerViewportUpdated(this.previousViewport,previousViewport,"onScroll");
                this.updateMetrics.scrollViewportAdjustTimes += (window.performance.now()-millisStart);
                this.updateMetrics.scrollIdleStart = window.performance.now();
              });
          this.updateMetrics.scrollViewportUpdatePrepareTimes += (window.performance.now()-millisStart);
        } else {
          this.updateMetrics.scrollMeasurementTimes += (window.performance.now()-millisStart);
          this.emitScrolled(this.previousViewport);
          this.updateMetrics.scrollIdleStart = window.performance.now();
        }
      }
    }
  }

  protected _previousEvent:VirtualScrollEvent = undefined;
  protected emitScrolled(viewport:Viewport) {
    if (viewport.renderedItems?.length>0 && !this.rendering &&
       (viewport.scrollSize!=this._previousEvent?.scrollSize ||
        viewport.scrollStartPosition!=this._previousEvent?.scrollStartPosition ||
        viewport.bufferedStartIndex!=this._previousEvent?.bufferedStartIndex ||
        viewport.bufferedEndIndex!=this._previousEvent?.bufferedEndIndex ||
        viewport.viewportSize!=this._previousEvent?.viewportSize)) {
      this.onScrolled.emit(this._previousEvent = {...viewport});
    }
  }

  protected _scrollEndTrigger:number = undefined;
  protected adjustAfterScroll() {
    this.previousViewport.scrollDirection = Direction.Undefined;
  }

  protected adjustOnScroll():boolean {
    if (!!this._scrollEndTrigger) {
      window.clearTimeout(this._scrollEndTrigger);
    }
    this._scrollEndTrigger = window.setTimeout(()=>{
      this._scrollEndTrigger = undefined;
      this.adjustAfterScroll();
    },100);
    const viewport = this.previousViewport;
    if (isValidNumber(viewport?.renderedSize) &&
        isValidNumber(viewport?.viewportSize) &&
        viewport.viewportSize>0 &&
        viewport.renderedSize>0) {
      const scrollPosition = this.getScrollStartPosition();
      const normalizedScrollPosition = Math.max(0, Math.min(viewport.scrollMaxPosition,scrollPosition));
      if (viewport.renderedSize==this.itemContainerElementRef.nativeElement[this._sizeProperty]) {
       const before = normalizedScrollPosition-viewport.padding;
       const after  = viewport.padding+viewport.renderedSize-viewport.viewportSize-normalizedScrollPosition;
       const minimumBefore = Math.min(this.minimumBufferSize,Math.max(0,before));
       const minimumAfter  = Math.min(this.minimumBufferSize,Math.max(0,after));
       if ((before>=minimumBefore || viewport.renderedIndex==0) &&
           (after>=minimumAfter   || (viewport.renderedIndex+viewport.renderedCount == viewport.itemCount))) {
         //viewport.scrollDirection = scrollStartPosition<viewport.scrollStartPosition ? 1 : -1;
         viewport.scrollStartPosition = normalizedScrollPosition;
         viewport.scrollEndPosition   = Math.min(normalizedScrollPosition + viewport.viewportSize,viewport.scrollSize);
         viewport.scrollDirection     = viewport.scrollPosition<scrollPosition ? Direction.StartToEnd :
                                        viewport.scrollPosition>scrollPosition ? Direction.EndToStart : Direction.Undefined;
                                        //viewport.scrollDirection==Direction.StartToEnd ||
                                        //viewport.scrollDirection==Direction.EndToStart
                                        //? viewport.scrollDirection
                                        //: this.startFromTop ? Direction.StartToEnd : Direction.EndToStart;
         viewport.scrollPosition      = scrollPosition;
         if (!viewport.anchorFixation) {
           viewport.anchorPosition    = viewport.scrollDirection==Direction.StartToEnd ? 0 :
                                        viewport.scrollDirection==Direction.EndToStart ? viewport.scrollSize :
                                        viewport.anchorPosition;
         }
         let visibleStartIndex = viewport.bufferedStartIndex;
         let visibleEndIndex   = viewport.bufferedEndIndex;
         while (visibleStartIndex<visibleEndIndex &&
                viewport.itemOffsets[visibleStartIndex]<=viewport.scrollStartPosition) {
           visibleStartIndex++;
         }
         while (visibleEndIndex>visibleStartIndex &&
                viewport.itemOffsets[visibleEndIndex-1]>=viewport.scrollEndPosition) {
           visibleEndIndex--;
         }
         viewport.visibleStartIndex = visibleStartIndex;
         viewport.visibleEndIndex   = visibleEndIndex;
         // if (this.log) console.log("X.scroll.direction",viewport.scrollDirection,"\nscroll.position",viewport.scrollPosition,"\nscroll.size",viewport.scrollSize);
         return true;
       }
      }
    }
    return false;
  }

  //protected unhandledChanges = new Set(['virtualForOf','virtualForOffset','virtualForTotal','virtualForLog']);
  public ngOnChanges(changes: SimpleChanges): void {
    changes = this.mergeChanges(changes,this.attached);
    if (this.log) {
      console.log("ngOnChanges",changes,"attached",this.attached,"size",this.elementRef?.nativeElement.clientWidth,this.elementRef?.nativeElement.clientHeight,
        "\nitems",this.items,this.items?.length,"curr",changes?.items?.currentValue);
    }
    if (!!this.attached) {
      const previousIndex  = this.previousViewport.bufferedStartIndex ?? 0;
      const previousSize   = (this.previousViewport.bufferedEndIndex ?? -1) + 1 - previousIndex ;
      const itemsIdChanged = !!changes?.itemsId || !this.previousViewport.renderedCount;
      const itemsUpdated   = !!changes?.items &&
        (this.previousViewport.itemCount !== this.items.length ||
          this.isViewportUpdated(this.items,this.viewportOffset,this.viewportItems,previousIndex,previousSize));
      const firstRun: boolean = !changes.items || !changes.items.previousValue || changes.items.previousValue.length === 0;
      // if (this.log) console.log("SCROLLER.ngOnChanges",changes,"items",this.items.length,"itemsIdChanged",itemsIdChanged,"firstRun",firstRun,"itemsUpdated",itemsUpdated);
      //console.log("UPDATED itemsId",itemsIdChanged || firstRun,"length",indexLengthChanged,"changes",changes);
      if (itemsIdChanged || firstRun) {
        if (this.log) console.log("SCROLLER.ngOnChanges.A.RESET");
        this.resetViewport();
        this.updateViewport(true, itemsUpdated, false, false)
            .then((previousViewport)=>this.triggerViewportUpdated(this.previousViewport,previousViewport,"ngOnChanges.1"));
      } else if (itemsUpdated) {
        let   viewportIndex = -1;
        let   arrayIndex    = undefined;
        const arrayLength   = this.items.length;
        if (this.synchronizeOnUpdate &&  // is it allowed to sync view if data changes?
            this.previousViewport?.renderedCount>0 &&
            this.items?.length>0 && this.viewportItems?.length>0) {
          // sync
          const hooks:IndexedArrayHooks<any> = (<any>this.items).backingHooks;
          const array:any[]    = (<any>this.items).backingArray ?? this.items;
          const indices        = (<any>this.items).backingIndices ?? {};
          const viewportLength = this.viewportItems.length;
          const viewportOffset = this.viewportOffset;
          const viewportItems  = this.startFromTop ? this.viewportItems : this.viewportItems.reverse();
          if (array===this.items) { // normal array
            const arrayLast = arrayLength-1;
            viewportIndex = viewportItems.findIndex((value,index)=>{
              if (!!value) {
                let before = this.startFromTop ? viewportOffset+index : viewportOffset+viewportLength-index-1;
                let after  = this.startFromTop ? viewportOffset+index+1 : viewportOffset+viewportLength-index;
                while (before>=0 || after<=arrayLast) {
                  if (before>=0) {
                    if (array[before]==value) {
                      arrayIndex = before;
                      return true;
                    }
                    before--;
                  }
                  if (after<=arrayLast) {
                    if (array[after]==value) {
                      arrayIndex = after;
                      return true;
                    }
                    after++;
                  }
                }
              }
              return false;
            });
            viewportIndex = this.startFromTop ? viewportIndex : viewportIndex<0 ? viewportIndex : viewportLength-1-viewportIndex;
          } else {
            const isValue = !!hooks ? hooks.isValue : (value)=>!!value;
            const getId   = !!hooks ? hooks.getId   : (value)=>value?.id;
            const index   = viewportItems.findIndex(value=>{
              if (isValue(value)) {
                arrayIndex = indices[getId(value)];
                return isValidNumber(arrayIndex);
              }
              return false;
            });
            viewportIndex = this.startFromTop ? index : index<0 ? index : viewportLength-1-index;
            // if (this.log) console.log("arrayIndex",arrayIndex,"value",array[arrayIndex],"\nviewportIndex",viewportIndex,"("+index+")","value",viewportItems[viewportIndex],"\nviewportLength",viewportLength,"\nrealViewportIndex",this.viewportOffset+viewportIndex);
          }
        }
        if (this.log) console.log("SCROLLER.ngOnChanges.B.sync",
          "\noldViewport.size",this.viewportItems?.length,"offset",this.viewportOffset,"index",viewportIndex,
          "\noldArray.size",this.previousViewport?.itemCount,"index",this.viewportOffset+viewportIndex,
          "\nnewArray.size",arrayLength,"index",arrayIndex);
        // only if we found any viewport item and it differs in position,
        // we can adjust, else just render it or leave it as it is...
        if (viewportIndex>=0 &&
            arrayIndex!=(this.viewportOffset+viewportIndex)) {
          const previousViewport = {...this.previousViewport};
          const viewport  = this.previousViewport;
          if (this.log) console.log("SCROLLER.ngOnChanges.B2.sync\nviewportIndex",viewportIndex,"abs",this.viewportOffset+viewportIndex,"\narrayIndex",arrayIndex,"\nviewport",{...viewport});
          // displacementCount>0 ... item(s) added before found item
          // displacementCount<0 ... item(s) removed before found item
          const displacementCount = arrayIndex-(this.viewportOffset+viewportIndex);
          const displacementSize  = displacementCount==0 ? 0 :
            displacementCount>0 ? this.itemDefaultSize*displacementCount :
              this.startGap - viewport.itemOffsets[-displacementCount-1];
          viewport.renderedItems        = undefined;
          viewport.renderedIndex       += displacementCount;
          viewport.visibleStartIndex   += displacementCount;
          viewport.visibleEndIndex     += displacementCount;
          viewport.bufferedStartIndex  += displacementCount;
          viewport.bufferedEndIndex    += displacementCount;
          viewport.scrollStartPosition += displacementSize;
          viewport.scrollPosition      += displacementSize;
          viewport.scrollEndPosition   += displacementSize;
          viewport.scrollMaxPosition   += displacementSize;
          const prevOffsets    = viewport.itemOffsets;
          let   copyIndex      = displacementCount<0 ? -displacementCount : 0;
          let   copyCount      = Math.max(0,Math.min(prevOffsets.length-copyIndex,arrayLength-Math.max(0,displacementCount)));
          const itemOffsets    = viewport.itemOffsets = new Array(arrayLength);
          let   index          = 0;
          for (let offset=this.startGap;index<displacementCount;index++) {
            itemOffsets[index] = offset = offset+this.itemDefaultSize;
          }
          for (let offset=displacementSize; copyCount>0; copyCount--, copyIndex++, index++) {
            itemOffsets[index] = offset = prevOffsets[copyIndex]+displacementSize;
          }
          for (let max=arrayLength; index<max; index++) {
            itemOffsets[index] = itemOffsets[index-1]+this.itemDefaultSize;
          }
          if (this.log) console.log("SCROLLER.ngOnChanges.B2.info",itemOffsets,
            "\nviewportItems",[...this.viewportItems],
            "\nviewportIndex",viewportIndex,
            "\nprevOffsets",prevOffsets,
            "\narray",[...this.items],
            "\narrayIndex",arrayIndex,"arrayLength",arrayLength,
            "\nitemDefaultSize",this.itemDefaultSize,
            "\nviewport",{...viewport});
          //if (this.log) console.log("SCROLLER.itemOffsets.upd",itemOffsets);
          const prevRenderedStart     = viewport.bufferedStartIndex;
          const prevRenderedEnd       = viewport.bufferedEndIndex;
          viewport.bufferedStartIndex = Math.max(0,viewport.bufferedStartIndex);
          viewport.visibleStartIndex  = Math.max(0,viewport.visibleStartIndex);
          viewport.renderedIndex      = Math.max(0,viewport.renderedIndex);
          viewport.visibleEndIndex    = Math.min(arrayLength-1,viewport.visibleEndIndex);
          viewport.bufferedEndIndex   = Math.min(arrayLength-1,viewport.bufferedEndIndex);
          viewport.padding            = this.calculatePadding(viewport.renderedIndex,itemOffsets);
          // if (this.log) console.log("X.vscroll.displace",displacementSize);
          //this.virtualScrollHandler.targetPosition$.value += displacementSize;
          //this.virtualScrollHandler.scrollPosition = viewport.scrollPosition;
          //this.virtualScrollHandler.scrollSize = itemOffsets[itemOffsets.length-1]+this.endGap;
          viewport.scrollDirection    = viewport.scrollDirection==Direction.StartToEnd ||
                                        viewport.scrollDirection==Direction.EndToStart
                                        ? viewport.scrollDirection
                                        : this.startFromTop ? Direction.StartToEnd : Direction.EndToStart;
          const anchorIndex = this.startFromTop ? arrayIndex-1 : arrayIndex;
          viewport.scrollSize = itemOffsets[itemOffsets.length-1];
          viewport.itemCount  = arrayLength;
          viewport.anchorPosition = anchorIndex<0 ? 0 : itemOffsets[anchorIndex];
          viewport.anchorFixation = true;
          viewport.anchorRenderRelease = true;
          //if (this.log) console.log("CREATED.0.updated",
          //  "\nviewport",{...viewport});
          if (this.log) console.log("SCROLLER.ngOnChanges.B2.sync\nviewport",{...viewport});
          //this.virtualScrollHandler.update(viewport.viewportSize,viewport.scrollSize,viewport.scrollPosition,0,false, false);
          this.virtualScrollHandler.update(viewport.viewportSize,
            itemOffsets[itemOffsets.length-1]+this.endGap,
            viewport.scrollPosition,
            //displacementSize,
            false);
          this.updateViewport(false,true,false,displacementCount!=0)
              .then(()=>this.triggerViewportUpdated(this.previousViewport,previousViewport,"ngOnChanges.2"));
          // the calculation methods need to be switched from top/bottom fixation
          // to index fixation (top->down: fixed index; bottom->up: fixed index+1).
          //if (this.log) console.log("SCROLLER.DISPLACEMENT",displacementCount);
        } else {
          const previousViewport = {...this.previousViewport};
          const viewport    = this.previousViewport;
          if (this.log) console.log("SCROLLER.ngOnChanges.B3.sync\nviewport",{...viewport});
          const itemDefaultSize = this.itemDefaultSize;
          const itemCount   = this.items.length;
          const prevOffsets = viewport.itemOffsets || [];
          const itemOffsets = prevOffsets.length==itemCount ? prevOffsets :
            itemCount==0 ? [] :
            prevOffsets.length==0 ? Array(itemCount) :
            prevOffsets.length>itemCount ? prevOffsets.slice(0,itemCount) :
            [...prevOffsets,...Array(itemCount-prevOffsets.length)];
          if (itemOffsets!==prevOffsets) {
            for (let i=0, offset=this.startGap, current=0; i<itemCount; i++) {
              let current = itemOffsets[i];
              if (!(current>=0)) {
                itemOffsets[i] = current = offset+itemDefaultSize;
              }
              offset=current;
            }
          }
          viewport.itemCount     = itemCount;
          viewport.itemOffsets   = itemOffsets;
          viewport.renderedItems = undefined;
          viewport.scrollSize = itemOffsets[itemOffsets.length-1];
          if (arrayIndex>=0) {
            const anchorIndex = this.startFromTop ? arrayIndex-1 : arrayIndex;
            viewport.anchorPosition = anchorIndex<0 ? 0 : itemOffsets[anchorIndex];
            viewport.anchorFixation = true;
            viewport.anchorRenderRelease = true;
          }
          // if (this.log) console.log("CREATED.1.itemOffsets",itemOffsets,"prevOffsets",prevOffsets,"arrayIndex",arrayIndex,"viewport",{...viewport});
          if (this.log) console.log("SCROLLER.ngOnChanges.B3.sync\nviewport",{...viewport});
          this.updateViewport(false, true, false, false)
              .then(()=>this.triggerViewportUpdated(this.previousViewport,previousViewport,"ngOnChanges.3"));
        }
      }
    }
  }

  protected triggerViewportUpdated(currentViewport:Viewport,previousViewport:Viewport,from:string) {
    if (this.log) console.log("onViewportUpdated",from,"\ncurrent",currentViewport,"\nprevious",previousViewport);
    this.onViewportUpdated.emit({current:currentViewport,previous:previousViewport});
  }

  protected updateViewport(itemsChanged: boolean, itemsUpdated:boolean = false, resized:boolean = false, scrolled:boolean = false, updateInfo:ViewportUpdateInfo = { pass:3,finished:new Future(), items:this.items, itemsId:this.itemsId }): Future<Viewport> {
    if (this.attached) {
      if (this.log) console.log("updateViewport.items", updateInfo.items?.length, "pass", updateInfo.pass, updateInfo.pass == 3 ? 'NEW' : '');
      //note: refreshInfo.pass is to force it to keep recalculating if the previous iteration caused a re-render (different sliced items in viewport or scrollPosition changed).
      //The default of 2x max will probably be accurate enough without causing too large a performance bottleneck
      //The code would typically quit out on the 2nd iteration anyways. The main time it'd think more than 2 runs would be necessary would be for vastly different sized child items or if this is the 1st time the items array was initialized.
      //Without maxRunTimes, If the user is actively scrolling this code would become an infinite loop until they stopped scrolling. This would be okay, except each scroll event would start an additional infinte loop. We want to short-circuit it to prevent this.
      itemsChanged = updateInfo.itemsChanged = !!itemsChanged || !!this.previousViewportUpdateInfo?.itemsChanged;
      itemsUpdated = updateInfo.itemsUpdated = !!itemsUpdated || !!this.previousViewportUpdateInfo?.itemsUpdated;
      scrolled = updateInfo.scrolled = !!scrolled || !!this.previousViewportUpdateInfo?.scrolled;
      resized = updateInfo.resized = !!resized || !!this.previousViewportUpdateInfo?.resized;
      if (!!this.previousViewportUpdateInfo?.finished && this.previousViewportUpdateInfo != updateInfo) {
        updateInfo.finished.then(this.previousViewportUpdateInfo.finished);
      }
      this.previousViewportUpdateInfo = updateInfo;
      if (!this.viewInitialized) {
        this.viewInitializedRaf = this.viewInitializedRaf ?? requestAnimationFrame(() => {
          this.viewInitializedRaf = undefined;
          updateInfo = this.previousViewportUpdateInfo;
          this.updateViewport(updateInfo.itemsChanged, updateInfo.itemsUpdated, updateInfo.resized, updateInfo.scrolled, updateInfo);
        });
        return updateInfo.finished;
      }
      //if (this.log) console.log("SCROLLER.updateViewport.rendering",this.rendering,"chg",itemsChanged,"upd",itemsUpdated,"scr",scrolled,"pass",updateInfo.pass);
      if (--updateInfo.pass >= 0 && !this.rendering) {
        updateInfo.itemsChanged = false;
        updateInfo.itemsUpdated = false;
        updateInfo.resized = false;
        updateInfo.scrolled = false;
        if (itemsChanged) {
          const itemCount = updateInfo.items.length;
          const itemOffsets = itemCount == 0 ? [] : new Array(itemCount);
          for (let i = 0, offset = this.startGap, current = 0; i < itemCount; i++) {
            itemOffsets[i] = offset = offset + this.itemDefaultSize;
          }
          //console.log("CREATED.2.itemOffsets",itemOffsets);
          //if (this.log) console.log("SCROLLER.itemOffsets.new",itemOffsets);
          const scrollDirection = this.startFromTop ? Direction.StartToEnd : Direction.EndToStart;
          const scrollSize = itemCount == 0 ? 0 : itemOffsets[itemCount - 1] + this.endGap;
          const anchorPosition = this.startFromTop ? 0 : scrollSize;
          // if (this.log) console.log("X.vscroll.updateViewport.itemsChanged!",anchorPosition);
          this.virtualScrollHandler.viewportSize = 0;
          this.virtualScrollHandler.scrollSize = scrollSize;
          this.virtualScrollHandler.scrollPosition = anchorPosition;
          this.virtualScrollHandler.targetPosition$.value = anchorPosition;
          this.previousViewport = {
            itemCount, itemOffsets, scrollDirection, scrollSize,
            anchorPosition, startGap: this.startGap, endGap: this.endGap,
            anchorFixation: false,
            anchorRenderRelease: true,
            expectedSize: 0,
            expectedCount: 0,
            viewportSize: 0,
            scrollStartPosition: anchorPosition,
            scrollEndPosition: anchorPosition,
            scrollMaxPosition: scrollSize,
            scrollPosition: anchorPosition,
            bufferedStartIndex: undefined,
            bufferedEndIndex: undefined,
            visibleStartIndex: undefined,
            visibleEndIndex: undefined,
            padding: undefined,
            renderedItems: undefined,
            renderedSize: undefined,
            renderedIndex: undefined,
            renderedCount: undefined
          }
        }
        const previousViewport = updateInfo.finished.parameter = this.previousViewport;
        const emergency = !previousViewport.renderedItems;
        const viewport = this.calculateViewport(itemsChanged, previousViewport);
        const scrollSizeChanged = viewport.scrollSize != previousViewport.scrollSize;
        const viewportSizeChanged = viewport.viewportSize != previousViewport.viewportSize;
        const scrollPositionAdjust = viewport.anchorPosition != previousViewport.anchorPosition;
        const render = !this.rendering &&
          isValidNumber(viewport.bufferedStartIndex) &&
          isValidNumber(viewport.bufferedEndIndex) && (
            itemsChanged || itemsUpdated || emergency ||
            viewport.bufferedStartIndex !== previousViewport?.bufferedStartIndex ||
            viewport.bufferedEndIndex !== previousViewport?.bufferedEndIndex) &&
          (viewport.expectedCount > 0 || this.viewportItems?.length > 0);
        if (this.log) console.log("SCROLLER.render", render, "\n!this.rendering", !this.rendering,
          "itemsChanged", itemsChanged, "itemsUpdated", itemsUpdated, "emergency", emergency,
          "\nviewport.changed.start", viewport.bufferedStartIndex !== previousViewport?.bufferedStartIndex,
          "end", viewport.bufferedEndIndex !== previousViewport?.bufferedEndIndex,
          "viewport.expectedCount>0", viewport.expectedCount > 0, "this.viewportItems?.length>0", this.viewportItems?.length > 0);
        const scrollDirection = viewport.scrollDirection;
        viewport.scrollDirection = updateInfo.rendered
          ? this.startFromTop ? Direction.StartToEnd : Direction.EndToStart
          : scrolled && viewport.renderedItems?.length > 0 && Math.max(0, Math.min(viewport.scrollMaxPosition, viewport.scrollPosition)) != Math.max(0, Math.min(previousViewport.scrollMaxPosition, previousViewport.scrollPosition))
            ? viewport.scrollPosition < previousViewport.scrollPosition ? Direction.EndToStart : Direction.StartToEnd
            : viewport.scrollDirection;
        if (!viewport.anchorFixation || (!!updateInfo.rendered && viewport.anchorRenderRelease)) {
          viewport.anchorFixation = false;
          viewport.anchorPosition = viewport.scrollDirection == Direction.StartToEnd
            ? 0 : viewport.scrollSize;
        }
        const padding =
          this.startFromTop
            ? viewport.padding
            : viewport.scrollSize < viewport.viewportSize
              ? viewport.viewportSize - viewport.scrollSize
              : viewport.padding;
        /*if (this.log) console.log("updateViewport()",viewport.viewportSize,
          "\nrendered",updateInfo.rendered,
          "\nviewport.padding",viewport.padding,"new",padding,
          "\nviewport.expectedSize",viewport.expectedSize,
          "\nviewport.itemOffsets",viewport.itemOffsets,
          "\nviewport.itemCount",viewport.itemCount,
          "\nviewport.scrollPosition",viewport.scrollPosition,previousViewport.scrollPosition,
          "\nviewport.scrollDirection",viewport.scrollDirection,scrollDirection,previousViewport.scrollDirection,
          "\nviewport.itemCount",viewport.itemCount,
          "\nitemsId",this.itemsId,
          "\nviewport",{...viewport});*/
        //this.renderer.setStyle(this.renderContainerElementRef.nativeElement,'transform',`${this._translatePaddingProperty}(${padding}px)`);
        this.renderer.setStyle(this.renderContainerElementRef.nativeElement, 'top', `${Math.round(padding)}px`);
        this.previousViewport = viewport;
        if (updateInfo.rendered) {
          updateInfo.rendered = false;
          // delay, else the headers are not yet rendered....
          requestAnimationFrame(() => this.viewportUpdated());
        }
        if (scrollSizeChanged || viewportSizeChanged || scrollPositionAdjust) {
          this.virtualScrollHandler.update(
            viewport.viewportSize,
            viewport.scrollSize,
            viewport.scrollStartPosition,
            //(viewport.scrollStartPosition-viewport.scrollPosition),
            itemsChanged);
        }
        this.emitScrolled(viewport);
        if (render) {
          this.rendering = true;
          viewport.renderedIndex = viewport.bufferedStartIndex;
          viewport.renderedCount = viewport.expectedCount;
          viewport.renderedSize = viewport.expectedSize;
          viewport.renderedItems = manualSlice(updateInfo.items, viewport.bufferedStartIndex, viewport.bufferedEndIndex + 1);
          // if (this.log) console.log("X.updateViewport.render");
          this.setViewportItems(viewport.renderedItems, viewport.renderedIndex, updateInfo.items);
          this.afterRendering().then(() => {
            this.rendering = false;
            updateInfo = this.previousViewportUpdateInfo;
            updateInfo.rendered = true;
            this.virtualScrollHandler.afterRender();
            this.onRendered.emit(viewport);
            // if (this.log) console.log("SCROLLER.updateViewport\nRENDERED",updateInfo);
            this.updateViewport(updateInfo.itemsChanged, updateInfo.itemsUpdated, updateInfo.resized, updateInfo.scrolled, updateInfo);
          });
        } else {
          updateInfo.finished.done();
        }
      } else if (!this.rendering) {
        updateInfo.finished.done();
      }
    } else {
      // not attached: done without any calculations!
      updateInfo.finished.done();
    }
    return updateInfo.finished;
  }

  afterRendering():Promise<void> {
    return new Promise<void>(resolve => {
      if (!this.rendering) {
        resolve();
      } else {
        let timer = window.setTimeout(()=>{
          //console.log("SCROLLER.DOMLOG.MANUAL_DOM_MODIFIED",this.viewportItems);
          this.afterDomModified.emit();
        },500);
        this.afterDomModified.asObservable().pipe(take(1)).subscribe(() => {
          window.clearTimeout(timer);
          resolve();
        });
      }
    });
  }

  /****************************
      VIEWPORT CALCULATIONS
   ****************************/

  /**
   * calculateDimensions calculates the dimensions and if necessary
   * measures the rendered items and adjusts scrollSize, itemOffsets
   * and anchorPosition.
   * scroller is not adjusted!
   * @param itemCount         ... number of items in scroller
   * @param previousViewport  ...
   * @protected
   */
  protected calculateDimensions(previousViewport:Viewport): Dimensions {
    const viewportElement      = this.getViewportElement();
    const viewportSize         = Math.round(viewportElement[this._sizeProperty]);
    //if (this.log) console.log("SCROLLER.calculateDimensions\nviewportSize",viewportSize,"\npreviousViewport",previousViewport);
    const measureRenderedItems = !this.rendering && previousViewport.renderedItems?.length>0;
    const renderedItems        = previousViewport.renderedItems ?? [];
    const renderedIndex        = previousViewport.renderedIndex ?? 0;
    let   renderedSize         = previousViewport.renderedSize ?? 0;
    const renderedCount        = renderedItems.length;
    //console.log("viewportSize",viewportSize,"width",viewportElement['offsetWidth']);
    const itemCount            = previousViewport.itemCount;
    const itemOffsets         = previousViewport.itemOffsets;
    const itemContainerElement          = this.itemContainerElementRef.nativeElement;
    let   anchorPosition       = previousViewport.anchorPosition;
    let   anchorDifference     = 0;
    let   renderedDifference   = 0;
    const padding              = this.calculatePadding(renderedIndex,itemOffsets,false);
    if (measureRenderedItems) {
      renderedSize = itemContainerElement[this._sizeProperty];
      for (let i=0, previousOffset=padding; i<renderedCount; i++) {
        const currentSize   = this.getRenderedItemSize(itemContainerElement,i)?.[this._containerSizeProperty];
        //if (currentSize==undefined) {
        //  console.log("SCROLLER.UNDEFINED_MEASUREMENT",itemContainerElement?.children?.length,"i",i,"items",this.items);
        //}
        const previousUpper = itemOffsets[renderedIndex+i];
        const previousSize  = previousUpper-previousOffset;
        renderedDifference += (currentSize-previousSize);
        itemOffsets[renderedIndex+i] = previousUpper+renderedDifference;
        if (previousUpper<=anchorPosition) {
          anchorDifference = renderedDifference;
        } else if (previousOffset<anchorPosition) {
          anchorDifference += (currentSize-previousSize)*(anchorPosition-previousOffset)/previousSize;
        }
        previousOffset = previousUpper;
      }
    }
    if (renderedDifference!=0) {
      for (let i=renderedIndex+renderedCount; i<itemCount; i++) {
        itemOffsets[i] += renderedDifference;
      }
    }
    const scrollSize = Math.round(itemCount>0 ? itemOffsets[itemCount-1] + this.endGap : 0);
    const scrollMaxPosition = Math.max(scrollSize - viewportSize, 0);
    const result:Dimensions = {
      itemCount: itemCount,                 // ok
      itemOffsets: itemOffsets,             // ok
      viewportSize: viewportSize,           // ok
      scrollSize: scrollSize,               // ok
      scrollMaxPosition: scrollMaxPosition, // ok
      renderedIndex: renderedIndex,         // ok
      renderedCount: renderedCount,         // ok
      renderedSize: renderedSize,           // ok -> measured
      padding: Math.round(padding),         // ok
      anchorPosition: anchorPosition+anchorDifference // ok
    };
    //console.log("calculateDimensions",result);
    return result;
  }

  protected calculatePadding(index: number, itemOffsets:number[], round:boolean = true): number {
    let padding = this.startGap;
    if (index>0 && index<itemOffsets.length) {
      padding = itemOffsets[index-1];
    }
    return round ? Math.round(padding) : padding;
  }

  protected calculateContentSize(bufferedStartIndex: number, bufferedEndIndex: number, dimensions: Dimensions): number {
    let contentSize = 0;
    if (bufferedStartIndex>=0 &&
      bufferedEndIndex>=0 &&
      bufferedStartIndex<=bufferedEndIndex &&
      bufferedEndIndex<dimensions.itemCount) {
      const startOffset = bufferedStartIndex>0 ? dimensions.itemOffsets[bufferedStartIndex-1] : this.startGap;
      const endOffset   = dimensions.itemOffsets[bufferedEndIndex];
      contentSize = endOffset-startOffset;
    }
    //console.log("calculateContentSize.start",bufferedStartIndex,"end",bufferedEndIndex,"items",dimensions.itemCount,"contentSize",contentSize);
    return Math.round(contentSize);
  }

  protected getIndex(position:number,before:boolean,startIndex:number,endIndex:number,offsets:number[],optionalTargetIndex?:number):number {
    if (offsets?.length>0 && startIndex>=0 && endIndex<offsets.length && startIndex<=endIndex) {
      const targetIndex = !isValidNumber(optionalTargetIndex)
        ? Math.floor((startIndex+endIndex)/2)
        : Math.max(startIndex, Math.min(optionalTargetIndex,endIndex));
      return this.internalGetIndex(position,before,startIndex,endIndex,targetIndex,offsets);
    }
    return undefined;
  }

  protected internalGetIndex(position:number,before:boolean,startIndex:number,endIndex:number,targetIndex:number,offsets:number[]):number {
    // all indizes must be valid for internalGetIndex!!
    const targetLowerOffset  = targetIndex==0 ? this.startGap : offsets[targetIndex-1];
    const targetHigherOffset = offsets[targetIndex];
    if (position>=targetLowerOffset && position<=targetHigherOffset) {
      return before
        ? position>targetLowerOffset ? targetIndex : targetIndex>startIndex ? targetIndex-1 : targetIndex
        : position<targetHigherOffset ? targetIndex : targetIndex<endIndex ? targetIndex+1 : targetIndex;
    } else if (position<targetLowerOffset) {
      return targetIndex>startIndex
        ? this.internalGetIndex(position,before,startIndex,targetIndex-1,Math.floor((startIndex+targetIndex-1)/2),offsets)
        : targetIndex;
    } else if (position>targetHigherOffset) {
      return targetIndex<endIndex
        ? this.internalGetIndex(position,before,targetIndex+1,endIndex,Math.ceil((targetIndex+1+endIndex)/2),offsets)
        : targetIndex;
    } else {
      return undefined;
    }
  }

  protected calculatePageInfo(scrollPosition: number, scrollDirection:Direction, dimensions: Dimensions, previousViewport?:Viewport): PageInfo {
    const lastIndex             = dimensions.itemCount-1;
    const scrollSize            = dimensions.scrollSize;
    const scrollStartPosition   = Math.max(0,Math.min(scrollPosition,dimensions.scrollMaxPosition));
    const scrollEndPosition     = Math.min(scrollStartPosition + dimensions.viewportSize,scrollSize);
    const visibleStartIndex     = this.getIndex(scrollStartPosition,false,0,lastIndex,dimensions.itemOffsets,previousViewport?.visibleStartIndex) ?? 0;
    const visibleEndIndex       = this.getIndex(scrollEndPosition,true,0,lastIndex,dimensions.itemOffsets,previousViewport?.visibleEndIndex) ?? 0;
    let   bufferedStartIndex    = visibleStartIndex==0 ? 0 :
                                  this.bufferSize==0 ? visibleStartIndex :
                                  this.getIndex(Math.max(0,scrollStartPosition-(scrollDirection==Direction.EndToStart ? this.bufferSize*3 : this.bufferSize)),true,0,visibleStartIndex,dimensions.itemOffsets,Math.floor(visibleStartIndex/2)) ?? visibleStartIndex;
    let   bufferedEndIndex      = visibleEndIndex==lastIndex ? lastIndex :
                                  this.bufferSize==0 ? visibleEndIndex :
                                  this.getIndex(Math.min(scrollSize,scrollEndPosition+(scrollDirection==Direction.StartToEnd ? this.bufferSize*3 : this.bufferSize)),false,visibleEndIndex,lastIndex,dimensions.itemOffsets,Math.ceil((visibleEndIndex+lastIndex)/2)) ?? visibleEndIndex;
    const bufferedStartMinIndex = visibleStartIndex==0 ? 0 :
                                  this.minimumBufferSize==0 || bufferedStartIndex==visibleStartIndex ? visibleStartIndex :
                                  this.getIndex(Math.max(0,scrollStartPosition-this.minimumBufferSize),true,bufferedStartIndex,visibleStartIndex,dimensions.itemOffsets,Math.floor((bufferedStartIndex+visibleStartIndex)/2)) ?? visibleStartIndex;
    const bufferedEndMinIndex   = visibleEndIndex==lastIndex ? lastIndex :
                                  this.minimumBufferSize==0 || bufferedEndIndex==visibleEndIndex ? visibleEndIndex :
                                  this.getIndex(Math.min(scrollSize,scrollEndPosition+this.minimumBufferSize),false,visibleEndIndex,bufferedEndIndex,dimensions.itemOffsets,Math.ceil((visibleEndIndex+bufferedEndIndex)/2)) ?? visibleEndIndex;
    //if (this.log) console.log("SCROLLER.calculatePageInfo\nvisibleStartIndex",visibleStartIndex,"\nvisibleEndIndex",visibleEndIndex,"\nbufferedStartIndex",bufferedStartIndex,"min",bufferedStartMinIndex,"\nbufferedEndIndex",bufferedEndIndex,"min",bufferedEndMinIndex,"\nprevStartIndex",previousViewport.bufferedStartIndex,"\nprevEndIndex",previousViewport.bufferedEndIndex);
    if (previousViewport.bufferedStartIndex<=bufferedStartMinIndex &&
        previousViewport.bufferedEndIndex>=bufferedEndMinIndex &&
        previousViewport.bufferedEndIndex<dimensions.itemCount) {
      bufferedStartIndex = previousViewport.bufferedStartIndex;
      bufferedEndIndex   = previousViewport.bufferedEndIndex;
    } else if (scrollDirection==Direction.StartToEnd) {
      bufferedStartIndex = bufferedStartMinIndex;
    } else if (scrollDirection==Direction.EndToStart) {
      bufferedEndIndex = bufferedEndMinIndex;
    } else {
      bufferedStartIndex = bufferedStartMinIndex;
      bufferedEndIndex = bufferedEndMinIndex;
    }
    /*
    console.log("calculatePageInfo.minimumBufferSize",this.minimumBufferSize,"bufferSize",this.bufferSize,
      "\nscrollStartPosition",scrollStartPosition,"\nscrollEndPosition",scrollEndPosition,"\ndirection",scrollDirection,
      "\nbufferedStartIndex",bufferedStartIndex,"\nbufferedStartMinIndex",bufferedStartMinIndex,"\nvisibleStartIndex",visibleStartIndex,
      "\nvisibleEndIndex",visibleEndIndex,"\nbufferedEndMinIndex",bufferedEndMinIndex,"\nbufferedEndIndex",bufferedEndIndex,
      "\nlastIndex",lastIndex);
     */
    return {
      viewportSize: dimensions.viewportSize,
      bufferedStartIndex: bufferedStartIndex,
      visibleStartIndex: visibleStartIndex,
      visibleEndIndex: visibleEndIndex,
      bufferedEndIndex: bufferedEndIndex,
      scrollStartPosition: scrollStartPosition,
      scrollEndPosition: scrollEndPosition,
      scrollMaxPosition: dimensions.scrollMaxPosition,
      scrollSize: Math.round(scrollSize),
      expectedCount: dimensions.itemCount>0 ? bufferedEndIndex+1-bufferedStartIndex : 0,
      expectedSize: this.calculateContentSize(bufferedStartIndex,bufferedEndIndex,dimensions),
      startGap: this.startGap,
      endGap: this.endGap,
    };
  }

  protected calculateViewport(itemsIdChanged:boolean,previousViewport:Viewport): Viewport {
    // ATTENTION: rounding in calculateDimensions leads sometimes to 1 pixel displacements
    // which we just accept at this time....
    let currentScrollPosition = this.getScrollStartPosition();
    const dimensions   = this.calculateDimensions(previousViewport);
    //if (this.log) console.log("SCROLLER.calculateViewport\ndimensions",dimensions);
    if (dimensions.viewportSize!=previousViewport.viewportSize ||
        dimensions.scrollSize!=previousViewport.scrollSize ||
        dimensions.padding!=previousViewport.padding ||
        dimensions.anchorPosition!=previousViewport.anchorPosition ||
        dimensions.scrollMaxPosition!=previousViewport.scrollMaxPosition ||
        dimensions.renderedSize!=previousViewport.expectedSize ||
        currentScrollPosition!=previousViewport.scrollPosition) {
      const viewportSizeDiff   = dimensions.viewportSize-previousViewport.viewportSize;
      const scrollSizeDiff     = dimensions.scrollSize-previousViewport.scrollSize;
      const anchorPositionDiff = dimensions.anchorPosition-previousViewport.anchorPosition;
      const scrollPosition     = previousViewport.viewportSize==0
                               ? currentScrollPosition  // measured scroll position
                               : this.startFromTop
                                 ? currentScrollPosition  // measured scroll position
                                 + anchorPositionDiff     // keep it same distance to anchor
                                 : viewportSizeDiff>0 && dimensions.scrollMaxPosition==currentScrollPosition
                                   ? currentScrollPosition
                                   : Math.min(dimensions.scrollMaxPosition,
                                     currentScrollPosition  // measured scroll position
                                   + anchorPositionDiff     // keep it same distance to anchor
                                   - viewportSizeDiff);     //Math.min(0,-viewportSizeDiff);      // viewport larger -> scrollPosition lower
      const pageInfo           = this.calculatePageInfo(scrollPosition, previousViewport.scrollDirection, dimensions, previousViewport);
      const padding            = previousViewport.renderedSize>0 && previousViewport.renderedIndex>=0
                                 ? this.calculatePadding(previousViewport.renderedIndex,previousViewport.itemOffsets)
                                 : previousViewport.padding ?? 0;
      /*if (this.log) console.log("SCROLLER.CALC:0",itemsIdChanged,
        "\ndimensions",dimensions,
        "\npageInfo",pageInfo,
        "\nscrollPosition",scrollPosition,"org",currentScrollPosition,
        "\nscrollSizeDiff",scrollSizeDiff,
        "\nviewportSizeDiff",viewportSizeDiff,
        "\nanchorPositionDiff",anchorPositionDiff,
        "\npadding",padding,"was",previousViewport.padding,
        "\npreviousViewport",previousViewport);*/
      // if (this.log) console.log("X.calculateViewport\npageInfo",pageInfo,"\nscrollPosition",scrollPosition,scrollPosition-anchorPositionDiff+viewportSizeDiff,"\nanchorPositionDiff",anchorPositionDiff,"\nviewportSizeDiff",viewportSizeDiff);
      //if (this.log) console.log("SCROLLER.calculateViewport\npageInfo",pageInfo,"\nscrollPosition",scrollPosition,scrollPosition-anchorPositionDiff+viewportSizeDiff,"\nanchorPositionDiff",anchorPositionDiff,"\nviewportSizeDiff",viewportSizeDiff);
      const viewport:Viewport = {
        itemCount: dimensions.itemCount,
        itemOffsets: dimensions.itemOffsets,
        scrollSize: dimensions.scrollSize,
        scrollPosition: scrollPosition,
        scrollDirection: previousViewport.scrollDirection,
        scrollMaxPosition: dimensions.scrollMaxPosition,
        scrollStartPosition: pageInfo.scrollStartPosition,
        scrollEndPosition: pageInfo.scrollEndPosition,
        viewportSize: dimensions.viewportSize,
        padding: padding,
        renderedItems: previousViewport.renderedItems,
        renderedIndex: dimensions.renderedIndex,
        renderedCount: dimensions.renderedCount,
        renderedSize:  dimensions.renderedSize,
        anchorPosition: dimensions.anchorPosition,
        anchorFixation: previousViewport.anchorFixation,
        anchorRenderRelease: previousViewport.anchorRenderRelease,
        expectedSize: pageInfo.expectedSize,
        expectedCount: pageInfo.expectedCount,
        bufferedStartIndex: pageInfo.bufferedStartIndex,
        visibleStartIndex: pageInfo.visibleStartIndex,
        visibleEndIndex: pageInfo.visibleEndIndex,
        bufferedEndIndex: pageInfo.bufferedEndIndex,
        startGap: this.startGap,
        endGap: this.endGap
      };
      if (this.log) console.log("SCROLLER.calculateViewport."+this.instanceId,"\nattached:",this.attached,"\npageInfo",pageInfo,"\nscrollPosition",scrollPosition,scrollPosition-anchorPositionDiff+viewportSizeDiff,"\nanchorPositionDiff",anchorPositionDiff,"\nviewportSizeDiff",viewportSizeDiff,"\nviewport",viewport);
      return viewport;
    } /*else {
      if (this.log) console.log("SCROLLER.CALC:1",itemsIdChanged,
        "\ndimensions",dimensions,
        "\ncurrentScrollPosition",currentScrollPosition,
        "\npreviousViewport",previousViewport);
    }*/
    return previousViewport;
  }
}

