import {Component} from '@angular/core';
import {BehaviorSubject, Observable, Subscription, TeardownLogic} from "rxjs";
import {OnAttach, OnDetach} from "../../directives/dom/dom_hooks";
import {OnResize} from "../../directives/resize/resize.hook";
import {ResizeEvent} from "../../directives/resize/resize.event";
import {filter, takeUntil} from "rxjs/operators";
import {BasicComponent} from "./basic.component";

export interface ObservableSubscription<TYPE> {
  get():Observable<TYPE>;
  set(observable:Observable<TYPE>):void;
}

@Component({
  selector: 'basic-container',
  template: ``,
  styles: []
})
export class BasicContainerComponent extends BasicComponent implements OnAttach, OnDetach, OnResize {

  protected _onResize$: BehaviorSubject<ResizeEvent> = new BehaviorSubject(undefined);
  protected attached$ = new BehaviorSubject<boolean>(false);
  protected subscriptions = new Subscription();
  protected uniqueIdSubscriptions:Map<string,TeardownLogic>;

  constructor() {
    super();
  }

  get onResize$():Observable<ResizeEvent> {
    return this._onResize$.asObservable().pipe(
      takeUntil(this.onDestroy$),
      filter(event => !!event?.element)
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.onResize$.subscribe(event => {
      this.onResize(event);
    });
  }

  addSubscription(subscription:TeardownLogic,uniqueId?:string):void {
    this.subscriptions.add(subscription);
    if (!!uniqueId) {
      this.uniqueIdSubscriptions = this.uniqueIdSubscriptions ?? new Map<string,TeardownLogic>();
      const previous = this.uniqueIdSubscriptions.get(uniqueId);
      if (!!previous) {
        this.subscriptions.remove(previous);
        if (previous instanceof Function) {
          previous();
        } else {
          previous.unsubscribe();
        }
      }
      this.uniqueIdSubscriptions.set(uniqueId,subscription);
    }
    //return subscription;
  }

  runInZone(code:()=>void) {
    code();
  }

  createObservableSubscription<TYPE>(callback:(previousValue:TYPE,currentValue:TYPE,first:boolean)=>void,initialValue?:TYPE,property?:string):ObservableSubscription<TYPE> {
    const self = this;
    //const instanceId = this.instanceId;
    return new class implements ObservableSubscription<TYPE> {
      subscription:Subscription;
      value$ = new BehaviorSubject<TYPE>(initialValue);
      previous:TYPE = initialValue;
      first:boolean = true;
      get(): Observable<TYPE> {
        return this.value$;
      }
      set(observable: Observable<TYPE>): void {
        this.subscription?.unsubscribe();
        this.subscription = undefined;
        if (!!observable) {
          this.subscription = observable
            .pipe(takeUntil(self.onDestroy$))
            .subscribe(value=>self.runInZone(()=>{
              if (this.first || value!=this.previous) {
                 /*!(value==this.previous ||
                    ((!!(<any>value)?.backingArray || !!(<any>this.previous)?.backingArray) &&
                        (<any>value)?.backingArray  ==  (<any>this.previous)?.backingArray))) {*/
                //console.log("XY.prop1",property,"instance",instanceId,this.first,this.previous==value,this.previous===value,"samebacking",(!!(<any>this.previous)?.backingArray || !!(<any>value)?.backingArray) && (<any>this.previous)?.backingArray==(<any>value)?.backingArray,"previous",this.previous,"current",value);
                this.value$.next(value);
                callback(this.previous,value,this.first);
                this.previous = value;
                this.first = false;
              }
            }));
        } else if (!!this.previous) {
          callback(this.previous,undefined,false);
        }
      }
    };
  }

  triggerResize(event:ResizeEvent): void {
    this._onResize$.next(event);
  }

  onResize(event:ResizeEvent): void {
  }

  /*
    called by domEvents (onAttach) directive
   */
  ngOnAttach() {
    if (!this.attached$.value) {
      this.attached$.next(true);
    }
    //console.debug("DOM.ATTACH."+this.constructor.name+"."+this.instanceId);
  }

  /*
    called by domEvents (onDetach) directive
   */
  ngOnDetach() {
    if (this.attached$.value) {
      this.attached$.next(false);
    }
    //console.debug("DOM.DETACH."+this.constructor.name+"."+this.instanceId);
  }
}
