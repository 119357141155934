import {
  AfterContentInit,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Injectable, InjectionToken,
  Injector,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {Action, ENVIRONMENT, Logger, Platform} from "core";
import get from "lodash/get";
import {take} from "rxjs/operators";
import {InAppBrowser, InAppBrowserObject, InAppBrowserOptions} from "@ionic-native/in-app-browser/ngx";
import {PropertiesService} from "properties";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {lastValueFrom} from "rxjs";

export const ACTION_HANDLER = new InjectionToken<ActionHandler>('actionHandler');

export interface ActionHandler {
  handleAction(action: Action): Promise<any>;
}

@Injectable()
@Directive({
  selector: '[action], [onAction]'
})
export class ActionDirective implements ActionHandler, AfterContentInit, OnDestroy {

  @Input()  action: Action;
  @Output() onAction: EventEmitter<Action> = new EventEmitter<Action>();
  @HostListener('click', ['$event']) triggerAction = () => this.action && this.handleAction(this.action);

  protected browsers: InAppBrowserObject[] = [];
  protected logger = new Logger('ActionDirective');

  constructor(protected platform: Platform,
              protected inAppBrowser: InAppBrowser,
              protected propertiesService: PropertiesService,
              protected router: Router,
              protected http: HttpClient,
              @Inject(ENVIRONMENT) protected environment: any) {
  }

  ngOnDestroy(): void {
    this.onAction.complete();
    this.browsers.forEach(browser => browser && browser.close());
  }

  ngAfterContentInit(): void {
  }

  public handleAction(action: Action): Promise<any> {
    this.logger.debug('handleAction()', action);
    const expandParameter = (parameter): string => {
      const regex = /{{([a-zA-Z_](?:[a-zA-Z0-9_]*\.[a-zA-Z_][a-zA-Z0-9_]*)+)}}/;
      const context = {
        ...this.environment,
        ...this.propertiesService.properties
      };
      return typeof parameter === 'string'
          ? (parameter || '').replace(regex, (match,path) => get(context, path, ''))
          : parameter
    };
    const handle = (action): Promise<any> => {
      const url = expandParameter(action.parameter) || '/';
      return new Promise<boolean>((resolve, reject) => {
        if (action.type == 'internal') {
          this.logger.debug("handleAction() -> navigate", { action, url });
          return this.router.navigateByUrl(url).then(() => resolve(true));
        } else if (action.type == 'external') {
          this.platform.ready().then(readySource => {
            if (this.platform.is('hybrid')) {
              const browser = this.browser(url);
              const index = this.browsers.push(browser);
              browser.on('exit')
                .pipe(take(1))
                .subscribe({ complete: () => delete this.browsers[index] });
              return lastValueFrom(browser.on('loadstop'))
                .then(event => {
                  if (event.url === url) {
                    resolve(true);
                  } else {
                    reject(new Error(`Failed to open action target url with InAppBrowser: action: ${JSON.stringify(action)}, url: ${url}`));
                  }
                });
            } else {
              window.open(url, action.context || "_blank");
              resolve(true);
            }
          });
        } else if (action.type == 'redirect') {
          return lastValueFrom(this.http.post<Action>(
            /*url || */ `${this.environment.serverUrl}/v1.0/contacts/action/resolve`,
            action
          ))
          .then((action: Action) => {
            if (action?.parameter) {
              if (!action.type || action.type=='redirect') { // no more redirects
                action.type ='external';
              }
              return this.handleAction(action).then(resolve, reject);
            } else {
              reject(new Error(`Invalid action ${JSON.stringify(action)}`));
            }
          });
        } else {
          resolve(false);
        }
      })
    }
    return handle(action).then(result => {
      if (result) {
        this.onAction.emit(action);
      }
      return result;
    });
  }

  protected browser(url: string): InAppBrowserObject {
    const target  = '_system'; // _system: load in system's web browser, _blank - load in inappbrowser, _self - load in cordova webview;
    // const options = `location=${this.environment.production ? 'no' : 'yes'},clearsessioncache='yes'`;  // no location bar in production, clear session cookie cache
    // const options = `location=${this.environment.production ? 'no' : 'yes'}`;  // no location bar in production
    const yesNo = this.environment.production ? 'no' : 'yes';
    const options: InAppBrowserOptions = {
      location: yesNo,    // no location bar in production
      toolbar: yesNo,     // no toolbar in production
      zoom: yesNo         // no zoom in production
    }
    const browser = this.inAppBrowser.create(url, target, options);
    return browser;
  }
}
