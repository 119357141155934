import {Inject, Injectable} from '@angular/core';
import {ENVIRONMENT, Logger, Platform} from "core";

@Injectable({
  providedIn: 'root'
})
export class SessionTokenService {

  protected _token;
  protected rewriteRegex: RegExp;
  protected logger = new Logger('SessionService');

  constructor(@Inject(ENVIRONMENT)
              protected environment: any,
              protected platform: Platform) {
    //this.logger.debug('SessionService.ctor');
    // this.linkRegex = new RegExp(`^(?:(?:https?):\/\/.*)?(?<version>\/v1.0\/)(?<path>.*)`);
    this.rewriteRegex = new RegExp(`^(?:${this.environment.serverUrl})?(?<version>\/v1.0\/)(?<path>.*)`);
  }

  set token(token: string) {
    //this.logger.debug('SessionService.TOKEN', token);
    this._token = token;
  }

  get token() {
    return this._token;
  }

  rewrite(link: string): string {
    if (this.shouldRewrite) {
      const match = link?.match(this.rewriteRegex);
      if (match) {
        return link.replace(match.groups.version, `/v1.1/${this.token}/`);
      }
    }
    return link;
  }

  get shouldRewrite(): boolean {
    return this.platform.is('ios') && this.platform.is('hybrid');
  }
}
