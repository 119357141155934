import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import {Media, NULL_MEDIA} from "../../store/models";
import {MediaService} from "../../service/media.service";
import {BasicContainerComponent} from "shared";
import {User} from "core";
import {Observable} from "rxjs";

@Component({
  selector: 'media-selection-list',
  templateUrl: './media-selection-list.component.html',
  styleUrls: ['./media-selection-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaSelectionListComponent extends BasicContainerComponent {

  @Input() entities$: Observable<Media[]>;
  @Input() user$: Observable<User>;
  @Output() selected = new EventEmitter<Media>();
  @Output() play = new EventEmitter<Media>();
  expandedDetailsElement: HTMLElement;

  @ContentChild('mediaNameTemplate') mediaNameTemplate: TemplateRef<any>;

  constructor(public mediaService: MediaService,
              @Inject(NULL_MEDIA) public nullMedia: Media) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onMediaDetails(media: Media, index: number, element: HTMLDivElement, event: MouseEvent) {
    event.stopPropagation();
    const height = element.clientHeight; //window.getComputedStyle(detailsElement, null).getPropertyValue("height");
    const expand = !height;
    element.style.maxHeight = `${expand ? 80 : 0}px`;
    if (expand) {
      if (this.expandedDetailsElement) {
        this.expandedDetailsElement.style.maxHeight = '0px';
      }
      this.expandedDetailsElement =  element;
    } else if (this.expandedDetailsElement) {
      this.expandedDetailsElement = null;
    }
  }

  isMediaSelected(media: any, index: number) {
    return false;
  }

  onSelectedMedia(media: Media) {
    this.selected.emit(media);
  }

  onPlayMedia(media: Media, index: number, event: Event) {
    this.play.emit(media);
  }

  safeMedia(media: Media): Media {
    return media || this.nullMedia;
  }

  trackByMedia(index: number, media: any): string {
    return `${media.id}.${media.version}`;
  }
}
