<mat-toolbar [color]="color" fxLayout="row">
  <button *ngIf="leftButton && leftIcon" mat-icon-button (click)="emitLeftClicked()">
    <mat-icon>{{leftIcon}}</mat-icon>
  </button>
  <div class="title-container" (click)="emitTitleClicked()" [style.width]="titleWidth"><ng-content></ng-content></div>
  <button *ngIf="rightText2" mat-flat-button
          color="primary"
          type="button" (click)="emitRightClicked2()"><mat-icon *ngIf="rightIcon2">{{rightIcon2}}</mat-icon><span>{{rightText2 | translate}}</span></button>
  <button *ngIf="rightButton && rightIcon" mat-icon-button (click)="emitRightClicked()">
    <mat-icon>{{rightIcon}}</mat-icon>
  </button>
</mat-toolbar>
