import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Action, Logger} from "core";

@Component({
  selector: 'member-id',
  templateUrl: './member-id.component.html',
  styleUrls: ['./member-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberIdComponent {

  @Input() memberId: string;
  @Input() actions: Action[];
  @Input() backgroundColor: string = 'transparent';
  @Input() role: 'leader'|'member'|'guest' = 'guest';
  @Input() verfied: boolean = false;

  logger = new Logger('MemberIdComponent');

  constructor(){}
}
