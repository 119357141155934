/* projects/shared/src/lib/components/more/more.text.component.scss */
.content {
  position: relative;
  overflow: hidden;
  min-height: 0;
  transition: min-height 0.3s;
}
.content > div {
  overflow: hidden;
  word-wrap: break-word;
  hyphens: auto;
  display: -webkit-box;
}
.content > div.collapsed-content {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content > div.collapsed-content {
  position: relative;
}
.content > div.expanded-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.content > div div {
  word-wrap: break-word;
  white-space: normal;
  word-break: normal;
  max-width: 100%;
  min-height: 8px;
}
.content > div a {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content > div div.empty {
  min-height: 8px;
}
.content.expanded > div.collapsed-content {
  z-index: 0;
  opacity: 0;
}
.content.expanded > div.expanded-content {
  z-index: 1;
  opacity: 1;
}
.content:not(.expanded) > div.collapsed-content {
  z-index: 1;
  opacity: 1;
}
.content:not(.expanded) > div.expanded-content {
  z-index: 0;
  opacity: 0;
}
div.more {
  text-align: left;
}
div.more.center {
  text-align: center;
}
div.more.right {
  text-align: right;
}
/*# sourceMappingURL=more.text.component-BZVZZDPH.css.map */
