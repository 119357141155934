/* projects/shared/src/lib/components/legal-info/legal-info.component.scss */
:host {
  display: block;
  word-break: break-word;
  color: #777;
}
:host ul {
  list-style-type: square;
  padding-inline-start: 30px;
}
:host ul li {
  color: var(--color-primary);
  cursor: pointer;
}
:host ul li:hover {
  text-decoration: underline;
  color: var(--color-primary-700);
}
:host ::ng-deep .text-container .block {
  margin: 10px;
}
:host ::ng-deep .text-container break {
  display: inline-block;
  max-width: 0;
}
:host ::ng-deep .text-container h1,
:host ::ng-deep .text-container .h1 {
  margin: 10px;
  font-size: 120% !important;
  color: var(--color-primary);
  font-weight: bold;
}
:host ::ng-deep .text-container h2,
:host ::ng-deep .text-container .h2 {
  margin: 10px;
  font-size: 100% !important;
  color: var(--color-primary);
  font-weight: bold;
}
:host ::ng-deep .text-container h3,
:host ::ng-deep .text-container .h3 {
  margin: 10px;
  font-size: 100% !important;
  color: var(--color-primary);
  font-weight: normal;
}
:host ::ng-deep .text-container a {
  color: var(--color-primary);
  text-decoration: none;
}
:host ::ng-deep .text-container a:hover {
  text-decoration: underline;
}
:host-context(.color-scheme-dark) {
  color: #ddd;
}
:host-context(.color-scheme-dark) ::ng-deep .text-container a {
  color: #bbb;
}
/*# sourceMappingURL=legal-info.component-OIZBSOEQ.css.map */
