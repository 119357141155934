export class TokenCode {

  static default = new TokenCode();

  allowedChars = "23456789ABCDEFGHJKLMNPQRSTUVWXYZ";
  charDigits : object = null;
  zeroCharCode : number = '0'.charCodeAt(0);

  public charMask() : number {
    return 0x1f;
  }
  public charCount() : number {
    return this.allowedChars.length;
  }
  public charBits() : number {
    return 5;
  }
  public charOfDigit(digit : number) : number {
    return this.allowedChars.charCodeAt(digit);
  }
  public digitOfChar(c : number) : number {
    if (this.charDigits==null) {
      this.charDigits = {};
      for (let i=0; i<this.allowedChars.length; i++) {
        this.charDigits[this.allowedChars.charCodeAt(i)] = i;
      }
    }
    let digit = this.charDigits[c];
    return digit===undefined ? -1 : digit;
  }
  public isValidChar(c : number) : boolean {
    return this.digitOfChar(c)>=0;
  }
  public isValidToken(token : string) : boolean {
    for (let char of token) {
      if (!this.isValidChar(char.charCodeAt(0))) {
        return false;
      }
    }
    return true;
  }
  public toTokenString(value : number) : string {
    let binary   = Math.floor(Math.abs(value)).toString(2);
    let charBits = this.charBits();
    let result = "";
    for (let i=binary.length; i>0; i-=charBits) {
      let low  = Math.max(i-charBits,0);
      let high = Math.min(i,binary.length);
      let char = 0;
      for (let b=low; b<high; b++) {
        char = char*2 + (binary.charCodeAt(b)==this.zeroCharCode ? 0 : 1);
      }
      //console.debug("low:"+low+" high:"+high+" char:"+char+" "+this.allowedChars.charAt(char));
      result = this.allowedChars.charAt(char) + result;
    }
    return result;
  }
  public fromTokenString(token : string) : number {
    let result = 0;
    let charCount = this.charCount();
    if (this.isValidToken(token)) {
      for (let max=(token || '').length, i=0; i<max; i++) {
        let digit = this.digitOfChar(token.charCodeAt(i));
        //console.debug("i:"+i+" max:"+max+" digit:"+digit);
        result = result*charCount + digit;
      }
    }
    return result;
  }
}
