<mat-toolbar class="top" [class.main]="panel=='main'" color="primary" #toolbar (onResize)="triggerResize($event)">
  <button tabindex="-1" mat-icon-button *ngIf="panel!='main'"
          [attr.aria-label]="'back' | translate"
          (click)="panel='main'">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span [innerHTML]="(panel=='main' ? 'media.details' : 'media.actions.selectAuthor') | translate | safeHtml"></span>
  <button tabindex="-1" mat-icon-button *ngIf="!!mediaDetails && !mediaDetails?.media?.readOnly && !edit && mediaDetails.isEditor" (click)="mediaDetails.onEdit()" aria-label="edit">
    <mat-icon>edit</mat-icon>
  </button>
  <button tabindex="-1" mat-icon-button *ngIf="panel=='main' && !!mediaDetails?.media" [matMenuTriggerFor]="menu" >
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu #menu>
    <button mat-menu-item (click)="onTapReport(mediaDetails?.media)">
      <mat-icon>rate_review</mat-icon>
      <span translate>actions.report</span>
    </button>
  </mat-menu>
  <button tabindex="-1" mat-icon-button *ngIf="panel=='main'"
          [attr.aria-label]="'close' | translate"
          (click)="layoutService.details.toggle()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<slide-panel [id]="panel" (onSlide)="onPanelSlide($event)" class="edit">
  <app-media-details #mediaDetails
                     *slidePanelLeft="'main'"
                     class="panel media-details"
                     [mediaChangeConsent]="mediaChangeConsent"
                     [startGap]="toolbarHeight"
                     (mediaChange)="onMediaChange($event)"
                     (mediaDelete)="onMediaDelete($event)"
                     (mediaAuthorSelect)="onAuthorSelect($event)"
                     (editModeChange)="edit = $event">
  </app-media-details>
  <div *slidePanelRightLazy="'*'" class="panel selectors">
    <div class="selector"><ng-container #contactSelectorContainer></ng-container></div>
    <mat-toolbar class="buttonBar" color="primary" #buttonBar>
      <button mat-mini-fab color="primary" (click)="onAuthorSelectCancel()" aria-label="cancel">
        <mat-icon>close</mat-icon>
      </button>
      <button mat-mini-fab color="primary" (click)="onAuthorDelete()" aria-label="delete">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </mat-toolbar>
  </div>
</slide-panel>
