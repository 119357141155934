<div class="menu-selector" [matMenuTriggerFor]="selectorMenu"
  (menuOpened)="button.disabled = true"
  (menuClosed)="button.disabled = false">
  <!-- https://github.com/angular/components/issues/14440 -->
  <button *ngIf="selectors && selectors.length" #button
          mat-icon-button
          type="button"
          aria-haspopup="dialog"
          [attr.tabindex]="disabled ? -1 : tabIndex"
          [disabled]="disabled"
          [disableRipple]="disableRipple"
          (click)="open($event)"
          [@ngIfAnimation]>
    <mat-icon class="icon"><ng-content></ng-content></mat-icon>
  </button>
  <mat-menu #selectorMenu="matMenu">
    <button mat-menu-item *ngFor="let selector of selectors" (click)="select($event,selector)">
      <mat-icon matPrefix>{{selector.icon}}</mat-icon>
      <span>{{getSelectorLabel(selector) | translate}}</span>
    </button>
  </mat-menu>
</div>
