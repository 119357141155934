<app-search-bar color="primary"
                (onSearchTermChange)="onSearchTermChange($event)"
                [rightButton]="false"
                [leftButton]="true"
                [leftIcon]="'close'"
                (onLeftClicked)="this.layoutService.details.close()">
  <span class="title" translate>media.actions.select</span>
</app-search-bar>
<media-selection-list [entities$]="mediaService.entities$"
                      [user$]="propertiesService.user$"
                      (selected)="onMediaSelected($event)"
                      (play)="onPlayMedia($event)">
  <ng-template #mediaNameTemplate let-media="media">
    <ng-container *ngTemplateOutlet="(mediaNameTemplateRef || defaultMediaNameTemplate); context: { media }"></ng-container>
  </ng-template>
</media-selection-list>
<ng-template #defaultMediaNameTemplate let-media="media">
  <ng-container *ngIf="media?.name">{{media.name}}</ng-container>
</ng-template>
