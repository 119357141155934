import {createCacheId, createPageLoadingArray, createPageLoadingProxy} from "core";
import {initialMediaListState, initialMediaOnboardingListState, MediaState} from "./state";
import isEqual from "lodash/isEqual";
import {
  mediaCacheRemoveAction,
  mediaCacheUpsertAction,
  mediaLoadPageAction,
  mediaLoadPageDoneAction,
  mediaLoadPageFailedAction,
  mediaLoadRequestAction,
  mediaOnboardingLoadAction,
  mediaOnboardingLoadDoneAction,
  mediaOnboardingLoadFailedAction,
  mediaOnboardingUpdateAction,
  mediaOnboardingUpdateDoneAction,
  mediaOnboardingUpdateFailedAction,
  mediaSelectAction,
  mediaSetTypedFiltersAction,
  mediaSubscribeAction,
  mediaUnsubscribeAction,
  mediaUpdateAction,
  mediaUpdateDoneAction,
  mediaUpdateFailedAction,
  mediaUpdateSearchTermAction,
  mediaUpdateUserAction
} from "./actions";
import {Media} from "./models";
import {ActionReducerMap, createReducer, on} from "@ngrx/store";
import {isValidNumber, logAction} from "shared";

export const mediaListReducer = createReducer(
  initialMediaListState,
  on(mediaLoadRequestAction,(state, action) => {
    return logMediaAction("mediaLoadRequestAction",false,state,action,()=>{
      return {
        ...state,
        selectedIndex: undefined,
        currentCacheId: createCacheId(),
        parent: null,
        loading: true,
      }
    });
  }),
  on(mediaSetTypedFiltersAction,(state, action) => {
    return logMediaAction("mediaSetTypedFiltersAction",true,state,action,()=>{
      if (!isEqual(state.filters, action.filters)) {
        const filters = {...action.filters??{}};
        Object.keys(filters).forEach(type=>filters[type]?.sort())
        const updated = {...state.filters,...filters};
        if (!!action.remove) {
          Object.keys(updated).forEach(type=>{
            if (!filters[type] && action.remove(type,updated[type])) {
              delete updated[type];
            }
          });
        }
        return {
          ...state,
          entities: createPageLoadingArray<Media>(0, 25, (pageIndex: number, pageSize: number): void => {}),
          indices: {},
          selectedIndex: undefined,
          filters:updated,
          currentCacheId: createCacheId(),
          parent: null,
          loading: true,
        }
      }
      return state;
    });
  }),
  /*
  on(mediaUpdateFiltersAction,(state, action) => {
    return logMediaAction("mediaUpdateFiltersAction",false,state,action,()=>{
      const cacheId  = createCacheId();
      const sorted   = action.filters?.sort() ?? EMPTY_ARRAY;
      if (!!action.type) {
        return isEqual(state.filters[action.type]??EMPTY_ARRAY, sorted) ? state : {
          ...state,
          entities: createPageLoadingArray<Media>(0,25, (pageIndex:number, pageSize:number):void => {}),
          indices: {},
          selectedIndex: undefined,
          filters: {
            ...state.filters,
            [action.type]:sorted,
          },
          currentCacheId: cacheId, //createCacheId(),
          parent: null,
          loading: true,
        }
      } else if (!isEqual(state.filters??{},{})) {
        return {
          ...state,
          entities: createPageLoadingArray<Media>(0,25, (pageIndex:number, pageSize:number):void => {}),
          indices: {},
          selectedIndex: undefined,
          filters: {},
          currentCacheId: cacheId, //createCacheId(),
          parent: null,
          loading: true,
        }
      }
      return state;
    });
  }),
  on(mediaSetFiltersAction,(state, action) => {
    return logMediaAction("mediaSetFiltersAction",true,state,action,()=>{
      if (!isEqual(state.filters, action.filters)) {
        const filters = {...action.filters??{}};
        Object.keys(filters).forEach(type=>filters[type]?.sort())
        return {
          ...state,
          entities: createPageLoadingArray<Media>(0, 25, (pageIndex: number, pageSize: number): void => {}),
          indices: {},
          selectedIndex: undefined,
          filters,
          currentCacheId: createCacheId(),
          parent: null,
          loading: true,
        }
      }
      return state;
    });
  }),*/
  on(mediaUpdateUserAction,(state, action) => {
    return logMediaAction("mediaUpdateUserAction",false,state,action,()=>{
      return {
        ...state,
        selectedIndex: undefined,
        currentCacheId: createCacheId(),
        loading: true,
      }
    });
  }),
  on(mediaUpdateSearchTermAction,(state, action) => {
    return logMediaAction("mediaUpdateSearchTermAction",false,state,action,()=>{
      return isEqual(state.term, action.term) ? state : {
        ...state,
        selectedIndex: undefined,
        term: action.term,
        currentCacheId: createCacheId(),
        parent: null,
        loading: true,
      }
    });
  }),
  on(mediaSelectAction,(state, action) => {
    return logMediaAction("mediaSelectAction",false,state,action,()=>{
      return isEqual(state.selectedIndex, action.selectedIndex) ? state : {
        ...state,
        selectedIndex: action.selectedIndex
      }
    });
  }),
  on(mediaLoadPageDoneAction,(state, action) => {
    return logMediaAction("mediaLoadPageDoneAction",false,state,action,()=>{
      let { entities, indices, previousCacheId, parent, subscriptions } = { ...state };
      const ioState = { loading: false, error: null };
      if (action.cacheId == state.currentCacheId && (!!action.data || !!action.parent)) {
        if (state.previousCacheId != state.currentCacheId) {
          entities = createPageLoadingArray<Media>(action.total,25, (pageIndex:number, pageSize:number):void => {
            action.dispatch(mediaLoadPageAction({
              cacheId:action.cacheId,
              index:pageIndex,
              size:pageSize
            }));
          });
          indices = {};
          previousCacheId = action.cacheId;
        } else {
          // backingArray may have not been set on entities proxy when there are no media filters defined
          // and the page load request is initiated by MediaUpdateSearchThermAction
          // in this case the createPageLoadingArray() function which prepares the proxy array assigns 'backingArray' and 'loadPageFunction' is not invoked
          // however loadPage$ effect still fires MediaLoadPageSuccessAction (despite that the actual server request is prevented)
          const backingArray: Media[] = (<any>entities).backingArray ?? [];
          const loadPageFunction: (pageIndex: number, pageSize: number) => void = (
            (<any>state.entities).loadPageFunction ||
            (() => ((pageIndex:number, pageSize:number): void => {}))
          )() ;
          entities = createPageLoadingProxy<Media>(backingArray,25, loadPageFunction)
        }
        const dataLength = action.data?.length ?? 0;
        const dataIndex  = action.index ?? 0;
        entities['loaded'] = Math.max(entities['loaded'] ?? 0, dataIndex + dataLength);
        for (let i=0, max=dataLength; i<max; i++) {
          entities[action.index+i] = action.data[i];
          indices[action.data[i].id] = action.index+i;
        }
        parent = action.parent;
        if (!!parent?.id) {
          const subscription = subscriptions[parent.id];
          if (!!subscription) {
            subscriptions = {...subscriptions};
            subscriptions[parent.id] = {...subscriptions[parent.id],entity:parent};
          }
        }
      }
      return { ...state, entities, indices, previousCacheId, ...ioState, parent, subscriptions };
    });
  }),
  on(mediaLoadPageFailedAction,(state, action) => {
    return logMediaAction("mediaLoadPageFailedAction",false,state,action,() => {
      return {
        ...state,
        error: action.error,
        loading: false
      };
    });
  }),
  on(mediaCacheUpsertAction,(state, action) => {
    return logMediaAction("mediaCacheUpsertAction",false,state,action,()=>{
      if (!!action.media && action.cacheId==state.currentCacheId) {
        const after  : number = !!action.afterId  ? state.indices[action.afterId]  : undefined;
        const before : number = !!action.beforeId ? state.indices[action.beforeId] : undefined;
        const addAfter  = isValidNumber(after);
        const addBefore = isValidNumber(before);
        const oldIndex: number = state.indices[action.media.id];
        const hasIndex  = isValidNumber(oldIndex);
        const newIndex: number = addAfter ? after+1 : addBefore ? before : hasIndex ? oldIndex : state.entities.length;
        let backingArray: Media[] = (<any>state.entities).backingArray;
        //console.log("mediaCacheUpsertAction.2",hasIndex,backingArray[oldIndex],action.media);
        if (!hasIndex || backingArray[oldIndex].version<=action.media.version) {
          state = {
            ...state
          };
          let loadPageFunction: (pageIndex: number, pageSize: number) => void = ((<any>state.entities).loadPageFunction ||
            ((pageIndex:number, pageSize:number): void => {}))() ;
          if (hasIndex && oldIndex==newIndex) {
            backingArray[oldIndex] = action.media;
            state.entities = createPageLoadingProxy<Media>(backingArray,25, loadPageFunction);
          } else {
            const indices: { [key: string]: number } = {};
            if (hasIndex) {
              backingArray.splice(newIndex,0,action.media);
              backingArray.splice(oldIndex<newIndex ? oldIndex : oldIndex+1,1);
            } else {
              backingArray.splice(newIndex,0,action.media);
            }
            backingArray.forEach((value,index)=>{
              if (!!value?.id) {
                indices[value.id] = index;
              }
            });
            state.entities = createPageLoadingProxy<Media>(backingArray,25, loadPageFunction);
            state.indices  = indices;
          }
        }
      }
      return state;
    });
  }),
  on(mediaCacheRemoveAction,(state, action) => {
    return logMediaAction("mediaCacheRemoveAction",false,state,action,()=>{
      if (!!action.media?.id && action.cacheId==state.currentCacheId) {
        const index: number = state.indices[action.media.id];
        if (isValidNumber(index)) {
          const indices: { [key: string]: number } = {};
          const loadPageFunction: (pageIndex: number, pageSize: number) => void = ((<any>state.entities).loadPageFunction ||
            ((pageIndex:number, pageSize:number): void => {}))() ;
          const backingArray: Media[] = (<any>state.entities).backingArray;
          backingArray.splice(index,1);
          backingArray.forEach((value,index)=>{
            if (!!value?.id) {
              indices[value.id] = index;
            }
          });
          return {
            ...state,
            entities: createPageLoadingProxy<Media>(backingArray,25, loadPageFunction),
            indices: indices
          };
        }
      }
      return state;
    });
  }),
  on(mediaUpdateAction, mediaUpdateDoneAction,(state, action) => {
    return logMediaAction("mediaUpdateAction,mediaUpdateDoneAction",false,state,action,()=>{
      let index : number = state.indices[action.media.id];
      const isInArray = isValidNumber(index) &&
        state.entities[index]?.version <= action.media.version;
      //console.debug('MEDIA UPDATE', 'action', action, 'index', index,"isInArray",isInArray);
      const isInSubscriptions =
        !!state.subscriptions[action.media.id] &&
        state.subscriptions[action.media.id]?.entity?.version != action.media.version;
      if (isInArray||isInSubscriptions) {
        state = {
          ...state,
          error: null
        };
        if (isInArray) {
          let backingArray : Media[] = (<any>state.entities).backingArray ?? [];
          let loadPageFunction : (pageIndex:number,pageSize:number)=>void = (<any>state.entities).loadPageFunction();
          backingArray[index] = action.media;
          state.entities = createPageLoadingProxy<Media>(backingArray,25, loadPageFunction);
        }
        if (isInSubscriptions) {
          state.subscriptions = {
            ...state.subscriptions
          };
          state.subscriptions[action.media.id] = {
            ...state.subscriptions[action.media.id],
            entity: action.media
          };
        }
      }
      return state;
    });
  }),
  on(mediaUpdateFailedAction,(state, action) => {
    return logMediaAction("mediaUpdateFailedAction",false,state,action,()=>{
      // restore previous media.
      // we have optimistic media updates => new media is already in the store
      // if server reports an error have to revert the media to its original version
      return mediaListReducer(
        {...state, error: action.error },
        mediaUpdateDoneAction({correlationId:action.correlationId,media:action.media}));
    });
  }),
  on(mediaSubscribeAction,(state, action) => {
    return logMediaAction("mediaSubscribeAction",true, state, action,() => {
      let subscribed = state.subscriptions[action.id];
      if (!subscribed) {
        let subscriptions = { ...state.subscriptions };
        let index  = state.indices[action.id];
        let entity = action.media = isValidNumber(index) ? state.entities[index] : undefined;
        subscriptions[action.id] = {
          subscribed: 1,
          entity: entity ?? <Media>{ id: action.id, version: 0 }
        };
        return {
          ...state,
          subscriptions
        }
      } else {
        action.media = subscribed.entity;
        subscribed.subscribed++;
      }
      return state;
    });
  }),
  on(mediaUnsubscribeAction,(state, action) => {
    return logMediaAction("mediaUnsubscribeAction",false,state,action,()=>{
      let subscribed = state.subscriptions[action.id];
      action.media = subscribed?.entity;
      if (subscribed?.subscribed>1) {
        subscribed.subscribed--;
      } else if (!!subscribed) {
        let subscriptions = { ...state.subscriptions };
        delete subscriptions[action.id];
        return {
          ...state,
          subscriptions
        }
      }
      return state;
    });
  })
);

export const mediaOnboardingReducer = createReducer(
    initialMediaOnboardingListState,
    on(mediaOnboardingLoadAction,(state, action) => {
      return logMediaAction("mediaOnboardingLoadAction",false,state,action,()=>{
        return {
          ...state,
          selectedIndex: undefined,
          // currentCacheId: createCacheId(),
          parent: null,
          loading: true,
        }
      });
    }),
    on(mediaOnboardingLoadDoneAction,(state, action) => {
      return logMediaAction("mediaOnboardingLoadDoneAction",false,state,action,()=>{
        let { entities, indices, previousCacheId, parent, subscriptions } = { ...state };
        const ioState = { loading: false, error: null };
        if (/*action.cacheId == state.currentCacheId && */(!!action.data)) {
          // backingArray may have not been set on entities proxy when there are no media filters defined
          // and the page load request is initiated by MediaUpdateSearchThermAction
          // in this case the createPageLoadingArray() function which prepares the proxy array assigns 'backingArray' and 'loadPageFunction' is not invoked
          // however loadPage$ effect still fires MediaLoadPageSuccessAction (despite that the actual server request is prevented)
          const backingArray: Media[] = (<any>entities).backingArray ?? [];
          const loadPageFunction: (pageIndex: number, pageSize: number) => void = (
            (<any>state.entities).loadPageFunction ||
            (() => ((pageIndex:number, pageSize:number): void => {}))
          )() ;
          entities = createPageLoadingProxy<Media>(backingArray,25, loadPageFunction)
        }
        const dataLength = action.data?.length ?? 0;
        const dataIndex  = 0;
        entities['loaded'] = Math.max(entities['loaded'] ?? 0, dataIndex + dataLength);
        for (let i=0, max=dataLength; i<max; i++) {
          entities[i] = action.data[i];
          indices[action.data[i].id] = i;
        }
        return { ...state, entities, indices, previousCacheId, ...ioState, parent, subscriptions };
      });
    }),
    on(mediaOnboardingLoadFailedAction,(state, action) => {
      return logMediaAction("mediaOnboardingLoadFailedAction",true,state,action,()=>{
        return {
          ...state,
          error: action.error,
          loading: false
        };
      });
    }),
    on(mediaOnboardingUpdateAction, mediaOnboardingUpdateDoneAction,(state, action) => {
    return logMediaAction("mediaUpdateAction,mediaUpdateDoneAction",false,state,action,()=>{
      let index : number = state.indices[action.media.id];
      const isInArray = isValidNumber(index) &&
        state.entities[index]?.version <= action.media.version;
      //console.debug('MEDIA UPDATE', 'action', action, 'index', index,"isInArray",isInArray);
      const isInSubscriptions =
        !!state.subscriptions[action.media.id] &&
        state.subscriptions[action.media.id]?.entity?.version != action.media.version;
      if (isInArray||isInSubscriptions) {
        state = {
          ...state,
          error: null
        };
        if (isInArray) {
          let backingArray : Media[] = (<any>state.entities).backingArray ?? [];
          let loadPageFunction : (pageIndex:number,pageSize:number)=>void = (<any>state.entities).loadPageFunction();
          backingArray[index] = action.media;
          state.entities = createPageLoadingProxy<Media>(backingArray,25, loadPageFunction);
        }

      }
      return state;
    });
  }),
    on(mediaOnboardingUpdateFailedAction,(state, action) => {
    return logMediaAction("mediaUpdateFailedAction",false,state,action,()=>{
      // restore previous media.
      // we have optimistic media updates => new media is already in the store
      // if server reports an error have to revert the media to its original version
      return mediaOnboardingReducer(
        {...state, error: action.error },
        mediaOnboardingUpdateDoneAction({correlationId:action.correlationId,media:action.media}));
    });
  }),
);

export function logMediaAction<S,A>(name:string,log:boolean,state:S,action:A,reducer:()=>S):S {
  return logAction(name, false, state, action, reducer);
}

export const mediaReducers: ActionReducerMap<MediaState> = {
  mediaList: mediaListReducer,
  mediaOnboardingList: mediaOnboardingReducer
};

export const mediaFeatureKey = 'media';
