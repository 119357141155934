import {Logger, Platform} from "core";
import {Injectable} from "@angular/core";

declare var AudioToggle;

export class SpeakerRegistration {

  // protected audioStream: MediaStream;
  protected speakerOn = false;
  protected deregistered = false;
  protected logger = new Logger('SpeakerRegistration').setSilent(true);

  constructor(private readonly speaker: (onOff: boolean) => void) {
  }

  update(tracks: MediaStreamTrack[]) {
    this.logger.info('update', tracks?.length);
    if (!this.deregistered) {
      // charly removed this code for some reason
      // !this.audioStream && (this.audioStream = new MediaStream());
      // const currentTrackIds = this.audioStream.getTracks().map(track => track.id);
      // tracks.filter(track => track.kind=='audio').forEach(track => {
      //   const index = currentTrackIds.indexOf(track.id);
      //   index < 0 ? this.audioStream.addTrack(track) : currentTrackIds.splice(index, 1);
      // })
      // currentTrackIds.forEach(trackId => this.audioStream.removeTrack(this.audioStream.getTrackById(trackId)));
      // const speakerOn = !!this.audioStream.getTracks().length;
      const speakerOn = !!(tracks?.length>0);
      if (this.speakerOn!==speakerOn) {
        this.speaker(this.speakerOn = speakerOn);
      }
    }
  }

  deregister() {
    this.logger.info('deregister');
    this.update([]);
    this.deregistered = true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SpeakerService {

  protected speakerCounter = 0;
  protected speakerForce = false;
  protected readonly noop: boolean;

  protected logger = new Logger('Speakers');

  constructor(protected platform: Platform) {
    this.logger.info('ctor');
    this.noop = !(this.platform.is('hybrid') && typeof AudioToggle !== 'undefined' && AudioToggle?.hasBuiltInSpeaker);
  }

  register(): SpeakerRegistration {
    return new SpeakerRegistration(onOff => {
      this.speakerCounter += onOff ? 1 : -1;
      this.update();
    });
  }

  on() {
    this.speakerForce = true;
    this.update();
  }

  off() {
    this.speakerForce = false;
    this.update();
  }

  get speakerOn(): boolean {
    return !!this.speakerCounter || this.speakerForce;
  }

  update() {
    if (!this.noop) {
      // https://github.com/neohelden/cordova-plugin-audiotoggle/blob/master/www/audiotoggle.js
      if (this.speakerOn && !AudioToggle.isSpeakerphoneOn) {
        AudioToggle.setAudioMode(AudioToggle.SPEAKER);
      } else if (!this.speakerOn && AudioToggle.isSpeakerphoneOn) {
        AudioToggle.setAudioMode(AudioToggle.EARPIECE);
      }
    }
    this.logger.info('update()', this.speakerOn ? 'SPEAKER' : 'EARPIECE');
  }
}
