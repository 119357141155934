@charset "UTF-8";

/* projects/auth/src/lib/session/components/session-list/session-list.component.scss */
:host {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
cdk-virtual-scroll-viewport.sessions {
  position: relative;
  flex: 1 1 auto;
}
cdk-virtual-scroll-viewport.sessions mat-list-item {
  height: auto;
  overflow: hidden;
}
virtual-section-scroller {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.session {
  display: flex;
  align-items: center;
  padding: 0 4px 0 16px;
  height: auto;
  font-size: 0.9em;
  overflow: hidden;
}
.session .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px 8px 8px 14px;
  min-height: 60px;
  justify-content: center;
}
.session .content .line > span {
  display: inline-block;
  word-break: break-all;
}
.session .content .line > span:not(:last-of-type):after {
  content: "\2022";
  margin: 0 4px;
  color: initial;
}
.session .content .line > span.location {
  font-style: italic;
  font-weight: normal;
}
.session .content .line > span.active,
.session .content .line > span.current {
  font-weight: bold;
}
.session .content .line > span.active {
  color: darkseagreen;
}
.session .content .line > span.current {
  color: lightcoral;
}
.session .content .line:first-child {
  color: grey;
  font-size: 0.9em;
  font-weight: bold;
}
.session .content .line:nth-child(2) {
  font-size: 0.8em;
}
.session img {
  width: 40px !important;
  height: 40px !important;
}
.session mat-icon {
  width: 40px !important;
  height: 40px !important;
  color: gray;
  font-size: 40px;
}
.controls {
  margin-right: 8px;
}
:host-context(.color-scheme-dark) .session mat-icon {
  color: #AEAFB0;
}
/*# sourceMappingURL=session-list.component-6LED77P7.css.map */
