import {ChangeDetectorRef, Component} from '@angular/core';
import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import transform from 'lodash/transform';
import {Country} from "shared";
import {PropertiesService} from 'properties';
import {combineLatest, firstValueFrom, map, Observable, skip, take, takeUntil} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {FilterService} from '../../../filter.service';
import {BasicFilterListComponent} from "../../core/basic-filter-list.component";
import {I18nService, Topic} from "core";

@Component({
  selector: 'country-filter',
  templateUrl: '../../core/basic-filter-list.component.html',
  styleUrls: ['../../core/basic-filter-list.component.scss']
})
export class CountryFilterComponent extends BasicFilterListComponent {

  constructor(
    protected filterService: FilterService,
    protected propertiesService: PropertiesService,
    protected translateService: TranslateService,
    protected i18nService: I18nService,
    protected changeDetectorRef: ChangeDetectorRef) {
    super(filterService,changeDetectorRef);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.i18nService.language$.pipe(takeUntil(this.onDestroy$), skip(1)).subscribe(language=>{
      console.log("LANGUAGE",language);
      this.resetFilters();
    });
    const items$ = combineLatest([
      this.translateService.stream(['countries', 'languages']),
      this.propertiesService.user$
    ]).pipe(
      map(([labels, user]) => {
        const currentLang = this.translateService.currentLang;
        const currentCountryCode = user.countryCode;
        const locales = pickBy(labels['languages'], (value, key) => {
          return startsWith(key, `${currentLang}_`);
        });
        const countryCodes = transform(locales, (result, value: string, key: string) => {
          const localeParts = key.split('_');
          const countryCode = localeParts[localeParts.length - 1];
          if (result.indexOf(countryCode) == -1) {
            result.push(countryCode);
          }
        }, []);
        const countries = transform(
          labels['countries'],
          (result: Country[], value: string, key: string) => {
            if (countryCodes.includes(key)) {
              const item: any = { id: 'country.' + key, label: value };
              if (key == currentCountryCode) {
                result.unshift(item);
              } else {
                result.push(item);
              }
            }
          }, []
        );
        return countries;
      }));
    items$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((items) =>  {
      this.items$.next(items);
      this.updateCacheId();
      }
    )
  }

  // getFilter(): Topic {
  //   return this.context.topics[0];
  // }

  // protected loadItems(value?: string, selectedIds?: string[]) {
  //   console.log("LANGUAGE.loadItems",value,selectedIds);
  // }
}
