import {Injectable} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {concat, from, Observable} from "rxjs";
import {auditTime, concatMap, filter, map, scan, switchMap, tap} from "rxjs/operators";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import {Logger} from "core";

@Injectable({
  providedIn: 'root'
})
export class FormChangeDetector {

  protected logger = new Logger('FormChangeDetector').setSilent(true);

  public detect(form: FormGroup, options?: { trigger$?: Observable<any>, normalizer?: (value: any) => Promise<any>, resetter?: Observable<void>, log?: boolean }): Observable<boolean> {
    const logger = new Logger('FormChangeDetector.detect()').setSilent(!options?.log);
    const normalizer = options?.normalizer || (value => Promise.resolve(value));
    const resetter = options?.resetter || form.statusChanges.pipe(filter(() => form.pristine)) /* EMPTY */;
    const once = (value) => new Observable(subscriber => {
      subscriber.next(value);
      subscriber.complete();
    });
    const reset$  = concat(once(void 0), resetter);
    const detector$ = (): Observable<any> => (options?.trigger$ || concat(
        once(form.getRawValue()).pipe(tap( value => logger.debug('start', { value }))),
        form.valueChanges,
      ).pipe(auditTime(0))  // form pristine/dirty state after reset() may not be reflected yet)).pipe(
    ).pipe(
      tap(change => logger.debug({change, form})),
      // ATTENTION: switchMap usage instead of concatMap will lead to racing conditions
      // this will happen when the detector$ emits next value before the previously invoked normalizer promise is not yet resolved
      // then switchMap cancel the previous inner observable and create a new observable leading to wrong detection of index in the following scan operator
      // which itself leads to incorrect initial state setup!
      concatMap(value => from(normalizer(value))),
      scan((state: { initial: object, next: object }, value: object, index: number) => {
          logger.debug({ state, value, index, pristine: form.pristine });
          if (value) {
            state = index == 0 //form.pristine
              ? { initial: cloneDeep(value), next: value }
              : { ...state, ...{ next: value } };
          }
          logger.debug({state});
          return state;
        }, { initial: undefined, next: undefined }
      ),
      map((state: any) => {
        const initial = Object.getOwnPropertyNames(state.initial || []);
        const    next = Object.getOwnPropertyNames(state.next || []);
        let  pristine = initial.length == next.length;
        if (pristine) {
          for (let i = 0; i < initial.length; i++) {
            let property = initial[i];
            logger.debug(property, { initial: state.initial[property], next: state.next[property] });
            const compareDates = state.initial[property] instanceof Date && state.next[property] instanceof Date;
            if (compareDates && state.initial[property].getTime() !== state.next[property].getTime() ||
              !compareDates && !isEqual(state.initial[property], state.next[property]) /*state.initial[property] !== state.next[property]*/) {
              pristine = false;
              break;
            }
          }
        }
        return pristine;
      })
    );
    return reset$.pipe(
      tap(reset => logger.debug('RESET!')),
      // a new detector is created after each reset (by calling detector$() function) in order to reflect
      // the current form value in case a manual trigger$ is not specified as option
      switchMap(() => detector$())  /* avoid concatMap */
    );
  }
}
