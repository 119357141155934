/* angular:styles/component:css;06ad475d72fb2404b600e1169aee9aa035c61f6e223eae4d4d0cf3896c680e26;/Users/mbratoev/Project/fbgroup/client.ng/projects/media/src/lib/components/media-viewer/overlay/media-viewer-bottom-sheet.component.ts */
:host {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
::ng-deep .media-viewer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
/*# sourceMappingURL=media-viewer-bottom-sheet.component-RJQBQXWT.css.map */
