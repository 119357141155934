import {createReducer, on} from "@ngrx/store";
import {Topic} from "core";
import isEqual from 'lodash/isEqual';
import {registerFilterHandlerAction, setFilterTemplatesAction, setFilterValuesAction} from "../store/actions";
import {FilterHandler} from "./models";
import {createFilterDataKey, getFilterTopics, getFilterValues} from "./state";
import {EMPTY_ARRAY} from "core";

export interface FilterData {
  path: string,
  view?: string,
  values: string[]
}

export interface FilterState {
  registry: {[type:string]:FilterHandler},
  templates: Topic[],
  data: {[key:string]:FilterData},
}

export let initialFilterState: FilterState = {
  registry: {},
  templates: [],
  data: {}
};

export interface State {
  filter: FilterState;
}

export const filterReducer = createReducer(initialFilterState,
  on(registerFilterHandlerAction,(state, action) => {
    if (!!action.filterType) {
      if (!!action.filterHandler) {
        if (state.registry[action.filterType]!==action.filterHandler) {
          const result = {...state, registry:{...state.registry}};
          result.registry[action.filterType] = action.filterHandler;
          return result;
        }
      } else if (!!state.registry[action.filterType]) {
        const result = {...state, registry:{...state.registry}};
        delete result.registry[action.filterType];
        return result;
      }
    }
    return state;
  }),
  on(setFilterTemplatesAction,(state, action) => {
    if (!isEqual(action.templates,state.templates)) {
      const result    = { registry:state.registry, templates: action.templates, data: {...state.data} };
      console.log("setFilterTemplatesAction",action,result);
      const registry  = result.registry;
      const temporary = new Map<string,string>();
      Object.keys(result.data).forEach(key => {
        const data:FilterData = {...result.data[key]};
        data.values = data.values?.length>0
          // we need to filter all filter values a according to the specific filter topics for this path/view...
          ? getFilterValues(data.values,getFilterTopics(result.templates??EMPTY_ARRAY,undefined,0,data.path,data.view,{},registry),data.path,data.view,registry,temporary)
          : EMPTY_ARRAY;
        result.data[key] = data;
      });
      return result;
    }
    return state;
  }),
  on(setFilterValuesAction,(state, action) => {
    const key = createFilterDataKey(action.path,action.view);
    let data:FilterData = state.data[key];
    if (!data || !isEqual(data.values,action.values)) {
      const result = { ...state,data: {...state.data} };
      const registry = result.registry;
      const values = action.merge ? [...data?.values??EMPTY_ARRAY,...action.values??EMPTY_ARRAY] : action.values??EMPTY_ARRAY;
      data = {path:action.path,view:action.view,values};
      data.values = values.length>0
        // we need to filter all filter values a according to the specific filter topics for this path/view...
        ? getFilterValues(values,getFilterTopics(result.templates??EMPTY_ARRAY,undefined,0,data.path,data.view,{},registry),data.path,data.view,registry)
        : EMPTY_ARRAY;
      result.data[key] = data;
      return result;
    }
    return state;
  }),
);
