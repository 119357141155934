import {InjectionToken, NgModule} from '@angular/core';
import {resetAppAction,resetGroupAction} from "./store";

export const RESET_APP_ACTION = new InjectionToken('RESET_APP_ACTION');
export const RESET_GROUP_ACTION = new InjectionToken('RESET_GROUP_ACTION');

@NgModule({
  providers: [
    { provide: RESET_APP_ACTION, useValue: resetAppAction() },
    { provide: RESET_GROUP_ACTION, useValue: resetGroupAction() }
  ]
})
export class StoreActionsModule {

}
