import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, withLatestFrom} from 'rxjs/operators';
import {closeSidenavAction, ensureSidenavAction, openSidenavAction, toggleSidenavAction} from "./actions";
import {LayoutState, Sidenav} from "./reducers";
import {noAction} from "core";

@Injectable()
export class LayoutEffects {

  toggle$ = createEffect(() =>
      this.actions$.pipe(
        ofType(toggleSidenavAction),
        withLatestFrom(this.store$.pipe(select(state => state.layout))),
        map(([action,state]) => {
          let open = action.sidenav==Sidenav.NAVIGATION ? state.navigation.open : state.details.open;
          return open ?
            closeSidenavAction({sidenav:action.sidenav}) :
            openSidenavAction({sidenav:action.sidenav});
        })
      ));

  ensure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ensureSidenavAction),
      withLatestFrom(this.store$.pipe(select(state => state.layout))),
      map(([action,state]) => {
        let open = action.sidenav==Sidenav.NAVIGATION ? state.navigation.open : state.details.open;
        return open == action.open ? noAction :
          open ?
            closeSidenavAction({sidenav:action.sidenav}) :
            openSidenavAction({sidenav:action.sidenav});
      })
    ));

  constructor(private actions$: Actions, private store$: Store<LayoutState>) {}
}
