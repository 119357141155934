import {Component, Inject, Injectable, Input, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {map} from "rxjs/operators";
import {Media} from "../../store/models";
import {MediaService} from "../../service/media.service";
import {MatCheckbox} from "@angular/material/checkbox";
import {PropertiesService} from "properties";

@Component({
  selector: 'app-media-change-dialog',
  template: `
    <h1 mat-dialog-title align="center">{{propertiesService.group?.name ?? ('app.name' | translate)}}</h1>
    <div mat-dialog-content>
      <mat-icon class="mat-accent">warning</mat-icon>
      <h4 [innerHTML]="label | translate:params | safeHtml"></h4>
    </div>
    <div class="content"><ng-content></ng-content></div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close cdkFocusInitial><span translate>actions.cancel</span></button>
      <button mat-button [mat-dialog-close]="result"><span translate>actions.proceed</span></button>
    </div>
  `,
  styles: [
    `.mat-mdc-dialog-content { display: flex; align-items: center; }`,
    `.content { display: flex; flex-direction: column; padding-left: 3em; margin-left: 10px}`,
    `h4 { text-align: center; margin: 0 }`,
    `mat-icon { width: auto; height: auto; font-size: 3em; margin-right: 10px;}`,
    `div[mat-dialog-actions] { margin-top:16px; }`,
  ]
})
export class MediaChangeDialogComponent {
  @Input() label = 'media.confirmChange';
  @Input() params: object;
  @Input() result: any = 'proceed';
  constructor(public propertiesService: PropertiesService) {}
}

@Component({
  selector: 'app-media-delete-dialog',
  template: `<app-media-change-dialog [label]="'media.confirmDelete'" [result]="result">
                <h4>
                  <mat-checkbox *ngIf="data.media?.tags?.includes('folder')" #deleteOrphans>
                    <span translate>media.confirmDeleteOrphans</span>
                  </mat-checkbox>
                </h4>
            </app-media-change-dialog>`
})
export class MediaDeleteDialogComponent {
  @ViewChild('deleteOrphans', { static: true }) deleteOrphans: MatCheckbox;
  result: { action: string, deleteOrphans: boolean };
  constructor(public dialogRef: MatDialogRef<MediaDeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    const self = this;
    this.result = {
      action: 'proceed',
      get deleteOrphans() { return self.deleteOrphans?.checked }
    };
  }
}

@Component({
  selector: 'app-media-composition-delete-dialog',
  template: `<app-media-change-dialog [label]="'media.composition.confirmDelete'"></app-media-change-dialog>`
})
export class MediaCompositionDeleteDialogComponent {}

@Injectable()
export class MediaChangeConsent {

  constructor(protected dialog: MatDialog,
              protected mediaService: MediaService) {}

  display(media: Media, previous?: Media): Promise<boolean> {
    return this.dialog
      .open(MediaChangeDialogComponent)
      .afterClosed()
      .pipe(map((result) => result === 'proceed'))
      .toPromise();
  }
}
