import {ActivatedRouteSnapshot, RouteReuseStrategy} from '@angular/router';
import {Injectable} from "@angular/core";
import {DetachedRouteHandleExt, RouteReusableStrategyCache} from "./route-reusable-strategy-cache";
import {Logger} from "../logger.service";

@Injectable()
export class RouteReusableStrategy extends RouteReuseStrategy {

  // protected routeCache = new Map<string,DetachedRouteHandleExt>();

  protected logger = new Logger('RouteReusableStrategy');

  public constructor(protected routeCache: RouteReusableStrategyCache) {
    super();
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const detach = !!route.data.reuse;
    const { routeConfig, data, outlet, fragment, params } = { ...route };
    const routePath = this.getPath(route);
    //this.logger.info("shouldDetach", { routePath, routeConfig, data, outlet, fragment, params });
    return detach;
  }

  public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandleExt | null): void {
    const routePath = this.getPath(route);
    //this.logger.info('store', { routePath });
    if (detachedTree) {
      const detachedRouteTree = this.routeCache.get(routePath);
      if (detachedRouteTree && detachedRouteTree.componentRef !== detachedTree.componentRef) {
        //this.logger.info('store > destroy detached tree', { routePath });
        detachedRouteTree.componentRef.destroy();
      }
      this.routeCache.set(routePath, detachedTree);
      //this.callHook(detachedTree, 'ngOnDetach');
    }
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    //this.logger.info('shouldAttach', { component: route.component });
    let shouldAttach;
    // if (route.component == LoginComponent) {
    //   this.routeCache.clear();
    // } else {
      const routePath = this.getPath(route);
      const cached = this.routeCache.get(routePath);
      const destroyed = !!(cached && cached.componentRef.hostView.destroyed);
      shouldAttach = !destroyed && !!cached;
      //this.logger.info('shouldAttach', { routePath, cached, destroyed, result: shouldAttach });
    // }
    return shouldAttach;
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandleExt | null {
    //this.logger.info('retrieve', { route });
    if (!route.routeConfig) return null;
    if (route.routeConfig.loadChildren) return null;
    const routePath = this.getPath(route);
    let   cached    = this.routeCache.get(routePath);
    const destroyed = !!(cached && cached.componentRef.hostView.destroyed);
    if (destroyed) {
      cached = null;
      this.routeCache.delete(routePath);
    }
    //this.logger.info('retrieve', { routePath, cached, destroyed });
    return cached;
  }

  public shouldReuseRoute(source: ActivatedRouteSnapshot, target: ActivatedRouteSnapshot): boolean {
    let sourceRoutePath = this.getPath(source);
    let targetRoutePath = this.getPath(target);
    let result = (source.routeConfig === target.routeConfig) || (source.data.reuse && sourceRoutePath==targetRoutePath);
    //this.logger.info('shouldReuseRoute', { sourceRoutePath, targetRoutePath, result, sourceReuse: source.data.reuse});
    return result;
  }

  protected getPath(snapshot: ActivatedRouteSnapshot): string {
    const componentName = (route: ActivatedRouteSnapshot) => {
      if (route.data?.cacheId) {
       return`${route.data.cacheId}`;
      } else {
        const component = route.routeConfig.component;
        if (typeof component === 'function') {
          return `${component.name}`;
        } else if (typeof component === 'string') {
          return component;
        } else {
          return '';
        }
      }
    };
    return snapshot.outlet+':'+
           snapshot.data?.reuse && !!snapshot.routeConfig?.component
            ? componentName(snapshot)
            : snapshot.pathFromRoot
               .filter(route => route.routeConfig)
               .map(route => {
                 const config = route.routeConfig;
                 const path = `${config.path ? `${config.path}#` : ''}`;
                 return `${path}${componentName(route)}`;
               })
               .filter(path => !!path)
               .join('->');
  }

  protected callHook(
    detachedTree: DetachedRouteHandleExt,
    hookName: string
  ): void {
    const componentRef = detachedTree.componentRef;
    if (
      componentRef &&
      componentRef.instance &&
      typeof componentRef.instance[hookName] === 'function'
    ) {
      componentRef.instance[hookName]();
    }
  }
}
