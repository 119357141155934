import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from "@ngrx/effects";
import {LayoutService} from "./layout.service";
import {LayoutEffects} from "./effects";
import {reducer} from "./reducers";

@NgModule({
  imports: [
    StoreModule.forFeature('layout', reducer),
    EffectsModule.forFeature([LayoutEffects])
  ],
  providers: [LayoutEffects, LayoutService]
})
export class LayoutModule {}
