import {NgModule, Optional, SkipSelf} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {OneSignal} from "@ionic-native/onesignal/ngx/index";

@NgModule({
  imports: [
    TranslateModule
  ],
  providers: [
    // OneSignal
  ],
  exports: [
  ]
})
export class NotificationModule {
  constructor(@Optional() @SkipSelf() parentModule: NotificationModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import NotificationModule module in the AppModule only.`);
    }
  }
}
