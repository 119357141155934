import {inject, InjectionToken} from "@angular/core";
import {SessionTokenService} from "./session-token.service";

// export const SESSION_TOKEN = new InjectionToken<Promise<string>>('sessionToken', {
//   providedIn: 'root',
//   factory: (): Promise<string> => {
//     const  appIdentifier = inject(AppIdentifierProvider);
//     const  deviceService = inject(DeviceService);
//     return deviceService.device().then(device => {
//       const base64 = (str: string) => {
//           // first we use encodeURIComponent to get percent-encoded UTF-8,
//           // then we convert the percent encodings into raw bytes which
//           // can be fed into btoa.
//           return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
//             function bytes(match, p1) {
//               return String.fromCharCode(parseInt('0x' + p1));
//             })
//           );
//       };
//       return base64(`${device.id}#${appIdentifier.getAppId()}`);
//     });
//   }
// });

// export const SESSION_TOKEN = new InjectionToken<string>('sessionToken');
// export function createSessionToken(appId: number, deviceService: DeviceService) {
//   return deviceService.device().then(device => {
//     const base64 = (str: string) => {
//         // first we use encodeURIComponent to get percent-encoded UTF-8,
//         // then we convert the percent encodings into raw bytes which
//         // can be fed into btoa.
//         return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
//           function bytes(match, p1) {
//             return String.fromCharCode(parseInt('0x' + p1));
//           })
//         );
//     };
//     return base64(`${device.id}#${appId}`);
//   });
// }

export const SESSION_TOKEN = new InjectionToken<string>('sessionToken', {
  providedIn: 'root',
  factory: (): string => {
    const  sessionTokenService = inject(SessionTokenService);
    return sessionTokenService.token;
  }
});
