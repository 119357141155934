<ng-container [ngSwitch]="media?.mediaType">
  <mat-icon material-x *ngSwitchCase="'image'">image</mat-icon>
  <mat-icon material-x *ngSwitchCase="'pdf'">pdf</mat-icon>
  <mat-icon material-x *ngSwitchCase="'video'">{{playable ? 'play' : 'video'}}</mat-icon>
  <mat-icon material-x *ngSwitchCase="'audio'">{{playable ? 'play' : 'audio'}}</mat-icon>
  <mat-icon material-x *ngSwitchCase="'survey'">poll</mat-icon>
  <mat-icon material-x *ngSwitchCase="'iap'">iap</mat-icon>
  <mat-icon material-x *ngSwitchCase="'link'">link</mat-icon>
  <mat-icon material-x *ngSwitchDefault>file</mat-icon>
</ng-container>
