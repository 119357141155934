<div #frame class="frame" (onAttach)="ngOnAttach()" (onDetach)="ngOnDetach()">
  <div class="content">
    <div #logo1 class="logo"
         [class.foreground]="mediaWrapper1.current"
         [style]="mediaWrapper1.style$ | async">
      <ng-container *ngTemplateOutlet="logoTemplate; context: { wrapper: mediaWrapper1 }"></ng-container>
    </div>
    <div #logo2 class="logo"
         [class.foreground]="mediaWrapper2.current"
         [style]="mediaWrapper2.style$ | async">
      <ng-container *ngTemplateOutlet="logoTemplate; context: { wrapper: mediaWrapper2 }"></ng-container>
    </div>
    <div class="placeholder" [class.show]="!mediaWrapper1.current && !mediaWrapper2.current">
      <div></div>
    </div>
  </div>
</div>

<ng-template #logoTemplate let-wrapper="wrapper" let-color="color">
  <div>
    <media-card *ngLet="(wrapper.image$ | async) as media"
                [style]="wrapper.imageStyle$ | async"
                [media]="media"
                [backgroundColor]="backgroundColor"
                [elevated]="elevated"
                [rounded]="rounded"
                [indicator]="false"
                [selectable]="false"
                [showDuration]="true"
                (onCoverLoaded)="wrapper.onImageLoaded(media)"
                (play)="onPlayMedia(media, $event)">
    </media-card>
    <div class="video" [class.rounded]="rounded" [class.elevated]="elevated">
      <div>
        <div>
          <div [style]="wrapper.videoStyle$ | async">
            <app-video #player *ngLet="(wrapper.video$ | async) as media"
                       [autoplay]="true"
                       [controls]="false"
                       [inline]="true"
                       [muted]="true"
                       (playing)="wrapper.onVideoPlaying(media,$event,player)"
                       (loadedMetaData)="wrapper.onMetadataLoaded(media,player)"
                       (loadedData)="wrapper.onVideoLoaded(media,player)"
                       [media]="media">
            </app-video>
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="info">animated:{{wrapper.animated}} playing:{{wrapper.playing}}:{{wrapper.playingX}}</div>
    -->
  </div>
</ng-template>
