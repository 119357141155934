import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBarComponent),
      multi: true
    }
  ]
})
export class SearchBarComponent implements OnInit, ControlValueAccessor {

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (this.search) {
      this.writeValue('');
      this.search = false;
    }
  }

  @Input() leftIcon : string = "menu";
  @Input() rightIcon : string = "more_vert";
  @Input() color : string = "primary";
  @Input() leftButton : boolean = true;
  @Input() rightButton : boolean = true;
  @Input() searchButton : boolean = true;
  @Input() search : boolean = false;
  @Input() disabled = false;
  @Input() value : string = null;

  @Input() rightButtonTemplateRef: TemplateRef<any>;
  @ViewChild('rightButtonTemplate', { static: true }) rightButtonTemplate: TemplateRef<any>;
  @Input() extensionTemplateRef: TemplateRef<any>;
  @ViewChild('extensionTemplate', { static: true }) extensionTemplate: TemplateRef<any>;

  @Output() onLeftClicked: EventEmitter<any> = new EventEmitter();
  @Output() onTitleClicked: EventEmitter<any> = new EventEmitter();
  @Output() onRightClicked: EventEmitter<any> = new EventEmitter();
  @Output() onSearchTermChange: EventEmitter<string> = new EventEmitter();

  protected onChangeFunction = (term: string) : void => {};

  constructor() {
  }

  ngOnInit() {
    this.rightButtonTemplateRef = this.rightButtonTemplateRef ?? this.rightButtonTemplate;
    this.extensionTemplateRef   = this.extensionTemplateRef ?? this.extensionTemplate;
  }

  emitLeftClicked() : void {
    this.onLeftClicked.emit();
  }

  emitTitleClicked() : void {
    this.onTitleClicked.emit();
  }

  emitRightClicked() : void {
    this.onRightClicked.emit();
  }

  onSearchClicked() : void {
    this.search = true;
  }

  onSearchCloseClicked() : void {
    // console.debug("LEFT "+this.leftIcon);
    // console.debug("RIGHT "+this.rightIcon);
    // console.debug("search "+this.search);

    this.writeValue('');
    this.search = false;
  }

  /*
    implementations for ControlValueAccessor to use SearchBarComponent
    with ngModel for the value
   */
  writeValue(value: string): void {
    // console.debug('writeValue', value);
    value = value || '';
    if (this.value != value) {
      this.value = value || '';
      this.onChange(this.value);
    }
  }

  onChange(value : string) : void {
    //console.debug('onChange', value);
    this.onSearchTermChange.emit(value);
    this.onChangeFunction(value);
  }

  registerOnChange(fn: (term: string) => void): void {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: () => void): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get titleWidth() {
    const buttonWidth = 40;
    const buttons = [this.leftButton, this.rightButton, this.searchButton].filter(button => button).length;
    return `calc(100% - ${buttons*buttonWidth}px)`;
  }
}
