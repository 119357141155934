import {NgModule, Optional, SkipSelf} from "@angular/core";
import {SessionModule} from "session";

@NgModule({
  imports: [SessionModule],
  providers: []
})
export class MessagingModule {

  constructor(@Optional() @SkipSelf() parentModule: MessagingModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import MessagingModule module only once.`);
    }
  }
}
