<ng-container *ngIf="memberId" [ngSwitch]="!!actions?.length">
  <ng-container *ngSwitchCase="true">
    <a tabIndex="-1" href="javascript:void(0);" [matMenuTriggerFor]="menu">
      <span [class]="role">{{memberId}}</span>
    </a>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let action of actions; let index = index">
        <ng-container [ngSwitch]="action.type!='separator'">
            <button *ngSwitchCase="true" mat-menu-item  [action]="action">
              <mat-icon>{{action.icon}}</mat-icon>
              <span>{{action.label | translate}}</span>
            </button>
            <mat-divider *ngSwitchCase="false"></mat-divider>
          </ng-container>
        </ng-container>
    </mat-menu>
  </ng-container>
  <span *ngSwitchDefault [class]="role">{{memberId}}</span>
</ng-container>

