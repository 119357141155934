import {ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ENVIRONMENT, Logger} from "core";
import {startWith, Subscription} from "rxjs";
import {isValidNumber, RouteContext} from "shared";

//https://stackoverflow.com/questions/43631813/angular-pass-multiple-templates-to-component


@Component({
  selector: 'app-thumb-status-right',
  //template: '<span *ngIf="!!status" [ngStyle]="style">{{status}}</span>',
  template: `
    <span *ngIf="!!count">{{count}}</span>
    <button tabindex="-1" *ngIf="filters!=undefined"
            mat-icon-button
            matBadge="!"
            [matBadgeHidden]="!filters"
            [class.inverted]="filters"
            (click)="onFilter($event)">
      <mat-icon>tune</mat-icon>
    </button>
  `,
  styleUrls: ['./thumb-status-right.component.scss']
})
export class ThumbStatusRightComponent implements OnInit, OnDestroy {

  count: string;
  filters: boolean;
  subscription: Subscription;
  style: any;
  protected context: RouteContext;

  protected logger = new Logger();

  constructor(private route: ActivatedRoute,
              private ngZone: NgZone,
              private changeDetector: ChangeDetectorRef,
              @Inject(ENVIRONMENT) private environment: any) {
    //console.debug('ThumbStatusRightComponent.ctor()');
    this.style = { backgroundColor:  this.environment.primaryColor };
  }

  ngOnInit() {
    const context: RouteContext = this.route.snapshot.data.context;
    if (context) {
      this.context = context;
      if (this.subscription) {
        this.subscription.unsubscribe()
      } else {
        this.subscription = new Subscription();
      }
      this.subscription.add(
        context.count$?.subscribe((count: number) => {
          this.ngZone.run(() => {
            this.count = isValidNumber(count) ? `${count}` : undefined;
            this.changeDetector.markForCheck();
          })
        })
      )
      this.subscription.add(context?.filters$
        .pipe(startWith(undefined))
        .subscribe((filters: boolean) => {
            this.ngZone.run(() => {
              console.log("filters:", filters);
              this.filters = filters;
              this.changeDetector.markForCheck();
          })
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  onFilter($event: MouseEvent) {
    this.context.action({ id: 'filter'});
  }
}
