/* projects/shared/src/lib/components/accordion-container/accordion-container.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
  flex-direction: column;
}
.badge {
  margin-left: 10px;
  background-color: #ddd;
  color: gray;
  padding: 0 6px;
  border-radius: 3px;
  display: inline-block;
  margin-right: 3px;
  outline: none;
  font-size: smaller;
  align-self: flex-start;
}
.button-badge {
  position: absolute;
  color: gray;
  background-color: #ddd;
  border-radius: 3px;
  padding: 2px 3px;
  right: 0;
  top: 2px;
  font-size: 14px;
  line-height: 14px;
}
.header-button {
  align-self: flex-end;
}
mat-expansion-panel-header {
  padding-left: 16px;
  padding-right: 20px;
}
.mat-expansion-panel-header-title {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-self: center;
  flex-basis: fit-content;
}
.mat-expansion-panel-header-description {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
}
mat-expansion-panel {
  flex: content;
  max-height: 46px;
  transition:
    max-height 225ms cubic-bezier(0.4, 0, 0.2, 1),
    margin 225ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
}
mat-expansion-panel ::ng-deep > .mat-expansion-panel-content {
  flex: 1;
  font-size: 1em;
  position: relative;
}
mat-expansion-panel ::ng-deep > .mat-expansion-panel-content > div {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
}
mat-expansion-panel.headless {
  max-height: 0;
}
mat-expansion-panel.mat-expanded,
mat-expansion-panel.headless.mat-expanded {
  flex: 1;
  max-height: 100vh;
  border-radius: 0;
}
.mat-expansion-panel-spacing {
  margin: 0;
}
mat-accordion {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.mat-accordion .mat-expansion-panel:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.filter-header {
  height: 46px;
}
.header-selected mat-expansion-panel-header {
  cursor: default;
}
::ng-deep .mat-expansion-panel-body {
  padding: 0 !important;
}
.reset-button {
  padding: 0;
}
:host-context(.color-scheme-light.pure) mat-expansion-panel.mat-expanded:focus mat-expansion-panel-header,
:host-context(.color-scheme-light.pure) mat-expansion-panel.mat-expanded:hover mat-expansion-panel-header {
  background-color: white !important;
}
:host-context(.color-scheme-light.pure) mat-expansion-panel-header {
  background-color: white !important;
}
:host-context(.color-scheme-light.pure) mat-expansion-panel-header {
  box-shadow: 0 0 4px 0 grey;
  z-index: 1;
}
:host-context(.color-scheme-light.pure) ::ng-deep .mat-expansion-panel-content::after {
  z-index: 1;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 1px;
  box-shadow: 0 0 4px 0 grey;
}
:host-context(.color-scheme-light.pure) ::ng-deep .mat-expansion-panel-content {
  background-color: #f5f5f5;
}
:host-context(.color-scheme-dark) mat-expansion-panel-header {
  background-color: #282828 !important;
}
:host-context(.color-scheme-dark) .reset-button {
  color: rgba(255, 255, 255, 0.7019607843);
}
:host-context(.color-scheme-dark) .reset-button[disabled] {
  color: rgba(255, 255, 255, 0.26);
}
/*# sourceMappingURL=accordion-container.component-F7ESWWTB.css.map */
