<form [formGroup]="mediaForm">
    <div formGroupName="lock" class="lock">
        <mat-form-field>
            <mat-label>{{'media.lock.formula' | translate}}</mat-label>
            <textarea matInput
                unmodified-input
                formControlName="formula"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="8">
            </textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'media.lock.message' | translate}}</mat-label>
            <textarea matInput
                unmodified-input
                formControlName="message"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="8">
            </textarea>
        </mat-form-field>
    </div>
</form>