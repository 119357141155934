import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingStarsComponent implements OnInit, OnChanges {

  static STARS_EMPTY:number[] = [];

  _rating: number;
    // stars: number[] = RatingStarsComponent.STARS_EMPTY;
  stars = new BehaviorSubject(RatingStarsComponent.STARS_EMPTY);

  @Input() small = false;
  @Input() inverted = false;
  @Input() starCount = 5;

  starSvgPath = 'assets/icons/star-rating.icons.svg#star-filled';   //star-half star-filled;

  constructor() {}

  ngOnInit() {
    /*
    window.setInterval(() => {
      this.rating = Math.floor(Math.random() * 4) + 1;
    }, 500);
     */
  }

  @Input()
  set rating(rating: number) {
    if (rating!=this._rating) {
      this._rating = rating;
      this.stars.next(this.createStars(rating));
      // this.stars = this.createStars(rating);
      // this.createStars(rating).forEach((star, index) => {
      //   if (this.stars[index]!=star) {
      //     this.stars[index] = star;
      //   }
      // });
    }
  }

  createStars(rating: number) {
    if (rating && rating > 0) {
      const starCount = Math.max(this.starCount, Math.ceil(rating));
      const stars = Array(starCount).fill(0);
      const whole = Math.floor(rating);
      const full  = Array(whole).fill(1);
      const args  = [0, full.length].concat(Array(whole).fill(1));
      Array.prototype.splice.apply(stars, args);
      const fragment = rating - whole;
      if (fragment > 0) {
        stars[full.length] = fragment;
      }
      // console.debug('RatingStarsComponent.stars', rating, stars, whole, fragment);
      return stars;
    } else {
      return RatingStarsComponent.STARS_EMPTY;
    }
  }

  starContext(star: number, index: number): { star: number, index: number, cssClass: string, fragment: boolean } {
    const fragment = star>0 && star<1;
    return {
      star: star,
      index: index,
      cssClass: `star${fragment ? ' fragment' : star==0 ? ' empty' :  ''}${this.small ? ' small' : ''}${this.inverted ? ' inverted' : ''}`,
      fragment: fragment
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.debug('CHANGES', changes);
  }

  trackByStar(index: number, star: number) {
    // console.debug('trackByStar', star);
    return star;
  }
}
