import { Injectable } from '@angular/core';
import { Topic } from 'core';

const BIBLEBOOKS: Topic[] = [
  {
    "id": "bible.0",
    "label": "bible.ot",
    "topics": [
      {
        "id": "bible.0.0",
        "label": "bible.books.genesis"
      },
      {
        "id": "bible.0.1",
        "label": "bible.books.exodus"
      },
      {
        "id": "bible.0.2",
        "label": "bible.books.leviticus"
      },
      {
        "id": "bible.0.3",
        "label": "bible.books.numbers"
      },
      {
        "id": "bible.0.4",
        "label": "bible.books.deuteronomy"
      },
      {
        "id": "bible.0.5",
        "label": "bible.books.joshua"
      },
      {
        "id": "bible.0.6",
        "label": "bible.books.judges"
      },
      {
        "id": "bible.0.7",
        "label": "bible.books.ruth"
      },
      {
        "id": "bible.0.8",
        "label": "bible.books.samuel1"
      },
      {
        "id": "bible.0.9",
        "label": "bible.books.samuel2"
      },
      {
        "id": "bible.0.10",
        "label": "bible.books.kings1"
      },
      {
        "id": "bible.0.11",
        "label": "bible.books.kings2"
      },
      {
        "id": "bible.0.12",
        "label": "bible.books.chronicles1"
      },
      {
        "id": "bible.0.13",
        "label": "bible.books.chronicles2"
      },
      {
        "id": "bible.0.14",
        "label": "bible.books.ezra"
      },
      {
        "id": "bible.0.15",
        "label": "bible.books.nehemia"
      },
      {
        "id": "bible.0.16",
        "label": "bible.books.esther"
      },
      {
        "id": "bible.0.17",
        "label": "bible.books.job"
      },
      {
        "id": "bible.0.18",
        "label": "bible.books.psalms"
      },
      {
        "id": "bible.0.19",
        "label": "bible.books.proverbs"
      },
      {
        "id": "bible.0.20",
        "label": "bible.books.ecclesiastes"
      },
      {
        "id": "bible.0.21",
        "label": "bible.books.songofsolomon"
      },
      {
        "id": "bible.0.22",
        "label": "bible.books.isaiah"
      },
      {
        "id": "bible.0.23",
        "label": "bible.books.jeremiah"
      },
      {
        "id": "bible.0.24",
        "label": "bible.books.lamentations"
      },
      {
        "id": "bible.0.25",
        "label": "bible.books.ezekiel"
      },
      {
        "id": "bible.0.26",
        "label": "bible.books.daniel"
      },
      {
        "id": "bible.0.27",
        "label": "bible.books.hosea"
      },
      {
        "id": "bible.0.28",
        "label": "bible.books.joel"
      },
      {
        "id": "bible.0.29",
        "label": "bible.books.amos"
      },
      {
        "id": "bible.0.30",
        "label": "bible.books.obadiah"
      },
      {
        "id": "bible.0.31",
        "label": "bible.books.jonah"
      },
      {
        "id": "bible.0.32",
        "label": "bible.books.micah"
      },
      {
        "id": "bible.0.33",
        "label": "bible.books.nahum"
      },
      {
        "id": "bible.0.34",
        "label": "bible.books.habakkuk"
      },
      {
        "id": "bible.0.35",
        "label": "bible.books.zephaniah"
      },
      {
        "id": "bible.0.36",
        "label": "bible.books.haggai"
      },
      {
        "id": "bible.0.37",
        "label": "bible.books.zechariah"
      },
      {
        "id": "bible.0.38",
        "label": "bible.books.malachi"
      }
    ]
  },
  {
    "id": "bible.1",
    "label": "bible.nt",
    "topics": [
      {
        "id": "bible.1.0",
        "label": "bible.books.matthew"
      },
      {
        "id": "bible.1.1",
        "label": "bible.books.mark"
      },
      {
        "id": "bible.1.2",
        "label": "bible.books.luke"
      },
      {
        "id": "bible.1.3",
        "label": "bible.books.john"
      },
      {
        "id": "bible.1.4",
        "label": "bible.books.acts"
      },
      {
        "id": "bible.1.5",
        "label": "bible.books.romans"
      },
      {
        "id": "bible.1.6",
        "label": "bible.books.corinthians1"
      },
      {
        "id": "bible.1.7",
        "label": "bible.books.corinthians2"
      },
      {
        "id": "bible.1.8",
        "label": "bible.books.galatians"
      },
      {
        "id": "bible.1.9",
        "label": "bible.books.ephesians"
      },
      {
        "id": "bible.1.10",
        "label": "bible.books.philippians"
      },
      {
        "id": "bible.1.11",
        "label": "bible.books.colossians"
      },
      {
        "id": "bible.1.12",
        "label": "bible.books.thessalonians1"
      },
      {
        "id": "bible.1.13",
        "label": "bible.books.thessalonians2"
      },
      {
        "id": "bible.1.14",
        "label": "bible.books.timothy1"
      },
      {
        "id": "bible.1.15",
        "label": "bible.books.timothy2"
      },
      {
        "id": "bible.1.16",
        "label": "bible.books.titus"
      },
      {
        "id": "bible.1.17",
        "label": "bible.books.philemon"
      },
      {
        "id": "bible.1.18",
        "label": "bible.books.hebrews"
      },
      {
        "id": "bible.1.19",
        "label": "bible.books.james"
      },
      {
        "id": "bible.1.20",
        "label": "bible.books.peter1"
      },
      {
        "id": "bible.1.21",
        "label": "bible.books.peter2"
      },
      {
        "id": "bible.1.22",
        "label": "bible.books.john1"
      },
      {
        "id": "bible.1.23",
        "label": "bible.books.john2"
      },
      {
        "id": "bible.1.24",
        "label": "bible.books.john3"
      },
      {
        "id": "bible.1.25",
        "label": "bible.books.jude"
      },
      {
        "id": "bible.1.26",
        "label": "bible.books.revelation"
      }
    ]
  }
];

const TOPICS = [
  {
    "label": "Bibel",
    "id": "topics.0",
    "topics": [
      {
        "label": "Begriffe und Themen",
        "id": "topics.0.0",
        "topics": [
          {
            "label": "10 Gebote",
            "id": "topics.0.0.0"
          },
          {
            "label": "1000 jähriges Reich (Millenium)",
            "id": "topics.0.0.1"
          },
          {
            "label": "Altes Testament",
            "id": "topics.0.0.2"
          },
          {
            "label": "Bekehrung",
            "id": "topics.0.0.3"
          },
          {
            "label": "Bitterkeit",
            "id": "topics.0.0.4"
          },
          {
            "label": "Bund",
            "id": "topics.0.0.5"
          },
          {
            "label": "Buße",
            "id": "topics.0.0.6"
          },
          {
            "label": "Christ, Staat und Politik",
            "id": "topics.0.0.7"
          },
          {
            "label": "Das Böse",
            "id": "topics.0.0.8"
          },
          {
            "label": "Demut",
            "id": "topics.0.0.9"
          },
          {
            "label": "Eifersucht und Neid",
            "id": "topics.0.0.10"
          },
          {
            "label": "Engel",
            "id": "topics.0.0.11"
          },
          {
            "label": "Entrückung",
            "id": "topics.0.0.12"
          },
          {
            "label": "Erfüllte Prophetien",
            "id": "topics.0.0.13"
          },
          {
            "label": "Erkenntnis",
            "id": "topics.0.0.14"
          },
          {
            "label": "Evangelien",
            "id": "topics.0.0.15"
          },
          {
            "label": "Evangelium",
            "id": "topics.0.0.16"
          },
          {
            "label": "Ewiges Leben",
            "id": "topics.0.0.17"
          },
          {
            "label": "Freiheit",
            "id": "topics.0.0.18"
          },
          {
            "label": "Freude",
            "id": "topics.0.0.19"
          },
          {
            "label": "Früchte des Geistes",
            "id": "topics.0.0.20"
          },
          {
            "label": "Geduld",
            "id": "topics.0.0.21"
          },
          {
            "label": "Geistliches Wachstum",
            "id": "topics.0.0.22"
          },
          {
            "label": "Genealogie",
            "id": "topics.0.0.23"
          },
          {
            "label": "Geographie",
            "id": "topics.0.0.24"
          },
          {
            "label": "Gerechtigkeit",
            "id": "topics.0.0.25"
          },
          {
            "label": "Gericht",
            "id": "topics.0.0.26"
          },
          {
            "label": "Geschichte",
            "id": "topics.0.0.27"
          },
          {
            "label": "Gesetz",
            "id": "topics.0.0.28"
          },
          {
            "label": "Gewissen",
            "id": "topics.0.0.29"
          },
          {
            "label": "Glaube",
            "id": "topics.0.0.30"
          },
          {
            "label": "Gnade",
            "id": "topics.0.0.31"
          },
          {
            "label": "Götter und Götzen",
            "id": "topics.0.0.32"
          },
          {
            "label": "Gottes Allmacht",
            "id": "topics.0.0.33"
          },
          {
            "label": "Gottes Ehre",
            "id": "topics.0.0.34"
          },
          {
            "label": "Gottes Führung",
            "id": "topics.0.0.35"
          },
          {
            "label": "Gottes Gegenwart",
            "id": "topics.0.0.36"
          },
          {
            "label": "Gottes Liebe",
            "id": "topics.0.0.37"
          },
          {
            "label": "Gottes Plan",
            "id": "topics.0.0.38"
          },
          {
            "label": "Gottes Reich",
            "id": "topics.0.0.39"
          },
          {
            "label": "Gottes Souveränität",
            "id": "topics.0.0.40"
          },
          {
            "label": "Heiden",
            "id": "topics.0.0.41"
          },
          {
            "label": "Heiligkeit",
            "id": "topics.0.0.42"
          },
          {
            "label": "Heilsgeschichte",
            "id": "topics.0.0.43"
          },
          {
            "label": "Heilsgewissheit",
            "id": "topics.0.0.44"
          },
          {
            "label": "Heilsweg",
            "id": "topics.0.0.45"
          },
          {
            "label": "Herrlichkeit",
            "id": "topics.0.0.46"
          },
          {
            "label": "Herz",
            "id": "topics.0.0.47"
          },
          {
            "label": "Himmel",
            "id": "topics.0.0.48"
          },
          {
            "label": "Hochmut",
            "id": "topics.0.0.49"
          },
          {
            "label": "Hoffnung",
            "id": "topics.0.0.50"
          },
          {
            "label": "Hölle",
            "id": "topics.0.0.51"
          },
          {
            "label": "Innerer Friede",
            "id": "topics.0.0.52"
          },
          {
            "label": "Israel",
            "id": "topics.0.0.53"
          },
          {
            "label": "Jerusalem",
            "id": "topics.0.0.54"
          },
          {
            "label": "Jesus",
            "id": "topics.0.0.55"
          },
          {
            "label": "König",
            "id": "topics.0.0.56"
          },
          {
            "label": "Körper, Seele und Geist",
            "id": "topics.0.0.57"
          },
          {
            "label": "Liebe",
            "id": "topics.0.0.58"
          },
          {
            "label": "Mut",
            "id": "topics.0.0.59"
          },
          {
            "label": "Neues Testament",
            "id": "topics.0.0.60"
          },
          {
            "label": "Offenbarung",
            "id": "topics.0.0.61"
          },
          {
            "label": "Opfer",
            "id": "topics.0.0.62"
          },
          {
            "label": "Paradies",
            "id": "topics.0.0.63"
          },
          {
            "label": "Propheten",
            "id": "topics.0.0.64"
          },
          {
            "label": "Rechtfertigung und Versöhnung",
            "id": "topics.0.0.65"
          },
          {
            "label": "Satan / Teufel",
            "id": "topics.0.0.66"
          },
          {
            "label": "Schöpfung",
            "id": "topics.0.0.67"
          },
          {
            "label": "Segen",
            "id": "topics.0.0.68"
          },
          {
            "label": "Selbstbeherrschung und Enthaltsamkeit",
            "id": "topics.0.0.69"
          },
          {
            "label": "Sprachen",
            "id": "topics.0.0.70"
          },
          {
            "label": "Stiftshütte",
            "id": "topics.0.0.71"
          },
          {
            "label": "Tempel",
            "id": "topics.0.0.72"
          },
          {
            "label": "Tiere",
            "id": "topics.0.0.73"
          },
          {
            "label": "Treue",
            "id": "topics.0.0.74"
          },
          {
            "label": "Verheißungen",
            "id": "topics.0.0.75"
          },
          {
            "label": "Vertrauen",
            "id": "topics.0.0.76"
          },
          {
            "label": "Vorherbestimmung",
            "id": "topics.0.0.77"
          },
          {
            "label": "Weisheit",
            "id": "topics.0.0.78"
          },
          {
            "label": "Welt",
            "id": "topics.0.0.79"
          },
          {
            "label": "Wiedergeburt",
            "id": "topics.0.0.80"
          },
          {
            "label": "Wiederkunft",
            "id": "topics.0.0.81"
          },
          {
            "label": "Wunder",
            "id": "topics.0.0.82"
          },
          {
            "label": "Zeit",
            "id": "topics.0.0.83"
          },
          {
            "label": "Zorn",
            "id": "topics.0.0.84"
          }
        ]
      },
      {
        "label": "Jesus Christus",
        "id": "topics.0.1",
        "topics": [
          {
            "label": "Auferstehung",
            "id": "topics.0.1.0"
          },
          {
            "label": "Bergpredigt",
            "id": "topics.0.1.1"
          },
          {
            "label": "Der gute Hirte",
            "id": "topics.0.1.2"
          },
          {
            "label": "Endzeitreden",
            "id": "topics.0.1.3"
          },
          {
            "label": "Erfüllte Jesusprophetien",
            "id": "topics.0.1.4"
          },
          {
            "label": "Geburt Jesu",
            "id": "topics.0.1.5"
          },
          {
            "label": "Gleichnisse",
            "id": "topics.0.1.6"
          },
          {
            "label": "Ich-Bin Worte",
            "id": "topics.0.1.7"
          },
          {
            "label": "Ist Jesus Gott?",
            "id": "topics.0.1.8"
          },
          {
            "label": "Kreuzigung - Passion",
            "id": "topics.0.1.9"
          },
          {
            "label": "Messias",
            "id": "topics.0.1.10"
          },
          {
            "label": "Retter",
            "id": "topics.0.1.11"
          },
          {
            "label": "Vaterunser",
            "id": "topics.0.1.12"
          },
          {
            "label": "Wunder",
            "id": "topics.0.1.13"
          },
          {
            "label": "Zeichen",
            "id": "topics.0.1.14"
          }
        ]
      },
      {
        "label": "Personen im Alten Testament",
        "id": "topics.0.2",
        "topics": [
          {
            "label": "Abel",
            "id": "topics.0.2.0"
          },
          {
            "label": "Abija",
            "id": "topics.0.2.1"
          },
          {
            "label": "Abraham",
            "id": "topics.0.2.2"
          },
          {
            "label": "Adam",
            "id": "topics.0.2.3"
          },
          {
            "label": "Ahab",
            "id": "topics.0.2.4"
          },
          {
            "label": "Ahas",
            "id": "topics.0.2.5"
          },
          {
            "label": "Amazia",
            "id": "topics.0.2.6"
          },
          {
            "label": "Amos",
            "id": "topics.0.2.7"
          },
          {
            "label": "Asa",
            "id": "topics.0.2.8"
          },
          {
            "label": "Barsillai",
            "id": "topics.0.2.9"
          },
          {
            "label": "Bileam",
            "id": "topics.0.2.10"
          },
          {
            "label": "Daniel",
            "id": "topics.0.2.11"
          },
          {
            "label": "David",
            "id": "topics.0.2.12"
          },
          {
            "label": "Debora",
            "id": "topics.0.2.13"
          },
          {
            "label": "Elia",
            "id": "topics.0.2.14"
          },
          {
            "label": "Elisa",
            "id": "topics.0.2.15"
          },
          {
            "label": "Esau",
            "id": "topics.0.2.16"
          },
          {
            "label": "Esra",
            "id": "topics.0.2.17"
          },
          {
            "label": "Esther",
            "id": "topics.0.2.18"
          },
          {
            "label": "Eva",
            "id": "topics.0.2.19"
          },
          {
            "label": "Gideon",
            "id": "topics.0.2.20"
          },
          {
            "label": "Hanani",
            "id": "topics.0.2.21"
          },
          {
            "label": "Hannah, die Mutter Samuels",
            "id": "topics.0.2.22"
          },
          {
            "label": "Hesekiel",
            "id": "topics.0.2.23"
          },
          {
            "label": "Hiob",
            "id": "topics.0.2.24"
          },
          {
            "label": "Hiskia",
            "id": "topics.0.2.25"
          },
          {
            "label": "Isaak",
            "id": "topics.0.2.26"
          },
          {
            "label": "Jakob",
            "id": "topics.0.2.27"
          },
          {
            "label": "Jedida",
            "id": "topics.0.2.28"
          },
          {
            "label": "Jehu",
            "id": "topics.0.2.29"
          },
          {
            "label": "Jeremia",
            "id": "topics.0.2.30"
          },
          {
            "label": "Jerobeam",
            "id": "topics.0.2.31"
          },
          {
            "label": "Jesaja",
            "id": "topics.0.2.32"
          },
          {
            "label": "Joasch",
            "id": "topics.0.2.33"
          },
          {
            "label": "Jona",
            "id": "topics.0.2.34"
          },
          {
            "label": "Jonathan",
            "id": "topics.0.2.35"
          },
          {
            "label": "Josaphat",
            "id": "topics.0.2.36"
          },
          {
            "label": "Joseph (Sohn von Jakob)",
            "id": "topics.0.2.37"
          },
          {
            "label": "Josia",
            "id": "topics.0.2.38"
          },
          {
            "label": "Josua",
            "id": "topics.0.2.39"
          },
          {
            "label": "Juda",
            "id": "topics.0.2.40"
          },
          {
            "label": "Kain",
            "id": "topics.0.2.41"
          },
          {
            "label": "Königin von Saba",
            "id": "topics.0.2.42"
          },
          {
            "label": "Levi",
            "id": "topics.0.2.43"
          },
          {
            "label": "Manasse",
            "id": "topics.0.2.44"
          },
          {
            "label": "Melchisedek",
            "id": "topics.0.2.45"
          },
          {
            "label": "Micha",
            "id": "topics.0.2.46"
          },
          {
            "label": "Mose",
            "id": "topics.0.2.47"
          },
          {
            "label": "Nadab",
            "id": "topics.0.2.48"
          },
          {
            "label": "Nebukadnezar",
            "id": "topics.0.2.49"
          },
          {
            "label": "Nehemia",
            "id": "topics.0.2.50"
          },
          {
            "label": "Noah",
            "id": "topics.0.2.51"
          },
          {
            "label": "Obadja",
            "id": "topics.0.2.52"
          },
          {
            "label": "Rehabeam",
            "id": "topics.0.2.53"
          },
          {
            "label": "Ruth",
            "id": "topics.0.2.54"
          },
          {
            "label": "Salomo",
            "id": "topics.0.2.55"
          },
          {
            "label": "Samuel",
            "id": "topics.0.2.56"
          },
          {
            "label": "Sara",
            "id": "topics.0.2.57"
          },
          {
            "label": "Saul",
            "id": "topics.0.2.58"
          },
          {
            "label": "Simson",
            "id": "topics.0.2.59"
          },
          {
            "label": "Usija",
            "id": "topics.0.2.60"
          },
          {
            "label": "Zedekia",
            "id": "topics.0.2.61"
          }
        ]
      },
      {
        "label": "Personen im Neuen Testament",
        "id": "topics.0.3",
        "topics": [
          {
            "label": "Apollos",
            "id": "topics.0.3.0"
          },
          {
            "label": "Aquila und Priszilla",
            "id": "topics.0.3.1"
          },
          {
            "label": "Barnabas",
            "id": "topics.0.3.2"
          },
          {
            "label": "Der barmherzige Samariter",
            "id": "topics.0.3.3"
          },
          {
            "label": "Die Tochter des Jairus",
            "id": "topics.0.3.4"
          },
          {
            "label": "Hanna, die Prophetin im Tempel",
            "id": "topics.0.3.5"
          },
          {
            "label": "Jakobus, der Halbbruder Jesu",
            "id": "topics.0.3.6"
          },
          {
            "label": "Johannes der Täufer",
            "id": "topics.0.3.7"
          },
          {
            "label": "Johannes, der Apostel",
            "id": "topics.0.3.8"
          },
          {
            "label": "Joseph, der Ehemann von Maria",
            "id": "topics.0.3.9"
          },
          {
            "label": "Judas Iskariot",
            "id": "topics.0.3.10"
          },
          {
            "label": "Kämmerer aus Žthiopien",
            "id": "topics.0.3.11"
          },
          {
            "label": "Lydia",
            "id": "topics.0.3.12"
          },
          {
            "label": "Maria, die Mutter Jesu",
            "id": "topics.0.3.13"
          },
          {
            "label": "Markus, der Evangelist",
            "id": "topics.0.3.14"
          },
          {
            "label": "Matthäus, der Jünger Jesu",
            "id": "topics.0.3.15"
          },
          {
            "label": "Nathanael",
            "id": "topics.0.3.16"
          },
          {
            "label": "Paulus",
            "id": "topics.0.3.17"
          },
          {
            "label": "Petrus",
            "id": "topics.0.3.18"
          },
          {
            "label": "Philippus",
            "id": "topics.0.3.19"
          },
          {
            "label": "Pontius Pilatus",
            "id": "topics.0.3.20"
          },
          {
            "label": "Simon, der Zauberer",
            "id": "topics.0.3.21"
          },
          {
            "label": "Stephanus",
            "id": "topics.0.3.22"
          },
          {
            "label": "Thomas",
            "id": "topics.0.3.23"
          },
          {
            "label": "Timotheus",
            "id": "topics.0.3.24"
          },
          {
            "label": "Titus",
            "id": "topics.0.3.25"
          },
          {
            "label": "Zacharias",
            "id": "topics.0.3.26"
          }
        ]
      }
    ]
  },
  {
    "label": "Christliche Feste",
    "id": "topics.1",
    "topics": [
      {
        "label": "Biblische Feiertage",
        "id": "topics.1.0",
        "topics": [
          {
            "label": "Fest der Erstlingsgabe",
            "id": "topics.1.0.0"
          },
          {
            "label": "Fest der Trompeten",
            "id": "topics.1.0.1"
          },
          {
            "label": "Fest der ungesäuerten Brote",
            "id": "topics.1.0.2"
          },
          {
            "label": "Fest der Wochen (Pfingsten)",
            "id": "topics.1.0.3"
          },
          {
            "label": "Laubhüttenfest",
            "id": "topics.1.0.4"
          },
          {
            "label": "Passah",
            "id": "topics.1.0.5"
          },
          {
            "label": "Purim",
            "id": "topics.1.0.6"
          },
          {
            "label": "Versöhnungstag",
            "id": "topics.1.0.7"
          }
        ]
      },
      {
        "label": "Christliche Feiertage",
        "id": "topics.1.1",
        "topics": [
          {
            "label": "Advent",
            "id": "topics.1.1.0"
          },
          {
            "label": "Dreieinigkeitsfest",
            "id": "topics.1.1.1"
          },
          {
            "label": "Erntedankfest",
            "id": "topics.1.1.2"
          },
          {
            "label": "Himmelfahrt",
            "id": "topics.1.1.3"
          },
          {
            "label": "Karfreitag",
            "id": "topics.1.1.4"
          },
          {
            "label": "Ostern",
            "id": "topics.1.1.5"
          },
          {
            "label": "Pfingsten",
            "id": "topics.1.1.6"
          },
          {
            "label": "Reformationsfest",
            "id": "topics.1.1.7"
          },
          {
            "label": "Weihnachten",
            "id": "topics.1.1.8"
          }
        ]
      }
    ]
  },
  {
    "label": "Lehre und Theologie",
    "id": "topics.2",
    "topics": [
      {
        "label": "Leben im Glauben (Ethik)",
        "id": "topics.2.0",
        "topics": [
          {
            "label": "Abtreibung",
            "id": "topics.2.0.0"
          },
          {
            "label": "Diskriminierung und Rassismus",
            "id": "topics.2.0.1"
          },
          {
            "label": "Ehescheidung und Wiederheirat",
            "id": "topics.2.0.2"
          },
          {
            "label": "Ernährungsideologien",
            "id": "topics.2.0.3"
          },
          {
            "label": "Familienplanung",
            "id": "topics.2.0.4"
          },
          {
            "label": "Genderideologie",
            "id": "topics.2.0.5"
          },
          {
            "label": "Gentechnik",
            "id": "topics.2.0.6"
          },
          {
            "label": "Glück",
            "id": "topics.2.0.7"
          },
          {
            "label": "Kirche und Staat",
            "id": "topics.2.0.8"
          },
          {
            "label": "Krankheit und Medizin",
            "id": "topics.2.0.9"
          },
          {
            "label": "Krieg und Frieden",
            "id": "topics.2.0.10"
          },
          {
            "label": "Leben und Sterben",
            "id": "topics.2.0.11"
          },
          {
            "label": "Medienkompetenz und Digitalisierung",
            "id": "topics.2.0.12"
          },
          {
            "label": "Moralisches Handeln",
            "id": "topics.2.0.13"
          },
          {
            "label": "Natur und Technik",
            "id": "topics.2.0.14"
          },
          {
            "label": "Pornographie",
            "id": "topics.2.0.15"
          },
          {
            "label": "Psychologie",
            "id": "topics.2.0.16"
          },
          {
            "label": "Schwarzarbeit",
            "id": "topics.2.0.17"
          },
          {
            "label": "Selbstmord und Suizid",
            "id": "topics.2.0.18"
          },
          {
            "label": "Soziale Gerechtigkeit und Verantwortung",
            "id": "topics.2.0.19"
          },
          {
            "label": "Sucht",
            "id": "topics.2.0.20"
          },
          {
            "label": "Verbrechen und Bestrafung",
            "id": "topics.2.0.21"
          },
          {
            "label": "Wirtschaftliches Handeln",
            "id": "topics.2.0.22"
          },
          {
            "label": "Wissenschaft",
            "id": "topics.2.0.23"
          }
        ]
      },
      {
        "label": "Lehre des Glaubens (Dogmatik)",
        "id": "topics.2.1",
        "topics": [
          {
            "label": "Bibellehre (Bibliologie)",
            "id": "topics.2.1.0"
          },
          {
            "label": "Endzeitlehre (Eschatologie)",
            "id": "topics.2.1.1"
          },
          {
            "label": "Engelslehre (Angelologie)",
            "id": "topics.2.1.2"
          },
          {
            "label": "Gemeindelehre (Ekklesiologie)",
            "id": "topics.2.1.3"
          },
          {
            "label": "Gotteslehre (Eigentliche Theologie)",
            "id": "topics.2.1.4"
          },
          {
            "label": "Heilslehre (Soteriologie)",
            "id": "topics.2.1.5"
          },
          {
            "label": "Lehre des Menschen (Anthropologie)",
            "id": "topics.2.1.6"
          },
          {
            "label": "Lehre über Christus (Christologie)",
            "id": "topics.2.1.7"
          },
          {
            "label": "Lehre über den Heiligen Geist (Pneumatologie)",
            "id": "topics.2.1.8"
          },
          {
            "label": "Sündenlehre (Harmatologie)",
            "id": "topics.2.1.9"
          }
        ]
      },
      {
        "label": "Verteidigung des Glaubens (Apologetik)",
        "id": "topics.2.2",
        "topics": [
          {
            "label": "Apokryphen",
            "id": "topics.2.2.0"
          },
          {
            "label": "Archäologie",
            "id": "topics.2.2.1"
          },
          {
            "label": "Bibelkritik",
            "id": "topics.2.2.2"
          },
          {
            "label": "Evolution",
            "id": "topics.2.2.3"
          },
          {
            "label": "Frage des Leides (Theodizee)",
            "id": "topics.2.2.4"
          },
          {
            "label": "Gottesbeweise",
            "id": "topics.2.2.5"
          },
          {
            "label": "Historizität Jesu",
            "id": "topics.2.2.6"
          },
          {
            "label": "Kirchengeschichte",
            "id": "topics.2.2.7"
          },
          {
            "label": "Prophetie",
            "id": "topics.2.2.8"
          },
          {
            "label": "Schöpfung",
            "id": "topics.2.2.9"
          },
          {
            "label": "Schwierige Stellen in der Bibel",
            "id": "topics.2.2.10"
          },
          {
            "label": "Überlieferung der Bibel",
            "id": "topics.2.2.11"
          }
        ]
      }
    ]
  },
  {
    "label": "Nachfolge",
    "id": "topics.3",
    "topics": [
      {
        "label": "Leben in der Gemeinde",
        "id": "topics.3.0",
        "topics": [
          {
            "label": "Abendmahl",
            "id": "topics.3.0.0"
          },
          {
            "label": "Beerdigung",
            "id": "topics.3.0.1"
          },
          {
            "label": "Begabung",
            "id": "topics.3.0.2"
          },
          {
            "label": "Berufung",
            "id": "topics.3.0.3"
          },
          {
            "label": "Beziehungen",
            "id": "topics.3.0.4"
          },
          {
            "label": "Diakonie",
            "id": "topics.3.0.5"
          },
          {
            "label": "Dienst für Gott",
            "id": "topics.3.0.6"
          },
          {
            "label": "Einheit",
            "id": "topics.3.0.7"
          },
          {
            "label": "Ermahnung",
            "id": "topics.3.0.8"
          },
          {
            "label": "Ermutigung",
            "id": "topics.3.0.9"
          },
          {
            "label": "Erweckung",
            "id": "topics.3.0.10"
          },
          {
            "label": "Evangelisation",
            "id": "topics.3.0.11"
          },
          {
            "label": "Geben",
            "id": "topics.3.0.12"
          },
          {
            "label": "Gemeinde",
            "id": "topics.3.0.13"
          },
          {
            "label": "Gemeindebau",
            "id": "topics.3.0.14"
          },
          {
            "label": "Gemeindeleitung",
            "id": "topics.3.0.15"
          },
          {
            "label": "Gemeindewachstum",
            "id": "topics.3.0.16"
          },
          {
            "label": "Gemeinschaft",
            "id": "topics.3.0.17"
          },
          {
            "label": "Glaubensbekenntnis",
            "id": "topics.3.0.18"
          },
          {
            "label": "Kleingruppen",
            "id": "topics.3.0.19"
          },
          {
            "label": "Lehre",
            "id": "topics.3.0.20"
          },
          {
            "label": "Mission",
            "id": "topics.3.0.21"
          },
          {
            "label": "Musik in der Gemeinde",
            "id": "topics.3.0.22"
          },
          {
            "label": "Seelsorge",
            "id": "topics.3.0.23"
          },
          {
            "label": "Taufe",
            "id": "topics.3.0.24"
          },
          {
            "label": "Umkehr und Buße",
            "id": "topics.3.0.25"
          },
          {
            "label": "Verfolgung",
            "id": "topics.3.0.26"
          },
          {
            "label": "Verführung",
            "id": "topics.3.0.27"
          }
        ]
      },
      {
        "label": "Leben in Ehe und Familie",
        "id": "topics.3.1",
        "topics": [
          {
            "label": "Ehe",
            "id": "topics.3.1.0"
          },
          {
            "label": "Ehekonflikte",
            "id": "topics.3.1.1"
          },
          {
            "label": "Erziehung",
            "id": "topics.3.1.2"
          },
          {
            "label": "Familie",
            "id": "topics.3.1.3"
          },
          {
            "label": "Frausein",
            "id": "topics.3.1.4"
          },
          {
            "label": "Heiraten",
            "id": "topics.3.1.5"
          },
          {
            "label": "Kinder loslassen",
            "id": "topics.3.1.6"
          },
          {
            "label": "Kleinkinder",
            "id": "topics.3.1.7"
          },
          {
            "label": "Mannsein",
            "id": "topics.3.1.8"
          },
          {
            "label": "Sexualität",
            "id": "topics.3.1.9"
          },
          {
            "label": "Teenager",
            "id": "topics.3.1.10"
          },
          {
            "label": "Verlobung",
            "id": "topics.3.1.11"
          }
        ]
      },
      {
        "label": "Leben mit Jesus",
        "id": "topics.3.2",
        "topics": [
          {
            "label": "Anbetung/Lob",
            "id": "topics.3.2.0"
          },
          {
            "label": "Ausharren",
            "id": "topics.3.2.1"
          },
          {
            "label": "Bibellesen",
            "id": "topics.3.2.2"
          },
          {
            "label": "Dankbarkeit",
            "id": "topics.3.2.3"
          },
          {
            "label": "Ehrfurcht",
            "id": "topics.3.2.4"
          },
          {
            "label": "Entscheidungen treffen",
            "id": "topics.3.2.5"
          },
          {
            "label": "Fasten",
            "id": "topics.3.2.6"
          },
          {
            "label": "Frucht bringen",
            "id": "topics.3.2.7"
          },
          {
            "label": "Gebet",
            "id": "topics.3.2.8"
          },
          {
            "label": "Geborgenheit",
            "id": "topics.3.2.9"
          },
          {
            "label": "Gehorsam",
            "id": "topics.3.2.10"
          },
          {
            "label": "Glaubenskampf",
            "id": "topics.3.2.11"
          },
          {
            "label": "Gottes Willen erkennen",
            "id": "topics.3.2.12"
          },
          {
            "label": "Gotteskindschaft",
            "id": "topics.3.2.13"
          },
          {
            "label": "Heiligung",
            "id": "topics.3.2.14"
          },
          {
            "label": "Hingabe",
            "id": "topics.3.2.15"
          },
          {
            "label": "Identität in Christus",
            "id": "topics.3.2.16"
          },
          {
            "label": "Jesus ähnlicher werden",
            "id": "topics.3.2.17"
          },
          {
            "label": "Jüngerschaft",
            "id": "topics.3.2.18"
          },
          {
            "label": "Lebenszeugnisse",
            "id": "topics.3.2.19"
          },
          {
            "label": "Persönliche Evangelisation",
            "id": "topics.3.2.20"
          },
          {
            "label": "Selbstverleugnung",
            "id": "topics.3.2.21"
          },
          {
            "label": "Sieg und Niederlage",
            "id": "topics.3.2.22"
          },
          {
            "label": "Sinn des Lebens",
            "id": "topics.3.2.23"
          },
          {
            "label": "Vergebung",
            "id": "topics.3.2.24"
          },
          {
            "label": "Wachsamkeit",
            "id": "topics.3.2.25"
          }
        ]
      },
      {
        "label": "Leben mit Schwierigkeiten",
        "id": "topics.3.3",
        "topics": [
          {
            "label": "Alt werden",
            "id": "topics.3.3.0"
          },
          {
            "label": "Anfechtungen",
            "id": "topics.3.3.1"
          },
          {
            "label": "Angst",
            "id": "topics.3.3.2"
          },
          {
            "label": "Arbeitslosigkeit",
            "id": "topics.3.3.3"
          },
          {
            "label": "Burnout",
            "id": "topics.3.3.4"
          },
          {
            "label": "Depression",
            "id": "topics.3.3.5"
          },
          {
            "label": "Enttäuschung",
            "id": "topics.3.3.6"
          },
          {
            "label": "Faulheit",
            "id": "topics.3.3.7"
          },
          {
            "label": "Habgier",
            "id": "topics.3.3.8"
          },
          {
            "label": "Heilung",
            "id": "topics.3.3.9"
          },
          {
            "label": "Jähzorn",
            "id": "topics.3.3.10"
          },
          {
            "label": "Konflikte",
            "id": "topics.3.3.11"
          },
          {
            "label": "Kraftlosigkeit",
            "id": "topics.3.3.12"
          },
          {
            "label": "Krankheit",
            "id": "topics.3.3.13"
          },
          {
            "label": "Leid",
            "id": "topics.3.3.14"
          },
          {
            "label": "Schmerzen",
            "id": "topics.3.3.15"
          },
          {
            "label": "Schuld und Sünde",
            "id": "topics.3.3.16"
          },
          {
            "label": "Schwachheit",
            "id": "topics.3.3.17"
          },
          {
            "label": "Schwierigkeiten",
            "id": "topics.3.3.18"
          },
          {
            "label": "Selbstliebe (Narzissmus)",
            "id": "topics.3.3.19"
          },
          {
            "label": "Selbstmord (Suizid)",
            "id": "topics.3.3.20"
          },
          {
            "label": "Sexueller Missbrauch",
            "id": "topics.3.3.21"
          },
          {
            "label": "Sorgen",
            "id": "topics.3.3.22"
          },
          {
            "label": "Stress",
            "id": "topics.3.3.23"
          },
          {
            "label": "Sucht",
            "id": "topics.3.3.24"
          },
          {
            "label": "Tod und Sterben",
            "id": "topics.3.3.25"
          },
          {
            "label": "Trauer",
            "id": "topics.3.3.26"
          },
          {
            "label": "Unglaube",
            "id": "topics.3.3.27"
          },
          {
            "label": "Unsicherheit",
            "id": "topics.3.3.28"
          },
          {
            "label": "Versagen",
            "id": "topics.3.3.29"
          },
          {
            "label": "Versuchung",
            "id": "topics.3.3.30"
          },
          {
            "label": "Verzweiflung",
            "id": "topics.3.3.31"
          },
          {
            "label": "Zweifel",
            "id": "topics.3.3.32"
          }
        ]
      },
      {
        "label": "Leben mit Werten",
        "id": "topics.3.4",
        "topics": [
          {
            "label": "Arbeit",
            "id": "topics.3.4.0"
          },
          {
            "label": "Charakter",
            "id": "topics.3.4.1"
          },
          {
            "label": "Denken",
            "id": "topics.3.4.2"
          },
          {
            "label": "Erfolg und Misserfolg",
            "id": "topics.3.4.3"
          },
          {
            "label": "Essen",
            "id": "topics.3.4.4"
          },
          {
            "label": "Freundschaft",
            "id": "topics.3.4.5"
          },
          {
            "label": "Geld und Besitz",
            "id": "topics.3.4.6"
          },
          {
            "label": "Gott lieben",
            "id": "topics.3.4.7"
          },
          {
            "label": "Kleidung",
            "id": "topics.3.4.8"
          },
          {
            "label": "Musik",
            "id": "topics.3.4.9"
          },
          {
            "label": "Nächstenliebe",
            "id": "topics.3.4.10"
          },
          {
            "label": "Reichtum und Armut",
            "id": "topics.3.4.11"
          },
          {
            "label": "Ruhe",
            "id": "topics.3.4.12"
          },
          {
            "label": "Sport",
            "id": "topics.3.4.13"
          },
          {
            "label": "Toleranz und Kompromisse",
            "id": "topics.3.4.14"
          },
          {
            "label": "Urlaub und Freizeit",
            "id": "topics.3.4.15"
          },
          {
            "label": "Wahrheit und Lüge",
            "id": "topics.3.4.16"
          },
          {
            "label": "Werte",
            "id": "topics.3.4.17"
          }
        ]
      }
    ]
  },
  {
    "label": "Schulungen",
    "id": "topics.4",
    "topics": [
      {
        "label": "Schulung in Begleitung",
        "id": "topics.4.0",
        "topics": [
          {
            "label": "Arbeit mit Flüchtlingen",
            "id": "topics.4.0.0"
          },
          {
            "label": "Arbeit mit Jugendlichen",
            "id": "topics.4.0.1"
          },
          {
            "label": "Arbeit mit Kindern",
            "id": "topics.4.0.2"
          },
          {
            "label": "Arbeit mit Senioren",
            "id": "topics.4.0.3"
          },
          {
            "label": "Arbeit mit Sondergruppen",
            "id": "topics.4.0.4"
          },
          {
            "label": "Arbeit mit Teenager",
            "id": "topics.4.0.5"
          },
          {
            "label": "Jüngerschaft",
            "id": "topics.4.0.6"
          },
          {
            "label": "Mentoring",
            "id": "topics.4.0.7"
          },
          {
            "label": "Seelsorge",
            "id": "topics.4.0.8"
          }
        ]
      },
      {
        "label": "Schulung in Methoden",
        "id": "topics.4.1",
        "topics": [
          {
            "label": "Auslegung (Exegese)",
            "id": "topics.4.1.0"
          },
          {
            "label": "Erlebnispädagogik",
            "id": "topics.4.1.1"
          },
          {
            "label": "Lehren und Lernen (Didaktik)",
            "id": "topics.4.1.2"
          },
          {
            "label": "Mission und Verkündigung (Missiologie und Evangelistik)",
            "id": "topics.4.1.3"
          },
          {
            "label": "Predigtlehre (Homiletik)",
            "id": "topics.4.1.4"
          },
          {
            "label": "Schriftverständnis (Hermeneutik)",
            "id": "topics.4.1.5"
          },
          {
            "label": "Verteidigung des Glaubens (Apologetik)",
            "id": "topics.4.1.6"
          }
        ]
      }
    ]
  },
  {
    "label": "Strömungen",
    "id": "topics.5",
    "topics": [
      {
        "label": "Okkulte Praktiken",
        "id": "topics.5.0",
        "topics": [
          {
            "label": "Esoterik",
            "id": "topics.5.0.0"
          },
          {
            "label": "Magie",
            "id": "topics.5.0.1"
          },
          {
            "label": "Okkultismus",
            "id": "topics.5.0.2"
          },
          {
            "label": "Östlicher Mystizismus",
            "id": "topics.5.0.3"
          },
          {
            "label": "Yoga",
            "id": "topics.5.0.4"
          }
        ]
      },
      {
        "label": "Protestantische Glaubensgemeinschaften (Konfessionen)",
        "id": "topics.5.1",
        "topics": [
          {
            "label": "Adventisten",
            "id": "topics.5.1.0"
          },
          {
            "label": "Baptisten",
            "id": "topics.5.1.1"
          },
          {
            "label": "Charismatiker",
            "id": "topics.5.1.2"
          },
          {
            "label": "Freikirchler",
            "id": "topics.5.1.3"
          },
          {
            "label": "Lutheraner",
            "id": "topics.5.1.4"
          },
          {
            "label": "Pfingstkirchler",
            "id": "topics.5.1.5"
          },
          {
            "label": "Pietisten",
            "id": "topics.5.1.6"
          }
        ]
      },
      {
        "label": "Religionen",
        "id": "topics.5.2",
        "topics": [
          {
            "label": "Atheismus",
            "id": "topics.5.2.0"
          },
          {
            "label": "Buddhismus",
            "id": "topics.5.2.1"
          },
          {
            "label": "Christentum",
            "id": "topics.5.2.2"
          },
          {
            "label": "Hinduismus",
            "id": "topics.5.2.3"
          },
          {
            "label": "Islam",
            "id": "topics.5.2.4"
          },
          {
            "label": "Judentum",
            "id": "topics.5.2.5"
          },
          {
            "label": "Religionen allgemein",
            "id": "topics.5.2.6"
          },
          {
            "label": "Weltanschauung",
            "id": "topics.5.2.7"
          }
        ]
      },
      {
        "label": "Sekten",
        "id": "topics.5.3",
        "topics": [
          {
            "label": "Andere Sekten",
            "id": "topics.5.3.0"
          },
          {
            "label": "Freimaurertum",
            "id": "topics.5.3.1"
          },
          {
            "label": "Katholizismus",
            "id": "topics.5.3.2"
          },
          {
            "label": "Mormonen",
            "id": "topics.5.3.3"
          },
          {
            "label": "Neuapostolische Kirche",
            "id": "topics.5.3.4"
          },
          {
            "label": "Scientology",
            "id": "topics.5.3.5"
          },
          {
            "label": "Zeitströmungen allgemein",
            "id": "topics.5.3.6"
          },
          {
            "label": "Zeugen Jehovas",
            "id": "topics.5.3.7"
          }
        ]
      },
      {
        "label": "Theologische Strömungen",
        "id": "topics.5.4",
        "topics": [
          {
            "label": "Arminianismus",
            "id": "topics.5.4.0"
          },
          {
            "label": "Bundestheologie",
            "id": "topics.5.4.1"
          },
          {
            "label": "Calvinismus",
            "id": "topics.5.4.2"
          },
          {
            "label": "Dispensationalismus",
            "id": "topics.5.4.3"
          }
        ]
      }
    ]
  },
  {
    "label": "Zielgruppen",
    "id": "topics.6",
    "topics": [
      {
        "label": "Alter",
        "id": "topics.6.0",
        "topics": [
          {
            "label": "Jugend",
            "id": "topics.6.0.0"
          },
          {
            "label": "Kinder",
            "id": "topics.6.0.1"
          },
          {
            "label": "Senioren",
            "id": "topics.6.0.2"
          }
        ]
      },
      {
        "label": "Familienstand",
        "id": "topics.6.1",
        "topics": [
          {
            "label": "Familie",
            "id": "topics.6.1.0"
          },
          {
            "label": "Singles",
            "id": "topics.6.1.1"
          },
          {
            "label": "Verheiratete",
            "id": "topics.6.1.2"
          },
          {
            "label": "Verwitwete",
            "id": "topics.6.1.3"
          }
        ]
      },
      {
        "label": "Geschlecht",
        "id": "topics.6.2",
        "topics": [
          {
            "label": "Frau",
            "id": "topics.6.2.0"
          },
          {
            "label": "Mann",
            "id": "topics.6.2.1"
          }
        ]
      }
    ]
  }
];

@Injectable({
  providedIn: 'root'
})
export class MediaTopicsService {

  filter: Topic = { id: 'topics', label: 'groups.topics.label', topics: this.getTopics() };
  bibleBooksFilter: Topic = { id: 'bible', label: 'bible.books.title', topics: this.getBibleBooks() };
  flatTopicMap = new Map<string, Topic>();
  flatBibleBooksMap = new Map<string, Topic>();

  constructor() {
    const flatten = (topics: Topic[], map: Map<string, Topic>) => {
      topics.forEach(topic => {
        map.set(topic.id, topic);
        if (topic.topics) {
          flatten(topic.topics, map);
        }
      });
    };

    // const updateId = (parentId: string, topics: Topic[]) => {
    //   let index = 0;
    //   topics.forEach(topic => {
    //     topic.id = parentId + '.' + index++;
    //     this.flatTopicMap.set(topic.id, topic);
    //     if (topic.topics) {
    //       updateId(topic.id, topic.topics);
    //     }
    //   });
    // };

    // updateId('bible', BIBLEBOOKS);

    flatten(TOPICS, this.flatTopicMap);
    flatten(BIBLEBOOKS, this.flatBibleBooksMap);
  }

  getFilter(): Topic {
    return this.filter;
  }

  getBibleBooksFilter(): Topic {
    return this.bibleBooksFilter;
  }

  getTopics(): Topic[] {
    return TOPICS;
  }

  getBibleBooks(): Topic[] {
    return BIBLEBOOKS;
  }

  getFlatTopicMap(): Map<string, Topic> {
    return this.flatTopicMap;
  }

  getFlatBibleBooksMap(): Map<string, Topic> {
    return this.flatBibleBooksMap;
  }

}
