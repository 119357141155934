export interface BadgeBasic {
    color?: string;
}
interface BadgeIcon extends BadgeBasic {
    icon: string;
}
interface BadgeLabel extends BadgeBasic {
    label: string;
}
export type Badge = BadgeIcon | BadgeLabel;
export type BadgeType = 'icon' | 'text';
export function badgeType(badge: Badge): BadgeType {
    if ('icon' in badge) {
        return 'icon';
    } else {
        return 'text';
    }
}
