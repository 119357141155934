// https://github.com/ng-packagr/ng-packagr/issues/641
// @dynamic
export class CRC16 {

  static fromString(value:string) : number {
    return CRC16.fromCharCodes(Array.from(value,c=>c.charCodeAt(0)));
  }

  static fromCharCodes(bytes:number[]) : number {
    let value = 0;
    let mask  = '耀'.charCodeAt(0);
    for (let byte of bytes) {
      let b1 = byte;
      for (let i=7; i>=0; i--) {
        b1 <<= 1;
        let b2 = b1 >>> 8 & 1;
        if ((value & mask) != 0) {
          value = (value << 1) + b2 ^ 4129;
        } else {
          value = (value << 1) + b2;
        }
      }
    }
    return value & 0x0ffff;
  }
}
