import {Challenge} from "./challenge";
import {PresenceState} from "presence";
// import {LoadRemoteModuleOptions} from "@angular-architects/module-federation";
import {Message} from "messaging";

export interface Presence {
  contactId: string;
  state: PresenceState;
  timeSince: number;
  timeActive: number;           // time last active (comes from session update)....
  timeDevice?: number;          // time last active on device
  timeBrowser?: number;         // time last active on browser
  devices?:number;              // number of logged in devices
  pushDevices?:number;          // number of logged in devices with push notification
  sessions?:number;             // number of total non expired sessions (browser and devices)
}

/*
export type RemoteModule = LoadRemoteModuleOptions & {
  displayName: string;
  routePath: string;
  ngModuleName: string;
  remoteName: string
};

 */

export interface Topic {
  id: string;
  type?: string;      // used for custom filter domains... undefined is standard tagging filter type...
  label?: string;
  abbreviation?: string;
  help?: string;
  filter?: string;
  icon?: string;
  inherited?: boolean;
  editable?: boolean;
  visible?: boolean;
  enabled?: boolean;
  preselected?: boolean;
  topics?: Topic[];
  defaults?: string[];
  tags?: string[];
  properties?: {[key: string]: any},
  remoteModule?: any; //RemoteModule;
  parent?:Topic;  // just used on client side
}

export interface Action {
  id: string;
  type?: 'internal' | 'external' | 'redirect' | 'separator';
  icon?: string;       // material icon
  label?: string;      // translatable label key
  parameter?: string;  // depends on action type. for direct links (i.e. internal and external) holds target hyperlink reference (possibly parametrized) e.g. https://domain.com/{{language}}
  context?: string;    // context in which the action should be performed i.e. _self, _blank, _parent, _top
}

export interface DomainAccess {
  label?: string;
  abbreviation?: string;
  domain_id?: string;
  // contact: own or inherited contact.
  // if contact_id equals to id of the contact to which the domain access is associated
  // then this domain access has been assigned directly to the contact
  contact_id?: string;
  contact_name?: string;
  admin_id?: string;  // admin: who has assigned this domain to the current user
  admin_name?: string;
  // role:
  //  none   - domain is blocked for whole downline
  //  user   - cannot assign this domain to anyone else. cannot approve media. can see and tag media
  //  leader - can assign this domain to other users and approve media for this domain
  role?: string;
  time?: number;  // creation time
  accepted?: number | undefined;
  declined?: number | undefined;
  // next 3 fields only for own domain accesses
  visible?: boolean;
  taggable?: boolean;
  assignable?: boolean;
}

export interface PrivacySelector {
  id:string;
  icon?:string;
  label:string;             // shown inline and in menu
  shortcut?:string;         // shown inline, but label in menu...
  default?:boolean;         // default selector
  defaultFields?:string[];  // fields for which this selector is default. overrides default boolean
  params?:{};
}

/*
export interface AppAccess {
  platform ?: string;
  model ?: string;
  manufacturer ?: string;
  version ?: string;
  agent ?: string;
  accessed ?: number;
  devices ?: number;
  sessions ?: number;
  warning ?: boolean;
}*/

export interface App {
  id: number;
  name: string;
  storeVersion?: string; // only provided for native/hybrid apps. browser sessions do not have associated platform/store
  tags: string[];
  stories: Story[];
  properties: any;
  privacy?: { [key:string]: string };
  privacySelectors: PrivacySelector[];
  /* #REMOVED */
  challenges?: { [key:string]: Challenge };
  //access?: { [key:string]: AppAccess }; // 'app','web'
  presence?: Presence,
  domains?: { [key:string] : DomainAccess };
  topics?: Topic[];
  filters?: Topic[];
  interests?: Topic[];
  actions?: Action[];
  refunds?: any;
}


export interface AppRelatedMessage extends Message {
}

export const AppUpdateMessageType: string = "appUpdate";
export interface AppUpdateMessage extends AppRelatedMessage {
  version: string;
}

export type Story = { type: string, html: string }
