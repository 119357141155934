import {Author} from "./author";
import {Step} from "./step";
import {UserInfo} from "./user-info";
import {Injectable, InjectionToken} from "@angular/core";

export interface Task {
  _id: string,
  id : string;
  type: string;
  group_id?: string;
  author: Author;
  responsible: UserInfo[]
  primary_executor_id?: string;
  primary_executor_name?: string;
  properties?: any;  // {played: "[{"start":0,"end":79},{"start":86,"end":205}]"}
  session_ids?: {[key: string]: number};
  time?: number;
  team?: boolean;
  created_time: number;
  updated_time?: number;

  phone: string;
  calling_code: string;
  country_code: string;
  email: string;
  resource_id: string;
  tags?: any[] | null;

  message: string;
  message_id: string;
  permalink_url: string;
  post_id: string;
  steps: Step[];

  webinar_id: string;
  webinar_title: string;
  played_time: number;

  campaign_id: string;
  resource_name: string;

  resource_selector: string;
  touched: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NullTask implements Task {
  _id = '';
  id = '';
  type  = 'task';
  group_id =  '';
  author = { id: '', name: '' } as Author;
  responsible =  [] as UserInfo[];
  primary_executor_id = '';
  primary_executor_name = '';
  properties = {};
  session_ids = {};
  time = 0;
  team = false;
  created_time = 0;
  updated_time = 0;

  phone = '';
  calling_code = '';
  country_code = '';
  email = '';
  resource_id = '';
  tags = [] as any[];

  message = '';
  message_id = '';
  permalink_url = '';
  post_id = '';
  steps = [] as Step[];

  webinar_id = '';
  webinar_title = '';
  played_time =0;

  campaign_id ='';
  resource_name ='';

  resource_selector = '';
  touched = false;
}

export const nullTask = new InjectionToken('NullTask');

/*
export interface ContactTask extends Task {
  type: 'contact' | 'webinar' | 'join';
  phone: string;
  calling_code: string;
  country_code: string;
  email: string;
  resource_id: string;
  session_ids: {[key: string]: number},
  tags?: any[] | null;
}

export interface JoinTask extends ContactTask {
  type: 'join' ;
}

export interface CommentTask extends Task {
  type: 'comment';
  message: string;
  message_id: string;
  permalink_url: string;
  post_id: string;
  steps: Step[];
}

export interface TrainingTask extends Task {
  type: 'training';
}

export interface WebinarTask extends ContactTask {
  type: 'webinar';
  webinar_id: string;
  webinar_title: string;
  played_time: number;
  steps: Step[];
}
*/
