/* projects/media/src/lib/components/media-viewer/containers/media-viewer/media-viewer.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: transparent;
}
video-viewer,
image-viewer,
pdf-viewer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.toolbar {
  position: absolute;
  padding: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  min-width: 180px;
  border-bottom-left-radius: 8px;
  background-color: transparent;
}
.toolbar .actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 8px;
}
.toolbar .actions .action > *:not(first-child) {
  margin-left: 16px;
}
.toolbar .actions button.mat-mdc-icon-button {
  width: 38px;
  height: 38px;
  padding: 3px;
  color: var(--color-primaryContrast-500);
}
.toolbar .actions button.mat-mdc-icon-button mat-icon {
  position: relative;
  font-size: 32px;
  line-height: normal;
  width: 100%;
  height: 100%;
}
.toolbar .actions button.mat-mdc-icon-button mat-icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
  border-radius: 50%;
  box-shadow: 0 0 3px black;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -1;
}
.actions-container.hide_action_background {
  transition: background-color 1s ease 3s;
}
.actions-container.hide_action_background.blur {
  background-color: rgba(43, 51, 63, 0.9);
  transition: none;
}
@supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
  .actions-container.hide_action_background {
    background-color: transparent;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    transition: backdrop-filter 1s ease 3s;
  }
  .actions-container.hide_action_background.blur {
    background-color: transparent;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    transition: none;
  }
}
@media only screen and (orientation: portrait) {
  .actions-container {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }
}
/*# sourceMappingURL=media-viewer.component-TJITHMZP.css.map */
