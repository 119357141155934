import {ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from 'material';
import {RouterModule} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";
import {AutoFocusDirective} from "./directives/focus/auto-focus.directive";
import {CleanInputDirective} from "./directives/input/clean-input.directive";
import {FormsModule} from "@angular/forms";
import {PipesModule} from "./pipes/pipes.module";
import {DomDirective} from "./directives/dom/dom.directive";
import {HoverDirective} from "./directives/hover/hover.directive";
import {ResizeDirective} from "./directives/resize/resize.directive";
import {ResizeService} from "./directives/resize/resize.service";
import {NgLetDirective} from "./directives/var/ng-let.directive";
import {PressDirective} from "./directives/press/press.directive";
import {PressHoldDirective} from "./directives/press/press-hold.directive";
import {DragDirective} from "./directives/drag/drag.directive";
import {DropDirective} from "./directives/drag/drop.directive";
import {InputFocusDirective} from "./directives/focus/input-focus.directive";
import {ScrollToDirective} from "./directives/scroll/scrollto.directive";
import {
  VirtualScrollerScrollToAddedDirective
} from "./components/virtual-scroller/virtual-scroller.scrollToAdded.directive";
import {ACTION_HANDLER, ActionDirective} from "./directives/action/action.directive";
import {ImageDirective} from "./directives/image/image.directive";
import {UnmodifiedInputDirective} from "./directives/input/unmodified.directive";
import {UncorrectedInputDirective} from "./directives/input/uncorrected.directive";
import {VirtualForDirective} from "./directives/for/for.directive";
import {DomModifiedDirective} from "./directives/dom/dom.modified.directive";
import {ManualMenuTrigger} from "./directives/menu/manual-menu-trigger";
import {BusyComponent} from './components/busy/busy.component';
import {LanguageButtonComponent} from './components/language/language-button/language-button.component';
import {LanguageListComponent} from './components/language/language-list/language-list.component';
import {LanguageLinkComponent} from './components/language/language-link/language-link.component';
import {LanguageSelectorComponent} from './components/language/language-selector/language-selector.component';
import {VersionComponent} from './components/version/version.component';
import {BasicComponent} from "./components/basic-container/basic.component";
import {BasicContainerComponent} from './components/basic-container/basic-container.component';
import {
  BasicResizingContainerComponent
} from './components/basic-resizing-container/basic-resizing-container.component';
import {BasicMainContainerComponent} from './components/basic-main-container/basic-main-container.component';
import {CardContainerComponent} from './components/card-container/card-container.component';
import {StatusBarComponent} from './components/status-bar/status-bar.component';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import {PartnerIdComponent} from "./components/partner-id/partner-id.component";
import {MemberIdComponent} from "./components/member-id/member-id.component";
import {FilterCarouselComponent} from './components/filter-carousel/filter-carousel.component';
import {ThumbNavigationComponent} from './components/thumb-navigation/thumb-navigation.component';
import {GripComponent} from './components/thumb-navigation/grip.component';
import {ThumbStatusLeftComponent} from './components/thumb-navigation/thumb-status-left.component';
import {ThumbStatusRightComponent} from './components/thumb-navigation/thumb-status-right.component';
import {TabBarComponent} from './components/tab-bar/tab-bar.component';
import {TriStateCheckboxComponent} from './components/tri-state-checkbox/tri-state-checkbox.component';
import {RatingSummaryComponent} from './components/rating/rating-summary/rating-summary.component';
import {RatingStarsComponent} from './components/rating/rating-stars/rating-stars.component';
import {ConfirmDialogComponent} from "./components/confirm/confirm";
import {PrivacySelectorComponent} from "./components/selectors/privacy-selector/privacy-selector.component";
import {MenuSelectorComponent} from "./components/selectors/menu-selector/menu-selector.component";
import {ActionSelectorComponent} from "./components/selectors/action-selector/action-selector.component";
import {MessageBoxComponent} from "./components/dialogs/message-box/message-box.component";
import {MenuComponent} from "./components/menu/menu.component";
import {ToolbarComponent} from "./components/toolbar/toolbar.component";
import {BasePageComponent} from "./components/base-page/base-page.component";
import {VirtualScrollerComponent} from "./components/virtual-scroller/virtual-scroller";
import {VirtualSectionScrollerComponent} from "./components/virtual-scroller/virtual-section-scroller";
import {VirtualScrollbarComponent} from "./components/virtual-scroller/virtual-scroll.bar";
import {VirtualGridComponent} from "./components/virtual-scroller/virtual-grid";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {LayoutModule} from "layout";
import {
  NavigationStatusBarComponent
} from './components/status-bar/navigation-status-bar/navigation-status-bar.component';
import {FormChangeDetector} from "./services/form-change-detector";
import {NumberOnlyDirective} from "./directives/input/number-only.directive";
import {CountrySelectComponent} from "./components/country-select/country-select.component";
import {MultiSelectComponent} from "./components/multi-select/multi-select.component";
import {PortalModule} from "@angular/cdk/portal";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import {
  SlidePanelDirective,
  SlidePanelLeftDirective,
  SlidePanelLeftLazyDirective,
  SlidePanelRightDirective,
  SlidePanelRightLazyDirective
} from "./components/slide-panel/slide-panel.directive";
import {SlidePanelContainerComponent} from "./components/slide-panel/slide-panel-container.component";
import {SlidePanelComponent} from "./components/slide-panel-deprecated/slide-panel.component";
import {TimezoneSelectorComponent} from './components/timezone/timezone-selector/timezone-selector.component';
import {AdjustableContainerComponent} from './components/adjustable-container/adjustable-container.component';
import {NumberInputComponent} from './components/number-input/number-input.component';
import {TimezoneListComponent} from './components/timezone/timezone-list/timezone-list.component';
import {EmojiPicker} from "./components/emoji-picker/emoji-picker.component";
import {TestComponent} from "./components/test/test.component";
import {AccordionContainerComponent} from './components/accordion-container/accordion-container.component';
import {
  AccordionSectionContainerComponent
} from './components/accordion-section-container/accordion-section-container.component';
import {LegalInfoComponent} from './components/legal-info/legal-info.component';
import {LegalInfoBottomSheetComponent} from './components/legal-info/legal-info-bottom-sheet.component';
import {TopicSelectionListComponent} from './components/topic/topic-selection-list/topic-selection-list.component';
import {BadgeContainerComponent} from "./components/badge/badge.container.component";
import {MoreTextComponent} from "./components/more/more.text.component";
import {SessionModule} from "session";
import {IconListComponent} from "./components/icon-list/icon-list.component";
import {ClickOutsideDirective} from "./directives/click/click-outside.directive";
import {QRScanner} from "@ionic-native/qr-scanner/ngx";

@NgModule({
  imports: [
    CommonModule,
    // CoreModule,
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    PipesModule,
    ScrollingModule,
    LayoutModule,
    PortalModule,
    PickerModule,
    SessionModule
  ],
  declarations: [
    AutoFocusDirective,
    CleanInputDirective,
    DomDirective,
    DomModifiedDirective,
    HoverDirective,
    ResizeDirective,
    PressDirective,
    PressHoldDirective,
    NgLetDirective,
    DragDirective,
    DropDirective,
    UnmodifiedInputDirective,
    UncorrectedInputDirective,
    NumberOnlyDirective,
    InputFocusDirective,
    ScrollToDirective,
    VirtualScrollerScrollToAddedDirective,
    ActionDirective,
    ImageDirective,
    ManualMenuTrigger,
    BusyComponent,
    LanguageButtonComponent,
    LanguageLinkComponent,
    LanguageListComponent,
    LanguageSelectorComponent,
    VersionComponent,
    BasicComponent,
    BasicContainerComponent,
    BasicResizingContainerComponent,
    BasicMainContainerComponent,
    CardContainerComponent,
    StatusBarComponent,
    NavigationStatusBarComponent,
    SearchBarComponent,
    PartnerIdComponent,
    MemberIdComponent,
    FilterCarouselComponent,
    ThumbStatusLeftComponent,
    ThumbStatusRightComponent,
    ThumbNavigationComponent,
    GripComponent,
    TabBarComponent,
    TriStateCheckboxComponent,
    RatingSummaryComponent,
    RatingStarsComponent,
    ConfirmDialogComponent,
    PrivacySelectorComponent,
    MenuSelectorComponent,
    ActionSelectorComponent,
    MessageBoxComponent,
    MenuComponent,
    ToolbarComponent,
    BasePageComponent,
    VirtualForDirective,
    VirtualScrollerComponent,
    VirtualSectionScrollerComponent,
    VirtualScrollbarComponent,
    VirtualGridComponent,
    SlidePanelComponent,
    SlidePanelContainerComponent,
    SlidePanelDirective,
    SlidePanelLeftDirective,
    SlidePanelLeftLazyDirective,
    SlidePanelRightDirective,
    SlidePanelRightLazyDirective,
    ClickOutsideDirective,
    MultiSelectComponent,
    CountrySelectComponent,
    TimezoneSelectorComponent,
    AdjustableContainerComponent,
    NumberInputComponent,
    TimezoneListComponent,
    EmojiPicker,
    TestComponent,
    AccordionContainerComponent,
    AccordionSectionContainerComponent,
    LegalInfoComponent,
    LegalInfoBottomSheetComponent,
    TopicSelectionListComponent,
    BadgeContainerComponent,
    MoreTextComponent,
    IconListComponent
  ],
  exports: [
    AutoFocusDirective,
    DomDirective,
    DomModifiedDirective,
    HoverDirective,
    ResizeDirective,
    PressDirective,
    PressHoldDirective,
    NgLetDirective,
    CleanInputDirective,
    DragDirective,
    DropDirective,
    UnmodifiedInputDirective,
    UncorrectedInputDirective,
    NumberOnlyDirective,
    InputFocusDirective,
    ScrollToDirective,
    VirtualScrollerScrollToAddedDirective,
    ActionDirective,
    ImageDirective,
    ManualMenuTrigger,
    BusyComponent,
    CardContainerComponent,
    LanguageButtonComponent,
    LanguageLinkComponent,
    LanguageListComponent,
    LanguageSelectorComponent,
    VersionComponent,
    BasicComponent,
    BasicContainerComponent,
    BasicResizingContainerComponent,
    BasicMainContainerComponent,
    StatusBarComponent,
    NavigationStatusBarComponent,
    SearchBarComponent,
    PartnerIdComponent,
    MemberIdComponent,
    PipesModule,
    FilterCarouselComponent,
    ThumbStatusLeftComponent,
    ThumbStatusRightComponent,
    ThumbNavigationComponent,
    GripComponent,
    TabBarComponent,
    TriStateCheckboxComponent,
    RatingSummaryComponent,
    RatingStarsComponent,
    ConfirmDialogComponent,
    PrivacySelectorComponent,
    MenuSelectorComponent,
    ActionSelectorComponent,
    MessageBoxComponent,
    MenuComponent,
    ToolbarComponent,
    BasePageComponent,
    VirtualForDirective,
    VirtualScrollerComponent,
    VirtualSectionScrollerComponent,
    VirtualScrollbarComponent,
    VirtualGridComponent,
    SlidePanelComponent,
    SlidePanelContainerComponent,
    SlidePanelDirective,
    SlidePanelLeftDirective,
    SlidePanelLeftLazyDirective,
    SlidePanelRightDirective,
    SlidePanelRightLazyDirective,
    ClickOutsideDirective,
    MultiSelectComponent,
    CountrySelectComponent,
    TimezoneSelectorComponent,
    AdjustableContainerComponent,
    NumberInputComponent,
    EmojiPicker,
    TestComponent,
    AccordionContainerComponent,
    LegalInfoComponent,
    LegalInfoBottomSheetComponent,
    TopicSelectionListComponent,
    BadgeContainerComponent,
    MoreTextComponent
  ]/*
  entryComponents: [
    BusyComponent,
    ConfirmDialogComponent,
    MessageBoxComponent,
    NumberInputComponent,
    EmojiPicker,
    LegalInfoBottomSheetComponent
  ]
  */,
  providers: [
    ResizeService,
    FormChangeDetector,
    QRScanner,
    // { provide: ActionDirective, useClass: ActionDirective },
    { provide: ACTION_HANDLER,  useClass: ActionDirective }
    // { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
  ]
})
export class SharedModule {
  static forRoot(menuServiceProvider: Provider): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [ menuServiceProvider]
    };
  }
}
