import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, cordovaPropertyGet, cordovaPropertySet, cordova } from '@ionic-native/core';
import { Observable } from 'rxjs';
var ScreenOrientation = /** @class */function (_super) {
  __extends(ScreenOrientation, _super);
  function ScreenOrientation() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    /**
     * Convenience enum for possible orientations
     */
    _this.ORIENTATIONS = {
      PORTRAIT_PRIMARY: 'portrait-primary',
      PORTRAIT_SECONDARY: 'portrait-secondary',
      LANDSCAPE_PRIMARY: 'landscape-primary',
      LANDSCAPE_SECONDARY: 'landscape-secondary',
      PORTRAIT: 'portrait',
      LANDSCAPE: 'landscape',
      ANY: 'any'
    };
    return _this;
  }
  ScreenOrientation.prototype.onChange = function () {
    return cordova(this, "onChange", {
      "eventObservable": true,
      "event": "orientationchange",
      "element": "window"
    }, arguments);
  };
  ScreenOrientation.prototype.lock = function (orientation) {
    return cordova(this, "lock", {
      "otherPromise": true
    }, arguments);
  };
  ScreenOrientation.prototype.unlock = function () {
    return cordova(this, "unlock", {
      "sync": true
    }, arguments);
  };
  Object.defineProperty(ScreenOrientation.prototype, "type", {
    get: function () {
      return cordovaPropertyGet(this, "type");
    },
    set: function (value) {
      cordovaPropertySet(this, "type", value);
    },
    enumerable: false,
    configurable: true
  });
  ScreenOrientation.pluginName = "ScreenOrientation";
  ScreenOrientation.plugin = "cordova-plugin-screen-orientation";
  ScreenOrientation.pluginRef = "screen.orientation";
  ScreenOrientation.repo = "https://github.com/apache/cordova-plugin-screen-orientation";
  ScreenOrientation.platforms = ["Android", "iOS", "Windows"];
  ScreenOrientation.decorators = [{
    type: Injectable
  }];
  return ScreenOrientation;
}(IonicNativePlugin);
export { ScreenOrientation };
