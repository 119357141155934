import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";

import {Platform} from "core";
import {SessionTokenService} from "./session-token.service";

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  constructor(private sessionTokenService: SessionTokenService, //private deviceService: DeviceService,
              private platform: Platform) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('SessionInterceptor.intercept!');
    if (this.sessionTokenService.shouldRewrite) {
      let clone = request.clone({
        'params': request.params.append('token', this.sessionTokenService.token) // 'params': request.params.append('deviceId', encodeURIComponent(device.id))
      });
      return next.handle(clone);
    } else {
      return next.handle(request);
    }
    // return forkJoin([
    //     this.deviceService.device(),
    //     this.platform.ready()
    // ]).pipe(
    //   mergeMap(([device, readySource]) => {
    //     if (this.sessionTokenService.shouldRewrite) {
    //       let clone = request.clone({
    //         'params': request.params.append('deviceId', encodeURIComponent(device.id))
    //       });
    //       return next.handle(clone);
    //     }
    //     return next.handle(request);
    //   })
    // );
  }

  /*
  base64Device: string;

  constructor(private device: Device,
              private platform: Platform) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.platform.ready())
          .pipe(
            mergeMap(() => {
              if (this.platform.is('ios')) {
                if (!this.base64Device) {
                  this.base64Device = this.b64EncodeUnicode(JSON.stringify(this.device));
                }
                let clone = request.clone();
                clone.params.append('device', this.base64Device);
                return next.handle(clone);
              }
              return next.handle(request);
            })
          );
  }

  // 'Unicode Problem' solution for btoa (Character Out Of Range) adapted from
  // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
  b64EncodeUnicode(str: string) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode(parseInt('0x' + p1));
      }));
  }

  b64DecodeUnicode(str: string) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
  */
}
