import {AfterViewInit, Directive, ElementRef} from "@angular/core";

/**
 * add scrollto support to component
 */
@Directive({
  selector: '[scrollTo]'
})
export class ScrollToDirective implements AfterViewInit {
  constructor(private elementRef:ElementRef) {}
  ngAfterViewInit() {
    this.elementRef.nativeElement.scrollIntoView({
      behavior:'smooth',
      block:'center'
    });
  }
}
