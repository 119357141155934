/* projects/media/src/lib/components/media-card/media-card.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: block;
  background-color: transparent;
}
:host.elevated {
  padding: 0.5em;
}
:host.elevated > .media-container .media {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
:host.elevated > .media-container .media:hover {
  box-shadow:
    0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
:host.rounded > .media-container {
  border-radius: 0.35em;
}
:host.rounded > .media-container .media {
  border-radius: 0.3em;
  transform: translate3d(0, 0, 0);
}
:host.rounded > .media-container .media .cover-container .cover {
  border-radius: 0.3em;
  transform: translate3d(0, 0, 0);
}
:host.selectable > .media-container {
  border: 2px solid transparent;
}
:host.selected > .media-container {
  border-color: var(--color-primary);
}
:host.invisible .media > .cover-container > div > img {
  opacity: 0.4 !important;
}
:host img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
.media-container .media {
  position: relative;
}
.media-container .media.Xfolder {
  padding: 15px;
}
.media-container .media.Xfolder:before {
  content: "";
  position: absolute;
  left: 10px;
  top: 10px;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.1882352941);
  border-radius: 10px;
  border-top-left-radius: unset;
}
.media-container .media.Xfolder:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 7px;
  width: 30px;
  height: 3px;
  background-color: rgba(0, 0, 0, 0.1882352941);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.media-container .media .cover-container {
  position: relative;
}
.media-container .media .cover-container .cover {
  overflow: hidden;
}
.media-container .media .cover-container .cover.primaryAction {
  cursor: pointer;
}
.media-container .media .cover-container .cover > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: relative;
  display: block;
  opacity: 0;
}
.media-container .media .cover-container .cover > img:not(.transitioned) {
  transition: opacity 300ms;
}
.media-container .media .cover-container .cover > img.transitioned {
  opacity: 1;
}
.media-container .media .cover-container .cover > div:first-of-type {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-container .media .cover-container .cover:not(.default) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.media-container .media .cover-container .cover:not(.default) > img {
  object-fit: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.media-container .media .cover-container .control {
  position: absolute;
}
.media-container .media .cover-container .control.bottom {
  bottom: 2px;
}
.media-container .media .cover-container .control.left {
  left: 2px;
}
.media-container .media .cover-container .control.top {
  top: 2px;
}
.media-container .media .cover-container .control.right {
  right: 2px;
}
.media-container .media .cover-container .badge {
  padding: 0.2em 0.4em;
  font-size: 0.75em;
  line-height: 1em;
  border-radius: 2px;
  background-color: var(--color-primary);
  color: white;
}
.media-container .media .cover-container play-button {
  font-size: 0.8em;
}
.media-container .media.Xfolder > .cover-container {
  position: relative;
}
.media-container .media.Xfolder > .cover-container .cover {
  border-radius: 5px;
}
.media-container.fullCover {
  height: 100%;
}
.media-container.fullCover .media {
  height: 100%;
}
.media-container.fullCover .media .cover-container {
  height: 100%;
}
:host-context(.color-scheme-dark) .media-container .media.Xfolder:before {
  background-color: rgba(255, 255, 255, 0.1882352941);
}
:host-context(.color-scheme-dark) .media-container .media.Xfolder:after {
  background-color: rgba(255, 255, 255, 0.1882352941);
}
/*# sourceMappingURL=media-card.component-O553NKWI.css.map */
