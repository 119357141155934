<div *ngIf="media && mediaForm" [ngSwitch]="!!editMode" class="container">
    <ng-container *ngSwitchCase="false"
        [ngTemplateOutlet]="view"></ng-container>
    <ng-container *ngSwitchCase="true"
        [ngTemplateOutlet]="edit"></ng-container>
</div>

<ng-template #view>
  <!--
    <mat-checkbox *ngIf="mediaTags.includes('invite.intro')"
        [checked]="true"
        disabled
        aria-label="invite intro">
        <span translate>media.filters.invite.intro</span>
    </mat-checkbox>
  -->
</ng-template>

<ng-template #edit>
    <form [formGroup]="mediaForm">
        <div *ngIf="isPriorityEditor" class="priority">
            <h4 class="header" matSubheader translate>media.priority</h4>
            <mat-slider #prioritySlider
                min="0"
                max="100"
                step="1"
                showTickMarks
                discrete>
              <input matSliderThumb formControlName="priority"/>
            </mat-slider>
        </div>
        <div class="section state">
            <!--
        <mat-checkbox *ngIf="isApprover && !isMaster" formControlName="approved" aria-label="approve">
          <span translate>media.approved</span>
        </mat-checkbox>
        -->
            <mat-checkbox formControlName="published"
                aria-label="publish">
                <span translate>media.published</span>
            </mat-checkbox>
            <mat-checkbox *ngIf="isLeader"
                formControlName="protected"
                aria-label="protect"
                [disabled]="!!media.protected"
                (change)="onProtectedChange($event)">
                <span translate>media.protected</span>
            </mat-checkbox>
            <mat-checkbox *ngIf="media.mediaType==MediaType.image ||
                                 media.mediaType==MediaType.video"
                [checked]="!!mediaTags.includes('folder')"
                (change)="toggleFilter('folder')"
                aria-label="folder">
                <span translate>media.folder</span>
            </mat-checkbox>
            <mat-checkbox *ngIf="editInviteIntro"
                [checked]="!!mediaTags.includes('invite.intro')"
                (change)="toggleFilter('invite.intro')"
                aria-label="invite intro">
                <span translate>media.filters.invite.intro</span>
            </mat-checkbox>
        </div>
      <mat-form-field *ngIf="isVisibilityEditor">
        <mat-label>{{'object.visible' | translate}}</mat-label>
        <textarea matInput
                  unmodified-input
                  formControlName="visible"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="100">
        </textarea>
      </mat-form-field>
      <div *ngIf="isLockEditor" formGroupName="lock" class="lock">
        <h4 class="header" matSubheader translate>media.lock.label</h4>
        <mat-form-field>
          <mat-label>{{'media.lock.formula' | translate}}</mat-label>
          <textarea matInput
                    unmodified-input
                    formControlName="formula"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="100">
            </textarea>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'media.lock.message' | translate}}</mat-label>
          <textarea matInput
                    unmodified-input
                    formControlName="message"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="100">
            </textarea>
        </mat-form-field>
      </div>
    </form>
</ng-template>
