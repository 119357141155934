import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {Logger} from "core";
import {PropertiesService} from "properties";
import {TranslateService} from "@ngx-translate/core";
import {Country} from "../models/country";
import values from "lodash/values";
import mapValues from "lodash/mapValues";

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  protected  _countries: Observable<Country[]>;

  protected logger = new Logger('CountryService').setSilent(true);

  constructor(protected propertiesService: PropertiesService,
              protected translateService: TranslateService) {
  }

  country(countryCode: string): Observable<string> {
    return this.translateService.stream(`countries.${countryCode}`);
  }

  countries(): Observable<Country[]> {
    if (!this._countries) {
      this._countries = this.translateService
        .stream('countries')
        .pipe(
          map(countries => {
            const language = this.translateService.currentLang;
            return values(mapValues(countries, (value, key) => ({ code: key, name: value })))
              .sort((c1, c2) => c1.name.localeCompare(c2.name, language));
          }),
          shareReplay(1));
    }
    return this._countries;
  }
}
