/* projects/shared/src/lib/components/virtual-scroller/virtual-scroll.bar.scss */
:host {
  position: absolute;
  overflow: visible;
  bottom: 0;
  right: 0;
  display: block;
  opacity: 0;
  transition: opacity 1000ms;
}
:host.visible {
  opacity: 1;
  transition: opacity 300ms;
}
:host > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
:host.vertical {
  top: 0;
  width: 10px;
}
:host.horizontal {
  left: 0;
  height: 10px;
}
:host > div > .thumb {
  position: absolute;
}
:host > div > .thumb > div {
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
}
:host.vertical > div > .thumb {
  top: 0;
  right: 0;
  width: 10px;
}
:host.horizontal > div > .thumb {
  bottom: 0;
  left: 0;
  height: 10px;
}
:host > div > .thumb:after {
  content: "";
  position: absolute;
  background-color: gray;
  border-radius: 3px;
  right: 2px;
  bottom: 2px;
}
:host.vertical > div > .thumb:after {
  top: 2px;
  width: 6px;
}
:host.horizontal > div > .thumb:after {
  left: 2px;
  height: 6px;
}
:host-context(.color-scheme-dark) > div > .thumb:after {
  background-color: #AEAFB0;
}
/*# sourceMappingURL=virtual-scroll.bar-TALV3TTU.css.map */
