import {MessageEnvelope} from "./models";

export class MessageHandlerRegistry {
  constructor() {
  }

  protected _messageHandlers = new Map<string,(MessageEnvelope)=>void>();
  addMessageHandler(type:string,handler:(envelope:MessageEnvelope)=>void) {
    this._messageHandlers.set(type,handler);
  }

  hasMessageHandler(type:string):boolean {
    return this._messageHandlers.has(type);
  }

  getMessageHandler(type:string):(MessageEnvelope)=>void {
    return this._messageHandlers.get(type);
  }
}
