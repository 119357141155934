/* projects/shared/src/lib/components/status-bar/status-bar.component.scss */
:host {
  font-size: 0.9em;
  font-weight: 400;
  padding: 5px 16px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  box-shadow:
    0 1px 8px 0 rgba(0, 0, 0, 0.2),
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.12);
}
/*# sourceMappingURL=status-bar.component-EAPJRVBP.css.map */
