import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Media} from "../../store/models";
import {PropertiesService} from "properties";
import {Reaction} from "shared";

@Component({
  selector: 'media-report-dialog',
  template: `
    <h1 mat-dialog-title align="center">{{ propertiesService.group?.name ?? ('app.name' | translate) }}</h1>
    <div mat-dialog-content>
      <media-report #mediaReport [media]="data?.media"></media-report>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close cdkFocusInitial><span translate>actions.cancel</span></button>
      <button mat-button [mat-dialog-close]="mediaReport.value"><span translate>actions.report</span></button>
    </div>
  `,
  styles: [ `.mat-mdc-dialog-actions { flex-wrap: nowrap; }` ]
})
export class MediaReportDialogComponent {

  constructor(public propertiesService: PropertiesService,
              public dialogRef: MatDialogRef<MediaReportDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }
}

@Component({
  selector: 'media-report',
  templateUrl: './media-report.component.html',
  styleUrls: ['./media-report.component.scss']
})
export class MediaReportComponent implements OnInit {

  @Input()
  set media(media: Media) {
    this._media = media;
    this.value = media.reaction ?? Reaction.none;
  }
  get media(): Media {
    return this._media;
  }
  protected _media: Media;
  value: Reaction;
  Reports = Reaction.getReactionsByType('report', true);

  constructor() { }

  ngOnInit(): void {
  }


}
