/* projects/group/src/lib/components/group-switch-dialog/group-switch-dialog.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
  display: block;
  min-height: inherit;
}
h1[mat-dialog-title] {
  text-align: center;
  margin: 0 0 20px;
}
div[mat-dialog-content] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
div[mat-dialog-content] group-logo {
  width: 100%;
}
div[mat-dialog-content] .message {
  padding: 24px 10px 10px 10px;
}
div[mat-dialog-content] .message > :not(:last-child) {
  margin-bottom: 10px;
}
div[mat-dialog-content] .message .avatar {
  display: flex;
  align-items: center;
}
div[mat-dialog-content] .message .avatar img {
  opacity: 0;
  width: 32px !important;
  height: 32px !important;
}
div[mat-dialog-content] .message .avatar span {
  margin-left: 8px;
  color: #424242;
}
div[mat-dialog-content] .message div.info {
  font-size: 0.8em;
  font-style: italic;
  color: #9e9e9e;
}
div[mat-dialog-actions] {
  margin: 16px 5px 0 5px;
  justify-content: center;
}
div[mat-dialog-actions] .last {
  margin-bottom: 16px;
}
button.close {
  position: absolute;
  top: 0;
  right: 0;
  color: #424242;
}
:host-context(.color-scheme-dark) {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .avatar span {
  color: #f5f5f5 !important;
}
:host-context(.color-scheme-dark) button.close mat-icon {
  color: #AEAFB0;
}
/*# sourceMappingURL=group-switch-dialog.component-WWAHNEZP.css.map */
