import {MediaViewerContainer} from "../media-viewer-container";
import {EventEmitter} from "@angular/core";
import {OverlayRef} from "@angular/cdk/overlay";
import {Observable} from "rxjs";

export class MediaViewerOverlayRef {

  public viewer: MediaViewerContainer;
  public complete = false;
  protected closed = new EventEmitter<void>();

  constructor(private overlayRef: OverlayRef) { }

  close(): void {
    this.overlayRef.dispose();
    this.closed.emit();
  }

  get closed$(): Observable<void> {
    return this.closed.asObservable();
  }
}
