<div class="content" *ngIf="!!channel">
  <h4 class="info-header" matSubheader translate>channel.information.description</h4>
  <ng-container [ngSwitch]="!!editMode" class="container">
    <ng-container *ngSwitchCase="false">
      <more-text [text]="channel.bio"></more-text>
    </ng-container>
    <ng-container *ngSwitchCase="true">
      <mat-form-field class="input-info" style="box-sizing: initial!important">
        <textarea matInput
                  #infoInput
                  [(ngModel)]="channel.bio"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="30"
                  maxlength="2000"
                  required>
            </textarea>
        <mat-hint align="end">{{infoInput.value?.length || 0}}/2000</mat-hint>
      </mat-form-field>
    </ng-container>
  </ng-container>
  <ng-container [ngSwitch]="!!editMode" class="container">
    <ng-container *ngSwitchCase="false">
      <ng-container *ngIf="!!channel.links && channel.links.length > 0">
        <h4 class="info-header" matSubheader translate>channel.links.title</h4>
        <a class="channel-link" [href]="link.url" target="_blank"
           *ngFor="let link of channel.links">{{link.label}}</a>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="true">
      <h4 class="info-header" matSubheader translate>channel.links.title</h4>
      <div cdkDropList class="example-list"
           [cdkDropListData]="channel.links"
           (cdkDropListDropped)="drop($event)">
        <div class="link-box mat-elevation-z" *ngFor="let link of channel.links; index as index" cdkDrag>
          <mat-icon cdkDragHandle class="move-icon">drag_handle</mat-icon>
          <mat-form-field class="link-input input-label">
            <mat-label translate>contact.name</mat-label>
            <input matInput
                   [(ngModel)]="link.label"
                   required>
          </mat-form-field>
          <mat-form-field class="link-input input-url">
            <mat-label translate>upload.fileType.link</mat-label>
            <input matInput
                   [(ngModel)]="link.url"
                   required>
          </mat-form-field>
          <button mat-icon-button color="primary" aria-label="Delete link"
                  (click)="channel.links.splice(index, 1)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
      <button mat-button (click)="channel.links.push({})" translate>channel.links.add</button>
    </ng-container>
  </ng-container>
  <!-- <h4 class="info-header" matSubheader translate>Unterstützen</h4> -->
</div>