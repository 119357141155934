import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, cordovaPropertyGet, cordovaPropertySet } from '@ionic-native/core';
var Device = /** @class */function (_super) {
  __extends(Device, _super);
  function Device() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  Object.defineProperty(Device.prototype, "cordova", {
    get: function () {
      return cordovaPropertyGet(this, "cordova");
    },
    set: function (value) {
      cordovaPropertySet(this, "cordova", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Device.prototype, "model", {
    get: function () {
      return cordovaPropertyGet(this, "model");
    },
    set: function (value) {
      cordovaPropertySet(this, "model", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Device.prototype, "platform", {
    get: function () {
      return cordovaPropertyGet(this, "platform");
    },
    set: function (value) {
      cordovaPropertySet(this, "platform", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Device.prototype, "uuid", {
    get: function () {
      return cordovaPropertyGet(this, "uuid");
    },
    set: function (value) {
      cordovaPropertySet(this, "uuid", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Device.prototype, "version", {
    get: function () {
      return cordovaPropertyGet(this, "version");
    },
    set: function (value) {
      cordovaPropertySet(this, "version", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Device.prototype, "manufacturer", {
    get: function () {
      return cordovaPropertyGet(this, "manufacturer");
    },
    set: function (value) {
      cordovaPropertySet(this, "manufacturer", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Device.prototype, "isVirtual", {
    get: function () {
      return cordovaPropertyGet(this, "isVirtual");
    },
    set: function (value) {
      cordovaPropertySet(this, "isVirtual", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(Device.prototype, "serial", {
    get: function () {
      return cordovaPropertyGet(this, "serial");
    },
    set: function (value) {
      cordovaPropertySet(this, "serial", value);
    },
    enumerable: false,
    configurable: true
  });
  Device.pluginName = "Device";
  Device.plugin = "cordova-plugin-device";
  Device.pluginRef = "device";
  Device.repo = "https://github.com/apache/cordova-plugin-device";
  Device.platforms = ["Android", "Browser", "iOS", "macOS", "Windows"];
  Device.decorators = [{
    type: Injectable
  }];
  return Device;
}(IonicNativePlugin);
export { Device };
