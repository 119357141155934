
let logActionInitialTime = Date.now();
export function logAction<S,A>(name:string,log:boolean,state:S,action:A,reducer:()=>S):S {
  const result = reducer();
  if (log && result===state) {
    console.log('STORE.'+name,"time",(Date.now()-logActionInitialTime).toLocaleString("de")+"ms",state,"action",action);
  } else if (log) {
    console.log('STORE.'+name,"time",(Date.now()-logActionInitialTime).toLocaleString("de")+"ms",state,"action",action,"\nresult",result);
  }
  return result;
}

