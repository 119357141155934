/* projects/shared/src/lib/components/partner-id/partner-id.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host > a {
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
}
:host > a:first-child > span,
:host > span:first-child {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  font-size: 80%;
  display: inline-block;
  vertical-align: top;
  background-color: var(--color-primary);
  color: #fff;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
  border: 1px solid var(--color-primaryContrast-900);
}
:host > a:first-child > span.leader,
:host > span:first-child.leader {
  background-color: darkseagreen;
}
:host > a:first-child > span.temporary,
:host > span:first-child.temporary {
  background-color: orange;
}
:host > a:first-child > span.extended,
:host > span:first-child.extended {
  background-color: #bbb;
}
:host-context(.goodnews) > span:first-child.admin,
:host-context(.goodnews) > span:first-child.leader {
  background-color: lightcoral;
}
:host-context(.color-scheme-dark) > a:first-child > span,
:host-context(.color-scheme-dark) > span:first-child {
  background-color: var(--color-primary);
  color: #E5E5E5;
  border: 1px solid #444;
}
:host-context(.color-scheme-dark) > a:first-child > span.leader,
:host-context(.color-scheme-dark) > span:first-child.leader {
  background-color: #709E4E;
}
:host-context(.color-scheme-dark) > a:first-child > span.temporary,
:host-context(.color-scheme-dark) > span:first-child.temporary {
  background-color: orange;
}
:host-context(.color-scheme-dark) > a:first-child > span.extended,
:host-context(.color-scheme-dark) > span:first-child.extended {
  background-color: #777;
}
/*# sourceMappingURL=partner-id.component-J73IVHDX.css.map */
