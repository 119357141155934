import {Inject, Injectable, Optional} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  NavigationCancel,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {combineLatest, from, Observable, of, Subject, Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectAuthenticationState,} from "../store/reducers";
import {PropertiesService, selectProperties} from "properties";
import {APP_ID, LOCAL_STORAGE, Logger, RouteReusableStrategyHandler, SyncStorage} from "core";
import {MessagingService} from "messaging";
import {AuthenticationRoutingConfig} from "../authentication.module.config";
import {HttpClient} from "@angular/common/http";
import {debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap} from "rxjs/operators";
import {LayoutService, SidenavMode} from "layout";
import {TranslateService} from "@ngx-translate/core";
import isEqual from "lodash/isEqual";
import {RoutePathService} from "core";
import {cloneDeep} from "lodash";

interface AuthenticationInfo {
  role: string,
  tags: string[],
  redirect: boolean
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationRoutingGuard implements CanActivate, CanActivateChild {

  protected authenticated = false;
  protected signed = false;
  protected onboardingMedia: { completed?: boolean } = undefined;

  rootAppPath: string;
  loginPath: string;
  signPath: string;
  onboardingPath: string;
  action$ = new Subject<any>();
  protected logger = new Logger('AuthenticationRoutingGuard');

  constructor(private router: Router,
              private propertiesService: PropertiesService,
              protected layoutService: LayoutService,
              protected messagingService: MessagingService,
              protected translateService: TranslateService,
              private store$: Store<any>,
              private routeReusableStrategyHandler: RouteReusableStrategyHandler,
              private httpClient: HttpClient,
              private routePathService: RoutePathService,
              @Optional() private config: AuthenticationRoutingConfig,
              @Inject(LOCAL_STORAGE) protected storage: SyncStorage,
              @Inject(APP_ID) protected appId: number) {
    //this.logger.debug('AuthenticationRoutingGuard.ctor');
    this.initialize();
  }

  public initialize() {
    this.rootAppPath = this.config    ? this.config.rootAppPath : '';
    this.loginPath   = this.appId > 0 ? "/login/guest" : "/login";
    this.signPath    = this.loginPath;
    this.onboardingPath = '/onboarding';

    const actionBuffer = (source) => {
      return new Observable(subscriber => {
        let buffer = [];
        const subscription = new Subscription();
        subscription.add(source.subscribe({
          next: (value) => {
            buffer.push(value);
            if (this.router.navigated) {
              subscriber.next(buffer);
              buffer = [];
            } else {
              subscription.add(this.router.events
                .pipe(
                  filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel),
                  take(1)
                )
                .subscribe((event) => {
                  subscriber.next(buffer);
                  buffer = [];
                })
              );
            }
          },
          error: (error) => subscriber.error(error),
          complete: () => subscriber.complete()
        }));
        return () => {
          subscription.unsubscribe();
        };
      })
    }

    this.action$.pipe(
      tap(action => this.logger.debug('ACTION', action)),
      // buffer actions until the initial router navigation is performed
      actionBuffer
    ).subscribe((buffer) => {
      this.logger.debug('ACTION BUFFER', buffer);
      const action = buffer?.[0];
      action?.call(this);
    })

    combineLatest([
        this.store$.pipe(
          select(selectAuthenticationState),
          tap(state  => this.logger.debug('selectAuthenticationState', state))
        ),
        this.store$.pipe(
          select(selectProperties),
          tap(properties  => this.logger.debug('selectProperties', properties)),
        )
      ]).pipe(
        // AuthenticationState and Properties are synced by AuthenticationService
        // which could happen with a little delay
        filter(([state, properties]) =>
          state.user.isAuthenticated==properties.user.isAuthenticated &&
          state.signed==properties.signed
        ),
        map(([state, properties]) => [
          state.user.isAuthenticated,
          state.signed,
          properties.group?.onboarding?.media
        ] as [boolean, boolean, { completed?: boolean }]),
        distinctUntilChanged((s1, s2) => isEqual(s1, s2)),
        filter(([authenticated, signed, onboardingMedia]) =>
             authenticated!=this.authenticated ||
             signed!=this.signed ||
            !onboardingMedia!=!this.onboardingMedia ||
           !!onboardingMedia &&
             onboardingMedia.completed!=this.onboardingMedia.completed
        ),
        // debounceTime(100)
      )
      .subscribe(([authenticated, signed, onboardingMedia]) => {
        let action: () => void = undefined;
        const onboardingMediaChange = !onboardingMedia!=!this.onboardingMedia ||
                                              !!onboardingMedia &&
                                                onboardingMedia.completed!=this.onboardingMedia.completed;
        if (authenticated && signed &&
           (!onboardingMedia || onboardingMedia?.completed) &&
           (!this.authenticated ||
            !this.signed ||
             onboardingMediaChange)) {
          action = this.onLogin;
        } else if (authenticated && (!signed ||
                  (!onboardingMedia /*|| !onboardingMedia?.completed*/)) &&
                  (!this.authenticated ||
                   this.signed ||
                  onboardingMediaChange)) {
          action = this.onSign;
        } else if (authenticated && signed && onboardingMedia && !onboardingMedia?.completed &&
                  (!this.authenticated || !this.signed || onboardingMediaChange)) {
          action = this.onOnboarding;
        } else if (!authenticated && (
                    this.authenticated  ||
                    this.signed!=signed ||
                    onboardingMediaChange)) {
          action = this.onLogout;
        }
        this.authenticated = authenticated;
        this.signed = signed;
        this.onboardingMedia = cloneDeep(onboardingMedia);
        this.action$.next(action);
      });
  }

  protected onLogin() : void {
    const url = this.storage.get(this.getUrlKey(true)) || this.rootAppPath;
    this.router.navigateByUrl(url, { replaceUrl: true }).then(() => {
      this.routePathService.clearHistory(1);
    })
  }

  protected onSign() : void {
    // let timer = this.loginTimer = window.setTimeout(() => {
    //this.logger.debug("onLogin equals:"+(this.loginTimer==timer));
    // if (this.loginTimer==timer /*&& !autoLogin*/) {
    // always go to signPath,
    // later if media provides internal "sign" feature we can directly navigate to onboardingPath
    const url = false && this.onboardingMedia ? this.onboardingPath : this.signPath;
    //this.logger.debug("onLogin -> url:"+url, "currentUrl:"+this.router.url);
    /*
    if (url == this.signPath) {
      const key = this.getUrlKey(false);
      if (this.storage.get(key) != url) {
        this.storage.set(key, url); // prevent invitation token (if it is supplied in the link) to be pulled from url on logout
      }
      // Path change results in update of the value for "router.url.false" key in local storage
      // instead of e.g. /join/FTBFK-MCGNK-D3Z4W-VL3JH-3SL6J we endup with /login/guest (i.e. signPath).
      // However, it also leads to undefined invitationToken being detected in LoginPageComponent.
      // Through LoginObserver this undefined value is reported to AppComponent which breaks connection procedure
      // this.router.navigateByUrl(url, { skipLocationChange: true }).then((result => {
      // when the url is not effectively changed the automatic change detection also does not run
      // this.changeDetector.detectChanges(); // seems not needed anymore (verified to work with angular 14.2)
      // }));
    } else
    */
    const key = this.getUrlKey(false);
    if (this.storage.get(key) != this.loginPath) {
      this.storage.set(key, this.loginPath);
    }
    if (this.router.url!=url) {
      this.router.navigateByUrl(url, {
          replaceUrl  : true,
          state: {
            rootAppPath : this.rootAppPath,
            loginPath   : this.loginPath
          }
      });
    }
  }

  protected onOnboarding() : void {
    const url = this.onboardingMedia;
    const key = this.getUrlKey(true);
    if (this.storage.get(key) != this.rootAppPath) {
      this.storage.set(key, this.rootAppPath);
    }
    if (this.router.url!=url) {
      this.router.navigateByUrl(this.onboardingPath, {
          replaceUrl  : true,
          state: {
            rootAppPath : this.rootAppPath,
            loginPath   : this.loginPath
          }
      });
    }
  }

  protected onLogout() : void {
    //this.logger.info("onLogout!");
    let url = this.storage.get(this.getUrlKey(false)) || this.loginPath;
    //this.logger.debug("onLogout -> "+url);
    this.messagingService.reset(); // workaround for problematic server side tracking of session -> connections mapping
    this.layoutService.navigation.closeIf(state => state.mode==SidenavMode.OVER);
    this.layoutService.details.closeIf(state => state.mode==SidenavMode.OVER);
    this.router.navigateByUrl(url, { replaceUrl: true }).then(() => {
      this.routePathService.clearHistory();
      this.routeReusableStrategyHandler.reset();
      /*
       *  avoid this.storage.clear();
       *  clears also onesignal data which forces display of notification slide prompt
       *  on next login once the page has been reloaded (and new settings are fetched from local store)
       */
      this.storage.remove(this.getUrlKey(true));
    })
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const canActivate = (): boolean | UrlTree => {
      const requiredAuthenticationInfo = this.getRequiredAuthenticationInfo(route);
      let url = state.url;
      if (requiredAuthenticationInfo.role) {
        if (!this.authenticated ||
           (this.onboardingMedia
             ? !(this.onboardingMedia.completed && this.signed) && url!=this.onboardingPath
             : !this.signed)) {
          this.logger.warn("canActivate -> FALSE",
              (({authenticated, onboardingMedia, signed}) =>
              ({authenticated, onboardingMedia, signed}))(this),
              url, route);
          return false;
        }
      }
      if (this.appId > 0) {
        switch (url) {
          case '/login': {
            return this.router.parseUrl(this.loginPath);
          }
          case '/entry': {
            if (this.authenticated) {
              return false;
            } else if (!this.propertiesService.group?.entry?.page) {
              return this.router.parseUrl(this.loginPath);
            } else {
              url = this.loginPath;
            }
            break;
          }
          case '/onboarding': {
            if (!this.authenticated) {
              return this.router.parseUrl(this.loginPath);
            }
            break;
          }
        }
      }
      if (url!=this.onboardingPath) {
        this.storage.set(this.getUrlKey(!!requiredAuthenticationInfo.role), url);
      }
      return true;
    };

    const match = state.url.match(/(.*)\?authenticationToken=([\S]+)$/);
    if (match && match.length >= 3) {
      const token = match[2];
      if (token) {
        return new Promise((resolve, reject) => {
          return this.httpClient.get(`/v1.0/session/clone/${token}`)
            .pipe(switchMap((response: any) => {
              //this.logger.debug('SESSION CLONE RESPONSE', token, response);
              return of(response && response.done);
            })).subscribe((success) => {
              //this.logger.debug('SESSION CLONE SUCCESS', success);
              if (success) {
                from(this.propertiesService.reload().then(() => resolve(canActivate())))
              } else {
                resolve(canActivate());
              }
            });
        });
      }
    }

    return canActivate();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const requiredAuthenticationInfo = this.getRequiredAuthenticationInfo(childRoute);
    if (requiredAuthenticationInfo.role) {
      if (!this.authenticated ||
        (this.onboardingMedia
          ? !(this.onboardingMedia.completed && this.signed) && state.url!=this.onboardingPath
          : !this.signed)) {
        this.logger.debug("canActivateChild false", childRoute);
        return false;
      }
    }
    this.storage.set(this.getUrlKey(!!requiredAuthenticationInfo.role), state.url);
    this.logger.debug("canActivateChild true", childRoute);
    return true;
  }

  getDefaultUrl(ignoreStoredUrl = false ): string {
    return this.authenticated
      ? this.signed
        ? !ignoreStoredUrl && this.storage.get(this.getUrlKey(true)) || this.rootAppPath
        : this.signPath
      : !ignoreStoredUrl && this.storage.get(this.getUrlKey(false)) || this.loginPath;
  }

  protected getUrlKey(authenticated:boolean) {
    return "router.url."+authenticated;
  }

  protected getRequiredAuthenticationInfo(path : ActivatedRouteSnapshot) : AuthenticationInfo {
    let result = <AuthenticationInfo> {
      role: null,
      tags: [],
      redirect: !!path.routeConfig.redirectTo
    };
    if (path && path.pathFromRoot) {
      for (let i = path.pathFromRoot.length-1; i >= 0; i--) {
        let data = path.pathFromRoot[i].data;
        if (data && data['role'] && !result.role) {
          result.role = data['role'];
        }
      }
    }
    return result;
  }

  protected printAll(pathes : ActivatedRouteSnapshot[]) : void {
    if (pathes) {
      pathes.forEach(path => {
        this.printOne(path);
      });
    }
  }

  protected printOne(path : ActivatedRouteSnapshot) : void {
    // this.logger.debug('\tpath: '+path+' '+JSON.stringify(path.data.valueOf())+' '+JSON.stringify(path.routeConfig.redirectTo));
  }
}
