import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Logger, Topic} from "core";
import {BehaviorSubject} from "rxjs";
import {EMPTY_ARRAY} from "core";

export interface FilterTagEvent {
  filter: Topic;
  auto: boolean;
}

@Component({
  selector: 'filter-tags',
  templateUrl: './filter-tags.component.html',
  styleUrls: ['./filter-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterTagsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() filter: Topic;
  @Input() selected: string[] = EMPTY_ARRAY;
  @Input() visibleFilter:  (filter: Topic) => boolean = filter => true;
  @Input() editableFilter: (filter: Topic) => boolean = filter => true;
  @Input() filterTagsTemplateRef: TemplateRef<any>;
  @Input() @HostBinding('class.readonly') readOnly = false;
  @Input() @HostBinding('class.markEditable') markEditable = true;

  @Output() onFilter: EventEmitter<FilterTagEvent> = new EventEmitter();
  @Output() onUpdate: EventEmitter<void> = new EventEmitter();
  @Output() filter$ = new BehaviorSubject<Topic>(undefined);
  EMPTY_SELECTION = EMPTY_ARRAY;

  @ViewChild('filterTagsTemplate', { static: true }) filterTagsTemplate: TemplateRef<any>;

  protected logger = new Logger('FilterTagsComponent').setSilent(true);

  constructor(private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.filterTagsTemplateRef = this.filterTagsTemplateRef ?? this.filterTagsTemplate;
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy()', this.filter?.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filter$.next(this.prepare(this.filter,this.selected??[]));
    this.logger.debug("FILTER-TAGS",this.filter?.id,
      "\nselected",this.selected,
      "\nchanges",changes,
      "\nfilter$",this.filter$.value);
    this.onUpdate.next();
    //this.logger.debug("FILTER-TAGS:empty",this.filter?.id);
    // this.changeDetectorRef.markForCheck();
  }

  protected prepare(filter:Topic,selected:string[]):Topic {
    if (!!filter) {
      const topics = filter.topics?.map(topic=>this.prepare(topic,selected))
                                   .filter(topic=> {
                                     if (!!topic) {
                                       topic.parent = filter;
                                       return true;
                                     }
                                     return false;
                                   });
      if (topics?.length>0) {
        return {
          ...filter,
          topics,
          editable:this.readOnly ? false : this.editableFilter(filter),
          visible: true
        }
      } else {
        if (this.readOnly) {
          return selected.includes(filter.id) ? {
            ...filter,
            topics:undefined,
            editable:false,
            visible:true
          } : undefined;
        } else if (this.visibleFilter(filter)) {
          return {
            ...filter,
            topics: undefined,
            editable: this.editableFilter(filter),
            visible: true
          };
        }
      }
    }
    return undefined;
  }

  onTapFilter(filter:Topic, auto:boolean) {
    if (!this.readOnly && filter.editable) {
      this.logger.debug('onFilter', filter);
      this.onFilter.emit({filter,auto});
    }
  }
}
