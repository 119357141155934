import {ApplicationRef, Inject, Injectable} from '@angular/core';

import {MatSnackBar} from '@angular/material/snack-bar';
import {SwUpdate} from '@angular/service-worker';
import {concat, interval, of} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ENVIRONMENT} from "../config";
import {Logger} from "../logger.service";

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  protected logger = new Logger('UpdateService');

  constructor(private applicationRef: ApplicationRef,
              private swUpdate: SwUpdate,
              private snackBar: MatSnackBar,
              private translateService: TranslateService, // injected translateService does not perform translations - bug!?
              @Inject(ENVIRONMENT) private environment: any) {

  }

  initialize(translateService: TranslateService) {
    console.log('UpdateService.initialize');
    const appUpdateAvailableKey = 'app.update.newVersionAvailable', appUpdateKey = 'actions.update';
    this.swUpdate.versionUpdates.subscribe(event => {
      this.logger.info('UpdateService.versionUpdates', event);
      if (event.type == "VERSION_READY") {
        translateService.get([appUpdateAvailableKey, appUpdateKey]).subscribe((translations) => {
          const snackBarRef = this.snackBar.open(
            translations[appUpdateAvailableKey],
            translations[appUpdateKey],
            {
              // duration: 10000,
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              panelClass: 'app-update'
            }
          );
          snackBarRef.onAction().subscribe(() => {
            snackBarRef.dismiss();
            document.location.reload();
            // this.swUpdate.activateUpdate().then(() => document.location.reload());
          });
        });
      }
    });

    const appIsStable$ = of(true);//this.applicationRef.isStable.pipe(first(isStable => isStable === true));
    const everyHour$ = interval(1 * 60 * 60 * 1000);
    const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);
    everyHourOnceAppIsStable$.subscribe(() => {
      console.log(`Check For Update... [currentVersion=${this.environment.version}]`);
      this.swUpdate.checkForUpdate();
    });
  }
}
