import {StorageCodec} from "../storage.codec";
import {SyncStorage} from "./storage";

export class BaseStorage<T> implements SyncStorage<T> {

  constructor(protected readonly storage: Storage,
              protected readonly codec: StorageCodec<T>) {
    console.log("BASE_STORAGE");
  }

  public has(key: string): boolean {
    return this.storage.getItem(key) !== null;
  }

  public get(key: string): T {
    const value = this.storage.getItem(key);
    return value !== undefined ? this.codec.decode(value) : undefined;
  }

  public set(key: string, value: T): void {
    return this.storage.setItem(key, this.codec.encode(value));
  }

  public remove(key: string): void {
    this.storage.removeItem(key);
  }

  public clear(): void {
    this.storage.clear();
  }

}
