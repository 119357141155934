import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  TemplateRef
} from '@angular/core';
import {Logger, Topic} from "core";
import {FilterTagEvent} from "../tags/filter-tags.component";

@Component({
  selector: 'filter-tag-section',
  templateUrl: './filter-tag-section.component.html',
  styleUrls: ['./filter-tag-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterTagSectionComponent implements OnDestroy {

  @Input() filter: Topic;
  @Input() selected: string[] = [];
  @Input() visibleFilter:  (filter: Topic) => boolean = filter => true;
  @Input() editableFilter: (filter: Topic) => boolean = filter => true;
  @Input() markEditable:boolean = true;
  @Input() filterTagsTemplateRef: TemplateRef<any>;
  @Input() @HostBinding('class.readonly') readOnly = false;

  @Output() onFilter: EventEmitter<FilterTagEvent> = new EventEmitter();
  @Output() onUpdate: EventEmitter<boolean> = new EventEmitter();

  protected _header = false;
  protected logger = new Logger('FilterTagSectionComponent').setSilent(true);

  set header(value:boolean) {
    if (this._header!=value) {
      this.logger.debug("HEADER",this._header,value,this.filter?.id);
      this._header = value;
      this.changeDetectorRef.detectChanges();
    }
  }
  get header():boolean {
    return this._header;
  }

  constructor(
    private elementRef: ElementRef,
    public changeDetectorRef: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy()', this.filter?.id);
  }
}
