import {ComponentFactoryResolver, ComponentRef, Injectable, Injector} from '@angular/core';
import {AccordionSectionComponentFactory, AccordionSectionFactoryService} from 'shared';
import {
  MediaDetailsCountryFilterComponent
} from '../media-details-country-filter/media-details-country-filter.component';
import {MediaDetailsCoverComponent} from '../media-details-cover/media-details-cover.component';
import {MediaDetailsFilterComponent} from '../media-details-filter/media-details-filter.component';
import {MediaDetailsInfoComponent} from '../media-details-info/media-details-info.component';
import {MediaDetailsLockComponent} from '../media-details-lock/media-details-lock.component';
import {MediaDetailsRatingComponent} from '../media-details-rating/media-details-rating.component';
import {MediaDetailsReviewComponent} from '../media-details-review/media-details-review.component';
import {MediaDetailsSettingsComponent} from '../media-details-settings/media-details-settings.component';
import {MediaDetailsUserRoleComponent} from '../media-details-user-role/media-details-user-role.component';
import {MediaActionsComponent} from "media/lib/components/media-actions/media-actions.component";

@Injectable({
  providedIn: 'root'
})
export class MediaDetailsComponentFactoryService implements AccordionSectionFactoryService {

  private factories: { [key: string]: AccordionSectionComponentFactory | AccordionSectionComponentFactory[] } = {};

  constructor() {
    this.factories['info'] = this.createInfoComponent();
    this.factories['rating'] = this.createRatingComponent();
    this.factories['review'] = this.createReviewComponent();
    this.factories['settings'] = this.createSettingsComponent();
    //this.factories['topics'] = this.createTopicsComponent();
    this.factories['tags'] = this.createTagsComponent();
    this.factories['cover'] = this.createCoverComponent();
    this.factories['lock'] = this.createLockComponent();
    this.factories['country'] = this.createCountryFilterComponent();
    this.factories['role'] = this.createUserRoleComponent();
    //this.factories['bible'] = this.createBibleComponent();
    this.factories['actions'] = this.createActionsComponent();
  }

  getViewerFactory(type: string, context?: any): AccordionSectionComponentFactory {
    return this.getFactory(type, context, this.factories);
  }

  protected getFactory(type: string, context?: any,
    factories?: { [key: string]: AccordionSectionComponentFactory | AccordionSectionComponentFactory[] }): AccordionSectionComponentFactory {
    const sameTypeFactories = factories[type];
    return Array.isArray(sameTypeFactories) ?
      sameTypeFactories.find(factory => factory.canHandle(context)) :
      sameTypeFactories?.canHandle(context) ? sameTypeFactories : undefined;
  }

  createInfoComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsInfoComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsInfoComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createRatingComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsRatingComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsRatingComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createReviewComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsReviewComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsReviewComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createSettingsComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsSettingsComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsSettingsComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createTagsComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsFilterComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsFilterComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createCoverComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsCoverComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsCoverComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createLockComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsLockComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsLockComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  /*
  createTopicsComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsTopicsComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsTopicsComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }
   */

  createCountryFilterComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsCountryFilterComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsCountryFilterComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  createUserRoleComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsUserRoleComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsUserRoleComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }

  /*
  createBibleComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaDetailsBibleComponent> {
        return resolver
          .resolveComponentFactory(MediaDetailsBibleComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }
   */

  createActionsComponent(): AccordionSectionComponentFactory {
    return new class implements AccordionSectionComponentFactory {
      createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<MediaActionsComponent> {
        return resolver
          .resolveComponentFactory(MediaActionsComponent)
          .create(injector);
      }
      canHandle(context: any): boolean {
        return true;
      }
    }
  }
}
