<ng-container *ngFor="let filter of context.topics; let index = index">
    <ng-container *ngTemplateOutlet="filterTemplate; context: {
        filter,
        selected: selectedFilterIds,
        readOnly: false,
        index
    }"></ng-container>
</ng-container>

<ng-template #filterTemplate let-filter="filter" let-selected="selected" let-readOnly="readOnly" let-index="index">
    <filter-tag-section [filter]="filter" [selected]="selected" [readOnly]="readOnly"
         (onFilter)="onTapFilter($event)" class="section">
    </filter-tag-section>
</ng-template>
