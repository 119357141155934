import {Observable} from "rxjs";

export interface SimpleFilterableState {
  readonly filters : string[];
}

export interface SimpleFilterService {
  updateFilter(filters : string[]) : void;
  filters$ : Observable<string[]>;
}

export interface TypedFilterableState {
  readonly filters : {[key:string]:string[]};
}
export enum FilterTypes {
  ROOT = "root",
  NAVIGATION = "navigation",
  TAGS = "tags",
  COUNTRY = "country",
  TOPICS = "topics"
}
export var REMOVE_ALL_OTHER_TYPES = (type:string,filters:string[])=>true;
export interface TypedFilterService {
  /**
   * set typed filters for all types at once...
   * @param typedFilters  0.n typed filters
   * @param remove        callback to check if existing type/filters other the given types should be removed....
   * @returns boolean true if updated, else false
   */
  setTypedFilters(typedFilters:{[key:string]:string[]},remove?:(type:string,filters:string[])=>boolean) : Promise<boolean>;

  /**
   * get all typed filters which are not excluded by given callback
   * @param exclude     callback to check if this type of filter should not be returned
   * @returns a copy of all requested typed filters
   */
  getTypedFilters$(exclude?:(type:string,filters:string[])=>boolean) : Observable<{[key:string]:string[]}>;

  /**
   * get all typed filters which are not excluded by given callback in one string array
   * @param exclude     callback to check if this type of filter should not be returned
   * @returns a combined array of all requested typed filters
   */
  getCombinedFilters$(exclude?:(type:string,filters:string[])=>boolean) : Observable<string[]>;
}

export function updateFilters(
  currentFilters:{[type:string]:string[]},
  newFilters:{[type:string]:string[]},
  removeFilters?:(type:string,filters:string[])=>boolean):{[type:string]:string[]} {
  newFilters = {...newFilters};
  Object.keys(newFilters).forEach(type=> newFilters[type] = [...newFilters[type]]?.sort())
  const updatedFilters = {...currentFilters,...newFilters};
  if (!!removeFilters) {
    Object.keys(updatedFilters).forEach(type=>{
      if (!newFilters[type] && removeFilters(type,updatedFilters[type])) {
        delete updatedFilters[type];
      }
    });
  }
  return updatedFilters;
}

export function removeFilters(
  currentFilters:{[type:string]:string[]},
  removeFilters:{[type:string]:string[]}):{[type:string]:string[]} {
  const updatedFilters = {...currentFilters};
  if (!!removeFilters) {
    Object.keys(removeFilters).forEach(type=>{
      delete updatedFilters[type];
    });
  }
  return updatedFilters;
}
