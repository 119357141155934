import { Component, EventEmitter, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime, takeUntil } from "rxjs";
import { BasicComponent } from "shared";

@Component({
  selector: 'filter-searchbar',
  templateUrl: './filter-searchbar.component.html',
  styleUrls: ['./filter-searchbar.component.scss']
})
export class FilterSearchbarComponent extends BasicComponent {

  @Output() searchChange = new EventEmitter<string>();
  @Output() selectAllChange = new EventEmitter<boolean>();

  selectAllChecked: boolean = false;
  searchFocus: boolean = false;
  searchValue: string = null;

  searchControl = new FormControl('');

  constructor() {
    super();
  }

  ngOnInit() {
    this.searchControl.valueChanges.pipe(takeUntil(this.onDestroy$), debounceTime(400)).subscribe(value => {
      this.searchChange.emit(value);
    });
  }

  onSearch(value: string) {
    this.searchChange.emit(value);
  }

  clearSearch() {
    this.searchValue = '';
    this.selectAllChecked = false;
    this.onSearch(this.searchValue);
  }

  setSelectAll(value: boolean) {
    this.selectAllChecked = value;
    this.selectAllChange.emit(value);
  }

}
