import {Contact, ENVIRONMENT, GroupMembership, Logger, ReferrerId} from "core";
import {Group, PropertiesService} from "properties";
import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {RESET_GROUP_ACTION, RESET_APP_ACTION, StoreService} from "store";
import {MenuService} from "shared";
import {TranslateService} from "@ngx-translate/core";
import isEqual from "lodash/isEqual";
import pick from "lodash/pick";
import {LayoutService} from "layout";
import {pairwise, tap} from "rxjs/operators";
import {lastValueFrom, Observable, Subject} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {GroupSwitchDialogComponent} from "./components/group-switch-dialog/group-switch-dialog.component";
import { ConfigurationGroup } from "./config/group-configuration-page/group-configuration-page.component";

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  protected group: Group;
  protected _switch$ = new Subject<Group>();
  protected logger = new Logger('GroupService');

  constructor(protected propertiesService: PropertiesService,
              protected http: HttpClient,
              protected menuService: MenuService,
              protected store$: Store<any>,
              protected storeService: StoreService,
              protected translateService: TranslateService,
              protected layoutService: LayoutService,
              protected dialog: MatDialog,
              @Inject(RESET_GROUP_ACTION) protected resetGroupAction: any,
              @Inject(RESET_APP_ACTION) protected resetAppAction: any,
              @Inject(ENVIRONMENT) protected environment: any) {
    console.log('GroupService.ctor');
    this.propertiesService.properties$
      .pipe(pairwise())
      .subscribe(([previousProperties, currentProperties]) => {
        // Reload translations if groups are switched or if the group language is different
        // (group locale is included in properties)
        const currentGroup = currentProperties?.group;
        const currentGroupLanguage = currentGroup?.language;
        //console.log('Group',currentGroup,currentGroupLanguage);
        const groupProperties = ['id', 'locale'];
        if (currentGroupLanguage &&
          !isEqual(pick(previousProperties?.group,   groupProperties),
            pick(currentGroup, groupProperties))) {
          this.logger.debug('Reloading translations...');
          this.reloadLanguage(currentGroup).then(language => {
            // if (properties.user.isAuthenticated) {
            //console.log('Group language reloaded!',currentGroupLanguage);
            //this.logger.debug('Group language reloaded!',currentGroupLanguage);
            this.menuService.reset();
            this.translateService.getTranslation(currentGroupLanguage).toPromise()
              .then(translation => {
                this.translateService.onTranslationChange.emit({lang: currentGroupLanguage, translations: translation});
                this._switch$.next(currentGroup);
              });
          })
        }
      });
  }

  async switch(group: Group, invitationCode?: string): Promise<Group> {
    const currentGroup = this.propertiesService.properties.group;
    if (group?.id!=currentGroup.id || // different group -> perform switch
        invitationCode) {             // invitation code -> perform connect
      const body = invitationCode ? { invitationToken: invitationCode } : null;
      const result: any = await lastValueFrom(this.http.post(`/v1.0/groups/switch/${group.id}`, body));
      if (result?.done && result.groupId==group.id) {
        // at this point the server has already changed the current user group in the corresponding session object
        // reload of properties will cause the new group to be delivered
        await this.reset();
        return group;
      } else {
        throw new Error('Unexpected server response: ' + result);
      }
    } else {
      return currentGroup;
    }
  }

  get switch$(): Observable<Group> {
    return this._switch$.asObservable();
  }

  async connect(invitationCode: string): Promise<Group> {
    try {
      // perform server api call to get the group details (incl. language) + host contact information from invitation token
      const { group,
              host,
              relationship
            } = await this.getInvitationCodeInfo(invitationCode)
                          .catch(error => {
                            throw new Error(`Failed to get invitation code info. Error: ${error}`);
                          });
      if (group && host) {
        // Display a confirmation dialog for group switch (or join+switch) if the invitation is not from upline
        // When current user is invited by its upline there is no need for any "connection" dialog - they are already connected
        // (technically in this case the relationship is created on the server as a result of invitation code info request handling)
        const user = this.propertiesService.user;
        const selectableGroups = user.selectableGroups;
        const member = selectableGroups?.find(g => g.id == group.id);
        const uplineInvitation = this.getReferrerId(member) == host.id;
        const promise = uplineInvitation
            ? Promise.resolve(true)
            : lastValueFrom(
                this.dialog.open(GroupSwitchDialogComponent, {
                  data: { group, host, member, connected: !!relationship },
                  panelClass: 'group-switch-dialog',
                  closeOnNavigation: true
                }).afterClosed()
                  .pipe(tap(result => {
                    if (!result) {
                      this.logger.debug(`Connection process has been cancelled by the user [groupId=${group.id}, host=${host}]`);
                    }
                  }))
              );
        if (await promise) {
          this.logger.debug('Start connection establishment', { group, host, member, relationship });
          return await this.switch(group, invitationCode);
        }
      } else {
        throw new Error(`Empty group or/and host for the specified invitation code: ${invitationCode}`);
      }
    } catch(error) {
      this.logger.error(`Connect error: ${error}`);
      throw error;
    }
  }

  async leave(groupId: string): Promise<void> {
    const result = await this.http.delete<any>(`/v1.0/groups/membership/${groupId}`).toPromise();
    if (!result?.error) {
      this.logger.info('leave', result);
      await this.reset();
      return void result;
    } else {
      throw new Error('Unexpected server response: ' + result.error || result);
    }
  }

  async create(groupName: string, rootUser: string, rootUserPwd: string) {
    var group: ConfigurationGroup = {
      name: groupName,
      id: groupName.toLowerCase(),
      language: this.translateService.currentLang,

      viewAs: {
        accounts: []
      },
      topics: [],
      filters: [],
      interests: [],
    };

    const result: any = await lastValueFrom(this.http.post(`/v1.0/groups/create/${group.id}`, group));
    if (result?.done && result.groupId==group.id) {
      // Saved
    }

    this.propertiesService.user.selectableGroups?.push(group);
  }

  async save(group: Group) {
    const result: any = await lastValueFrom(this.http.post(`/v1.0/groups/update/${group.id}`, group));
    if (result?.done && result.groupId==group.id) {
      // Saved
    }
  }

  async addTranslation(language: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (language) {
        try {
          const result: any = await lastValueFrom(this.http.post(`/v1.0/groups/translation`, {
            language,
            // groupId: this.propertiesService.properties.group.id
          }));
          this.propertiesService.reload();
          resolve(result);
        } catch (error) {
          return reject(error);
        }
      } else {
        reject('Invalid language');
      }
    });
  }

  async deleteTranslation(language: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (language) {
        try {
          const result: any = await lastValueFrom(this.http.delete(`/v1.0/groups/translation/${language}`));
          this.propertiesService.reload();
          resolve(result);
        } catch (error) {
          return reject(error);
        }
      } else {
        reject('Invalid language');
      }
    });
  }

  protected getInvitationCodeInfo(invitationToken: string): Promise<{ group: Group, host: Partial<Contact>, relationship: any }> {
    return this.http.get<any>(`/v1.0/auth/invitationToken/info/${invitationToken}`).toPromise()
      .then(result => {
        if (!result?.error) {
          const { group, host, relationship } = result;
          this.logger.info('getInvitationCodeInfo', result);
          return result;
        } else {
          throw new Error('Unexpected server response: ' + result?.error || result);
        }
      });
  }

  protected async reloadLanguage(group: Group): Promise<string> {
    const groupLanguage = group.language;
    if (groupLanguage) {
      // Labels are group-specific - clear all cached translations
      // except the one which will be reloaded. then reload the new language
      const currentLanguage = this.translateService.currentLang;
      this.translateService.langs
        .filter(lang  => lang!=groupLanguage)
        .forEach(lang => this.translateService.resetLang(lang));
      if (currentLanguage==groupLanguage) {
        await this.translateService.reloadLang(groupLanguage);
      } else {
        this.translateService.use(groupLanguage);
      }
    }
    return groupLanguage;
  }

  protected async reset() {
    await new Promise<void>(async (resolve, reject) => {
      this.layoutService.details.getState((state) => {
        if (state.open) {
          this.layoutService.details.close();
        }
        resolve();
      });
    });
    this.store$.dispatch(this.resetGroupAction);
    await this.propertiesService.reload().then(() => this.menuService.reset())
  }

  public getReferrerId(referrerId: ReferrerId): string {
    return referrerId
      ? referrerId.override_referrer_id ||
        referrerId.manual_referrer_id   ||
        referrerId.sync_referrer_id
      : undefined;
  }
}

