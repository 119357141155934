/* projects/shared/src/lib/components/virtual-scroller/virtual-grid.scss */
virtual-scroller {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
:host ::ng-deep .tile-container {
  display: flex;
}
:host.vertical ::ng-deep .tile-container {
  flex-flow: row wrap;
}
:host.vertical.c1 ::ng-deep .tile-container > * {
  width: 100%;
}
:host.vertical.c2 ::ng-deep .tile-container > * {
  width: 50%;
}
:host.vertical.c3 ::ng-deep .tile-container > * {
  width: 33.33%;
}
:host.vertical.c4 ::ng-deep .tile-container > * {
  width: 25%;
}
:host.vertical.c5 ::ng-deep .tile-container > * {
  width: 20%;
}
:host.vertical.c6 ::ng-deep .tile-container > * {
  width: 16.66%;
}
:host.vertical.c7 ::ng-deep .tile-container > * {
  width: 14.28%;
}
:host.vertical.c8 ::ng-deep .tile-container > * {
  width: 12.5%;
}
:host.vertical.c9 ::ng-deep .tile-container > * {
  width: 11.11%;
}
:host.vertical.c10 ::ng-deep .tile-container > * {
  width: 10%;
}
:host.horizontal ::ng-deep .tile-container {
  flex-flow: column wrap;
}
:host.horizontal.c2 ::ng-deep .tile-container > * {
  height: 50%;
}
:host.horizontal.c3 ::ng-deep .tile-container > * {
  height: 33.33%;
}
:host.horizontal.c4 ::ng-deep .tile-container > * {
  height: 25%;
}
:host.horizontal.c5 ::ng-deep .tile-container > * {
  height: 20%;
}
:host.horizontal.c6 ::ng-deep .tile-container > * {
  height: 16.66%;
}
:host.horizontal.c7 ::ng-deep .tile-container > * {
  height: 14.28%;
}
:host.horizontal.c8 ::ng-deep .tile-container > * {
  height: 12.5%;
}
:host.horizontal.c9 ::ng-deep .tile-container > * {
  height: 11.11%;
}
:host.horizontal.c10 ::ng-deep .tile-container > * {
  height: 10%;
}
/*# sourceMappingURL=virtual-grid-6TKH2463.css.map */
