import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  input,
  OnDestroy,
  Renderer2,
  signal,
  ViewChild
} from '@angular/core';
import {Platform} from "core";

@Component({
  selector: 'more-text',
  template: `
    <div class="content"
         [ngClass]="{ 'expanded': expanded() }"
         (onDomModified)="onDomModified()"
         [subtreeDomMutations]="true"
         *ngLet="textRows() as textRows">
      <div #expandedContent class="expanded-content">
        <ng-container *ngFor="let row of textRows">
          <ng-container *ngFor="let element of row">
            <ng-container [ngSwitch]="element.type">
              <div *ngSwitchCase="'text'">{{element.content}}</div>
              <div *ngSwitchCase="'empty'" class="empty"></div>
              <a *ngSwitchCase="'link'" [href]="element.content" target="_BLANK">{{element.content}}</a>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div #collapsedContent class="collapsed-content">
        <ng-container *ngFor="let row of textRows">
          <ng-container *ngFor="let element of row">
            <ng-container [ngSwitch]="element.type">
              <div *ngSwitchCase="'text'">{{element.content}}</div>
              <div *ngSwitchCase="'empty'" class="empty"></div>
              <a *ngSwitchCase="'link'" [href]="element.content" target="_BLANK">{{element.content}}</a>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="more {{align}}">
      <button mat-button (click)="switchExpanded()" *ngIf="showMoreButton()">
        {{( expanded() ? 'actions.showLess' : 'actions.showMore') | translate}}
      </button>
    </div>
  `,
  styleUrls: ['./more.text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MoreTextComponent implements OnDestroy, AfterViewInit {

  text = input<string>('');
  align = input<'left'|'center'|'right'>('left');

  expanded = signal<boolean>(false);
  overflows = signal<boolean>(false);
  showMoreButton = signal<boolean>(false);

  textRows = computed<{ content: string, type: string }[][]>(() => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const text = this.text()??'';
    console.log("TEXT",text,typeof text);
    let textRows = <{ content: string, type: string }[][]>[];
    text.split(/\r?\n/).forEach(row => {
      let rows = [];
      if (row.length === 0) {
        rows.push({ content: '', type: 'empty' });
      } else {
        const matches = [...row.matchAll(urlRegex)];
        if (matches.length > 0) {
          let startIndex = 0;
          matches.forEach(match => {
            if (match.index > startIndex) {
              rows.push({ content: row.substring(startIndex, match.index), type: 'text' });
            }
            const endIndex = row.indexOf(" ", match.index);
            const content = endIndex === -1 ? row.substring(match.index) : row.substring(match.index, endIndex);
            rows.push({ content: content, type: 'link' });
            startIndex = endIndex;
          });
        } else {
          rows.push({ content: row, type: 'text' });
        }
      }
      textRows.push(rows);
    });
    return textRows;
  });

  @ViewChild('expandedContent', { read: ElementRef }) expandedElement: ElementRef;
  @ViewChild('collapsedContent', { read: ElementRef }) collapsedElement: ElementRef;

  constructor(public readonly elementRef: ElementRef,
              protected readonly renderer: Renderer2,
              protected readonly platform: Platform) {
    effect(() => {
      const text = this.text();
      this.expanded.set(false);
      this.overflows.set(false);
    }, { allowSignalWrites: true });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }

  switchExpanded() {
    this.expanded.set(!this.expanded());
    if (this.expanded() && !!this.expandedElement?.nativeElement) {
      this.renderer.setStyle(this.expandedElement.nativeElement.parentElement,"min-height",this.expandedHeight()+"px");
    } else {
      this.renderer.removeStyle(this.expandedElement.nativeElement.parentElement,"min-height");
    }
  }

  onDomModified() {
    if (!!this.expandedElement &&
        !!this.collapsedElement) {
      const expandedHeight  = this.expandedHeight();
      const collapsedHeight = this.collapsedHeight();
      console.log("expandedHeight",expandedHeight,"collapsedHeight",collapsedHeight);
      this.overflows.set(expandedHeight > collapsedHeight);
      this.showMoreButton.set(this.overflows());
    }
  }

  expandedHeight():number {
    return this.expandedElement?.nativeElement.offsetHeight ?? 0;
  }

  collapsedHeight():number {
    return this.collapsedElement?.nativeElement.offsetHeight ?? 0;
  }
}
