import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var FileOpener = /** @class */function (_super) {
  __extends(FileOpener, _super);
  function FileOpener() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  FileOpener.prototype.open = function (filePath, fileMIMEType) {
    return cordova(this, "open", {
      "callbackStyle": "object",
      "successName": "success",
      "errorName": "error"
    }, arguments);
  };
  FileOpener.prototype.uninstall = function (packageId) {
    return cordova(this, "uninstall", {
      "callbackStyle": "object",
      "successName": "success",
      "errorName": "error"
    }, arguments);
  };
  FileOpener.prototype.appIsInstalled = function (packageId) {
    return cordova(this, "appIsInstalled", {
      "callbackStyle": "object",
      "successName": "success",
      "errorName": "error"
    }, arguments);
  };
  FileOpener.prototype.showOpenWithDialog = function (filePath, fileMIMEType) {
    return cordova(this, "showOpenWithDialog", {
      "callbackStyle": "object",
      "successName": "success",
      "errorName": "error"
    }, arguments);
  };
  FileOpener.ɵfac = i0.ɵɵngDeclareFactory({
    minVersion: "12.0.0",
    version: "12.2.16",
    ngImport: i0,
    type: FileOpener,
    deps: null,
    target: i0.ɵɵFactoryTarget.Injectable
  });
  FileOpener.ɵprov = i0.ɵɵngDeclareInjectable({
    minVersion: "12.0.0",
    version: "12.2.16",
    ngImport: i0,
    type: FileOpener
  });
  FileOpener.pluginName = "FileOpener";
  FileOpener.plugin = "cordova-plugin-file-opener2";
  FileOpener.pluginRef = "cordova.plugins.fileOpener2";
  FileOpener.repo = "https://github.com/pwlin/cordova-plugin-file-opener2";
  FileOpener.platforms = ["Android", "iOS", "Windows", "Windows Phone 8"];
  FileOpener = __decorate([], FileOpener);
  return FileOpener;
}(AwesomeCordovaNativePlugin);
export { FileOpener };
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "12.2.16",
  ngImport: i0,
  type: FileOpener,
  decorators: [{
    type: Injectable
  }],
  propDecorators: {
    open: [],
    uninstall: [],
    appIsInstalled: [],
    showOpenWithDialog: []
  }
});
