import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import {EMPTY, Observable} from "rxjs";
import {Logger, Topic} from "core";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {takeUntil} from "rxjs/operators";
import {BasicContainerComponent} from "../../basic-container/basic-container.component";
import {MessageBoxComponent} from "../../dialogs/message-box/message-box.component";

// in future the (rendering) behaviour of this component could be customized using HOC
// see https://github.com/eliraneliassy/bye-bye-ngmodules/tree/master/src/app/timer-example
// https://github.com/ceceradio/cecelia.online/tree/master/src/app

export declare type TopicDisplayInfo = Partial<{
  maxLevel: number,
  selectable: boolean,
  expand: boolean, // undefined -> expand only when selected
  template: TemplateRef<any>
}>;

@Component({
  selector: 'topic-selection-list',
  templateUrl: './topic-selection-list.component.html',
  styleUrls: ['./topic-selection-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicSelectionListComponent extends BasicContainerComponent {

  @Input() topics$: Observable<Topic[]>;
  @Input() topicDisplayInfo: (topic: Topic) => TopicDisplayInfo = (topic) => ({ selectable: true });
  @Input() @HostBinding('class.readonly') readOnly = false;
  @Output() selectionChanged = new EventEmitter<[Topic, string[]]>();

  @ViewChildren('optionPanel') optionPanels: QueryList<ElementRef>;
  @ContentChild("actionsTemplate", { static: true }) actionsTemplate: TemplateRef<any>;
  @ContentChild("badgeTemplate", { static: true }) badgeTemplate: TemplateRef<any>;

  protected topics: Topic[];

  selectionCache: {[key:string]: {
      selected  : boolean,
      options  ?: string[],
      defaults ?: string[],
      single   ?: boolean
    }} = {};
  selection: string[];

  trackByTopic = (topic: Topic) => {
    return topic.id;
  };

  protected logger = new Logger(this.constructor.name).setSilent(true);

  constructor(protected dialog: MatDialog,
              protected translateService: TranslateService,
              protected changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.topics$?.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(topics => {
      this.logger.info('topics update', { topics });
      this.topics = topics;
      this.updateSelectionCache(topics, this.selection || []);
    });
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  updateSelectionCache(topics: Topic[], tags:string[]) {
    this.logger.debug('updateSelectionCache.START', {topics, tags});
    this.selection = [];
    this.selectionCache = {};
    topics?.forEach(topic => {
      const optionTags: string[] = [topic.id, ...(topic.topics || []).map(childTopic => `${topic.id}.${childTopic.id}`)];
      let single      = !!topic.tags && topic.tags.includes('single_select');
      let topicTag    = topic.id;
      let selected    = this.selection.length;
      let defaults    = topic.defaults || [];
      optionTags.forEach(optionTag => {
        this.selectionCache[optionTag] = {
          selected: !single || optionTag==topicTag
            ? tags.includes(optionTag)
            : selected < this.selection.length - 1 // -1 for main/root topic
              ? false
              : tags.includes(optionTag)
        };
        if (!this.selection.includes(optionTag) &&
             this.selectionCache[optionTag].selected) {
          this.selection.push(optionTag);
        }
      });
      this.selectionCache[topicTag] = {
        selected: optionTags.length ? selected<this.selection.length : tags.includes(topicTag),
        options:  optionTags,
        defaults: optionTags.filter(id => defaults.includes(id)),
        single:   single
      };
      // if (this.selectionCache[topicTag].selected) {
      //   this.selection.push(topicTag);
      // }
    });
    this.selection = this.selection.length ?  this.selection : tags;
    this.logger.debug('updateSelectionCache.COMPLETE', { selection: this.selection });
  }

  @Input()
  set selectedTags(tags: string[]) {
    this.logger.debug('selectedTags', tags)
    this.updateSelectionCache(this.topics,tags || []);
    this.changeDetector.detectChanges();
  }

  get selectedTags(): string[] {
    return this.selection;
  }

  selectedTopicTags(topicId: string) {
    const topicTag = topicId;
    const prefix   = `${topicTag}.`;
    const selectedTopicTags = this.selection.filter(tag => tag?.startsWith(prefix));
    // this.logger.debug('selectedTopicTags', { topicId, selectedTopicTags });
    return selectedTopicTags;
  }

  onTapTopic(topicId: string, selectable: boolean) {
    this.onTapOption(topicId,undefined, selectable);
  }

  onTapOption(topicId: string, optionId: string, selectable: boolean) {
    this.logger.debug('onTapOption. START', { topicId, optionId });
    //console.log("onTapOption.topicId",topicId,"optionId",optionId);
    if (!this.readOnly) {
      /*const optionPanel = this.optionPanels.find((item) =>
        index == item.nativeElement.dataset.index
      );*/
      let topicTag  = topicId;
      let optionTag = optionId ? `${topicTag}.${optionId}` : undefined;
      let info      = this.selectionCache[topicTag];
      if (!!info && selectable) {
        //console.log("onTapOption.topicId",topicId,"optionId",optionId,"info",info);
        let options = info.options || [topicTag];
        let prefix  = `${topicTag}.`;
        let option  = optionTag ? this.selectionCache[optionTag] : undefined;
        this.selection = this.selection.filter(tag => !!tag && tag != topicTag && !tag.startsWith(prefix));
        let getSelectedOptions: (tags: string[]) => string[] = (tags) => {
          return tags.filter(tag => !!this.selectionCache[tag] && this.selectionCache[tag].selected);
        };
        let setSelectedOptions: (tags: string[], select: string[]) => number = (tags, selected) => {
          return tags.filter(tag => {
            let current = this.selectionCache[tag];
            return !!current && (current.selected = selected.includes(tag));
          }).length;
        };
        if (!!option) {
          if (info.single && option.selected) {
            setSelectedOptions(options, []);
            info.selected = false;
          } else if (info.single) {
            setSelectedOptions(options, [optionTag]);
            info.selected = true;
            this.selection.push(topicTag);
            this.selection.push(optionTag);
          } else {
            let selected = getSelectedOptions(options).filter(tag => tag != optionTag);
            option.selected = !option.selected;
            if (option.selected) {
              info.selected = true;
              this.selection.push(topicTag);
              this.selection.push(optionTag);
              this.selection = this.selection.concat(selected);
            } else if (selected.length > 0) {
              info.selected = true;
              this.selection.push(topicTag);
              this.selection = this.selection.concat(selected);
            } else {
              info.selected = false;
            }
          }
        } else if (info.selected) {
          info.selected = false;
          setSelectedOptions(options,[]);
        } else {
          info.selected = true;
          setSelectedOptions(options,info.defaults || [topicTag]);
          this.selection.push(topicTag);
          this.selection = this.selection.concat(getSelectedOptions(options));
        }
      }
      const event: any = [this.topics.find(topic => topic.id==topicId), [...this.selection]];
      this.logger.debug('onTapOption.COMPLETE', { topicId, optionId, event, 'selectionCache': this.selectionCache });
      this.selectionChanged.emit(event);
    }
  }

  isTopicSelected(topicId: string): boolean {
    let info = this.selectionCache[topicId];
    return !!info && info.selected;
  }

  isOptionSelected(topicId: string, optionId: string): boolean {
    return this.isTopicSelected(`${topicId}.${optionId}`);
  }

  help(topic: Topic): Observable<string> {
    return topic?.help ? this.translateService.stream(topic.help) : EMPTY;
  }

  showHelp(help: string, event: Event) {
    event.stopPropagation();
    if (help) {
      this.dialog.open(MessageBoxComponent, { data: { message: help }});
    }
  }
}
