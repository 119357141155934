import {Pipe, PipeTransform} from '@angular/core';
import {SessionTokenService} from "./session-token.service";

@Pipe({ name: 'sessionLink' })
export class SessionLinkPipe implements PipeTransform {

  constructor(private sessionTokenService: SessionTokenService) {}

  transform(link: string) {
    return this.sessionTokenService.rewrite(link);
  }
}
