import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {FilterService, FilterTagEvent} from 'filter';
import {MediaType} from '../../store/models';
import {MediaDetailsShareDataService} from '../../service/media-details-share-data.service';
import {PropertiesService} from 'properties';
import {combineLatest, takeUntil} from 'rxjs';
import {ConfirmDialogComponent} from 'shared';
import {MediaDetailsSectionComponent} from '../media-details/media-details-section.component';

@Component({
  selector: 'media-details-settings',
  templateUrl: './media-details-settings.component.html',
  styleUrls: ['./media-details-settings.component.scss']
})
export class MediaDetailsSettingsComponent extends MediaDetailsSectionComponent {

  MediaType = MediaType;
  mediaTags: string[] = [];
  editInviteIntro = false;
  editDetailsVisibility = false;
  mediaForm: FormGroup;
  isVisibilityEditor: boolean;
  isLockEditor: boolean;
  isPriorityEditor: boolean;
  isLeader: boolean;

  constructor(
    private filterService: FilterService,
    protected mediaDetailsShareDataService: MediaDetailsShareDataService,
    private dialog: MatDialog,
    private propertiesService: PropertiesService
  ) {
    super(mediaDetailsShareDataService);
  }

  ngOnInit(): void {
    combineLatest([
      this.mediaDetailsShareDataService.getEditMode$,
      this.mediaDetailsShareDataService.getMediaTags$,
      this.mediaDetailsShareDataService.getMediaForm$,
      this.mediaDetailsShareDataService.getOptions$,
      this.propertiesService.user$
    ])
      .pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(
        ([editMode, mediaTags, mediaForm, options, user]) => {
          this.editInviteIntro = !!options.editInviteIntro;
          this.mediaTags = mediaTags;
          this.editMode = editMode;
          this.mediaForm = mediaForm;

          this.isVisibilityEditor = user.isAdmin || (user.app?.tags || []).includes('visibilityEditor');
          this.isLockEditor = user.isAdmin || (user.app?.tags || []).includes('lockEditor');
          this.isPriorityEditor = user.isAdmin || (user.app?.tags || []).includes('priorityEditor');
          this.isLeader = user.isLeader || user.isAdmin;

          this._isHidden.emit(!this.editMode);
          /*
          if (this.mediaTags.includes('invite.intro') ||
            (options.mode != 'basic' && this.editMode)) {
            this._isHidden.emit(false);
          } else {
            this._isHidden.emit(true);
          }*/
        });
  }

  onProtectedChange(event) {
    if (event.checked) {
      this.dialog.open(
        ConfirmDialogComponent,
        { data: { label: "media.confirmProtected" }, closeOnNavigation: true }
      ).afterClosed().subscribe(result => {
        if (!result) {
          this.mediaForm.get('protected').setValue(!event.checked);
        }
      });
    }
  }

  onTapFilter(event: FilterTagEvent) {
    this.toggleFilter(event.filter.id);
  }

  toggleFilter(filterId: string) {
    const newTags = this.filterService.setFilter(this.mediaTags, filterId, false);
    this.mediaDetailsShareDataService.setMediaTags(newTags);
  }
}
