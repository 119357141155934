import {AfterContentInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';

/** michael sigmund ... a support....
 *
 * add following attributes to any component element you want to survail:
 * <div (onAttach)="ngOnAttach()" (onDetach)="ngOnDetach()">
 *
 * ngOnAttach is fired when the component is already attached to the dom
 * tree or at the moment it is attached.
 *
 * ngOnDetach is fired when the component is detached. but not if
 * the component is initially detached.
 */
@Directive({
  selector: '[onAttach],[onDetach]'
})
export class DomDirective implements AfterContentInit, OnDestroy {
  @Output() onAttach: EventEmitter<ElementRef> = new EventEmitter();
  @Output() onDetach: EventEmitter<ElementRef> = new EventEmitter();
  protected mutationObserver : MutationObserver;
  protected parentNodes : Set<Node> = new Set();

  public constructor(protected elementRef: ElementRef) {
  }

  public ngOnDestroy(): void {
    this.mutationObserver.disconnect();
    if (this.parentNodes.has(window.document.body)) {
      this.onDetach.emit(this.elementRef);
    }
    this.parentNodes.clear();
  }

  public ngAfterContentInit(): void {
    this.updateParentNodes();
    if (this.parentNodes.has(window.document.body)) {
      this.onAttach.emit(this.elementRef);
    }
    this.mutationObserver = new MutationObserver((mutations : MutationRecord[], observer : MutationObserver) => {
      this.onEvent(mutations,observer);
    });
    this.mutationObserver.observe(window.document.body,{
      subtree: true,
      childList: true,
    });
  }

  protected updateParentNodes() : Set<Node> {
    //console.info("\tDOM!! PARENT UPDATE...");
    this.parentNodes.clear();
    for (let node : Node = this.elementRef.nativeElement; node && node!=window.document; node = node.parentElement) {
      //DomDirective.printNode("\tDOM!! PARENT ", node);
      this.parentNodes.add(node);
    }
    //console.info("\tDOM!! PARENT UPDATED! size:"+this.parentNodes.size);
    return this.parentNodes;
  }

  protected onEvent(mutations : MutationRecord[], observer : MutationObserver): void {
    //let time = Date.now();
    mutations.forEach(mutation => {
      if (mutation.addedNodes) {
        for (var i=mutation.addedNodes.length-1; i>=0; i--) {
          let node = mutation.addedNodes.item(i);
          if (this.parentNodes.has(node)) {
            //DomDirective.printNode("\tDOM!! ADDED ", node);
            this.updateParentNodes();
            this.onAttach.emit(this.elementRef);
          }
        }
      }
      if (mutation.removedNodes) {
        for (var i=mutation.removedNodes.length-1; i>=0; i--) {
          let node = mutation.removedNodes.item(i);
          if (this.parentNodes.has(node)) {
            //DomDirective.printNode("\tDOM!! REMOVED ", node);
            this.updateParentNodes();
            this.onDetach.emit(this.elementRef);
          }
        }
      }
    });
    //console.log("DOM.onEvent time",(Date.now()-time));
  }

  public static printNode(prefix : string, node : Node): void {
    let text = this.renderNode(node);
    if (text) {
      console.info(prefix+text);
    } else {
      console.info(prefix+"<null>");
    }
  }

  public static renderNode(node : Node) : string {
    if (node) {
      let text = '<'+node.nodeName;
      if (node instanceof Element) {
        let attributes = node.attributes;
        for (var i=0; i<attributes.length; i++) {
          var item = attributes.item(i);
          text += ' '+item.name;
          if (item.value) {
            text += '="'+item.value+'"';
          }
        }
      }
      text += '>';
      return text;
    } else {
      return null;
    }
  }
}
