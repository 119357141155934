import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {Logger} from 'core';
import {takeUntil} from "rxjs";
import {Media} from '../../store/models';
import {AccordionSectionComponent, BasicComponent} from 'shared';
import {MediaDetailsShareDataService} from '../../service/media-details-share-data.service';

@Component({
  selector: 'basic-container',
  template: ``,
  styles: []
})
export abstract class MediaDetailsSectionComponent extends BasicComponent implements AccordionSectionComponent {

  _context: any;
  protected _isHidden: EventEmitter<boolean> = new EventEmitter<boolean>();
  buttonsTemplate = new EventEmitter<TemplateRef<any>>();
  actionsTemplate = new EventEmitter<TemplateRef<any>>();

  protected logger = new Logger(this.constructor.name);
  media: Media;
  editMode: boolean = false;

  protected constructor(protected mediaDetailsShareDataService: MediaDetailsShareDataService) {
    super();
    this.mediaDetailsShareDataService.getMedia$.pipe(takeUntil(this.onDestroy$)).subscribe(media => {
      this.media = media;
    });
  }

  @Input()
  set context(context: any) {
    this._context = context;
  }

  get context() {
    return this._context;
  }

  @Output()
  get isHidden() {
    return this._isHidden;
  }
}
