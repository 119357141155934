import {Pipe, PipeTransform} from '@angular/core';
import {DateAdapter} from "@angular/material/core";
import moment from "moment";

@Pipe({
  name: 'date',
  pure: false // the pipe should be invoked on each change-detection cycle, even if the arguments have not changed
})
export class DatePipe implements PipeTransform {

  constructor(
    protected dateAdapter: DateAdapter<moment.Moment>,
  ) {}

  transform(date: any, format = 'LL'): any {
    // format trough dateAdapter as it knows the current locale
    return this.dateAdapter.format(moment(date), format);
  }
}
