import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ContentChild,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  Output,
  QueryList,
  signal,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ScreenOrientation} from "@ionic-native/screen-orientation/ngx";
import {StatusBar} from "@ionic-native/status-bar/ngx";
import {auditTime, buffer, filter, map, switchMap, takeUntil} from "rxjs/operators";
import {EMPTY, lastValueFrom, merge, Observable, of, ReplaySubject, Subscription, tap} from "rxjs";
import {ImageCroppedEvent} from "ngx-image-cropper";
import {ENVIRONMENT, getCurrentThemeMode, isThemeModePure, Logger, Platform} from "core";
import {ACTION_HANDLER, ActionDirective, ActionHandler, ImageCropperOverlayService} from "shared";
import {PropertiesService} from "properties";
import {UploadService} from "upload";
import {Media, MediaAction, MediaType} from "../../../../store/models";
import {MediaService} from "../../../../service/media.service";
import {VideoViewerComponent} from "../../viewers/video/video-viewer.component";
import {ImageViewerComponent} from "../../viewers/image/image-viewer.component";
import {PdfViewerComponent} from "../../viewers/pdf/pdf-viewer.component";
import {SurveyViewerComponent} from "../../viewers/survey/survey-viewer.component";
import {InteractivePresentationViewerComponent} from "../../viewers/iap/interactive-presentation-viewer.component";
import {MediaPlayer} from "../../media-player";
import {MediaViewer} from "../../media-viewer";
import {MediaViewerContainer} from "../../media-viewer-container";
import {cloneDeep} from "lodash";

@Component({
  selector: 'media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.scss'],
  animations: [
    trigger('animation', [
      state('display', style({ opacity: 1, pointerEvents: 'auto' })),
      state('hide',  style({ opacity: 0, pointerEvents: 'none' })),
      // transition('display => *', animate('{{time}}')),
      transition('* => *', animate('{{time}}')),
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaViewerComponent extends MediaViewer implements MediaViewerContainer {

  @Input() set entities(entities: Media[]) {
    this.media = entities?.length ? entities[0] : undefined;
  };

  set size(size: number) {}

  set mediaStream(provider: (index: any) => Observable<Media>) {}

  @Input()
  set media(media: Media) {
    // ensure autoplay is initialized before setMedia call
    this.initialized.subscribe(() => super.media = media )
  };

  // NOTE: The following line is exact copy of the base MediaViewer.media accessor
  // but it is required because without it this.media will always return undefined.
  // The reason for this behavior is unclear.
  // could be related with the applied @Input() annotation to the set media(Media);
  get media(): Media { return this._media; }

  @Input()  index     = 0;
  @Input()  autoplay  = true;
  @Input() @HostBinding('class.display-toolbar') hasToolbar = true;
  @Input() toolbarHandler: (display: boolean) => void;

  @Output() changed   = new EventEmitter<{media: Media, index?: number}>();
  @Output() completed = new EventEmitter<{media: Media, index?: number}>();
  @Output() actionIds: Observable<{media: Media, index?: number, actionIds: string[]}>;
  protected actionIdsEmitter = new EventEmitter<string[]>();
  @Output() action   = new EventEmitter<{media: Media, index?: number, action: MediaAction}>();
  @Output() closed    = new EventEmitter<void>();

  @ContentChild ('actionsTemplate',  { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild ('closeActionTemplate', { static: true }) closeActionTemplate : TemplateRef<any>;
  @ViewChild ('snapshotActionTemplate', { static: true }) snapshotActionTemplate : TemplateRef<any>;
  @ViewChild ('downloadActionTemplate', { static: true }) downloadActionTemplate : TemplateRef<any>;
  @ViewChild ('viewerContainer', { read: ViewContainerRef, static: true }) viewerContainer: ViewContainerRef;
  @ViewChildren (ActionDirective) mediaActionElements : QueryList<ActionDirective>;

  protected initialized = new ReplaySubject<void>(1);
  protected viewerRef: ComponentRef<MediaViewer>;
  visibleActionIds: string[] = [];
  defaultActions = new ReplaySubject<MediaAction[]>(1);
  protected defaultActionsSubscription: Subscription;
  protected allowedDefaultActionIds: string[] = [];
  protected pendingDisplayActionRequests: {[actionId: string]: boolean} = {};
  trackByMediaAction = (index: number, mediaAction: MediaAction) => {
    return `${mediaAction.id}`;
  }

  protected screenOrientationType: string;
  protected takingSnapshot = false;

  viewerMedia: Media;

  protected static mediaTypeComponents = {};
  static initialize() {
    MediaViewerComponent.mediaTypeComponents[MediaType.audio]  = VideoViewerComponent;
    MediaViewerComponent.mediaTypeComponents[MediaType.video]  = VideoViewerComponent;
    MediaViewerComponent.mediaTypeComponents[MediaType.image]  = ImageViewerComponent;
    MediaViewerComponent.mediaTypeComponents[MediaType.binary] = PdfViewerComponent;
    MediaViewerComponent.mediaTypeComponents[MediaType.iap]    = InteractivePresentationViewerComponent;
    MediaViewerComponent.mediaTypeComponents[MediaType.survey] = SurveyViewerComponent;
  };

  toolbarDisplayed = signal(false);
  displayToolbar = (display: boolean)=>  {
    console.log('displayToolbar', display, this.hasToolbar);
    if (this.hasToolbar) {
        this.toolbarDisplayed.set(display);
    }
    this.toolbarHandler?.(display);
  }

  displayAction = (actionId: string, display?: boolean) => {
    // this.logger.debug('displayAction', { actionId, display });
    if (!this.mediaPromise) {
      this.defaultActions.subscribe((defaultActions) => {
        if (this.viewerMedia.actions.find(action => action.id == actionId) &&
          (!defaultActions.find(action => action.id == actionId) ||
            this.allowedDefaultActionIds.includes(actionId))) {
          // if (this.mediaActionElements) {
          //   this.mediaActionElements.forEach((element) => {
          //     const action = element.action;
          const visibleActionIds = this.visibleActionIds.slice() || [];
          const index = visibleActionIds.indexOf(actionId);
          if (index === -1 && display) {
            visibleActionIds.push(actionId);
          } else if (index !== -1 && !display) {
            visibleActionIds.splice(index, 1);
          }
          this.updateVisibleActions(visibleActionIds);
          //   });
          // }
        }
      })
    } else if (this.pendingDisplayActionRequests[actionId] != display) {
      this.pendingDisplayActionRequests[actionId] = display;
    }
  };

  protected mediaPromise: Promise<Media>;
  protected logger = new Logger('MediaViewerComponent');

  constructor(private resolver: ComponentFactoryResolver,
              private screenOrientation: ScreenOrientation,
              private statusBar: StatusBar,
              private platform: Platform,
              private mediaService : MediaService,
              private imageCropperOverlayService: ImageCropperOverlayService,
              private propertiesService: PropertiesService,
              private changeDetector: ChangeDetectorRef,
              private uploadService: UploadService,
              @Inject(ACTION_HANDLER) private actionHandler: ActionHandler,
              @Inject(ENVIRONMENT) private environment: any) {
    super();
    this.actionIds = this.actionIdsEmitter.pipe(
        tap(actionId => this.logger.debug(actionId)),
        takeUntil(this.onDestroy$),
        tap((actionIds) => this.logger.debug('ACTIONS UPDATE', actionIds)),
        buffer(this.actionIdsEmitter.pipe(auditTime(10))),
        map((buffer) => {
          this.logger.debug('ACTIONS BUFFER', buffer);
          const actionIds = buffer.length > 0 ? buffer[buffer.length-1] : [];
          return {
            media: this.viewerMedia,
            index: this.index,
            actionIds: actionIds
          }
        }),
        tap(actionIds => this.logger.debug('ACTIONS EMITTED', actionIds))
      );
  }

  ngOnInit() {
    super.ngOnInit();
    this.initialized.next();
    this.initialized.complete();
    this.toolbarDisplayed.set(this.hasToolbar)
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.defaultActionsSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.mediaActionElements.changes
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((changes) => {
        this.logger.debug('MediaActionElements > CHANGES', changes);
      });
    this.defaultActions.next([
      { id: 'download', template: this.downloadActionTemplate },
      { id: 'snapshot', template: this.snapshotActionTemplate },
      { id: 'close',    template: this.closeActionTemplate }
    ]);
    this.defaultActions.complete();
  }

  ngOnAttach() {
    super.ngOnAttach();
    if (this.platform.is('hybrid')) {
      this.screenOrientationType = this.screenOrientation.type;
      this.statusBar.hide();
      if (!this.platform.is('tablet')) {
        this.screenOrientation.unlock();
      }
    }
  }

  ngOnDetach() {
    super.ngOnDetach();
    if (this.platform.is('hybrid')) {
      this.statusBar.show();
      if (getCurrentThemeMode()=='dark' || !isThemeModePure()) {
        this.statusBar.styleBlackOpaque();
      } else {
        this.statusBar.styleLightContent();
      }
      if (this.screenOrientationType && !this.platform.is('tablet')) {
        this.screenOrientation
          .lock(this.screenOrientationType)
          .catch((error) => this.logger.error(`Failed to lock screen orientation to ${this.screenOrientationType}`, error));
      }
    }
  }

  setMedia(media: Media): Promise<Media> {
    this.logger.debug('setMedia', {
      media,
      review: media?.review?.state,
      previousReview: this.media?.review?.state
    });
    if (!this.mediaPromise) {
      let mediaPromise: Promise<Media>;
      const component = !!media ? MediaViewerComponent.mediaTypeComponents[media.mediaType] : undefined;
      if (component) {
        if (!this.viewerRef || this.media.mediaType != media.mediaType) {
          this.viewerContainer.clear();
          const factory = this.resolver.resolveComponentFactory<MediaViewer>(component);
          this.viewerRef = this.viewerContainer.createComponent<MediaViewer>(factory);
          const viewer = this.viewerRef.instance;
          viewer.displayToolbar = this.displayToolbar;
          viewer.displayAction  = this.displayAction;
          viewer.onAction       = this.onAction.bind(this);
          viewer.defaultActions = this.defaultActions.asObservable();
          viewer.isMediaPlayer() && (viewer.autoplay = this.autoplay);
          viewer.isMediaEditor() && (viewer.onMediaChange((media) => this.changed.emit({media})));
          this.defaultActionsSubscription?.unsubscribe();
        }
        mediaPromise = this.viewerRef.instance
          .setMedia(media)
          .then(media => lastValueFrom(this.defaultActions))
          .then(defaultActions => {
            this.logger.debug('defaultActions', defaultActions);
            defaultActions = defaultActions || [];
            const viewerMedia = cloneDeep(media);
            viewerMedia.actions = viewerMedia.actions
                ? [...viewerMedia.actions, ...defaultActions]
                : defaultActions;
            return [media, viewerMedia, defaultActions];
          })
          .then(([media, viewerMedia, defaultActions]: [Media, Media, MediaAction[]]) => {
            this._media      = media;
            this.viewerMedia = viewerMedia;
            // at this moment it is too early to invoke updateVisibleActions
            // because the client/container does not have access to the final media yet
            // consider executing updateVisibleActions immediately after the returned Promise is fulfilled
            if (!this.defaultActionsSubscription) {
              this.defaultActionsSubscription =
                merge(
                  of({ id: 'close', display: true }),
                  this.viewerRef.instance.canTakeSnapshot.pipe(
                    switchMap(canTakeSnapshot =>
                      this.propertiesService.properties$.pipe(
                        map(properties => {
                          const user  = properties.user;
                          const group = properties.group;
                          return {
                            id: "snapshot",
                            display: canTakeSnapshot &&
                              (viewerMedia.author?.id == user.id
                                || viewerMedia['authorId'] == user.id
                                || (user.isLeader && !!user.groups?.[group.id]?.tags?.includes('snapshot')))
                          };
                        })
                      )
                  )),
                  // download actionis disabled for android and ios apps because the current impl relies on
                  // download property of anchor element which seems not supported by the webviews
                  // we need to address this issue in the next releases. There is a cordova plugin which can maybe help:
                  // https://github.com/apache/cordova-plugin-file-transfer#example-1
                  !this.platform.is('hybrid')
                    ? this.viewerRef.instance.canDownload.pipe(
                      map(canDownload => ({ id: "download", display: canDownload }))
                    )
                    : EMPTY
                )
                .pipe(
                  takeUntil(this.onDestroy$),
                  filter(() => !!viewerMedia)
                )
                .subscribe((action) => {
                  let actionIds = [...this.visibleActionIds];
                  if (action.display) {
                    if (!this.allowedDefaultActionIds.includes(action.id)) {
                      this.allowedDefaultActionIds.push(action.id);
                    }
                    const index = actionIds.findIndex(actionId => actionId == action.id);
                    if (index >= 0) {
                      actionIds.splice(index, 1, action.id);
                    } else {
                      actionIds.splice(viewerMedia.actions.length - defaultActions.length, 0, action.id);
                    }
                  } else {
                    actionIds = actionIds.filter(actionId => actionId != action.id);
                    this.allowedDefaultActionIds = this.allowedDefaultActionIds.filter(actionId => actionId != action.id);
                  }
                  this.logger.debug('setMedia->updateVisibleActions', { action, actionIds });
                  this.updateVisibleActions(actionIds);
                });
            }
            // else {
            //   this.updateVisibleActions(media.actions.map(action => action.id));
            // }
            return viewerMedia;
          })
          .then(viewerMedia => {
            this.changeDetector.detectChanges();
            this.changed.emit({ media: viewerMedia });
            return viewerMedia;
          });
      } else {
        mediaPromise = Promise.reject(`Invalid media or media type [media=${media}]`);
      }
      this.mediaPromise = mediaPromise;
      mediaPromise.catch(() => {}).then(() => {
        this.mediaPromise = undefined;
        Object.entries(this.pendingDisplayActionRequests).forEach(([actionId, display]) => {
          // this.logger.debug('setMedia.pendingDisplayActionRequests -> displayAction', {actionId, display});
          this.displayAction(actionId, display);
          delete this.pendingDisplayActionRequests[actionId];
        })
      });
    } else {
        this.mediaPromise = this.mediaPromise.finally(() => this.setMedia(media));
    }
    return this.mediaPromise;
  }

  get viewer(): MediaViewer {
    return this.viewerRef?.instance;
  }

  isMediaPlayer(): this is MediaPlayer {
    return this.viewer?.isMediaPlayer();
  }

  get player(): MediaPlayer {
    return this.isMediaPlayer() ? this.viewer as MediaPlayer : null;
  }

  play() {
    this.isMediaPlayer() && (this.viewer as MediaPlayer).play();
  }

  pause() {
    this.isMediaPlayer() && (this.viewer as MediaPlayer).pause();
  }

  get playing() {
    return this.isMediaPlayer() && (this.viewer as MediaPlayer).playing;
  }

  isControlEvent(event: Event) {
    const player: MediaPlayer = this.player;
    if (player && typeof player.isControlEvent=='function') {
      return player.isControlEvent(event);
    }
    return false;
  }

  onAction = async (action: MediaAction): Promise<any> => {
    this.logger.debug('onAction', {media: this.media, action});
    if (action.id == 'close') {
      this.onClose();
      return Promise.resolve(true);
    } else {
      const result = await this.actionHandler.handleAction(action);
      if (!result) {
        this.action.emit({media: this.media, action});
      }
      return result;
    }
  }

  onTakeSnapshot() {
    if (this.viewerRef.instance instanceof VideoViewerComponent) {
      const viewer = this.viewerRef.instance;
      const paused = viewer.paused();
      !paused && viewer.pause();
      this.takingSnapshot = true;
      viewer.takeSnapshot()
        // NOTE: Trying to convert the snapshot here from File to base64 encoded string fails for cordova apps
        // However the conversion can be done immediately after retrieving the blob from canvas - see VideoComponent.takeSnapshot()
        // .then((file) => {
        //   this.logger.debug('MEDIA COVER SNAPSHOT', file);
        //   const toBase64 = file => new Promise<string>((resolve, reject) => {
        //     const reader = new FileReader();
        //     reader.onloadstart  = (event) => this.logger.debug('MEDIA COVER SNAPSHOT -> base64 onloadstart', event);
        //     reader.onprogress   = (event) => this.logger.debug('MEDIA COVER SNAPSHOT -> base64 onloadprogress', event);
        //     reader.onabort      = (event) => this.logger.debug('MEDIA COVER SNAPSHOT -> base64 onabort', event);
        //     reader.onloadend    = (event) => resolve(reader.result as string);
        //     reader.onerror      = (error) => reject(error);
        //     reader.readAsDataURL(file);
        //   });
        //   return toBase64(file);
        // })
        .then((base64) => { // snapshot base64 encoded png image
          this.logger.debug('MEDIA COVER SNAPSHOT BASE64', base64);
          return new Promise<void>((resolve, reject) => {
            const image = new Image();
            image.src = base64;
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              let cropped = false;
              const overlayRef = this.imageCropperOverlayService.open({
                data: {
                  // imageURL: file, // does not work. bug in ngx-image-cropper?
                  imageBase64: base64,
                  aspectRatio: width / height,
                  maintainAspectRatio: false,
                  canChangeMaintainAspectRatio: true,
                  format: "jpeg",
                  header: 'media.actions.uploadCover',
                  onCrop: (imageCroppedEvent: ImageCroppedEvent) => {
                    cropped = true;
                    fetch(imageCroppedEvent.base64)
                      .then(response => response.blob())
                      .then((blob) => {
                        this.logger.debug('MEDIA COVER CROPPED', blob);
                        const file = new File(
                          [ blob ],
                          `snapshot_${this.media.id}.png`, // we use the default cropper output format -> png
                          { lastModified: new Date().getTime() }
                        );
                        return this.uploadCover(blob);
                      })
                      .then(() => {
                          this.logger.debug('MEDIA COVER UPLOADED');
                          this.mediaService.loadRequest();
                          resolve();
                      })
                      .catch((error) => reject(error))
                      .then(() => overlayRef.close())
                  }
                }
              });
              overlayRef.onClose.subscribe(() => {
                !cropped && resolve();
                !paused && viewer.play();
              })
            };
            image.onerror = (error) => reject(error);
          })
        })
        .catch(error => this.logger.error('MEDIA COVER', error))
        .then(() => this.takingSnapshot = false);
    }
  }

  onClose() {
    if (this.viewerRef) {
      this.viewerRef.instance
        .beforeClose()
        .catch((error) => this.logger.error('Failed to properly close MediaViewer', error))
        .then(() => this.closed.emit());
    } else {
      this.closed.emit();
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'Escape' && !this.takingSnapshot) {
      event.stopPropagation();
      event.preventDefault();
      this.onClose();
    }
  }

  uploadCover(file: Blob | File): Promise<any> {
    const endpoint = `${this.environment.serverUrl}/v1.0/media/upload/cover/${this.media.id}`;
    return this.uploadService.uploadFile(file, endpoint).then(uploadRef => {
      return uploadRef.upload().then((result) => {
        uploadRef.release();
        this.logger.info('uploadCover completed', result);
        if (result.successful.length == 1) {
          return (result.successful[0] as any).response || {};
        } else {
          throw new Error('uploadCover failed');
        }
      });
    });
  }

  updateVisibleActions(visibleActionIds: string[]) {
    this.logger.debug('updateVisibleActions', visibleActionIds);
    if (visibleActionIds?.length!=this.visibleActionIds?.length ||
       !visibleActionIds?.every(id => this.visibleActionIds.includes(id))) {
      this.visibleActionIds = visibleActionIds;
      this.actionIdsEmitter.emit(visibleActionIds);
      this.changeDetector.detectChanges();
    }
  }

  animation(actionId: string) {
    const state = this.visibleActionIds.includes(actionId) ? 'display' : 'hide';
    const time  = state == 'display' ? '0.5' : '1';
    //this.logger.debug('animation', actionId, state);
    return { value: state, params:{ time: `${time}s` }};
  }

  get hideActionBackground(): boolean {
    const hide =  this.media && !!this.mediaService.getYoutubeMediaId(this.media);
    // this.logger.debug("hideActionBackground()", hide);
    return hide;
  }

  onDownload(download: HTMLElement) {
    this.mediaService
      .downloadMedia(this.media, download)
      .catch(error => this.logger.error('Failed to download media', error));
  }
}

MediaViewerComponent.initialize();
