import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, lastValueFrom, Observable, ReplaySubject, Subject, take, tap} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {Platform} from "../platform/platform.service";
import {ENVIRONMENT} from "../config";
import {NOTIFICATION_APP_ID, NOTIFICATION_SAFARI_WEB_ID} from "../app/app.provider";
import {Logger} from "../logger.service";
import {MatDialog} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {WINDOW} from "../core.token";
import {DeviceState} from "./model";
import {OSPermissionState} from "@ionic-native/onesignal";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  static readonly ONESIGNAL_SDK_SRC    = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js';

  protected initialized = false;
  initialized$ = new ReplaySubject<any>(1);

  protected _nativePermission$ = new ReplaySubject<any>(1);
  protected _permission$       = new ReplaySubject<any>(1);
  protected _subscription$     = new ReplaySubject<any>(1);
  protected _enabled$          = new ReplaySubject<boolean>(1);
  protected _prompting$        = new BehaviorSubject<boolean>(false);
  protected _opened$           = new Subject<any>();

  protected users = {};
  protected oneSignal: any;

  protected logger = new Logger('NotificationService');

  constructor(private platform: Platform,
              // private oneSignal: OneSignal,
              private translateService: TranslateService,
              private httpClient: HttpClient,
              private dialog: MatDialog,
              @Inject(ENVIRONMENT) private readonly environment: any,
              @Inject(WINDOW) private readonly window: Window,
              @Inject(NOTIFICATION_APP_ID) private readonly notificationAppId: string,
              @Inject(NOTIFICATION_SAFARI_WEB_ID) private readonly notificationSafariWebId: string) {
    //this.logger.info("NotificationService.ctor()", this.translateService ? this.translateService.instant('notification.prompt.accept') : 'translateService is null');
  }

  public ensureInitialized(): Observable<any> {
    if (!this.initialized) {
      this.initialize()
        .then((oneSignal) => this.initialized$.next(oneSignal))
        .catch((error) => this.initialized$.error(error))
        .then(() => {
          this.initialized = true;
          this.initialized$.complete();
        });
    }
    return this.initialized$;
  }

  protected async initialize(): Promise<any> {
    let promise;
    if (this.platform.is('hybrid')) {
      promise = this.initializeNative();
    } else {
      // The following line forces translation load from server!
      // At this moment the TranslateService should have been already initialized and translations loaded but sometimes the keys cannot be resolved.
      // As a temp solution an explicit currentLang translations reload is triggered to ensure that subsequent invocations of translateService.instant() will succeed.
      // await this.translateService.currentLoader.getTranslation(this.translateService.currentLang).toPromise();
      // UPDATE: instead of instant() we use get() (see initializeWeb) to ensure the current lang translations are loaded.
      // because the app may have to switch to the default user lang there could be an outstanding translations load request
      // which causes instant() api to return the key itself not the label.
      promise = this.initializeWeb();
    }
    return promise.then((oneSignal) => {
      combineLatest([
        this._nativePermission$,
        this._permission$,
        this._subscription$
      ]).subscribe((state) => {
        this.logger.info('STATE', state);
        const [nativePermission, permission, subscription] = [...state];
        this._enabled$.next(nativePermission && permission && subscription.subscribed && !!subscription.userId)
      });
      return oneSignal;
    });
  }

  protected async loadScript(src: string, id: string): Promise<void> {
    let resolveLoad: () => void;
    let rejectLoad: (error: string | Event) => void;
    const promise = new Promise<void>((resolve, reject) => {
      resolveLoad = resolve;
      rejectLoad  = reject;
    });
    if (!document.getElementById(id)) {
      const parent = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.type    = 'text/javascript';
      script.src     = src;
      // script.async   = true;
      script.onload  = () => resolveLoad();
      script.onerror = (error) => rejectLoad(error);
      parent.appendChild(script);
    }
    return promise.then(() => console.log('Loaded', src)).catch(error => { console.error('Failed to load',src); throw error });
  }

  protected initializeNative(): Promise<any> {
    this.logger.info('initializeNative!');
    this.oneSignal = (this.window as any)?.plugins?.OneSignal;
    if (this.oneSignal) {
      return new Promise((resolve, reject) => {
        try {
          const oneSignal = this.oneSignal;
          oneSignal.setLogLevel(6, 0);
          oneSignal.setAppId(this.notificationAppId);
          oneSignal.setRequiresUserPrivacyConsent(false);
          oneSignal.setNotificationWillShowInForegroundHandler(notificationReceivedEvent =>
            this.logger.info('NotificationWillShowInForeground', notificationReceivedEvent)
          );
          oneSignal.setNotificationOpenedHandler(notification => {
            this.logger.info('Notification opened', notification);
            this._opened$.next(notification);
          });
          // ATTENTION: OSInAppMessageAction, OSSubscriptionState and maybe other type declarations
          // do not match the data structures effectively used by the OneSignal SDK
          // there is a discrepancy in casing of some properties:
          // type declarations use snake_case while the objects passed by SDK use camelCase
          oneSignal.setInAppMessageClickHandler((action: any/*OSInAppMessageAction*/) => {
            this.logger.info('InAppMessage click', action);
            if (action.closesMessage) {
              this._prompting$.next(false);
              // oneSignal.removeTriggerForKey('notification_prompt');
              oneSignal.addTrigger('notification_prompt', false);
            }
          });

          // produces runtime error - maybe setInAppMessageLifecycleHandler requires OneSignal IAM JS Library??
          // oneSignal.setInAppMessageLifecycleHandler({
          //   onWillDisplayInAppMessage : (message)  => this.logger.info('onWillDisplayInAppMessage', message),
          //   onDidDisplayInAppMessage  : (message)  => this.logger.info('onDidDisplayInAppMessage', message),
          //   onWillDismissInAppMessage : (message)  => this.logger.info('onWillDismissInAppMessage', message),
          //   onDidDismissInAppMessage  : (message)  => {
          //     this.logger.info('onDidDismissInAppMessage', message);
          //     // is this needed?
          //     oneSignal.removeTriggerForKey('notificationPrompt');
          //   },
          // });

          oneSignal.addEmailSubscriptionObserver(emailSubscription => this.logger.info('EmailSubscription', emailSubscription));
          oneSignal.addSubscriptionObserver((subscriptionStateChange: any/*{ from: OSSubscriptionState, to: OSSubscriptionState }*/) => {
            this.logger.info('Subscription Change', subscriptionStateChange);
            const { from, to } = subscriptionStateChange;
            const { userId, isSubscribed } = to;
            this.users[userId] = isSubscribed;
            this._subscription$.next({ userId, subscribed: isSubscribed });
          });
          oneSignal.addPermissionObserver((permissionStateChange: { from: OSPermissionState, to: OSPermissionState }) => {
              this.logger.info('Permission Change', permissionStateChange);
              const { from, to } = permissionStateChange;
              const authorized = to?.status==2;
              this._nativePermission$.next(authorized);
              this._permission$.next(authorized);
          });

          // https://github.com/OneSignal/OneSignal-Cordova-SDK/issues/738
          oneSignal.getDeviceState((state: DeviceState) => {
            this.logger.info('DeviceState', state);
            // The following OSDeviceState is received when application has been restarted
            // after the prompt shown in first app launch has been dismissed:
            /*
              OSDeviceState
              emailAddress: undefined
              emailSubscribed: false
              emailUserId: undefined
              hasNotificationPermission: undefined
              notificationPermissionStatus: 1
              pushDisabled: false
              pushToken: "0f2d6da44cc0d59c6c387d31b2edd1ab12e872e6317e48a3f2efa85b0de02f2e"
              smsNumber: undefined
              smsSubscribed: false
              smsUserId: undefined
              subscribed: false
              userId: "7bff1962-e2cd-4dfb-b60d-135c55c7d970"
             */
            const permission = !!state.hasNotificationPermission; // process undefined as false - this will lead to proper enabled$ initialization
            const subscription = (({ userId, subscribed }) => ({ userId, subscribed }))(state);
            this._subscription$.next(subscription);
            this._nativePermission$.next(permission)
            this._permission$.next(permission);
          });

          // Deprecated!
          // oneSignal.push(["getPermissionSubscriptionState", (state) => {
          //   console.info('getPermissionSubscriptionState', state);
          //   this.nativePermissionChanged$.next(state.getPermissionStatus().getEnabled());
          // }]);

          //OneSignal.promptForPushNotificationsWithUserResponse - displays ios native dialog/prompt but only the first time the app is started
          //Subsequent calls to promptForPushNotificationsWithUserResponse (even after app restart) do not cause the prompt to appear - this behavior is by design
          //no effect on android (because it does not require the dialog to be shown at all -
          //the subscription is done automatically, user consent is collected on installation time)
          this._prompting$.next(true);
          this.logger.info('promptForPushNotificationsWithUserResponse', this._prompting$.getValue());
          oneSignal.promptForPushNotificationsWithUserResponse(response => {
              this.logger.info('PromptPushNotificationWithUserResponse', response);
              this._prompting$.next(false);
          });
          resolve(oneSignal);
        } catch (e) {
          this.logger.error(e);
          reject(e);
        }
      });
    } else {
      return Promise.reject('OneSignal plugin not found!');
    }

    // const logLevel    = this.environment.production ? 1 : 4;
    // const visualLevel = this.environment.production ? 1 : 3;
    // this.oneSignal.setLogLevel({ logLevel: logLevel, visualLevel: visualLevel });
    // this.oneSignal.enableVibrate(true); // default: true
    // this.oneSignal.startInit(this.notificationAppId);
    // //inFocusDisplaying - how OneSignal notifications will be shown
    // // when one is received while your app is in focus.
    // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
    // // Received handler is only called if the app is in focus at the time the notification was received
    // this.oneSignal.handleNotificationReceived().subscribe((jsonData) => this.notificationReceived$.next(jsonData));
    // this.oneSignal.handleNotificationOpened().subscribe((jsonData) => this.notificationOpened$.next(jsonData));
    // this.oneSignal.endInit();
    //
    // this.oneSignal.addSubscriptionObserver().subscribe((state) => {
    //   this.subscriptionChanged$.next({
    //     subscribed: state.to.subscribed,
    //     userId: state.to.userId
    //   });
    // });
  }

  protected async initializeWeb(): Promise<any> {
    const welcomeTitleKey   = 'notification.welcome.title';
    const welcomeMessageKey = 'notification.welcome.message';
    const promptMessageKey  = 'notification.prompt.message';
    const promptAcceptKey   = 'notification.prompt.accept';
    const promptCancelKey   = 'notification.prompt.cancel';
    const translations = await this.translateService.get([
      welcomeTitleKey, welcomeMessageKey,
      promptMessageKey, promptAcceptKey, promptCancelKey
    ]).toPromise();
    this.oneSignal = (this.window as any)?.OneSignal || [];
    const oneSignal = this.oneSignal;
    const options: any = {
      appId: this.notificationAppId,
      autoRegister: false,
      webhooks: {
        cors: false, // Defaults to false if omitted
        'notification.displayed': '/v1.0/notification/webhook/displayed',
        'notification.clicked'  : '/v1.0/notification/webhook/clicked',
        'notification.dismissed': '/v1.0/notification/webhook/dismissed'
      },
      notifyButton: {
        enable: false, /* Required to use the Subscription Bell */
        /* SUBSCRIPTION BELL CUSTOMIZATIONS START HERE */
        size: 'medium', /* One of 'small', 'medium', or 'large' */
        theme: 'default', /* One of 'default' (red-white) or 'inverse" (white-red) */
        position: 'bottom-right', /* Either 'bottom-left' or 'bottom-right' */
        offset: {
          bottom: '0px',
          left: '0px', /* Only applied if bottom-left */
          right: '0px' /* Only applied if bottom-right */
        },
        prenotify: true, /* Show an icon with 1 unread message for first-time site visitors */
        showCredit: false, /* Hide the OneSignal logo */
        text: {
          'tip.state.unsubscribed': 'Subscribe to notifications',
          'tip.state.subscribed': "You're subscribed to notifications",
          'tip.state.blocked': "You've blocked notifications",
          'message.prenotify': 'Click to subscribe to notifications',
          'message.action.subscribed': 'Thanks for subscribing!',
          'message.action.resubscribed': "You're subscribed to notifications",
          'message.action.unsubscribed': "You won't receive notifications again",
          'dialog.main.title': 'Manage Site Notifications',
          'dialog.main.button.subscribe': 'SUBSCRIBE',
          'dialog.main.button.unsubscribe': 'UNSUBSCRIBE',
          'dialog.blocked.title': 'Unblock Notifications',
          'dialog.blocked.message': 'Follow these instructions to allow notifications:'
        },
        colors: { // Customize the colors of the main button and dialog popup button
          'circle.background': 'rgb(84,110,123)',
          'circle.foreground': 'white',
          'badge.background': 'rgb(84,110,123)',
          'badge.foreground': 'white',
          'badge.bordercolor': 'white',
          'pulse.color': 'white',
          'dialog.button.background.hovering': 'rgb(77, 101, 113)',
          'dialog.button.background.active': 'rgb(70, 92, 103)',
          'dialog.button.background': 'rgb(84,110,123)',
          'dialog.button.foreground': 'white'
        },
        /* HIDE SUBSCRIPTION BELL WHEN USER SUBSCRIBED */
        displayPredicate: function () {
          return oneSignal.isPushNotificationsEnabled()
            .then(function (isPushEnabled: boolean) {
              return !isPushEnabled;
            });
        }
      },
      welcomeNotification: {
        title: translations[welcomeTitleKey],     //this.translateService.instant('notification.welcome.title'),
        message: translations[welcomeMessageKey], //this.translateService.instant('notification.welcome.message'),
        // "url": "" /* Leave commented for the notification to not open a window on Chrome and Firefox (on Safari, it opens to your webpage) */
      },
      promptOptions: {
        /* actionMessage limited to 90 characters */
        actionMessage: translations[promptMessageKey],    //this.translateService.instant('notification.prompt.message'),
        /* acceptButtonText limited to 15 characters */
        acceptButtonText: translations[promptAcceptKey],  //this.translateService.instant('notification.prompt.accept'),
        /* cancelButtonText limited to 15 characters */
        cancelButtonText: translations[promptCancelKey],  //this.translateService.instant('notification.prompt.cancel')
      },
      unsubscribeEnabled: true, /* Controls whether the prompt is visible after subscription */
    };

    if (this.notificationSafariWebId) {
      options.safari_web_id = this.notificationSafariWebId;
    }

    // this.logger.info('OPTIONS', options);
    oneSignal.push(() => oneSignal.log.setLevel('trace'));
    oneSignal.push(['init', options]);
    oneSignal.push(() => {
      oneSignal.log.setLevel('trace');
      // https://stackoverflow.com/questions/35217959/how-to-listen-for-web-notification-permission-change

      // onesignal sdk is loaded with a script tag in index.html
      // delayed loading stopped working as expected starting at least with v151001 of the sdk (if not before that)
      // await this.loadScript(NotificationService.ONESIGNAL_SDK_SRC, 'onesignal-js');

      //   var Notification = window.Notification || window.mozNotification || window.webkitNotification;
      //
      //   var was_questioned = false;
      //   if (Notification.permission == 'default') {
      //     was_questioned = true;
      //   }
      //
      //   Notification.requestPermission(function (permission) {
      //     if (was_questioned) {
      //       console.log("User was asked. New permission is: " + permission);
      //     }
      //     if ('permissions' in navigator) {
      //       navigator.permissions.query({name:'notifications'}).then(function(notificationPerm) {
      //         notificationPerm.onchange = function() {
      //           console.log("User decided to change his seettings. New permission: " + notificationPerm.state);
      //         };
      //       });
      //     }
      //   });
      //
      oneSignal.on('notificationPermissionChange', (permissionChange: any) => {
        // Event occurs when the user clicks Allow or Block or dismisses the browser's native permission request.
        // granted - the user has allowed notifications
        // denied" - the user has blocked notifications
        // default - the user has clicked the 'X' button to dismiss the prompt
        console.info('notificationPermissionChange', permissionChange);
        const permission = permissionChange.to;
        this._nativePermission$.next(permission=='granted');
      });

      oneSignal.on('customPromptClick', (promptClickResult: any) => {
        // not relevant to slide prompts
        // "You should therefore ignore this event if you are using the Slide Prompt."
        // https://documentation.onesignal.com/docs/web-push-sdk#slide-prompt-events
        const result = promptClickResult.result;
        console.info('HTTP Pop-Up Prompt click result:', result);
      });
      oneSignal.on('popoverShown', () => {
        console.info('popoverShown');
        this._permission$.next(undefined);
        this._prompting$.next(true);
      });
      oneSignal.on('popoverAllowClick', () => {
        console.info('popoverAllowClick');
        this._permission$.next(true);
        this._prompting$.next(false);
      });
      oneSignal.on('popoverCancelClick', () => {
        console.info('popoverCancelClick');
        this._permission$.next(false);
        this._subscription$.next({ subscribed: false, userId: undefined });
        this._prompting$.next(false);
      });
      oneSignal.on('subscriptionChange', (subscribed: boolean) => {
        console.info("The user's subscription state is now:", subscribed);
        oneSignal.getUserId((userId: string) => {
          console.info('oneSignal.getUserId()', userId, this);
          if (userId) {
            this.users[userId] = subscribed;
            this._subscription$.next({ subscribed: subscribed, userId: userId });
          } else {
            console.error('Failed to get notification user ID!');
          }
        });
      });
      oneSignal.on('notificationDisplay', (event: any) => {
        console.info('oneSignal notification displayed:', event);
      });
      oneSignal.on('notificationDismiss', (event: any) => {
        console.warn('oneSignal notification dismissed:', event);
      });
      // Reasons for prompt not showing: https://documentation.onesignal.com/docs/web-push-setup-faq
      // oneSignal.showHttpPrompt(); //show onesignal slide prompt
    });
    oneSignal.push(() => {
      oneSignal.isPushNotificationsEnabled((isEnabled) => {
        console.info('isPushNotificationsEnabled', isEnabled);
        if (isEnabled) {
          this._nativePermission$.next(true);
          this._permission$.next(true);
          oneSignal.getUserId((userId) => {
            console.info('getUserId', userId);
            this._subscription$.next({ subscribed: true, userId: userId });
          });
        } else {
          // Reasons for prompt not showing: https://documentation.onesignal.com/docs/web-push-setup-faq
          oneSignal.showSlidedownPrompt(); //show onesignal slide prompt
        }
      });
    })
    oneSignal.push(["getNotificationPermission", (permission) => {
      // Returns a Promise that resolves to the browser's current notification permission
      console.info('getNotificationPermission', permission);
      this._nativePermission$.next(permission=='granted');
    }]);
    return oneSignal;
  }

  send(notification: { message: string, data?: object }, receiverId?: string): Promise<void> {
    if (notification?.message) {
      return (receiverId
            ? Promise.resolve(receiverId)
            : lastValueFrom(this.ensureInitialized()).then((oneSignal) => {
              const [ userId, subscribed ] = Object.entries(this.users)[0] || [];
              if (userId && subscribed) {
                return userId
              }
              // throw new Error('Missing notification subscription');
              return undefined;
            })
          ).then(receiverId => {
            return lastValueFrom(this.httpClient.post('/v1.0/notification', {notification, receiverId}))
              .then(response => this.logger.info('SEND RESULT', response))
              .catch(error => {
                console.error('SEND FAILURE!', error);
                throw new Error(error.getMessage());
              });
          });
    } else {
      return Promise.reject('Invalid arguments');
    }
  }

  enableNotifications(): Promise<void> {
    return lastValueFrom(this.ensureInitialized()).then(oneSignal => {
      this.logger.info('enableNotifications -> ensureInitialized', { oneSignal });
      return new Promise<void>((resolve, reject) => {
        if (this.platform.is('hybrid')) {
          this._nativePermission$.pipe(take(1)).subscribe(permission => {
            this.logger.info('enableNotifications -> nativePermission', { permission });
            if (permission) {
              // https://documentation.onesignal.com/docs/sdk-reference#disablepush-method
              this.logger.info('enableNotifications -> disablePush(false)', { permission });
              oneSignal.disablePush(false);
              resolve();
            } else {
              // https://documentation.onesignal.com/docs/ios-push-opt-in-prompt
              // https://documentation.onesignal.com/docs/example-tag-substitution
              // ATTENTION: This implementation is vulnerable to racing conditions
              // when one user with 2 sessions having different languages
              // triggers the ios permission prompt at the same time
              // (onesignal tags are set per user not per session and DE session could end up seeing EN prompt and vice versa)
              const prefix = "notification.prompt.inApp.";
              const tags = {
                notification_prompt_title: this.translateService.instant(`${prefix}title`),
                notification_prompt_message: this.translateService.instant(`${prefix}message`),
                notification_prompt_allow: this.translateService.instant(`${prefix}allow`),
                notification_prompt_cancel: this.translateService.instant(`${prefix}cancel`)
              }
              this.logger.info('enableNotifications -> sendTags', tags);
              oneSignal.sendTags(tags);
              oneSignal.getTags((tags: {[tag: string]: string}) => {
                this.logger.info('enableNotifications -> getTags', tags);
                this._prompting$.next(true);
                oneSignal.addTrigger("notification_prompt", true);
                // if (!!tags['notification_prompt_title'] &&
                //   !!tags['notification_prompt_message'] &&
                //   !!tags['notification_prompt_allow'] &&
                //   !!tags['notification_prompt_later']) {
                //   oneSignal.addTrigger("notification_prompt", "true");
                // }
              });

              // Obsolete is OneSignal.promptForPushNotificationsWithUserResponse and not recommended
              // (Moreover it is noop when invoked more than once - confirmed by OneSignal support)
              // oneSignal.promptForPushNotificationsWithUserResponse(response => {
              //     this.logger.info('enableNotifications -> promptForPushNotificationsWithUserResponse', { permission, response });
              //     resolve();
              // });
            }
          })
        } else {
          const showSlidedownPrompt = () => {
            this.logger.info('enableNotifications -> showSlidedownPrompt', {oneSignal});
            oneSignal.push(() => {
              this.logger.info('enableNotifications -> showSlidedownPrompt');
              // oneSignal.registerForPushNotifications();
              // Slide Prompt will not show up if
              // "The user previously dismissed the message by clicking the "No Thanks" button"
              // https://documentation.onesignal.com/docs/slide-prompt#why-isnt-my-slide-prompt-showing-up
              // "Prompting behavior if declined
              // If declined, future calls will be ignored for 3 days with additional backoff on another decline.
              // Note: If you need to override this back off behavior to prompt the user again you can do so by passing {force: true}. To provide a good user experience however ONLY do this from an action taken on your site to avoid unexpected prompts."
              // https://documentation.onesignal.com/docs/web-push-sdk#showslidedownprompt-method
              oneSignal.showSlidedownPrompt({force: true}).then((result) => {
                this.logger.info('enableNotifications -> showSlidedownPrompt.DONE', { result });
                resolve();
              }).catch((error) => {
                this.logger.error('enableNotifications -> showSlidedownPrompt', { error });
                reject(error);
              })
            });
          };
          const promise = Notification.permission=='granted'
            ? Promise.resolve()
            : Notification.requestPermission().then((permission: NotificationPermission) => {
                this.logger.info('enableNotifications -> requestPermission', permission);
                if (permission !== "granted") {
                  throw new Error('Failed to obtain notification permission');
                }
            });
          promise.then(() => showSlidedownPrompt()).catch((error) => {
            this.logger.warn('enableNotifications', error.message);
            throw error;
          });
        }
      });
    });
  }

  disableNotifications(): Promise<void> {
    return lastValueFrom(this.ensureInitialized()).then(oneSignal => {
      return new Promise<void>((resolve, reject) => {
        if (this.platform.is('hybrid')) {
          // https://documentation.onesignal.com/docs/sdk-reference#disablepush-method
          oneSignal.disablePush(true);
        } else {
          // https://documentation.onesignal.com/docs/web-push-sdk#setsubscription-method
          oneSignal.setSubscription(false);
        }
      })
    });
  }

  get permission$(): Observable<any> {
    return combineLatest([this._nativePermission$, this._permission$]);
  }

  get subscription$(): Observable<any> {
    return this._subscription$.asObservable();
  }

  get enabled$(): Observable<boolean> {
    return this._enabled$.asObservable();
  }

  get opened$(): Observable<any> {
    return this._opened$.asObservable();
  }

  get prompting$(): Observable<boolean> {
    return this._prompting$.pipe(tap(prompting => this.logger.info(`Prompting: ${prompting}`)))
  }
}
