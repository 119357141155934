/* projects/shared/src/lib/components/thumb-navigation/thumb-status-left.component.scss */
:host {
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 4px;
  background-color: var(--color-primary);
  color: var(--color-primaryContrast);
}
:host.hide {
  display: none;
}
:host > span {
  font-size: 0.9em;
  vertical-align: bottom;
  margin-left: 3px;
}
:host > span:first-of-type {
  font-size: 0.9em;
  vertical-align: bottom;
}
:host > span:nth-of-type(2) {
  font-size: 0.72em;
  vertical-align: top;
}
mat-icon {
  vertical-align: middle;
  color: var(--color-primaryContrast);
}
/*# sourceMappingURL=thumb-status-left.component-ACGLJOZP.css.map */
