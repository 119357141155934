import { FormGroup } from "@angular/forms";
import { createFormComponent, FormComponent, FormComponentType } from "./form-element";

export interface WizardStep {
  label: string,
  formGroup?: FormGroup,
  components: FormComponent[],
  componentMap?: Map<string, FormComponent>
}

export function getWizardSteps(): WizardStep[] {
  return WIZARD_STEPS;
}

export function getComponents(components: FormComponent[]): Map<string, FormComponent> {
  const result = components.reduce(function (map, obj) {
    map[obj.name] = obj;
    return map;
  }, new Map());
  return result;
}

const stepInformation: WizardStep = {
  "label": "groups.information",
  "components": [
    {
      "name": "id",
      "label": "groups.id",
      "type": FormComponentType.Input,
      "required": true,
      "errorText": "groups.validation.idRequired"
    }, {
      "name": "name",
      "label": "groups.name",
      "type": FormComponentType.Input,
      "required": true,
      "errorText": "groups.validation.nameRequired"
    }, {
      "name": "languages",
      "label": "groups.languages.label",
      "type": FormComponentType.Select,
      "icon": "language",
      "multiple": true
    }, {
      "name": "textLanguage",
      "label": "groups.languages.textLanguage",
      "type": FormComponentType.Select,
      "icon": "language",
      "multiple": false
    }, {
      "name": "textStyle",
      "label": "invite.language.style.label",
      "type": FormComponentType.Select,
      "multiple": true
    }
  ]
}

const stepContact: WizardStep = {
  "label": "groups.owner",
  "components": [
    {
      "name": "owner",
      "label": "contact.name",
      "type": FormComponentType.Input,
      "required": true,
      "errorText": "groups.validation.ownerRequired"
    },
    {
      "name": "organization",
      "label": "groups.organization",
      "type": FormComponentType.Input,
      "required": true,
      "errorText": "groups.validation.organizationRequired"
    },
    {
      "name": "phone",
      "label": "contact.phone",
      "type": FormComponentType.Input,
      "validators": [
        {
          "name": "pattern",
          "value": "[- +()0-9]+"
        }
      ],
      "required": true,
      "errorText": "contact.validation.phoneRequired"
    },
    {
      "name": "email",
      "label": "contact.email",
      "type": FormComponentType.Input,
      "validators": [{
        "name": "email"
      }],
      "required": true,
      "errorText": "contact.validation.emailRequired"
    },
    {
      "name": "link",
      "label": "login.legal_contact.link",
      "type": FormComponentType.Input
    },
    {
      "name": "address",
      "label": "contact.address",
      "type": FormComponentType.Text,
      "required": true,
      "errorText": "contact.validation.addressRequired"
    },
    {
      "name": "zip",
      "label": "contact.zip",
      "type": FormComponentType.Input,
      "required": true,
      "errorText": "contact.validation.zipRequired"
    },
    {
      "name": "community",
      "label": "contact.community",
      "type": FormComponentType.Input,
      "required": true,
      "errorText": "contact.validation.communityRequired"
    },
    {
      "name": "country",
      "label": "contact.country",
      "type": FormComponentType.Select,
      "icon": "language",
      "required": true,
      "errorText": "contact.validation.countryRequired"
    },
    {
      "name": "duns",
      "label": "contact.duns",
      "type": FormComponentType.Input,
      "validators": [{
        "name": "pattern",
        "value": "^[0-9]*$"
      }, {
        "name": "min",
        "value": "9"
      }, {
        "name": "max",
        "value": "9"
      }],
      "errorText": "groups.validation.dunsInvalid"
    },
    {
      "name": "vatid",
      "label": "contact.vatid",
      "type": FormComponentType.Input
    }
  ]
}

const stepColors: WizardStep = {
  "label": "groups.colors.label",
  "components": [
    {
      "name": "pure",
      "label": "groups.colors.pure",
      "type": FormComponentType.Input
    },
    {
      "name": "primary",
      "label": "groups.colors.primary",
      "type": FormComponentType.Input
    },
    {
      "name": "primaryContrast",
      "label": "groups.colors.primaryContrast",
      "type": FormComponentType.Input
    },
    {
      "name": "accent",
      "label": "groups.colors.accent",
      "type": FormComponentType.Input
    },
    {
      "name": "accentContrast",
      "label": "groups.colors.accentContrast",
      "type": FormComponentType.Input
    }
  ]
}
const stepLogo: WizardStep = {
  "label": "groups.logo.label",
  "components": [
    {
      "name": "lightAppVersionStyle",
      "label": "groups.logo.versionStyle",
      "type": FormComponentType.Text
    },
    {
      "name": "lightLogoSeparatorStyle",
      "label": "groups.logo.logoSeparatorStyle",
      "type": FormComponentType.Text
    },
    {
      "name": "darkAppVersionStyle",
      "label": "groups.logo.versionStyle",
      "type": FormComponentType.Text
    },
    {
      "name": "darkLogoSeparatorStyle",
      "label": "groups.logo.logoSeparatorStyle",
      "type": FormComponentType.Text
    }
  ]
}

const WIZARD_STEPS: WizardStep[] = [
  stepInformation,
  stepContact,
  stepColors,
  stepLogo
]
{
  WIZARD_STEPS.forEach(step => {
    const componentList: FormComponent[] = [];
    step.components.forEach(component => {
      componentList.push(createFormComponent(component));
    });
    step.components = componentList;
    step.componentMap = getComponents(step.components);
  })
}
