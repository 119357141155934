/* projects/shared/src/lib/components/virtual-scroller/virtual-scroller.scss */
:host {
  overflow: hidden;
}
:host > .viewport {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  transform: translate3d(0, 0, 0);
}
:host > .viewport::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}
:host.vertical > .viewport {
  overflow-y: auto;
}
:host.horizontal > .viewport {
  overflow-x: auto;
}
:host-context(.plt-ios) > .viewport {
  overflow-x: hidden;
  overflow-y: hidden;
}
:host .scroll-container {
  position: absolute;
  left: 0;
  top: 0;
  overflow: visible;
}
:host.vertical .scroll-container {
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  transform: translate3d(0, 0, 0);
}
:host.horizontal .scroll-container {
  height: 100%;
  max-height: 100%;
  min-width: 100%;
}
:host .render-container {
  position: absolute;
  left: 0;
  top: 0;
}
:host.vertical .render-container {
  width: 100%;
  max-width: 100%;
}
:host.horizontal .render-container {
  height: 100%;
  max-height: 100%;
}
:host .item-container {
  position: relative;
}
:host .item-container ::ng-deep > * {
  box-sizing: border-box;
}
/*# sourceMappingURL=virtual-scroller-TQN3LQY5.css.map */
