<div class="search-container">
    <mat-checkbox [(ngModel)]="selectAllChecked" (change)="setSelectAll($event.checked)">
    </mat-checkbox>

    <mat-form-field appearance="none">
        <input unmodified-input 
               class="search-field" 
               [autoFocus]="searchFocus" 
               matInput 
               [(ngModel)]="searchValue"
               [formControl]="searchControl">
    </mat-form-field>
    <button *ngIf="searchValue" class="clear-icon" matSuffix mat-icon-button aria-label="Clear"
            (click)="clearSearch()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="separator"></div>