import {createCacheId} from "core";
import {initialSessionState} from "./state";
import {
  sessionCloseDoneAction,
  sessionCloseFailedAction,
  sessionListSubscribeAction,
  sessionListUnsubscribeAction,
  sessionSelectAction,
  sessionUpdateAction,
} from "./actions";
import {Session} from "./models";
import {createReducer, on} from "@ngrx/store";
import {logAction} from "shared";
import {isValidNumber} from "shared";

export const sessionReducer = createReducer(
  initialSessionState,
  on(sessionSelectAction,(state, action) => {
    return logAction("sessionSelectAction",false,state,action,()=>{
      return {
        ...state,
        selectedIndex: action.selectedIndex
      }
    });
  }),
  on(sessionListSubscribeAction,(state, action) => {
    return logAction("sessionListSubscribeAction",false,state,action,()=>{
      action.send = !state.subscribed;
      return state.subscribed ? state : {
        ...initialSessionState,
        subscribed: true,
        cacheId: createCacheId()
      }
    });
  }),
  on(sessionListUnsubscribeAction,(state, action) => {
    return logAction("sessionListUnsubscribeAction",false,state,action,()=>{
      action.send = state.subscribed;
      return state.subscribed ? {
        ...initialSessionState
      } : state;
    });
  }),
  on(sessionUpdateAction,(state, action) => {
    return logAction("sessionUpdateAction",false,state,action,()=>{
      if (!!action.session?.id) {
        const session = action.session;
        const time    = session.timeUpdated;
        const index   = state.indices[session.id];
        const indexOk = isValidNumber(index);
        state = { ...state };
        state.entities = [...state.entities];
        state.indices  = {};
        if (indexOk) {
          //console.log("XY.ENTITIES.1a",[...state.entities]);
          state.entities[index] = session;
          //console.log("XY.ENTITIES.1b",state.entities);
        } else {
          //console.log("XY.ENTITIES.2a",[...state.entities]);
          state.entities.push(session);
          //console.log("XY.ENTITIES.2b",state.entities);
        }
        state.entities.sort((a:Session,b:Session)=>{
          const cmp = b.timeUpdated-a.timeUpdated;
          return cmp!=0 ? cmp : a.id > b.id ? -1 : 1;
        });
        state.entities.forEach((session,index)=>state.indices[session.id]=index);
      }
      return state;
    });
  }),
  on(sessionCloseDoneAction,(state, action) => {
    return logAction("sessionCloseDoneAction",false,state,action,()=>{
      if (!!action.sessionId) {
        const index = state.indices[action.sessionId];
        console.log("INDEX",index,"update",isValidNumber(index));
        if (isValidNumber(index)) {
          state = {...state};
          state.entities = [...state.entities];
          state.entities.splice(index,1);
          state.indices = {};
          state.entities.forEach((session,index)=>state.indices[session.id]=index);
          console.log("UPDATED",state);
        }
      }
      return state;
    });
  }),
  on(sessionCloseFailedAction,(state, action) => {
    return logAction("sessionCloseFailedAction",false,state,action,()=>{
      return {
        ...state,
        error: action.error
      }
    });
  }),
);
