import {
  Component,
  ComponentFactory,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  NgZone,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {Contact, Logger, once, Platform} from "core";
import {LayoutService} from "layout"
import {MediaDetailsComponent} from "../../components/media-details/media-details.component";
import {MediaService} from "../../service/media.service";
import {Media} from "../../store/models";
import {Store} from "@ngrx/store";
import {
  concatMap,
  filter,
  map,
  Observable,
  ReplaySubject,
  startWith,
  Subscription,
  switchMap,
  take,
  takeUntil
} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {PropertiesService} from "properties";
import {MediaChangeConsent, MediaDeleteDialogComponent} from "./media-change-consent";
import isEqual from "lodash/isEqual";
import {EmailComposer, EmailComposerOptions} from "@ionic-native/email-composer/ngx";
import {BasicContainerComponent, ResizeEvent, SlideEvent, SlideState} from "shared";
import {MediaDetailsShareDataService} from '../../service/media-details-share-data.service';
import {MediaReportDialogComponent} from "../../components/media-report/media-report.component";

export const AUTHOR_SELECTOR_COMPONENT_FACTORY = new InjectionToken<ContactSelectorComponent>('authorSelectorComponentFactory');

@Component({
  selector: 'contact-selector',
  template: `<span>CONTACT SELECTOR</span>`
})
export class ContactSelectorComponent extends BasicContainerComponent {
  @Output() onSelectionChanged = new EventEmitter<{ contact: Contact, index: number }>();
}

export abstract class ContactSelectorFactory extends ComponentFactory<ContactSelectorComponent> {
}

export type MediaDetailPanel = 'main' | 'contacts';

@Component({
  selector: 'app-media-details-container',
  templateUrl: './media-details-container.component.html',
  styleUrls: ['./media-details-container.component.scss']
})
export class MediaDetailsContainerComponent extends BasicContainerComponent {

  @ViewChild('mediaDetails') mediaDetails: MediaDetailsComponent;
  @ViewChildren('mediaDetails') mediaDetailsList: QueryList<MediaDetailsComponent>;
  @ViewChild('contactSelectorContainer', { read: ViewContainerRef }) contactSelectorContainer: ViewContainerRef;
  @ViewChild('toolbar', { read: ElementRef, static: true }) toolbar: ElementRef;

  noop = (arg?: any): void => void 0;
  onMediaUpdate: (media: Media) => void = this.noop;
  edit: boolean;
  protected mediaPath: string[];
  target: string;

  protected _panel: MediaDetailPanel = 'main';
  protected _selector: Exclude<MediaDetailPanel, 'main'>;

  protected subscription: Subscription;
  protected contactSelectorSubscription: Subscription;
  protected contactSelectorRef: ComponentRef<ContactSelectorComponent>;
  protected authorSelectCallback: (contact: Contact) => void;
  protected initialized$ = new ReplaySubject<void>(1);
  protected logger = new Logger('MediaDetailsContainerComponent');
  toolbarHeight: any;

  constructor(public layoutService: LayoutService,
              protected mediaService: MediaService,
              protected store$: Store<any>,
              protected zone: NgZone,
              protected snackBar: MatSnackBar,
              protected translateService: TranslateService,
              protected propertiesService: PropertiesService,
              protected platform: Platform,
              protected emailComposer: EmailComposer,
              public mediaChangeConsent: MediaChangeConsent,
              @Inject(AUTHOR_SELECTOR_COMPONENT_FACTORY)
              protected contactSelectorFactory: ContactSelectorFactory,
              protected dialog: MatDialog,
              private mediaDetailsShareDataService: MediaDetailsShareDataService) {
    super();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.initialized$.next();
    this.initialized$.complete();
    this.toolbarHeight = this.getToolbarHeight();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.unsubscribe();
  }

  onResize(event: ResizeEvent): void {
      this.toolbarHeight = this.getToolbarHeight();
  }

  getToolbarHeight() {
    return this.toolbar?.nativeElement?.offsetHeight;
  }

  set context(context: any) {
    this.target = context.options?.target;
    this.onMediaUpdate = context?.onMediaUpdate || this.noop;
    this.mediaPath = context?.mediaPath || [];
    const media = context?.media;
    const options = context.options || {};
    //console.trace("MDC.context",context,options);
    this.mediaDetailsShareDataService.setOptions(options);
    if (media?.id) {
      this.reset();
      const trigger: () => Observable<[MediaDetailsComponent, Media]> = () => this.mediaDetailsList.changes.pipe(
        takeUntil(this.onDestroy$),
        filter(changes => changes.length),
        map(changes => changes.first),
        startWith(this.mediaDetails),
        filter(mediaDetails => !!mediaDetails),
        take(1),
        switchMap((mediaDetails: MediaDetailsComponent) =>
          // this.store$.pipe(
          //   select(selectMedia, context.media.id),  // selectMedia always returns media clone!!
          this.mediaService.getMedia$(media.id, media).pipe(
            // auditTime(300, asyncScheduler) // throttle media changes
            map((media: Media, index: number) => {
              // media passed in context could have been removed from the store e.g. if media filters were changed
              // and the media does no longer pass these new filters in this case we display the media from context.
              // In this case the calling code should supply onMediaUpdate callback to react on media changes as the store
              // will not fire an update event (because the media is not there).
              // However when the media has been already displayed (index>0) and then it is removed from the store
              // we reflect the removal in MediaDetailsComponent.
              return index > 0 || media ? media : context.media;
            }),
            filter((media: Media) => !isEqual(media, mediaDetails.media)),
            map((media: Media) => [mediaDetails, media] as [MediaDetailsComponent, Media])
          )
        )
      );
      this.subscription = this.initialized$
        .pipe(concatMap( () => trigger()))
        .subscribe(([mediaDetails, media]) => {
          mediaDetails.media = media;
          const options = context.options || {};
          mediaDetails.mode = options.mode;
          mediaDetails.resetHandler = options.resetHandler;
        });
    }
  }

  onMediaChange(change: Media[] | Media) {
    if (change instanceof Array) {
      const [media, previous] = [...change];
      const updateMedia = () => {
        this.mediaService.updateMedia([media, previous], this.mediaPath)
          .then(result => {
            console.debug('MEDIA UPDATED', result);
            if (!isEqual(media, result)) {
              this.onMediaUpdate(result);
              this.mediaDetails.media = result;
            }
            // this.showSnackBar('media.updateResult.mediaSuccess');
          })
          .catch(error => {
            this.showSnackBar('media.updateResult.mediaError');
          });
        this.onMediaUpdate(media);
      };
      if (this.mediaService.requiresReapprove(media, previous)) {
        this.mediaChangeConsent.display(media, previous).then(result => {
          if (result) {
            updateMedia();
          } else {
            this.mediaDetails.media = previous;
          }
        });
      } else {
        updateMedia();
      }
    } else {
      this.mediaService.syncMedia(change);
      this.onMediaUpdate(change);
    }
  }

  onMediaDelete(media: Media) {
    this.dialog.open(MediaDeleteDialogComponent, {
      data: { media }
    })
      .afterClosed()
      .subscribe((result) => {
        // console.debug('MediaDeleteDialogComponent', result);
        if (result?.action == 'proceed') {
          this.layoutService.details.close();
          this.mediaService.deleteMedia(media, this.mediaPath, result.deleteOrphans);
        }
    });
  }

  onTapReport(media: Media) {
    this.dialog.open(MediaReportDialogComponent, {
      data: { media }
    })
      .afterClosed()
      .subscribe((reaction) => {
        if (reaction) {
          // this.layoutService.details.close();
          this.mediaService
            .react(media?.id, reaction, this.mediaPath)
            .catch(error => this.showSnackBar('media.report.error'));
        }
      });
  }

  protected reset() {
    this.unsubscribe();
    this.authorSelectCallback = null;
    this.panel = 'main';
  }

  protected unsubscribe() {
    this.subscription?.unsubscribe();
    this.contactSelectorSubscription?.unsubscribe();
    this.subscription = this.contactSelectorSubscription = null;
  }

  set panel(panel: MediaDetailPanel) {
    this.logger.debug(`PANEL: ${this._panel} --> ${panel}`);
    if (this._panel != panel) {
      if (panel != 'main') {
        this._selector = panel;
      }
      this._panel = panel;
      // this.onPanelChange.emit(this._panel = panel);
    }
  }

  get panel(): MediaDetailPanel {
    return this._panel;
  }

  get selector(): Exclude<MediaDetailPanel, 'main'> {
    return this._selector;
  }


  onAuthorSelect(event: (contact: Contact) => void) {
    this.authorSelectCallback = once(event, this);
    this.panel = "contacts";
  }

  onAuthorSelected(contact: Contact, index: number) {
    if (contact && this.authorSelectCallback) {
       this.authorSelectCallback(contact);
    }
    this.panel = 'main';
  }

  onAuthorSelectCancel() {
    this.authorSelectCallback = null;
    this.panel='main';
  }

  onAuthorDelete() {
    this.authorSelectCallback(null);
    this.panel='main';
  }

  onPanelSlide(event: SlideEvent) {
    this.logger.debug('onPanelSlide', event);
    // clear selector to remove the corresponding dom element when it has been fully slided out
    if (event.state==SlideState.END && event.slideIn=='main' && this._selector) {
      // schedule selector removal to run once the main thread stack is empty
      // in order to avaid input focus issues - i.e. focus is assigned to e.g. a material list component
      // and browser tries to ensure that this control is visible which ends in layout inconsistencies.
      window.setTimeout(() => {
        if (typeof this._selector['scrollToPosition'] === 'function') {
          this._selector['scrollToPosition'](0);
        } else {
          this._selector = undefined;
        }
      } );
    } else if (event.state==SlideState.END) {
      if (this.contactSelectorContainer) {
        if (!this.contactSelectorRef) {
          this.contactSelectorContainer.clear();
          this.contactSelectorRef = this.contactSelectorContainer.createComponent<ContactSelectorComponent>(this.contactSelectorFactory);
        }
        if (!this.contactSelectorSubscription && this.contactSelectorRef.instance) {
          const contactSelector: ContactSelectorComponent = this.contactSelectorRef.instance;
          this.contactSelectorSubscription = contactSelector.onSelectionChanged.subscribe(({contact, index}) => {
            this.logger.debug('contactSelector -> onSelectionChanged', {contact, index});
            this.onAuthorSelected(contact, index);
          })
        }
      }
    }
  }

  showSnackBar(message: string, action = 'actions.close') {
    this.translateService.get(message).subscribe(translatedMessage => {
      this.snackBar.open(translatedMessage, this.translateService.instant(action), {
        duration: 2000,
        horizontalPosition: 'right',
        // verticalPosition: 'bottom'
      });
    })
  }

  sendReportEmail(fallback: boolean = false) {
    this.platform.ready()
      .then((readySource) => {
        if (!fallback && this.platform.is('hybrid')) {
          new Promise((resolve) => resolve(true))
            .then((hasPermission) => {
              if (hasPermission) {
                return this.createReportEmail().then((message) => {
                  let options: EmailComposerOptions  = {...message, isHtml: true};
                  return this.emailComposer.open(options).then((result) => {
                    // console.debug('Email send result', result);
                  });
                })
              } else {
                return this.emailComposer.requestPermission().then((result) => {
                  if (result) {
                    this.sendReportEmail();
                  } else {
                    console.error('Failed to get email permission');
                    this.sendReportEmail(true);
                  }
                });
              }
            })
            .catch((error) => {
              console.error('Failed to send email using native composer', error);
              this.sendReportEmail(true);
            });
        } else {
          this.createReportEmail(false).then((message) => {
            let anchor = document.createElement('a');
            let subject = encodeURIComponent(message.subject);
            let body = encodeURIComponent(message.body);
            anchor.href = `mailto:${message.to}?subject=${subject}&body=${body}`;
            anchor.target = '_top';
            document.body.appendChild(anchor);
            window.setTimeout(() => anchor.remove());
            anchor.click();
          });
        }
      });
  }

  protected createReportEmail(html: boolean = true): Promise<any> {
    const user  = this.propertiesService.user;
    return this.translateService.get(
      'media.report.subject',
      {

      })
      .toPromise()
      .then(subject =>
        this.translateService.get(`media.report.${html ? 'html' : 'text'}`, {
          user: user,
          media: this.mediaDetails.media
        })
        .toPromise()
        .then(body => [subject, body])
      ).then(([subject, body]) => {
        let message = {
          to: `mediareport_${this.mediaDetails.media.id}_${user.id}@groupmanager.info`,
          subject: subject,
          body: body
        };
        console.debug('REPORT MESSAGE', message);
        return message;
      });
  }

}
