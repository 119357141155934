import {
  AfterContentInit, AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subscription} from "rxjs";
import {TouchTracker} from "../../models/touch/touch-tracker";
import {Matrix} from "../../models/css/matrix";
import {animate, AnimationEvent, state, style, transition, trigger} from "@angular/animations";
import {DragEvent} from "../../models/touch/drag-event";
import {TranslateService} from "@ngx-translate/core";

const snapAnimation = trigger('snapAnimation',
  [
    state('flow',
      style({ transform: 'translateX({{ from }})' }),
      { params: { from: '0px'}}
    ),
    state('snap',
      style({ transform: 'translateX({{ to }})' }),
      { params: { to: '0px'}}
    ),
    transition('flow => snap',animate('{{ time }}')),
    transition('snap => flow',animate('0ms'))
  ]
);

// some hints:
// https://github.com/bfwg/ngx-drag-scroll/blob/develop/projects/ngx-drag-scroll/src/lib/ngx-drag-scroll.component.ts
// cdkDragLockAxis="x" cdkDrag (click)="onClick($event)"
//<div class="handle" #handle cdkDrag></div>
@Component({
  selector: 'filter-carousel',
  template: `
    <div class="container" [class.touch-grow]="touchGrow" #container>
      <div class="content" #content
           [@snapAnimation]="{value:snapAnimationState,params:{from:snapAnimationFrom,to:snapAnimationTo,time:snapAnimationTime}}"
           (@snapAnimation.done)="onSnapDoneEvent($event)">
        <div #wrapper>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
  animations: [
    snapAnimation
  ],
  host: {
    '[attr.extended-drag-area]': 'extendedDragArea'
  },
  styleUrls: ['./filter-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterCarouselComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Output() onDrag:    EventEmitter<DragEvent> = new EventEmitter<DragEvent>();
  @Output() onSelect:  EventEmitter<number> = new EventEmitter<number>();
  @Input()  selected:  number  = undefined;
  @Input()  touchGrow: boolean = false;
  @Input()  extendedDragArea: boolean = true;

  @ViewChild('container', {read: ElementRef, static: true})
  containerElementRef: ElementRef;
  @ViewChild('content', {read: ElementRef, static: true})
  contentElementRef: ElementRef;
  @ViewChild('wrapper', {read: ElementRef, static: true})
  wrapperElementRef: ElementRef;

  protected previousSelected:number = undefined;
  protected selectSubscription: Subscription;
  protected languageSubscription: Subscription;
  protected mutationObserver : MutationObserver;

  public snapAnimationState:string = 'flow';
  public snapAnimationFrom:string = '0px';
  public snapAnimationTo:string = '0px';
  public snapAnimationTime:string = '0ms';

  constructor(
    protected changeDetector: ChangeDetectorRef,
    // protected application: ApplicationRef,
    protected translateService: TranslateService
  ) { }

  public ngOnDestroy(): void {
    //console.debug("ngOnDestroy()");
    this.mutationObserver.disconnect();
    this.selectSubscription.unsubscribe();
    this.onSelect.complete();
  }

  public setHorizontalDragFreezed(freezed:boolean): void {
    this.horizontalDragFreezed = freezed;
  }

  public isHorizontalDragFreezed(): boolean {
    return this.horizontalDragFreezed;
  }

  public ngAfterViewInit(): void {
    this.mutationObserver = new MutationObserver((mutations : MutationRecord[], observer : MutationObserver) => {
      this.onMutationEvent(mutations,observer);
    });
    this.mutationObserver.observe(this.contentElementRef.nativeElement,{
      characterData: true,
      subtree: true,
      childList: true,
      //attributes: true,
    });
    this.onMutationEvent([],this.mutationObserver);
    this.selectSubscription = this.onSelect.subscribe((index:any) => {
      //console.log("ngAfterContentInit::onSelect",index);
      window.setTimeout(()=> {
        this.onSelected(index);
      });
    });
    this.languageSubscription = this.translateService.onLangChange.subscribe(()=> {
      //console.debug("LANGUAGE CHANGED");
      window.setTimeout(()=>{
        //console.debug("NOW");
        this.previousSelected = undefined;
        this.onSelected(this.selected);
      });
    });
    //this.onSelect.emit(this.selected);
    this.setChildOpacity(this.getChildPositions(0));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    //console.log("ngOnChanges",this.selected,changes);
    if (this.previousSelected!=this.selected) {
      //console.log("ngOnChanges",this.selected,changes);
      //console.log("ngOnChanges::onSelect.EMIT",this.selected);
      this.onSelect.emit(this.selected);
    }
  }

  protected onMutationEvent(mutations : MutationRecord[], observer : MutationObserver): void {
    let wrapper = this.wrapperElementRef.nativeElement;
    let children  = wrapper.children;
    //console.log("container",this.containerElementRef.nativeElement,"children",children,"container.w",this.containerElementRef.nativeElement.offsetWidth);
    if (children && children.length) {
      //console.log("onMutationEvent",children.length,children,"displacement",this.displacement);
      let child1 = children[0];
      let childN = children[children.length-1];
      let width1 = child1.offsetWidth;
      let widthN = childN.offsetWidth;
      let marginLeft1  = parseInt(getComputedStyle(child1,null).marginLeft) || 0;
      let marginRightN = parseInt(getComputedStyle(childN,null).marginRight) || 0;
      //console.log("onMutationEvent children",children.length,"w1",width1,"wn",widthN,"marginL1",marginLeft1,"marginRN",marginRightN);
      wrapper.style.marginLeft = (-((width1/2)+marginLeft1))+"px";
      wrapper.style.marginRight = (-((widthN/2)+marginRightN))+"px";
    } else {
      //console.log("onMutationEvent",0,"displacement",this.displacement);
    }
    //console.log("onMutationEvent displacement",this.displacement,"selected",this.selected,"children",children);
    //this.setDisplacement(0, false);
    this.previousSelected = undefined;
    if (this.selected>=0) {
      this.onSelected(this.selected);
    } else {
      this.setDisplacement(0);
    }
  }

  protected hasFilters(): boolean {
    let wrapper = this.wrapperElementRef.nativeElement;
    let children  = wrapper.children;
    if (children) {
      if (children.length>1 || !children[0].getPropertyValue("tracker")) {
        return true;
      }
    }
    return false;
  }

  protected childrenAsArray(element:Element): Element[] {
    let children:Element[] = [];
    let collection:HTMLCollection = element.children;
    for (let i=0; i<collection.length; i++) {
      children.push(collection.item(i));
    }
    return children;
  }

  protected setChildOpacity(childPositions:{x:number,center:number,width:number}[]) {
    if (childPositions && childPositions.length) {
      let containerElement = this.containerElementRef.nativeElement;
      let containerCenter  = containerElement.offsetWidth/2;
      let children = this.wrapperElementRef.nativeElement.children;
      for (let i=0; i<children.length; i++) {
        //console.log("setChildOpacity",children.length,children);
        let childElement = children[i];
        let centerDiff   = containerCenter-childPositions[i].center;
        //console.log("child",childElement,"childCenter",childCenter,"centerDiff",centerDiff);
        let opacity      = Math.min(1, Math.max(0.1, 1 - (Math.abs(centerDiff)/(containerCenter*0.9))));
        //console.log("child",childElement,"childCenter",childPositions[i].center,"centerDiff",centerDiff);
        childElement.style.opacity = opacity;
      }
    }
  }

  protected getChildPositions(displacement:number) : {x:number,center:number,width:number}[] {
    let containerElement = this.containerElementRef.nativeElement;
    let wrapperElement   = this.wrapperElementRef.nativeElement;
    let children         = wrapperElement.children;
    let result:{x:number,center:number,width:number}[] = [];
    if (children && children.length>0) {
      let containerLeft = -displacement;
      for (let element=wrapperElement;element && element!=containerElement;element=element.parentElement) {
        containerLeft += element.offsetLeft;
      }
      for (let i=0; i<children.length; i++) {
        let childElement = children[i];
        result.push({
          x: containerLeft + childElement.offsetLeft,
          center: containerLeft + childElement.offsetLeft + (childElement.offsetWidth/2),
          width: childElement.offsetWidth
        });
      }
    }
    return result;
  }

  // when touched is protected builder reports and error:
  // "Directive FilterCarouselComponent, Property 'touched' is protected and only accessible within class 'FilterCarouselComponent' and its subclasses."
  // @HostBinding('class.focus') protected touched: boolean = false;
  @HostBinding('class.focus') public touched: boolean = false;
  protected touchTracker: TouchTracker = new TouchTracker();
  protected displacement: number = 0;
  protected touchClick:boolean = false;
  protected animationInterval:number = undefined;
  protected horizontalDragFreezed:boolean = false;

  @HostListener('touchstart',['$event'])
  @HostListener('mousedown',['$event'])
  onPointerDown(event: MouseEvent | TouchEvent): void {
    //console.log("pointerdown",event,"!started",!this.touchTracker.isStarted(),"valid",this.touchTracker.isValidEvent(event));
    if (!this.touchTracker.isStarted() &&
         this.touchTracker.isValidEvent(event)) {
      this.horizontalDragFreezed = false;
      this.touched = true;
      this.snapAnimationState = 'flow';
      //let contentElement = this.contentElementRef.nativeElement;
      //let style  = getComputedStyle(contentElement, null);
      //let matrix = Matrix.fromString(style.getPropertyValue('transform'));
      //this.touchTracker.initialize(event,-matrix.translateX,-matrix.translateY);
      this.touchTracker.initialize(event,this.displacement,0);
      this.touchClick = true;
      try {
        let dragEvent:DragEvent = {
          deltaX:0,
          deltaY:0,
          type:'start',
          cancel:false,
          stopPropagation:false,
          preventDefault: false,
          startPageX:this.touchTracker.start.pageX,
          startPageY:this.touchTracker.start.pageY
        };
        this.onDrag.emit(dragEvent);
        if (dragEvent.cancel) {
          this.onPointerUp(event);
          event.stopPropagation();
          //console.log("onPointerDown::event.stopPropagation()");
        } else if (dragEvent.stopPropagation) {
          event.stopPropagation();
          //console.log("onPointerDown::event.stopPropagation()");
        }
      } catch(e) {}
      //console.log("pointerdown",event,"inside");
      //console.log("pointerdown",event,"containerElement.scrollLeft");
    }
  }

  @HostListener('document:touchmove',['$event'])
  @HostListener('document:mousemove',['$event'])
  onPointerMove(event: MouseEvent | TouchEvent): void {
    if (this.touched) {
      this.touched = this.touchTracker.isValidEvent(event);
      //console.log("pointermove",this.touched);
      if (this.touched) {
        let difference = this.touchTracker.track(event);
        if (!this.isHorizontalDragFreezed()) {
          let containerElement = this.containerElementRef.nativeElement; //.parentElement;
          let containerWidth   = containerElement.offsetWidth;
          let contentElement   = this.contentElementRef.nativeElement;
          let style            = getComputedStyle(contentElement,null);
          var marginLeft       = parseInt(style.marginLeft) || 0;
          var marginRight      = parseInt(style.marginRight) || 0;
          let contentWith      = contentElement.offsetWidth + marginLeft + marginRight;
          let maximum          = contentWith-containerWidth;
          let current          = this.touchTracker.startX-difference.totalX;
          let limited          = Math.max(0, Math.min(maximum,Math.abs(current))*(current<0 ? -1 : 1));
          contentElement.style.transform = "translateX("+(-limited)+"px)";
          this.displacement    = limited;
          this.snapAnimationTo = this.snapAnimationFrom = -this.displacement+'px';
          this.touchClick      = this.touchClick && (Math.abs(difference.totalX)<10 && Math.abs(difference.totalY)<10);
          this.setChildOpacity(this.getChildPositions(limited));
          //console.log("pointermove",event,difference,"containerElement.scrollLeft:",containerElement.scrollLeft,"containerWidth",containerWidth,"contentWith",contentWith,"maximum",maximum,"current",current,"limited",limited);
        }
        //console.log("pointermove",event,difference);
        try {
          let dragEvent:DragEvent = {
            deltaX:this.touchTracker.pageX-this.touchTracker.start.pageX,
            deltaY:this.touchTracker.pageY-this.touchTracker.start.pageY,
            type:'drag',
            cancel:false,
            stopPropagation:false,
            preventDefault: false,
            startPageX:this.touchTracker.start.pageX,
            startPageY:this.touchTracker.start.pageY
          };
          this.onDrag.emit(dragEvent);
          if (dragEvent.cancel) {
            this.onPointerUp(event);
            event.stopPropagation();
            //console.log("onPointerMove::event.stopPropagation()");
          } else if (dragEvent.stopPropagation) {
            event.stopPropagation();
            //console.log("onPointerMove::event.stopPropagation()");
          }
        } catch(e) {}
      }
    }
  }

  @HostListener('document:touchend',['$event'])
  @HostListener('document:touchcancel',['$event'])
  @HostListener('document:mouseup',['$event'])
  //@HostListener('document:mouseleave',['$event'])
  @HostListener('document:mouseout', ['$event'])
  onPointerUp(event: MouseEvent | TouchEvent): void {
    //console.log("onPointerUp",event,this.touched);
    let mouseout = event.type=='mouseout';
    let pageout  = false;
    if (this.touched && mouseout) {
      let pageX = event instanceof MouseEvent ? event.pageX : event.touches.length ? event.touches[0].pageX : 0;
      let pageY = event instanceof MouseEvent ? event.pageY : event.touches.length ? event.touches[0].pageY : 0;
      //console.log("event",event,pageX,pageY,document.body.clientWidth,document.body.clientHeight);
      pageout = (pageX<=0 || pageY<=0 ||
                 pageX>=document.body.clientWidth ||
                 pageY>=document.body.clientHeight);
    }
    if (this.touched && (!mouseout || pageout)) {
      this.horizontalDragFreezed = false;
      this.touched = false;
      this.touchClick = this.touchClick && this.touchTracker.elapsed()<1000;
      try {
        let dragEvent:DragEvent = {
          deltaX:this.touchTracker.pageX-this.touchTracker.start.pageX,
          deltaY:this.touchTracker.pageY-this.touchTracker.start.pageY,
          type:'stop',
          cancel:false,
          stopPropagation:false,
          preventDefault: false,
          startPageX:this.touchTracker.start.pageX,
          startPageY:this.touchTracker.start.pageY
        };
        this.onDrag.emit(dragEvent);
        if (dragEvent.stopPropagation) {
          event.stopPropagation();
          //console.log("onPointerUp::event.stopPropagation()");
        }
      } catch(e) {}
      let childPositions = this.getChildPositions(this.displacement);
      //console.log("childPositions",childPositions.length,childPositions);
      if (childPositions.length) {
        let containerElement     = this.containerElementRef.nativeElement;
        let containerCenter      = containerElement.offsetWidth / 2;
        let wrapperElement       = this.wrapperElementRef.nativeElement;
        let children             = wrapperElement.children;
        let childIndex:number    = undefined;
        let childDistance:number = undefined;
        if (this.touchClick) {
          for (let i=0; i<children.length; i++) {
            let childElement  = children[i];
            for (let node : Node = <Node>event.target; node && node!=wrapperElement; node = node.parentElement) {
              if (node==childElement) {
                childIndex    = i;
                childDistance = childPositions[i].center-containerCenter;
                //console.debug("emit:"+i);
                //this.onSelect.emit(i);
                //this.touched = false;
                break;
              }
            }
          }
        }
        //console.log("childIndex",childIndex);
        if (childIndex===undefined) {
          childPositions.forEach((child,index) => {
            let distance = child.center-containerCenter;
            if (childDistance===undefined || Math.abs(childDistance)>Math.abs(distance)) {
              childIndex = index;
              childDistance = distance;
            }
          });
        }
        //console.log("onPointerUp::EMIT.before");
        this.previousSelected = -1;
        this.onSelect.emit(childIndex);
        //console.log("onPointerUp::EMIT",childIndex);
      }
      //console.log("pointermove",this.previousSelected);

      //this.touchTracker.stop();
      //console.log("pointerup",event);
    }
  }

  onSnapDoneEvent(event:AnimationEvent) {
    //console.log("onSnapDoneEvent",event);
    if (event.toState == 'snap') {
      this.setDisplacement(this.displacement);
    }
  }

  setDisplacement(displacement:number) {
    //console.log("setDisplacement("+displacement+"):start snapAnimationFrom",this.snapAnimationFrom,"snapAnimationTo",this.snapAnimationTo,"snapAnimationState",this.snapAnimationState,"touched",this.touched);
    this.snapAnimationFrom  = this.snapAnimationTo = (-displacement)+'px';
    this.snapAnimationState = 'flow';
    this.displacement       = displacement;
    this.touched            = false;
    this.touchTracker.stop();
    if (this.animationInterval) {
      clearInterval(this.animationInterval);
      this.animationInterval = undefined;
    }
    this.contentElementRef.nativeElement.style.transform = "translateX("+this.snapAnimationTo+")";
    this.setChildOpacity(this.getChildPositions(this.displacement));
    //console.log("setDisplacement("+displacement+"):end snapAnimationFrom",this.snapAnimationFrom,"snapAnimationTo",this.snapAnimationTo,"snapAnimationState",this.snapAnimationState,"touched",this.touched);
    this.triggerChangeDetection();
  }

  forceRedraw(): void {
    let display = this.containerElementRef.nativeElement.style.display;
    this.containerElementRef.nativeElement.style.display = 'none';
    let height = this.containerElementRef.nativeElement.offsetHeight;
    this.containerElementRef.nativeElement.style.display = display;
  }

  onSelected(index : number) {
    if (this.previousSelected != index) {
      let childPositions = this.getChildPositions(this.displacement);
      //console.log("onSelected:",index,"childPositions",childPositions);
      if (index>=0 && index<childPositions.length) {
        let instant   = this.previousSelected===undefined;
        this.selected = this.previousSelected = index;
        let containerElement     = this.containerElementRef.nativeElement;
        let containerCenter      = containerElement.offsetWidth / 2;
        let childDistance        = childPositions[index].center-containerCenter;
        //console.log("onSelected:",index,"instant",instant,"childDistance",childDistance,"displacement",this.displacement,this.displacement+childDistance,this.displacement+childDistance+childPositions[0].center);
        if (childDistance!=0) {
          if (instant || this.snapAnimationState == 'snap') {
            //console.log("onSelected("+index+"):!=0 tick instant",instant,"displacement",this.displacement,"snapAnimationFrom",this.snapAnimationFrom,"snapAnimationTo",this.snapAnimationTo,"snapAnimationState",this.snapAnimationState);
            this.setDisplacement(this.displacement+childDistance);
            // this.application.tick();
          } else {
            //console.log("onSelected("+index+"):!=0 instant",instant,"displacement",this.displacement,"snapAnimationFrom",this.snapAnimationFrom,"snapAnimationTo",this.snapAnimationTo,"snapAnimationState",this.snapAnimationState);
            this.snapAnimationFrom  = -this.displacement+'px';
            this.snapAnimationTo    = -(this.displacement+childDistance)+'px';
            this.snapAnimationTime  = '300ms';
            this.snapAnimationState = 'snap';
            this.displacement      += childDistance;
            //console.log("pointerup",event,"displacement",this.displacement,"childDistance",childDistance,"touchClick",this.touchClick);
            let childPositions = this.getChildPositions(this.displacement);
            let contentElement = this.contentElementRef.nativeElement;
            let style          = getComputedStyle(contentElement, null);
            //let startTime      = Date.now();
            //console.log("\t\ndisplacement",this.displacement,"snapAnimationFrom",this.snapAnimationFrom,"snapAnimationTo",this.snapAnimationTo,"snapAnimationState",this.snapAnimationState);
            // this.application.tick();
            this.animationInterval = window.setInterval(()=> {
              let matrix = Matrix.fromString(style.getPropertyValue('transform'));
              let difference = this.displacement + matrix.translateX;
              //console.debug("time "+(Date.now()-startTime)+" x:"+matrix.translateX+" target:"+this.displacement);
              this.setChildOpacity(childPositions.map(cp => {
                return { x:cp.x-difference, center:cp.center-difference, width:cp.width };
              }));
              //this.touchTracker.initialize(event,-matrix.translateX,-matrix.translateY);
              if (!this.touchTracker.isStarted()) {
                clearInterval(this.animationInterval);
                this.animationInterval = undefined;
              }
            });
            this.triggerChangeDetection();
            // this.changeDetector.markForCheck();
            //this.forceRedraw();
          }
        } else {
          //console.log("onSelected("+index+"):0+tick displacement",this.displacement,"snapAnimationFrom",this.snapAnimationFrom,"snapAnimationTo",this.snapAnimationTo,"snapAnimationState",this.snapAnimationState);
          this.setDisplacement(this.displacement);
          // this.application.tick();
        }
      }
    }
  }

  triggerChangeDetection() {
    // this.application.tick();
    window.setTimeout(() => this.changeDetector.markForCheck());
  }
}
