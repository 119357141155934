import {Component, ElementRef, Inject, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {animate, style, transition, trigger} from "@angular/animations";
import {
  BasicContainerComponent,
  SlideEvent,
  SlidePanelComponent,
  SlidePanelContainerComponent,
  UploadStats
} from "shared";
import {UploadService} from "../../upload.service";
import {Logger, prettyDateDiff} from "core";
import {BehaviorSubject, last, Observable, of, startWith} from "rxjs";
import {scan, takeUntil} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {ResizeSensor} from "css-element-queries";
import {UploadTypeListComponent} from "../upload-type-list/upload-type-list.component";
import {UPLOAD_TYPE} from "upload/lib/components/upload-type-list/upload-type";

@Component({
  selector: 'app-upload-bottom-sheet',
  templateUrl: './upload-bottom-sheet.component.html',
  styleUrls: ['./upload-bottom-sheet.component.scss'],
  animations: [
    trigger('panelActivation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms ease-in', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('300ms ease-out', style({opacity: 0}))
      ])
    ])
  ]
})
export class UploadBottomSheetComponent extends BasicContainerComponent {

  @ViewChild(SlidePanelContainerComponent, { read: ElementRef }) slidePanelRef: ElementRef;
  @ViewChildren(UploadTypeListComponent, { read: ElementRef }) uploadTypeListRefs: QueryList<ElementRef>;

  panel: 'uploadTypes' | 'uploads' = 'uploadTypes';
  slidePanel: 'uploadTypes' | 'selector' = 'uploadTypes';
  selector: TemplateRef<any>;


  uploadRef = (id: string) => this.uploadService.getUploadRef(id);
  uploadItemSelected = (type: UPLOAD_TYPE, item: any) => {
    if (typeof this.data.onUploadItemSelect=='function') {
      const promise = this.data.onUploadItemSelect(type, item);
      if (promise) {
        promise//.then(() => this.bottomSheetRef.dismiss())
          .catch((error) => console.error('UPLOAD ERROR', {type, item, error}));
      }
    }
  };
  uploadStats = new BehaviorSubject<UploadStats>(null);
  uploadRemainingTime: Observable<String>;

  protected logger = new Logger('UploadBottomSheetComponent');
  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              public bottomSheetRef: MatBottomSheetRef<UploadBottomSheetComponent>,
              public uploadService: UploadService,
              public translateService: TranslateService,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    super();
    this.data  = this.data || {};
    this.panel = this.data.mode || this.panel;
  }

  ngOnInit() {
    super.ngOnInit();
    this.uploadService.getUploadStats()
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe((uploadStats) => {
        this.uploadStats.next(uploadStats);
        const uploadRemainingTime = (remainingTime): Observable<string> => {
          if (remainingTime) {
            const now = new Date().getTime();
            const [units, unitName] = prettyDateDiff(now, now + remainingTime*1000);
            if (units >= 0) {
              const key = units > 0 ? `moment.remaining.${unitName}${units > 1 ? 's' : ''}` : 'moment.now';
              return this.translateService.stream(key, {units: units});
            }
          }
          return of('');
        };
        this.uploadRemainingTime = uploadRemainingTime(uploadStats.remainingTime);
        if (uploadStats.count==0 && this.panel=='uploads' && this.data.mode!='uploads') {
          this.togglePanel();
        }
      });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.uploadTypeListRefs.changes
      .pipe(
        takeUntil(this.onDestroy$),
        startWith(0),
        scan((state: { sensor: ResizeSensor }, changes: any) => {
          this.logger.debug('SENSOR');
          const uploadTypesList = this.uploadTypeListRefs.first;
            if (uploadTypesList && !state.sensor) {
              this.logger.debug('SENSOR CREATE');
              state.sensor = new ResizeSensor(
                uploadTypesList.nativeElement,
                (size) => {
                  if (size && size.height && this.slidePanelRef?.nativeElement) {
                    this.renderer.setStyle(
                      this.slidePanelRef.nativeElement,
                      'height',
                      Math.max(0, size.height) + 'px'
                    );
                  }
                }
              );
            } else if (!uploadTypesList && state.sensor) {
                this.logger.debug('SENSOR DETACH');
                state.sensor.detach();
                state.sensor = undefined;
            }
            return state;
          }, { sensor: undefined }
        ),
        last()
      ).subscribe(state => {
        this.logger.debug('LAST SENSOR DETACH', state);
        state.sensor?.detach();
      })
  }

  onSelectType(type: string) {
    const selector = this.data.selectors?.[type];
    if (selector) {
      this.selector = selector;
      this.slidePanel = 'selector';
    } else if (typeof this.data.onUploadTypeSelect=='function') {
      this.data.onUploadTypeSelect(type);
    }
  }

  onAddLink(link: string) {
    if (typeof this.data.onLinkAdd=='function') {
      const promise = this.data.onLinkAdd(link);
      if (promise) {
        promise//.then(() => this.bottomSheetRef.dismiss())
          .catch((error) => console.error('LINK UPLOAD ERROR', error));
      }
    }
  }

  onTapClose($event: MouseEvent) {
    this.bottomSheetRef.dismiss();
  }

  togglePanel() {
    this.panel = this.panel=='uploadTypes' ? 'uploads' : 'uploadTypes';
    // window.setTimeout(() => this.updateStyles());
  }

  toggleSlidePanel() {
    this.slidePanel = this.slidePanel=='uploadTypes' ? 'selector' : 'uploadTypes';
  }

  updateStyles() {
    // const nodes = this.elementRef.nativeElement.querySelectorAll('.mat-list-item-content, .mat-list-text') as NodeList;
    const nodes = this.elementRef.nativeElement.querySelectorAll('.mat-list-text') as NodeList;
    nodes && nodes.forEach(listItem=> {
      this.renderer.setStyle(listItem, 'padding-right', '0px');
    });
  }

  onSlide(event: SlideEvent) {
  }
}
