import {Inject, Injectable, InjectionToken, Optional} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {APP_ID} from "../app/app.provider";
import {LOCAL_STORAGE} from '../storage/storage.module';
import {SyncStorage} from '../storage/sync/storage';
import {Logger} from '../logger.service';
import {filter, map, startWith} from "rxjs";

export const APP_DEFAULT_PATH_PROVIDER = new InjectionToken<(ignoreStoredUrl: boolean) => string>('appDefaultPathProvider');

@Injectable({
  providedIn: 'root'
})
export class RoutePathService {

  currentUrl : string;
  protected history : string[] = [];
  protected logger = new Logger('RoutePathService');

  constructor(private router: Router,
              @Optional()
              @Inject(APP_DEFAULT_PATH_PROVIDER) protected appDefaultPathProvider: (ignoreStoredUrl) => string,
              @Inject(LOCAL_STORAGE) protected storage: SyncStorage,
              @Inject(APP_ID) protected appId: number) {
    this.logger.debug('ctor');
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url),
      startWith(this.router.url),
      filter(url => !!url)
    )
    .subscribe(url => {
      this.currentUrl = url;
      if (this.history?.[this.history.length-1] != url) {
        this.history.push(url);
      }
    });
  }

  initialize(appDefaultPathProvider: () => string) {
    this.appDefaultPathProvider = appDefaultPathProvider;
  }

  getDefaultUrl(ignoreStoredUrl?: boolean): string {
    return this.appDefaultPathProvider?.(ignoreStoredUrl) || '';
  }

  getCurrentUrl() : string {
    return this.currentUrl;
  }

  getPreviousUrl() : string {
    if (this.currentUrl && this.history.length) {
      if (this.currentUrl!=this.history[this.history.length-1]) {
        return this.history[this.history.length-1];
      } else if (this.history.length>=2) {
        return this.history[this.history.length-2];
      }
    }
    return undefined;
  }

  getPreviousPathTrunkUrl() : string {
    if (this.currentUrl && this.history.length) {
      const comparePathTrunk = (path1: string, path2: string) => {
        const trunk1 = path1?.split('/')[1];
        const trunk2 = path2?.split('/')[1];
        return trunk1?.localeCompare(trunk2);
      }
      const samePathTrunk = (path1: string, path2: string) => comparePathTrunk(path1, path2)==0;
      if (!samePathTrunk(this.currentUrl, this.history[this.history.length-1])) {
        return this.history[this.history.length-1];
      } else if (this.history.length>=2) {
        for (let index=this.history.length-1; index>=0; index--) {
          if (!samePathTrunk(this.history[index], this.history[index-1])) {
            return this.history[index-1];
          }
        }
      }
    }
    return undefined;
  }

  clearHistory(keep?: number) {
    this.history.splice(0, this.history.length - Math.min(Math.max(0, keep), this.history.length));
  }
}
