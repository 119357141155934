export interface AsyncStorage<T = any> {
  has(key: string): Promise<boolean>;
  get(key: string): Promise<T>;
  set(key: string, value: T): Promise<void>;
  values(keys?:string[]): Promise<T[]>;
  remove(keys?:string|string[]): Promise<void>;
  clear(): Promise<void>;
}

export abstract class AsyncStorageFactory {
  abstract request<T=any>(db:string,store:string):AsyncStorage<T>;
}
