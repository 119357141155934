import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {propertiesLoadRequest, propertiesLoadSuccess, propertiesLoadFailure} from "./actions";
import {APP_ID, DeviceService, ENVIRONMENT, NULL_USER, User} from "core";
import {Properties} from "../models/properties";
import {PropertiesState} from "./reducers";

@Injectable()
export class PropertiesEffects {

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(propertiesLoadRequest),
      mergeMap((action) =>
        from(Promise.all([
          this.deviceService.device(),
          this.deviceService.package()
        ])).pipe(
          switchMap(([device, pkg]) => {
            const identifier = action.identifier ? action.identifier : '';
            const body: any = {
              'device': device,
              'app_version': this.environment.version,
              'path': window.location.pathname
            }
            if (pkg) {
              body.package = pkg;
            }
            return this.http
              .put<Properties>(`/v1.0/bootstrap/${identifier}?app=${this.appId}&select=all`, body)
              .pipe(
                switchMap(data => {
                  if (device.previous) {
                    return from(
                      device.purge().catch((error) => console.error('Failed to purge device', error))
                    ).pipe(map(result => data));
                  } else {
                    return of(data);
                  }
                }),
                map(data => propertiesLoadSuccess({
                  correlationId: action.correlationId,
                  identifier: action.identifier,
                  properties: {...data, user: data.user ? new User(data.user) : this.nullUser}
                }))
              );
          }),
          catchError(error => of(propertiesLoadFailure({
            correlationId: action.correlationId,
            identifier: action.identifier,
            error
          })))
        )
      )
    )
  );

  /*
  @Effect()
  load: Observable<Action> = this.actions$.pipe(
    ofType<PropertiesLoadRequestAction>(PropertiesActionIds.LOAD_REQUEST),
    mergeMap((action) =>
      from(this.deviceService.device()).pipe(
        switchMap((device) => {
          let identifier = action.identifier ? action.identifier : '';
          return this.http.put<Properties>(
            `/bootstrap/${identifier}?app=${this.appId}&select=all`,
            {'device': device, 'app_version': this.environment.version}
            ).pipe(
              switchMap(data => {
                if (device.previous) {
                  return from(
                    device.purge().catch((error) => console.error('Failed to purge device', error))
                  ).pipe(map(result => data));
                } else {
                  return of(data);
                }
              }),
              map(data => new PropertiesLoadSuccessAction(
                action.identifier,
                {...data, user: data.user ? new User(data.user) : this.nullUser}
              ))
            )
        }),
        catchError(error => of(new PropertiesLoadFailureAction(action.identifier, error)))
      )
    )
  );
  */

  constructor(private http: HttpClient,
              private actions$: Actions,
              private store$: Store<PropertiesState>,
              private deviceService: DeviceService,
              @Inject(NULL_USER) protected nullUser: User,
              @Inject(APP_ID) private appId: number,
              @Inject(ENVIRONMENT) private environment: any) {
  }
}
