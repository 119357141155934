<mat-toolbar color="primary" fxLayout="row">
  <span translate>media.filters.label</span>
  <button mat-icon-button (click)="clearFilter()" [disabled]="filterCount === 0" aria-label="close">
    <mat-icon>delete</mat-icon>
    <div class="badge" *ngIf="filterCount > 0">{{filterCount}}</div>
  </button>
  <button mat-icon-button (click)="layoutService.details.toggle()" aria-label="close">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<accordion-container *ngIf="sections" [sections]="sections">
</accordion-container>
<!--
<accordion-container *ngIf="sections" [sections]="sections" (changed)="onFilter($event)">
</accordion-container>
-->
