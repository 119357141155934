<!--<div *ngFor="let language of allLanguages$ | async">{{language.name}}</div>-->
<virtual-section-scroller #scroller [items$]="allLanguages$"
                          [itemsId$]="itemsId$"
                          [startFromTop]="true"
                          [itemDefaultSize]="48"
                          [startGap]="8"
                          [endGap]="8"
                          (onResize)="onResize($event)">
  <ng-container *virtualFor="let language of scroller.viewportItems offset scroller.viewportOffset total scroller.items?.length;
                              let index=index;
                              let total=total;
                              let last=last;
                              trackBy:trackLanguage">
    <div *ngIf="context(language, index) as context"
                [class.lru]="context.isLru"
                (click)="onSelectionChange(index, language, $event)"
                class="language-container">
      <span class="language" dir="{{getDir(language.code)}}">{{language.name}}</span>
      <div *ngIf="context.isLru" class="controls" (click)="$event.stopPropagation(); $event.preventDefault();">
        <ng-container *ngTemplateOutlet="controlsTemplate; context: { index: index, language: language, isLru: context.isLru }"></ng-container>
      </div>
      <mat-divider *ngIf="!last" [class.strong]="context.sectionEnd"></mat-divider>
    </div>
  </ng-container>
</virtual-section-scroller>
