import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Inject,
  INJECTOR,
  Injector,
  Input,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import {BehaviorSubject, Observable, takeUntil} from 'rxjs';
import {
  AccordionSectionComponent,
  AccordionSectionDefinition,
  AccordionSectionHeaderBadgeDefinition,
  AccordionSectionHeaderButtonDefinition
} from '../accordion-container/accordion-container.component';
import {BasicContainerComponent} from '../basic-container/basic-container.component';
import {Logger} from "core";

@Component({
  selector: 'accordion-section-container',
  templateUrl: './accordion-section-container.component.html',
  styleUrls: ['./accordion-section-container.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionSectionContainerComponent extends BasicContainerComponent {

  @ViewChild('sectionContent', { read: ViewContainerRef }) content: ViewContainerRef;

  protected componentSubject$ = new BehaviorSubject<ComponentRef<AccordionSectionComponent>>(undefined);
  component$: Observable<ComponentRef<AccordionSectionComponent>> = this.componentSubject$.asObservable();

  _sectionComponent: AccordionSectionComponent;
  _sectionDefinition: AccordionSectionDefinition;
  //_changed$: EventEmitter<FilterChangeEvent>;

  // @Output() actionsTemplate = new EventEmitter<TemplateRef<any>>();
  // @Output() buttonsTemplate = new EventEmitter<TemplateRef<any>>();
  @Output() isHidden = new EventEmitter<boolean>();
  @Output() headerBadges = new EventEmitter<AccordionSectionHeaderBadgeDefinition | AccordionSectionHeaderBadgeDefinition[]>();
  @Output() headerButtons = new EventEmitter<AccordionSectionHeaderButtonDefinition | AccordionSectionHeaderButtonDefinition[]>();

  protected logger = new Logger('AccordionSectionContainerComponent');

  constructor(public resolver: ComponentFactoryResolver,
             @Inject(INJECTOR) public injector: Injector,) {
    super();
    // console.debug("CTOR."+this.constructor.name+"."+this.instanceId);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.component$.pipe(takeUntil(this.onDestroy$)).subscribe(component => {
      this._sectionComponent = component.instance;
      this.content.clear();
      this.content.insert(component.hostView);
    });
  }

  @Input()
  set section(sectionDefinition: AccordionSectionDefinition) {
    this.logger.debug('section', sectionDefinition);
    if (!!sectionDefinition) {
      const createComponent = !isEqual(this._sectionDefinition?.id, sectionDefinition.id) ||
                              !isEqual(this._sectionDefinition?.type, sectionDefinition.type);
      const updateContext   = createComponent ||
                              !isEqual(this._sectionDefinition?.context?.topics, sectionDefinition.context?.topics) ||
                              !isEqual(this._sectionDefinition?.context?.search, sectionDefinition.context?.search) ||
                              !isEqual(this._sectionDefinition?.context?.multiselect, sectionDefinition.context?.multiselect);
      if (createComponent) {
        console.debug("SECTION."+this.constructor.name+"."+this.instanceId,sectionDefinition);
        const factory = sectionDefinition.factoryService.getViewerFactory(sectionDefinition.type, sectionDefinition.context);
        if (!!factory) {
          const component = factory.createComponent(this.injector, this.resolver);
          // component.instance.headerBadges?.subscribe(badges => {
          //   this.headerBadges.emit(badges);
          // });
          component.instance.headerButtons?.subscribe(buttons => {
            this.headerButtons.emit(buttons)
          });
          /*component.instance.changed?.subscribe(event => {
            this.logger.debug('CHANGED', component.instance, event);
            this.changed.emit(event);
          });*/
          component.instance.isHidden?.subscribe(hidden => {
            this.isHidden.emit(hidden);
          })
          this.componentSubject$.next(component);
        }
      }
      if (updateContext) {
        this.componentSubject$.value.instance.context = sectionDefinition.context;
      }
      this._sectionDefinition = sectionDefinition;
    }
  }

  get section(): AccordionSectionDefinition {
    return this._sectionDefinition;
  }

  /*
  @Output()
  get changed() {
    this._changed$ = this._changed$ ?? new EventEmitter();
    return this._changed$;
  }*/

  onOpen() {
    if (this._sectionComponent && this._sectionComponent.onOpen) {
      this._sectionComponent.onOpen();
    }
  }
}
