import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { combineLatest, Observable, takeUntil } from 'rxjs';
import { FilterService, FilterTagEvent } from 'filter';
import { MediaDetailsShareDataService } from '../../service/media-details-share-data.service';
import { MediaDetailsSectionComponent } from '../media-details/media-details-section.component';
import { Topic } from 'core';
import {PropertiesService} from "properties";

@Component({
  selector: 'media-details-filter',
  templateUrl: './media-details-filter.component.html',
  styleUrls: ['./media-details-filter.component.scss']
})
export class MediaDetailsFilterComponent extends MediaDetailsSectionComponent {

  public mediaTags: string[];
  // public filters: any[];
  // filters$: Observable<Topic[]>;
  filters: Topic[];
  userRoleFilter: Topic;
  userRoleFilterVisibilityFunction: (filter:Topic)=>boolean;
  filterVisible: boolean[];
  isLeader: boolean;

  constructor(
    private filterService: FilterService,
    protected mediaDetailsShareDataService: MediaDetailsShareDataService,
    private propertiesService: PropertiesService
  ) {
    super(mediaDetailsShareDataService);
    this.mediaDetailsShareDataService.getMedia$.pipe(takeUntil(this.onDestroy$)).subscribe(media => {
      if (this.context?.filters) {
        this.context.filters.pipe(takeUntil(this.onDestroy$)).subscribe(filters => {
          this.filters = filters;
          this._isHidden.emit(!this.filters);
        });
      }
    });
    this.userRoleFilterVisibilityFunction = this.isRoleFilterVisible.bind(this);
    this.userRoleFilter = {
      id:'role',
      label:'media.userRoles',
      topics: [
        {
          id: 'role.user',
          label: 'userRoles.user'
        },
        {
          id: 'role.leader',
          label: 'userRoles.leader'
        }
      ]
    };
  }

  ngOnInit(): void {
    const filters$ = this.filterService.getFilterTopics$(undefined, undefined,
      (filter, root, level, path, view) => {
        //console.log("GET_FILTER_TOPICS",path,filter,root);
        if (filter.tags?.includes('media')) {
          return true;
        }
        return false;
      });

    combineLatest([
      this.mediaDetailsShareDataService.getEditMode$,
      this.mediaDetailsShareDataService.getMediaTags$,
      filters$,
      this.propertiesService.user$
    ]).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(([editMode, mediaTags, filters, user]) => {
      this.editMode = editMode;
      this.mediaTags = mediaTags;
      this.filterVisible = Array.from({ length: filters.length }, () => true);
      this.filters = filters;
      this.isLeader = user.isLeader || user.isAdmin;
    });
  }

  onTapFilter(event: FilterTagEvent) {
    this.toggleFilter(event.filter.id);
  }

  toggleFilter(filterId: string) {
    const newTags = this.filterService.setFilter(this.mediaTags, filterId, false);
    this.mediaDetailsShareDataService.setMediaTags(newTags);
  }

  filterCheck(event, index) {
    this.filterVisible[index] = event;
    if (this.filterVisible.some(val => val)) {
      this.isHidden.emit(false);
    } else {
      this.isHidden.emit(true);
    }
  }

  isRoleFilterVisible(filter:Topic):boolean {
    console.log("isRoleFilterVisible",this.isLeader,filter,this.userRoleFilter);
    return this.isLeader || filter===this.userRoleFilter;
  }
}
