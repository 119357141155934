import {from, Observable} from "rxjs";
import {isValidNumber} from "../utils";

export function createCacheId() : string {
  return 'cid_'+Date.now()+'_'+Math.floor((Math.random() * 1000) + 1);
}

export function createFilterId() : string {
  return 'fid_'+Date.now()+'_'+Math.floor((Math.random() * 1000) + 1);
}

function _createProxyArray<T>(array:Array<T>, offset:number,
  getAccessor:(array:Array<T>,index:number,value:T)=>T,
  setAccessor:(array:Array<T>,index:number,value:T,newValue:T)=>T,
  spliceAccessor:(array:Array<T>,index:number,removed:T[],added:T[])=>void) : Array<T> {
  let backingHooks:IndexedArrayHooks<T> = undefined;
  let backingArray:Array<T> = undefined;
  let backingIndices:{[key:string]: number} = undefined;
  let backingIndicesProxy:any = undefined;
  return new Proxy(array, {
    get(target : Array<T>, property:PropertyKey, receiver : Array<T>) {
      //console.trace("PROXY.get",property,"array.size",array.length,"offset",offset,"value",value);
      const propertyName = property.toString();
      const index = Number(propertyName);
      if (isValidNumber(index)) {
        return getAccessor(target,index,target[index]);
      } else if (propertyName=='length') {
        return target[property];
      } else if (propertyName=='slice') {
        return (begin : number, end ?: number) => {
          const slice = target.slice(begin,end);
          return _createProxyArray(slice,offset+begin, (sliceArray:Array<T>,sliceIndex:number,value:T) : T => {
            return getAccessor(target,begin+sliceIndex, target[begin+sliceIndex]);
          },(sliceArray:Array<T>,sliceIndex:number,value:T,previousValue:T) : T => {
            return setAccessor(target,begin+sliceIndex, value, previousValue);
          }, (sliceArray:Array<T>,sliceIndex:number,removed:T[],added:T[]):void => {
            spliceAccessor(sliceArray,begin+sliceIndex,removed,added);
          });
        }
      } else if (propertyName=='splice') {
        return (start: number, deleteCount: number, ...added: T[]): T[] => {
          let removed = added?.length>0 ?
            target.splice(start,deleteCount,...added) :
            target.splice(start,deleteCount);
          spliceAccessor(target,start,removed,added||[]);
          return removed;
        }
      } else if (propertyName=='backingHooks') {
        return backingHooks;
      } else if (propertyName=='backingArray') {
        return backingArray;
      } else if (propertyName=='backingIndices') {
        return backingIndices;
      } else if (propertyName=='backingIndicesProxy') {
        return backingIndicesProxy;
      } else {
        return target[property];
      }
    },
    set(target: Array<T>, property: PropertyKey, value: T, receiver: any): boolean {
      const propertyName = property.toString();
      const index        = Number(propertyName);
      if (isValidNumber(index)) {
        target[index] = setAccessor(target,index,target[index],value);
      } else if (propertyName=='backingHooks') {
        backingHooks = <any>value;
      } else if (propertyName=='backingArray') {
        backingArray = <any>value;
      } else if (propertyName=='backingIndices') {
        backingIndices = <any>value;
      } else if (propertyName=='backingIndicesProxy') {
        backingIndicesProxy = <any>value;
      } else {
        target[property] = value;
      }
      return true;
    }
  });
}

export function createPageLoadingArray<T>(size : number, pageSize : number, loadPage : (pageIndex:number,pageSize:number)=>void) : T[] {
  return createPageLoadingProxy(new Array<T>(size),pageSize,loadPage);
}

export function createPageLoadingProxy<T>(backingArray : Array<T>, pageSize : number, loadPage : (pageIndex:number,pageSize:number)=>void) : T[] {
  let proxyArray = _createProxyArray(backingArray,0, (array:Array<T>,index:number,value:T):T => {
    //console.debug("array access index:"+index+" value:"+value);
    if (value === undefined) {
      let page      = Math.floor(index/pageSize);
      let pageIndex = page*pageSize;
      let pageMax   = Math.min(pageSize,array.length-pageIndex);
      for (let i=0; i<pageMax; i++) {
        array[pageIndex+i] = null;
      }
      //console.debug("array access index:"+index+" load page["+pageIndex+"/"+pageMax+"]");
      window.setTimeout(()=>{
        loadPage(pageIndex,pageMax);
      },0);
      return null;
    }
    return value;
  }, (array:Array<T>,index:number,value:T,newValue:T):T => {
    return newValue;
  }, (array:Array<T>,index:number,removed:T[],added:T[]):void => {
  });
  proxyArray['backingArray'] = backingArray;
  proxyArray['loadPageFunction'] = ()=>loadPage;
  return proxyArray;
}

export function createLazyLoadingArray<T>(backingArray : Array<T>, getAccessor:(array:Array<T>,index:number,value:T)=>T) : T[] {
  let proxyArray = _createProxyArray(backingArray,0,
    getAccessor,
    (array:Array<T>,index:number,value:T,newValue:T):T => {
      return newValue;
    },
    (array:Array<T>,index:number,removed:T[],added:T[]):void => {
    });
  proxyArray['backingArray'] = backingArray;
  proxyArray['getAccessor'] = ()=>getAccessor;
  return proxyArray;
}

export interface LazyLoadingArray<T> extends Array<T> {
  backingArray: Array<T>;
  backingIndices: {[key:string]: number};
  getAccessor: ()=>(array:Array<T>,index:number,value:T)=>T;
}

/**
 * create a lazy loading indexed array with access hooks into get/set/splice
**/
export interface IndexedArrayHooks<T> {
  getId:(value:T)=>string;
  isValue:(value:T)=>boolean;                         // true: real value, false: default value (undefined,null or default)
  getValue:(array:Array<T>,index:number,value:T)=>T;  // undefined or null for not existing values, or a default value
  onSet:(array:Array<T>,index:number,value:T)=>void;
  onSpliced:(array:Array<T>,removedAtIndex:number,removed:T[],addedAtIndex:number,added:T[])=>void;
}

/**
 * create an indexed array proxy. if backingArray and backingIndices not provided,
 * a new array is created.
 * @param backingHooks
 * @param backingArray (defaults to [])
 * @param backingIndices (defaults to {})
 */
export function createIndexedArrayProxy<T>(backingHooks:IndexedArrayHooks<T>, backingArray:Array<T> = [], backingIndices:{[key:string]: number} = {}) : T[] {
  if (!!(<any>backingArray)?.backingArray) {
    console.trace("ERROR",backingArray);
  }
  let proxyArray = _createProxyArray(backingArray,0, (array:Array<T>,index:number,value:T):T => {
    return backingHooks.getValue(array,index,value);
    /*
    if (value === undefined) {
      array[index] = null;
      loadFunction(index,1);
      return null;
    }
    return value;*/
  },(array:Array<T>,index:number,value:T,newValue:T):T => {
    let oldId = backingHooks.getId(value);
    let newId = backingHooks.getId(newValue);
    if (oldId!=newId) {
      if (!!oldId) {
        delete backingIndices[oldId];
      }
      if (!!newId) {
        backingIndices[newId]=index;
      }
    }
    backingHooks.onSet(array,index,newValue);
    return newValue;
  },(array:Array<T>,index:number,removed:T[],added:T[]):void => {
    removed.forEach(value => {
      let id = backingHooks.getId(value);
      if (!!id) {
        delete backingIndices[id];
      }
    });
    const delta = added.length-removed.length;
    Object.keys(backingIndices).forEach(id => {
      let i = backingIndices[id];
      if (i>=index) {
        backingIndices[id] = i+delta;
      }
    });
    added.forEach((value,i) => {
      let id = backingHooks.getId(value);
      if (!!id) {
        backingIndices[id]=index+i;
      }
    });
    backingHooks.onSpliced(array,index,removed,index,added);
  });
  proxyArray['backingHooks']        = backingHooks;
  proxyArray['backingArray']        = backingArray;
  proxyArray['backingIndices']      = backingIndices;
  proxyArray['backingIndicesProxy'] = ()=>new Proxy(backingIndices,{});
  return proxyArray;
}

export interface ArrayEntity<T,S> {
  index: number,    // the array index of entity
  entity: T,        // the entity at index
  stored?: S        // this value can be set by subscribers
}

export function loaded$<T,S>(entityArray : Array<T>, begin?:number,end?:number): Observable<{prev?:ArrayEntity<T,S>,curr:ArrayEntity<T,S>,next?:ArrayEntity<T,S>}> {
  let array : T[] = !!(<any>entityArray)?.backingArray ? (<any>entityArray).backingArray : entityArray;
  function* loadedIterator(begin:number,end:number) {
    let prev = undefined;
    let curr = undefined;
    let next = undefined;
    for (let index=begin; index<end; index++) {
      let value = array[index];
      if (value) {
        prev = curr;
        curr = next;
        next = <ArrayEntity<T,S>>{
          index: index,
          entity: value
        };
        if (curr !== undefined) {
          yield prev === undefined ? {
            curr: curr,
            next: next
          } : {
            prev: prev,
            curr: curr,
            next: next
          }
        }
      }
    }
    if (next!==undefined) {
      yield {
        prev: curr,
        curr: next
      }
    }
  }
  begin = begin!=undefined ? Math.max(0,Math.min(begin,array.length)) : 0;
  end   = end!=undefined ? Math.max(0, Math.min(end,array.length)) : array.length;
  return from(loadedIterator(begin,end));
}

