<virtual-section-scroller #scroller [items$]="allTimezones$"
                          [itemsId$]="itemsId$"
                          [startFromTop]="true"
                          [itemDefaultSize]="48"
                          [startGap]="8"
                          [endGap]="8"
                          (onResize)="onResize($event)">
  <ng-container *virtualFor="let countryTimezones of scroller.viewportItems offset scroller.viewportOffset total scroller.items?.length;
                              let index=index;
                              let total=total;
                              let first=first;
                              let last=last;">
    <div *ngIf="context(countryTimezones, index) as context"
         class="timezone-container"
         [class]="{ lru:   context.isLru,
                    multi: context.multi }">
      <div *ngIf="context.multi" class="country">
        {{countryTimezones.countryCode | country}}
      </div>
      <ng-template ngFor
                   [ngForOf]="countryTimezones.zones"
                   [ngForTrackBy]="trackTimezone"
                   let-timezone
                   let-index="index"
                   let-last="last">
        <div class="timezone" (click)="onSelectionChange(index, timezone, $event)">
          <span class="name">{{countryTimezones.countryCode | country}} - {{timezoneName(timezone)}}</span>
          <span class="offset">{{timezoneOffset(timezone.id)}}</span>
          <div *ngIf="controlsTemplate" class="controls" (click)="$event.stopPropagation(); $event.preventDefault();">
            <ng-container *ngTemplateOutlet="controlsTemplate; context: {
                index: index,
                timezone: timezone,
                country: countryTimezones.countryCode,
                isLru: context.isLru
              }">
            </ng-container>
          </div>
          <mat-divider *ngIf="!last"></mat-divider>
        </div>
      </ng-template>
      <mat-divider *ngIf="!last && !context.multi" class="section" [class.strong]="context.sectionEnd"></mat-divider>
    </div>
  </ng-container>
</virtual-section-scroller>

