// @dynamic
export abstract class InvitationToken {
  abstract get code(): string;
  abstract formatted: string;
  abstract time: number;
  abstract user: number;
  // hash: number;
  abstract expiring: boolean;
  //abstract email: boolean;
  //abstract sms: boolean;
  //abstract whatsapp: boolean;

  /*
   * Returns a hash code for a string.
   * (Compatible to Java's String.hashCode())
   *
   * The hash code for a string object is computed as
   *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
   * using number arithmetic, where s[i] is the i th character
   * of the given string, n is the length of the string,
   * and ^ indicates exponentiation.
   * (The hash value of the empty string is zero.)
   *
   * @param {string} s a string
   * @return {number} a hash code value for the given string.
   */
  public static hashCode(s:string) : number {
    let h = 0, l = s.length, i = 0;
    if ( l > 0 )
      while (i < l)
        h = (h << 5) - h + s.charCodeAt(i++) | 0;
    return h;
  }
}
