<ng-container [ngSwitch]="panel">
  <ng-container *ngSwitchCase="'uploadTypes'"  [ngTemplateOutlet]="uploadTypesTemplate"></ng-container>
  <ng-container *ngSwitchCase="'uploads'" [ngTemplateOutlet]="uploadsTemplate"></ng-container>
</ng-container>

<ng-template #uploadTypesTemplate>
<!--<h4>{{'upload.selectFileType' | translate}}</h4>-->
  <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  <slide-panel [id]="slidePanel" [@panelActivation] (onSlide)="onSlide($event)">
    <upload-type-list *slidePanelLeft="'uploadTypes'"
                      [uploadTypes] = "data?.uploadTypes"
                      (selectType)="onSelectType($event)"
                      (addLink)="onAddLink($event)">
    </upload-type-list>
    <div *slidePanelRightLazy="'*'" class="selector">
      <ng-container *ngTemplateOutlet="selector; context:{ onSelectionChange: uploadItemSelected }"></ng-container>
    </div>
  </slide-panel>
</ng-template>
<ng-template #uploadsTemplate>
  <div>
    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    <upload-list [@panelActivation]
                 [uploadBatches]="uploadService.getUploadBatches()"
                 [uploadRef]="uploadRef">
    </upload-list>
  </div>
</ng-template>

<button mat-icon-button class="close" (click)="onTapClose($event)">
  <mat-icon>close</mat-icon>
</button>

<ng-template #headerTemplate>
  <ng-container [ngSwitch]="(panel=='uploadTypes' || !!this.data.mode) && slidePanel=='uploadTypes'">
    <ng-container *ngSwitchCase="true">
      <ng-container *ngIf="(uploadStats | async) as stats">
        <ng-container *ngIf="stats.count > 0">
          <ng-template #uploadStatsTemplate let-stats="stats" let-remainingTime="remainingTime">
            <span>{{('upload.progress.'+(stats.count == 1 ? 'single' : 'plural') | translate: stats) || ('upload.progress' | translate: stats)}}</span>
            <span [innerHTML]="remainingTime | safeHtml"></span>
          </ng-template>
          <ng-container *ngIf="{stats: stats, remainingTime: (uploadRemainingTime | async)} as context"
                        [ngSwitch]="!!this.data.mode">
            <a *ngSwitchCase="false" (click)="togglePanel()" class="header stats action">
              <ng-container *ngTemplateOutlet="uploadStatsTemplate; context: context"></ng-container>
            </a>
            <span *ngSwitchCase="true" class="header stats">
              <ng-container *ngTemplateOutlet="uploadStatsTemplate; context: context"></ng-container>
            </span>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <a *ngSwitchCase="false" (click)="panel!='uploadTypes' ? togglePanel() : toggleSlidePanel()" class="header action" translate>actions.back</a>
  </ng-container>
</ng-template>
