/* projects/shared/src/lib/components/filter-carousel/filter-carousel.component.scss */
:host {
  position: relative;
}
:host[extended-drag-area=true]:before {
  content: "";
  position: absolute;
  bottom: 100%;
  width: 100px;
  left: 50%;
  height: 32px;
  transform: translateX(-50%);
}
:host[extended-drag-area=true]:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 12px;
}
:host > div.container {
  position: relative;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
}
:host > div.container > div.content {
  overflow: visible;
  display: inline-block;
  position: relative;
  margin-right: 50%;
  margin-left: 50%;
  transform: translate3d(0, 0, 0);
  padding-top: 0;
  padding-bottom: 0;
}
:host > div.container.touch-grow > div.content {
  transition: padding ease 0.3s;
}
:host.focus > div.container.touch-grow > div.content {
  padding-top: 8px;
  padding-bottom: 8px;
}
:host > div.container > div.content > div {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}
:host > div.container > div.content > div::ng-deep > * {
  position: relative;
  display: inline-block;
  cursor: pointer;
  box-sizing: border-box !important;
}
/*# sourceMappingURL=filter-carousel.component-A6OKKOGD.css.map */
