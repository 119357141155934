import {InjectionToken} from "@angular/core";
import {Topic} from "core";
import {Observable} from "rxjs";

export const SURVEY_COMPONENT_FACTORY = new InjectionToken<SurveyComponent>('surveyComponentFactory');

export interface SurveyComponent {
  survey: Topic;
  selectedTags: string[];
  selectionChanged: Observable<[Topic, string[]]>;
  contactId: string;
  readOnly: boolean;
}
