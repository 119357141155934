import {Observable, Subscription} from "rxjs";
import {ListViewState} from "synchronization/lib/store/state";

export interface ListViewFilter {
  filters?: string[];
  searchTerm?: string;
}

export interface SearchFilter {
  id: string;
  filters?: string[];
  term?: string;
}

export interface LoadMoreState {
  size: number;   // total size of more items available
  load: number;   // 0 ... dont load more, >0 ... load more
  time: number;   // time of last request for more items...
}
export const EMPTY_MORE_STATE = {size:0,load:0,time:0};


export interface LoadMoreState1 {
  size: number;   // 0 ... dont load more, >0 ... load more
  time: number;   // time of last request for more items...
}

export interface More {
  load(size?:number):Promise<number>;
  size:number;
  loading:boolean;
}

export abstract class FilteredListView<T> extends Subscription {
  abstract get id$(): Observable<string>;
  abstract get entities$(): Observable<T[]>;
  abstract get sections$(): Observable<number[]>;
  abstract get searchTerm(): string|undefined;
  abstract get searchTerm$(): Observable<string|undefined>;
  abstract get filters(): string[];
  abstract get filters$(): Observable<string[]>;
  abstract get typedFilters():{[key:string]:string[]};
  abstract get typedFilters$():Observable<{[key:string]:string[]}>;
  abstract setSearchTerm(term:string|undefined,delay?:number):Promise<boolean>;
  abstract setTypedFilters(typedFilters:{[key:string]:string[]},remove?:(type:string,filters:string[])=>boolean,delay?:number): Promise<boolean>;
  abstract get state(): ListViewState;
  //abstract get state$(): Observable<ListViewState>;
  abstract get moreActive$(): Observable<More>;
  abstract get morePassive$(): Observable<More>;
  // normally does not have to be triggered as it is triggered
  // by creation of the filtered list view if authenticated...
  abstract load():Promise<FilteredListView<T>>;
}
