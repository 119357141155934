import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import {DragEvent} from "../../models/touch/drag-event";
import {TouchTracker} from "../../models/touch/touch-tracker";
import {Subscription} from "rxjs";

export interface DragHandler {
  attachToDragEventEmitter(emitter:EventEmitter<DragEvent>);
}

/**
 * add press support to component
 */
@Directive({
  selector: '[onDrag],[dragHandler]'
})
export class DragDirective implements OnInit, AfterContentInit, OnDestroy {
  @Output() onDrag: EventEmitter<DragEvent> = new EventEmitter<DragEvent>();
  @Input() dragHandler: DragHandler;

  protected subscriptions = new Subscription();

  public constructor(protected elementRef: ElementRef,
                     private zone: NgZone,
                     private renderer: Renderer2) {
    //console.debug("DRAG");
  }

  public ngOnDestroy(): void {
    this.onDrag.complete();
    this.subscriptions.unsubscribe();
  }

  public ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.subscriptions.add(this.registerElementListener('touchstart',(event:TouchEvent) => this.onPointerDown(event)));
      this.subscriptions.add(this.registerDocumentListener('touchmove',(event:TouchEvent) => this.onPointerMove(event)));
      this.subscriptions.add(this.registerDocumentListener('touchend',(event:TouchEvent) => this.onPointerUp(event)));
      this.subscriptions.add(this.registerDocumentListener('touchcancel',(event:TouchEvent) => this.onPointerUp(event)));
      this.subscriptions.add(this.registerElementListener('mousedown',(event:MouseEvent) => this.onPointerDown(event)));
      this.subscriptions.add(this.registerDocumentListener('mousemove',(event:MouseEvent) => this.onPointerMove(event)));
      this.subscriptions.add(this.registerDocumentListener('mouseup',(event:MouseEvent) => this.onPointerUp(event)));
      this.subscriptions.add(this.registerDocumentListener('mouseout',(event:MouseEvent) => this.onPointerUp(event)));
    });
  }

  protected registerElementListener(event:string,handler:(event:KeyboardEvent|TouchEvent|MouseEvent)=>void):()=>void {
    return this.renderer.listen(this.elementRef.nativeElement,event,(event:KeyboardEvent|TouchEvent|MouseEvent)=>this.zone.run(()=>handler(event)));
  }

  protected registerDocumentListener(event:string,handler:(event:KeyboardEvent|TouchEvent|MouseEvent)=>void):()=>void {
    return this.renderer.listen(window.document,event,(event:KeyboardEvent|TouchEvent|MouseEvent)=>this.zone.run(()=>handler(event)));
  }

  public ngAfterContentInit(): void {
    if (this.dragHandler) {
      this.dragHandler.attachToDragEventEmitter(this.onDrag);
    }
  }

  @HostBinding('class.focus') public touched: boolean = false;
  protected touchTracker: TouchTracker = new TouchTracker();
  protected eventPrefix:string = undefined;

  onPointerDown(event: MouseEvent | TouchEvent): void {
    //console.log("down",this.touched,"!started",!this.touchTracker.isStarted(),"valid",this.touchTracker.isValidEvent(event));
    //console.log("down",this.touched,"!started",!this.touchTracker.isStarted(),"valid",this.touchTracker.isValidEvent(event),"event",event,(<TouchEvent>event).touches,this);
    //console.log("pointerdown",event,"!started",!this.touchTracker.isStarted(),"valid",this.touchTracker.isValidEvent(event));
    if (!this.touched &&//!this.touchTracker.isStarted() &&
      this.touchTracker.isValidEvent(event)) {
      this.touched = true;
      this.touchTracker.initialize(event,0,0);
      this.eventPrefix = event.type.substring(0,3);
      //console.log("onPointerDown",event,"type",event.type,"prefix",this.eventPrefix);
      try {
        let dragEvent:DragEvent = {
          deltaX:0,
          deltaY:0,
          type:'start',
          cancel:false,
          stopPropagation:false,
          startPageX:this.touchTracker.start.pageX,
          startPageY:this.touchTracker.start.pageY,
          preventDefault: false,
          out:false
        };
        this.onDrag.emit(dragEvent);
        if (dragEvent.preventDefault) {
          event.preventDefault();
        }
        if (dragEvent.cancel) {
          event.stopPropagation();
          this.touched = false;
          this.touchTracker.stop();
        } else if (dragEvent.stopPropagation) {
          event.stopPropagation();
          //} else {
          //console.log("STARTED",this);
        }
      } catch(e) {}
      //console.log("pointerdown",event,"inside");
      //console.log("pointerdown",event,"containerElement.scrollLeft");
    }
  }

  onPointerMove(event: MouseEvent | TouchEvent): void {
    //console.log("move",this.touched,"!started",!this.touchTracker.isStarted(),"valid",this.touchTracker.isValidEvent(event));
    //console.log("move",this.touched,"event",event,(<TouchEvent>event).touches,this);
    if (this.touched && event.type.startsWith(this.eventPrefix)) {
      this.touched = this.touchTracker.isValidEvent(event);
      //console.log("pointermove",event,"this.touched",this.touched,"started",this.touchTracker.isStarted(),"valid",this.touchTracker.isValidEvent(event));
      if (this.touched) {
        //console.log("onPointerMove",event,"type",event.type);
        let difference = this.touchTracker.track(event);
        //console.log("pointermove",event,difference);
        try {
          let dragEvent:DragEvent = {
            deltaX:this.touchTracker.pageX-this.touchTracker.start.pageX,
            deltaY:this.touchTracker.pageY-this.touchTracker.start.pageY,
            type:'drag',
            cancel:false,
            stopPropagation:false,
            startPageX:this.touchTracker.start.pageX,
            startPageY:this.touchTracker.start.pageY,
            preventDefault: false,
            out:false
          };
          this.onDrag.emit(dragEvent);
          if (dragEvent.preventDefault) {
            event.preventDefault();
          }
          if (dragEvent.cancel) {
            //console.log("CANCEL",this);
            //this.onPointerUp(event);
            //event.stopPropagation();
            this.touched = false;
            this.touchTracker.stop();
          } else if (dragEvent.stopPropagation) {
            //console.log("STOPPROPAGATION",this);
            //event.stopPropagation();
          }
        } catch(e) {}
      }
    }
  }

  onPointerUp(event: MouseEvent | TouchEvent): void {
    //console.log("up",this.touched,"!started",!this.touchTracker.isStarted(),"valid",this.touchTracker.isValidEvent(event));
    if (this.touched && event.type.startsWith(this.eventPrefix)) {
      //console.log("onPointerUp",event,"type",event.type);
      let mouseout = event.type=='mouseout';
      let pageout  = false;
      if (mouseout) {
        let pageX = event instanceof MouseEvent ? event.pageX : event.touches.length ? event.touches[0].pageX : 0;
        let pageY = event instanceof MouseEvent ? event.pageY : event.touches.length ? event.touches[0].pageY : 0;
        //console.log("pointerup",event,"this.touched",this.touched,"mouseout",mouseout,"pageX",pageX,"pageY",pageY,document.body.clientWidth,document.body.clientHeight);
        //console.log("event",event,pageX,pageY,document.body.clientWidth,document.body.clientHeight);
        pageout = (pageX<=0 || pageY<=0 ||
          pageX>=document.body.clientWidth ||
          pageY>=document.body.clientHeight);
      }
      if ((!mouseout || pageout) && (((<TouchEvent>event).touches==undefined) || ((<TouchEvent>event).touches.length==0))) {
        this.touched = false;
        //console.log("pointerup",event,"this.touched",this.touched);
        try {
          let dragEvent:DragEvent = {
            deltaX:this.touchTracker.pageX-this.touchTracker.start.pageX,
            deltaY:this.touchTracker.pageY-this.touchTracker.start.pageY,
            type:'stop',
            cancel:false,
            stopPropagation:false,
            startPageX:this.touchTracker.start.pageX,
            startPageY:this.touchTracker.start.pageY,
            preventDefault: false,
            out:pageout||mouseout
          };
          this.onDrag.emit(dragEvent);
          if (dragEvent.preventDefault) {
            event.preventDefault();
          }
          if (dragEvent.stopPropagation) {
            event.stopPropagation();
          }
        } catch(e) {}
        this.touched = false;
        this.touchTracker.stop();
      }
    }
  }
}
