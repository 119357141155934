/* projects/auth/src/lib/authentication/containers/login/login-page.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  background-color: #f5f5f5;
}
:host .info {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}
:host .tab-page {
  background-color: white;
  min-height: 240px;
}
mat-sidenav-container {
  width: 100%;
  display: flex;
  flex: 1;
}
mat-sidenav {
  width: 300px;
}
mat-sidenav .content,
mat-sidenav-content {
  width: 100%;
  display: flex;
  flex: 1;
  height: auto;
  background-color: #fafafa;
}
mat-sidenav .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  display: flex;
}
mat-toolbar {
  flex: none;
}
mat-toolbar > button {
  text-transform: uppercase;
  outline: none;
  padding-right: 0;
}
.legal-info-container,
.card-container {
  overflow-y: auto;
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
}
mat-card {
  padding: 16px;
  margin-top: 10px;
  overflow: hidden;
  width: 420px;
}
mat-card > .logo-container {
  position: relative;
  width: calc(100% + 32px);
  margin: -16px -16px 0 -16px;
}
mat-card > .logo-container group-logo {
  position: relative;
  display: block;
  width: 100%;
  background-color: var(--color-primary);
}
mat-card > .logo-container group-logo:not(:last-child) {
  padding-right: 122px;
}
mat-card > .logo-container group-logo ::ng-deep > div {
  min-height: 116px;
}
mat-card > .logo-container .spinner {
  position: absolute;
  right: 26px;
  width: 64px;
  height: 64px;
  color: white;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: none;
}
mat-card > .logo-container .spinner > mat-progress-spinner ::ng-deep circle {
  stroke: white;
}
mat-card > .logo-container .qr-code {
  position: absolute;
  right: 16px;
  width: 90px;
  height: 90px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  z-index: 0;
}
mat-card > .logo-container .qr-code:hover {
  cursor: pointer;
}
mat-card > .logo-container .qr-code qr-code-all {
  width: 84px;
  height: 84px;
}
mat-card > .logo-container .qr-code qr-code-all {
  position: absolute;
  top: 3px;
  left: 3px;
}
mat-card > .logo-container .qr-code button {
  position: absolute;
  top: -20px;
  right: -20px;
  background-color: rgba(255, 255, 255, 0.7);
}
mat-tab-group mat-icon {
  margin: 6px;
}
mat-tab-group .scan-button {
  margin: 15px 0 10px 0;
}
mat-tab-group .scan-button .scan-icon {
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  left: 10px;
  top: 5px;
  background-color: white;
  padding: 2px;
  border-radius: 2px;
}
mat-tab-group .separator {
  width: 78%;
  height: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  text-align: center;
  align-self: center;
  margin: 30px 0 20px;
}
mat-tab-group .separator span {
  font-size: 16px;
  background-color: white;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
}
mat-tab-group::ng-deep > div {
  height: 100%;
}
slide-panel {
  flex: 1;
}
slide-panel .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
slide-panel .panel.invitationCodeOrMemberId,
slide-panel .panel.passwordLogin {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}
slide-panel .panel.invitationCodeOrMemberId form,
slide-panel .panel.passwordLogin form {
  margin: auto 0;
}
slide-panel .panel.invitationCodeOrMemberId button ~ button,
slide-panel .panel.passwordLogin button ~ button {
  margin-left: 16px;
}
p {
  font-size: 80%;
  text-align: justify;
  color: #757575;
}
p.invitationCodeHint {
  margin-bottom: 0;
}
form {
  padding-bottom: 24px;
}
.first {
  margin-top: 16px;
}
.last {
  margin-bottom: 16px;
}
.inline-loader {
  display: inline-block;
}
.login-container,
mat-form-field {
  width: 100%;
}
mat-form-field {
  margin-bottom: 4px;
}
mat-form-field.password {
  margin-bottom: 10px;
  position: relative;
}
mat-form-field.password a {
  position: absolute;
  right: 2px;
  font-size: 0.8rem;
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}
mat-form-field.password a:hover {
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  text-decoration: underline;
}
mat-card-title {
  margin-bottom: 24px;
}
.login-error {
  margin-top: 8px;
  color: #f44336;
}
button[type=submit] {
  width: calc(100% - 45px);
}
:host ::ng-deep .text-container {
  overflow-y: scroll;
}
:host ::ng-deep .text-container .block {
  margin: 10px;
  color: gray;
}
:host ::ng-deep .text-container break {
  display: inline-block;
  max-width: 0;
}
:host ::ng-deep .text-container .h1 {
  margin: 10px;
  font-size: 120%;
  color: var(--color-primary);
  font-weight: bold;
}
:host ::ng-deep .text-container .h2 {
  margin: 10px;
  font-size: 100%;
  color: var(--color-primary);
  font-weight: bold;
}
:host ::ng-deep .text-container .h3 {
  margin: 10px;
  font-size: 100%;
  color: var(--color-primary);
  font-weight: normal;
}
:host ::ng-deep .text-container a {
  color: gray;
}
.spacer {
  flex: 1 1 auto;
}
.spacer.row {
  padding-top: 10px;
}
.reference-bar ::ng-deep button {
  font-size: 80% !important;
  line-height: 1em;
  padding: 0.5em;
  color: white;
}
.reference-bar {
  margin: 0 -16px -16px -16px !important;
  padding: 6px 16px 8px;
  background-color: #BBB;
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.5);
}
.signature-container {
  padding: 0;
}
.signature-container > div {
  padding: 16px 0;
}
.signature-container > div button {
  line-height: 1em;
}
.page-container {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 270px;
}
.page-container.error {
  height: 296px;
}
.page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  background-color: transparent;
}
.page > mat-tab-group,
.page > form {
  flex: 1;
}
.recovery-container {
  display: flex;
  width: 200%;
  min-width: 200%;
}
.recovery-container > form {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}
.recovery-container > form h4 {
  margin-bottom: 16px;
  font-size: 0.85em;
  line-height: 1.2;
  min-height: 55px;
}
.recovery-container > form .actions {
  margin-top: 5px;
}
.recovery-container > form .actions button {
  min-width: 100px;
  margin: 0 10px;
}
.recovery-container > form:nth-child(2) .mat-mdc-form-field {
  width: 65%;
  align-self: center;
  margin-bottom: 30px;
}
.recovery-container > form:nth-child(2) .mat-mdc-form-field input {
  border: none;
  padding: 0;
  margin: 0;
  width: 6.5ch;
  min-width: 205px;
  font:
    50px droid sans mono,
    consolas,
    monospace;
  background:
    repeating-linear-gradient(
      90deg,
      dimgrey 0,
      dimgrey 1ch,
      transparent 0,
      transparent 1.5ch) 0 100%/6ch 2px no-repeat;
  letter-spacing: 0.5ch;
  color: dimgrey;
}
.recovery-container > form:nth-child(2) .mat-mdc-form-field input:focus {
  outline: none;
  color: var(--color-primary-300);
}
.recovery-container > form:nth-child(2) .mat-mdc-form-field mat-icon {
  position: relative;
  bottom: 10px;
}
input.hidden {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
:host-context(.plt-ios) card-container {
  transition: 0.3s padding-bottom ease;
}
:host-context(.keyboard.plt-ios) card-container {
  padding-bottom: 200px;
}
:host-context(.color-scheme-dark) ::ng-deep .text-container {
  background-color: #111;
  color: #aaa;
}
:host-context(.color-scheme-dark) ::ng-deep .text-container .block {
  color: #aaa;
}
:host-context(.color-scheme-dark) ::ng-deep .text-container a {
  color: #aaa;
}
:host-context(.color-scheme-light.pure) mat-card > .logo-container group-logo {
  background-color: white;
  border-bottom: 1px solid #ddd;
}
:host-context(.color-scheme-light.pure) mat-card > .logo-container .spinner > mat-progress-spinner ::ng-deep circle {
  stroke: #ddd;
}
:host-context(.color-scheme-light.pure) mat-card > .logo-container .qr-code button {
  background-color: rgba(255, 255, 255, 0.7);
}
:host-context(.color-scheme-dark) {
  background-color: #282828;
}
:host-context(.color-scheme-dark) mat-tab-group,
:host-context(.color-scheme-dark) .tab-page {
  background-color: #111;
}
:host-context(.color-scheme-dark) .recovery-container {
  background-color: #111;
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) page {
  background-color: black;
}
:host-context(.color-scheme-dark) .reference-bar {
  background-color: #282828;
  box-shadow: none;
}
:host-context(.color-scheme-dark) .reference-bar ::ng-deep button {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) mat-card {
  background-color: #111;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}
:host-context(.color-scheme-dark) mat-card > div.logo-container > group-logo,
:host-context(.color-scheme-dark) mat-card > div.logo-container > img {
  background-color: #282828;
}
:host-context(.color-scheme-dark) mat-card > div.logo-container > .qr-code {
  background-color: #AAAAAA;
}
:host-context(.color-scheme-dark) mat-sidenav-content {
  background-color: #181818;
}
:host-context(.color-scheme-dark) .signature-container {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) mat-form-field.password a {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) mat-form-field.password a:hover {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) p {
  color: #AEAFB0;
}
@media only screen and (max-width: 446px) {
  mat-card {
    min-width: 384px;
    width: 94%;
  }
  mat-card button {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media only screen and (max-width: 400px) {
  mat-card {
    min-width: 327px;
    width: 96%;
    padding-left: 6px;
    padding-right: 6px;
  }
  mat-card .logo-container {
    width: calc(100% + 12px);
    margin-left: -6px;
    margin-right: -6px;
  }
  mat-card .logo-container group-logo {
    padding-right: 105px;
  }
  mat-card .logo-container .qr-code {
    right: 6px;
  }
  mat-card button {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media only screen and (max-width: 330px) {
  mat-card {
    width: 99%;
    min-width: 260px;
    padding-left: 4px;
    padding-right: 4px;
  }
  mat-card .logo-container {
    width: calc(100% + 8px);
    margin-left: -4px;
    margin-right: -4px;
  }
  mat-card .logo-container group-logo {
    padding-right: 95px;
  }
  mat-card .logo-container .qr-code {
    right: 4px;
  }
  mat-card button {
    padding-left: 4px;
    padding-right: 4px;
  }
}
@media only screen and (min-width: 446px) {
  mat-card {
    width: 420px;
  }
}
/*# sourceMappingURL=login-page.component-IGBIO5TH.css.map */
