import {createAction, props} from '@ngrx/store';
import {Sidenav, SidenavMode, SidenavState,} from "./reducers";

export const openSidenavAction = createAction(
  'layout.sidenav.open',
  props<{
    readonly sidenav : Sidenav,
    readonly mode ?: SidenavMode}>()
);

export const closeSidenavAction = createAction(
  'layout.sidenav.close',
  props<{
    readonly sidenav : Sidenav,
    readonly predicate ?: (state: SidenavState) => boolean}>()
);

export const clearAnimationAction = createAction(
  'layout.sidenav.animation.clear',
  props<{
    readonly sidenav : Sidenav}>()
);

export const toggleSidenavAction = createAction(
  'layout.sidenav.toggle',
  props<{
    readonly sidenav : Sidenav}>()
);

export const ensureSidenavAction = createAction(
  'layout.sidenav.ensure',
  props<{
    readonly sidenav : Sidenav,
    readonly open : boolean}>()
);

export const updateSidenavAction = createAction(
  'layout.sidenav.update',
  props<{
    readonly sidenav : Sidenav,
    readonly state : SidenavState}>()
);

export const setSidenavModeAction = createAction(
  'layout.sidenav.mode',
  props<{
    readonly sidenav : Sidenav,
    readonly mode : SidenavMode,
    readonly preferredMode ?: SidenavMode}>()
);

export const setSidenavContentAction = createAction(
  'layout.sidenav.content',
  props<{
    readonly sidenav : Sidenav,
    readonly contentType : string,
    readonly content : any}>()
);
