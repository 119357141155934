import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  AccordionSectionComponent,
  AccordionSectionHeaderBadgeDefinition,
  AccordionSectionHeaderButtonDefinition,
  BasicContainerComponent,
} from "shared";
import {Logger, Topic, EMPTY_ARRAY} from 'core';
import {FilterTagEvent} from '../tags/filter-tags.component';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';
import {FilterService} from '../../filter.service';
import {Observable} from "rxjs";
import {AccordionFilterSectionContext} from "../filter.component.factory.service";

@Component({
  selector: 'basic-filter',
  template: ``
})
export abstract class BasicFilterComponent extends BasicContainerComponent implements AccordionSectionComponent {

  protected logger = new Logger(this.constructor.name);

  label: string;
  @Output() headerBadges = new EventEmitter<AccordionSectionHeaderBadgeDefinition[]>();
  @Output() headerButtons = new EventEmitter<AccordionSectionHeaderButtonDefinition[]>();

  protected _context: AccordionFilterSectionContext;
  protected _height: string;
  protected _topics: Topic[] = [];

  protected _selectedFilterIds$:Observable<string[]>;
  protected _selectedFilterIds:string[] = [];   // comes directly from store
  protected _updateFilters:(filters:string[])=>Promise<boolean>;

  constructor(
    protected filterService: FilterService,
    protected changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  @Input()
  set context(context: AccordionFilterSectionContext) {
    if (!isEqual(context?.topics, this._context?.topics) ||
        !isEqual(context?.search, this._context?.search)||
        !isEqual(context?.multiselect, this._context?.multiselect)) {
      this._context = context;
      this._updateFilters = context.updateFilters;
      this._selectedFilterIds = [];
      this._selectedFilterIds$ = context.selectedFilters$;
      if (!!context.selectedFilters$) {
        this.addSubscription((context.selectedFilters$.subscribe(filters=>{
          this._selectedFilterIds = filters;
          this.changeDetectorRef.markForCheck();
        })),'selectedFilters$');
      }
      this.onContextUpdate();
      window.setTimeout(() => {
          // this.headerBadges.emit([{count: () => this.selectedFilterIds.length}])
          this.headerButtons.emit([{
            // label: 'actions.delete',
            icon: 'delete',
            badgeCount: () => this.selectedFilterIds.length,
            onClick: () => this.resetFilters(),
            disabled: () => this.selectedFilterIds.length === 0
          }])},
        100);
    }
  }

  protected onContextUpdate(){

  }

  get context() {
    return this._context;
  }

  isHidden?: EventEmitter<boolean>;

  getSelectedFilterIds$(): Observable<string[]> {
    return this._selectedFilterIds$;
  }

  set selectedFilterIds(ids: string[]) {
    if (!isEqual(ids??EMPTY_ARRAY, this.selectedFilterIds??EMPTY_ARRAY) && !!this._updateFilters) {
      this._updateFilters(ids);
    }
  }

  get selectedFilterIds():string[] {
    return this._selectedFilterIds;
  }

  @Input()
  set height(height: string) {
    if (this._height !== height) {
      this._height = height;
    }
  }

  get height(): string {
    return this._height;
  }

  onOpen() {
  }

  resetFilters() {
    this.selectedFilterIds = EMPTY_ARRAY;
  }

  isFilterSelected(filter: string): boolean {
    return includes(this.selectedFilterIds, filter);
  }

  getRootTopic(topic: Topic):Topic {
    if (!!topic?.parent) {
      return this.getRootTopic(topic.parent);
    } else {
      return topic;
    }
  }

  onTapFilter(event: FilterTagEvent) {
    console.log("tapevent", event, "\nexclusive", !event.auto, "group", event.filter?.parent?.tags, event.filter?.parent?.tags?.includes('group'));
    const getFilterId = (topic: Topic) => {
      let parentId = '';
      if (topic.parent) {
        parentId = getFilterId(topic.parent) + '.';
      }
      return parentId + topic.id;
    };
    const filterId = event.filter.id;
    const topic = this.getRootTopic(event.filter);
    this.selectedFilterIds = this.filterService.setFilter(this.selectedFilterIds, filterId, !event.auto, topic.id);
  }

  setFilter(filter: string, exclusive = false) {
    this.selectedFilterIds = this.filterService.setFilter(this.selectedFilterIds, filter, exclusive);
  }
}
