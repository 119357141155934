import {Inject, Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {ENVIRONMENT} from "../config";

@Injectable()
export class CorsInterceptor implements HttpInterceptor {

  constructor(@Inject(ENVIRONMENT) private environment: any) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.debug("added cors credentials for ["+request.method+"]"+request.url);
    if (request.url?.startsWith(this.environment.serverUrl) &&
       !request.withCredentials) {
      const clone = request.clone({ withCredentials: true });
      return next.handle(clone);
    }
    return next.handle(request);
  }
}
