import {ModuleWithProviders, NgModule} from '@angular/core';
import {META_REDUCERS, StoreModule as NgrxStoreModule} from "@ngrx/store";
import {RESET_APP_STATE, metaReducerFactory, metaReducers, reducer } from "./store";
import {EffectsModule} from "@ngrx/effects";
import {StoreActionsModule} from "./store.actions.module";

@NgModule({
  imports: [
    NgrxStoreModule.forRoot(reducer, {
      metaReducers,
      runtimeChecks: {
        // would be good to have at least immutability checks enabled but the code should be prepared first
        // e.g. backingArray property assignments lead to:
        // Type Error: Cannot assign to read only property 'backingArray' of object '[object Object]'
        strictStateImmutability: false,
        strictActionImmutability: false,
        // currently some states and actions are not serializable
        // https://ngrx.io/guide/store/configuration/runtime-checks
        strictStateSerializability: false,
        strictActionSerializability: false
      }
    }),
    EffectsModule.forRoot([]),
    StoreActionsModule
  ]
})
export class StoreModule {
  static forRoot(resetAppState: any): ModuleWithProviders<StoreModule> {
    return {
      ngModule: StoreModule,
      providers: [
        { provide: RESET_APP_STATE, useValue: resetAppState},
        {
            provide: META_REDUCERS,
            deps: [RESET_APP_STATE],
            useFactory: metaReducerFactory,
            multi: true,
        }
      ]
    };
  }
}
