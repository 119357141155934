import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MediaDetailsShareDataService } from '../../service/media-details-share-data.service';
import { PropertiesService } from 'properties';
import { combineLatest, takeUntil } from 'rxjs';
import { MediaDetailsSectionComponent } from '../media-details/media-details-section.component';

@Component({
  selector: 'media-details-lock',
  templateUrl: './media-details-lock.component.html',
  styleUrls: ['./media-details-lock.component.scss']
})
export class MediaDetailsLockComponent extends MediaDetailsSectionComponent {

  mediaForm: FormGroup;

  constructor(
    protected mediaDetailsShareDataService: MediaDetailsShareDataService,
    private propertiesService: PropertiesService
  ) {
    super(mediaDetailsShareDataService);
  }

  ngOnInit(): void {
    combineLatest([
      this.mediaDetailsShareDataService.getEditMode$,
      this.mediaDetailsShareDataService.getMediaForm$,
      this.mediaDetailsShareDataService.getOptions$,
      this.propertiesService.user$
    ])
      .pipe(
        takeUntil(this.onDestroy$)
      ).subscribe(([editMode, mediaForm, options, user]) => {
          const isLockEditor = user.isAdmin || (user.app?.tags || []).includes('lockEditor');
          this.isHidden.emit(!editMode || options.mode == 'basic' || !isLockEditor);
          this.mediaForm = mediaForm;
        });
  }

}
