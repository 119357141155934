import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {BasicContainerComponent} from "../../basic-container/basic-container.component";
import {Language, LanguageListComponent} from "../language-list/language-list.component";
import {auditTime, distinctUntilChanged, map, scan, shareReplay, switchMap, takeUntil} from "rxjs/operators";
import {Logger} from "core";
import isEqual from "lodash/isEqual";

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectorComponent extends BasicContainerComponent {

  @Input('languages$') allLanguages$: Observable<Language[]>;
  @Input('lru$') lruLanguages$: Observable<string[]>;
  @Input() searchTerm$: Observable<string>;
  @Input() set currentLanguage(currentLanguage: string) {
    if (this._currentLanguage!=currentLanguage) {
      // react on language change to update lru list - fist items should be always current language
      this._currentLanguage = currentLanguage;
      let next = this.lru$.getValue().slice();
      next = next.reduce((result, language, index) => {
        if (index == 0 && language==currentLanguage ||
          index > 0  && language!=currentLanguage) {
          result.push(language);
        }
        return result;
      }, []);
      if (next.length == 0 || next[0]!=currentLanguage) {
        next.unshift(currentLanguage);
      }
      this.logger.debug('CURRENT LANG CHANGE. NEXT LRU', next);
      this.lru$.next(next);
    }
  }

  @Output() selectionChange = new EventEmitter<Language>();
  @Output() lruChange       = new EventEmitter<string[]>();

  languages$: Observable<Language[]>;
  lru$ = new BehaviorSubject<string[]>([]); // last recently used language codes e.g ['de', 'en', 'it']

  protected _currentLanguage: string;

  @ViewChild(LanguageListComponent, {static: true}) languageList: LanguageListComponent;

  protected logger = new Logger('LanguageSelectorComponent').setSilent(false);

  constructor(protected changeDetector: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    // observable emitting languages filtered by current search term
    const languages$ = combineLatest([
      this.allLanguages$,
      this.searchTerm$
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        map(([languages, term]) => {

          const result: Language[] = [];
          if (languages) {
            languages.forEach((language: Language) => {
              const languageName: string = language?.name;
              if (languageName && (!term || languageName.toLowerCase().startsWith(term.toLowerCase()))) {
                result.push({...language});
              }
            });
          }
          this.logger.debug({languages, term, result});

          return result;
        }),
        shareReplay(1)
      );
    this.languages$ = languages$;
    languages$
      .pipe(auditTime(100))
      .subscribe((language) => {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    });
    const lruLanguages$ = this.lruLanguages$.pipe(
      takeUntil(this.onDestroy$),
      // startWith(this.lru$.getValue()),
      distinctUntilChanged((previous, current) => !isEqual(previous, current)),
      shareReplay(1)
    );

    // listen for new lru values from input and reflect the changes
    lruLanguages$.subscribe(lru => {
        const currentLanguage = this.currentLanguage;
        if (!lru.length || lru[0] != currentLanguage) {
          lru.unshift(currentLanguage);  // add current language
        }
        this.logger.debug('INPUT LRU CHANGE. NEXT LRU', lru);
        this.lru$.next(lru);
      });

    // track lru changes to detect dirty state
    const lru$ = this.lru$.pipe(
      scan((state: { initial: string[], next: string[] }, value: string[]) => {
        // console.debug(value, form.pristine);
        if (value) {
          state = !state.initial
            ? { initial: value, next: value }
            : {...state, ...{ next: value }};
        }
        return state;
      }, { initial: undefined, next: undefined })
    );

    lruLanguages$
      .pipe(switchMap(() => lru$))      // ensure lru$ state is reset on each lruLanguages$ emission
      .subscribe((state) => {
        // when lru list is changed -> emit event
        const { initial, next } = {...state};
        // remove current language when it was not in the previous lru
        if (initial.indexOf(this.currentLanguage) == -1) {
          next.slice.apply(next, [1]);
        }
        if (!isEqual(initial, next)) {
          this.lruChange.emit(next);
        }
      });
  }

  onSelectionChange(event: {index: number; language: Language}) {
    this.updateLanguageLru(event.language, true);
    this.selectionChange.emit(event.language);
  }

  onRemoveLru(index, language, $event: MouseEvent) {
    this.logger.debug('onLanguageRemoveLru', language);
    this.updateLanguageLru(language, false);
    // prevent firing of selection event when 'x'  button is clicked
    // this was possible to be done directly in language-list component
    // but maybe because of event routing changes introduced in newer versions of angular
    // the event handlers defined by the parent element/component are invoked
    // before the handlers registered by the child element
    $event.preventDefault();
    $event.stopPropagation();
  }

  updateLanguageLru(language: Language, insert: boolean, lru = this.lru$.getValue()) {
    // Current language is always fixed at the first position in lru list. it cannot be added or deleted directly by the user.
    // Updates of this language are handled as a reaction to changes of currentLanguage input.
    if (language.code==this.currentLanguage) {
      return;
    }
    const   next = [...lru];
    const update = (language, insert) => {
      const index = next.indexOf(language);
      if (index > 0) {  // first one is always current language
        next.splice(index, 1);
      }
      insert && next.splice(1, 0, language);
    };
    update(language?.code, insert);
    if (lru.length!=next.length ||
      !lru.every(language => next.includes(language))) {
      this.lru$.next(next);
    }
  }

  get currentLanguage(): string {
    return this._currentLanguage;
  }

  scrollToPosition(index: number) {
    this.languageList.scrollToPosition(index);
  }
}
