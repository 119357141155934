import {Component, ElementRef} from '@angular/core';
import {BasicContainerComponent} from "../basic-container/basic-container.component";
import {BehaviorSubject, fromEvent} from "rxjs";
import {distinctUntilChanged, map, takeUntil, tap} from "rxjs/operators";
import {Size} from "../../types/size";

@Component({
  selector: 'basic-resizing-container',
  template: ``,
  styles: []
})
export class BasicResizingContainerComponent extends BasicContainerComponent {

  protected windowSize    : Size;
  protected elementSize   : Size;
  protected elementSize$  : BehaviorSubject<Size>;
  protected elementSizes$ : BehaviorSubject<Size[]>;
  //protected interval : number;

  constructor(protected elementRef: ElementRef) {
    super();
    this.elementSize$  = new BehaviorSubject<Size>({ width: 0, height: 0 });
    this.elementSizes$ = new BehaviorSubject<Size[]>([{ width: 0, height: 0 }, { width: 0, height: 0 }]);
  }

  ngAfterViewInit() : void {
    //console.debug("ON_AFTER_INIT");
    super.ngAfterViewInit();
    this.captureSizeValues();
    let size = this.getSize(this.getCapturedElement());
    fromEvent(window, 'resize').pipe(
      takeUntil(this.onDestroy$),
      //debounceTime(300),
      map((event) : Size => this.getSize(this.getCapturedElement()))
    ).subscribe(size => {
      //console.debug("RESIZE ",size);
      this.elementRef.nativeElement.setAttribute("_w",size.width);
      this.elementRef.nativeElement.setAttribute("_h",size.height);
      //console.debug("COMPONENT.onResize() size:"+JSON.stringify(size));
      this.elementSize$.next(size);
      this.captureSizeValues();
    });
    this.elementSize$.pipe(
      takeUntil(this.onDestroy$),
      //tap(data => console.debug("BasicResizingContainerComponent.tap1.size",data))
    ).subscribe(size => {
      //this.onResize(size);
      //FATAL!!!! onResize paramter now ResizeEvent instead of Size...
    });
    this.elementSize$.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged(),
      //tap(data => console.debug("BasicResizingContainerComponent.tap2.size",data))
    ).subscribe(size => {
      let value = this.elementSizes$.getValue();
      //console.debug("COMPONENT.onResize() "+JSON.stringify(value));
      this.elementSizes$.next([value[1],size]);
      this.captureSizeValues();
    });
    this.elementSize$.next(size);
  }

  ngOnInit() {
    //console.debug("BasicResizingContainerComponent.ngOnInit()");
    super.ngOnInit();
    let size = this.getSize(this.getCapturedElement());
    //console.debug("BasicResizingContainerComponent.ngOnInit.pump.size:",size);
    this.elementSize$.next(size);
    //console.debug("INIT."+this.constructor.name+"."+this.instanceId);
  }

  ngOnAttach() {
    super.ngOnAttach();
    let size = this.getSize(this.getCapturedElement());
    //console.debug("BasicResizingContainerComponent.ngOnAttach.pump.size:",size);
    this.elementSize$.next(size);
    /*
    let time  = Date.now();
    let count = 0;
    this.interval = window.setInterval(()=> {
      console.debug("ON_2_INTERVAL "+(Math.floor((Date.now()-time+500)/1000))+"sec handle:"+this.interval+
        "\n\tsize:"+JSON.stringify(this.getSize(this.getCapturedElement()))+
        "\n\twindow:"+JSON.stringify(this.getSize(window)));
      if (++count >= 3) {
        window.clearInterval(this.interval);
        this.interval = null;
      }
    },1000);
    console.debug("ON_1_ATTACH " + this.interval +
      "\n\tsize:"+JSON.stringify(this.getSize(this.getCapturedElement()))+
      "\n\twindow:"+JSON.stringify(this.getSize(window)));
    */
  }

  ngOnDetach() {
    /*
    console.debug("ON_1_DETACH " +this.interval +
      "\n\tsize:"+JSON.stringify(this.getSize(this.getCapturedElement()))+
      "\n\twindow:"+JSON.stringify(this.getSize(window)));
    if (this.interval!=null) {
      console.debug("ON_INTERVAL END handle:"+this.interval);
      window.clearInterval(this.interval);
      this.interval = null;
    }
    */
    super.ngOnDetach();
  }

  getCapturedElement() : Element | EventTarget {
    return this.elementRef.nativeElement;
  }

  captureSizeValues(): void {
    this.windowSize  = this.getSize(window);
    this.elementSize = this.getSize(this.getCapturedElement());
  }

  getSize(target: Element | EventTarget): Size  {
    return {
      // https://plainjs.com/javascript/styles/getting-width-and-height-of-an-element-23/
      width:  target instanceof Element ? target.clientWidth  : target['innerWidth'],
      height: target instanceof Element ? target.clientHeight : target['innerHeight'],
    }
    /*if (target instanceof Element) {
      let rect = target.getBoundingClientRect();
      let text = "GET_SIZE";
      let element = target;
      while (element && element != window.document.body) {
        var rect2 = element.getBoundingClientRect();
        var style = getComputedStyle(element, null);
        var marginLeft = parseInt(style.marginLeft) || 0;
        var marginRight = parseInt(style.marginRight) || 0;
        var marginTop = parseInt(style.marginTop) || 0;
        var marginBottom = parseInt(style.marginBottom) || 0;
        text += "\n\t"+DomDirective.renderNode(element)+
                "\n\t: width:"+rect2.width+",height:"+rect2.height+",left:"+element.clientLeft+",top:"+element.clientTop+" margin(top:"+marginTop+",right:"+marginRight+",bottom:"+marginBottom+",left:"+marginLeft+")";
        element = element.parentElement;
      }
      console.debug(text);
      return {
        width: target.clientWidth,
        height: target.clientHeight
      }
    } else {
      return {
        width:  target['innerWidth'],
        height: target['innerHeight'],
      }
    }*/
  }
}
