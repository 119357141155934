import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

@Component({
  selector: 'play-button',
  template: `
    <div (click)="onPlay($event)">
      <mat-icon [style.color]="color">{{locked ? 'lock' : icon}}</mat-icon>
    </div>
  `,
  styleUrls: ['./play-button.component.scss'],
})
export class PlayButtonComponent {

  @Input() size = 20;
  @Input() icon = 'play_arrow';
  @Input() color = 'grey';
  @Input() @HostBinding('class.locked') locked = false;
  @Output() play = new EventEmitter<Event>();

  constructor() {
  }

  onPlay(event: MouseEvent) {
    this.play.emit();
  }
}
