import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource, MatTree } from '@angular/material/tree';
import { TranslateService } from '@ngx-translate/core';
import {Logger, Topic} from 'core';
import filter from 'lodash/filter';
import partition from 'lodash/partition';
import { BehaviorSubject } from 'rxjs';
import { FilterSearchbarComponent } from '../../core/filter-searchbar.component';

export class ElementFlatNode {
  id: string = '';
  label: string = '';
  icon: string;
  level!: number;
  expandable!: boolean;
  visible: boolean = true;

  getDisplayName(): string {
    return this.label ? this.label : this.id;
  }
}

@Injectable()
export class ElementDatabase {
  dataChange = new BehaviorSubject<Topic[]>([]);
  get data(): Topic[] {
    return this.dataChange.value;
  }
  initialize(elements: Topic[]) {
    // Notify the change.
    this.dataChange.next(elements);
  }
}

@Component({
  selector: 'topic-tree',
  templateUrl: './topic-tree.component.html',
  styleUrls: ['./topic-tree.component.scss'],
  providers: [ElementDatabase]
})
export class TopicTreeComponent {

  @ViewChild(FilterSearchbarComponent) searchBar;

  @Output() selectionChanged: EventEmitter<string[]> = new EventEmitter();

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<ElementFlatNode, Topic>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<Topic, ElementFlatNode>();

  treeControl: FlatTreeControl<ElementFlatNode>;
  treeFlattener: MatTreeFlattener<Topic, ElementFlatNode>;
  dataSource: MatTreeFlatDataSource<Topic, ElementFlatNode>;
  checklistSelection = new SelectionModel<ElementFlatNode>(true /* multiple */);

  _disabled: boolean = false;
  _selection: string[] = [];

  protected logger = new Logger('TopicTreeComponent');

  constructor(
    private _database: ElementDatabase,
    private translate: TranslateService
  ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ElementFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  @Input()
  set topics(topics: Topic[]) {
    this._database.initialize(topics);
  }

  @Input()
  set selection(selectedIds: string[]) {
    this._selection = selectedIds;
    this.checklistSelection.selected.forEach(node => this.checklistSelection.deselect(node));
    if (!selectedIds.length && this.searchBar) {
      this.searchBar.selectAllChecked = false;
    } else if (!!selectedIds) {
      this.flatNodeMap.forEach(node => {
        if (selectedIds.includes(node.id)) {
          this.checklistSelection.toggle(this.nestedNodeMap.get(node));
          this.checkAllParentsSelection(this.nestedNodeMap.get(node));
        }
      });
    }
    if (this.disabled) {
      this.filterSelectedNodes();
    }
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = value;
    if (value) {
      this.filterSelectedNodes();
    } else {
      this.treeControl.dataNodes.forEach(node => node.visible = true);
    }
  }

  private filterSelectedNodes() {
    this.treeControl.dataNodes.forEach(node => node.visible = false);
    if (!!this._selection) {
      const visibleNodes = filter(this.treeControl.dataNodes,
        node => this._selection.includes(node.id));
      visibleNodes.forEach(node => {
        node.visible = true;
        let parent = this.getParentNode(node);
        while (!!parent) {
          parent.visible = true;
          this.treeControl.expand(parent);
          parent = this.getParentNode(parent);
        }
      });
    }
  }

  get disabled() {
    return this._disabled;
  }

  getLevel = (node: ElementFlatNode) => node.level;

  isExpandable = (node: ElementFlatNode) => node.expandable;

  getChildren = (node: Topic): Topic[] => node.topics;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: Topic, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.id === node.id
      ? existingNode
      : new ElementFlatNode();
    flatNode.id = node.id;
    flatNode.label = node.label;
    flatNode.level = level;
    flatNode.icon = node.icon;
    flatNode.expandable = !!node.topics?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  getParentNode(node: ElementFlatNode): ElementFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: ElementFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: ElementFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle a leaf item selection. Check all the parents to see if they changed */
  itemSelectionToggle(node: ElementFlatNode): void {
    if (node.expandable) {
      this.checklistSelection.toggle(node);
      const descendants = this.treeControl.getDescendants(node);
      this.checklistSelection.isSelected(node)
        ? this.checklistSelection.select(...descendants)
        : this.checklistSelection.deselect(...descendants);

      // Force update for the parent
      descendants.forEach(child => this.checklistSelection.isSelected(child));
      this.checkAllParentsSelection(node);
    } else {
      this.checklistSelection.toggle(node);
      this.checkAllParentsSelection(node);
    }
    const selection = [];
    this.checklistSelection.selected.forEach(node => {
      if (!node.expandable) {
        selection.push(node.id);
      }
    });
    this.selectionChanged.emit(selection);
  }

  // resetFilter(keepSelectAll?: boolean) {
  //   super.resetFilter();
  //   this.checklistSelection.selected.forEach(node => this.checklistSelection.deselect(node));
  //   if (!keepSelectAll) {
  //     this.searchBar.selectAllChecked = false;
  //   }
  // }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: ElementFlatNode): void {
    let parent: ElementFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: ElementFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  onSearch(value: string) {
    this.treeControl.dataNodes.forEach(node => node.visible = true);
    if (!value) {
      this.treeControl.collapseAll();
    } else {
      this.treeControl.collapseAll();
      const result = partition(this.treeControl.dataNodes,
        node => this.translate.instant(node.getDisplayName()).toLowerCase().indexOf(value.toLowerCase()) === -1
      );
      const hiddenNodes = result[0];
      const visibleNodes = result[1];
      hiddenNodes.map(node => {
        node.visible = false;
      });
      visibleNodes.forEach(node => {
        let parent = this.getParentNode(node);
        while (!!parent && !parent.visible) {
          this.treeControl.expand(parent);
          parent.visible = true;
          parent = this.getParentNode(parent);
        }
      });

      const setChildrenVisible = (node: ElementFlatNode) => {
        node.visible = true;
        if (node.expandable) {
          this.treeControl.getDescendants(node).forEach(child => setChildrenVisible(child));
        }
      };
      visibleNodes.forEach(node => {
        if (node.expandable) {
          setChildrenVisible(node);
        }
      });

      hiddenNodes.forEach(node => this.checkAllParentsHidden(node));
    }
  }

  checkAllParentsHidden(node: ElementFlatNode): void {
    let parent: ElementFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeHidden(parent);
      parent = this.getParentNode(parent);
    }
  }

  checkRootNodeHidden(node: ElementFlatNode): void {
    const descendants = this.treeControl.getDescendants(node);
    if (!node.visible) {
      const descAllHidden = descendants.length > 0 && descendants.every(child => {
        return !child.visible;
      });
      if (!descAllHidden) {
        node.visible = true;
      }
    }
  }

  onSelectAll(value: boolean) {
    if (value) {
      const visibleNodes = this.treeControl.dataNodes.filter(node => node.visible);
      const selectedTopicIds = visibleNodes.reduce((result: string[], node: ElementFlatNode) => {
        this.checklistSelection.select(node);
        if (!node.expandable) {
          result.push(this.flatNodeMap.get(node).id);
        }
        return result;
      }, [] as string[]);
      this.logger.debug('onSelectAll', { value, selectedTopicIds });
      this.selectionChanged.emit(selectedTopicIds);
    } else {
      this.logger.debug('onSelectAll', { value });
      this.checklistSelection.clear();
      this.selectionChanged.emit([]);
    }
  }
}
