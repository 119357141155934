import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UPLOAD_TYPE} from "./upload-type";

@Component({
  selector: 'upload-type-list',
  templateUrl: './upload-type-list.component.html',
  styleUrls: ['./upload-type-list.component.scss']
})
export class UploadTypeListComponent implements OnInit {

  @Input()
  set uploadTypes(value: UPLOAD_TYPE[])  {
      this.types = value;
  }

  @Output() selectType = new EventEmitter<string>();
  @Output() addLink= new EventEmitter<string>();

  private _types: UPLOAD_TYPE[];
  set types(value: UPLOAD_TYPE[]) {
    this._types = (value?.length ? value : Object.values(UPLOAD_TYPE)).filter(type => type!=UPLOAD_TYPE.LINK);
  }
  get types() {
    return this._types
  }

  constructor() { }

  ngOnInit(): void {
  }

  onSelectType(type: string) {
    type!='link' && this.selectType.emit(type);
  }

  onAddLink(link: string) {
    this.addLink.emit(link);
  }
}
