import {Store} from '@ngrx/store';
import {Observable, Subject} from 'rxjs';
import {take, takeUntil, tap} from 'rxjs/operators';
import {Store as UppyStore} from "@uppy/core";
import {UploadFiles, UploadState} from './models';
import {selectUploadFiles, selectUploadState} from "./reducers";
import {uploadUpdate} from "./actions";
import {Logger} from "core";

export interface UploadStoreOptions {
  id?: any;
  store: Store<UploadState>;
}

export class UploadStore implements UppyStore {

  protected id: string;
  protected store: Store<any>;

  protected logger = new Logger('UploadStore');

  constructor(opts: UploadStoreOptions) {
    this.id = opts.id || 'uppy';
    this.store = opts.store;
    this.setState({});
  }

  setState(patch: any): void {
    // this.logger.debug('setState', 'patch', patch);
    this.store.dispatch(uploadUpdate({ payload: { patch, id: this.id }} ));
  }

  getState(): any {
    let result = undefined;
    this.store.select(selectUploadState, this.id)
      .pipe(take(1))
      .subscribe(state => result = state);
    // this.logger.debug('getState', result);
    return result;
  }

  subscribe(listener: any): () => void {
    const subject: Subject<boolean> = new Subject<boolean>();
    let previousState = this.getState();
    this.store.select(selectUploadState, this.id)
      .pipe(takeUntil(subject))
      .subscribe(nextState => {
        if (previousState !== nextState) {
          const patch = {};
          const keys = Object.keys(nextState);
          keys.forEach(key => {
            if (previousState[key] !== nextState[key]) {
              patch[key] = nextState[key];
            }
          });
          // this.logger.debug('subscribe > listener', previousState, nextState, patch);
          listener(previousState, nextState, patch);
          previousState = nextState;
        }
      });
    return () => subject.next(true);
  }

  getStore(): Store<any> {
    return this.store;
  }

  getFiles(): Observable<UploadFiles> {
    return this.store.pipe(selectUploadFiles(this.id), tap(files => {
      // this.logger.debug('getFiles', files);
    }));
  }
}

export function createUploadStore(opts: UploadStoreOptions): UploadStore {
  return new UploadStore(opts);
}
