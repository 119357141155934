import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
        selector: '[cleanInput]'
})
export class CleanInputDirective {

    private regex = /[^\x20-\x7E\u00A0-\u00BE\u2000-\u200A\u202F\u205F\u3000]+/gu;
    constructor(private elementRef: ElementRef) {}

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        event.preventDefault();
        event.stopPropagation();
        const text = event.clipboardData?.getData('text/plain') || '';
        const clean = text.replace(this.regex, '');
        this.elementRef.nativeElement.value = clean;
        const eventInput = new Event('input', { bubbles: true });
        this.elementRef.nativeElement.dispatchEvent(eventInput);
    }
}
