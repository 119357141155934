/* projects/shared/src/lib/components/country-select/country-select.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: inline-block;
  overflow: hidden;
}
.country {
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;
  margin-right: 3px;
  margin-bottom: 3px;
  background-color: var(--color-primary);
  color: white;
  vertical-align: middle;
}
.country > * {
  display: inline-block;
  vertical-align: middle;
}
.country > button {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin: -3px 0 0 2px;
}
.country mat-icon {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  vertical-align: middle;
}
.country.add {
  position: relative;
  background-color: var(--color-primary);
  cursor: pointer;
}
.country.add select {
  position: absolute;
  background-color: transparent;
  color: transparent;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.country.add.add {
  background-color: var(--color-primary);
  cursor: pointer;
}
.country.add.add ::ng-deep .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
/*# sourceMappingURL=country-select.component-SQQRNX3O.css.map */
