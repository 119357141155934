import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {animate, AnimationEvent, state, style, transition, trigger} from "@angular/animations";

export type RatingSegments = {[key: number]: number};

@Component({
  selector: 'rating-summary',
  templateUrl: './rating-summary.component.html',
  styleUrls: ['./rating-summary.component.scss'],
  animations: [
    trigger('segmentAnimation',
    [
        state('flip', style({ width: '{{ from }}' }), { params: { from: '0' } }),
        state('flop', style({ width:   '{{ to }}' }), { params: {   to: '0' } }),
        // transition('* <=> *', animate('{{ time }}' )),
        transition('flip => flop', animate('{{ time }}' ))
      ]
    )
  ],
  // if OnPush is enabled animation states are not detected by default
  // i.e. change detection should be triggered by other means
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingSummaryComponent implements OnInit, AfterViewInit {

  @Input() minRating = 1;
  @Input() maxRating = 5;

  segments = Array(this.maxRating - this.minRating + 1)
    .fill(0)
    .map((_, idx) => this.maxRating - idx);
  ratingSegments: RatingSegments;
  maxSegment: number;
  totalRatingCount: number;
  averageRating: number;

  ratingSegmentsFrom: RatingSegments;
  maxSegmentFrom: number;
  segmentAnimationStates: string[] = Array(
      Math.max(0, this.maxRating - this.minRating) + 1
    ).fill('flip');
  segmentAnimationDisabled = true;

  static SEGMENT_ANIMATION_TIME = '0.75s';

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  @Input()
  set ratings(ratingSegments: RatingSegments) {
    this.ratingSegmentsFrom = this.ratingSegments || {};
    this.maxSegmentFrom     = this.maxSegment || 0;
    // prepare to flop later - just change the state
    // but disable animation to prevent triggering its execution
    // (seems animation is triggered even if there is no related state transition defined)
    // as having an animation in progress when the subsequent flip=>flop state change happens
    // may lead to interference and failure to properly animate the transition.
    this.segmentAnimationDisabled = true;
    this.segmentAnimationStates.fill('flip');
    this.segmentAnimationDisabled = false;
    this.ratingSegments = ratingSegments || {};
    this.maxSegment = 0;
    let totalRating = this.totalRatingCount = 0;
    Object.keys(ratingSegments).forEach((key) => {
      const  rating = Math.max(0, Math.min(Number(key) || 0, this.maxRating));
      if (rating>0) {
        const segment = ratingSegments[rating];
        totalRating  += rating * segment;
        this.totalRatingCount += segment;
        if (this.maxSegment < segment) {
          this.maxSegment = segment;
        }
      }
    });
    this.averageRating = this.totalRatingCount > 0 ? totalRating / this.totalRatingCount : 0;
    window.setTimeout(() => {
      this.segmentAnimationStates.fill('flop');
      this.changeDetector.markForCheck();
    }, 100);
    /*
    console.debug(`RatingSummary [averageRating=${this.averageRating},
                                  totalRatingCount=${this.totalRatingCount},
                                  segments=${this.segments},
                                  ratingSegments=${JSON.stringify(ratingSegments)},
                                  maxSegment=${this.maxSegment},
                                  segmentAnimationStates=${this.segmentAnimationStates}]`
    );
    */
  }

  segmentRatio(segment: number, maxSegment: number): number {
    segment    = segment    || 0;
    maxSegment = maxSegment || 0;
    return maxSegment!=0 ? 100 * segment / maxSegment : 0;
  }

  segmentAnimationTime(segment: number, index: number): string {
    return RatingSummaryComponent.SEGMENT_ANIMATION_TIME;
  }

  formatNumber(number: number) {
    return number
      ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : '';
  }

  onAnimationDone(segment: number, index: number, event: AnimationEvent) {
    // if (event.toState=='flop') {
    //   // prepare ready for the next change
    //   this.segmentAnimationStates[index] = 'flip';
    // }
  }
}
