import {AfterContentInit, Directive, ElementRef, EventEmitter, OnDestroy, Output} from '@angular/core';
import {Subject} from "rxjs";
import {ResizeService} from "./resize.service";
import {ResizeEvent} from "./resize.event";
import {filter, takeUntil} from "rxjs/operators";

/**
 * add following attributes to any component element you want to survail:
 * <div (onResize)="onResize($event)">
 *
 * onResize is fired whenever the size changes.
 */
@Directive({
  selector: '[onResize],[onResizeAsync]'
})
export class ResizeDirective implements AfterContentInit, OnDestroy {
  @Output() onResize: EventEmitter<ResizeEvent> = new EventEmitter();
  @Output() onResizeAsync: EventEmitter<ResizeEvent> = new EventEmitter();
  // @Input() log = false;
  protected onDestroy$ = new Subject<void>();
  previousEvent: ResizeEvent;
  previousAsyncEvent: ResizeEvent;
  asyncTimeout:number;

  public constructor(
    protected elementRef: ElementRef,
    protected resizeService: ResizeService) {
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public ngAfterContentInit(): void {
    let currentSize = this.resizeService.getSize(this.elementRef.nativeElement);
    this.previousEvent = {
      currentSize: currentSize,
      previousSize: currentSize,
      element: this.elementRef
    };
    this.previousAsyncEvent = {
      ...this.previousEvent
    }
    this.resizeService.windowSize$.pipe(
      takeUntil(this.onDestroy$),
      filter(windowSize => document.body.contains(this.elementRef.nativeElement))
    ).subscribe(windowSize => {
      let size = this.resizeService.getSize(this.elementRef.nativeElement);
      //if (this.log) console.log("RESIZE",size,this.elementRef.nativeElement);
      if (size.width  != this.previousEvent.currentSize.width ||
          size.height != this.previousEvent.currentSize.height) {
        let event : ResizeEvent = {
          currentSize: size,
          previousSize: this.previousEvent.currentSize,
          element: this.previousEvent.element
        }
        this.onResize.emit(event);
        this.previousEvent = event;
        //console.log("RESIZE",event);
        if (!this.asyncTimeout) {
          this.asyncTimeout = window.setTimeout(()=> {
            this.asyncTimeout = undefined;
            let asyncEvent : ResizeEvent = {
              currentSize: this.previousEvent.currentSize,
              previousSize: this.previousAsyncEvent.currentSize,
              element: this.previousAsyncEvent.element
            }
            this.onResizeAsync.emit(asyncEvent);
            this.previousAsyncEvent = asyncEvent;
          });
        }
      }
    });
    this.onResize.emit(this.previousEvent);
    this.onResizeAsync.emit(this.previousEvent);
  }
}
