/* angular:styles/component:css;a59ed88a876567e4eea32678444c7aeaa3419ab12e21727fc452829284bba76b;/Users/mbratoev/Project/fbgroup/client.ng/projects/shared/src/lib/components/legal-info/legal-info-bottom-sheet.component.ts */
:host {
  display: flex;
  position: relative;
  max-height: 100%;
}
legal-info {
  margin-top: 30px;
  overflow-y: scroll;
}
button.close {
  position: absolute;
  top: 0;
  right: 0;
}
button mat-icon {
  color: #aaa;
}
button.scrollTop {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(192, 192, 192, 0.5);
  border: 1px solid transparent;
  border-radius: 6px;
  cursor: pointer;
}
button.scrollTop:hover {
  background-color: rgba(192, 192, 192, 0.7);
  border-color: rgba(128, 128, 128, 0.5);
}
button.scrollTop:hover mat-icon {
  color: #888;
}
/*# sourceMappingURL=legal-info-bottom-sheet.component-YRHSHA5K.css.map */
