import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UploadFile} from "../../store/models";
import {Logger} from "core";

@Component({
  selector: 'upload-list-item',
  templateUrl: './upload-list-item.component.html',
  styleUrls: ['./upload-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadListItemComponent implements OnInit, OnChanges {

  @Input() uploadFile: UploadFile;
  @Output() onAction = new EventEmitter<string>();
  protected resuming = false;
  protected paused = false;

  protected logger = new Logger('UploadListItemComponent');

  constructor() { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    // this.logger.debug('UploadListItemComponent', changes);
    const previous: UploadFile = changes['uploadFile'].previousValue;
    const  current: UploadFile = changes['uploadFile'].currentValue;
    if (previous && current) {
      const previousBytesUploaded = previous && previous.progress && previous.progress.bytesUploaded || 0;
      const currentBytesUploaded = current.progress && current.progress.bytesUploaded || 0;
      this.resuming = previousBytesUploaded == currentBytesUploaded && !current.isPaused;
      this.paused = previous.isPaused;
      // this.logger.debug(`ngOnChanges:
      //   [previousBytesUploaded, ${previousBytesUploaded}],
      //   [currentBytesUploaded, ${currentBytesUploaded}],
      //   [previous.isPaused, ${previous.isPaused}],
      //   [current.isPaused, ${current.isPaused}]
      // `);
    }
    // this.logger.debug(`ngOnChanges: [resuming, ${this.resuming}]`)
  }

  onTapAction(action: string) {
    /* bypass pause/resume processing while resuming.
     * this is a workaround for a tusd server related "423 Locked" issue described below:
     *
     * https://github.com/tus/tus-resumable-upload-protocol/issues/115
     * https://github.com/tus/tusd/issues/208
     *
     * "The tusd server implements locking which will return a 423 if multiple client try to access the same upload simultaneously.
     * However, this is custom behavior and is not defined in the specification."
     *
     * "The tusd server will also return 423 Locked when a HEAD request is issued while a PATCH request is running to the same upload URL.
     * The reason is that the Upload-Offset header may not be correct anymore as the offset is only updated once, if the PATCH request completes but not in between.
     * Personally, I am not sure if this is the best approach but for now it has served the purpose."
     *
     * TODO: move the resuming state detection and actual action bypassing outside of the component UploadRefImpl will be a better place.....
     */

    // if (!this.resuming || action=='cancel') {
    if (action=='resume' && !this.resuming ||
        action=='pause'  && !this.paused ||
        action=='cancel') {
      this.onAction.emit(action);
    }
  }
}
