/* projects/shared/src/lib/components/search-bar/search-bar.component.scss */
mat-toolbar {
  padding-left: 4px;
  padding-right: 4px;
}
.title-container {
  flex: 1 1 0%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.title-container:first-child {
  padding-left: 20px;
}
.search-container {
  flex: 1 1 0%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
}
.search-container > mat-form-field {
  width: 100%;
}
.search-icon {
  margin-left: 8px;
  margin-right: 8px;
}
mat-toolbar > button:last-child {
  margin-right: 8px;
}
:host-context(.color-scheme-light.pure) {
  border-bottom: 1px solid #ddd;
}
:host-context(.color-scheme-light.pure) mat-toolbar {
  border-bottom: none;
}
/*# sourceMappingURL=search-bar.component-IMSYTXCN.css.map */
