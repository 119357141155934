<app-search-bar #searchBar
                *ngIf="hasToolbar"
                color="primary"
                [(ngModel)]="searchTerm"
                (onSearchTermChange)="updateSearchTerm($event)"
                (onLeftClicked)="!!onLeftClicked.observers.length
                                 ? onLeftClicked.emit()
                                 : layoutService.navigation.toggle()"
                (onTitleClicked)="onTitleClicked.emit()"
                (onRightClicked)="onRightClicked.emit()"
                [leftButton]="leftButton"
                [leftIcon]="leftIcon"
                [rightIcon]="rightIcon"
                [rightButton]="false"
                [searchButton]="false"
                [rightButtonTemplateRef]="rightButtonTemplate">
  <span *ngIf="!!title" class="title breadcrumb" [innerHTML]="title| safeHtml"></span>
</app-search-bar>
<ng-container *ngTemplateOutlet="extensionBarTemplate; context: {}"></ng-container>
<div class="drop-container">
  <media-grid #mediaGrid
              [mediaCovers]="mediaCovers"
              [author$]="author$"
              [entities$]="entities$"
              [cacheId$]="cacheId$"
              [selectedIndex]="mediaService.selectedIndex$ | async"
              [defaultCoverHeight]="defaultCoverHeight"
              [forcedCoverHeight]="forcedCoverHeight"
              [canDrop]="canDrop"
              (mediaDrop)="onMediaDrop($event)"
              (mediaDragStart)="onMediaDragStart($event)"
              (mediaDragEnd)="onMediaDragEnd($event)"
              (selectedTopic)="selectedTopic.emit($event)">
    <ng-template #mediaBadges let-index="index" let-media="media" let-folder="folder">
      <div *ngIf="!folder && duration(media); let duration" class="badge right bottom">{{duration}}</div>
      <!--
      <div class="light badge left bottom">
        <mat-icon>visibility</mat-icon>
        <span>{{views(media)}}</span>
      </div>
      -->
      <!--
      <div *ngIf="!folder && !!propertiesService.group?.showMediaSourceTags &&
          (user.isLeader || user.isAdmin) &&
          (media.author_source=='downline' || media.author_source=='crossline')"
           class="badge left top" [ngClass]="sourceBadgeClass(media)">
      </div>
      -->
      <ng-container> <!-- *ngIf="media.tags?.includes('hide.details')"> -->
        <button class="badge more more-horizontal-box" tabindex="-1" mat-icon-button (click)="onTapDetails(index, media, $event)">
          <mat-icon>more_horiz</mat-icon>
        </button>
      </ng-container>
      <div *ngIf="rating(media); let rating;" class="badge left bottom rating" (click)="onTapRating(index, media, $event)">
        <rating-stars [rating]="rating" [small]="true" [inverted]="true"></rating-stars>
      </div>
      <ng-container *ngLet="playedBadgeInfo(media) as badge">
        <div *ngIf="!!badge && !folder"
             class="badge played {{badge.class}}"
             [ngSwitch]="badge.icon">
          <mat-icon *ngSwitchCase="'eye'">visibility</mat-icon>
          <ng-container *ngSwitchDefault>
            <mat-icon *ngIf="badge.icon">{{badge.icon}}</mat-icon>
          </ng-container>
          <span *ngIf="badge.label" translate>{{badge.label}}</span>
        </div>
      </ng-container>
      <!--
      <button tabindex="-1" mat-icon-button class="more-plain" (click)="onTapDetails(index, media, $event)"
              *ngIf="mediaGrid.selectedIndex==index && media.tags?.includes('hide.details') && !(!viewed$()?.id && (user.id==media.author?.id || user.isAdmin))">
        <mat-icon>more_vert</mat-icon>
      </button>
      -->
    </ng-template>
    <ng-template #mediaDetails let-index="index" let-media="media" >
      <!--
      <ng-container *ngIf="!media.tags?.includes('hide.details') || (!viewed$()?.id && (user.id==media.author?.id || user.isAdmin))">
      -->
      <ng-container *ngIf="!media.tags?.includes('hide.details')">
        <div class="pending"  *ngIf="mediaService.isMediaApprovalPending(media)" translate>invite.mediaPending</div>
        <div class="declined" *ngIf="mediaService.isMediaDeclined(media)" translate>invite.mediaDeclined</div>
        <div class="media-details" [class.invisible]="!media.author?.visible" (click)="onTapDetails(index, media, $event)">
          <div *ngIf="!!media">
            <ng-container *ngTemplateOutlet="mediaDetailsRef; context: { index: index, media: media }"></ng-container>
            <ng-container *ngTemplateOutlet="mediaActionsRef; context: { index: index, media: media }"></ng-container>
          </div>
          <!--
          <button dense tabindex="-1" mat-icon-button (click)="onTapDetails(index, media, $event)">
            <mat-icon>more_vert</mat-icon>
          </button>
          -->
        </div>
      </ng-container>
    </ng-template>

    <!--
    <ng-template #controlsTemplate let-index="index" let-media="media">
      <button *ngIf="hasSettings" mat-mini-fab class="settings" (click)="selectMedia(index, media, 'details')">
        <mat-icon aria-label="settings">settings</mat-icon>
      </button>
    </ng-template>
    -->
  </media-grid>
  <div (dragenter)="onMediaDropContainerDragEnter($event)"
       (dragover)="onMediaDropContainerDragOver($event)"
       (dragleave)="onMediaDropContainerDragLeave($event)"
       class="drop-overlay">
    <div><mat-icon>input</mat-icon></div>
  </div>
</div>
<app-status-bar *ngIf="hasStatusbar"><span>{{size$ | async | number}}</span></app-status-bar>
<ng-template #details let-index="index" let-media="media">
  <h3 class="media-name" translate>{{mediaService.replaceName(media.name)}}</h3>
  <div class="line">
    <span class="author" *ngIf="!media.tags?.includes('hide.author') && (media.author?.name || media.published?.contact?.name); let author"
    (click)="onAuthorClick(media.author.id)">{{author}}</span>
    <span *ngIf="!media.tags?.includes('hide.timeProduced')">{{publishTime$(media) | async}}</span>
    <ng-container *ngIf="!media.tags?.includes('hide.views')" >
      <span class="nowrap" *ngIf="media.reactionSummary?.views?.[10]; let views">
        <mat-icon>visibility</mat-icon>
        <span>{{fullViews$(views) | async}}</span>
      </span>
    </ng-container>
  </div>
</ng-template>
<ng-template #actions let-index="index" let-media="media">
  <!-- No actions are rendered by default -->
</ng-template>
<ng-template #rightButtonTemplate>
  <button mat-icon-button class="rightButton" [class]="hasFilters ? 'inverted warn' : ''" (click)="onRightClicked.emit()">
    <mat-icon>{{rightIcon}}</mat-icon>
  </button>
</ng-template>
<ng-template #extensionBar></ng-template>
