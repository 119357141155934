import {
  Component,
  ComponentFactory,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Logger, Topic} from "core";
import {MediaViewer} from "../../media-viewer";
import {Media, SurveyLink} from "../../../../store/models";
import {MediaService} from "../../../../service/media.service"
import {MediaEditor} from "../../media-editor";
import cloneDeep from "lodash/cloneDeep";
import {SURVEY_COMPONENT_FACTORY, SurveyComponent} from "./survey-component";
import {PropertiesService} from "properties";
import {Subject, Subscription, takeUntil} from "rxjs";
import {scan} from "rxjs/operators";

@Component({
  selector: 'app-survey-viewer',
  templateUrl: './survey-viewer.component.html',
  styleUrls: ['./survey-viewer.component.scss']
})
export class SurveyViewerComponent extends MediaViewer implements MediaEditor {

  @ViewChild('host', {static: true, read: ViewContainerRef}) host!: ViewContainerRef;

  link: SurveyLink;
  protected callback: (media: Media) => void;
  protected readonly untilDestroyed = this.takeUntilDestroyed();
  protected subscription: Subscription;
  protected logger = new Logger('SurveyViewerComponent');

  constructor(@Inject(SURVEY_COMPONENT_FACTORY)
              protected surveyFactory: ComponentFactory<SurveyComponent>,
              protected propertiesService: PropertiesService,
              public mediaService: MediaService,
              protected elementRef: ElementRef) {
    super();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    if (!this.media?.touched) { //this.media.completed??
      this.mediaService.markPlayed(this.media.id);
    }
    this.media.actions.forEach(action => this.displayAction(action.id, true));
  }

  setMedia(media: Media): Promise<Media>  {
    // the following line is a legal ts code but the compiler reports an error
    // ([] as AudioLink[] | ImageLink[] | VideoLink[] | SurveyLink[]).find(() => true);
    const result = super.setMedia(media);
    this.host.clear();
    this.link = media?.links[0] as SurveyLink;
    if (this.link) {
      const surveyComponent = this.host.createComponent<SurveyComponent>(this.surveyFactory);
      const instance = surveyComponent.instance;
      instance.survey = this.link?.survey;
      instance.selectedTags = this.link?.selectedTags;
      this.subscription?.unsubscribe();
      this.subscription = instance.selectionChanged
        .pipe(
          this.untilDestroyed(),
          scan((state: {
              selection: [Topic, string[]],
              surveyTag: string,
              complete?: boolean
            },
            selection: [Topic, string[]],
            index: number) => {
            state.complete = selection?.[1]?.includes(state.surveyTag) &&
                            !state[1]?.includes(state.surveyTag)
            state.selection = selection;
            return state;
          }, {
            selection: [instance.survey, instance.selectedTags],
            surveyTag: `${instance.survey.id}.survey`,
          })
        )
        .subscribe((state:  {
          selection: [Topic, string[]],
          surveyTag: string,
          complete: boolean
        }) => {
          this.logger.debug('selectionChanged', state.selection);
          this.onSelectionChanged(state.selection);
          if (state.complete) {
            const element = this.elementRef.nativeElement;
            if (element && element.scrollTop!=0) {
              element.scrollTop = 0;
            }
          }
        });
      (instance as any).contactId = media.viewedAsId;
      (instance as any).readonly = media.readOnly;
      (instance as any).defaultInfo = false; // what is defaultInfo purpose?
    }
    return result;
  }

  onMediaChange(callback: (media: Media) => void) {
    this.callback = callback;
  }

  onSelectionChanged([topic, selection]: [Topic, string[]] ) {
    const link = { survey: this.link.survey, selectedTags: selection } as SurveyLink;
    const media = cloneDeep(this.media);
    media.links = [link];
    this.callback?.(media);
  }

  takeUntilDestroyed() {
    const subject = new Subject();
    inject(DestroyRef).onDestroy(() => {
      subject.next(true);
      subject.complete();
    });
    return <T>() => takeUntil<T>(subject.asObservable());
  }
}
