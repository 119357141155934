<div *ngIf="media && mediaForm" [ngSwitch]="!!editMode" class="container">
    <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="view"></ng-container>
    <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="edit"></ng-container>
</div>

<ng-template #view>
    <ng-container *ngIf="{ state: !isReader && (media.review || media.published), reaction: media.reaction } as context">
        <div class="state-container" *ngIf="context.state || context.reaction">
            <ng-container *ngIf="context.state"
                          [ngTemplateOutlet]="stateInfoTemplate"
                          [ngTemplateOutletContext]="stateInfoTemplateContext">
            </ng-container>
            <ng-container *ngIf="context.reaction"
                          [ngTemplateOutlet]="reactionTemplate"
                          [ngTemplateOutletContext]="{ reaction: context.reaction }">
            </ng-container>
        </div>
    </ng-container>

    <div class="media-name">{{mediaService.replaceName(media.name) | translate }}</div>

    <div *ngIf="media.author && !(mediaTags.includes('hide.author') && mediaTags.includes('hide.timeProduced'))" class="author-date" >
        <img matListItemAvatar
             [src]="getAvatarUrl() | sessionLink"
             [style.width]="'40px'"
             [style.height]="'40px'"
             (click)="onAuthorClick()"
             onload="this.style.opacity=1"
             *ngIf="!mediaTags.includes('hide.author')" />
        <div class="author-date-text">
            <span class="author-name"
                  (click)="onAuthorClick()"
                  *ngIf="!mediaTags.includes('hide.author')">
                {{media.author.name}}
            </span>
            <span *ngIf="!mediaTags.includes('hide.timeProduced')">{{timeProduced(media) | date}}</span>
        </div>
    </div>

    <div *ngIf="mediaTags.includes('hide.author') && mediaTags.includes('hide.timeProduced') && media.info"
         class="separator">
    </div>

    <more-text [text]="media.info"></more-text>

    <ng-container *ngIf="isVerifiedMember && media.author && !editMode">
        <div class="spacing"></div>
        <span class="media-id">{{'media.id' | translate}}: <span>{{media.id}}</span></span>
        <span *ngIf="isAdmin" class="media-id">{{'media.path' | translate}}: <span>{{media.path?.substring('media'.length,media.path.length-media.id.length)}}</span></span>
        <div class="section header ownership" [innerHTML]="'media.source' | translate:{
            author: media.author.name,
            source: ('structure.'+media.author.source) | translate,
            root: media.author.root
            } | safeHtml">
        </div>
    </ng-container>

    <ng-template #stateInfoTemplate let-data="data">
        <div *ngIf="data" class="state-info section" [ngClass]="data.class">
            <ng-container [ngSwitch]="data.class=='declined'">
                <mat-icon *ngSwitchCase="false">verified_user</mat-icon>
                <mat-icon *ngSwitchCase="true">report_problem</mat-icon>
            </ng-container>
            <span><span translate>{{data.text}}</span>&nbsp;{{data.name}}</span>
        </div>
    </ng-template>

    <ng-template #reactionTemplate let-reaction="reaction">
        <!--    <div class="reaction section" @reaction>-->
        <div class="reaction section">
            <ng-container [ngSwitch]="Reaction.getReactionType(reaction)">
                <ng-container *ngSwitchCase="'report'">
                    <mat-icon>flag_circle</mat-icon>
                    <span [innerHTML]="('media.reactions.'+reaction) | translate | safeHtml"></span>
                    <button mat-icon-button class="delete" (click)="onTapReactionRemove()">
                        <mat-icon>delete</mat-icon>
                    </button>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>
</ng-template>

<ng-template #edit>
    <form [formGroup]="mediaForm">
        <mat-form-field style="box-sizing: initial!important">
            <mat-label>{{'media.name' | translate}}</mat-label>
            <textarea matInput
                      #nameInput
                      unmodified-input
                      formControlName="name"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="8"
                      maxlength="100"
                      required>
            </textarea>
            <mat-hint align="end">{{nameInput.value?.length || 0}}/100</mat-hint>
            <a *ngIf="resetHandler" class="action reset" (click)="onReset()"
               [innerHTML]="('actions.reset') | translate | lowercase"></a>
            <mat-error *ngIf="mediaForm.controls.name.invalid && mediaForm.controls.name.touched">
                <span translate>media.validation.nameRequired</span>
            </mat-error>
        </mat-form-field>
        <ng-container *ngIf="mode!='basic' && isAdmin">
            <ng-container formGroupName="author">
                <mat-form-field (click)="onMediaAuthorSelect($event)">
                    <mat-icon matPrefix>person</mat-icon>
                    <mat-label translate>media.author</mat-label>
                    <input type="text"
                           matInput
                           unmodified-input
                           formControlName="name"
                           name="author"/>
                    <mat-icon matSuffix>arrow_right</mat-icon>
                </mat-form-field>
            </ng-container>
        </ng-container>
        <mat-form-field>
            <mat-label>{{'media.info' | translate}}</mat-label>
            <textarea matInput
                      formControlName="info"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      maxlength="5000"
                      cdkAutosizeMinRows="1">
            </textarea>
        </mat-form-field>
        <mat-form-field>
            <mat-label translate>media.timeProduced</mat-label>
            <input matInput
                   formControlName="timeProduced"
                   readonly
                   [matDatepicker]="producedDatePicker"
                   [matDatepickerFilter]="producedDateFilter">
            <mat-datepicker-toggle matSuffix [for]="producedDatePicker">
            </mat-datepicker-toggle>
            <button mat-icon-button matSuffix (click)="onClearTimeProduced()"
                    *ngIf="mediaForm.controls.timeProduced?.dirty">
                <mat-icon>undo</mat-icon>
            </button>
            <mat-datepicker #producedDatePicker disabled="false" [touchUi]="platform.is('hybrid')"></mat-datepicker>
        </mat-form-field>
        <mat-checkbox [checked]="mediaTags.includes('hide.author')"
                      (click)="toggleFilter('hide.author')"
                      aria-label="author not visible">
            <span translate>media.hide.author</span>
        </mat-checkbox>
        <mat-checkbox [checked]="mediaTags.includes('hide.timeProduced')"
                      (click)="toggleFilter('hide.timeProduced')"
                      aria-label="time produced not visible">
            <span translate>media.hide.timeProduced</span>
        </mat-checkbox>
        <mat-checkbox [checked]="mediaTags.includes('hide.views')"
                      (click)="toggleFilter('hide.views')"
                      aria-label="views not visible">
          <span translate>media.hide.views</span>
        </mat-checkbox>
        <mat-checkbox [checked]="!!mediaTags.includes('hide.details')"
                      (change)="toggleFilter('hide.details')"
                      aria-label="hide details">
          <span translate>media.hide.details</span>
        </mat-checkbox>
    </form>
</ng-template>
