<ul>
  <li (click)="sectionId='privacy'" translate>entry.actions.privacyPolicy</li>
  <li (click)="sectionId='terms'" translate>entry.actions.terms</li>
</ul>

<p [innerHTML]="(infoLabel$ | async) | translate | safeHtml"></p>

<!--
<h2 id="privacy">Privacy</h2>
<p>Privacy...</p>

<p [innerHTML]="(infoLabel$ | async) | translate | safeHtml"></p>

<h2 id="terms">Terms</h2>
<p>Terms...</p>
-->
