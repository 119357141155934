import {Action, createAction, props} from "@ngrx/store";
import {Session} from "./models";

export const sessionListSubscribeAction = createAction(
  'session.list.subscribe',
  props<{
    send : boolean
  }>()
);

export const sessionListUnsubscribeAction = createAction(
  'session.list.unsubscribe',
  props<{
    send : boolean
  }>()
);

export const sessionUpdateAction = createAction(
  'session.update',
  props<{
    session : Session
  }>()
);

export const sessionCloseAction = createAction(
  'session.close',
  props<{
    sessionId : string
  }>()
);

export const sessionCloseDoneAction = createAction(
  'session.close.done',
  props<{
    sessionId : string
  }>()
);

export const sessionCloseFailedAction = createAction(
  'session.close.failed',
  props<{
    sessionId : string,
    error: any
  }>()
);

export const sessionSelectAction = createAction(
  'session.select',
  props<{
    selectedIndex : number
  }>()
);

export const sessionEmptyAction = createAction(
  'session.empty'
);


