import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {I18nService} from "core";
import {PropertiesService} from "properties";
import {Observable} from "rxjs";

@Component({
  selector: 'app-language-link',
  template: `
    <a tabIndex="-1" href="javascript:void(0);" [matMenuTriggerFor]="languageMenu">
      <span>{{getLanguageLabel(currentLanguage) | translate}}</span>
    </a>
    <mat-menu #languageMenu="matMenu">
      <button mat-menu-item *ngFor="let language of languages | async" (click)="setLanguage(language)">
        {{getLanguageLabel(language) | translate}}
      </button>
    </mat-menu>
  `,
  styles: [`
    a {
      display: inline-block;
      text-decoration: none;
      color: inherit;
      font-weight: inherit;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageLinkComponent implements OnInit {

  constructor(protected i18nService: I18nService, protected propertiesService: PropertiesService) { }

  ngOnInit() {
  }

  getLanguageLabel(language: string) : string {
    return 'languages.'+language;
  }

  setLanguage(language: string) {
    this.i18nService.setLanguage(language);
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): Observable<string[]> {
    console.log("LANGUAGES", this.i18nService.supportedLanguages, this.propertiesService.properties.languages);
    return this.i18nService.supportedLanguages$;
  }
}
