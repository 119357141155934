import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {asyncScheduler, BehaviorSubject, combineLatest, fromEvent, Observable} from "rxjs";
import {Media, NULL_MEDIA} from "../../store/models";
import {BasicContainerComponent, isValidNumber, ResizeEvent, VirtualGridComponent} from "shared";
import {MediaService} from "../../service/media.service";
import {debounceTime, filter, shareReplay, takeUntil} from "rxjs/operators";
import {ScreenOrientation} from "@ionic-native/screen-orientation/ngx";
import {Contact, ENVIRONMENT, FilterTypes, Logger, Platform, Topic} from "core";
import {PropertiesService} from 'properties';
import {ChannelDetailsComponent} from '../channel-details/channel-details.component';

interface Layout {
  colYs: number[];
  size: {width:number,height:number};
}
interface LayoutElement {
  layout: Layout;
}

@Component({
  selector: 'media-grid',
  templateUrl: './media-grid.component.html',
  styleUrls: ['./media-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaGridComponent extends BasicContainerComponent {

  @Input()  entities$: Observable<Media[]>;
  @Input()  cacheId$: Observable<string>;
  @Input()  selectedIndex: number = null;
  @Input()  defaultCoverHeight:number = undefined;  // default cover height in percent of width
  @Input()  forcedCoverHeight:number = undefined;   // forced cover height in percent of width
  @Input()  canDrop: (media: Media, target: Media) => boolean = () => true;
  @Input()  dragDrop = true;
  @Input()  author$: Observable<Contact>;

  @Output() selectedMediaChanged = new EventEmitter<{media: Media, index: number, ready:boolean, action?:string}>();
  @Output() mediaDrop = new EventEmitter<{media: Media, target: Media}>();
  @Output() mediaDragStart = new EventEmitter<Media>();
  @Output() mediaDragEnd = new EventEmitter<Media>();
  @Output() columns$ = new BehaviorSubject<number>(1);
  @Output() selectedTopic = new EventEmitter<Topic>();

  @ViewChild('view', {read: ElementRef}) viewElementRef: ElementRef;
  @ViewChild('grid', {read: VirtualGridComponent, static: true}) gridRef: VirtualGridComponent;

  @Input() mediaCovers: { [type: string]: TemplateRef<any> };
  @ContentChild('mediaBadges',  { static: true })  mediaBadges:  TemplateRef<any>;
  @ContentChild('mediaDetails', { static: true }) mediaDetails: TemplateRef<any>;

  public gridEntities$: Observable<Media[]>;
  protected layout$ = new BehaviorSubject<Layout>(null);
  protected trigger = {
    triggered: 0,
    entities: <Media[]>[]
  };

  dragMedia: Media;
  dragTargetMedia: Media;
  dragTargetTime: number;
  dragTargetWaitTime = 300;
  dragTargetPosition: 'before' | 'after' | 'self';
  dropTargetMedia: Media;

  showGrid$ = new BehaviorSubject(true);

  @ViewChild('dragMediaImage', { read: ElementRef }) dragMediaImage: ElementRef;

  protected logger = new Logger('MediaGridComponent').setSilent(true);

  constructor(protected elementRef: ElementRef,
              public mediaService: MediaService,
              protected platform: Platform,
              protected renderer: Renderer2,
              protected screenOrientation: ScreenOrientation,
              private propertiesService: PropertiesService,
              @Inject(NULL_MEDIA) public nullMedia: Media,
              @Inject(ENVIRONMENT) public environment: any) {
    super();
    this.logger.debug('ctor()');
    //this.contactService.update(contact);
  }

  ngOnInit() {
    //console.debug("MediaGridComponent.ngOnInit()");
    super.ngOnInit();
    this.gridEntities$ = new Observable<Media[]>(subscriber => {
      return combineLatest([this.entities$, this.author$, this.showGrid$])
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((([entities, author, showGrid]) => {
          entities = showGrid ? entities : [];
          author && entities.unshift(this.nullMedia);
          subscriber.next(entities);
        }));
    }).pipe(shareReplay(1));

    combineLatest([
      this.entities$,
      this.layout$
    ]).pipe(
        takeUntil(this.onDestroy$),
        filter(([entities, layout]) => !!entities && !!layout && !!layout.colYs),
        debounceTime(300, asyncScheduler),
      )
      .subscribe(([entities, layout]) => {
/*        let viewBounds    = this.bounds(this.viewElementRef.nativeElement);
        let masonryBounds = this.bounds(this.masonryElementRef.nativeElement);
        let triggerHeight = layout.colYs.reduce((a, b) => Math.min(a, b));
        let coveredHeight = triggerHeight+masonryBounds.top-viewBounds.scrollTop-viewBounds.height;
        if (coveredHeight<0) {
          if (this.trigger.entities!=entities) {
            this.trigger.entities  = entities;
            this.trigger.triggered = 0;
          }
          if (this.trigger.triggered<entities.length) {
            this.trigger.triggered = entities.length;
            //console.log("trigger load current",entities.length);
            if (typeof entities['next'] == 'function') {
              entities['next']();
            }
          }
        }*/
        //console.log("viewBounds",viewBounds,"masonryBounds",masonryBounds,"triggerHeight",triggerHeight,"coveredHeight",coveredHeight);
      });
    if (this.platform.is('ios')) {
      // this.screenOrientation.onChange() - does not work!!!
      // see https://github.com/apache/cordova-plugin-screen-orientation/issues/54
      fromEvent(window, 'orientationchange')
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          window.setTimeout(() => {
            // onResize() in not called on orientation change (at least) in ios cordova app.
            // safari browser on ios behaves properly.
            // react by adjusting the layout-width attribute of the grid element to force resize
            // of media items (see scss) and then trigger masonry reflow.
            this.setLayoutWidth(this.gridRef.elementRef.nativeElement.clientWidth);
          }, 100);
        });
    }
  }

  onResize(event:ResizeEvent) {
    this.setLayoutWidth(event.currentSize.width);
  }

  setLayoutWidth(width: number) {
    const columns = this.getColumns(width);
    if (this.columns$.value!=columns) {
      this.columns$.next(columns);
    }
  }

  getColumns(width?:number):number {
    width = isValidNumber(width) && width>0 ? width : this.gridRef?.elementRef?.nativeElement?.offsetWidth ?? 0;
    //console.log("WIDTH",width);
    if (width>=1600) {
      return 5;
    } else if (width>=1260) {
      return 4;
    } else if (width>=760) {
      return 3;
    } else if (width>=480) {
      return 2;
    }
    return 1;
  }

  onSelectMedia(index:number, media: Media, event: Event) {
    this.selectedIndex = index;
    // this.selectedMedia = media;
    this.selectedMediaChanged.emit({
      index: index,
      media: media,
      ready: this.mediaService.isMediaReady(media),
      action: 'selected'
    });
  }

  onPlayMedia(index:number, media: Media) {
    // event.stopPropagation();
    this.selectedMediaChanged.emit({
      index:  index,
      media:  media,
      ready:  this.mediaService.isMediaReady(media),
      action: 'play'
    });
  }

  protected safeMedia(media: Media): Media {
    return media || this.nullMedia;
  }

  trackByMedia(index: number, media: Media): string {
    //const safeMedia = this.safeMedia(media);
    // return `${safeMedia.id}.${safeMedia.version}`;
    return `${media?.id}.${media?.version}.${media?.lock?.value}.${media?.reactionSummary?.version}`;//media?.id+media?.version+;//`${safeMedia.id}.${safeMedia.version}`;
  }

  bounds(element:HTMLElement): { width:number,height:number,top:number,left:number,scrollTop:number } {
    return {
      width:element.offsetWidth,
      height:element.offsetHeight,
      top:element.offsetTop,
      left:element.offsetLeft,
      scrollTop:element.scrollTop
    };
  }

  onDragStart(event, media: Media, element: HTMLElement) {
    this.dragMedia = media;
    const dragMediaImage = this.dragMediaImage.nativeElement;
    this.logger.debug('onDragStart', {
      event,
      media,
      element,
      dragMediaImageWidth: dragMediaImage?.clientWidth,
      dragMediaImageHeight: dragMediaImage?.clientHeight
    });
    // Required by Firefox
    // (https://stackoverflow.com/questions/19055264/why-doesnt-html5-drag-and-drop-work-in-firefox)
    event.dataTransfer.setData("media", JSON.stringify(media));
    event.dataTransfer.effectAllowed = "move";
    const coverHeightPercentage = this.forcedCoverHeight ?? this.mediaService.getMediaCoverHeight(media, this.defaultCoverHeight)
    const coverHeightRatio = coverHeightPercentage / 100;
    const dragImageWidth  = this.dragMediaImage.nativeElement.clientWidth;
    const dragImageHeight = dragImageWidth * coverHeightRatio;
    this.renderer.setAttribute(dragMediaImage, 'style', `width:${dragImageWidth}px; height:${dragImageHeight}px`);
    const x = Math.min(10, dragImageWidth);
    const y = dragImageHeight - Math.max(0, Math.min(10, dragImageHeight-10));
    this.logger.debug('onDragStart.setDragImage', { dragImageWidth, dragImageHeight, x, y,  });
    event.dataTransfer.setDragImage(this.dragMediaImage.nativeElement, x, y);
    this.mediaDragStart.emit(media);
  }

  onDragOver(event, media: Media) {
    this.logger.debug('onDragOver', event, media);
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    // handle media folder expanding
    if (media === this.dragTargetMedia) {
      if (this.dragMedia !== media) {
        this.dropTargetMedia = this.canDrop(this.dragMedia, media) ? media : null;
        if ((new Date().getTime() - this.dragTargetTime) > this.dragTargetWaitTime) {
            // TODO: OPEN FOLDER?
        }
      }
    } else {
      this.dragTargetMedia = media;
      this.dragTargetTime = new Date().getTime();
      this.dropTargetMedia = null;
    }

    // calculate drag target position
    const percentageX = event.offsetX / event.target.clientWidth;
    const percentageY = event.offsetY / event.target.clientHeight;
    // if (percentageX < 0.25) {
    //   this.dragTargetPosition = 'before';
    // } else if (percentageX > 0.75) {
    //   this.dragTargetPosition = 'after';
    // } else {
      this.dragTargetPosition = 'self';
    // }
  }

  onDragEnd(event, media) {
    this.logger.debug('onDragEnd', event);
    this.mediaDragEnd.emit(media);
    this.resetDragState();
  }

  onDrop(event, media: Media) {
    this.logger.debug('onDrop', event, media);
    if (this.dragDrop) {
      event.preventDefault();
      if (media !== this.dragMedia) {
        if (this.dragTargetPosition === 'before') {
        } else if (this.dragTargetPosition === 'after') {
        } else {
          this.mediaDrop.emit({media: this.dragMedia, target: media});
        }
      }
      this.resetDragState();
    }
  }

  resetDragState() {
    this.dragMedia = null;
    this.dragTargetPosition = undefined;
    this.dragTargetMedia = null;
    this.dragTargetTime = 0;
  }


}
