import {ComponentRef, Injectable, Injector} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {ImageCropperOverlayComponent, ImageCropperOverlayRef} from "./image-cropper-overlay.component";
import {IMAGE_CROPPER_OVERLAY_DATA} from "./image-cropper-overlay.tokens";

// https://stackblitz.com/edit/custom-overlay-step-5

export interface ImageCropperOverlayConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: any;
}

const DEFAULT_CONFIG: ImageCropperOverlayConfig = {
  hasBackdrop: true,
  // backdropClass: 'backdrop',
  panelClass: 'image-cropper-overlay-panel',
  data: {}
};

@Injectable({
  providedIn: "root"
})
export class ImageCropperOverlayService {

  constructor(private injector: Injector,
              private overlay: Overlay) { }

  open(config: ImageCropperOverlayConfig = {}) {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };
    const overlayRef = this.createOverlay(dialogConfig);
    const dialogRef = new ImageCropperOverlayRef(overlayRef);
    const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);
    overlayRef.backdropClick().subscribe(() => dialogRef.close());
    return dialogRef;
  }

  private createOverlay(config: ImageCropperOverlayConfig) {
    const overlayConfig = this.createOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: ImageCropperOverlayConfig, dialogRef: ImageCropperOverlayRef) {
    const injector = this.createInjector(config, dialogRef);
    const containerPortal = new ComponentPortal(ImageCropperOverlayComponent, null, injector);
    const containerRef: ComponentRef<ImageCropperOverlayComponent> = overlayRef.attach(containerPortal);
    return containerRef.instance;
  }

  private createInjector(config: ImageCropperOverlayConfig, dialogRef: ImageCropperOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(ImageCropperOverlayRef, dialogRef);
    injectionTokens.set(IMAGE_CROPPER_OVERLAY_DATA, config.data);
    return new PortalInjector(this.injector, injectionTokens);
  }

  private createOverlayConfig(config: ImageCropperOverlayConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      disposeOnNavigation: true,
      positionStrategy
    });
    return overlayConfig;
  }
}
