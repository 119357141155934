<mat-accordion>
    <ng-container *ngFor="let section of sections; let i = index; trackBy:trackBySection">
        <mat-expansion-panel #panel [expanded]="i === expandedSection"
            [hidden]="!!sectionHidden && sectionHidden[i]" class="filter-header"
            (opened)="onTabOpen(i)"
            (closed)="onSectionHeaderClick(panel, i === expandedSection)"
            [class.headless]="section.headless"
            [class.header-selected]="i === expandedSection">
            <mat-expansion-panel-header *ngIf="!section.headless" collapsedHeight="46px" expandedHeight="46px">
                <mat-panel-title>{{section.label | translate}}<ng-container
                    *ngFor="let badge of headerBadges[i]">
                        <div class="badge" *ngIf="badge.count() > 0">{{badge.count()}}</div>
                    </ng-container>
                </mat-panel-title>
                <mat-panel-description>
                    <ng-container *ngFor="let button of headerButtons.get(section.id)">
                        <button *ngIf="!button.icon" mat-button class="header-button" (click)="button.onClick(); $event.stopPropagation();"
                        [disabled]="button.disabled()"><span translate>{{button.label}}</span></button>

                        <button *ngIf="button.icon" mat-icon-button class="header-button" (click)="button.onClick(); $event.stopPropagation();"
                        [disabled]="button.disabled()">
                            <mat-icon>{{button.icon}}</mat-icon>
                            <div class="button-badge" *ngIf="button.badgeCount && button.badgeCount() > 0">{{button.badgeCount()}}</div>
                          </button>
                    </ng-container>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <accordion-section-container [section]="section"
                (isHidden)="setHidden(i, $event)"
                (headerBadges)="headerBadges[i]=$event; this.changeDetector.markForCheck();"
                (headerButtons)="setHeaderButtons(section.id, $event)">
            </accordion-section-container>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
