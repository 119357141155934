import {Component} from '@angular/core';

@Component({
  selector: 'grip',
  template: `
    <div>
      <div>
        <div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./grip.component.scss']
})
export class GripComponent {
}
