<ng-container>
  <ng-container *ngIf="!!contact && !!channel">
    <ng-container [ngSwitch]="currentIndex">
      <div style="position: absolute; top: 0; left: 0; width: 100%;">
        <div style="height: 240px;">
        </div>
        <div class="tab-content" *ngSwitchCase="1">
          <channel-info [channel]="channel" [editMode]="editMode"></channel-info>
        </div>
      </div>
    </ng-container>

    <div class="channel-header">
      <div class="header-section">
        <media-card
                    [indicator]="false"
                    [rounded]="false"
                    [imageMaxWidth]="undefined"
                    style="padding: 0px"
                    [media]="getBackgroundMedia()"></media-card>
        <div class="header">
          <div class="header-row">
            <img matListItemAvatar
                 [src]="avatarUrl$ | async"
                 [ngClass]="platform.is('mobile') ? 'avatar-small' : 'avatar-large'"
                 style="z-index:99"
                 onload="this.style.opacity=1" />

            <div class="channel-name">
              {{contact.name}}
            </div>

            <!-- <button mat-icon-button color="primary" aria-label="Favorite"
                                (click)="subscribed = !subscribed">
                            <mat-icon *ngIf="subscribed">star</mat-icon>
                            <mat-icon *ngIf="!subscribed">star_outline</mat-icon>
                        </button> -->

            <!-- <button mat-raised-button color="primary" (click)="subscribed = !subscribed">
              {{( subscribed ? 'Abonniert' : 'Abonnieren') | translate}}
            </button> -->
            <button style="margin-left: 60px" mat-raised-button color="primary" (click)="onChannelsClick()">
              All authors (Test)
            </button>
          </div>
        </div>
      </div>

      <mat-tab-group animationDuration="0ms" *ngLet="channelTopics$ | async as channelTopics"
                     (selectedTabChange)="selectedTopic.emit(channelTopics[$event.index])"
                     [(selectedIndex)]="currentIndex">
        <mat-tab *ngFor="let topic of channelTopics">
          <ng-template mat-tab-label>
            <span translate>{{topic.label}}</span>
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span translate>channel.information.title</span>
          </ng-template>

        </mat-tab>
      </mat-tab-group>
    </div>
    <button mat-mini-fab style="position: absolute; top: 24px; right: 24px;" *ngIf="editMode"
            (click)="onUploadMedia($event, contact)">
      <mat-icon aria-label="Upload banner">add_a_photo</mat-icon>
    </button>
  </ng-container>
</ng-container>