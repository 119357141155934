
export function manualSlice(array:any[],from:number,to:number):any[] {
  //console.log("MANUAL_SLICE",array,"from",from,"to",to);
  const length = array.length;
  from = Math.max(0,Math.min(from,length-1));
  to   = Math.max(0,Math.min(to,length));
  const size   = Math.max(0,to-from);
  const slice  = new Array(size);
  for (let i=0; i<size; i++) {
    slice[i] = array[from+i];
  }
  return slice;
}

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
