import {Inject, inject, Injectable, InjectionToken} from "@angular/core";
import {Platform} from "../platform/platform.service";
import {ENVIRONMENT} from "../config";
import {secondLevelDomain} from "../utils";

@Injectable()
export class AppIdentifierProvider {
  getIdentifier(): string {
    return undefined;
  }
  getApp(): string {
    return this.getPathApp();
  }
  getPathApp(): string {
    return undefined;
  }
  getAppId(): number {
    return undefined;
  }
}

@Injectable()
export class DefaultAppIdentifierProvider extends AppIdentifierProvider {

  allowedApps = ['groupmanager', 'realizer', 'goodnews', 'bibletube', 'microfranchise', 'moneytime', 'lifechanger'];

  constructor(private platform: Platform, @Inject(ENVIRONMENT) private environment: any) {
    super();
  }

  getPathAppAndIdentifier() : { app: string, identifier: string} {
    let path  = window.location.pathname || '';
    let parts = path.split('\/').filter((part : string) => part);
    return {
            app:  parts.length>0 ? parts[0] : null,
      identifier: parts.length>1 ? parts[1] : null
    };
  }

  getPathApp(): string {
    return secondLevelDomain();
  }

  getIdentifier() : string {
    let info = this.getPathAppAndIdentifier();
    return info && this.isValid(info.app) ? info.identifier : undefined;
  }

  getApp(): string {
    let app = (this.platform.is('hybrid') || !this.environment.production) && this.environment.app;
    if (!app) {
      // let info = this.getPathApp();
      // app = info && info.app;
      app = this.getPathApp();
    }
    return this.isValid(app) ? app : undefined;
  }

  getAppId(): number {
    let app = this.getApp();
    switch (app) {
      case 'groupmanager'   : return 0;
      case 'realizer'       : return 1;
      case 'bibletube'      :
      case 'goodnews'       : return 2;
      case 'microfranchise' : return 3;
      case 'moneytime'      : return 4;
      case 'lifechanger'    : return 5;
      default: return super.getAppId();
    }
  }

  private isValid(app: string): boolean {
    return app && this.allowedApps.includes(app);
  }
}

export const APP_ID = new InjectionToken<number>('appId', {
  providedIn: 'root',
  factory: () => {
    // from https://angular.io/api/core/InjectionToken
    // "If the factory function, which takes zero arguments,
    // needs to inject dependencies, it can do so using the inject function."
    const appIdentifier = inject(AppIdentifierProvider);
    return appIdentifier.getAppId();
  }
});

export const APP_PACKAGE_ID = new InjectionToken<string>('appPackageId', {
  providedIn: 'root',
  factory: () => {
    const appIdentifier = inject(AppIdentifierProvider);
    const app = appIdentifier.getApp();
    switch (app) {
      case 'realizer'       : return 'social.realizer.app';
      case 'bibletube'      :
      case 'goodnews'       : return 'social.goodnews.app';
      case 'microfranchise' : return 'social.microfranchise.app';
      case 'lifechanger'    : return 'social.lifechanger.app';
      case 'moneytime'      : return 'social.moneytime.app';
      default               : return undefined;
    }
  }
});

export const NOTIFICATION_APP_ID = new InjectionToken<string>('notificationAppId', {
  providedIn: 'root',
  factory: () => {
    const appIdentifier = inject(AppIdentifierProvider);
    const app = appIdentifier.getApp();
    switch (app) {
      case 'realizer'       : return 'be061b3b-790c-492e-a64e-6deee3632fff'; // onesignal realizer appId
      case 'bibletube'      :
      case 'goodnews'       : return 'aa3089d6-1da1-4178-9381-1704f4f237e9'; // onesignal goodnews appId
      case 'microfranchise' : return 'a8d6fc18-07f8-45af-acfe-041efcefb8ed'; // onesignal microfranchise appId
      case 'lifechanger'    : return '0bd9797e-9ad6-4e94-8d6b-dfff112fe850'; // onesignal lifechanger onesignal app
      case 'moneytime'      : return 'e13fc57e-ec5c-4647-98ac-d6469bb1a124'; // onesignal moneytime appId
      default               : return undefined;
    }
  }
});

export const NOTIFICATION_SAFARI_WEB_ID = new InjectionToken<string>('notificationSafariWebId', {
  providedIn: 'root',
  factory: () => {
    const appIdentifier = inject(AppIdentifierProvider);
    const app = appIdentifier.getApp();
    switch (app) {
      case 'realizer'       : return 'web.onesignal.auto.41aef4b6-51a4-4778-92bc-f3aa3e26acde'; // onesignal realizer safari web id
      case 'bibletube'      :
      case 'goodnews'       : return 'web.onesignal.auto.13169687-b109-4155-bbf4-37fb1a1576f8'; // onesignal goodnews safari web id
      case 'microfranchise' : return 'web.onesignal.auto.220e9b0b-02d4-465b-a5d3-49d3d287ceee'; // onesignal microfranchise safari web id
      case 'lifechanger'    : return 'web.onesignal.auto.4d0b3421-6847-4c4b-a531-b4bcf634e4d8'; // onesignal lifechanger safari web id
      case 'moneytime'      : return 'web.onesignal.auto.0353ec9c-a08c-4477-8a0d-c11d5b7e8e59'; // onesignal moneytime safari web id
      default               : return undefined;
    }
  }
});


