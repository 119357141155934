import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Directive, ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone, OnDestroy, OnInit,
  Output, Renderer2
} from '@angular/core';
import {Timer} from "../../util/timer";
import {Subscription} from "rxjs";

/**
 * add press support to component
 */
@Directive({
  selector: '[onDrop]'
})
export class DropDirective implements OnInit, OnDestroy {
  @HostBinding('class.dropFilesOver') //x:boolean = true;
  dropFilesOver: boolean = false;
  @HostBinding('class.dropForwardingTriggered')
  dropForwardingTriggered: boolean = false;
  @HostBinding('class.dropForwardingTarget')
  dropForwardingTargetMatch: boolean = false;

  protected static dropDirectives:DropDirective[] = [];
  protected static instanceCounter:number = 0;
  protected instanceId = ++DropDirective.instanceCounter;
  protected _dropForwardingTarget:string = undefined;
  protected subscriptions = new Subscription();

  @Input() dropForwarding:{
    trigger:boolean,            // is forwarding enabled for this directive?
    timeout:number,             // the time between dragEnter and calling of callback
    callback:()=>Promise<void>, // the callback called after timeout
    target?:string              // unique target id of the drop target, so forwarding can activate drop ui there...
  } = undefined;
  @Input()                      // unique target id for forwarding to this element
  set dropForwardingTarget(target:string) {
    if (this._dropForwardingTarget!=target) {
      this._dropForwardingTarget = target;
      if (!!target) {
        DropDirective.dropDirectives?.forEach(directive=>{
          if (!!directive.dropFilesOver && directive.dropForwarding?.target==target) {
            this.dropForwardingTargetMatch = true;
            this.changeDetector.markForCheck();
          }
        });
      }
    }
  }
  get dropForwardingTarget():string {
    return this._dropForwardingTarget;
  }
  @Output() onDrop = new EventEmitter<any>();
  @Output() dragEnter = new EventEmitter<DataTransfer>();
  @Output() dragLeave = new EventEmitter<DataTransfer>();

  constructor(
    protected elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone,
    private renderer: Renderer2) {
    this.dragEnter.subscribe(transfer=>this.triggerDropForwarding());
    this.dragLeave.subscribe(transfer=>this.cancelDropForwarding());
    DropDirective.dropDirectives.push(this);
  }

  public ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.subscriptions.add(this.registerElementListener('dragstart',(event:DragEvent) => this.onDragEnter(event)));
      this.subscriptions.add(this.registerElementListener('dragenter',(event:DragEvent) => this.onDragEnter(event)));
      this.subscriptions.add(this.registerElementListener('dragover',(event:DragEvent) => this.onDragEnter(event)));
      this.subscriptions.add(this.registerElementListener('dragexit',(event:DragEvent) => this.onDragLeave(event)));
      this.subscriptions.add(this.registerElementListener('drop',(event:DragEvent) => this.ondrop(event)));
    });
  }

  protected registerElementListener(event:string,handler:(event:DragEvent|KeyboardEvent|TouchEvent|MouseEvent)=>void):()=>void {
    return this.renderer.listen(this.elementRef.nativeElement,event,(event:DragEvent|KeyboardEvent|TouchEvent|MouseEvent)=>this.zone.run(()=>handler(event)));
  }

  ngOnDestroy(): void {
    DropDirective.dropDirectives = DropDirective.dropDirectives.filter(directive=>directive!=this);
    this.subscriptions.unsubscribe();
  }

  onDragEnter(event:DragEvent) {
    //console.log("DRAG.ENTER",event);
    this.update(event,true);
  }

  onDragLeave(event:DragEvent) {
    //console.log("DRAG.LEAVE",event);
    this.update(event,false);
  }

  ondrop(event:DragEvent) {
    //console.log("DRAG.DROP",event);
    this.update(event,false);
    const files: FileList = event.dataTransfer.files;
    if (files.length > 0) {
      this.onDrop.emit(files);
    } else {
      this.onDrop.emit(event);
    }
  }

  protected timerId:number = undefined;
  protected inside:boolean = false;
  update(event:DragEvent,inside:boolean) {
    event.preventDefault();
    event.stopPropagation();
    if (inside!=this.inside) {
      if (inside) {
        //console.log("ENTER",this.current);
        const notify = !this.dropFilesOver;
        this.dropFilesOver = this.inside = true;
        this.changeDetector.markForCheck();
        if (!!this.timerId) {
          window.clearTimeout(this.timerId);
          this.timerId = undefined;
        }
        if (notify) {
          this.dragEnter.emit(event.dataTransfer);
        }
      } else {
        //console.log("LEAVE",this.current);
        this.inside = false;
        this.timerId = window.setTimeout(()=>this.zone.run(()=>{
          this.timerId = undefined;
          this.dropFilesOver = this.inside;
          this.changeDetector.markForCheck();
          this.dragLeave.emit(event.dataTransfer);
        },100));
      }
    }
  }

  protected timer:Timer = undefined;
  triggerDropForwarding() {
    console.log("TRIGGER_DROP_FORWARDING",this.instanceId);
    if (!!this.dropForwarding) {
      const update = ()=> {
        if (!!this.dropForwarding?.target) {
          const target = this.dropForwarding.target;
          DropDirective.dropDirectives.forEach(directive=>{
            console.log(console.log("dropDirective",directive.dropForwardingTarget));
            if (directive.dropForwardingTarget==target) {
              directive.dropForwardingTargetMatch = true;
              directive.changeDetector.markForCheck();
            }
          });
        }
      }
      update();
      if (this.dropForwarding?.trigger) {
        this.timer = this.timer ?? new Timer((time)=>this.zone.run(()=>{
          this.dropForwarding.callback().then(update);
        }),this.dropForwarding.timeout);
        this.timer.trigger();
        this.dropForwardingTriggered = true;
        this.changeDetector.markForCheck();
      }
    }
  }

  cancelDropForwarding() {
    console.log("CANCEL_DROP_FORWARDING",this.instanceId);
    this.timer?.cancel();
    const target = this.dropForwarding?.target;
    if (!!target) {
      DropDirective.dropDirectives?.forEach(directive=>{
        if (directive.dropForwardingTarget==target) {
          directive.dropForwardingTargetMatch = false;
          directive.changeDetector.markForCheck();
        }
      });
    }
    this.dropForwardingTriggered = false;
    this.changeDetector.markForCheck();
  }
}
