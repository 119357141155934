<div class="language-selector">
  <button *ngIf="raised && primary" mat-raised-button color="primary" [matMenuTriggerFor]="languageMenu">
    {{getLanguageLabel(currentLanguage) | translate}}
  </button>
  <button *ngIf="raised && !primary" mat-raised-button [matMenuTriggerFor]="languageMenu">
    {{getLanguageLabel(currentLanguage) | translate}}
  </button>
  <button *ngIf="!raised && primary" mat-button color="primary" [matMenuTriggerFor]="languageMenu">
    {{getLanguageLabel(currentLanguage) | translate}}
  </button>
  <button *ngIf="!raised && !primary" mat-button [matMenuTriggerFor]="languageMenu">
    {{getLanguageLabel(currentLanguage) | translate}}
  </button>
  <mat-menu #languageMenu="matMenu">
    <button mat-menu-item *ngFor="let language of languages" (click)="setLanguage(language)">
      {{getLanguageLabel(language) | translate}}
    </button>
  </mat-menu>
</div>
