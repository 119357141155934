import {
  AfterViewInit, ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import {Observable, Subscription} from "rxjs";
import {Logger} from "core";
import {Badge} from "shared";

export interface TabDefinition {
  id?:string;
  icon?:string;
  abbreviation?:string;
  type?:"tab" | "flex" | undefined;
  link?:string | (() => string);
  action?:(event?: any)=>Promise<boolean>;
  disabled$?:Observable<boolean>;
  // unseen$?:Observable<number>;
  badge$?:Observable<Badge>;
}

@Component({
  selector: 'tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['./tab-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabBarComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() tabDefinitions: TabDefinition[] = [];
  @Input() center: boolean = true;
  @Input() selected: number = undefined;
  @Input() selectable: boolean = true;

  @Output() onSelect: EventEmitter<number> = new EventEmitter<number>();

  protected previousSelected: number = undefined;
  protected selectSubscription: Subscription;

  uniqueId = Math.floor(Math.random() * 1000) + 1; // used only for debugging
  protected logger = new Logger('TabBarComponent.'+this.uniqueId);

  constructor() { }

  public ngOnDestroy(): void {
    this.selectSubscription.unsubscribe();
    this.onSelect.complete();
  }

  public ngAfterViewInit(): void {
    this.selectSubscription = this.onSelect.subscribe((index:any) => {
      // this.logger.debug('onSelected ->', index);
      this.onSelected(index);
    });
    //this.onSelect.emit(this.selected);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // this.logger.debug('ngOnChanges', { previous: this.previousSelected, selected: this.selected });
    if (this.previousSelected!=this.selected) {
      this.onSelect.emit(this.selected);
    }
  }

  protected onSelected(index : number) {
    this.selected = this.previousSelected = index;
  }
}
