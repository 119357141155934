import {Directive, Input} from '@angular/core';
import {MatMenuTrigger, MatMenuPanel} from '@angular/material/menu';

@Directive({
    selector: `[manualMenuTriggerFor]`,
    exportAs: 'manualMenuTrigger'
})
export class ManualMenuTrigger extends MatMenuTrigger {
    _handleClick(event: MouseEvent): void {}

    @Input('manualMenuTriggerFor')
    get menu() { return super.menu; }
    set menu(menu: MatMenuPanel) {
        super.menu = menu;
    }
}
