/* projects/upload/src/lib/components/image-upload/image-upload.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: block;
  min-height: 45px;
}
.image {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #ddd;
  overflow: hidden;
  position: relative;
  min-height: 60px;
}
.image input.hidden {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.image img {
  opacity: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.image .upload-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 0;
  font-size: 0;
  background-color: rgba(0, 0, 0, 0.25);
  transition: height 0.25s ease-in;
}
.image .upload-overlay span {
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.image:hover {
  cursor: pointer;
}
.image .actions {
  position: absolute;
  bottom: 5px;
  right: 0;
}
.image .actions > button {
  margin-right: 5px;
  background-color: rgba(255, 255, 255, 0.7);
}
.progress-container {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  overflow: hidden;
  opacity: 0;
  margin-top: 3px;
  background-color: #ddd;
  transition: opacity 0s ease-in, width 0s ease-in;
}
.progress-container.progress {
  opacity: 1;
}
.progress-container.progress > div:first-child {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  background-color: var(--color-accent-300);
}
/*# sourceMappingURL=image-upload.component-LL5IPTQB.css.map */
