import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CountryFilterComponent} from './components/country/country-filter/country-filter.component';
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {filterFeatureKey} from "./store/state";
import {filterReducer} from "./store/reducers";
import {FilterEffects} from "./store/effects";
import {MatButtonModule} from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {FilterTagsComponent} from './components/tags/filter-tags.component';
import {FilterTagSectionComponent} from "./components/tags/filter-tag-section.component";
import {MaterialModule} from "material";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HierarchicalFilterComponent} from './components/hierarchical-filter/hierarchical-filter.component';
import {FilterTagSectionContainer} from "./components/tags/filter-tag-section.container";
import {BasicFilterListComponent} from "./components/core/basic-filter-list.component";
import {TagFilterComponent} from './components/tag-filter/tag-filter.component';
import {TopicFilterComponent} from './components/topic-filter/topic-filter.component';
import {FilterSearchbarComponent} from './components/core/filter-searchbar.component';
import {TopicTreeComponent} from './components/topic-filter/topic-tree/topic-tree.component';
import {SharedModule} from 'shared';
import {CoreModule} from "core";
import {PropertiesModule} from "properties";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MaterialModule,
    TranslateModule,
    StoreModule.forFeature(filterFeatureKey,filterReducer),
    EffectsModule.forFeature([FilterEffects]),
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule
  ],
  declarations: [
    BasicFilterListComponent,
    CountryFilterComponent,
    FilterTagSectionContainer,
    FilterTagSectionComponent,
    FilterTagsComponent,
    HierarchicalFilterComponent,
    TagFilterComponent,
    TopicFilterComponent,
    FilterSearchbarComponent,
    TopicTreeComponent
  ],
  exports: [
    BasicFilterListComponent,
    FilterTagSectionContainer,
    FilterTagSectionComponent,
    FilterTagsComponent,
    // FilterSearchbarComponent,
    TopicTreeComponent
  ]
})
export class FilterModule { }
