import {ScrollRequestEvent, ScrollUpdateEvent, VirtualScrollHandler} from "./virtual-scroll.handler";
import {ElementRef, EventEmitter, NgZone, Renderer2} from "@angular/core";
import {DragEvent} from "../../models/touch/drag-event";
import {Direction} from "./virtual-scroll.enums";
import {ease} from "./virtual-scroll.ease";

export class TouchScrollHandler extends VirtualScrollHandler {

  protected _translatePaddingProperty = 'translateY';
  protected _displacementProperty = 'marginTop';
  protected _startProperty = 'top';
  protected _containerSizeProperty = 'height';
  protected _positionProperty = 'y';
  protected _dragDeltaProperty = 'deltaY';
  protected _transformProperty = 'transform';
  protected _transitionTimingFunctionProperty = 'transitionTimingFunction';
  protected _transitionDurationProperty = 'transitionDuration';

  protected _viewportElement:HTMLElement;
  protected _scrollContainerElement:HTMLElement;
  protected _itemContainerElement:HTMLElement;
  protected _previousScrollSize:number = 0;

  protected scrollDisplacement:number = 0;

  constructor(protected elementRef:ElementRef,protected readonly renderer: Renderer2, protected readonly zone: NgZone) {
    super(elementRef,renderer);
  }

  public attachToDragEventEmitter(dragEventEmitter:EventEmitter<DragEvent>) {
    if (!this.onDrag) {
      //console.log("setDragEventEmitter",dragEventEmitter,"this",this);
      this._viewportElement = this.elementRef.nativeElement.firstElementChild;
      this._scrollContainerElement = this.elementRef.nativeElement.firstElementChild.firstElementChild;
      this._itemContainerElement = this.elementRef.nativeElement.firstElementChild.firstElementChild.firstElementChild.firstElementChild;
      //console.log("itemContainerElement",this._itemContainerElement);
      const viewportElement = this.elementRef.nativeElement.firstElementChild;
      viewportElement.style.overflow = 'hidden';
      super.attachToDragEventEmitter(dragEventEmitter);
      if (!this.vertical) {
        this._translatePaddingProperty = 'translateX';
        this._displacementProperty = 'marginLeft';
        this._containerSizeProperty = 'width';
        this._startProperty = 'left';
        this._positionProperty = 'x';
        this._dragDeltaProperty = 'deltaX';
      }
      this.doUpdate.subscribe((event)=>this.handleUpdate(event));
      this.doScroll.subscribe((event)=>this.handleScroll(event));
      this.onDrag.subscribe((event)=>this.handleTouchDrag(event));
    }
  }

  protected handleUpdate(event:ScrollUpdateEvent) {
    //console.log("TouchScrollDirective.handleUpdate",{...event},"internal",{...this});
    const currentPosition = this.getComputedPosition();
    if (this._previousScrollSize!=event.scrollSize) {
      this._previousScrollSize = event.scrollSize;
      this.renderer.setStyle(this._scrollContainerElement, this._containerSizeProperty, `${event.scrollSize}px`);
    }
    if (event.refresh) {
      //console.log("TouchScrollDirective.handleUpdate.refresh");
      this.stop();
      this.translate(event.scrollPosition);
    } else if (this._pending && event.scrollPosition!=currentPosition) {
      this._silent = true;
      const scrollDisplacement = event.scrollPosition - (currentPosition-this.scrollDisplacement);
      //console.log("TouchScrollDirective.handleUpdate.displace",scrollDisplacement);
      this.displace(scrollDisplacement);
      requestAnimationFrame(()=>{
        this._silent = false;
      });
    } else if (event.scrollPosition!=currentPosition) {
      const scrollDisplacement = event.scrollPosition - currentPosition;
      //console.log("TouchScrollDirective.handleUpdate.direct",scrollDisplacement,this._pending ? "PENDING!!" : "");
      this.displace(scrollDisplacement);
      this.translate(event.scrollPosition);
      this.sendScrollEvent(event.scrollPosition);
    }
  }

  protected handleScroll(event:ScrollRequestEvent) {
    //console.log("TouchScrollDirective.handleScroll",event);
    this.stop();
    this.translate(event.scrollPosition$.value,event.time,ease.easeInOutSine.style,event.scrollCompletedCallback);
    this.sendScrollEvent(event.time==0 ?
      event.scrollPosition$.value :
      this.getComputedPosition(),true);
  }

  protected _dragDirection:Direction = Direction.Undefined;
  protected _lastDragDelta  = 0;
  protected _startDragDelta = 0;
  protected _startDragTime  = 0;

  protected handleTouchDrag(event:DragEvent) {
    //console.log("TouchScrollDirective.onTouchDrag",event);
    this.stop();
    this.sendScrollEvent(this.getComputedPosition());
    if (event.type=='drag') {
      //console.log("START.DRAG.touchScrollHandler");
      const dragDelta = event[this._dragDeltaProperty];
      const distance  = this._lastDragDelta - dragDelta;
      const position  = this.scrollPosition + distance;
      const minimum   = 0;
      const maximum   = Math.max(0,this.scrollSize-this.viewportSize);
      let scrollPosition = !this.bounces ?
        Math.max(0, Math.min(maximum, position)) :
        position < minimum ? this.scrollPosition + distance/3 :
          position > maximum ? this.scrollPosition + distance/3 :
            position;
      const direction:Direction = dragDelta>this._lastDragDelta ? Direction.EndToStart : dragDelta<this._lastDragDelta ? Direction.StartToEnd : this._dragDirection;
      if (direction!=Direction.Undefined) {
        if (this._dragDirection==Direction.Undefined) {
          this._dragDirection  = direction;
        } else if (this._dragDirection!=direction) {
          this._dragDirection  = direction;
          this._startDragDelta = dragDelta;
          this._startDragTime  = Date.now();
        }
      }
      /*
      const currentTime = Date.now();
      console.log("TouchScrollDirective.onTouchDrag.direction",direction,"position",position,
        "scrollPosition",scrollPosition,"maximum",maximum,
        "scrollSize",this.scrollSize,
        "totalSpeed",((Math.abs(this._startDragDelta - dragDelta)/Math.max(0.0000001,(currentTime-this._startDragTime)))));*/
      this._lastDragDelta = dragDelta;
      this.translate(scrollPosition);
      this.sendScrollEvent(scrollPosition);
    } else if (event.type=='start') {
      //console.log("START.START.touchScrollHandler");
      this._dragDirection  = Direction.Undefined;
      this._lastDragDelta  = 0;
      this._startDragDelta = 0;
      this._startDragTime  = Date.now();
    } else {
      //console.log("START.STOP.touchScrollHandler",event);
      const dragDelta = event[this._dragDeltaProperty];
      const position  = this.getComputedPosition();
      const minimum   = 0;
      const maximum   = Math.max(0,this.scrollSize-this.viewportSize);
      const time      = Date.now()-this._startDragTime;
      const delta     = dragDelta-this._startDragDelta;
      const momentum  = this.momentum(position,position+delta,time,minimum,maximum,this.viewportSize);
      //console.log("TouchScrollDirective.onTouchDrag.END",dragDelta,"time",time,"start",position+delta,"position",position,"momentum",momentum);
      this._lastDragDelta = 0;
      if (momentum.speed < 0.01 || Math.abs(position-momentum.endPosition)<10 ||
        (position<=minimum && momentum.endPosition<=minimum) ||
        (position>=maximum && momentum.endPosition>=maximum)) {
        this.resetPosition(this.bounceTime);
      } else {
        this.translate(momentum.endPosition,momentum.duration,momentum.easing);
      }
    }
  }

  protected momentum(
    currentPosition: number,
    startPosition: number,
    time: number,
    lowerMargin: number,
    upperMargin: number,
    wrapperSize: number,
    options = this
  ):{endPosition:number,duration:number,easing:string,speed:number} {
    const distance = currentPosition - startPosition;
    const speed = Math.abs(distance) / time;

    const { deceleration, swipeBounceTime, swipeTime } = options;
    const momentumData = {
      endPosition: currentPosition + (speed / deceleration) * (distance < 0 ? -1 : 1),
      duration: swipeTime,
      easing: ease.swipe.style,
      speed,
      rate: 15
    };

    if (momentumData.endPosition < lowerMargin) {
      momentumData.endPosition = wrapperSize
        ? Math.max(
          lowerMargin - wrapperSize / 4,
          lowerMargin - (wrapperSize / momentumData.rate) * speed
        )
        : lowerMargin;
      momentumData.duration = swipeBounceTime;
      //momentumData.easing   = ease.swipeBounce.style;
    } else if (momentumData.endPosition > upperMargin) {
      momentumData.endPosition = wrapperSize
        ? Math.min(
          upperMargin + wrapperSize / 4,
          upperMargin + (wrapperSize / momentumData.rate) * speed
        )
        : upperMargin;
      momentumData.duration = swipeBounceTime;
      //momentumData.easing   = ease.swipeBounce.style;
    }
    momentumData.endPosition = Math.round(momentumData.endPosition);
    return momentumData;
  }

  sendScrollEvent(scrollPosition:number,force:boolean=false):boolean {
    //console.log("sendScrollEvent",(this.scrollPosition != scrollPosition),scrollPosition);
    if (!this._silent && (force || this.scrollPosition != scrollPosition)) {
      this.scrollPosition = scrollPosition;
      this.onScroll.emit({
        vertical:this.vertical,
        viewportSize:this.viewportSize,
        scrollSize:this.scrollSize,
        scrollPosition:this.scrollPosition
      });
      return true;
    }
    return false;
  }

  protected _pending: boolean;
  protected _silent: boolean;
  protected _timer: number;
  protected _endTime: number;

  startProbe(scrollCompletedCallback:()=>void=undefined) {
    const probe = () => {
      const scrollPosition = this.getComputedPosition();
      const scrollPositionChanged = this.sendScrollEvent(scrollPosition);
      if (!scrollPositionChanged && this._endTime<Date.now()) {
        this.setPending(false);
        this.resetPosition();
      }
      if (this._pending) {
        this._timer = requestAnimationFrame(probe)
      } else {
        if (this.scrollDisplacement!=0) {
          this.displace(0);
          this.translate(scrollPosition);
        }
        scrollCompletedCallback?.();
      }
    };
    cancelAnimationFrame(this._timer);
    this._timer = requestAnimationFrame(probe);
  }

  afterRender() {
  }

  resetPosition(bounceTime:number = this.bounceTime) {
    const startPosition = this.getComputedPosition();
    const endPosition = Math.max(0, Math.min(startPosition, Math.max(0,this.scrollSize-this.viewportSize)));
    if (startPosition!=endPosition) {
      //console.log("resetPosition.bounceTime",bounceTime,"startPosition",startPosition,"endPosition",endPosition);
      stop();
      requestAnimationFrame(()=>this.translate(endPosition,bounceTime,ease.bounce.style));
    }
  }

  getComputedPosition(): number {
    const cssStyle = window.getComputedStyle(
      this._scrollContainerElement,
      null
    ) as CSSStyleDeclaration;
    const matrix = cssStyle[this._transformProperty].split(')')[0].split(', ');
    const x = -(matrix[12] || matrix[4]); // - (minus) as we internally use opposite direction than translate
    const y = -(matrix[13] || matrix[5]);
    //return this.vertical ? y : x;
    return this.scrollDisplacement + (this.vertical ? y : x);
  }

  stop() {
    // still in transition
    if (this._pending) {
      this.setPending(false);
      cancelAnimationFrame(this._timer);
    }
    if (this.scrollDisplacement!=0) {
      const scrollPosition = this.getComputedPosition();
      this.displace(0);
      this.translate(scrollPosition);
    }
  }

  setPending(pending: boolean) {
    this._pending = pending
    this._silent  = false;
    if (!pending) {
      this.clearTranslateProperties();
    }
  }

  displace(scrollDisplacement: number) {
    if (this.scrollDisplacement!=scrollDisplacement) {
      //console.log("TouchScrollDirective.displace",scrollDisplacement,"from",this.scrollDisplacement);
      this.scrollDisplacement = scrollDisplacement;
      //this.renderer.setStyle(this._itemContainerElement, 'transform', `${this._translatePaddingProperty}(${-scrollDisplacement}px)`);
      this.renderer.setStyle(this._itemContainerElement, this._displacementProperty, `${-scrollDisplacement}px`);
    }
  }

  translate(scrollPosition: number, time: number = 0, easing?: string, scrollCompletedCallback:()=>void=undefined) {
    if (!this._pending) {
      //console.log("translate",scrollPosition,"time",time);
      //console.log("translate",scrollPosition,"time",time,"easing",easing);
      this.displace(0);
      this.renderer.setStyle(this._scrollContainerElement, 'transform', `${this._translatePaddingProperty}(${-scrollPosition}px)`);
      if (easing) {
        this.renderer.setStyle(this._scrollContainerElement, this._transitionDurationProperty, time + 'ms');
        this.renderer.setStyle(this._scrollContainerElement, this._transitionTimingFunctionProperty, easing);
      } else {
        this.renderer.removeStyle(this._scrollContainerElement, this._transitionDurationProperty);
        this.renderer.removeStyle(this._scrollContainerElement, this._transitionTimingFunctionProperty);
      }
      if (time && easing) {
        const startPosition = this.getComputedPosition();
        if (startPosition!=scrollPosition) {
          this._endTime        = Date.now()+time;
          this.setPending(true);
          this.startProbe(scrollCompletedCallback);
        } else {
          scrollCompletedCallback?.();
        }
      } else {
        scrollCompletedCallback?.();
      }
    }
  }

  clearTranslateProperties() {
    this._endTime = undefined;
  }
}
