/* projects/media/src/lib/components/media-grid/media-grid.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
}
.media-grid-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}
.media-grid-container > div > div {
  padding: 8px;
  padding-bottom: 80px;
}
virtual-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
channel-details {
  display: block;
  width: 100% !important;
}
.save-button {
  position: absolute;
  bottom: 16px;
  right: 80px;
}
.edit-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.media-tile {
  padding: 4px;
}
.media-tile.drop-self {
  border: 2px solid var(--color-accent);
  border-radius: 4px;
}
.media-tile > div {
  border: 2px solid transparent;
  border-radius: 10px;
}
.media-tile > div.selected {
  border-color: var(--color-primary);
}
.media-tile > div > .media {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 8px;
  background-color: white;
  background-size: cover;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.media-tile > div > .media:hover {
  box-shadow:
    0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.media-tile > div > .media > .media-card-container {
  position: relative;
}
.media-tile > div > .media > .media-card-container > media-card:not(.cardOnly) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}
.media-tile > div > .media > .media-card-container > media-card.cardOnly {
  border-radius: 8px;
  overflow: hidden;
}
.media-tile > div > .media > .details-container {
  overflow: hidden;
}
.media-tile > div > .media > .details-container:not(:empty):last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.media-tile > div > .media > .details-container:empty + .media-card-container > media-card {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.drag-image {
  position: absolute;
  left: -100%;
  z-index: -100;
  width: 150px;
}
.drag-image img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
:host-context(.color-scheme-dark) .media-tile > div > .media {
  background-color: #333;
  color: #ddd;
}
/*# sourceMappingURL=media-grid.component-RR7MEOHJ.css.map */
