import {Component, Input} from '@angular/core';
import {BasicContainerComponent} from "../basic-container/basic-container.component";
import {TranslateService} from "@ngx-translate/core";
import {EMPTY_ARRAY} from "core";

@Component({
  selector: 'badge-container',
  templateUrl: './badge.container.component.html',
  styleUrls: ['./badge.container.component.scss']
})
export class BadgeContainerComponent extends BasicContainerComponent {

  protected
  @Input() tags:string[] = EMPTY_ARRAY;

  constructor(protected translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
}
