import {APP_INITIALIZER, NgModule} from '@angular/core';
import {SessionTokenService} from "./session-token.service";
import {APP_ID, DeviceService} from "core";
import {SessionInterceptor} from "./session.interceptor";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {SessionLinkPipe} from "./session-link.pipe";

export function sessionInitializer(sessionTokenService: SessionTokenService, appId: string, deviceService: DeviceService) {
  return (): Promise<string> => {
    return deviceService.device().then(device => {
      const base64 = (str: string) => {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
          function bytes(match, p1) {
            return String.fromCharCode(parseInt('0x' + p1));
          })
        );
      };
      const token = base64(`${device.id}#${appId}`);
      sessionTokenService.token = token;
      return token;
    });
  }
}

@NgModule({
  declarations: [SessionLinkPipe],
  exports: [SessionLinkPipe],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: sessionInitializer,
      deps: [SessionTokenService, APP_ID, DeviceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true }
  ]
})
export class SessionModule {}
