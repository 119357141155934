import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Group } from 'properties';
import { asyncScheduler, debounceTime, mergeMap, of, take, takeUntil, throwError } from 'rxjs';
import { ConfigurationGroup } from './group-configuration-page.component';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  getTemplates(): ConfigurationGroup[] {
    const small: ConfigurationGroup = {
      id: "small",
      label: "Nur Medien",
      name: 'small',
      language: '',
      viewAs: {
        accounts: []
      },
      filters: [],
      interests: [],
      topics: [
        {
          "id": "project",
          "label": "module.project",
          "abbreviation": "module.tab.project",
          "icon": "ondemand_video",
          "topics": [
            {
              "id": "intro",
              "label": "media.filters.project.intro",
              "filter": "project.intro"
            },
            {
              "id": "testimonials",
              "label": "media.filters.project.testimonials",
              "filter": "project.testimonials",
              // "visible": "known()",
              // "enabled": "known()",
              // "enabled.removed": "known() and tag(interest.testimonials)"
            },
            {
              "id": "topics",
              "label": "media.filters.project.topics",
              "filter": "project.topics",
              // "visible": "known()",
              // "enabled": "known()",
              // "enabled.removed": "known() and tag(interest.bible,interest.history,interest.topics,interest.science)"
            }
          ],
          "defaults": [
            "project",
            "project.intro"
          ]
        },
        {
          "id": "settings",
          "label": "module.settings",
          "abbreviation": "module.tab.settings",
          "icon": "settings",
          "topics": [
            {
              "id": "profile",
              "label": "settings.filters.profile",
              "filter": "profile"
            },
            {
              "id": "preferences",
              "label": "settings.filters.preferences",
              "filter": "preferences"
            },
            {
              "id": "security",
              "label": "settings.filters.security",
              "filter": "security",
            },
            {
              "id": "sessions",
              "label": "settings.filters.sessions",
              "filter": "sessions",
            },
            {
              "id": "media",
              "label": "settings.filters.media",
              "filter": "media",
            },
            {
              "id": "interests",
              "label": "settings.filters.interests",
              "filter": "interests"
            },
            {
              "id": "translation",
              "label": "settings.filters.translation",
              "filter": "translation",
            },
            {
              "id": "app",
              "label": "settings.filters.app",
              "filter": "app",
            }
          ],
          "defaults": [
            "profile"
          ]
        }
      ]
    }
    const medium: ConfigurationGroup = {
      id: "m",
      label: "Medien mit Chat",
      name: 'm',
      language: '',
      viewAs: {
        accounts: []
      },
      filters: [],
      interests: [],
      topics: [
        {
          "id": "project",
          "label": "module.project",
          "abbreviation": "module.tab.project",
          "icon": "ondemand_video",
          "topics": [
            {
              "id": "intro",
              "label": "media.filters.project.intro",
              "filter": "project.intro"
            },
            {
              "id": "testimonials",
              "label": "media.filters.project.testimonials",
              "filter": "project.testimonials",
              // "visible": "known()",
              // "enabled": "known()",
              // "enabled.removed": "known() and tag(interest.testimonials)"
            },
            {
              "id": "topics",
              "label": "media.filters.project.topics",
              "filter": "project.topics",
              // "visible": "known()",
              // "enabled": "known()",
              // "enabled.removed": "known() and tag(interest.bible,interest.history,interest.topics,interest.science)"
            }
          ],
          "defaults": [
            "project",
            "project.intro"
          ]
        },
        {
          "id": "training",
          "label": "module.training",
          "abbreviation": "module.tab.training",
          "icon": "video_library",
          "topics": [
            {
              "id": "current",
              "label": "media.filters.training.current",
              "filter": "training.current",
            },
            {
              "id": "video",
              "label": "media.filters.training.video",
              "filter": "training.video",
            },
            {
              "id": "audio",
              "label": "media.filters.training.audio",
              "filter": "training.audio",
            },
            {
              "id": "passages",
              "label": "media.filters.training.passages",
              "filter": "training.passages",
            },
            {
              "id": "topics",
              "label": "media.filters.training.topics",
            }
          ],
          "defaults": [
            "training",
            "training.current"
          ]
        },
        {
          "id": "team",
          "label": "module.team",
          "abbreviation": "module.tab.team",
          "icon": "group_add",
          "topics": [
            {
              "id": "hosts",
              "label": "team.filters.hosts",
              "filter": "team.hosts"
            },
            {
              "id": "invitations",
              "label": "team.filters.invitations",
              "filter": "team.invitations"
            },
            {
              "tags": [
                "can_view_domains",
                "can_edit_domains",
                "can_view_interests"
              ],
              "id": "guests",
              "label": "team.filters.guests",
              "filter": "team.guests"
            },
            {
              "id": "connected",
              "label": "team.filters.connected",
              "filter": "team.connected"
            }
          ],
          "defaults": [
            "team.hosts"
          ]
        },
        {
          "id": "chat",
          "label": "module.chat",
          "abbreviation": "module.tab.chat",
          "icon": "chat",
        },
        {
          "id": "events",
          "label": "module.events",
          "abbreviation": "module.tab.events",
          "icon": "location_on",
          "topics": [
            {
              "id": "all",
              "label": "events.filters.all",
              "filter": "all"
            },
            {
              "id": "mine",
              "label": "events.filters.mine",
              "filter": "mine"
            }
          ],
          "defaults": [
            "events",
            "all"
          ]
        },
        {
          "id": "settings",
          "label": "module.settings",
          "abbreviation": "module.tab.settings",
          "icon": "settings",
          "topics": [
            {
              "id": "profile",
              "label": "settings.filters.profile",
              "filter": "profile"
            },
            {
              "id": "preferences",
              "label": "settings.filters.preferences",
              "filter": "preferences"
            },
            {
              "id": "security",
              "label": "settings.filters.security",
              "filter": "security",
            },
            {
              "id": "sessions",
              "label": "settings.filters.sessions",
              "filter": "sessions",
            },
            {
              "id": "media",
              "label": "settings.filters.media",
              "filter": "media",
            },
            {
              "id": "interests",
              "label": "settings.filters.interests",
              "filter": "interests"
            },
            {
              "id": "translation",
              "label": "settings.filters.translation",
              "filter": "translation",
            },
            {
              "id": "app",
              "label": "settings.filters.app",
              "filter": "app",
            }
          ],
          "defaults": [
            "profile"
          ]
        }
      ]
    }
    return [small, medium];
  }

}
