import {Component, HostBinding, Input} from '@angular/core';
import {Media} from "../../store/models";

@Component({
  selector: 'media-icon',
  templateUrl: './media-icon.component.html',
  styleUrls: ['./media-icon.component.scss']
})
export class MediaIconComponent {
  @Input() media: Media;
  @Input() playable = false;
  @Input()@HostBinding('style.--size') size: string = '32px';
  @Input()@HostBinding('style.--backgroundColor') backgroundColor: string;
}
