/* projects/filter/src/lib/components/core/basic-filter-list.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}
.separator {
  margin-left: -16px;
  width: 300px;
  height: 1px;
  background-color: #ddd;
}
.search-container {
  flex: 0;
  box-sizing: border-box;
  font-size: 14.4px;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.search-container input {
  font-size: 14.4px;
  padding-right: 35px;
}
.search-container .clear-icon {
  position: absolute;
  left: 235px;
}
.search-container .mat-mdc-checkbox {
  padding: 0 16px;
}
.search-field {
  width: 100%;
  padding: 12px 12px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 1px;
}
.search-field:focus {
  border: 2px solid var(--color-primary);
  outline: none;
  margin: 0;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mat-mdc-list-base[dense] {
  padding-top: 0;
  flex: 1;
  display: flex;
}
virtual-section-scroller {
  flex: 1;
}
:host-context(.color-scheme-dark) .search-field {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 1px;
  background: transparent;
}
:host-context(.color-scheme-dark) .separator {
  background-color: rgba(255, 255, 255, 0.1882352941);
}
/*# sourceMappingURL=basic-filter-list.component-TS5PRC3T.css.map */
