import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {map, takeUntil} from "rxjs/operators";
import {BasicContainerComponent} from "../../basic-container/basic-container.component";
import {Logger} from "core";
import {getLangDir, isRtlLang} from "rtl-detect";
import {VirtualScrollerComponent} from "../../virtual-scroller/virtual-scroller";

// TODO move to shared location and use also in translations
export type Language = { code: string, name: string };

@Component({
  selector: 'app-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageListComponent extends BasicContainerComponent {

  // @Input() languages$: Observable<Language[]>;
  @Input()
  set languages$(languages: Observable<Language[]>) {
    this.languagesSubscription?.unsubscribe();
    this.languagesSubscription = languages
      ?.pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        this._languages$.next(value);
        this.logger.debug('UPDATE LANGUAGES', value);
        this.lru = this._lru; // update
      });
  }

  @Input()
  set lru(lru: string[]) {
    this._lruLanguages$.next(this.transformLru(this._lru = lru, this._languages$.getValue()));
    this.itemsId$.next((this.itemsIdUid++).toString());
  }

  @Output() selectionChange = new EventEmitter<{index: number, language: Language}>();
  @ContentChild(TemplateRef, { static: true }) controlsTemplate: TemplateRef<any>;

  protected _languages$ = new BehaviorSubject<Language[]>([]);
  protected _lruLanguages$ = new BehaviorSubject<Language[]>([]);
  protected languagesSubscription: Subscription;
  protected _allLanguages$: Observable<Language[]>;
  protected _lru: string[];
  itemsId$ = new BehaviorSubject<string>('');
  protected itemsIdUid = 0;

  @ViewChild('scroller', {static: true}) scroller: VirtualScrollerComponent;

  trackLanguage = (index: number, language: Language) => {
    return language.code;
  };

  protected logger = new Logger('LanguageListComponent').setSilent(true);

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this._allLanguages$ = combineLatest([this.lruLanguages$, this.languages$]).pipe(
      map(([lru, languages]) => [].concat(lru, languages))
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.languagesSubscription?.unsubscribe();
  }

  onSelectionChange(index: number, language: Language, event: Event) {
    this.logger.debug('SELECT', index, language);
    this.selectionChange.emit({ index: index, language: language });
  }

  get languages$(): Observable<Language[]> {
    return this._languages$.asObservable();
  }

  get lruLanguages$() {
    return this._lruLanguages$.asObservable();
  }

  get allLanguages$(): Observable<Language[]> {
    return this._allLanguages$;
  }

  scrollToPosition(index: number) {
    this.scroller?.scrollToPosition(index, 0);
  }

  transformLru(lru: string[], languages: Language[]): Language[] {
    const lruLanguages = (lru || []).reduce((result: Language[], lru: string) => {
      const language = languages.find(language => language.code == lru);
      if (language) {
        result.push(language);
      }
      return result;
    }, [] as Language[]);
    this.logger.debug('transformLru', lruLanguages);
    return lruLanguages;
  }

  context(language: Language, index: number): { isLru: boolean, sectionEnd: boolean} {
    const lruCount = this._lruLanguages$?.value?.length || 0;
    return { isLru: index < lruCount, sectionEnd: index==lruCount-1 };
  }

  isRtl(language:string): boolean {
    return isRtlLang(language);
  }

  getDir(language:string): string {
    return getLangDir(language);
  }
}
