<cdk-virtual-scroll-viewport itemSize="66" autosize>
  <mat-list dense class="mat-accent">
    <ng-container *cdkVirtualFor="let media of (entities$ | async); trackBy: trackByMedia;
                                  let index = index;
                                  let first = first;
                                  let last = last;
                                  let even = even;
                                  let odd = odd;">
      <ng-container *ngTemplateOutlet="mediaTemplate; context: { media: safeMedia(media), index: index }"></ng-container>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
  </mat-list>
</cdk-virtual-scroll-viewport>

<ng-template #mediaTemplate let-media="media" let-index="index">
  <div>
    <div class="media" [class.selected]="isMediaSelected(media, index)" (click)="onSelectedMedia(media)" matRipple>
      <media-card [media]="media" (play)="onPlayMedia(media, index, $event)" [showDuration]="true"></media-card>
      <div class="info">
        <div>
          <span>{{media.timeProduced | date:'ll'}}</span>
          <span *ngIf="media.author?.name || media.published?.contact?.name; let author">{{author}}</span>
        </div>
<!--        <div>{{media.name}}</div>-->
        <ng-container *ngTemplateOutlet="mediaNameTemplate, context: { media }"></ng-container>
      </div>
      <button *ngIf="!!details.childElementCount"
              mat-icon-button
              mat-mdc-icon-micro-button
              tabindex="-1"
              (click)="onMediaDetails(media, index, details, $event)">
        <mat-icon>{{details==expandedDetailsElement ? 'expand_less' : 'expand_more'}}</mat-icon>
      </button>
    </div>
    <div #details class="details">
          <span *ngIf="mediaService.getMediaRating(media); let rating;">
            <span translate>rating.summary</span>
            <rating-stars [rating]="rating" [small]="true"></rating-stars>
          </span>
      <span>
            <span translate>media.name</span>
<!--            <span>{{': '+media.name}}</span>-->
            <span> : <ng-container *ngTemplateOutlet="mediaNameTemplate, context: { media }"></ng-container></span>
          </span>
      <ng-container *ngIf="media.author as author">
        <ng-container *ngIf="user$ | async as user">
          <span *ngIf="user.isVerifiedMember || user.isAdmin"
                class="section header"
                [innerHTML]="'media.source' | translate:{
                  author: author.name,
                  source: ('structure.'+author.source) | translate,
                  root: media.author.root
                } | safeHtml">
          </span>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
