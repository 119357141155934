import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'parameter' })
export class ParameterPipe implements PipeTransform {
  transform(text: string, parameters: { [key:string]: string }) {
    let regex = /\{\{(\w+)\}\}/g;
    return (text||'').replace(regex, function(match, name) {
      return parameters?.[name] ?? '';
    });
  }
}
