import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Inject,
  INJECTOR,
  Injector,
  Input, Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { BasicContainerComponent } from '../basic-container/basic-container.component';
import { LayoutService } from 'layout';
import { isEqual } from 'lodash';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AccordionSectionContainerComponent } from '../accordion-section-container/accordion-section-container.component';
import {Logger, Topic} from "core";

export interface AccordionSectionContext {
  topics?:Topic[];         // 1+ topics
  search?:boolean;        // default: false
  multiselect?:boolean;   // default: false
}

export interface AccordionSectionDefinition {
  id: string;
  type: string;
  label?: string;
  headless?: boolean;       // no header!
  context: AccordionSectionContext;
  factoryService: AccordionSectionFactoryService;
}
export interface AccordionSectionHeaderBadgeDefinition {
  count: any;
}

export interface AccordionSectionHeaderButtonDefinition {
  label?: string;
  icon?: string;
  badgeCount?: any;
  onClick: any;
  disabled: any;
}
export interface AccordionSectionFactoryService {
  getViewerFactory(type: string, context: any): AccordionSectionComponentFactory;
}

export interface AccordionSectionComponent {
  context: any;
  height?: string;
  onOpen?(): any;
  changed?: EventEmitter<any>;
  isHidden?: EventEmitter<boolean>;
  // headerBadges?: EventEmitter<AccordionSectionHeaderBadgeDefinition[]>;
  headerButtons?: EventEmitter<AccordionSectionHeaderButtonDefinition[]>;
}

export interface AccordionSectionComponentFactory {
  canHandle(context: any): boolean;
  createComponent(injector: Injector, resolver: ComponentFactoryResolver): ComponentRef<AccordionSectionComponent>;
}

@Component({
  selector: 'accordion-container',
  templateUrl: './accordion-container.component.html',
  styleUrls: ['./accordion-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionContainerComponent extends BasicContainerComponent {

  @ViewChildren(AccordionSectionContainerComponent) contentQueryList: QueryList<AccordionSectionContainerComponent>;
  @ViewChild('actions', { static: true }) actions: TemplateRef<any>;
  @ViewChild('buttons', { static: true }) buttons: TemplateRef<any>;

  protected _sectionMap: Map<string, AccordionSectionDefinition>;
  protected _sections:AccordionSectionDefinition[] = [];
  protected _startGap: number = 0;
  protected _endGap: number = 0;

  @Output() onSectionExpanded = new EventEmitter<{index: number, section: AccordionSectionDefinition}>();

  sectionHidden: boolean[];
  @Output() expandedSection = 0;
  @Output() expandedSectionId:string = undefined;

  headerBadges: AccordionSectionHeaderBadgeDefinition[][] = [];
  headerButtons: Map<string, AccordionSectionHeaderButtonDefinition[]> = new Map();

  protected logger = new Logger('AccordionContainerComponent');

  constructor(
    public layoutService: LayoutService,
    public resolver: ComponentFactoryResolver,
    @Inject(INJECTOR) public injector: Injector,
    private changeDetector: ChangeDetectorRef) {
    super();
    //console.log("ACCORDION.ctor",this.instanceId);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // we have to trigger onTabOpen as it is fired too early in ui, when contentQueryList
    // still not filled...
    this.contentQueryList.changes
      .subscribe(data=>{
        this.onTabOpen(this.expandedSection);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.target) {
      // Trigger update of target since user might have navigated to other tabs
      // in the meantime
      this.target = changes.target.currentValue;
      window.setTimeout(() => this.target = undefined);
    }
  }

  @Input()
  public set sections(sections: AccordionSectionDefinition[]) {
    // this.expandedSection = 0;
    if (!isEqual(this.sections, sections)) {
      let onlyContentChanged: boolean = false;
      let changedSections: number[] = [];
      if (sections && sections.length == this.sections?.length) {
        // Check if only part of one section got updated
        onlyContentChanged = true;
        let index = 0;
        for (let section of this.sections) {
          if (section.type !== sections[index].type || section.label !== sections[index].label) {
            onlyContentChanged = false;
          }
          if (!isEqual(section.context, sections[index].context)) {
            changedSections.push(index);
          }
          index++;
        }
      }
      if (onlyContentChanged && this.contentQueryList.length === this.sections.length) {
        //console.log("ACCORDION.SECTIONS.1",this.sections,this.expandedSectionId);
        changedSections.forEach(index => {
          this.contentQueryList.get(index).section = sections[index];
        });
      } else {
        // this.headerBadges = Array.from({ length: sections.length }, () => []);
        const newButtons: Map<string, AccordionSectionHeaderButtonDefinition[]> = new Map();
        sections.forEach(section => newButtons.set(section.id, []));
        for(let entry of this.headerButtons.entries()) {
          if(newButtons.has(entry[0])) {
            newButtons.set(entry[0], entry[1]);
          }
        }
        const previous = this.expandedSection;
        const previousId = this.expandedSectionId;
        this.headerButtons = newButtons;
        this._sectionMap = new Map();
        this._sections = [];
        sections.forEach(section => {
          this._sections.push(section);
          this._sectionMap.set(section.id, section);
        });
        //console.log("ACCORDION.SECTIONS.2",this.sections,this.expandedSectionId);
        const index = this.getSectionIndex(this.expandedSectionId);
        this.expandedSection   = index>=0 && index<=this.sections.length ? index : 0;
        this.expandedSectionId = this.expandedSection<this.sections.length ? this.sections[this.expandedSection].id : undefined;
        if (previous!=this.expandedSection || previousId!=this.expandedSectionId) {
          this.onSectionExpanded.emit({index:this.expandedSection,section:!!this.expandedSectionId?this.sections[this.expandedSection]:undefined})
        }
      }
    }
    this.sectionHidden = Array.from({ length: sections.length }, () => false);
  }

  public getSectionIndex(id:string):number {
    return this.sections.findIndex(section=>section.id==id);
  }

  public get sections(): AccordionSectionDefinition[] {
    return this._sections;
  }

  @Input()
  set startGap(gap: number) {
    if (this._startGap !== gap) {
      this._startGap = gap;
    }
  }

  get startGap() {
    return this._startGap;
  }

  @Input()
  set endGap(gap: number) {
    if (this._endGap !== gap) {
      this._endGap = gap;
    }
  }

  get endGap() {
    return this._endGap;
  }

  setExpandedSection(index: number) {
    if (index < 0 || this.sectionHidden[index]) {
      index = Math.max(0,this.sectionHidden.findIndex(h=>!h));
    }
    const previous = this.expandedSection;
    const previousId = this.expandedSectionId;
    this.expandedSection   = index>=0 && index<=this.sections.length ? index : 0;
    this.expandedSectionId = this.expandedSection<this.sections.length ? this.sections[this.expandedSection].id : undefined;
    if (previous!=this.expandedSection || previousId!=this.expandedSectionId) {
      this.onSectionExpanded.emit({index:this.expandedSection,section:!!this.expandedSectionId?this.sections[this.expandedSection]:undefined})
    }
    this.changeDetector.markForCheck();
  }

  @Input()
  set target(target: string) {
    if (!!target) {
      this.setExpandedSection(this.sections.findIndex(section=>section.id==target));
    }
  }

  onSectionHeaderClick(panel: MatExpansionPanel, currentStep: boolean) {
    if (currentStep) {
      panel.toggle();
      return false;
    }
    return true;
  }

  onTabOpen(index: number) {
    if (this.contentQueryList && this.contentQueryList.length > index) {
      // Not initialized the first time this is called
      this.contentQueryList.get(index).onOpen();
    }
    this.setExpandedSection(index);
  }

  setHidden(index: number, hidden: boolean): void {
    if (this.sectionHidden[index] != hidden) {
      this.sectionHidden[index] = hidden;
      // Ensure that a visible section is expanded
      this.setExpandedSection(this.expandedSection);
    }
  }

  setHeaderButtons(id: string, buttons: AccordionSectionHeaderButtonDefinition[]) {
    this.headerButtons.set(id, buttons);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  trackBySection(index: number, section: AccordionSectionDefinition) {
    const topicsToString:(topics:Topic[])=>string = (topics:Topic[]) => {
      return topics?.map(topic=>topic.topics?.length>0 ? topic.id+'.'+topicsToString(topic.topics) : topic.id).join(':') ?? '';
    }
    const track = section.id+'.'+topicsToString(section.context?.topics);
    //console.log("UPDATE2.track",track,section);
    return track;
  }
}
