@charset "UTF-8";

/* projects/media/src/lib/components/media-selection-list/media-selection-list.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: block;
  position: relative;
}
cdk-virtual-scroll-viewport {
  position: absolute !important;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
cdk-virtual-scroll-viewport ::ng-deep .cdk-virtual-scroll-content-wrapper {
  width: 100%;
}
.media {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: auto;
}
.media media-card {
  width: 110px;
  font-size: 0.5em;
}
.media .info {
  flex: 1;
  line-height: 1.2;
  font-size: 0.8em;
  color: #424242;
  padding: 5px 0;
  margin-right: 5px;
  overflow: hidden;
  cursor: pointer;
}
.media .info > div {
  position: relative;
}
.media .info > div:first-child {
  white-space: nowrap;
  font-size: 85%;
  color: #757575;
  margin-bottom: 4px;
}
.media .info > div:first-child > span:not(:last-of-type):after {
  content: "\2022";
  margin: 0 2px;
}
.media .info > div:last-child {
  max-height: 35px;
}
.media .info > div::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 1em;
  background:
    linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      white);
  pointer-events: none;
}
.media > button.mat-mdc-icon-button {
  align-self: center;
}
.details {
  height: auto;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  overflow: auto;
  padding: 0 6px;
}
.details > span {
  display: block;
  font-size: 13.5px;
  color: #757575;
}
.details rating-stars {
  margin-left: 4px;
}
:host-context(.color-scheme-dark) .media .info {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) .media .info > div:first-child {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) .media .info > div::after {
  background:
    linear-gradient(
      to right,
      rgba(24, 24, 24, 0),
      #181818);
}
:host-context(.color-scheme-dark) .details > span {
  color: #757575;
}
/*# sourceMappingURL=media-selection-list.component-YP5UNV6W.css.map */
