import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter, HostBinding,
  Inject,
  Input,
  Output,
  QueryList,
  Renderer2, signal, TemplateRef,
  ViewChild,
} from '@angular/core';
import {ENVIRONMENT, Logger} from "core";
import {MediaService} from "../../service/media.service";
import {getMediaDurationString, Media} from "../../store/models";
import {take, takeUntil} from "rxjs/operators";
import {BasicContainerComponent, DragEvent, MessageBoxComponent} from "shared";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {ResizeSensor} from "css-element-queries";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

export declare type side = 'top' | 'right' | 'bottom' | 'left';

@Directive({
  selector: '[mediaCardElement]'
})
export class MediaCardElement  {
  @Input('position') position?: [side, side];
  constructor(public element: ElementRef) {
  }
}

@Component({
  selector: 'media-card',
  templateUrl: './media-card.component.html',
  styleUrls: ['./media-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaCardComponent extends BasicContainerComponent {

  @Input()
  set media(media: Partial<Media>) {
    this._media = media;
    this.locked = this.media?.lock?.value ?? false;
  }

  get media() {
    return this._media;
  }
  @Input() cover: TemplateRef<any>;
  @Input() imageMaxWidth = 640;
  @Input() showDuration = false;
  @Input() indicator = true;
  @Input() defaultCover = false;
  @Input() defaultCoverHeight:number = undefined;  // default cover height in percent of width
  @Input() forcedCoverHeight:number = undefined;   // forced cover height in percent of width
  @Input() fullCover:boolean = false;              // if true, defaultCoverHeight and forcedCoverHeight are ignored
  @Input() @HostBinding('class.elevated')   elevated   = true;
  @Input() @HostBinding('class.rounded')    rounded    = true;
  @Input() @HostBinding('class.selectable') selectable = true; // reserves space for selection by rendering a transparent 2px border
  @Input() @HostBinding('class.selected')   selected   = false;
  @Input() @HostBinding('class.invisible')  invisible  = false;
  @Input() backgroundColor = 'transparent';
  @Output() play = new EventEmitter();
  @Output() onCoverLoaded: EventEmitter<Partial<Media>> = new EventEmitter<Partial<Media>>();

  @ContentChildren(MediaCardElement) mediaCardElements: QueryList<MediaCardElement>;

  @ViewChild('spinner') spinner: MatProgressSpinner;
  spinnerDiameter = signal(40);

  locked: boolean;

  protected _media: Partial<Media>;
  protected static loadedUrls = new Map<string,{ src:string, loaded:boolean, transitioned:boolean, maxWidth:number, fallback:boolean }>();
  protected sensor: ResizeSensor;
  protected logger = new Logger('MediaCardComponent')

  constructor(public mediaService: MediaService,
              protected renderer: Renderer2,
              protected elementRef: ElementRef,
              // protected changeDetector: ChangeDetectorRef,
              protected dialog: MatDialog,
              protected translateService: TranslateService,
              @Inject(ENVIRONMENT) public environment: any) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.sensor = new ResizeSensor(this.elementRef.nativeElement, (size) => {
      // console.debug('SIZE', size);
      if (this.spinner) {
        const diameter = Math.floor(Math.min(size.width, size.height) * 0.4);
        // console.log('diameter', diameter);
        this.spinnerDiameter.set(diameter);
        // this.changeDetector.markForCheck();
      }
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    const update = () => {
      this.mediaCardElements.forEach((element) => {
        // console.debug('ELEMENT', element);
        if (element && element.position && element.position.length) {
          let style = 'position: absolute;';
          element.position.forEach((side) => {
            style += `${side}: 2px;`;
          });
          this.renderer.setAttribute(element.element.nativeElement, 'style', style);
        }
      });
    };
    update();
    this.mediaCardElements.changes.pipe(takeUntil(this.onDestroy$)).subscribe((changes) => update());
  }

  ngOnDestroy(): void {
    this.sensor.detach();
  }

  onPlay(event: Event) {
    // this.logger.debug('onPlay', event);
    // event.stopPropagation();
      if (!this.locked) {
        this.play.emit();
      } else if (this.media.lock?.message) {
        this.translateService
          .get(this.media.lock.message)
          .subscribe((message) =>
            this.dialog.open(MessageBoxComponent, {data: {message: message}})
          );
      }
  }

  mediaDuration(media: Partial<Media>): string {
    return getMediaDurationString(media);
  }

  onTransitionEnd(src:string) {
    MediaCardComponent.loadedUrls.get(src).transitioned = true;
  }

  onLoad(event:any,element:HTMLImageElement,src:string) {
    MediaCardComponent.loadedUrls.get(src).loaded = true;
    //console.log("ONLOAD",element);
    this.onCoverLoaded.emit(this.media);
    element.style.opacity='1';
  }

  onClick() {
    //console.log("CLICKED");
    if ((!this.indicator || this.mediaService.isFolderMedia(this.media) || !!this.mediaService.getPrimaryAction(this.media)) && this.play.observed) {
      this.play.emit();
    }
  }

  getMediaCover(media:Partial<Media>,maxWidth?:number,fallback:boolean = true): {
    src:string,
    loaded:boolean,
    transitioned:boolean,
    maxWidth:number,
    fallback:boolean
  } {
    const src = this.mediaService.getMediaCoverSrc(media,maxWidth, fallback);
    const context = MediaCardComponent.getLoadedUrlContext(src) ?? { src, loaded:false, transitioned:false, maxWidth, fallback };
    //console.log("getMediaCoverSrc",context.loaded,"src",src);
    if (!context.loaded) {
      MediaCardComponent.setLoadedUrlContext(src,context);
    }
    return context;
  }

  public static getLoadedUrlContext(src:string):{src:string,loaded:boolean,transitioned:boolean,maxWidth:number,fallback:boolean} {
    return MediaCardComponent.loadedUrls.get(src);
  }

  public static setLoadedUrlContext(src:string,context:{src:string,loaded:boolean,transitioned:boolean,maxWidth:number,fallback:boolean}) {
    return MediaCardComponent.loadedUrls.set(src,context);
  }
}
