/* projects/filter/src/lib/components/hierarchical-filter/hierarchical-filter.component.scss */
:host ::ng-deep .p-menuitem {
  width: 60px;
}
.hierarchical-filter-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}
.hierarchical-filter-container div {
  flex-grow: content;
}
.mat-tab-body-wrapper {
  flex-grow: 1;
}
::ng-deep .mat-tab-body-content {
  height: 500px;
}
::ng-deep .mat-tab-label,
::ng-deep .mat-tab-label-active {
  min-width: 0 !important;
  padding: 3px !important;
  margin: 3px !important;
}
slide-panel {
  flex: 1;
}
slide-panel .entry-grid {
  position: absolute;
  width: 100%;
  height: 100%;
}
.entry-grid {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
}
.entry-grid .entry {
  text-align: center;
  font-size: 1rem;
  height: 46px;
  cursor: pointer;
}
.entry-grid .entry-selected {
  background: grey;
}
.breadcrumb-container {
  max-width: 100%;
  display: table;
}
.breadcrumb-container .breadcrumb {
  display: table-row;
  padding: 16px 8px;
}
.breadcrumb-container .breadcrumb .breadcrumb-header {
  width: 33%;
  display: table-cell;
  border-left: 16px solid transparent;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.54);
}
.breadcrumb-container .breadcrumb .breadcrumb-value {
  display: table-cell;
  font-size: 1rem;
  border-left: 16px solid transparent;
  cursor: pointer;
}
mat-selection-list {
  height: 100%;
  overflow-y: scroll;
}
:host ::ng-deep .p-component,
.p-component * {
  padding: 1rem 0.5rem;
}
:host ::ng-deep .p-component .p-breadcrumb ul li.p-breadcrumb-chevron,
.p-component * .p-breadcrumb ul li.p-breadcrumb-chevron {
  padding: 0rem 0.5rem;
}
/*# sourceMappingURL=hierarchical-filter.component-I3GX67VY.css.map */
