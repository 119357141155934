<div *ngIf="reviewForm" class="container">
    <form [formGroup]="reviewForm" *ngIf="media.published" class="state">
      <tri-state-checkbox *ngIf="editDownlineVisibility"
                          formControlName="downlineInclude" aria-label="downline include">
        <span translate>media.downlineInclude</span>
      </tri-state-checkbox>
      <ng-container *ngIf="isApprover || isMaster">
        <mat-checkbox formControlName="approved"
            (change)="$event.checked && reviewForm.controls.declined.setValue(false)" aria-label="approve">
            <span translate>media.approved</span>
        </mat-checkbox>
        <mat-checkbox formControlName="declined"
            (change)="$event.checked && reviewForm.controls.approved.setValue(false)" aria-label="decline">
            <span translate>media.declined</span>
        </mat-checkbox>
        <mat-form-field *ngIf="reviewForm.controls.declined.value">
          <mat-label translate>media.declineReason</mat-label>
          <textarea #autosize="cdkTextareaAutosize"
                    matInput
                    unmodified-input
                    formControlName="reason"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="3"
                    aria-label="reason">
          </textarea>
        </mat-form-field>
      </ng-container>
      <button *ngIf="reviewForm.valid && !reviewForm.pristine" mat-mini-fab type="button" (click)="onSaveReview()"
          aria-label="save">
          <mat-icon>done</mat-icon>
      </button>
    </form>
</div>
