import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, cordova, cordovaPropertyGet, cordovaPropertySet } from '@ionic-native/core';
var StatusBar = /** @class */function (_super) {
  __extends(StatusBar, _super);
  function StatusBar() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  StatusBar.prototype.overlaysWebView = function (doesOverlay) {
    return cordova(this, "overlaysWebView", {
      "sync": true
    }, arguments);
  };
  StatusBar.prototype.styleDefault = function () {
    return cordova(this, "styleDefault", {
      "sync": true
    }, arguments);
  };
  StatusBar.prototype.styleLightContent = function () {
    return cordova(this, "styleLightContent", {
      "sync": true
    }, arguments);
  };
  StatusBar.prototype.styleBlackTranslucent = function () {
    return cordova(this, "styleBlackTranslucent", {
      "sync": true
    }, arguments);
  };
  StatusBar.prototype.styleBlackOpaque = function () {
    return cordova(this, "styleBlackOpaque", {
      "sync": true
    }, arguments);
  };
  StatusBar.prototype.backgroundColorByName = function (colorName) {
    return cordova(this, "backgroundColorByName", {
      "sync": true
    }, arguments);
  };
  StatusBar.prototype.backgroundColorByHexString = function (hexString) {
    return cordova(this, "backgroundColorByHexString", {
      "sync": true
    }, arguments);
  };
  StatusBar.prototype.hide = function () {
    return cordova(this, "hide", {
      "sync": true
    }, arguments);
  };
  StatusBar.prototype.show = function () {
    return cordova(this, "show", {
      "sync": true
    }, arguments);
  };
  Object.defineProperty(StatusBar.prototype, "isVisible", {
    get: function () {
      return cordovaPropertyGet(this, "isVisible");
    },
    set: function (value) {
      cordovaPropertySet(this, "isVisible", value);
    },
    enumerable: false,
    configurable: true
  });
  StatusBar.pluginName = "StatusBar";
  StatusBar.plugin = "cordova-plugin-statusbar";
  StatusBar.pluginRef = "StatusBar";
  StatusBar.repo = "https://github.com/apache/cordova-plugin-statusbar";
  StatusBar.platforms = ["Android", "iOS", "Windows"];
  StatusBar.decorators = [{
    type: Injectable
  }];
  return StatusBar;
}(IonicNativePlugin);
export { StatusBar };
