<mat-toolbar [color]="color" fxLayout="row">
  <button tabindex="-1" *ngIf="leftButton && !search && leftIcon" mat-icon-button (click)="emitLeftClicked()">
    <mat-icon>{{leftIcon}}</mat-icon>
  </button>
  <div *ngIf="!search" class="title-container" (click)="emitTitleClicked()" [style.width]="titleWidth"><ng-content></ng-content></div>
  <button tabindex="-1" *ngIf="searchButton && !search" mat-icon-button (click)="onSearchClicked()">
    <mat-icon>search</mat-icon>
  </button>
  <ng-container *ngIf="rightButton && !search" [ngTemplateOutlet]="rightButtonTemplateRef"></ng-container>
  <mat-icon class="search-icon" *ngIf="search">search</mat-icon>
  <div *ngIf="search" class="search-container">
    <mat-form-field>
      <input unmodified-input [autoFocus]="search" matInput [(ngModel)]="value" (ngModelChange)="onChange(value)">
    </mat-form-field>
  </div>
  <button tabindex="-1" *ngIf="search" mat-icon-button (click)="onSearchCloseClicked()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>
<ng-container [ngTemplateOutlet]="extensionTemplateRef"></ng-container>
<ng-template #rightButtonTemplate>
  <button tabindex="-1" *ngIf="rightIcon" mat-icon-button (click)="emitRightClicked()">
    <mat-icon>{{rightIcon}}</mat-icon>
  </button>
</ng-template>
<ng-template #extensionTemplate></ng-template>
