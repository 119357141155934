<ng-container *ngIf="media && mediaForm" [ngSwitch]="!!editMode">
  <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="view"></ng-container>
  <ng-container *ngSwitchCase="true" [ngTemplateOutlet]="edit"></ng-container>
</ng-container>

<ng-template #view>
</ng-template>

<ng-template #edit>
  <slide-panel [id]="panel"  (onSlide)="onSlide($event)">
    <div *slidePanelLeft="'list'" class="panel">
      <mat-list>
        <ng-container *ngFor="let action of actions(); let index = index; let first = first">
          <ng-container *ngIf="{ editable: action.editable!=false } as context">
            <mat-list-item *ngIf="action.type!='separator'">
              <div class="row" [class.editable]="context.editable" (click)="context.editable && onActionEdit(action)">
                <div *ngIf="{ label: action?.label || 'media.actions.'+action.id } as context" class="action">
                  <mat-icon *ngIf="action.icon as icon">{{icon}}</mat-icon>
                  <span class="label" translate>{{context.label}}</span>
                </div>
                <button *ngIf="context.editable"
                        mat-icon-button
                        mat-mdc-icon-micro-button
                        (click)="$event.stopPropagation(); onActionRemove(action)"
                        class="action-remove">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <mat-divider *ngIf="first"></mat-divider>
            </mat-list-item>
          </ng-container>
        </ng-container>
        <a class="action" (click)="onActionEdit();" translate>media.actions.create</a>
      </mat-list>
      <!--
      <div *ngIf="action() as action" class="details">
        <ng-container *ngFor="let key of [ 'id', 'type', 'icon', 'label', 'parameter', 'from', 'to', 'promotion', 'primary' ]">
          <span *ngIf="action[key] as value"
                [innerHTML]="'media.actions.property' | translate:{
                name: ('media.actions.properties.'+key | translate),
                value: !!translations[key] ? (translations[key] | translate) : value
            } | safeHtml">
        </span>
        </ng-container>
      </div>
      -->
    </div>
    <div *slidePanelRightLazy="'edit'" class="panel">
      <form [formGroup]="actionForm" novalidate>
        <mat-form-field>
          <!--        <mat-icon matPrefix>id</mat-icon>-->
          <mat-label translate>media.actions.properties.id</mat-label>
          <input type="text" matInput unmodified-input required formControlName="id" aria-label="id"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label translate>media.actions.properties.type</mat-label>
          <select matNativeControl
                  formControlName="type"
                  aria-label="type">
            <option *ngFor="let type of ['internal', 'external', 'redirect']" [value]="type" translate>
              {{'media.actions.properties.types.' + type}}
            </option>
          </select>
        </mat-form-field>
        <!--mat-form-field>
          <mat-label translate>media.actions.properties.type</mat-label>
          <input type="text" matInput unmodified-input required formControlName="type"/>
        </mat-form-field-->
        <mat-form-field>
          <mat-label translate>media.actions.properties.label</mat-label>
          <input type="text" matInput unmodified-input formControlName="label" aria-label="label"/>
        </mat-form-field>
        <mat-form-field>
          <mat-icon *ngIf="actionForm.controls.icon.value as icon" matSuffix>{{icon}}</mat-icon>
          <mat-label translate>media.actions.properties.icon</mat-label>
          <input type="text" matInput unmodified-input formControlName="icon" aria-label="label"/>
        </mat-form-field>
        <mat-form-field>
          <mat-label translate>media.actions.properties.parameter</mat-label>
          <input type="text" matInput unmodified-input formControlName="parameter" aria-label="parameter"/>
        </mat-form-field>
        <mat-checkbox formControlName="primary" aria-label="primary">
          <span translate>media.actions.properties.primary</span>
        </mat-checkbox>
        <mat-checkbox formControlName="promotion" aria-label="promotion">
          <span translate>media.actions.properties.promotion</span>
        </mat-checkbox>
        <button mat-icon-button
                class="back"
                aria-label="back"
                (click)="onBack($event)">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-mini-fab
                color="accent"
                class="save"
                aria-label="save"
                [disabled]="!(actionForm.valid && actionForm.dirty)"
                (click)="onActionSave($event)">
          <mat-icon>done</mat-icon>
        </button>
      </form>
    </div>
  </slide-panel>
</ng-template>
