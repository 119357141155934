import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  OnInit,
  Output
} from '@angular/core';
import {ENVIRONMENT} from "core";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";

//https://stackoverflow.com/questions/43631813/angular-pass-multiple-templates-to-component

// TODO: move to core models?
export type UploadStats = {
  count: number;
  percentage: number;
  remainingTime: number;
}

@Component({
  selector: 'app-thumb-status-left',
  template: `
    <ng-container>
      <mat-icon>cloud_upload</mat-icon>
      <span>{{status?.percentage}}%</span>
      <span *ngIf="!environment.production">({{status?.count}})</span>
    </ng-container>
  `,
  styleUrls: ['./thumb-status-left.component.scss']
})
export class ThumbStatusLeftComponent implements OnInit {

  status: UploadStats;
  subscription: Subscription;
  onClick = new EventEmitter();
  onHide  = new EventEmitter();

  @HostBinding('class.hide') hide = true;

  constructor(private route: ActivatedRoute,
              private changeDetector: ChangeDetectorRef,
              @Inject(ENVIRONMENT) public environment: any) {
  }

  ngOnInit() {
    const status = this.route.snapshot.data.status;
    if (status) {
      this.subscription = status.subscribe((status: UploadStats) => {
        if (this.status!=status) {
          const hide = !status || !status.count;
          if (hide && this.status && this.status.count > 0) this.onHide.emit();
          // this.display = hide ? 'none' : '';
          this.status = status;
          this.hide   = !(status?.count>0);
          this.changeDetector.markForCheck();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  @HostListener('click')
  onHostClick() {
    this.onClick.emit();
  }
}
