import {ActionReducer, createAction, createReducer, MetaReducer} from "@ngrx/store";
import {InjectionToken} from "@angular/core";

export interface State {}
export const initialState: State = {};

export const RESET_APP_STATE = new InjectionToken('RESET_APP_STATE');
export const resetAppAction = createAction('resetApp');
export const resetGroupAction = createAction('resetGroup');

export function metaReducerFactory(resetState: any): MetaReducer<State> {
  return (reducer: ActionReducer<any>) => (state, action) => {
    if (action?.type === resetAppAction.type) {
      // return reducer(undefined, { type: INIT }); // switches all features to their initial state but we handle properties and auth state ourselves
      const next = {...state, ...resetState.app};
      console.log("RESET.APP",state,next)
      return reducer(next, action);
    } else if (action?.type === resetGroupAction.type) {
      // return reducer(undefined, { type: INIT }); // switches all features to their initial state but we handle properties and auth state ourselves
      const next = {...state, ...resetState.group};
      console.log("RESET.GROUP",state,next)
      return reducer(next, action);
    }
    return reducer(state, action);
  }
}

export const reducer = createReducer(
  initialState,
  {
    types: [resetAppAction.type],
    reducer: (state, action) => ({ ...initialState })
  }
);

// export const APP_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>('APP_REDUCERS', {
//   factory: () => ({
//     //router: fromRouter.routerReducer
//   }),
// });

// export const reducers: ActionReducerMap<State> = {};

/*
export function resetter(reducer: ActionReducer<State>): ActionReducer<any> {
  return (state, action) => {
    if (action?.type === resetAppAction.type) {
      // return reducer(undefined, { type: INIT }); // switches all features to their initial state but we handle properties and auth state ourselves
      const next = {...state, ...resetState};
      console.debug('RESET. Next', next);
      return reducer(next, action);
    }
    return reducer(state, action);
  };
}
*/

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();
    return result;
  };
}

export const metaReducers: MetaReducer<State>[] = []//[logger]// , storeFreeze];
// export const metaReducers: MetaReducer<State>[] = [ resetter, logger]; //, storeFreeze];
