import {Injectable, InjectionToken} from "@angular/core";
import {GroupMembership} from "./group-membership";
import {Image} from "./image";
import {App} from "./app";
import {HasTags} from "./has-tags";
import {Company} from "./company";
import {Media} from "media";

/*
export interface ContactRelationship {
  upline?:boolean;
  downline?:boolean;
  crossline?:boolean;
}
 */

export interface Link {
  label: string;
  url: string;
}

export declare type ContactReference = Required<Pick<Contact, 'id' | 'version' | 'name'>>;

export class HasContact {
  contact: ContactReference;
}

export class Contact extends HasTags {
 _id: string;
  id: string;
  version: number;
  memberId?: string;           // the users member id
  role?: string;
  firstName: string;
  lastName: string;
  nickName: string;
  name: string;
  birthday?: number;
  birthdaywoy?: number;  // birthday without year
  gender: 'male' | 'female' | 'unknown';
  email?: string;
  phone?: string;
  chat?: boolean;
  whatsapp?: string;
  wechat?: string;
  messenger?: string;
  telegram?: string;
  picture: { data: Image };
  avatar: string;
  zip?: string;
  community?: string;
  address?: string;
  countryCode: string;
  latitude?: number;
  longitude?: number;
  link: string;
  locale: string;
  timeUpdated: number;
  timeDeleted?: number;   // not passed from server. just for local deletion.....
  timeExpired?: number;   // for invitations
  ymdCreated: number;
  installed: boolean;
  verified: boolean;      // verified partner or member
  subscriptions?: boolean;
  //relationship?: ContactRelationship;
  groups?: { [key:string]: GroupMembership };
  app?: App; //#REMOVED
  rank?:number;
  value?:number;  // rank value
  //apps?: App[];
  company: Company;
  interestTags?: string[];
  badgeData?: string[];
  filterTags?: string[];
  hasNotificationId: boolean;
  bio?: string;
  background?: Media;
  links?: Link[];
  constructor(init?: Partial<Contact>) {
    super();
    Object.assign(this, init);
  }
}

export function hasAddress(contact:Contact):boolean {
  return !!contact &&
    !!contact.address  && !!contact.community &&
    !!contact.latitude && !!contact.longitude;
}

@Injectable({
  providedIn: 'root'
})
export class NullContact extends Contact {
 _id        = '';
  id        = '';
  version   = 0;
  firstName = '';
  lastName  = '';
  name      = '';
  link      = '';
  locale    = 'de';
  picture   = { data: {
    height: 200,
    width : 200,
      // TODO: inject environment
    // url   : this.environment.serverUrl+'/shared/contact/default.png'
      url   : '/shared/contact/default.png'
  }};
  // avatar      = this.environment.serverUrl+'/shared/contact/default.png';
  avatar        = '/shared/contact/default.png';
  countryCode   = 'AT';
  groups = {};
  installed    = false;
  verified     = false;
  ymdCreated   = 0;
  timeUpdated  = 0;
  constructor() { super(); }
}

export const NULL_CONTACT = new InjectionToken('NullContact');
