<mat-list dense class="mat-accent" *ngLet="{
    mode: getThemeMode$() | async,
    pure: isPureMode$() | async
  } as scheme">
  <ng-container *ngFor="let group of (entities$ | async); trackBy: trackByGroup;
                                let index = index;
                                let first = first;
                                let last = last;
                                let even = even;
                                let odd = odd;">
    <ng-container *ngTemplateOutlet="groupTemplate; context: { group: safeGroup(group), index: index, mode:scheme.mode, pure:scheme.pure }"></ng-container>
    <!--
    <mat-divider *ngIf="!last"></mat-divider>
    -->
  </ng-container>
</mat-list>

<ng-template #groupTemplate let-group="group" let-mode="mode" let-pure="pure" let-index="index">
  <div class="group-container">
    <div class="group" [class.selected]="isGroupSelected(group, index)" matRipple>
      <div class="content" [style.background-color]="mode=='light' && !!group.colors?.primary && !group.colors.pure ? group.colors.primary : 'inherit'">
        <group-logo [group]="group"
                    [theme]="theme"
                    [animated]="animated$ | async"
                    [elevated]="false"
                    [rounded]="true"
                    (play)="onPlayMedia($event, index)">
        </group-logo>
        <div class="info">
          <div [style.color]="mode=='light' && !!group.colors?.primaryContrast && !group.colors.pure ? group.colors?.primaryContrast : 'inherit'">{{group.name | translate}}</div>
        </div>
      </div>
      <div class="touch-cover" (click)="onSelectedGroup(group)"></div>
    </div>
  </div>
</ng-template>
