import {
  propertiesLoadFailure,
  propertiesLoadRequest,
  propertiesLoadSuccess, propertiesUpdateInterests
} from "./actions";
import {User} from "core";
import {Properties} from "../models/properties";
import {CountryInfo} from "../models/country-info";
import {Partner} from "../models/partner";
import {Action, createFeatureSelector, createReducer, createSelector, on} from "@ngrx/store";
import {EMPTY_ARRAY} from "core";

export const propertiesFeatureKey = 'properties';

export interface PropertiesState {
  readonly identifier : string;
  readonly properties : Properties | null;
  readonly error : any | null;
}

export const initialPropertiesState : PropertiesState = {
  identifier: null,
  properties: null,
  error: null
};

// do not export - leads to naming conflicts with other feature State interfaces
interface State {
  readonly [propertiesFeatureKey] : PropertiesState;
}

export const reducer = createReducer(
  initialPropertiesState,
  on(propertiesLoadRequest, (state, {}) => ({...state, error: null})),
  on(propertiesLoadSuccess, (state, {identifier, properties}) => ({
    identifier: identifier,
    properties: {...properties},
    error: null
  })),
  on(propertiesLoadFailure, (state, {identifier, error}) => ({
    ...initialPropertiesState,
    identifier: identifier,
    error: error
  })),
  on(propertiesUpdateInterests, (state, {interests}) => ({
    ...state,
    properties: <Properties>{
      ...state.properties,
      user: new User({
        ...state.properties.user,
        interestTags: [...(interests??EMPTY_ARRAY)]
      })
    }
  })),
);

/* The exported propertiesReducer function is necessary as function calls are not supported by the AOT compiler. */
export function propertiesReducer(state: PropertiesState | undefined, action: Action) {
  return reducer(state, action);
}

/*
export function propertiesReducer(state = initialPropertiesState, action: PropertiesActionTypes): PropertiesState {
  console.debug("PROPERTIES reducer", state, action);
  switch(action.type) {
    case PropertiesActionIds.LOAD_REQUEST: {
      //console.debug("PROPERTIES LOAD REQUEST");
      return {
        ...state,
        error: null
      };
    }
    case PropertiesActionIds.LOAD_SUCCESS: {
      //console.debug("PROPERTIES LOADED",action.properties);
      //window.alert("properties loaded! properties:"+action.properties+" user:"+(action.properties && action.properties.user ?action.properties.user.id : false));
      return {
        identifier: action.identifier,
        properties: {...action.properties},
        error: null
      };
    }
    case PropertiesActionIds.LOAD_FAILURE: {
      return {
        ...initialPropertiesState,
        identifier: action.identifier,
        error: action.error
      };
    }
    default:
      return state;
  }
}
*/

export const selectPropertiesState = createFeatureSelector<State, PropertiesState>(propertiesFeatureKey);

// export const selectPropertiesLoaded = createSelector(
//   selectPropertiesState,
//   (state: PropertiesState) : boolean => !!state.properties
// );

export const selectPropertiesUser = createSelector(
  selectPropertiesState,
  (state: PropertiesState) : User => state.properties?.user
);

export const selectPropertiesViewed = createSelector(
  selectPropertiesState,
  (state: PropertiesState) : {
    id: string;
    name: string;
    public?: boolean;
  } => state.properties?.viewed
);

export const selectPropertiesPartner = createSelector(
  selectPropertiesState,
  (state: PropertiesState) : Partner => state.properties?.partner
);

export const selectProperties  = createSelector(
  selectPropertiesState,
  (state: PropertiesState) : Properties => state.properties
);

export const selectPropertiesSession = createSelector(
  selectPropertiesState,
  (state: PropertiesState) : CountryInfo => state.properties?.session
);

