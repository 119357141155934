interface Touch {
  pageX: number;
  pageY: number;
  time: number;
}

interface Difference {
  x: number;
  y: number;
  time: number;
  totalX: number;
  totalY: number;
  totalTime: number;
}

export class TouchTracker implements Touch {
  public start   : Touch = undefined;
  public startX  : number;
  public startY  : number;
  public started : boolean = false;

  constructor(public pageX : number = 0, public pageY : number = 0, public time : number = 0) {}

  public initialize(event : MouseEvent | TouchEvent, startX:number, startY:number) : TouchTracker {
    this.pageX  = event instanceof MouseEvent ? event.pageX : event.touches[0].pageX;
    this.pageY  = event instanceof MouseEvent ? event.pageY : event.touches[0].pageY;
    this.time   = Date.now();
    this.start  = {
      pageX: this.pageX,
      pageY: this.pageY,
      time:  this.time
    };
    this.startX  = startX;
    this.startY  = startY;
    this.started = true;
    return this;
  }

  public track(event : MouseEvent | TouchEvent) : Difference {
    let  pageX  = this.pageX;
    let  pageY  = this.pageY;
    let  time   = this.time;
    this.pageX  = event instanceof MouseEvent ? event.pageX : event.touches.length ? event.touches[0].pageX : pageX;
    this.pageY  = event instanceof MouseEvent ? event.pageY : event.touches.length ? event.touches[0].pageY : pageY;
    this.time   = Date.now();
    return {
      x: this.pageX-pageX,
      y: this.pageY-pageY,
      time: this.time-time,
      totalX: this.pageX-this.start.pageX,
      totalY: this.pageY-this.start.pageY,
      totalTime: this.time-this.start.time
    }
  }

  public isValidEvent(event : MouseEvent | TouchEvent, maxTouches:number = 1, minTouches:number = 1) : boolean {
    if (event instanceof MouseEvent) {
      return minTouches==1 && maxTouches==1;
    } else if (event.touches.length>=minTouches && event.touches.length<=maxTouches) {
      return true;
    }
    return false;
  }

  public stop() : boolean {
    let result = this.started;
    this.started = false;
    return result;
  }

  public isStarted() : boolean {
    return this.started;
  }

  public elapsed() : number {
    return this.start && this.start.time ? Date.now()-this.start.time : 0;
  }
}
