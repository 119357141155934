/* projects/media/src/lib/components/channel-details/channel-details.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
}
.header-section {
  width: 100%;
  display: block;
}
.header-section .background-image {
  width: 100%;
}
::ng-deep .cover-container {
  border: 0px;
  padding: 0px;
}
::ng-deep .media-container {
  border: 0px !important;
}
.header {
  margin-left: 24px;
  display: flex;
}
.header .header-row {
  display: flex;
}
.header .header-row button {
  align-self: center;
  justify-self: center;
}
.header .avatar-large {
  width: 120px;
  height: 120px;
  margin-top: -60px;
  border: 4px solid #fff;
}
.header .avatar-small {
  width: 80px;
  height: 80px;
  margin-top: -40px;
  border: 2px solid #fff;
}
.header media-card {
  padding: 0px;
}
.header .channel-name {
  padding-left: 8px;
  font-weight: 500;
  align-self: center;
}
.header .channel-link {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group ::ng-deep .mat-ink-bar {
  background-color: var(--color-primary);
}
.channel-tab-content {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 98;
}
.tab-content {
  padding: 16px;
  font-size: 14px;
  padding-right: 64px;
  max-width: 1284px;
}
:host-context(.color-scheme-dark) .avatar-large {
  border-color: #181818;
}
:host-context(.color-scheme-dark) .channel-link {
  color: #fff;
}
:host-context(.color-scheme-dark) .channel-link:hover {
  color: var(--color-primary);
}
/*# sourceMappingURL=channel-details.component-2VR2DJ2T.css.map */
