import {Injectable} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {map} from "rxjs/operators";
import {Observable, ReplaySubject} from "rxjs";
import {CountryService} from "./country.service";
import {Logger, Timezone, Timezones} from "core";

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  // https://stackoverflow.com/tags/timezone/info
  /*
  protected static zones: Observable<{[key: string]: string[]}> = of ({
    AD: ['Europe/Andorra'],
    AE: ['Asia/Dubai'],
    AF: ['Asia/Kabul'],
    AG: ['America/Antigua'],
    AI: ['America/Anguilla'],
    AL: ['Europe/Tirane'],
    AM: ['Asia/Yerevan'],
    AO: ['Africa/Luanda'],
    AQ: [
      'Antarctica/McMurdo',
      'Antarctica/Rothera',
      'Antarctica/Palmer',
      'Antarctica/Mawson',
      'Antarctica/Davis',
      'Antarctica/Casey',
      'Antarctica/Vostok',
      'Antarctica/DumontDUrville',
      'Antarctica/Syowa',
      'Antarctica/Troll'
    ],
    AR: [
      'America/Argentina/Buenos_Aires',
      'America/Argentina/Cordoba',
      'America/Argentina/Salta',
      'America/Argentina/Jujuy',
      'America/Argentina/Tucuman',
      'America/Argentina/Catamarca',
      'America/Argentina/La_Rioja',
      'America/Argentina/San_Juan',
      'America/Argentina/Mendoza',
      'America/Argentina/San_Luis',
      'America/Argentina/Rio_Gallegos',
      'America/Argentina/Ushuaia'
    ],
    AS: ['Pacific/Pago_Pago', 'Pacific/Samoa'],
    AT: ['Europe/Vienna'],
    AU: [
      'Australia/Lord_Howe',
      'Antarctica/Macquarie',
      'Australia/Hobart',
      'Australia/Currie',
      'Australia/Melbourne',
      'Australia/Sydney',
      'Australia/Broken_Hill',
      'Australia/Brisbane',
      'Australia/Lindeman',
      'Australia/Adelaide',
      'Australia/Darwin',
      'Australia/Perth',
      'Australia/Eucla',
      'Australia/Canberra',
      'Australia/Queensland',
      'Australia/Tasmania',
      'Australia/Victoria'
    ],
    AW: ['America/Aruba'],
    AX: ['Europe/Mariehamn'],
    AZ: ['Asia/Baku'],
    BA: ['Europe/Sarajevo'],
    BB: ['America/Barbados'],
    BD: ['Asia/Dhaka'],
    BE: ['Europe/Brussels'],
    BF: ['Africa/Ouagadougou'],
    BG: ['Europe/Sofia'],
    BH: ['Asia/Bahrain'],
    BI: ['Africa/Bujumbura'],
    BJ: ['Africa/Porto-Novo'],
    BL: ['America/St_Barthelemy'],
    BM: ['Atlantic/Bermuda'],
    BN: ['Asia/Brunei'],
    BO: ['America/La_Paz'],
    BQ: ['America/Kralendijk'],
    BR: [
      'America/Noronha',
      'America/Belem',
      'America/Fortaleza',
      'America/Recife',
      'America/Araguaina',
      'America/Maceio',
      'America/Bahia',
      'America/Sao_Paulo',
      'America/Campo_Grande',
      'America/Cuiaba',
      'America/Santarem',
      'America/Porto_Velho',
      'America/Boa_Vista',
      'America/Manaus',
      'America/Eirunepe',
      'America/Rio_Branco'
    ],
    BS: ['America/Nassau'],
    BT: ['Asia/Thimphu'],
    BW: ['Africa/Gaborone'],
    BY: ['Europe/Minsk'],
    BZ: ['America/Belize'],
    CA: [
      'America/St_Johns',
      'America/Halifax',
      'America/Glace_Bay',
      'America/Moncton',
      'America/Goose_Bay',
      'America/Blanc-Sablon',
      'America/Toronto',
      'America/Nipigon',
      'America/Thunder_Bay',
      'America/Iqaluit',
      'America/Pangnirtung',
      'America/Resolute',
      'America/Atikokan',
      'America/Rankin_Inlet',
      'America/Winnipeg',
      'America/Rainy_River',
      'America/Regina',
      'America/Swift_Current',
      'America/Edmonton',
      'America/Cambridge_Bay',
      'America/Yellowknife',
      'America/Inuvik',
      'America/Creston',
      'America/Dawson_Creek',
      'America/Vancouver',
      'America/Whitehorse',
      'America/Dawson',
      'America/Montreal',
      'Canada/Atlantic',
      'Canada/Central',
      'Canada/Eastern',
      'Canada/Mountain',
      'Canada/Newfoundland',
      'Canada/Pacific',
      'Canada/Saskatchewan',
      'Canada/Yukon'
    ],
    CC: ['Indian/Cocos'],
    CD: ['Africa/Kinshasa', 'Africa/Lubumbashi'],
    CF: ['Africa/Bangui'],
    CG: ['Africa/Brazzaville'],
    CH: ['Europe/Zurich'],
    CI: ['Africa/Abidjan'],
    CK: ['Pacific/Rarotonga'],
    CL: [
      'America/Santiago',
      'Pacific/Easter',
      'Chile/Continental',
      'Chile/EasterIsland'
    ],
    CM: ['Africa/Douala'],
    CN: [
      'Asia/Shanghai',
      'Asia/Harbin',
      'Asia/Chongqing',
      'Asia/Urumqi',
      'Asia/Kashgar'
    ],
    CO: ['America/Bogota'],
    CR: ['America/Costa_Rica'],
    CU: ['America/Havana'],
    CV: ['Atlantic/Cape_Verde'],
    CW: ['America/Curacao'],
    CX: ['Indian/Christmas'],
    CY: ['Asia/Nicosia'],
    CZ: ['Europe/Prague'],
    DE: ['Europe/Berlin'],
    DJ: ['Africa/Djibouti'],
    DK: ['Europe/Copenhagen'],
    DM: ['America/Dominica'],
    DO: ['America/Santo_Domingo'],
    DZ: ['Africa/Algiers'],
    EC: ['America/Guayaquil', 'Pacific/Galapagos'],
    EE: ['Europe/Tallinn'],
    EG: ['Egypt'],
    EH: ['Africa/El_Aaiun'],
    ER: ['Africa/Asmara'],
    ES: ['Europe/Madrid', 'Africa/Ceuta', 'Atlantic/Canary'],
    ET: ['Africa/Addis_Ababa'],
    FI: ['Europe/Helsinki'],
    FJ: ['Pacific/Fiji'],
    FK: ['Atlantic/Stanley'],
    FM: ['Pacific/Chuuk', 'Pacific/Pohnpei', 'Pacific/Kosrae'],
    FO: ['Atlantic/Faroe'],
    FR: ['Europe/Paris'],
    GA: ['Africa/Libreville'],
    GB: ['Europe/London'],
    GD: ['America/Grenada'],
    GE: ['Asia/Tbilisi'],
    GF: ['America/Cayenne'],
    GG: ['Europe/Guernsey'],
    GH: ['Africa/Accra'],
    GI: ['Europe/Gibraltar'],
    GL: [
      'America/Godthab',
      'America/Danmarkshavn',
      'America/Scoresbysund',
      'America/Thule'
    ],
    GM: ['Africa/Banjul'],
    GN: ['Africa/Conakry'],
    GP: ['America/Guadeloupe'],
    GQ: ['Africa/Malabo'],
    GR: ['Europe/Athens'],
    GS: ['Atlantic/South_Georgia'],
    GT: ['America/Guatemala'],
    GU: ['Pacific/Guam'],
    GW: ['Africa/Bissau'],
    GY: ['America/Guyana'],
    HK: ['Asia/Hong_Kong'],
    HN: ['America/Tegucigalpa'],
    HR: ['Europe/Zagreb'],
    HT: ['America/Port-au-Prince'],
    HU: ['Europe/Budapest'],
    ID: ['Asia/Jakarta', 'Asia/Pontianak', 'Asia/Makassar', 'Asia/Jayapura'],
    IE: ['Europe/Dublin'],
    IL: ['Asia/Jerusalem'],
    IM: ['Europe/Isle_of_Man'],
    IN: ['Asia/Kolkata'],
    IO: ['Indian/Chagos'],
    IQ: ['Asia/Baghdad'],
    IR: ['Asia/Tehran'],
    IS: ['Atlantic/Reykjavik'],
    IT: ['Europe/Rome'],
    JE: ['Europe/Jersey'],
    JM: ['America/Jamaica'],
    JO: ['Asia/Amman'],
    JP: ['Asia/Tokyo'],
    KE: ['Africa/Nairobi'],
    KG: ['Asia/Bishkek'],
    KH: ['Asia/Phnom_Penh'],
    KI: ['Pacific/Tarawa', 'Pacific/Enderbury', 'Pacific/Kiritimati'],
    KM: ['Indian/Comoro'],
    KN: ['America/St_Kitts'],
    KP: ['Asia/Pyongyang'],
    KR: ['Asia/Seoul'],
    KW: ['Asia/Kuwait'],
    KY: ['America/Cayman'],
    KZ: [
      'Asia/Almaty',
      'Asia/Qyzylorda',
      'Asia/Aqtobe',
      'Asia/Aqtau',
      'Asia/Oral'
    ],
    LA: ['Asia/Vientiane'],
    LB: ['Asia/Beirut'],
    LC: ['America/St_Lucia'],
    LI: ['Europe/Vaduz'],
    LK: ['Asia/Colombo'],
    LR: ['Africa/Monrovia'],
    LS: ['Africa/Maseru'],
    LT: ['Europe/Vilnius'],
    LU: ['Europe/Luxembourg'],
    LV: ['Europe/Riga'],
    LY: ['Africa/Tripoli'],
    MA: ['Africa/Casablanca'],
    MC: ['Europe/Monaco'],
    MD: ['Europe/Chisinau'],
    ME: ['Europe/Podgorica'],
    MF: ['America/Marigot'],
    MG: ['Indian/Antananarivo'],
    MH: ['Pacific/Majuro', 'Pacific/Kwajalein'],
    MK: ['Europe/Skopje'],
    ML: ['Africa/Bamako'],
    MM: ['Asia/Rangoon'],
    MN: ['Asia/Ulaanbaatar', 'Asia/Hovd', 'Asia/Choibalsan'],
    MO: ['Asia/Macau'],
    MP: ['Pacific/Saipan'],
    MQ: ['America/Martinique'],
    MR: ['Africa/Nouakchott'],
    MS: ['America/Montserrat'],
    MT: ['Europe/Malta'],
    MU: ['Indian/Mauritius'],
    MV: ['Indian/Maldives'],
    MW: ['Africa/Blantyre'],
    MX: [
      'America/Mexico_City',
      'America/Cancun',
      'America/Merida',
      'America/Monterrey',
      'America/Matamoros',
      'America/Mazatlan',
      'America/Chihuahua',
      'America/Ojinaga',
      'America/Hermosillo',
      'America/Tijuana',
      'America/Santa_Isabel',
      'America/Bahia_Banderas'
    ],
    MY: ['Asia/Kuala_Lumpur', 'Asia/Kuching'],
    MZ: ['Africa/Maputo'],
    NA: ['Africa/Windhoek'],
    NC: ['Pacific/Noumea'],
    NE: ['Africa/Niamey'],
    NF: ['Pacific/Norfolk'],
    NG: ['Africa/Lagos'],
    NI: ['America/Managua'],
    NL: ['Europe/Amsterdam'],
    NO: ['Europe/Oslo'],
    NP: ['Asia/Kathmandu'],
    NR: ['Pacific/Nauru'],
    NU: ['Pacific/Niue'],
    NZ: ['Pacific/Auckland', 'Pacific/Chatham'],
    OM: ['Asia/Muscat'],
    PA: ['America/Panama'],
    PE: ['America/Lima'],
    PF: ['Pacific/Tahiti', 'Pacific/Marquesas', 'Pacific/Gambier'],
    PG: ['Pacific/Port_Moresby'],
    PH: ['Asia/Manila'],
    PK: ['Asia/Karachi'],
    PL: ['Europe/Warsaw', 'Poland'],
    PM: ['America/Miquelon'],
    PN: ['Pacific/Pitcairn'],
    PR: ['America/Puerto_Rico'],
    PS: ['Asia/Gaza', 'Asia/Hebron'],
    PT: ['Europe/Lisbon', 'Atlantic/Madeira', 'Atlantic/Azores'],
    PW: ['Pacific/Palau'],
    PY: ['America/Asuncion'],
    QA: ['Asia/Qatar'],
    RE: ['Indian/Reunion'],
    RO: ['Europe/Bucharest'],
    RS: ['Europe/Belgrade'],
    RU: [
      'Europe/Kaliningrad',
      'Europe/Moscow',
      'Europe/Volgograd',
      'Europe/Samara',
      'Europe/Simferopol',
      'Asia/Yekaterinburg',
      'Asia/Omsk',
      'Asia/Novosibirsk',
      'Asia/Novokuznetsk',
      'Asia/Krasnoyarsk',
      'Asia/Irkutsk',
      'Asia/Yakutsk',
      'Asia/Khandyga',
      'Asia/Vladivostok',
      'Asia/Sakhalin',
      'Asia/Ust-Nera',
      'Asia/Magadan',
      'Asia/Kamchatka',
      'Asia/Anadyr'
    ],
    RW: ['Africa/Kigali'],
    SA: ['Asia/Riyadh'],
    SB: ['Pacific/Guadalcanal'],
    SC: ['Indian/Mahe'],
    SD: ['Africa/Khartoum'],
    SE: ['Europe/Stockholm'],
    SG: ['Asia/Singapore'],
    SH: ['Atlantic/St_Helena'],
    SI: ['Europe/Ljubljana'],
    SJ: ['Arctic/Longyearbyen'],
    SK: ['Europe/Bratislava'],
    SL: ['Africa/Freetown'],
    SM: ['Europe/San_Marino'],
    SN: ['Africa/Dakar'],
    SO: ['Africa/Mogadishu'],
    SR: ['America/Paramaribo'],
    SS: ['Africa/Juba'],
    ST: ['Africa/Sao_Tome'],
    SV: ['America/El_Salvador'],
    SX: ['America/Lower_Princes'],
    SY: ['Asia/Damascus'],
    SZ: ['Africa/Mbabane'],
    TC: ['America/Grand_Turk'],
    TD: ['Africa/Ndjamena'],
    TF: ['Indian/Kerguelen'],
    TG: ['Africa/Lome'],
    TH: ['Asia/Bangkok'],
    TJ: ['Asia/Dushanbe'],
    TK: ['Pacific/Fakaofo'],
    TL: ['Asia/Dili'],
    TM: ['Asia/Ashgabat'],
    TN: ['Africa/Tunis'],
    TO: ['Pacific/Tongatapu'],
    TR: ['Europe/Istanbul'],
    TT: ['America/Port_of_Spain'],
    TV: ['Pacific/Funafuti'],
    TW: ['Asia/Taipei'],
    TZ: ['Africa/Dar_es_Salaam'],
    UA: ['Europe/Kiev', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
    UG: ['Africa/Kampala'],
    UM: ['Pacific/Johnston', 'Pacific/Midway', 'Pacific/Wake'],
    US: [
      'America/New_York',
      'America/Detroit',
      'America/Kentucky/Louisville',
      'America/Kentucky/Monticello',
      'America/Indiana/Indianapolis',
      'America/Indiana/Vincennes',
      'America/Indiana/Winamac',
      'America/Indiana/Marengo',
      'America/Indiana/Petersburg',
      'America/Indiana/Vevay',
      'America/Chicago',
      'America/Indiana/Tell_City',
      'America/Indiana/Knox',
      'America/Menominee',
      'America/North_Dakota/Center',
      'America/North_Dakota/New_Salem',
      'America/North_Dakota/Beulah',
      'America/Denver',
      'America/Boise',
      'America/Phoenix',
      'America/Los_Angeles',
      'America/Anchorage',
      'America/Juneau',
      'America/Sitka',
      'America/Yakutat',
      'America/Nome',
      'America/Adak',
      'America/Metlakatla',
      'Pacific/Honolulu'
    ],
    UY: ['America/Montevideo'],
    UZ: ['Asia/Samarkand', 'Asia/Tashkent'],
    VA: ['Europe/Vatican'],
    VC: ['America/St_Vincent'],
    VE: ['America/Caracas'],
    VG: ['America/Tortola'],
    VI: ['America/St_Thomas'],
    VN: ['Asia/Ho_Chi_Minh'],
    VU: ['Pacific/Efate'],
    WF: ['Pacific/Wallis'],
    WS: ['Pacific/Apia'],
    YE: ['Asia/Aden'],
    YT: ['Indian/Mayotte'],
    ZA: ['Africa/Johannesburg'],
    ZM: ['Africa/Lusaka'],
    ZW: ['Africa/Harare']
  });
  */

  protected _zones: Observable<Timezones>;
  protected logger = new Logger('TimezoneService');

  constructor(protected countryService: CountryService,
              protected translateService: TranslateService) {
  }

  zones(): Observable<Timezones> {
    if (!this._zones) {
      // this._zones = TimezoneService.zones
      //   .pipe(map(zones => {
      //     return Object.keys(zones || {})
      //       .reduce((result: Timezone[], countryCode, index) => {
      //         result.push({
      //           countryCode: countryCode,
      //           zones: zones[countryCode]
      //         });
      //         return result;
      //       }, [] as Timezone[]);
      //   }));
      const zones = new ReplaySubject<Timezones>(1);
      this.translateService
        .stream('time_zones')
        .pipe(map((timezones: {countryCode: string, zones: {[zoneId: string]: string}[]}[]) => {
          return timezones.reduce((result, current) => {
            result.push({
              countryCode: current.countryCode,
              zones: current.zones.reduce((result: Timezone[], zone) => {
                const [zoneId] = Object.keys(zone);
                result.push({ id: zoneId, name: zone[zoneId] });
                return result;
              }, [] as Timezone[])});
            return result;
          }, [] as Timezones);
        }))
        .subscribe((timezones) => {
          this.logger.debug('TIMEZONES ', timezones);
          zones.next(timezones)
        });
      this._zones = zones.asObservable();
    }
    return this._zones;
  }
}
