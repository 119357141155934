import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output,} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'action-selector',
  templateUrl: './action-selector.component.html',
  styleUrls: ['./action-selector.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('300ms', style({opacity: 0}))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionSelectorComponent {

  @Input() tabIndex: number | null;
  @Input() disableRipple: boolean;
  @Input() visible: boolean = true;
  @Output() click = new EventEmitter<Event>();

  constructor() {
  }

  @Input()
  get disabled(): boolean {
    //if (this._disabled === undefined && this.datepicker) {
    //  return this.datepicker.disabled;
    //}
    return !!this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
  }
  private _disabled: boolean;

  onClick(event: Event): void {
    if (!this.disabled) {
      event.stopPropagation();
      this.click.emit(event);
    }
  }
}
