import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {map, takeUntil} from "rxjs/operators";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {Logger, Timezone, Timezones} from "core";
import {BasicContainerComponent} from "../../basic-container/basic-container.component";
import {VirtualScrollerComponent} from "../../virtual-scroller/virtual-scroller";
import moment from "moment-timezone";

@Component({
  selector: 'app-timezone-list',
  templateUrl: './timezone-list.component.html',
  styleUrls: ['./timezone-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimezoneListComponent extends BasicContainerComponent {

  @Input()
  set timezones$(timezones: Observable<Timezones>) {
    this.timezonesSubscription?.unsubscribe();
    this.timezonesSubscription = timezones
      ?.pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        this._timezones$.next(value);
        this.logger.debug('UPDATE TIMEZONES', value);
        this.lru = this._lru; // update
      });
  }

  @Input()
  set lru(lru: string[]) {
    this._lruTimezones$.next(this.transformLru(this._lru = lru, this._timezones$.getValue()));
    this.itemsId$.next((this.itemsIdUid++).toString());
  }

  @Output() selectionChange = new EventEmitter<{index: number, timezone: Timezone}>();
  @ContentChild(TemplateRef, { static: true }) controlsTemplate: TemplateRef<any>;

  protected _timezones$ = new BehaviorSubject<Timezones>([]);
  protected _lruTimezones$ = new BehaviorSubject<Timezones>([]);
  protected timezonesSubscription: Subscription;
  protected _allTimezones$: Observable<Timezones>;
  protected _lru: string[];
  itemsId$ = new BehaviorSubject<string>('');
  protected itemsIdUid = 0;

  @ViewChild('scroller', {static: true}) scroller: VirtualScrollerComponent;

  trackTimezone = (index: number, timezone: Timezone) => {
    return timezone.id;
  };

  protected logger = new Logger('TimezoneListComponent').setSilent(true);

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this._allTimezones$ = combineLatest([this.lruTimezones$, this.timezones$]).pipe(
      map(([lru, timezones]) => [].concat(lru, timezones))
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.timezonesSubscription?.unsubscribe();
  }

  onSelectionChange(index: number, timezone: Timezone, event: Event) {
    this.logger.debug('SELECT', index, timezone);
    this.selectionChange.emit({ index, timezone });
  }

  get timezones$(): Observable<Timezones> {
    return this._timezones$.asObservable();
  }

  get lruTimezones$() {
    return this._lruTimezones$.asObservable();
  }

  get allTimezones$(): Observable<Timezones> {
    return this._allTimezones$;
  }

  scrollToPosition(index: number) {
    this.scroller?.scrollToPosition(index, 0);
  }

  transformLru(lru: string[], timezones: Timezones): Timezones {
    const lruTimezones = (lru || []).reduce((result: Timezones, lru: string) => {
      timezones.forEach(({countryCode, zones}) => {
          const timezone = zones.find(timezone => timezone.id == lru);
          if (timezone) {
            result.push({countryCode, zones: [timezone]});
          }
        }
      );
      return result;
    }, [] as Timezones);
    this.logger.debug('transformLru', lruTimezones);
    return lruTimezones;
  }

  context(countryTimezones: { countryCode, zones: Timezone[] }, index: number): { isLru: boolean, multi: boolean, sectionEnd: boolean } {
    const multi = countryTimezones.zones?.length > 1;
    const lruCount = this._lruTimezones$?.value?.length || 0;
    return { isLru: index < lruCount, multi, sectionEnd: index==lruCount-1 };
  }

  timezoneName(timezone: Timezone): string {
    const parts = timezone.id.split('/');
    return `${timezone.name} - ${parts[parts.length - 1].replace('_', ' ')}`;
  }

  timezoneOffset(timezone: string): string {
    const targetOffset  = moment.tz(timezone).utcOffset();
    const currentOffset = 0; //moment.tz(this.currentTimezone).utcOffset();
    let offset = targetOffset - currentOffset;
    const negative = offset < 0;
    if (negative) {
      offset = -1 * offset;
    }
    const hours = Math.floor(offset / 60);
    const minutes = (offset / 60 - hours) * 60;
    // return `(GMT${negative ? '-' : '+'}${this.padLeft(hours.toString(), 2)}:${this.padLeft(minutes.toString(), 2)})`;
    return `(${negative ? '-' : '+'}${this.padLeft(hours.toString(), 2)}:${this.padLeft(minutes.toString(), 2)})`;
  }

  protected padLeft(string: string, width: number, padding = '0'): string {
    padding = padding || ' ';
    padding = padding.substr(0, 1);
    if (string.length < width) {
      return padding.repeat(width - string.length) + string;
    } else {
      return string;
    }
  }
}
