import {select, Selector, Store} from '@ngrx/store';
import {
  initialLayoutState,
  LayoutState,
  selectDetailsState,
  selectNavigationState,
  Sidenav,
  SidenavMode,
  SidenavState
} from "./reducers";

import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {first} from "rxjs/operators";
import {Platform} from "core";

import {
  clearAnimationAction,
  closeSidenavAction,
  ensureSidenavAction,
  openSidenavAction,
  setSidenavContentAction,
  setSidenavModeAction,
  toggleSidenavAction,
  updateSidenavAction
} from "./actions";

export class SidenavHandler {
  constructor(
    protected store$: Store<any>,
    protected selector:Selector<LayoutState, SidenavState>,
    protected sidenav: Sidenav,
    protected initialState: SidenavState,
    protected platform:Platform) {
    if (this.platform.is('phone') && this.initialState.preferredMode!=SidenavMode.OVER) {
      this.initialState.preferredMode=SidenavMode.OVER;
      this.setMode(SidenavMode.OVER,SidenavMode.OVER);
    }
  }

  open(mode?:SidenavMode) : void {
    //console.trace("LAYOUT.OPEN",this,mode);
    this.store$.dispatch(openSidenavAction({sidenav:this.sidenav,mode}));
  }

  close() : void {
    //console.trace("LAYOUT.CLOSE",this);
    this.store$.dispatch(closeSidenavAction({sidenav:this.sidenav}));
  }

  closeIf(predicate: (state: SidenavState) => boolean) : void {
    //console.trace("LAYOUT.CLOSE.IF",this);
    this.store$.dispatch(closeSidenavAction({sidenav:this.sidenav,predicate}));
  }

  ensure(open:boolean) : void {
    //console.trace("LAYOUT.ENSURE",this,open);
    this.store$.dispatch(ensureSidenavAction({sidenav:this.sidenav,open}));
  }

  update(state:SidenavState) : void {
    this.store$.dispatch(updateSidenavAction({sidenav:this.sidenav,state}));
  }

  toggle() : void {
    //console.trace("LAYOUT.TOGGLE",this);
    this.store$.dispatch(toggleSidenavAction({sidenav:this.sidenav}));
  }

  clearAnimation() : void {
    this.store$.dispatch(clearAnimationAction({sidenav:this.sidenav}));
  }

  setMode(mode:SidenavMode|undefined, preferredMode?:SidenavMode|undefined) : void {
    this.store$.dispatch(setSidenavModeAction({sidenav:this.sidenav,mode,preferredMode}));
  }

  setContent(contentType:string, content?:any) : void {
    this.store$.dispatch(setSidenavContentAction({sidenav:this.sidenav,contentType,content}));
  }

  get state$() : Observable<SidenavState> {
    return this.store$.pipe(select(this.selector));
  }

  public getState(callback : (state:SidenavState)=>void) : void {
    this.state$.pipe(first()).subscribe(state => {
      callback(state);
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  protected _navigation : SidenavHandler;
  protected _details : SidenavHandler;

  constructor(protected store : Store<any>, protected platform : Platform) {
    this._navigation = new SidenavHandler(store, selectNavigationState, Sidenav.NAVIGATION, initialLayoutState.navigation, platform);
    this._details    = new SidenavHandler(store, selectDetailsState,    Sidenav.DETAILS,    initialLayoutState.details,    platform);
    /*
     || this.platform.is('phablet')
.then(ok => {
      let route = this.router.routerState.root;
      while (route.firstChild) {
        route = route.firstChild;
      }
      console.debug("DATA "+JSON.stringify(route.snapshot.data));
      this.layoutService.
    });
     */
  }

  get navigation():SidenavHandler {
    return this._navigation;
  }

  get details():SidenavHandler {
    return this._details;
  }
}
