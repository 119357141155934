import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {Observable} from "rxjs";
import {nullSession, Session} from "../../store/models";
import {ENVIRONMENT, prettyDateDiff} from "core";
import {TranslateService} from "@ngx-translate/core";
import {ResizeEvent, VirtualSectionScrollerComponent} from "shared";

@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionListComponent implements OnInit {
  @Input()  cacheId$: Observable<string>;
  @Input()  entities$: Observable<Session[]>;
  @Output() selectionChanged = new EventEmitter<{index: number, session: Session}>();
  @ContentChild(TemplateRef) controlsTemplate: TemplateRef<any>;

  @ViewChild('scroller', {read: VirtualSectionScrollerComponent})
  scrollerComponent: VirtualSectionScrollerComponent;

  trackSession = (index: number, session: Session) => {
    return this.safeSession(session).id;
  };

  constructor(@Inject(nullSession)
              protected nullSession: Session,
              @Inject(ENVIRONMENT)
              protected environment: any,
              protected translateService: TranslateService) {
  }

  ngOnInit() {
  }

  select(index: number, session: Session, event: Event) {
    this.selectionChanged.emit({ index: index, session: session });
  }

  safeSession(session: Session) {
    return session || this.nullSession;
  }

  agent(session: Session): string {
    return session.agentParsed?.AgentName ?? this.translateService.instant('app.name') ?? this.environment.appName;
    // return session.agentParsed?.AgentName
    //   ? this.translateService.instant('app.name')
    //   : this.environment.appName;
    /* Moved fallback code to the server.
      AgentName is already properly resolved to app name when appropriate
    let agent = session.agentParsed?.AgentName ?? '';
    if (agent.length > 0) {
      const lowerAgent = agent.toLocaleLowerCase();
      if (lowerAgent==='chrome webview' || lowerAgent==='applewebkit' || lowerAgent==='uiwebview') {
        agent = 'Realizer App';
      }
    }
    return agent;
    */
  }

  iconSrc(session: Session):{src?:string,icon?:string} {
    let agent = session.agentParsed?.AgentName?.toLocaleLowerCase(); //this.agent(session).toLocaleLowerCase();
    if (['chrome', 'safari', 'opera', 'firefox', 'ie', 'edge'].includes(agent)) {
      return { src: `/assets/icons/browser/${agent}.svg` };
    } else if (session.agentParsed?.DeviceClass) {
      //return `/assets/icons/device/${session.agentParsed.DeviceClass.toLocaleLowerCase()=='phone' ? 'mobile' : 'desktop'}.svg`;
      return { icon: session.agentParsed.DeviceClass.toLocaleLowerCase()=='phone' ? 'phone_android' : 'tv' };
    }
    return {};
  }

  lastActiveTime(session: Session): [number, string] {
    return session.timeUpdated ? prettyDateDiff(session.timeUpdated, new Date()) : undefined;
  }

  lastActiveTimeText(units: number, unitName: string): Observable<string> {
      const key = !this.isCurrentlyActive(units, unitName) ? `moment.past.${unitName}${units > 1 ? 's' : ''}` : 'session.active';
      return this.translateService.get(key, { units: units });
  }

  isCurrentlyActive(units: number, unitName: string): boolean {
    return units < 120 && unitName == 'second';
  }

  onResize(event: ResizeEvent) {
    if (this.scrollerComponent) {
      this.scrollerComponent.refresh();
    }
  }
}
