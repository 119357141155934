/* projects/media/src/lib/components/channel-info/channel-info.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
}
.link-box {
  font-size: 14px;
  padding: 8px;
  margin-bottom: 2px;
  background-color: white;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  max-width: 800px;
  align-items: center;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.link-box .input-label {
  width: 35%;
}
.link-box .input-url {
  width: 65%;
}
.link-box button mat-icon {
  color: var(--color-primary);
}
.link-box:active {
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.link-input {
  padding-right: 24px;
}
.move-icon {
  padding-right: 32px;
  cursor: move;
  color: gray;
}
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.cdk-drag-placeholder {
  opacity: 0;
}
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.content {
  padding: 16px;
  font-size: 14px;
  padding-right: 64px;
  max-width: 1284px;
}
.content .info-header {
  display: block;
  padding: 0;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 6px;
}
.content .input-info {
  width: 100%;
}
.content textarea {
  width: 100%;
}
.content h4:not(:first-child) {
  margin-top: 16px;
}
.content .channel-link {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.content a {
  width: 100%;
  display: block;
}
.content a,
.content a:visited,
.content a:hover,
.content a:active {
  color: inherit;
}
:host-context(.color-scheme-dark) .avatar-large {
  border-color: #181818;
}
:host-context(.color-scheme-dark) .channel-link {
  color: #fff;
}
:host-context(.color-scheme-dark) .channel-link:hover {
  color: var(--color-primary);
}
:host-context(.color-scheme-dark) .content h4 {
  color: #aeafb0;
}
:host-context(.color-scheme-dark) .link-box {
  background-color: #282828;
}
:host-context(.color-scheme-dark) .link-box .cdk-drag-preview {
  background-color: #282828;
}
/*# sourceMappingURL=channel-info.component-WZUDFMAH.css.map */
