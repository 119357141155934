import {Injectable} from "@angular/core";
import {InvitationToken} from "./invitation-token";
import {InvitationTokenImpl} from "./invitation-token-impl";
import {TokenModule} from "../token.module";

@Injectable({
  providedIn: 'root' //TokenModule
})
export class InvitationTokenFactory {

  public createWithToken(token:string) : InvitationToken {
    return new InvitationTokenImpl().createWithToken(token);
  }

  public create(time:number,user:number,info:number,app:number,expiring:boolean) : InvitationToken {
    return new InvitationTokenImpl().create(time, user, info, app, expiring);
  }
  /*
  public create(time:number,user:number,info:number,app:number,expiring:boolean,email:boolean,sms:boolean,whatsapp:boolean) : InvitationToken {
    return new InvitationTokenImpl().create(time, user, info, app, expiring, email, sms, whatsapp);
  }*/
}

/*
console.info(TokenCode.default.charCount());
console.info(TokenCode.default.charOfDigit(0));
console.info(TokenCode.default.isValidToken("ABCDE23456"));
console.info(TokenCode.default.toTokenString(0x1f_afaf_afaf_afaf));
console.info(TokenCode.default.toTokenString(0b11111101011111010111110101111101011111010111110101111));
console.info(TokenCode.default.fromTokenString("9XHPYRUZDX"));
console.info(TokenCode.default.fromTokenString(TokenCode.default.toTokenString(0x1f_afaf_afaf_afaf)).toString(2));
let time = Date.now();
let user = 1;
let hash = InvitationToken.hashCode("Max Mustermann max123@gmail.com 06643044500");
let token1 = InvitationToken.create(time,user,hash,true,false,false,true);
console.info("invitation.token: "+token1.code());
let token2 = InvitationToken.createWithToken(token1.code());
console.info("time1: "+time.toString(16)+" time2: "+token2.time().toString(16));
console.info("user1: "+user.toString(16)+" user2: "+token2.user().toString(16));
console.info("hash1: "+hash.toString(16)+" hash2: "+token2.hash().toString(16));

let factory = new InvitationTokenFactory();
let token3 = factory.create(0x1aaa_faaa_faaa,0x0afa_afaf,0xfafa_fafa,true,false,false,true);
console.debug("token: "+token3.formatted);
*/
