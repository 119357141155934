import {starRatingColor, StarRatingConfigService} from "shared";
import {Injectable} from "@angular/core";

@Injectable()
export class MediaRatingConfigService extends StarRatingConfigService {

  constructor() {
    super();
    //console.debug('MediaRatingConfigService.ctor');
    this.numOfStars = 5;

    this.assetsPath     = 'assets/icons/';  // default is assets/images/
    this.svgPath        = `${this.assetsPath}star-rating.icons.svg`;
    this.svgPathEmpty   = `${this.svgPath}#${this.svgEmptySymbolId}`;
    this.svgPathFilled  = `${this.svgPath}#${this.svgFilledSymbolId}`;
    this.svgPathHalf    = `${this.svgPath}#${this.svgHalfSymbolId}`;

    this.getColor = (rating: number, numOfStars: number, staticColor?: starRatingColor): starRatingColor => {
      if (rating>0) {
        return 'positive';  // use one color instead of negative, ok, positive. see $color-positive-rating in theme.scss
      } else {
        return super.getColor(rating, numOfStars, staticColor);
      }
    };
  }

  // get assetsPath(): string {
  //   return this._assetsPath;
  // }
  //
  // get svgPath() {
  //   return this.assetsPath + 'star-rating.icons.svg';
  // }
}
