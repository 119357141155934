<mat-selection-list class="mat-accent topic-selection-list">
  <ng-container *ngFor="let topic of topics$ | async; let index = index; trackBy: trackByTopic">
    <ng-container *ngIf="topicDisplayInfo(topic) as displayInfo">
      <mat-list-option #topicListItem
                       [value]="topic.id"
                       [disabled]="readOnly"
                       [selected]="isTopicSelected(topic.id)"
                       [checkboxPosition]="'before'"
                       (click)="onTapTopic(topic.id, displayInfo.selectable!=false);"
                       [class.selectable]="displayInfo.selectable!=false"
                       [disableRipple]="topic.type=='survey' || displayInfo.selectable==false"
                       class="{{topic.type}}">
        <ng-container [ngTemplateOutlet]="displayInfo.template || defaultRootTopicTemplate"
                      [ngTemplateOutletContext]="{
                      topic,
                      parent: undefined,
                      index,
                      selected: topicListItem.selected,
                      selectedTags: selectedTopicTags(topic.id),
                      ripple: topic.type=='survey'}">
        </ng-container>
      </mat-list-option>
      <div *ngIf="(!displayInfo.maxLevel || displayInfo.maxLevel >= 1) && !!topic.topics"
           [class.open]="displayInfo.expand==false ? false : displayInfo.expand || topicListItem.selected"
           class="options">
        <ng-container *ngFor="let option of topic.topics; let optionIndex = index;">
          <ng-container *ngIf="topicDisplayInfo(option) as optionDisplayInfo"
            [ngTemplateOutlet]="optionDisplayInfo.template || defaultTopicTemplate"
            [ngTemplateOutletContext]="{ topic: option,
                                         parent: topic,
                                         index,
                                         selected: isOptionSelected(topic.id, option.id),
                                         selectedTags: selectedTopicTags(option.id),
                                         selectable: optionDisplayInfo.selectable!=false}">
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</mat-selection-list>
<ng-template #defaultRootTopicTemplate
             let-topic="topic"
             let-selected="selected"
             let-ripple="ripple">
  <div class="topic-container">
    <div class="topic-interactive" matRipple [matRippleDisabled]="ripple==false">
      <div class="topic">
        <div>
          <div [innerHTML]="topic.label | translate | safeHtml"></div>
        </div>
        <ng-container *ngIf="selected"
                      [ngTemplateOutlet]="actionsTemplate"
                      [ngTemplateOutletContext]="{topic}">
        </ng-container>
      </div>
      <button *ngIf="(help(topic) | async); let help"
              mat-icon-button
              color="accent"
              class="help"
              (click)="showHelp(help, $event)">
        <mat-icon>help</mat-icon>
      </button>
    </div>
    <ng-container [ngTemplateOutlet]="badgeTemplate"
                  [ngTemplateOutletContext]="{topic}">
    </ng-container>
  </div>
</ng-template>
<ng-template #defaultTopicTemplate
             let-topic="topic"
             let-parent="parent"
             let-index="index"
             let-selected="selected"
             let-selectedTags="selectedTags"
             let-selectable="selectable">
  <div [attr.data-index]="index"
       [class.selected]="selected"
       class="option"
       (click)="onTapOption(parent.id, topic.id, selectable)">
    {{topic.label | translate}}
  </div>
</ng-template>

