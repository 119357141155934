/* projects/media/src/lib/components/media-viewer/containers/media-slider/media-slider.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  display: flex;
}
.container {
  flex: 1;
  position: relative;
}
.container .swiper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.container .swiper ::ng-deep .swiper-button-prev,
.container .swiper ::ng-deep .swiper-button-next {
  color: var(--color-primary-300);
}
.container .actions-container {
  position: absolute;
  bottom: 0;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  left: 0;
  right: 0;
  height: 3em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  z-index: 1;
  overflow-x: auto;
}
.container .actions-container .media-actions {
  display: flex;
  align-items: center;
  height: 100%;
}
.container .actions-container .action {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  line-height: 36px;
}
.container .actions-container .action ::ng-deep button {
  box-sizing: content-box;
  width: auto;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  padding: 0;
  border-radius: 4px;
  border: 2px solid transparent;
}
.container .actions-container .action ::ng-deep button.mdc-icon-button {
  color: var(--color-primaryContrast-500);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.container .actions-container .action ::ng-deep button.mdc-icon-button.mat-mdc-button-disabled {
  pointer-events: none;
  color: #9e9e9e;
}
.container .actions-container .action ::ng-deep button.mdc-icon-button mat-icon {
  position: relative;
  width: 32px;
  height: 32px;
  font-size: 32px;
  line-height: normal;
  color: rgba(43, 51, 63, 0.7);
}
.container .actions-container .action ::ng-deep button.mdc-icon-button.dual {
  background-color: var(--color-primaryContrast-500);
  color: #333;
  padding: 0 8px 0 4px;
  margin: 0 2px;
}
.container .actions-container .action ::ng-deep button.mdc-icon-button.dual .mat-mdc-button-touch-target {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: none;
}
.container .actions-container .action ::ng-deep button.mdc-icon-button.dual mat-icon {
  font-size: 32px;
  width: 32px;
  height: 32px;
  vertical-align: middle;
}
.container .actions-container .action ::ng-deep button.mdc-icon-button.dual span {
  color: #333;
  font-weight: 500;
  font-family:
    Roboto,
    "Helvetica Neue",
    sans-serif;
  margin-left: 4px;
}
.container .actions-container .action ::ng-deep button.mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0;
}
.container .actions-container .action ::ng-deep button:not(.mat-icon-button) {
  margin: 0 2px;
  padding: 0 4px;
  font-weight: 500;
  font-family:
    Roboto,
    "Helvetica Neue",
    sans-serif;
  color: #333;
  background-color: var(--color-primaryContrast-500);
}
.container .actions-container .action ::ng-deep button.mat-mdc-raised-button {
  margin: 0 3px;
}
.container .actions-container .action:hover ::ng-deep button {
  border-color: var(--color-primaryContrast-800);
}
.container .actions-container .action.disabled {
  cursor: pointer;
  pointer-events: none;
  color: #9e9e9e;
}
.container .actions-container .action > .image {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  padding: 2px;
  transform: translateX(2px);
  margin-right: -12px;
  background-color: var(--color-primaryContrast-500);
  z-index: 1;
}
.container .actions-container .action > .image img {
  width: 40px;
  height: 40px;
}
.container .actions-container .action > .image + button {
  padding-left: 12px;
}
.container .actions-container:not(.video) {
  background-color: rgba(43, 51, 63, 0.7);
}
.media-review-menu {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 250px;
}
.media-review-menu > div.media-review-actions {
  margin-top: 16px;
  align-self: flex-end;
}
.media-review-menu > div.media-review-actions > *:not(:last-child) {
  margin-right: 10px;
}
/*# sourceMappingURL=media-slider.component-AGFDGU2A.css.map */
