import {BehaviorSubject} from "rxjs";

export const PLATFORMS_MAP = {
  'ipad': isIpad,
  'iphone': isIphone,
  'ios': isIOS,
  'android': isAndroid,
  'phablet': isPhablet,
  'tablet': isTablet,
  'phone': isPhone,
  'cordova': isCordova,
  'capacitor': isCapacitorNative,
  'electron': isElectron,
  'pwa': isPWA,
  'mobile': isMobile,
  'desktop': isDesktop,
  'hybrid': isHybrid,
  'macos': isMacOs
};

export type Platforms = keyof typeof PLATFORMS_MAP;

export function getPlatforms(win: any) {
  return setupPlatforms(win);
}

export function isPlatform(win: Window, platform: Platforms) {
  return getPlatforms(win).includes(platform);
}

export function setupPlatforms(win: any) {
  win.Ionic = win.Ionic || {};
  let platforms: string[] | undefined | null = win.Ionic.platforms;
  if (platforms == null) {
    platforms = win.Ionic.platforms = detectPlatforms(win);
    const classList = win.document.documentElement.classList;
    platforms.forEach(p => classList.add(`plt-${p}`));
    setThemeModeClass(getPreferredThemeMode());
    // https://stackoverflow.com/questions/56466261/matchmedia-addlistener-marked-as-deprecated-addeventlistener-equivalent
    const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const darkMediaListener = event => {
      if (!getPreferredThemeMode(false)) {
        setThemeModeClass(event.matches ? 'dark' : 'light');
      }
    };
    try { // Chrome & Firefox
      darkMediaQuery.addEventListener('change', darkMediaListener);
    } catch (e1) {
      try { // Safari
        darkMediaQuery.addListener(darkMediaListener);
      } catch (e2) {
        console.error(e2);
      }
    }
  }
  return platforms;
}

export function isBrowser(win: Window, browser: 'safari' | 'chrome' | 'firefox' | 'opera'): boolean {
  const userAgent = win.navigator.userAgent;
  if (userAgent) {
    if (browser=='safari') {
      return (isIOS(win) || isMacOs(win)) &&
             userAgent.indexOf('Safari') != -1 &&
             userAgent.indexOf('Chrome') == -1;
    } else if (browser) {
        return userAgent.toLocaleLowerCase().indexOf(browser) != -1;
    }
  }
  return false;
}

export const ThemeColorNames = ['primary','primaryContrast','accent','accentContrast'];
export interface ThemeColors {
  pure:boolean;         // light/dark pure mode is mostly white (light), mostly black (dark)
  primary:string;
  primaryContrast:string,
  accent:string;
  accentContrast:string,
  entry: {
    button:string,
    buttonContrast:string,
    buttonDisabled:string,
    link:string,
    linkHover:string
  }
}
export const ThemeModeNames = ['light','dark'];
export type  ThemeMode  = 'light'|'dark';
export const themeMode$ = new BehaviorSubject(getPreferredThemeMode());
export const pureMode$  = new BehaviorSubject(isThemeModePure());

export function setPreferredThemeMode(mode:ThemeMode|null|undefined) {
  let known = !!mode && ThemeModeNames.includes(mode);
  if (known) {
    localStorage.setItem('preferred-theme-mode', mode);
  } else {
    localStorage.removeItem('preferred-theme-mode');
    mode = getPreferredThemeMode();
  }
  setThemeModeClass(mode);
}

export function isThemeModePure():boolean {
  return window.document.documentElement.classList.contains('pure');
}
export function setThemeModePure(pure:boolean) {
  if (pureMode$.value!=pure) {
    pureMode$.next(pure);
  }
  if (pure) {
    window.document.documentElement.classList.add('pure');
  } else {
    window.document.documentElement.classList.remove('pure');
  }
}

export function getPreferredThemeMode(returnCurrentIfUndefined:boolean=true): ThemeMode {
  let mode = localStorage.getItem('preferred-theme-mode');
  return ThemeModeNames.includes(mode) ? <ThemeMode>mode : returnCurrentIfUndefined ? <ThemeMode>getCurrentThemeMode() : undefined;
}

export function getCurrentThemeMode(): ThemeMode {
  if (window.matchMedia('(prefers-color-scheme)').media !== 'not all' &&
      window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise set to light.
    return 'dark';
  }
  // If the browser doesn't support prefers-color-scheme, set it as default to light
  //return 'light';
  return 'light';
}

function setThemeModeClass(mode:ThemeMode) {
  mode = !!mode && ThemeModeNames.includes(mode) ? mode : getCurrentThemeMode();
  if (mode!=themeMode$.value) {
    themeMode$.next(mode);
  }
  const classList = window.document.documentElement.classList;
  ThemeModeNames.forEach(m => {
    if (m==mode) {
      classList.add('color-scheme-'+m);
    } else {
      classList.remove('color-scheme-'+m);
    }
  });
}

function detectPlatforms(win: Window): string[] {
  return Object.keys(PLATFORMS_MAP).filter(p => (PLATFORMS_MAP as any)[p](win));
}

function isIpad(win: Window) {
  return testUserAgent(win, /iPad/i) || device.ipad();
}

function isIphone(win: Window) {
  return testUserAgent(win, /iPhone/i);
}

function isIOS(win: Window) {
  return testUserAgent(win, /iPad|iPhone|iPod/i) || device.ios();
}

function isAndroid(win: Window) {
  return testUserAgent(win, /android|sink/i);
}

function isPhablet(win: Window) {
  const width = win.innerWidth;
  const height = win.innerHeight;
  const smallest = Math.min(width, height);
  const largest = Math.max(width, height);

  return (smallest > 390 && smallest < 520) &&
    (largest > 620 && largest < 800);
}

function isPhone(win: Window) {
  return isMobile(win) && !isTablet(win);
}

function isTablet(win: Window) {
  const width = win.innerWidth;
  const height = win.innerHeight;
  const smallest = Math.min(width, height);
  const largest = Math.max(width, height);
  return (smallest > 460 && smallest <= 1200) &&
    (largest > 780 && largest <= 1600);
  //return (smallest > 460 && smallest < 820) &&
  //  (largest > 780 && largest < 1400);
}

function isMobile(win: Window) {
  return matchMedia(win, '(any-pointer:coarse)') && !device.desktop();
}

function isDesktop(win: Window) {
  return !isMobile(win) && device.desktop();
}

function isHybrid(win: Window) {
  return isCordova(win) || isCapacitorNative(win);
}

function isCordova(window: Window): boolean {
  const win = window as any;
  return !!(win['cordova'] || win['phonegap'] || win['PhoneGap']);
}

function isCapacitorNative(window: Window): boolean {
  const win = window as any;
  const capacitor = win['Capacitor'];
  return !!(capacitor && capacitor.isNative);
}

function isElectron(win: Window): boolean {
  return testUserAgent(win, /electron/);
}

function isPWA(win: Window): boolean {
  return win.matchMedia('(display-mode: standalone)').matches;
}

function isMacOs(win: Window): boolean {
  return device.desktop();
}

function testUserAgent(win: Window, expr: RegExp) {
  return expr.test(win.navigator.userAgent);
}

function matchMedia(win: Window, query: string): boolean {
  return win.matchMedia(query).matches;
}

/***
 *
 * CURRENT-DEVICE START
 * https://github.com/matthewhudson/current-device/blob/master/src/index.js
 *
***/

const device = {} as any;
const userAgent = window.navigator.userAgent.toLowerCase();

// Detectable television devices.
const television = [
  'googletv',
  'viera',
  'smarttv',
  'internet.tv',
  'netcast',
  'nettv',
  'appletv',
  'boxee',
  'kylo',
  'roku',
  'dlnadoc',
  'pov_tv',
  'hbbtv',
  'ce-html'
];

// Main functions
// --------------

device.macos = function() {
  return find('mac')
};

device.ios = function() {
  return device.iphone() || device.ipod() || device.ipad()
};

device.iphone = function() {
  return !device.windows() && find('iphone')
};

device.ipod = function() {
  return find('ipod')
};

device.ipad = function() {
  return find('ipad')
    || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
};

device.android = function() {
  return !device.windows() && find('android')
};

device.androidPhone = function() {
  return device.android() && find('mobile')
};

device.androidTablet = function() {
  return device.android() && !find('mobile')
};

device.blackberry = function() {
  return find('blackberry') || find('bb10') || find('rim')
};

device.blackberryPhone = function() {
  return device.blackberry() && !find('tablet')
};

device.blackberryTablet = function() {
  return device.blackberry() && find('tablet')
};

device.windows = function() {
  return find('windows')
};

device.windowsPhone = function() {
  return device.windows() && find('phone')
};

device.windowsTablet = function() {
  return device.windows() && (find('touch') && !device.windowsPhone())
};

device.fxos = function() {
  return (find('(mobile') || find('(tablet')) && find(' rv:')
};

device.fxosPhone = function() {
  return device.fxos() && find('mobile')
};

device.fxosTablet = function() {
  return device.fxos() && find('tablet')
};

device.meego = function() {
  return find('meego')
};

device.cordova = function() {
  return window['cordova'] && location.protocol === 'file:'
};

device.nodeWebkit = function() {
  return typeof (window as any).process === 'object'
};

device.mobile = function() {
  return (
    device.androidPhone() ||
    device.iphone() ||
    device.ipod() ||
    device.windowsPhone() ||
    device.blackberryPhone() ||
    device.fxosPhone() ||
    device.meego()
  )
};

device.tablet = function() {
  return (
    device.ipad() ||
    device.androidTablet() ||
    device.blackberryTablet() ||
    device.windowsTablet() ||
    device.fxosTablet()
  )
};

device.desktop = function() {
  return !device.tablet() && !device.mobile()
};

device.television = function() {
  let i = 0;
  while (i < television.length) {
    if (find(television[i])) {
      return true
    }
    i++
  }
  return false
};

device.portrait = function() {
  if (
    screen.orientation &&
    Object.prototype.hasOwnProperty.call(window, 'onorientationchange')
  ) {
    return includes(screen.orientation.type, 'portrait')
  }
  return window.innerHeight / window.innerWidth > 1
};

device.landscape = function() {
  if (
    screen.orientation &&
    Object.prototype.hasOwnProperty.call(window, 'onorientationchange')
  ) {
    return includes(screen.orientation.type, 'landscape')
  }
  return window.innerHeight / window.innerWidth < 1
};

// Private Utility Functions
// -------------------------

// Check if element exists
function includes(haystack: string, needle: string) {
  return haystack.indexOf(needle) !== -1
}

// Simple UA string search
function find(needle: string) {
  return includes(userAgent, needle);
}

/*** CURRENT-DEVICE END ***/

/*
 * The document.execCommand() method's "cut" and "copy" commands can be used to replace the clipboard's current contents
 * with the selected material. These commands can be used without any special permission if you are using them in a
 * short-lived event handler for a user action (for example, a click handler).
 * https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
 *
 * ios solution:
 * https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
 */
export function clipboardCopy(text: string) {
    let element = document.createElement('textarea');
    element.style.position = 'fixed';
    element.style.left     = '0';
    element.style.top      = '0';
    element.style.opacity  = '0';
    element.value = text;
    document.body.appendChild(element);
    if (isIOS(window)) {
      let range = document.createRange();
      element.contentEditable = 'true';
      element.readOnly = false;
      range.selectNodeContents(element);
      let selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      element.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
    } else {
      element.focus();
      element.select();
  }
  document.execCommand('copy');
  document.body.removeChild(element);
}
