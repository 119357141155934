import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TimezoneService} from '../../../services/timezone.service';
import moment from "moment-timezone";
import {CountryPipe} from "../../../pipes/country.pipe";
import {BehaviorSubject, combineLatest, Observable, Subscription} from "rxjs";
import {Logger, Timezone, Timezones} from "core";
import {BasicContainerComponent} from "../../basic-container/basic-container.component";
import {TimezoneListComponent} from "../timezone-list/timezone-list.component";
import {distinctUntilChanged, map, scan, shareReplay, switchMap, takeUntil} from "rxjs/operators";
import isEqual from "lodash/isEqual";

@Component({
  selector: 'timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.scss'],
  providers: [ CountryPipe ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimezoneSelectorComponent extends BasicContainerComponent {

  // @Input('timezones$')
  // set allTimezones$(timezones: Observable<Timezones>) {
  //   this.logger.debug('UPDATE TIMEZONES', timezones);
  //   this.timezonesSubscription?.unsubscribe();
  //   this.timezonesSubscription = timezones
  //     ?.pipe(takeUntil(this.onDestroy$))
  //     .subscribe(value => {
  //       this.logger.debug('UPDATE TIMEZONES', value);
  //       this._allTimezones$.next(value);
  //       this.itemsId$.next((this.itemsIdUid++).toString());
  //     });
  // }
  //
  // get allTimezones$(): Observable<Timezones> {
  //   return this._allTimezones$;
  // }

  @Input('timezones$') allTimezones$: Observable<Timezones>;
  @Input('lru$') lruTimezones$: Observable<string[]>;
  @Input() searchTerm$: Observable<string>;
  @Input() set currentTimezone(currentTimezone: string) {
    if (this._currentTimezone!=currentTimezone) {
      // react on timezone change to update lru list - fist items should be always current timezone
      this._currentTimezone = currentTimezone;
      let next = this.lru$.getValue().slice();
      next = next.reduce((result, timezone, index) => {
        if (index == 0 && timezone==currentTimezone ||
          index > 0  && timezone!=currentTimezone) {
          result.push(timezone);
        }
        return result;
      }, []);
      if (next.length == 0 || next[0]!=currentTimezone) {
        next.unshift(currentTimezone);
      }
      this.logger.debug('CURRENT TIMEZONE CHANGE. NEXT LRU', next);
      this.lru$.next(next);
    }
  }

  @Output() selectionChange = new EventEmitter<Timezone>();
  @Output() lruChange       = new EventEmitter<string[]>();

  @ViewChild(TimezoneListComponent, {static: true}) timezoneList: TimezoneListComponent;

  timezones$ = new Observable<Timezones>(undefined);
  lru$ = new BehaviorSubject<string[]>([]); // last recently used timezones
  // itemsId$ = new BehaviorSubject<string>('');

  protected _allTimezones$ = new BehaviorSubject<Timezones>([]);
  protected _currentTimezone: string = moment.tz.guess();
  protected timezonesSubscription: Subscription;
  protected itemsIdUid = 0;

  protected logger = new Logger('TimezoneSelectorComponent').setSilent(false);

  trackTimezone = (index: number, timezone: string) => {
    return timezone;
  };

  constructor(protected timezoneService: TimezoneService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.allTimezones$) {
      this.allTimezones$ = this.timezoneService.zones();
    }

    // observable emitting timezones filtered by current search term
    this.timezones$ = this.allTimezones$;
    // TODO: Transfer the filtering code from EventDetailComponent and supply searchTerm$ property in template
    // combineLatest([
    //   this.allTimezones$,
    //   this.searchTerm$
    // ])
    //   .pipe(
    //     takeUntil(this.onDestroy$),
    //     map(([timezones, term]: [Timezones, string]) => {
    //       const result: Timezones = [];
    //       if (timezones) {
    //         timezones.forEach(({countryCode, zones}) => {
    //           const matches = zones.filter(zone => zone.name && (!term || zone.name.toLowerCase().startsWith(term.toLowerCase())));
    //           if (matches.length) {
    //             result.push({countryCode, zones: matches});
    //           }
    //         });
    //       }
    //       return result;
    //     }),
    //   );

    const lruTimezones$ = this.lruTimezones$.pipe(
      takeUntil(this.onDestroy$),
      // startWith(this.lru$.getValue()),
      distinctUntilChanged((previous, current) => !isEqual(previous, current)),
      shareReplay(1)
    );

    // listen for new lru values from input and reflect the changes
    lruTimezones$.subscribe(lru => {
      const currentTimezone = this.currentTimezone;
      if (!lru.length || lru[0] != currentTimezone) {
        lru.unshift(currentTimezone);  // add current timezone
      }
      this.logger.debug('INPUT LRU CHANGE. NEXT LRU', lru);
      this.lru$.next(lru);
    });

    // track lru changes to detect dirty state
    const lru$ = this.lru$.pipe(
      scan((state: { initial: string[], next: string[] }, value: string[]) => {
        // console.debug(value, form.pristine);
        if (value) {
          state = !state.initial
            ? { initial: value, next: value }
            : {...state, ...{ next: value }};
        }
        return state;
      }, { initial: undefined, next: undefined })
    );

    lruTimezones$
      .pipe(switchMap(() => lru$))      // ensure lru$ state is reset on each lruTimezones$ emission
      .subscribe((state) => {
        // when lru list is changed -> emit event
        const { initial, next } = {...state};
        // remove current timezone when it was not in the previous lru
        if (initial.indexOf(this.currentTimezone) == -1) {
          next.slice.apply(next, [1]);
        }
        if (!isEqual(initial, next)) {
          this.lruChange.emit(next);
        }
      });
  }

  onSelectionChange(event: {index: number; timezone: Timezone}) {
    this.updateTimezoneLru(event.timezone, true);
    this.selectionChange.emit(event.timezone);
  }

  onRemoveLru(index, timezone, $event: MouseEvent) {
    this.logger.debug('onTimezoneRemoveLru', timezone);
    this.updateTimezoneLru(timezone, false);
    // prevent firing of selection event when 'x'  button is clicked
    // this was possible to be done directly in timezone-list component
    // but maybe because of event routing changes introduced in newer versions of angular
    // the event handlers defined by the parent element/component are invoked
    // before the handlers registered by the child element
    $event.preventDefault();
    $event.stopPropagation();
  }

  updateTimezoneLru(timezone: Timezone, insert: boolean, lru = this.lru$.getValue()) {
    // Current timezone is always fixed at the first position in lru list. it cannot be added or deleted directly by the user.
    // Updates of this timezone are handled as a reaction to changes of currentTimezone input.
    if (timezone.id==this.currentTimezone) {
      return;
    }
    const   next = [...lru];
    const update = (timezone, insert) => {
      const index = next.indexOf(timezone);
      if (index > 0) {  // first one is always current timezone
        next.splice(index, 1);
      }
      insert && next.splice(1, 0, timezone);
    };
    update(timezone?.id, insert);
    if (lru.length!=next.length ||
      !lru.every(timezone => next.includes(timezone))) {
      this.lru$.next(next);
    }
  }

  get currentTimezone(): string {
    return this._currentTimezone;
  }

  scrollToPosition(index: number) {
    this.timezoneList?.scrollToPosition(index);
  }
}
