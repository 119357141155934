import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import {Subject} from "rxjs";

export interface OnRouterOutletAttach {
  ngOnRouterOutletAttach(): void;
}

@Component({
  selector: 'basic',
  template: ``,
})
export class BasicComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges, OnRouterOutletAttach {

  private static instanceCounter: number = 0;
  protected instanceId: number = BasicComponent.instanceCounter++;

  protected onDestroy$ = new Subject<void>();
  protected changeTriggerRaf:number;
  protected changeTriggers:SimpleChanges = {};
  protected viewInitialized = false;
  protected logged = false;

  @Input()
  public set log(value:boolean) {
    this.logged = value;
  }
  public get log():boolean {
    return this.logged;
  }

  constructor() {
  }

  ngOnInit() {
    // console.debug("INIT."+this.constructor.name+"."+this.instanceId);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    this.viewInitialized = true;
    //this.triggerChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnRouterOutletAttach():void {
  }

  triggerChanges(): void {
    if (!this.changeTriggerRaf && Object.keys(this.changeTriggers).length>0) {
      this.changeTriggerRaf = requestAnimationFrame(()=> {
        let  changeTriggers = this.changeTriggers;
        this.changeTriggers = {};
        this.changeTriggerRaf = undefined;
        Object.keys(changeTriggers).forEach(key=>{
          this[key] = changeTriggers[key].currentValue;
        });
        this.ngOnChanges(changeTriggers);
      });
    }
  }
  triggerChange(property:string,event:SimpleChange): void {
    const previous = this.changeTriggers[property];
    if (!!previous) {
      event.previousValue = previous.previousValue;
      event.firstChange   = previous.firstChange;
    }
    this.changeTriggers[property] = event;
    if (this.changeTriggerRaf) {
      cancelAnimationFrame(this.changeTriggerRaf);
      this.changeTriggerRaf = undefined;
    }
    this.changeTriggerRaf = requestAnimationFrame(()=> {
      let  changeTriggers = this.changeTriggers;
      this.changeTriggers = {};
      this.changeTriggerRaf = undefined;
      Object.keys(changeTriggers).forEach(key=>{
        this[key] = changeTriggers[key].currentValue;
      });
      //if (this.log) console.log("onViewportUpdated.TRIGGER",changeTriggers);
      this.ngOnChanges(changeTriggers);
    });
  }

  mergeChanges(changes:SimpleChanges,clear=true):SimpleChanges {
    const result:SimpleChanges = !!changes ? { ...this.changeTriggers, ...changes } : this.changeTriggers;
    if (this.changeTriggerRaf) {
      cancelAnimationFrame(this.changeTriggerRaf);
      this.changeTriggerRaf = undefined;
    }
    this.changeTriggers = clear ? {} : result;
    return result;
  }
}
