<ng-container *ngFor="let star of stars | async; trackBy: trackByStar; let index = index"
              [ngTemplateOutlet]="starTemplate"
              [ngTemplateOutletContext]="starContext(star, index)">
</ng-container>

<ng-template #starTemplate let-star="star" let-fragment="fragment" let-cssClass="cssClass">
  <div [ngClass]="cssClass">
    <ng-container *ngTemplateOutlet="svg"></ng-container>
    <div *ngIf="fragment" class="star" [style.width.%]="100 * star">
      <ng-container *ngTemplateOutlet="svg"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #svg>
  <!--
    Extracted from SVG sprite (assets/icons/star-rating.icons.svg) used by star-rating component.
    Referencing this svg with <svg>><use ... [attr.xlink:href]="..."></use></svg> (see starTemplate3 below)
    causes occasional flickering on rating change.
  -->
  <svg viewBox="0 0 34 32">
    <path class="path-star-filled"
          d="M16.941 25.621l10.179 6.144-2.701-11.579 8.993-7.791-11.842-1.005-4.628-10.92-4.628 10.92-11.842 1.005 8.993 7.791-2.701 11.579z"/>
  </svg>
</ng-template>

<!--
<ng-template #starTemplate2 let-star="star" let-fragment="fragment" let-cssClass="cssClass">
  <div [ngClass]="cssClass">
    <div *ngIf="fragment" class="star" [style.width.%]="100 * star"></div>
  </div>
</ng-template>

<ng-template #starTemplate3 let-star="star" let-fragment="fragment" let-cssClass="cssClass">
  <div [ngClass]="cssClass">
    <svg><use xmlns:xlink="http://www.w3.org/1999/xlink" [attr.xlink:href]="starSvgPath"></use></svg>
    <div *ngIf="fragment" class="star" [style.width.%]="100 * star">
      <svg><use xmlns:xlink="http://www.w3.org/1999/xlink" [attr.xlink:href]="starSvgPath"></use></svg>
    </div>
  </div>
</ng-template>
-->


