/* projects/group/src/lib/components/group-logo/group-logo.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  position: relative;
}
div.frame {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: height 500ms ease;
}
div.frame div {
  width: 100%;
  transition: opacity 500ms ease;
}
div.frame div.logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
div.frame div.logo.foreground {
  position: relative;
  z-index: 1;
}
div.frame div.logo div {
  width: 100%;
  position: relative;
}
div.frame div.logo div.video {
  transition: opacity 500ms ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
div.frame div.logo div.video div {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
div.frame div.logo div.video div div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
div.frame div.logo div.video div div div {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
div.frame div.logo div.video.elevated {
  padding: 0.5em;
}
div.frame div.logo div.video.elevated div > div {
  left: -1px;
  top: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}
div.frame div.logo div.video.rounded > div {
  border-radius: 0.3em;
  transform: translate3d(0, 0, 0);
}
media-card {
  opacity: 0.2;
}
div.placeholder {
  position: absolute;
}
div.placeholder div {
  padding-top: 56.25%;
}
div.placeholder.show {
  position: relative;
}
/*# sourceMappingURL=group-logo.component-GITV2X2G.css.map */
