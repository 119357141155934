/* projects/media/src/lib/containers/media-details-container/media-details-container.component.scss */
:host {
  display: flex;
  flex-direction: column;
  height: 100%;
}
mat-toolbar.top {
  flex: none;
  padding: 0 4px;
}
mat-toolbar.top span {
  flex: 1 1 auto;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}
mat-toolbar.top .mat-mdc-menu-item {
  font-size: 1em;
}
mat-toolbar.top.main {
  padding-left: 16px;
}
slide-panel {
  flex: 1;
}
slide-panel .panel {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
slide-panel .panel.media-details {
  flex: 1;
}
slide-panel .panel.selectors .selector {
  flex: 1;
  position: relative;
  overflow: hidden;
}
slide-panel .panel.selectors .selector + button {
  margin: 10px 5px 5px;
}
.mat-toolbar.mat-primary.buttonBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mat-toolbar.mat-primary.buttonBar > button {
  margin-left: 8px;
}
:host-context(.color-scheme-light.pure) mat-toolbar.top {
  border-bottom: none;
}
:host-context(.color-scheme-light.pure) mat-toolbar.mat-primary.buttonBar {
  border-top: 1px solid #ddd;
}
:host-context(.color-scheme-light.pure) slide-panel .panel.selectors {
  border-top: 1px solid #ddd;
}
:host-context(.color-scheme-dark) .header,
:host-context(.color-scheme-dark) .media-id {
  color: #AEAFB0;
}
:host-context(.color-scheme-dark) mat-toolbar.top {
  border-bottom: 0;
}
:host-context(.color-scheme-dark) .mat-toolbar.mat-primary.buttonBar {
  border-top: 0;
}
/*# sourceMappingURL=media-details-container.component-GIFCFDEW.css.map */
