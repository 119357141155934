import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Group} from "properties";
import {ImageLink, Media, MediaType, VideoComponent} from "media";
import {ENVIRONMENT, Logger} from "core";
import {BehaviorSubject} from "rxjs";
import {BasicContainerComponent} from "shared";
import {distinctUntilChanged, takeUntil} from "rxjs/operators";
import {CRC16} from "auth";

export class LogoState {
  public static hidden          = new LogoState();
  public static visibleRelative = new LogoState();
  public static visibleAbsolute = new LogoState();
  public isHidden() {
    return this===LogoState.hidden;
  }
  public isVisible() {
    return !this.isHidden();
  }
  public isVisibleRelative() {
    return this===LogoState.visibleRelative;
  }
  public isVisibleAbsolute() {
    return this===LogoState.visibleAbsolute;
  }
}

class MediaWrapper {
  public STYLES_VISIBLE = "opacity:1;";
  public STYLES_HIDDEN  = "opacity:0;";

  public image$       = new BehaviorSubject<Media>(undefined);
  public imageStyle$  = new BehaviorSubject<string>(this.STYLES_HIDDEN);
  public video$       = new BehaviorSubject<Media>(undefined);
  public videoStyle$  = new BehaviorSubject<string>(this.STYLES_HIDDEN);

  public style$       = new BehaviorSubject<string>(this.STYLES_HIDDEN);

  public player:VideoComponent = undefined;
  public animated = true;
  public log:boolean = false;
  public other:MediaWrapper = undefined;
  public current:boolean = false;
  public logoElement: ElementRef;
  public frameElement: ElementRef;

  protected logger = new Logger('MediaWrapper').setSilent(true);

  constructor(public instanceId:number, public index:number) {
  }

  set media(media:Media) {
    let  changed = media.id != this.image$.value?.id;
    let  video   = media?.mediaType=='video';
    let  videoLoaded = !changed && this.isVideoLoaded();
    let  imageLoaded = !changed && this.isImageLoaded();
    if (this.log) console.log(this.instanceId,"GroupLogoComponent.MediaWrapper.media",this.index,"current",this.current,"id",media.id,this.image$.value?.id,"changed",changed,"imageLoaded",this.isImageLoaded(),"videoLoaded",this.isVideoLoaded());
    this.current = true;
    this.other.current = false;
    this.video$.next(video ? media : undefined);
    this.image$.next(media);
    if (changed) {
      this.style$.next(this.STYLES_HIDDEN);
      this.imageStyle$.next(this.STYLES_HIDDEN);
      this.videoStyle$.next(this.STYLES_HIDDEN);
    } else {
      this.style$.next(this.STYLES_HIDDEN);
      this.imageStyle$.next(this.STYLES_HIDDEN);
      this.videoStyle$.next(this.STYLES_HIDDEN);
      window.requestAnimationFrame(()=> {
        if (videoLoaded && media.id==this.video$.value?.id) {
          this.onVideoLoaded(this.image$.value,this.player);
        }
        if (imageLoaded && media.id==this.image$.value?.id) {
          this.onImageLoaded(this.image$.value);
        }
      });
    }
  }

  onImageLoaded(media:Partial<Media>) {
    if (this.log) {
      this.logger.debug("IMAGE_LOADED", media?.id);
    }
    this.imageStyle$.next(this.STYLES_VISIBLE);
    this.other.player?.pause();
    window.requestAnimationFrame(()=> {
      this.triggerSwitch();
    });
  }

  onMetadataLoaded(media:Partial<Media>, player?:VideoComponent) {
    if (this.log) {
      this.logger.debug("METADATA_LOADED", media?.id);
    }
    this.player = player;
    this.videoStyle$.next(this.STYLES_VISIBLE);
    this.other.player?.pause();
    this.triggerSwitch();
    window.requestAnimationFrame(()=> {
      this.animate(this.animated);
    });
  }

  onVideoLoaded(media:Partial<Media>, player?:VideoComponent) {
    if (this.log) {
      this.logger.debug("VIDEO_LOADED", media?.id);
    }
    //this.player = player;
    //this.videoStyle$.next(this.STYLES_VISIBLE);
    //this.other.player?.pause();
    //this.triggerSwitch();
    window.requestAnimationFrame(()=> {
      this.animate(this.animated);
    });
  }

  onVideoPlaying(media:Partial<Media>, play:boolean, player?:VideoComponent) {
    if (this.log) {
      this.logger.debug("VIDEO_PLAY", media?.id, play);
    }
    /*
    this.playingX++;
    this.playing = play;
    if (!play) {
      //this.animate(true);
    }*/
  }

  //public playingX = 0;
  //public playing = false;

  triggerSwitch() {
    if (this.log) console.log(this.instanceId,"GroupLogoComponent.MediaWrapper.triggerSwitch",this.index,"current",this.current);
    if ((!this.image$.value || this.isImageLoaded()) &&
        (!this.video$.value || this.isVideoLoaded())) {
      this.style$.next(this.STYLES_VISIBLE);
      this.other.hide();
      if (this.log) console.log(this.instanceId,"GroupLogoComponent.MediaWrapper.triggerSwitch:2",this.index,"current",this.current);
      if (this.frameElement?.nativeElement.clientWidth>0 &&
          this.frameElement.nativeElement?.style) {
        this.frameElement.nativeElement.style.height = this.logoElement.nativeElement.offsetHeight+'px';
        if (this.log) console.log(this.instanceId,"GroupLogoComponent.MediaWrapper.triggerSwitch:3",this.index,"current",this.current,this.frameElement.nativeElement.style.height,this.frameElement);
      }
    }
  }

  hide() {
    this.style$.next(this.STYLES_HIDDEN);
  }

  isImageLoaded():boolean {
    return this.imageStyle$.value!=this.STYLES_HIDDEN;
  }

  isVideoLoaded():boolean {
    return this.videoStyle$.value!=this.STYLES_HIDDEN;
  }

  animate(animate:boolean) {
    this.animated = animate;
    if (this.current && animate) {
      this.player?.currentTime(0);
      this.player?.play(true);
    } else {
      this.player?.pause();
    }
  }
}

@Component({
  selector: 'group-logo',
  templateUrl: './group-logo.component.html',
  styleUrls: ['./group-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// By design ngOnChanges is not invoked when the component is dynamically created.
// This also includes the case when the component is used inside MatDialog.
export class GroupLogoComponent extends BasicContainerComponent { //, OnChanges {

  @Input() group: Group;
  @Input() theme: string;

  @Input() id:string = undefined;

  @Input()
  public set log(value:boolean) {
    super.log = value;
    this.mediaWrapper1.log = value;
    this.mediaWrapper2.log = value;
  }
  public get log():boolean {
    return super.log;
  }
  @Input() animated = true;
  @Input() elevated = false;
  @Input() rounded = false;
  @Input() backgroundColor: string = 'transparent';
  @Output() play = new EventEmitter<Media>();

  @ViewChild('logo1') logoElement1: ElementRef;
  @ViewChild('logo2') logoElement2: ElementRef;
  @ViewChild('frame') frameElement: ElementRef;

  protected logger = new Logger('GroupLogoComponent');

  public mediaWrapper1 = new MediaWrapper(this.instanceId,1);
  public mediaWrapper2 = new MediaWrapper(this.instanceId,2);
  public media$        = new BehaviorSubject<Media>(undefined);
  public standard:boolean = true;

  constructor(@Inject(ENVIRONMENT) public environment: any) {
    super();
    this.mediaWrapper1.other = this.mediaWrapper2;
    this.mediaWrapper2.other = this.mediaWrapper1;
    this.media$.pipe(
      takeUntil(this.onDestroy$),
      distinctUntilChanged((m1:Media,m2:Media)=>m1?.id==m2?.id)
    ).subscribe(media => {
      if (!!media) {
        if (this.mediaWrapper1.current) {
          this.mediaWrapper2.media = media;
        } else {
          this.mediaWrapper1.media = media;
        }
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.mediaWrapper1.logoElement  = this.logoElement1;
    this.mediaWrapper1.frameElement = this.frameElement;
    this.mediaWrapper2.logoElement  = this.logoElement2;
    this.mediaWrapper2.frameElement = this.frameElement;
  }

  ngOnAttach() {
    super.ngOnAttach();
    if (this.log) console.log(this.instanceId,"GroupLogoComponent.ngOnAttach");
    if (this.mediaWrapper1.current) {
      this.mediaWrapper1.triggerSwitch();
    } else {
      this.mediaWrapper2.triggerSwitch();
    }
    window.requestAnimationFrame(()=> {
      this.animate(this.animated);
    });
  }
  ngOnDetach() {
    super.ngOnDetach();
    if (this.log) console.log(this.instanceId,"GroupLogoComponent.ngOnDetach");
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    let media = this.group?.appLogoInfos?.[this.theme]?.logoMedia;
    if (!media && this.theme) {
      this.standard = true;
      const image = new Image();
      const imageUrl = `/assets/images/logo-${this.theme}.png`;
      image.src = imageUrl;
      image.onload = () => {
        const link:ImageLink = {
          type: 'image',
          link: imageUrl,
          contentType: 'image/png',
          width: image.width,
          height: image.height,
          size:0  // unknown
        };
        media = {
          id    : CRC16.fromString(imageUrl).toString(),
          type  : 'media',
          links : [ link ],
          cover : link,
          ready : true,
          valid : true,
          mediaType: MediaType.image,
          properties: { cover: link },
          rootPath: ''
        };
        this.media$.next(media);
      }
    } else if (!!media) {
      this.standard = false;
      this.media$.next({...media});
    }
    if (changes['animated']) {
      //this.logger.debug("ngOnChanges",changes['animated'].currentValue,changes);
      this.animate(changes['animated'].currentValue);
    }
  }

  onPlayMedia(media: Media, event: Event) {
    this.play.emit(media);
  }

  animate(animate:boolean) {
    this.animated = animate;
    this.mediaWrapper1.animate(animate);
    this.mediaWrapper2.animate(animate);
  }
}
