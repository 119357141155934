import {
  AfterContentInit,
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  ViewContainerRef
} from "@angular/core";
import {Viewport, VirtualScrollerComponent} from "shared";
import {Subscription} from "rxjs";

/**
 * add scrollToAdded support to VirtualScrollerComponent
 */
@Directive({
  selector: '[scrollToAdded]'
})
export class VirtualScrollerScrollToAddedDirective implements AfterContentInit, OnDestroy {

  @Input('scrollToAdded')
  virtualScroller:VirtualScrollerComponent = undefined;

  private subscription:Subscription = undefined;
  private scrollTrigger:number = undefined;
  private viewport:Viewport = undefined;
  private startFromTop:boolean = false;

  constructor(private elementRef:ElementRef) {
  }

  ngAfterContentInit() {
    this.startFromTop = this.virtualScroller?.startFromTop;
    this.subscription = this.virtualScroller?.onViewportUpdated.subscribe(this.onViewportUpdated.bind(this));
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  onViewportUpdated(event: { current: Viewport; previous: Viewport }) {
    this.viewport = event.current;
    /*
    if (!!event.current && event.current.itemCount>1 &&
        event.current.scrollSize > event.current.viewportSize &&
        event.current.itemCount > (event.previous?.itemCount ?? 0)) {

     */

    if (!!event.current && event.current.itemCount>1 &&
          event.current.scrollSize > event.current.viewportSize &&
        ((this.startFromTop &&
          event.current.visibleStartIndex==1 &&
          event.previous.visibleStartIndex==0) ||
         (!this.startFromTop &&
           // TODO: BUG in VirtualScrollerComponent: visibleEndIndex and scroll position are wrong in startFromBottom
           event.current.itemCount > (event.previous?.itemCount ?? 0)))) {
      // console.log('onViewportUpdated.TRIGGER',event);
      if (!!this.scrollTrigger) {
        window.clearTimeout(this.scrollTrigger);
      }
      this.scrollTrigger = window.setTimeout(()=>{
        //console.log('onViewportUpdated.SCROLL');
        this.scrollTrigger = undefined;
        if (this.startFromTop) {
          this.virtualScroller.scrollToStart(300);
        } else {
          this.virtualScroller.scrollToEnd(300);
        }
      }, 100);
    }
    // console.log('onViewportUpdated', event, "startFromTop",this.startFromTop);
  }
}
