/* projects/shared/src/lib/components/toolbar/toolbar.component.scss */
mat-toolbar {
  padding-left: 4px;
  padding-right: 4px;
}
mat-toolbar > button:last-child {
  margin-right: 8px;
}
mat-toolbar > button {
  padding-left: 8px;
  padding-right: 8px;
}
mat-toolbar > button mat-icon + span {
  margin-left: 4px;
  margin-right: 4px;
}
.title-container {
  flex: 1 1 0;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
}
.title-container:first-child {
  padding-left: 20px;
}
/*# sourceMappingURL=toolbar.component-CWUQGKJU.css.map */
