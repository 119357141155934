import {ModuleWithProviders, NgModule, Provider} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "material";
import {TranslateModule} from "@ngx-translate/core";
import {MediaGridComponent} from './components/media-grid/media-grid.component';
import {MediaGridContainerComponent} from './containers/media-grid-container/media-grid-container.component';
import {NgxMasonryModule, SharedModule, StarRatingConfigService, StarRatingModule} from "shared";
import {UploadModule} from "upload";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MediaRecordingService} from "./recording/media-recording.service";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {MediaEffects} from "./store/effects";
import {mediaFeatureKey, mediaReducers} from "./store/reducers";
import {MediaDetailsComponent} from './components/media-details/media-details.component';
import {
  ContactSelectorComponent,
  MediaDetailsContainerComponent
} from './containers/media-details-container/media-details-container.component';
import {MediaViewerOverlayComponent} from "./components/media-viewer/overlay/media-viewer-overlay.component";
import {MediaViewerOverlayService} from "./components/media-viewer/overlay/media-viewer-overlay.service";
import {
  MediaChangeConsent,
  MediaChangeDialogComponent,
  MediaCompositionDeleteDialogComponent,
  MediaDeleteDialogComponent
} from "./containers/media-details-container/media-change-consent";
import {MediaFilterContainerComponent} from './containers/media-filter-container/media-filter-container.component';
import {MediaRatingConfigService} from "./service/media-rating-config.service";
import {VideoViewerComponent} from "./components/media-viewer/viewers/video/video-viewer.component";
import {ImageViewerComponent} from "./components/media-viewer/viewers/image/image-viewer.component";
import {PdfViewerComponent} from "./components/media-viewer/viewers/pdf/pdf-viewer.component";
// moved to app module
// import * as Hammer from 'hammerjs';
// import {HammerGestureConfig} from '@angular/platform-browser';
import {VideoComponent} from "./components/video/video.component";
import {PlayButtonComponent} from "./components/video/play-button/play-button.component";
import {MediaSelectorComponent} from './containers/media-selector/media-selector.component';
import {MediaCardComponent, MediaCardElement} from './components/media-card/media-card.component';
import {MediaSelectionListComponent} from './components/media-selection-list/media-selection-list.component';
import {Media, NULL_MEDIA, NullMedia} from "./store/models";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {LayoutModule} from "layout";
import {SessionModule} from "session";
import {SwiperModule} from "swiper/angular";
import {MediaViewerBottomSheetComponent} from './components/media-viewer/overlay/media-viewer-bottom-sheet.component';
import {MediaViewerComponent} from "./components/media-viewer/containers/media-viewer/media-viewer.component";
import {MediaSliderComponent} from "./components/media-viewer/containers/media-slider/media-slider.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {FilterModule} from "filter";
import {MediaDetailsRatingComponent} from './components/media-details-rating/media-details-rating.component';
import {MediaDetailsInfoComponent} from './components/media-details-info/media-details-info.component';
import {MediaDetailsReviewComponent} from './components/media-details-review/media-details-review.component';
import {MediaDetailsSettingsComponent} from './components/media-details-settings/media-details-settings.component';
import {MediaDetailsFilterComponent} from './components/media-details-filter/media-details-filter.component';
import {MediaDetailsCoverComponent} from './components/media-details-cover/media-details-cover.component';
import {MediaDetailsLockComponent} from './components/media-details-lock/media-details-lock.component';
import {
  MediaDetailsCountryFilterComponent
} from './components/media-details-country-filter/media-details-country-filter.component';
import {MediaDetailsUserRoleComponent} from './components/media-details-user-role/media-details-user-role.component';
import {MediaReportComponent, MediaReportDialogComponent} from './components/media-report/media-report.component';
import {ChannelDetailsComponent} from './components/channel-details/channel-details.component';
import {MEDIA_CONTACT_SERVICE, MediaContactDefaultService} from "./service/media-contact.service";
import {SurveyViewerComponent} from './components/media-viewer/viewers/survey/survey-viewer.component';
import {MediaActionsComponent} from './components/media-actions/media-actions.component';
import {ChannelInfoComponent} from './components/channel-info/channel-info.component';
import {MediaReviewComponent} from './components/media-review/media-review.component';
import {MediaIconComponent} from './components/media-icon/media-icon.component';
import {
  InteractivePresentationViewerComponent
} from "./components/media-viewer/viewers/iap/interactive-presentation-viewer.component";

// moved to app module
// export class HammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//     'swipe': { direction: Hammer.DIRECTION_ALL },
//     'pinch': { enabled: true }
//   };
// }

export function nullMediaFactory(): Media {
  return new NullMedia();
}

export const PROVIDERS = [
  // MediaService,
  MediaRecordingService,
  MediaViewerOverlayService,
  MediaChangeConsent,
  { provide: StarRatingConfigService, useClass: MediaRatingConfigService },
  // moved to app module
  // { provide: HAMMER_GESTURE_CONFIG,   useClass: HammerConfig }
  { provide: NULL_MEDIA, useFactory: nullMediaFactory },
  { provide: MEDIA_CONTACT_SERVICE, useClass: MediaContactDefaultService }
];

@NgModule({
  imports: [
    CommonModule,
    FilterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    DragDropModule,
    SharedModule,
    NgxMasonryModule,
    UploadModule,
    StarRatingModule.forRoot(),
    StoreModule.forFeature(mediaFeatureKey, mediaReducers),
    EffectsModule.forFeature([MediaEffects]),
    ScrollingModule,
    LayoutModule,
    SessionModule,
    SwiperModule
    // ContactModule
  ],
  declarations: [
    MediaGridComponent,
    MediaGridContainerComponent,
    MediaViewerComponent,
    MediaSliderComponent,
    MediaViewerOverlayComponent,
    MediaViewerBottomSheetComponent,
    MediaDetailsComponent,
    MediaDetailsContainerComponent,
    MediaChangeDialogComponent,
    MediaDeleteDialogComponent,
    MediaCompositionDeleteDialogComponent,
    MediaFilterContainerComponent,
    VideoViewerComponent,
    ImageViewerComponent,
    InteractivePresentationViewerComponent,
    PdfViewerComponent,
    VideoComponent,
    PlayButtonComponent,
    MediaCardComponent,
    MediaCardElement,
    MediaSelectionListComponent,
    MediaSelectorComponent,
    ContactSelectorComponent,
    MediaDetailsRatingComponent,
    MediaDetailsInfoComponent,
    MediaDetailsReviewComponent,
    MediaDetailsSettingsComponent,
    MediaDetailsFilterComponent,
    MediaDetailsCoverComponent,
    MediaDetailsLockComponent,
    MediaDetailsCountryFilterComponent,
    MediaDetailsUserRoleComponent,
    MediaReportComponent,
    MediaReportDialogComponent,
    //MediaDetailsTopicsComponent,
    //MediaDetailsBibleComponent,
    ChannelDetailsComponent,
    SurveyViewerComponent,
    MediaActionsComponent,
    ChannelInfoComponent,
    MediaReviewComponent,
    MediaIconComponent
  ],
  exports: [
    MediaGridContainerComponent,
    MediaViewerComponent,
    MediaSliderComponent,
    MediaDetailsComponent,
    MediaDetailsContainerComponent,
    MediaCardComponent,
    PdfViewerComponent, // for demo pdf-page
    VideoComponent,
    PlayButtonComponent,
    MediaCardElement,
    MediaSelectionListComponent,
    MediaSelectorComponent,
    MediaCompositionDeleteDialogComponent,
    ChannelDetailsComponent,
    MediaReviewComponent,
    MediaIconComponent
  ],
  providers: PROVIDERS,
  /*,
  entryComponents: [
    MediaViewerOverlayComponent,
    MediaViewerBottomSheetComponent,
    MediaDetailsContainerComponent,
    MediaChangeDialogComponent,
    MediaDeleteDialogComponent,
    MediaCompositionDeleteDialogComponent,
    MediaFilterContainerComponent,
    MediaSelectorComponent,
    MediaReportDialogComponent,
    VideoViewerComponent,
    ImageViewerComponent,
    PdfViewerComponent,
    ContactSelectorComponent
  ]
  */
})
export class MediaModule {
  static forRoot(contactSelectorFactoryProvider: Provider): ModuleWithProviders<MediaModule> {
    return {
      ngModule: MediaModule,
      providers: [
        contactSelectorFactoryProvider
      ]
    };
  }
}
