import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, checkAvailability, cordovaPropertyGet, cordovaPropertySet, getPromise } from '@ionic-native/core';
var File = /** @class */function (_super) {
  __extends(File, _super);
  function File() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.cordovaFileError = {
      1: 'NOT_FOUND_ERR',
      2: 'SECURITY_ERR',
      3: 'ABORT_ERR',
      4: 'NOT_READABLE_ERR',
      5: 'ENCODING_ERR',
      6: 'NO_MODIFICATION_ALLOWED_ERR',
      7: 'INVALID_STATE_ERR',
      8: 'SYNTAX_ERR',
      9: 'INVALID_MODIFICATION_ERR',
      10: 'QUOTA_EXCEEDED_ERR',
      11: 'TYPE_MISMATCH_ERR',
      12: 'PATH_EXISTS_ERR',
      13: 'WRONG_ENTRY_TYPE',
      14: 'DIR_READ_ERR'
    };
    return _this;
  }
  File.prototype.getFreeDiskSpace = function () {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return getPromise(function (resolve, reject) {
          cordova.exec(resolve, reject, 'File', 'getFreeDiskSpace', []);
        });
      }
    }();
  };
  File.prototype.checkDir = function (path, dir) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        if (/^\//.test(dir)) {
          var err = new FileError(5);
          err.message = 'directory cannot start with /';
          return Promise.reject(err);
        }
        var fullPath = path + dir;
        return _this.resolveDirectoryUrl(fullPath).then(function () {
          return true;
        });
      }
    }();
  };
  File.prototype.createDir = function (path, dirName, replace) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        if (/^\//.test(dirName)) {
          var err = new FileError(5);
          err.message = 'directory cannot start with /';
          return Promise.reject(err);
        }
        var options = {
          create: true
        };
        if (!replace) {
          options.exclusive = true;
        }
        return _this.resolveDirectoryUrl(path).then(function (fse) {
          return _this.getDirectory(fse, dirName, options);
        });
      }
    }();
  };
  File.prototype.removeDir = function (path, dirName) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        if (/^\//.test(dirName)) {
          var err = new FileError(5);
          err.message = 'directory cannot start with /';
          return Promise.reject(err);
        }
        return _this.resolveDirectoryUrl(path).then(function (fse) {
          return _this.getDirectory(fse, dirName, {
            create: false
          });
        }).then(function (de) {
          return _this.remove(de);
        });
      }
    }();
  };
  File.prototype.moveDir = function (path, dirName, newPath, newDirName) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        newDirName = newDirName || dirName;
        if (/^\//.test(newDirName)) {
          var err = new FileError(5);
          err.message = 'directory cannot start with /';
          return Promise.reject(err);
        }
        return _this.resolveDirectoryUrl(path).then(function (fse) {
          return _this.getDirectory(fse, dirName, {
            create: false
          });
        }).then(function (srcde) {
          return _this.resolveDirectoryUrl(newPath).then(function (destenation) {
            return _this.move(srcde, destenation, newDirName);
          });
        });
      }
    }();
  };
  File.prototype.copyDir = function (path, dirName, newPath, newDirName) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        if (/^\//.test(newDirName)) {
          var err = new FileError(5);
          err.message = 'directory cannot start with /';
          return Promise.reject(err);
        }
        return _this.resolveDirectoryUrl(path).then(function (fse) {
          return _this.getDirectory(fse, dirName, {
            create: false
          });
        }).then(function (srcde) {
          return _this.resolveDirectoryUrl(newPath).then(function (deste) {
            return _this.copy(srcde, deste, newDirName);
          });
        });
      }
    }();
  };
  File.prototype.listDir = function (path, dirName) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        if (/^\//.test(dirName)) {
          var err = new FileError(5);
          err.message = 'directory cannot start with /';
          return Promise.reject(err);
        }
        return _this.resolveDirectoryUrl(path).then(function (fse) {
          return _this.getDirectory(fse, dirName, {
            create: false,
            exclusive: false
          });
        }).then(function (de) {
          var reader = de.createReader();
          return _this.readEntries(reader);
        });
      }
    }();
  };
  File.prototype.removeRecursively = function (path, dirName) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        if (/^\//.test(dirName)) {
          var err = new FileError(5);
          err.message = 'directory cannot start with /';
          return Promise.reject(err);
        }
        return _this.resolveDirectoryUrl(path).then(function (fse) {
          return _this.getDirectory(fse, dirName, {
            create: false
          });
        }).then(function (de) {
          return _this.rimraf(de);
        });
      }
    }();
  };
  File.prototype.checkFile = function (path, file) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        if (/^\//.test(file)) {
          var err = new FileError(5);
          err.message = 'file cannot start with /';
          return Promise.reject(err);
        }
        return _this.resolveLocalFilesystemUrl(path + file).then(function (fse) {
          if (fse.isFile) {
            return true;
          } else {
            var err = new FileError(13);
            err.message = 'input is not a file';
            return Promise.reject(err);
          }
        });
      }
    }();
  };
  File.prototype.createFile = function (path, fileName, replace) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        if (/^\//.test(fileName)) {
          var err = new FileError(5);
          err.message = 'file-name cannot start with /';
          return Promise.reject(err);
        }
        var options = {
          create: true
        };
        if (!replace) {
          options.exclusive = true;
        }
        return _this.resolveDirectoryUrl(path).then(function (fse) {
          return _this.getFile(fse, fileName, options);
        });
      }
    }();
  };
  File.prototype.removeFile = function (path, fileName) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        if (/^\//.test(fileName)) {
          var err = new FileError(5);
          err.message = 'file-name cannot start with /';
          return Promise.reject(err);
        }
        return _this.resolveDirectoryUrl(path).then(function (fse) {
          return _this.getFile(fse, fileName, {
            create: false
          });
        }).then(function (fe) {
          return _this.remove(fe);
        });
      }
    }();
  };
  File.prototype.writeFile = function (path, fileName, text, options) {
    var _this = this;
    if (options === void 0) {
      options = {};
    }
    return function () {
      if (checkAvailability(_this) === true) {
        if (/^\//.test(fileName)) {
          var err = new FileError(5);
          err.message = 'file-name cannot start with /';
          return Promise.reject(err);
        }
        var getFileOpts = {
          create: !options.append,
          exclusive: !options.replace
        };
        return _this.resolveDirectoryUrl(path).then(function (directoryEntry) {
          return _this.getFile(directoryEntry, fileName, getFileOpts);
        }).then(function (fileEntry) {
          return _this.writeFileEntry(fileEntry, text, options);
        });
      }
    }();
  };
  /**
   * Write content to FileEntry.
   * @hidden
   * Write to an existing file.
   * @param {FileEntry} fe file entry object
   * @param {string | Blob | ArrayBuffer} text text content or blob to write
   * @param {IWriteOptions} options replace file if set to true. See WriteOptions for more information.
   * @returns {Promise<FileEntry>}  Returns a Promise that resolves to updated file entry or rejects with an error.
   */
  File.prototype.writeFileEntry = function (fe, text, options) {
    var _this = this;
    return this.createWriter(fe).then(function (writer) {
      if (options.append) {
        writer.seek(writer.length);
      }
      if (options.truncate) {
        writer.truncate(options.truncate);
      }
      return _this.write(writer, text);
    }).then(function () {
      return fe;
    });
  };
  File.prototype.writeExistingFile = function (path, fileName, text) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return _this.writeFile(path, fileName, text, {
          replace: true
        });
      }
    }();
  };
  File.prototype.readAsText = function (path, file) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return _this.readFile(path, file, 'Text');
      }
    }();
  };
  File.prototype.readAsDataURL = function (path, file) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return _this.readFile(path, file, 'DataURL');
      }
    }();
  };
  File.prototype.readAsBinaryString = function (path, file) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return _this.readFile(path, file, 'BinaryString');
      }
    }();
  };
  File.prototype.readAsArrayBuffer = function (path, file) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return _this.readFile(path, file, 'ArrayBuffer');
      }
    }();
  };
  File.prototype.moveFile = function (path, fileName, newPath, newFileName) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        newFileName = newFileName || fileName;
        if (/^\//.test(newFileName)) {
          var err = new FileError(5);
          err.message = 'file name cannot start with /';
          return Promise.reject(err);
        }
        return _this.resolveDirectoryUrl(path).then(function (fse) {
          return _this.getFile(fse, fileName, {
            create: false
          });
        }).then(function (srcfe) {
          return _this.resolveDirectoryUrl(newPath).then(function (deste) {
            return _this.move(srcfe, deste, newFileName);
          });
        });
      }
    }();
  };
  File.prototype.copyFile = function (path, fileName, newPath, newFileName) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        newFileName = newFileName || fileName;
        if (/^\//.test(newFileName)) {
          var err = new FileError(5);
          err.message = 'file name cannot start with /';
          return Promise.reject(err);
        }
        return _this.resolveDirectoryUrl(path).then(function (fse) {
          return _this.getFile(fse, fileName, {
            create: false
          });
        }).then(function (srcfe) {
          return _this.resolveDirectoryUrl(newPath).then(function (deste) {
            return _this.copy(srcfe, deste, newFileName);
          });
        });
      }
    }();
  };
  /**
   * @hidden
   */
  File.prototype.fillErrorMessage = function (err) {
    try {
      err.message = this.cordovaFileError[err.code];
    } catch (e) {}
  };
  File.prototype.resolveLocalFilesystemUrl = function (fileUrl) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return getPromise(function (resolve, reject) {
          try {
            window.resolveLocalFileSystemURL(fileUrl, function (entry) {
              resolve(entry);
            }, function (err) {
              _this.fillErrorMessage(err);
              reject(err);
            });
          } catch (xc) {
            _this.fillErrorMessage(xc);
            reject(xc);
          }
        });
      }
    }();
  };
  File.prototype.resolveDirectoryUrl = function (directoryUrl) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return _this.resolveLocalFilesystemUrl(directoryUrl).then(function (de) {
          if (de.isDirectory) {
            return de;
          } else {
            var err = new FileError(13);
            err.message = 'input is not a directory';
            return Promise.reject(err);
          }
        });
      }
    }();
  };
  File.prototype.getDirectory = function (directoryEntry, directoryName, flags) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return new Promise(function (resolve, reject) {
          try {
            directoryEntry.getDirectory(directoryName, flags, function (de) {
              resolve(de);
            }, function (err) {
              _this.fillErrorMessage(err);
              reject(err);
            });
          } catch (xc) {
            _this.fillErrorMessage(xc);
            reject(xc);
          }
        });
      }
    }();
  };
  File.prototype.getFile = function (directoryEntry, fileName, flags) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return new Promise(function (resolve, reject) {
          try {
            directoryEntry.getFile(fileName, flags, resolve, function (err) {
              _this.fillErrorMessage(err);
              reject(err);
            });
          } catch (xc) {
            _this.fillErrorMessage(xc);
            reject(xc);
          }
        });
      }
    }();
  };
  File.prototype.readFile = function (path, file, readAs) {
    var _this = this;
    if (/^\//.test(file)) {
      var err = new FileError(5);
      err.message = 'file-name cannot start with /';
      return Promise.reject(err);
    }
    return this.resolveDirectoryUrl(path).then(function (directoryEntry) {
      return _this.getFile(directoryEntry, file, {
        create: false
      });
    }).then(function (fileEntry) {
      var reader = new FileReader();
      return getPromise(function (resolve, reject) {
        reader.onloadend = function () {
          if (reader.result !== undefined || reader.result !== null) {
            resolve(reader.result);
          } else if (reader.error !== undefined || reader.error !== null) {
            reject(reader.error);
          } else {
            reject({
              code: null,
              message: 'READER_ONLOADEND_ERR'
            });
          }
        };
        fileEntry.file(function (file) {
          reader["readAs" + readAs].call(reader, file);
        }, function (error) {
          reject(error);
        });
      });
    });
  };
  /**
   * @hidden
   */
  File.prototype.remove = function (fe) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      fe.remove(function () {
        resolve({
          success: true,
          fileRemoved: fe
        });
      }, function (err) {
        _this.fillErrorMessage(err);
        reject(err);
      });
    });
  };
  /**
   * @hidden
   */
  File.prototype.move = function (srce, destdir, newName) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      srce.moveTo(destdir, newName, function (deste) {
        resolve(deste);
      }, function (err) {
        _this.fillErrorMessage(err);
        reject(err);
      });
    });
  };
  /**
   * @hidden
   */
  File.prototype.copy = function (srce, destdir, newName) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      srce.copyTo(destdir, newName, function (deste) {
        resolve(deste);
      }, function (err) {
        _this.fillErrorMessage(err);
        reject(err);
      });
    });
  };
  /**
   * @hidden
   */
  File.prototype.readEntries = function (dr) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      dr.readEntries(function (entries) {
        resolve(entries);
      }, function (err) {
        _this.fillErrorMessage(err);
        reject(err);
      });
    });
  };
  /**
   * @hidden
   */
  File.prototype.rimraf = function (de) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      de.removeRecursively(function () {
        resolve({
          success: true,
          fileRemoved: de
        });
      }, function (err) {
        _this.fillErrorMessage(err);
        reject(err);
      });
    });
  };
  /**
   * @hidden
   */
  File.prototype.createWriter = function (fe) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      fe.createWriter(function (writer) {
        resolve(writer);
      }, function (err) {
        _this.fillErrorMessage(err);
        reject(err);
      });
    });
  };
  /**
   * @hidden
   */
  File.prototype.write = function (writer, gu) {
    if (gu instanceof Blob) {
      return this.writeFileInChunks(writer, gu);
    }
    return new Promise(function (resolve, reject) {
      writer.onwriteend = function (evt) {
        if (writer.error) {
          reject(writer.error);
        } else {
          resolve(evt);
        }
      };
      writer.write(gu);
    });
  };
  /**
   * @hidden
   */
  File.prototype.writeFileInChunks = function (writer, file) {
    var BLOCK_SIZE = 1024 * 1024;
    var writtenSize = 0;
    function writeNextChunk() {
      var size = Math.min(BLOCK_SIZE, file.size - writtenSize);
      var chunk = file.slice(writtenSize, writtenSize + size);
      writtenSize += size;
      writer.write(chunk);
    }
    return getPromise(function (resolve, reject) {
      writer.onerror = reject;
      writer.onwrite = function () {
        if (writtenSize < file.size) {
          writeNextChunk();
        } else {
          resolve();
        }
      };
      writeNextChunk();
    });
  };
  Object.defineProperty(File.prototype, "applicationDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "applicationDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "applicationDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "applicationStorageDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "applicationStorageDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "applicationStorageDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "dataDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "dataDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "dataDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "cacheDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "cacheDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "cacheDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "externalApplicationStorageDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "externalApplicationStorageDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "externalApplicationStorageDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "externalDataDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "externalDataDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "externalDataDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "externalCacheDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "externalCacheDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "externalCacheDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "externalRootDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "externalRootDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "externalRootDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "tempDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "tempDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "tempDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "syncedDataDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "syncedDataDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "syncedDataDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "documentsDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "documentsDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "documentsDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(File.prototype, "sharedDirectory", {
    get: function () {
      return cordovaPropertyGet(this, "sharedDirectory");
    },
    set: function (value) {
      cordovaPropertySet(this, "sharedDirectory", value);
    },
    enumerable: false,
    configurable: true
  });
  File.pluginName = "File";
  File.plugin = "cordova-plugin-file";
  File.pluginRef = "cordova.file";
  File.repo = "https://github.com/apache/cordova-plugin-file";
  File.platforms = ["Android", "Browser", "iOS", "macOS", "Windows"];
  File.decorators = [{
    type: Injectable
  }];
  return File;
}(IonicNativePlugin);
export { File };
