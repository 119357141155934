import {ErrorHandler, Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Logger} from "../logger.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorsHandler implements ErrorHandler {

  logger = new Logger('ErrorsHandler');

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
      } else {
        // Handle Http Error (error.status === 403, 404...)
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
    }
    this.logger.error(error);
  }
}
