import {NgModule} from '@angular/core';
import {GroupLogoComponent} from "./components/group-logo/group-logo.component";
import {GroupListComponent} from "./components/group-list/group-list.component";
import {GroupSwitchDialogComponent} from './components/group-switch-dialog/group-switch-dialog.component';
import {SharedModule} from "shared";
import {MediaModule} from "media";
import {StoreActionsModule} from "store";
import {CommonModule} from "@angular/common";
import {FlexLayoutModule} from "@angular/flex-layout";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "material";
import {SessionModule} from "session";

@NgModule({
  declarations: [
    GroupLogoComponent,
    GroupListComponent,
    GroupSwitchDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslateModule,
    MaterialModule,
    SharedModule,
    MediaModule,
    StoreActionsModule,
    SessionModule
  ],
  exports: [
    GroupLogoComponent,
    GroupListComponent,
    GroupSwitchDialogComponent
  ]/*,
  entryComponents: [
    GroupSwitchDialogComponent
  ]
  */
})
export class GroupModule { }
