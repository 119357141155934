import {ElementRef, EventEmitter, Renderer2} from "@angular/core";
import {DragEvent} from "../../models/touch/drag-event";
import {DragHandler} from "../../directives/drag/drag.directive";
import {Unsubscribable} from "rxjs";

export interface ScrollEvent {
  readonly vertical:boolean;
  readonly viewportSize:number;
  readonly scrollSize:number;
  readonly scrollPosition:number;
}

export interface ScrollUpdateEvent extends ScrollEvent {
  readonly refresh:boolean;
}

export interface ScrollRequestEvent {
  readonly scrollPosition$:SubscribableValue<number>;
  readonly scrollCompletedCallback?:()=>void;
  readonly time:number;
}

export class SubscribableValue<T> {
  private _callbacks:((T)=>void)[];
  public static of<T>(value:T):SubscribableValue<T> {
    return new SubscribableValue<T>(value);
  }
  get value():T {
    return this._value;
  }
  set value(value:T) {
    const previous = this._value;
    this._value = value;
    if (this._callbacks?.length>0) {
      this._callbacks.forEach(callback => callback && callback(value))
    }
  }
  constructor(private _value:T) {
  }
  reset(value:T):SubscribableValue<T> {
    this._callbacks = undefined;
    this._value = value;
    return this;
  }
  subscribe(callback:(T)=>void):Unsubscribable {
    this._callbacks = this._callbacks || [];
    const index = this._callbacks.length;
    this._callbacks.push(callback);
    return {
      unsubscribe: ()=>this._callbacks[index]=undefined
    }
  }
}

export abstract class VirtualScrollHandler implements DragHandler {
  vertical:boolean = undefined;
  bounces:boolean = false;
  bounceTime:number = 800;
  swipeBounceTime:number = 500;
  swipeTime:number = 2500;
  deceleration:number = 0.0015;
  viewportSize:number = 0;
  scrollSize:number = 0;
  scrollPosition:number = 0;
  targetPosition$:SubscribableValue<number> = SubscribableValue.of(0);
  protected _onDrag;
  readonly onScroll:EventEmitter<ScrollEvent> = new EventEmitter<ScrollEvent>();
  readonly doUpdate:EventEmitter<ScrollUpdateEvent> = new EventEmitter<ScrollUpdateEvent>();
  readonly doScroll:EventEmitter<ScrollRequestEvent> = new EventEmitter<ScrollRequestEvent>();
  constructor(protected elementRef:ElementRef,protected readonly renderer: Renderer2) {
    //console.log("VirtualScrollHandler.ctor",elementRef,elementRef.nativeElement);
  }
  public attachToDragEventEmitter(dragEventEmitter:EventEmitter<DragEvent>) {
    this._onDrag = dragEventEmitter;
  }
  public get onDrag():EventEmitter<DragEvent> {
    return this._onDrag;
  }
  public initialize(vertical:boolean,bounces:boolean=true) {
    if (this.vertical===undefined) {
      this.vertical = vertical;
      this.bounces = bounces;
    }
  }
  update(viewportSize:number,scrollSize:number,scrollPosition:number,refresh:boolean) {
    const fire = this.viewportSize != viewportSize ||
                 this.scrollSize != scrollSize ||
                 this.scrollPosition != scrollPosition;
    this.viewportSize = viewportSize;
    this.scrollSize = scrollSize;
    this.scrollPosition = scrollPosition;
    //console.log("VirtualScrollHandler.update",fire,"viewportSize",viewportSize,"scrollSize",scrollSize,"scrollPosition",scrollPosition);
    if (fire) {
      this.doUpdate.emit({
        vertical:this.vertical,
        viewportSize,
        scrollSize,scrollPosition,refresh
      });
    }
  }
  scrollTo(scrollPosition:number,time:number=0,scrollCompletedCallback:()=>void=undefined):SubscribableValue<number> {
    //console.log("VirtualScrollHandler.scrollTo",scrollPosition,"time",time);
    const fire = this.scrollPosition != scrollPosition;
    if (time==0) {
      this.scrollPosition = scrollPosition;
    }
    this.targetPosition$.reset(scrollPosition);
    //this.targetPosition$.subscribe((position)=>console.log("UPDATE",position,"from",scrollPosition));
    //console.log("TouchScrollHandler.scrollTo",fire,"scrollPosition",scrollPosition,"time",time);
    if (fire) {
      this.doScroll.emit({ scrollPosition$:this.targetPosition$,time,scrollCompletedCallback });
    } else if (scrollCompletedCallback) {
      scrollCompletedCallback();
    }
    return this.targetPosition$;
  }
  // called directly after rendering, but before adjustments done!
  abstract afterRender();
  abstract getComputedPosition(): number;
  resetPosition(bounceTime:number = this.bounceTime) {
  }
  destroy() {
    this.onScroll.complete();
    this.doUpdate.complete();
    this.doScroll.complete();
  }
  onAttach() {}
  onDetach() {}
}
