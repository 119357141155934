import { Directive, Inject, ElementRef, forwardRef, OnDestroy, AfterViewInit, Renderer2, OnInit, Input } from '@angular/core';
import { style, animate, AnimationBuilder } from '@angular/animations';

import { NgxMasonryComponent } from './ngx-masonry.component';
import { NgxMasonryAnimations } from './ngx-masonry-options';

@Directive({
  selector: '[ngxMasonryItem], ngxMasonryItem'
})
export class NgxMasonryDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() prepend = false;
  private images: Set<HTMLImageElement>;

  private animations: NgxMasonryAnimations = {
    show: [
      style({opacity: 0}),
      animate('400ms ease-in', style({opacity: 1})),
    ],
    hide: [
      style({opacity: '*'}),
      animate('400ms ease-in', style({opacity: 0})),
    ]
  }

  constructor(
    private builder: AnimationBuilder,
    private element: ElementRef,
    @Inject(forwardRef(() => NgxMasonryComponent)) private parent: NgxMasonryComponent,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    if (this.parent.options.animations !== undefined) {
      this.animations = this.parent.options.animations;
    }
  }

  ngAfterViewInit() {
    const images = this.element.nativeElement.getElementsByTagName('img');
    this.renderer.setStyle(this.element.nativeElement, 'opacity', '0');
    this.images = new Set(images);
    if (images.length === 0) {
      this.parent.add(this.element.nativeElement, this.prepend);
    } else {
      for (const imageRef of images) {
        // this.renderer.listen(imageRef, 'load', _ => {
        //   this.imageLoaded(imageRef);
        // });
        // this.renderer.listen(imageRef, 'error', _ => {
        //   this.imageLoaded(imageRef);
        // });
        /*
         * mb 11.06.2020
         * FIX: When masonry item is updated its image (if any) will be loaded again
         * and the imageLoaded() (which calls NgxMasonryComponent.add()) will be also invoked.
         * This is a problem because actually there is no new "physical" DOM element attached
         * and a discrepancy is introduced between the data model maintained internally by the masonry lib itself
         * and the effectively rendered html elements.
         * For this reason we unsubscribe the listeners immediately once the initial image loading completes (either with success or failure)
         *
         * let loadUnlisten, errorUnlisten;
         * const onComplete = () => {
         *   loadUnlisten  && loadUnlisten();
         *   errorUnlisten && errorUnlisten();
         *   this.imageLoaded(imageRef);
         * };
         * loadUnlisten = this.renderer.listen(imageRef, 'load',  onComplete);
         * errorUnlisten = this.renderer.listen(imageRef, 'error', onComplete);
         *
         * UPDATE: The element is added to the masonry after its images are loaded.
         * Because images are loaded asynchronously this can cause the sort order in the data source
         * to be different than the order of the elements in masonry
         * However as long as we know the dimensions of the image and our masonry items already have proper dimensions
         * it is safe to make a direct immediate call to imageLoaded() which will keep the proper sorting order
         *
         *
         * see also: https://masonry.desandro.com/layout.html#imagesloaded
         * Unloaded images can throw off Masonry layouts and cause item elements to overlap. imagesLoaded resolves this issue.
         * imagesLoaded is a separate script you can download at imagesloaded.desandro.com
         */
        this.imageLoaded(imageRef);
      }
    }
  }

  ngOnDestroy() {
    if (this.images.size === 0 && this.element.nativeElement.parentNode) {
      this.playAnimation(false);
      this.parent.remove(this.element.nativeElement);
    }
  }

  private imageLoaded(image?: HTMLImageElement) {
    this.images.delete(image);
    if (this.images.size === 0) {
      this.renderer.setStyle(this.element.nativeElement, 'opacity', '100');
      this.parent.add(this.element.nativeElement, this.prepend);
      this.playAnimation(true);
    }
  }

  private playAnimation(show: boolean) {
    const metadata = show ? this.animations.show : this.animations.hide;
    if (metadata) {
      const player = this.builder.build(metadata).create(this.element.nativeElement);
      player.play();
    }
  }
}
