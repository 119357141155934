/* projects/filter/src/lib/components/core/filter-searchbar.component.scss */
.separator {
  width: 300px;
  height: 1px;
  background-color: #ddd;
}
.search-container {
  flex: 0;
  box-sizing: border-box;
  font-size: 14.4px;
  display: flex;
  align-items: center;
  padding: 0;
}
.search-container input {
  font-size: 14.4px;
  padding-right: 35px;
}
.search-container .clear-icon {
  position: absolute;
  left: 235px;
}
.search-container .mat-mdc-checkbox {
  padding: 0 16px;
}
.mat-mdc-form-field {
  padding: 16px 16px 16px 0;
  width: 100%;
}
::ng-deep .mat-form-field-wrapper {
  padding: 0;
}
::ng-deep .mat-form-field-infix {
  border: 0;
  padding: 0;
}
.search-field {
  width: 100%;
  padding: 12px 12px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 1px;
  background-color: #fff;
}
.search-field:focus {
  border: 2px solid var(--color-primary);
  outline: none;
  margin: 0;
}
:host-context(.color-scheme-dark) .search-field {
  background-color: rgb(40, 40, 40);
}
:host-context(.color-scheme-dark) .separator {
  background-color: #AEAFB0;
}
/*# sourceMappingURL=filter-searchbar.component-YAEVA4XD.css.map */
