import {ErrorHandler, ModuleWithProviders, NgModule} from '@angular/core';
import {File} from "@ionic-native/file/ngx";
import {FileOpener} from '@awesome-cordova-plugins/file-opener/ngx';
import {Device} from "@ionic-native/device/ngx";
import {TranslateModule} from "@ngx-translate/core";
import {AppIdentifierProvider, DefaultAppIdentifierProvider} from "./app/app.provider";
import {CoreHttpModule} from "./http/core-http.module";
import {ErrorsHandler} from "./error/errors-handler";
import {StorageModule} from "./storage/storage.module";
import {RouteReusableStrategyCache} from "./router/route-reusable-strategy-cache";
import {RouteReusableStrategyHandler} from "./router/route-reusable-strategy-handler";
import {RouteReuseStrategy} from "@angular/router";
import {RouteReusableStrategy} from "./router/route-reusable-strategy";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {CorrelationIdGenerator} from "./correlationId-generator";

/*
MOVED TO core.token.ts to avoid recursive imports
 */
// export const WINDOW = new InjectionToken<Window>(
//   'global window object abstraction',
//   {
//     factory: () => {
//       const { defaultView } = inject(DOCUMENT);
//       if (!defaultView) {
//         throw new Error('Window is not available');
//       }
//       return defaultView;
//     }
//   }
// );

@NgModule({
  imports: [
    TranslateModule,
    CoreHttpModule,
    StorageModule,
    // MatSnackBarModule is used by UpdateService
    // (consider refactoring: update functionality should be in separate lib or made tree-shakable)!
    MatSnackBarModule
  ],
  providers: [
    // { provide: AppIdentifierProvider, useClass: DefaultAppIdentifierProvider },
    // { provide: ErrorHandler, useClass: ErrorsHandler },
    // { provide: RouteReusableStrategyCache, useClass: RouteReusableStrategyCache },
    // { provide: RouteReusableStrategyHandler, useClass: RouteReusableStrategyHandler },
    // { provide: RouteReuseStrategy, useClass: RouteReusableStrategy },
    // // { provide: CONFIG, useValue: config },
    // // { provide: ENVIRONMENT, useValue: config.environment },
    // File, Device
    // CorrelationIdGenerator
  ],
  exports: [
    // TranslateModule,
    CoreHttpModule,
    StorageModule
  ]
})
export class CoreModule {

  // constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
  //   // Import guard
  //   if (parentModule) {
  //     throw new Error(`${parentModule} has already been loaded. Import CoreModule only once!`);
  //   }
  // }

  // static forRoot(config: Config): ModuleWithProviders<CoreModule> {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        // triggers eager creating of provider instance which makes possible
        // to retrieve this instance in APP_ID InjectionToken factory see ./app/app.provider.ts
        // the providers declared in @NgModule are created lazily
        // i.e. when an instance of a class which depends on the corresponding provider has to be created.
        { provide: AppIdentifierProvider, useClass: DefaultAppIdentifierProvider },
        { provide: ErrorHandler, useClass: ErrorsHandler },
        { provide: RouteReusableStrategyCache, useClass: RouteReusableStrategyCache },
        { provide: RouteReusableStrategyHandler, useClass: RouteReusableStrategyHandler },
        { provide: RouteReuseStrategy, useClass: RouteReusableStrategy },
        // { provide: CONFIG, useValue: config },
        // { provide: ENVIRONMENT, useValue: config.environment },
        File,
        FileOpener,
        Device,
        CorrelationIdGenerator
      ]
    };
  }
}
