import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {Topic} from 'core';
import {FilterService} from "../../filter.service";
// import {MediaTopicsService} from 'media';
import {BasicFilterComponent} from '../core/basic-filter.component';

@Component({
  selector: 'lib-topic-filter',
  templateUrl: './topic-filter.component.html',
  styleUrls: ['./topic-filter.component.scss']
})
export class TopicFilterComponent extends BasicFilterComponent {

  constructor(
    protected filterService: FilterService,
    // private _mediaTopicsService: MediaTopicsService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(filterService,changeDetectorRef);
  }

  onSelectionChanged(selectedIds: string[]) {
    this.selectedFilterIds = selectedIds;
  }

  // NOT USED?!
  // protected getFilter(): Topic {
  //   return this._mediaTopicsService.getFilter();
  // }
}
