import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  QueryList,
  Renderer2,
  signal,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ActivatedRoute, EventType, NavigationEnd, Params, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  tap,
  take
} from 'rxjs/operators';
import {
  APP_ID,
  ENVIRONMENT,
  getPreferredThemeMode,
  isThemeModePure,
  Logger,
  LogMessage,
  LogMessageType,
  Platform,
  Resource,
  rgb2hex,
  themeMode$
} from "core";
import {MessagingService} from "messaging";
import {asyncScheduler, BehaviorSubject, from, lastValueFrom, Observable, of, Subject, Subscription, timer} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser'
import {
  AutoFocusDirective,
  BasicContainerComponent,
  BusyService,
  enterLeaveAnimations,
  LegalInfoBottomSheetComponent,
  SlideEvent,
  SlideState
} from "shared";
import {AuthenticationService} from "../../services/authentication.service";
import {Group, PropertiesService} from "properties";
import {Clipboard} from "@ionic-native/clipboard/ngx";
import {AutofillMonitor} from "@angular/cdk/text-field";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {LOGIN_OBSERVER, LoginObserver} from "../../authentication.module.config";
import {SessionTokenMessage, SessionTokenMessageType} from "../../models/messages";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LoginQrCodeDialogComponent} from "../login-qr-code-dialog/login-qr-code-dialog.component";
import isEqual from "lodash/isEqual";
import {MatTabGroup} from "@angular/material/tabs";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {Overlay} from "@angular/cdk/overlay";
// import {WELCOME_OVERLAY_DATA, WelcomeOverlayComponent, WelcomeOverlayRef} from "launcher";
import {DOCUMENT} from "@angular/common";
import {GroupLogoComponent} from "group";

//type mode = 'facebook' | 'member' | 'guestOrMember' | 'sign';

// @Directive({ selector: '[formControlElement]' })
// export class FormControlElementDirective {
//   constructor(private elementRef: ElementRef, private control : NgControl) {
//     control is an instance of FormControlName - we need access to FormControl
//     console.log('FormControlElementDirective', control, elementRef);
//     (control as any).nativeElement = elementRef.nativeElement;
//   }
// }

type GuestOrMemberLoginPanel = 'invitationCodeOrMemberId' | 'passwordLogin';

// @dynamic
@Component({
  selector: 'app-login',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  animations: [
    trigger('recoverySlide', [
      state('request', style({ transform: 'translateX(0)' })),
      state('login', style({ transform: 'translateX(-50%)' })),
      // transition('* => *', animate(300)),
      transition('request => login', animate(300)),
      transition('login => request', animate(300))
    ]),
    trigger('pageActivation', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms ease-in', style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate('300ms ease-out', style({opacity: 0}))
      ])
    ]),
    trigger("height", [
      transition(':enter', [
        style({ height: 0, opacity: 0, overflow: 'hidden' }),
        animate('300ms ease-in', style({ height: '*', opacity: 1 }))
      ]),
      transition(":leave", [
        style({ height: '*', opacity: 1, overflow: 'hidden' }),
        animate('300ms ease-out', style({ height: 0, opacity: 0 }))
      ])
    ]),
    enterLeaveAnimations
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent extends BasicContainerComponent {

  params: Params;
  memberLoginForm: FormGroup;
  passcodeRequestForm: FormGroup;
  passcodeLoginForm: FormGroup;

  invitationCodeOrMemberIdForm: FormGroup;

  legalInfo: SafeHtml;
  memberLoginError: any;
  guestLoginError: any;
  passcodeRequestError: any;
  passcodeLoginError: any;
  group$ = new BehaviorSubject<Group>(undefined);

  token$: BehaviorSubject<string> = new BehaviorSubject(null);

  protected loadingSubject = new Subject<boolean>();
  public loading$:Observable<boolean> = this.loadingSubject.asObservable();

  busy: Resource;
  signRequired  = signal(false);
  signing = signal(false);
  recoveryPanel: 'request' | 'login' |  null = null;
  themeMode$: Observable<string>;

  guestTypes = ['guest', 'join'];

  disableSlideAnimation = false;
  protected _guestOrMemberLoginPanel: GuestOrMemberLoginPanel = 'invitationCodeOrMemberId';
  protected _loginQrCodeDialogRef: MatDialogRef<LoginQrCodeDialogComponent>;

  // use regex literal for load time compilation and better performance
  // unfortunately this legex literal is not accepted when code is compiled as library
  // produces "Expression form not supported" compile time error.
  // static invitationCodeRegex = /^[a-zA-Z0-9]{5}(?:-[a-zA-Z0-9]{5}){4}$/;
  //static invitationCodeRegex = new RegExp("^[a-zA-Z0-9]{5}(?:-[a-zA-Z0-9]{5}){4}$");
  static invitationCodeRegex = new RegExp("^[A-Z0-9]{5}(?:[-]?[A-Z0-9]{5}){4}$");

  // runtime compilation as the pattern is dynamically constructed
  // moved initialization to ctor as environment is injected
  //static invitationLinkRegex = new RegExp(`^${environment.serverUrl}/login/guest/([a-zA-Z0-9]{5}(?:-[a-zA-Z0-9]{5}){4})$`);
  //protected invitationRawTextRegex = new RegExp(`[.]*([a-zA-Z0-9]{5}(?:-[a-zA-Z0-9]{5}){4})[.]*$`);
  protected invitationRawTextRegex = new RegExp(`[.]*([A-Z0-9]{5}(?:[-]?[A-Z0-9]{5}){4})[.]*`);

  @ViewChild('password', {read: ElementRef}) password: ElementRef;
  @ViewChild('passcode', {read: ElementRef}) passcode: ElementRef;
  @ViewChild('guestOrMemberLoginTabGroup') guestOrMemberLoginTabGroup: MatTabGroup;
  @ViewChild('grouplogo') grouplogo: GroupLogoComponent;
  @ViewChildren(AutoFocusDirective, {read: ElementRef}) focusElements: QueryList<ElementRef<HTMLInputElement>>;

  protected subscriptionBundle = new Subscription();
  protected tokenSubscription:Subscription = undefined;
  public autoFocus$ = new BehaviorSubject(false);
  public invitationCodeHint$ = new Subject();

  static logger = new Logger('LoginPageComponent');

  constructor(protected route: ActivatedRoute,  // see: https://angular-2-training-book.rangle.io/handout/routing/routeparams.html
              protected router: Router,
              protected formBuilder: FormBuilder,
              protected authenticationService: AuthenticationService,
              public propertiesService: PropertiesService, // public to stay AoT friendly (propertiesService is used inside template)
              protected translateService: TranslateService,
              public platform: Platform,                   // public for AoT compiler
              //protected qrScanner: QrScanner,
              protected messagingService: MessagingService,
              protected clipboard: Clipboard,
              protected busyService: BusyService,
              protected changeDetectorRef: ChangeDetectorRef,
              protected sanitizer: DomSanitizer,
              @Inject(APP_ID) public appId: number,        // public for AoT compiler
              @Inject(LOGIN_OBSERVER) protected observer: LoginObserver,
              private autofill: AutofillMonitor,
              protected dialog: MatDialog,
              protected bottomSheet: MatBottomSheet,
              protected elementRef: ElementRef,
              private overlay: Overlay,
              private injector: Injector,
              private renderer: Renderer2,
              @Inject(ENVIRONMENT) protected environment: any,
              @Inject(DOCUMENT) protected document: Document) {
    super();
    LoginPageComponent.logger.debug("ctor()");
    this.themeMode$ = themeMode$.asObservable();
    // LoginPageComponent.debug("LoginComponent.ctor");
    this.memberLoginForm = this.createMemberLoginForm();
    this.passcodeRequestForm = this.createPasscodeRequestForm();
    this.passcodeLoginForm = this.createPasscodeLoginForm();
    this.invitationCodeOrMemberIdForm = this.createInvitationCodeOrMemberIdForm();

    this.authenticationService.loading$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((loading) => {
        this.loadingSubject.next(loading);
      });

    this.messagingService
      .register(envelope => envelope.message?.type==SessionTokenMessageType)
      .pipe(
        takeUntil(this.onDestroy$),
        map((envelope) => (<SessionTokenMessage>envelope.message).token)
      ).subscribe(token => {
        //console.log("SessionTokenMessage.token",token);
        this.token$.next(token);
      });

    this.propertiesService.properties$.pipe(
      takeUntil(this.onDestroy$),
      map(properties => properties?.group),
      distinctUntilChanged((g1:Group,g2:Group)=>isEqual(g1,g2))
    ).subscribe(group => {
      this.group$.next(group);
    });

    //this.invitationLinkRegex = new RegExp(`^${environment.serverUrl}/login/guest/([a-zA-Z0-9]{5}(?:-[a-zA-Z0-9]{5}){4})$`);
    // subscribe in ctor to start processing path params and notify invitation code observers asap
    // i.e. before the LoginComponent is displayed on the screen as the observer may want to display something else before
    // see RealizerComponent.bootstrap()
    // ActivatedRoute: the observables in this class only emit when the current and previous values differ based on shallow equality
    const invitationCodeOrMemberId$ = this.route.params
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap((params) => {
          this.params = params; // this.id = params['id'];
          //console.debug('ID: '+this.id);
          if (!this.isGuestOrMemberMode) {
            return of(this.type);
          } else if (this.token) { // e.g. realizer invitation link: /login/guest/3EZN-WUPU-W3EZ-NWUP-W3EZ
            return of(this.token);
          } else if (this.platform.is('hybrid')) {
            return from(this.autofillInvitationCode())
          } else {
            return of(undefined);
          }
        }),
        distinctUntilChanged(),
        shareReplay(1)
      );

    invitationCodeOrMemberId$
      .pipe(
        take(1),
        switchMap((invitationCodeOrMemberId) => {
          LoginPageComponent.logger.debug('INVITATION CODE.1', invitationCodeOrMemberId)
          const invitationCodeOrMemberIdControl = this.invitationCodeOrMemberIdForm.get('invitationCodeOrMemberId');
          return invitationCodeOrMemberIdControl.valueChanges
            .pipe(
              tap(invitationCodeOrMemberId => LoginPageComponent.logger.debug('INVITATION CODE.1.1', invitationCodeOrMemberId)),
              debounceTime(300),
              startWith(invitationCodeOrMemberId),
              distinctUntilChanged()
            )
        }),
        tap(invitationCodeOrMemberId => LoginPageComponent.logger.debug('INVITATION CODE.2', invitationCodeOrMemberId)),
        filter(invitationCodeOrMemberId =>
            !invitationCodeOrMemberId ||
            invitationCodeOrMemberId?.match(LoginPageComponent.invitationCodeRegex)
        ),
        tap(invitationCodeOrMemberId => LoginPageComponent.logger.debug('INVITATION CODE.3', invitationCodeOrMemberId)),
      )
      .subscribe((invitationCode: string) => {
        LoginPageComponent.logger.debug('INVITATION CODE.4', invitationCode);
        this.observer.onInvitationCodeChange(invitationCode);
      });

    invitationCodeOrMemberId$.subscribe((invitationCodeOrMemberId) => {
      this.memberLoginForm.get('member_id').setValue(invitationCodeOrMemberId);
      const invitationCodeOrMemberIdControl = this.invitationCodeOrMemberIdForm.get('invitationCodeOrMemberId');
      if (invitationCodeOrMemberId) {
        if (!invitationCodeOrMemberIdControl.value) {
          invitationCodeOrMemberIdControl.setValue(invitationCodeOrMemberId);
          const isAuthenticated = this.propertiesService.user.isAuthenticated;
          const previousNavigation = this.router.getCurrentNavigation().previousNavigation;
          const initialNavigation = !previousNavigation?.id;
          if (!isAuthenticated &&
              initialNavigation &&
              invitationCodeOrMemberId.match(LoginPageComponent.invitationCodeRegex)) {
            // trigger autologin only when the user has landed on login page (initial navigation)
            // has invitation token and is not already authenticated
            this.loginGuest();
          }
        }
      } else {
        if (this.platform.is('hybrid')) {
          setTimeout(() => {
            this.invitationCodeHint$.next(true);
          }, 2500);
        }
      }
      // this.autoFocus$.next(true);
      asyncScheduler.schedule(() => this.changeDetectorRef.detectChanges());
    });

    this.router.events.pipe(
      takeUntil(this.onDestroy$),
      filter(event => event.type==EventType.NavigationEnd)
    )
    .subscribe(() => this.memberLoginForm.get('password').setValue(''))
  }

  ngOnDestroy() {
    console.debug("LoginPageComponent.ngOnDestroy()");
    super.ngOnDestroy();
    this.tokenSubscription?.unsubscribe();
    this.tokenSubscription = undefined;
    this.subscriptionBundle?.unsubscribe();
  }

  ngOnInit() {
    this.platform.resume$.pipe(
        takeUntil(this.onDestroy$)
    ).subscribe(() => {
      if (this.isGuestOrMemberMode) {
        this.autofillInvitationCode().then(invitationCode => {
          const invitationCodeOrMemberIdControl = this.invitationCodeOrMemberIdForm.get('invitationCodeOrMemberId');
          if (invitationCode && !invitationCodeOrMemberIdControl.value) {
            invitationCodeOrMemberIdControl.setValue(invitationCode);
            this.loginGuest();
          }
        });
      }
    });

    this.translateService.stream('legal_info')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value) => {
        this.legalInfo = this.sanitizer.bypassSecurityTrustHtml(value);
      });

    this.authenticationService.state$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(state => {
        if (state) {
          const authenticated = state.user.isAuthenticated;
          if (authenticated) {
            this.disableSlideAnimation = true;
            // allow the change detection cycle to complete - then slide-panel animate property will be updated
            // (i.e. set to false) and the slide animation will not be applied
            // (otherwise it happens at the same time as the transition to the app main page which is not desirable)
            setTimeout(() => {
              authenticated && (this._guestOrMemberLoginPanel = 'invitationCodeOrMemberId');
              this.disableSlideAnimation = false;
              this.changeDetectorRef.markForCheck();
              this.changeDetectorRef.detectChanges();
            }, 1000);
          }
          if (state.error && state.error!='passwordRequired') {
            // this.memberLoginError = this.guestLoginError = this.passcodeLoginError = undefined;
            if (state.error.type==='member') {
              this.memberLoginError = state.error.reason;
            } else if (state.error.type==='guest') {
              this.guestLoginError = state.error.reason;
            } else if (state.error.type==='passcode') {
              this.passcodeLoginError = state.error.reason;
            }
            const { memberLoginError, guestLoginError, passcodeLoginError } = {... this};
            LoginPageComponent.logger.error({ memberLoginError, guestLoginError, passcodeLoginError });
          } else {
            if (state.error=='passwordRequired') {
              this._guestOrMemberLoginPanel = 'passwordLogin';
            }
            this.guestLoginError = undefined;
            this.memberLoginError = undefined;
          }
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
        }
      });

    this.propertiesService.properties$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(properties => {
        if (properties) {
          const authenticated = properties.user.isAuthenticated;
          if (authenticated) {
            const onboardingMedia = properties.group?.onboarding?.media;
            this.signRequired.set(/*!onboardingMedia && */ !properties.signed);
            LoginPageComponent.logger.debug('SIGN REQUIRED', this.signRequired());
          }
        }
      });

    this.loading$.pipe(takeUntil(this.onDestroy$))
      .subscribe(loading => {
        if (this.busy) {
          this.busy.release();
          this.busy = null;
        }
        if (loading) {
          this.busy = this.busyService.lock('');
        }
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      });

    this.propertiesService.groupId$.pipe(takeUntil(this.onDestroy$))
      .subscribe(groupSwitch => {
        /*
          TODO: invalidate all loaded languages
          this.translateService.reloadLang(group.language); // reload because labels are group-specific
          if (this.translateService.currentLang!=group.language) {
            this.translateService.use(group.language);
          }
         */
      });

    this.subscriptionBundle.add(
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
        if (this.router.url?.startsWith('/login')) {
          if (this.isGuestOrMemberMode && this.guestOrMemberLoginTabGroup) {
            this.guestOrMemberLoginTabGroup.realignInkBar();
          }
        } else if (this._loginQrCodeDialogRef) {
          this._loginQrCodeDialogRef.close();
        }
      })
    );
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (!this.platform.is('hybrid') &&
         this.router.url.startsWith('/login/guest')) {
      // What to do if app is already installed?
      // we have to make this info available to the web app somehow...
      // if (this.platform.is('hybrid')) {
      //   this.onTapInstallApp();
      // } else {
      //   window.setTimeout(() => {
      //     this.onTapInstallApp();
      //   }, 2000);
      // }
      // const code = this.guestLoginForm.get('code');
      // this.observer.onInvitationCodeChange(code.value);
      // code.valueChanges.pipe(debounceTime(300)).subscribe(value => this.observer.onInvitationCodeChange(value));
    }

    // if (this.password) {
    //   this.autofill.monitor(this.password)
    //     .pipe(takeUntil(this.onDestroy$))
    //     .subscribe(e => console.log('AUTOFILLED', e));
    // }
  }

  get type(): string {
    return this.params?.type;
  }

  get token(): string {
    return this.params?.token;
  }

  get isMemberMode(): boolean {
    return this.isGuestOrMemberMode ? false : !!this.type;
  }

  get isGuestOrMemberMode(): boolean {
    return this.platform.is('hybrid') || this.guestTypes.includes(this.type);
  }

  get isRecoveryMode(): boolean {
    return this.recoveryPanel!=null;
  }

  continue() {
    const invitationCodeOrMemberId = this.invitationCodeOrMemberIdForm.get('invitationCodeOrMemberId').value;
    LoginPageComponent.logger.debug("CODE",invitationCodeOrMemberId);
    this.memberLoginForm.get('member_id').setValue(invitationCodeOrMemberId);
    if (invitationCodeOrMemberId.match(LoginPageComponent.invitationCodeRegex)) {
      this.loginGuest();
    } else {
      this._guestOrMemberLoginPanel = 'passwordLogin';
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }

  onPasswordLoginBack() {
    this._guestOrMemberLoginPanel = 'invitationCodeOrMemberId';
    this.memberLoginError = undefined;
  }

  onGuestOrMemberLoginPanelSlide(event: SlideEvent) {
    const focus = (element, select: boolean, delay: boolean) => {
      if (element) {
        if (delay) {
          asyncScheduler.schedule(() => {
              element?.focus();
              select && element?.select();
            } //500
          );
        } else {
          element?.focus();
        }
      }
    }
    const focusInput = this.elementRef.nativeElement.querySelector("input[name='focus']")
    if (event.state==SlideState.START) {
      focus(focusInput, false, false);
    } else { // SlideState.END
      const input: HTMLInputElement = event.slideIn=='passwordLogin'
          ? this.elementRef.nativeElement.querySelector("input[name='password']")  //(this.memberLoginForm.get('password') as any)?.nativeElement;
          : this.elementRef.nativeElement.querySelector("input[name='invitationCodeOrMemberId']");
      if (focusInput.value) {
        input.value += focusInput.value;
        focusInput.value = '';
      }
      // do we need to check these conditions in order to focus?
      // !invitationCodeOrMemberIdForm.controls.invitationCodeOrMemberId.valid || invitationCodeOrMemberIdForm.controls.invitationCodeOrMemberId.dirty
      // memberLoginForm.controls.password.untouched || memberLoginForm.controls.password.dirty)"
      focus(input, true, true);
    }
  }

  login() {
    let { member_id, password } = this.memberLoginForm.value;
    this.authenticationService.login(member_id, password)
      .then(info => {
        switch (info.status) {
          case "ok":        // login ok!
          default:          // must be an error
        }
      })
      .catch(reason=> LoginPageComponent.logger.error('login', reason));
  }

  logout() {
    this.authenticationService
      .logout()
      .then(() => this.signRequired.set(false));
  }

  loginGuest() {
    let token = this.invitationCodeOrMemberIdForm.get('invitationCodeOrMemberId').value;
    LoginPageComponent.logger.info(`Login guest: ${token}`);
    this.authenticationService.loginGuest(token)
      .then(info => {
        switch (info.status) {
          case "ok": break;        // login ok!
          case "password":        // password required for this token
            this._guestOrMemberLoginPanel = 'passwordLogin';
            break;
          // case "expired":     // token is expired -> handled in effect as an error condition
          default:               // must be an error
        }
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      }).catch(reason => LoginPageComponent.logger.error('loginGuest', reason));
  }

  onTapForgotPassword(event: Event) {
    event.stopPropagation();
    const memberId = this.memberLoginForm.get('member_id').value;
    this.passcodeRequestForm.get('member_id').setValue(memberId);
    this.recoveryPanel = 'request';
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  passcodeRequest(request: boolean) {
    this.passcodeRequestError = null;
    if (request) {
        const memberId = this.passcodeRequestForm.get('member_id').value;
        const phone = this.passcodeRequestForm.get('phone').value;
        this.loadingSubject.next(true);
        this.authenticationService.requestPasscode(memberId, phone)
          .then(() => {
            this.recoveryPanel = 'login';
            window.setTimeout(() => {
              this.passcode.nativeElement.focus();
            }, 350);
          })
          .catch((error) => {
            this.passcodeRequestError = error;
          })
          .then(() => this.loadingSubject.next(false))
          .finally(()=>{
            this.changeDetectorRef.markForCheck();
            this.changeDetectorRef.detectChanges();
          });
    } else {
      this.passcodeRequestForm.reset();
      this.recoveryPanel = null;
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }

  passcodeLogin(login: boolean) {
    this.passcodeLoginError = null;
    if (login) {
      const memberId = this.passcodeRequestForm.get('member_id').value;
      const passcode = this.passcodeLoginForm.get('passcode').value;
      this.authenticationService.loginPasscode(memberId, passcode);
    } else {
      this.recoveryPanel = null;
      this.passcodeRequestForm.reset();
      this.passcodeLoginForm.reset();
    }
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  onPasswordChange(event: any) {
    if (this.platform.is('hybrid')) { // should we enable for all?
      // our  password field (and angular reactive form) state is not updated
      // when ios password manager is used to autofill the data. we do it manually...
      window.setTimeout(() => {
        const formControl = this.memberLoginForm.get('password');
        const     element = this.password.nativeElement;
        if (formControl.value!==element.value) {
          formControl.setValue(element.value);
        }
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
      }, 0);
    }
  }

  onPasteInvitationCode(event: ClipboardEvent) {
    this.handleInvitationCodeChange(
      event.clipboardData?.getData('text')
        // || this.guestLoginForm.get('code').value
    );
  }

  onChangeInvitationCode(event: any) {
    this.handleInvitationCodeChange(event.currentTarget?.value);
  }

  handleInvitationCodeChange(text: string, control?: FormControl) {
    this.memberLoginForm.get('password').setValue('');
    const match = text?.match(this.invitationRawTextRegex);
    if (match && match.length > 1) {
      const code = match[1];
      const invitationCodeOrMemberIdControl = this.invitationCodeOrMemberIdForm.get('invitationCodeOrMemberId');
      if (code != invitationCodeOrMemberIdControl.value) {
        invitationCodeOrMemberIdControl.setValue(code);
        // console.log("CODE",match[1],"route",this.router.url);
        // const url = `/login/guest/${match[1]}`;
        const url = `/login/guest`;
        if (url != this.router.url) {
          this.router.navigateByUrl(url).then(() => this.propertiesService.reload());
          //event.preventDefault();
          // SEE onInit -> groupSwitch$ to reload i18n
        }
      }
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
  }

  sign() {
    if (!this.signing()) {
      this.signing.set(true);
      this.observer.onSign(true);
      this.authenticationService.sign().finally(()=> this.signing.set(false));
    }
  }

  /*
  scanQrCode() {
    this.qrScanner.scanQrCode()
      .then(link => {
        // LoginComponent.logger.log("QR CODE TEXT: " + link);
        let url = new URL(link);
        let parts = (url.pathname || '').split('/');
        if (parts.length==4 && // /login/guest/<invitation-code> -> ['', 'login', 'guest', '<invitation-code>']
          _.isEqual(parts.slice(1, 3), ['login', 'guest']) &&
          url.origin === environment.serverUrl) {
          let code = parts[parts.length-1];
          if (code.match(LoginComponent.invitationCodeRegex)) {
            this.guestLoginForm.get('code').setValue(code);
            this.loginGuest();
          } else {
            throw new Error(`Invalid invitation code ${code}`);
          }
        } else {
          throw new Error(`Failed to parse invitation link ${link}`);
        }
      })
      .catch((error) => {
        LoginComponent.logger.error(error);
        this.guestLoginError = error;
      })
  }
  */

  get canInstallApp(): Observable<boolean> {
    return this.platform.is('hybrid') ? of(false) : this.translateService
      .get(['app.links.googlePlay', 'app.links.appleStore'])
      .pipe(map(result => Object.values(result).filter(value => !!value).length > 0));
  }

  onTapInstallApp() {
    this.observer.onInstallAppRequest();
  }

  protected createMemberLoginForm() : FormGroup {
    return this.formBuilder.group({
      member_id: ['', Validators.required],
      password:   ['', Validators.nullValidator],
      remember: true
    });
  }

  protected createInvitationCodeOrMemberIdForm(): FormGroup {
    return this.formBuilder.group({
      invitationCodeOrMemberId: ['', Validators.required],
      remember: true
    });
  }

  protected createPasscodeRequestForm() : FormGroup {
    return this.formBuilder.group({
      member_id: ['', Validators.required],
      phone: ['', Validators.required]
    });
  }

  protected createPasscodeLoginForm() : FormGroup {
    return this.formBuilder.group({
      passcode: ['', Validators.minLength(4)]
    });
  }

  protected autofillInvitationCode(retry = true): Promise<string> {
    const codeCtrl = this.invitationCodeOrMemberIdForm.get('invitationCodeOrMemberId');
    if (!codeCtrl.value) {
      //region get invitation code from server - deprecated
      /*
      this.authenticationService.getInvitationCode()
        .then(
          code => {
            if (code?.match(LoginPageComponent.invitationCodeRegex)) {
              LoginPageComponent.logger.debug('Invitation code retrieved from server', code);
              return code;
            } else {
              LoginPageComponent.logger.warn('Invalid invitation code retrieved from server', code);
              return null;
            }
          },
          error => {
            LoginPageComponent.logger.error('Failed to read invitation code from server', error);
            return null;
          }
        )
        .then(code => {
          return code
            ? Promise.resolve(code)
            : this.tryGetClipboardInvitationCode().then(
              code => {
                code && this.clipboard.clear();
                return code;
              },
              error => {
                LoginPageComponent.logger.error('Failed to read clipboard content', error);
                return null;
              }
            );
        })
        .then(code => {
            if (code) {
              codeCtrl.setValue(code);
            } else {
              LoginPageComponent.logger.debug('Invitation code was not found on server and in clipboard');
            }
        });
       */
      //endregion

      const getClipboardInvitationCode = this.platform.is('ios')
        ? Promise.resolve(true) //this.authenticationService.hasInvitationCode()
        : Promise.resolve(true);
      return getClipboardInvitationCode
        .then(getClipboardInvitationCode  => getClipboardInvitationCode
          ? this.tryGetClipboardInvitationCode().then(
              code => {
                code && this.clipboard.clear();
                return code;
              },
              error => {
                LoginPageComponent.logger.error('autofillInvitationCode -> Failed to read clipboard content', error);
                return null;
              }
            )
          : null
        )
        .then(code => {
          if (code) {
            codeCtrl.setValue(code);
            // reload properties. the body of the server api request is a json
            // which always includes the current path (i.e. window.location.pathname)
            // from which the server can extract invitation token and other details which influence the properties delivery
            // To ensure that the found invitation code will be sent to the server
            // we change the current path by including the invitation code as a last segment.
            // Alternatively we can extend propertiesLoadRequest action with arguments which will be passed to the server
            // pass the code to propertiesService.reload() and construct the relevant action for properties load
            //this.router.navigateByUrl(`this.router.url/${code}`).then(() => {

            // this.router.navigateByUrl(`/login/guest/${code}`).then(() => {
            this.router.navigateByUrl(`/login/guest`).then(() => {
              this.propertiesService.reload();
            })
            return code;
          } else {
            const openWelcomeOverlay = false; //retry && this.platform.is('ios');
            if (openWelcomeOverlay) {
              LoginPageComponent.logger.info('autofillInvitationCode -> Retrying to get invitation code from clipboard on user welcome screen interaction');
              retry = false;
              //TODO: keyboard caret is displayed (blinking) in the focused element,
              // it penetrates the welcome overlay and is visible to the user
              return new Promise<string>((resolve) => {
                LoginPageComponent.logger.info('autofillInvitationCode -> Wellcome');
                const overlayRef = this.openWelcomeOverlay();
                lastValueFrom(overlayRef.onAction.pipe(switchMap((action) => {
                  if (action=='close' || action=='continue') {
                    return this.autofillInvitationCode(retry);
                  } else {
                    return of(undefined);
                  }
                }))).then(resolve);
              })
            } else {
              LoginPageComponent.logger.info('autofillInvitationCode -> Invitation code was not found in clipboard');
              if (this.platform.is('ios')) {
                this.messagingService.sendMessage(this.messagingService.initializeMessage(<LogMessage>{
                  type: LogMessageType,
                  object: {
                    system: 'login',
                    type: "invitationCodeEmpty",
                    value : code,
                  }
                }));
              }
              return undefined;
            }
          }
        }).catch(error => {
          LoginPageComponent.logger.error(`autofillInvitationCode -> Failed to read clipboard data: ${error.message}`);
          return undefined;
        })
    } else {
      return Promise.resolve(undefined);
    }
  }

  protected tryGetClipboardInvitationCode(): Promise<string> {
    return this.clipboard.paste().then(
      code => {
        if (code) {
          if (code.match(LoginPageComponent.invitationCodeRegex)) {
            return code;
          } else {
            LoginPageComponent.logger.warn(`The clipboard content is not a valid invitation code: ${code}`);
          }
        }
        return null;
      }
    );
  }

  protected triggerTokenUpdate():Subscription {
    return this.authenticationService.state$()
      .pipe(
        map(state => state?.user?.isAuthenticated ?? false),
        distinctUntilChanged(),
        filter(loggedIn => !loggedIn),
        switchMap((loggedIn) => timer(100,60_000))
      ).subscribe((count) => {
        //console.log("LOGIN.PAGE.COMPONENT.trigger",count);
        //let token   = this.messagingService.connectionId+'_'+(~~(Math.random() * 100000));
        let message = this.messagingService.initializeMessage(<SessionTokenMessage>{
          type:  SessionTokenMessageType,
          //token: token
        });
        this.messagingService.sendMessage(message);
        //.then(() => console.log("sendSessionTokenMessage",token,message,this.messagingService.isOpen()))
        //.catch(error => console.error("sendSessionTokenMessage", error));
      });
  }

  displayLoginQrCodeDialog(event: MouseEvent) {
    if (!this._loginQrCodeDialogRef) {
      this._loginQrCodeDialogRef = this.dialog.open(LoginQrCodeDialogComponent, {
        width: '400px',
        minHeight: '400px',
        maxWidth: '96vw',
        data: {
          token: this.token$
        },
        panelClass: 'login-qr-code-dialog'
      })
      this._loginQrCodeDialogRef.afterClosed().subscribe(() => {
        this._loginQrCodeDialogRef = undefined;
      })
    }
  }

  get qrCodeColorDark(): string {
    return getPreferredThemeMode()=='dark' ? '#282828' :  isThemeModePure() ? '#555' : rgb2hex(this.environment.primaryColor);
  }

  get qrCodeColorLight(): string {
    return getPreferredThemeMode()=='dark' ? '#AAAAAA' : '#FFFFFF';
  }

  get guestOrMemberLoginPanel(): GuestOrMemberLoginPanel {
    return this._guestOrMemberLoginPanel;
  }

  displayLegalInfo() {
    const config = {
      panelClass: ['login', 'legal-info', 'bottom-sheet'],
      autoFocus: false
    };
    return this.bottomSheet.open(LegalInfoBottomSheetComponent, config);
  }

  ngOnAttach() {
    console.log("$$.LoginComponent.attach()");
    this.tokenSubscription?.unsubscribe();
    this.tokenSubscription = this.triggerTokenUpdate();
    //console.log("LOGIN.PAGE.COMPONENT.attach()");
    super.ngOnAttach();
    this.grouplogo?.animate(true);
  }

  ngOnDetach() {
    this.tokenSubscription?.unsubscribe();
    this.tokenSubscription = undefined;
    //console.log("LOGIN.PAGE.COMPONENT.detach()");
    super.ngOnDetach();
    this.grouplogo?.animate(false);
  }

  //TODO: fix this after migration to ng 14.2
  // public openWelcomeOverlay(): WelcomeOverlayRef {
  public openWelcomeOverlay(): any {
    return
    // console.log('OPEN WELCOME OVERLAY');
    // const positionStrategy = this.overlay.position()
    //   .global()
    //   .centerHorizontally()
    //   .centerVertically();
    // const overlayConfig = new OverlayConfig({
    //   panelClass: ['welcome', 'overlay'],
    //   scrollStrategy: this.overlay.scrollStrategies.block(),
    //   positionStrategy
    // });
    // const overlayRef =  this.overlay.create(overlayConfig);
    // const dialogRef = new WelcomeOverlayRef(overlayRef);
    // const injectionTokens = new WeakMap();
    // injectionTokens.set(WelcomeOverlayRef, dialogRef);
    // injectionTokens.set(WELCOME_OVERLAY_DATA, {});
    // const injector = new PortalInjector(this.injector, injectionTokens);
    // const containerPortal = new ComponentPortal(WelcomeOverlayComponent, null, injector);
    // const componentRef: ComponentRef<WelcomeOverlayComponent> = overlayRef.attach(containerPortal);
    // return dialogRef;
  }
}
