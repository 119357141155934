import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ENVIRONMENT} from "../config";


/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  constructor(@Inject(ENVIRONMENT) private environment: any) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https|ionic):/i.test(request.url)) {
      request = request.clone({ url: this.environment.serverUrl + request.url });
    }
    return next.handle(request);
  }
}
