import {Action, ActionsSubject, select, Store} from "@ngrx/store";
import {ElementRef, Inject, Injectable, Injector, Renderer2, RendererFactory2} from "@angular/core";
import {
  CorrelationIdGenerator,
  EntityService,
  ENVIRONMENT,
  FilterTypes,
  Logger,
  once,
  SearchService,
  TypedFilterService,
  User,
  VersionedId
} from "core";
import {MessageEnvelope, MessageHandlerRegistry, MessagingService} from "messaging";
import {
  BehaviorSubject,
  firstValueFrom,
  from,
  lastValueFrom,
  Observable,
  ReplaySubject,
  Subscriber,
  Subscription,
  switchMap,
  tap
} from "rxjs";
import {
  ImageLink,
  Media,
  MediaAction,
  MediaCacheRemoveMessage,
  MediaCacheRemoveMessageType,
  MediaCacheUpsertMessage,
  MediaCacheUpsertMessageType,
  MediaMessage,
  MediaMessageType,
  MediaReviewState,
  MediaSubscriptionMessage,
  MediaSubscriptionMessageType,
  MediaType,
  MediaUpdateMessage,
  MediaUpdateMessageType,
  SurveyLink
} from "../store/models";
import {debounceTime, distinctUntilChanged, filter, finalize, map, shareReplay, take} from "rxjs/operators";
import * as fromMedia from "../store/state";
import {selectSubscribed} from "../store/state";
import {TranslateService} from "@ngx-translate/core";
import {PropertiesService} from "properties";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import omit from "lodash/omit";
import {UploadBottomSheetComponent, UploadFile, UploadRef, UploadService} from "upload";
import {HttpClient} from "@angular/common/http";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {DownloadService, isValidNumber, mimeExtensions, Reaction, SpeakerService} from "shared";
import {StoreService} from "store";
import {
  mediaCacheRemoveAction,
  mediaCacheUpsertAction,
  mediaLoadRequestAction,
  mediaOnboardingLoadAction,
  mediaSelectAction,
  mediaSetTypedFiltersAction,
  mediaSubscribeAction,
  mediaUnsubscribeAction,
  mediaUpdateAction,
  mediaUpdateDoneAction,
  mediaUpdateFailedAction,
  mediaUpdateSearchTermAction,
  mediaUpdateUserAction
} from "../store/actions";
import cloneDeep from "lodash/cloneDeep";

export declare type MediaUpload = { file: UploadFile, media?: Media, error?: any }
export declare type MediaUploadRef = { uploadId?: string, uploadRef?: UploadRef, options?:any, complete: Promise<MediaUpload[]>};

export interface Playback {
  play():Promise<void>;
  pause();
  source$():Promise<string>;
  playing():boolean;  // is it playing??
  playing$():Observable<boolean>;
  stalled$():Observable<boolean>;
  duration$():Promise<number>;  // seconds
  position(position?:number):number;  // seconds
  position$():Observable<number>;
  release();          // release this playback and all it's resources
}
interface DetachablePlayback extends Playback {
  detach();           // detach if not playing
  request();          // request if already released
}

export interface AudioAnalysis {
  duration():number;
  wave(points:number):Uint8Array; // 1024 enough...
}

@Injectable({
  providedIn: 'root'
})
export class MediaService extends StoreService implements SearchService, TypedFilterService, /*SelectableService,*/ EntityService<Media> {

  user: User = null;
  protected youtubeLinkRegex = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)|vi?)\/|\S*?[?&](?:v=|vi=))|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  protected vimeoImageSizeRegExp = new RegExp('_([0-9]*)x([0-9]*)\.','g');
  protected messageHandlerRegistry = new MessageHandlerRegistry();
  protected mediaSubscription:Subscription = undefined;
  protected bottomSheet: MatBottomSheet = undefined;

  protected _renderer: Renderer2;

  protected logger = new Logger('MediaService');

  constructor(protected store$: Store<any>,
              protected action$: ActionsSubject,
              protected messagingService: MessagingService,
              protected translateService : TranslateService,
              protected propertiesService: PropertiesService,
              protected uploadService: UploadService,
              protected downloadService: DownloadService,
              protected speakerService: SpeakerService,
              protected httpClient: HttpClient,
              protected correlationIdGenerator: CorrelationIdGenerator,
              // protected renderer: Renderer2,
              protected rendererFactory: RendererFactory2,
              protected injector: Injector,
              @Inject(ENVIRONMENT)
              protected environment: any) {
    super(store$,action$);
    console.log('MediaService.ctor');
    this.messagingService.open$.pipe().subscribe(open => {
      //this.logger.debug("OPENSTATE",open);
      this.mediaSubscription?.unsubscribe();
      this.mediaSubscription = undefined;
      if (open) {
        this.mediaSubscription = this.store$.select(selectSubscribed)
          .pipe(
            debounceTime(30),
            distinctUntilChanged((keys1:VersionedId[],keys2:VersionedId[])=> {
              // before: isEqual(keys1,keys2)
              // now:
              // we compare only the ids, not the versions, as first time we request
              // with version==0, and after server sent the object, we request with the
              // real version, so we get a change, but we don't want to send a message
              const keys1Length = keys1?.length ?? 0;
              const keys2Length = keys2?.length ?? 0;
              return keys1Length == keys2Length &&
                (keys1Length == 0 || keys1.every((key1:VersionedId,index:number)=>keys2[index].id==key1.id));
            })
          )
          .subscribe(versionedIds => {
            this.messagingService.sendMessage(this.messagingService.initializeMessage(<MediaSubscriptionMessage>{
              type: MediaSubscriptionMessageType,
              subscribed: versionedIds ?? []
            }));
          });
      }
    });
    let handleMediaUpdateMessage = (envelope:MessageEnvelope):void => {
      let message = <MediaUpdateMessage>envelope.message;
      this.syncMedia(message.media);
    };
    let handleMediaMessage = (envelope:MessageEnvelope):void => {
      let message = <MediaMessage>envelope.message;
      this.syncMedia(message.media);
    };
    let handleMediaCacheUpsertMessage = (envelope:MessageEnvelope):void => {
      let message = <MediaCacheUpsertMessage>envelope.message;
      this.store$.dispatch(mediaCacheUpsertAction({cacheId:message.cacheId,media:message.media,afterId:message.afterId,beforeId:message.beforeId}));
    };
    let handleMediaCacheRemoveMessage = (envelope:MessageEnvelope):void => {
      let message = <MediaCacheRemoveMessage>envelope.message;
      this.store$.dispatch(mediaCacheRemoveAction({cacheId:message.cacheId,media:<Pick<Media,"id">>message.media}));
    };
    this.messageHandlerRegistry.addMessageHandler(MediaCacheUpsertMessageType,handleMediaCacheUpsertMessage);
    this.messageHandlerRegistry.addMessageHandler(MediaCacheRemoveMessageType,handleMediaCacheRemoveMessage);
    this.messageHandlerRegistry.addMessageHandler(MediaUpdateMessageType,handleMediaUpdateMessage);
    this.messageHandlerRegistry.addMessageHandler(MediaMessageType,handleMediaMessage);
    this.messagingService
      .register(envelope => this.messageHandlerRegistry.hasMessageHandler(envelope.message?.type))
      .subscribe(envelope => {
        // this.logger.debug('ON MESSAGE', envelope);
        this.messageHandlerRegistry.getMessageHandler(envelope.message.type)(envelope);
      });
    this.propertiesService.user$.subscribe((user) => {
      if (!!user?.id && !!user?.version &&
           (user.id != this.user?.id ||
            user.version != this.user?.version)) {
        this.store$.dispatch(mediaUpdateUserAction({userId:user.id,userVersion:user.version}));
      }
      this.user = user
    });
    // filter out all COUNTRY FILTERS when language is changed...
    this.translateService.onLangChange
      .pipe(
        switchMap(event=>this.setTypedFilters({},
          (type,filters)=>type==FilterTypes.COUNTRY && filters.length>0))
      ).subscribe(updated=>{
        this.loadRequest();
      });
  }

  getMedia$(id:string, defaultMedia?:Media):Observable<Media> {
    //this.logger.debug("mediaService.getMedia$",id,defaultMedia);
    let subscribed = false;
    let ensureSubscription = (media:Media) => {
      if (!subscribed) {
        subscribed = true;
        //this.logger.debug("media.service.getMedia$.subscribe",id);
        this.dispatch(mediaSubscribeAction({ id, media: media ?? defaultMedia }));
      }
    }
    return this.store$.select(fromMedia.selectMedia,id).pipe(
      //tap(media =>  this.logger.debug("media.service.getMedia$.tap1",id,media)),
      //tap(media =>  this.logger.debug("media.service.getMedia$.tap2",id,media)),
      distinctUntilChanged((media1:Media,media2:Media)=>isEqual(media1,media2)),
      finalize(() => {
        //this.logger.debug("media.service.getMedia$.finalize",id,"subscribed",subscribed);
        if (subscribed) {
          //this.logger.debug("media.service.getMedia$.finalize",id);
          subscribed = false;
          window.setTimeout(()=>
             this.store$.dispatch(mediaUnsubscribeAction({ id })),
            1000);
        }
      }),
      tap(media => ensureSubscription(media)),
      map(media => {
        return media?.timeDeleted ? undefined :
          media ?? defaultMedia;
      })
      //tap(media =>  this.logger.debug("media.service.getMedia$.tap3",id,{...media})),
    );
  }

  getViewedId() : string {
    return this.propertiesService.properties?.viewed?.id;
  }

  protected _goToMedia:()=>Promise<boolean> = undefined;
  set goToMediaFunction(goToMedia:()=>Promise<boolean>) {
    this._goToMedia = goToMedia;
  }

  goToMedia():Promise<boolean> {
    if (!!this._goToMedia) {
      return this._goToMedia();
    }
    return Promise.reject('undefined');
  }

  protected _goToHome:()=>Promise<boolean> = undefined;
  set goToHomeFunction(goToHome:()=>Promise<boolean>) {
    this._goToHome = goToHome;
  }
  goToHome():Promise<boolean> {
    if (!!this._goToHome) {
      return this._goToHome();
    }
    return Promise.reject('undefined');
  }

  setViewedId(viewedId?:string) : Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post(
          '/v1.0/media/view/as/' + (viewedId ?? 'me'), {}
        )
        .subscribe({
          next: (response: { done:boolean, viewedId?:string }) => {
            if (response) {
              const id = response.viewedId;
                this.propertiesService.reload()
                .then(value=>{
                  this.loadRequest();
                  resolve(id);
                })
                .catch(reject);
            } else {
              reject('view as failed');
            }
          },
          error: reject
        });
    });
  }

  loadRequest() : void {
    this.store$.dispatch(mediaLoadRequestAction());
  }

  get selectedIndex$() : Observable<number> {
    return this.store$.pipe(
      select(fromMedia.selectMediaListState),
      map(state => state.selectedIndex));
  }

  get loaded$() : Observable<boolean> {
    return this.store$.pipe(
      select(fromMedia.selectMediaListState),
      map(state => !!state.currentCacheId && state.currentCacheId==state.previousCacheId));
  }

  get entities$() : Observable<Media[]> {
    return this.store$.select(fromMedia.selectMediaEntities);
  }

  get cacheId$() : Observable<string> {
    return this.store$.select(fromMedia.selectMediaCacheId);
  }

  get size$() : Observable<number> {
    return this.store$.select(fromMedia.selectMediaEntitiesLength);
  }

  get loadedEntities$() : Observable<Media[]> {
    return this.store$.select(fromMedia.selectLoadedMediaEntities);
  }

  get searchTerm$() : Observable<string> {
    return this.store$.select(fromMedia.selectMediaSearchTerm).pipe(
      distinctUntilChanged((a,b) =>isEqual(a,b))
    );
  }

  get loading$() : Observable<boolean> {
    return this.store$.select(fromMedia.selectMediaLoading);
  }

  selectMediaOnboarding$():Observable<Media[]> {
    return this.store$.select(fromMedia.selectMediaOnboardingEntities, this.propertiesService.user.id);
  }

  loadMediaOnboarding$( language = this.translateService.currentLang, contactId= this.propertiesService.user.id) {
    this.dispatch(mediaOnboardingLoadAction({ language, contactId }));
  }

  get loadingMediaOnboarding$() : Observable<boolean> {
    return this.store$.select(fromMedia.selectMediaOnboardingLoading);
  }

  protected currentPlayback$ = new BehaviorSubject<DetachablePlayback>(undefined);
  protected audioContainer:HTMLDivElement = undefined;
  public playback(source:string|Promise<string>):Playback {
    let renderer  = this.getRenderer(document.body);
    let duration$ = new BehaviorSubject<number>(undefined);
    let stalled$  = new BehaviorSubject<boolean>(true);
    let playing$  = new BehaviorSubject<boolean>(false);
    let position$ = new BehaviorSubject<number>(0);
    let seeking$  = new BehaviorSubject<boolean>(false);
    let makeAbsolute0 = (element:HTMLElement):any=>{
      renderer.setStyle(element,"position","absolute");
      renderer.setStyle(element,"left","0px");
      renderer.setStyle(element,"top","0px");
      renderer.setStyle(element,"width","0px");
      renderer.setStyle(element,"height","0px");
      return element;
    };
    if (!this.audioContainer) {
      this.audioContainer = makeAbsolute0(renderer.createElement("div"));
      renderer.appendChild(document.body,this.audioContainer);
    }
    let audio:HTMLAudioElement = makeAbsolute0(renderer.createElement("audio"));
    renderer.appendChild(this.audioContainer,audio);
    const updatePosition = (position:number,force:boolean)=>{
      // audio.currentTime is not accurate due to fingerprint preventions mechanisms.
      // see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/currentTime
      // so we have to take measures, that audio progress viewing is not jumping back during
      // playback....
      position = position ?? 0;
      position = isValidNumber(duration$.value) ? Math.min(position,duration$.value) : position;
      position = force ? position : Math.max(position,position$.value);
      if (position$.value!=position) {
        //this.logger.trace("UPDATE",position,position$.value);
        position$.next(position);
      }
    }
    const updateStalled = (stalled:boolean)=>{
      if (stalled$.value!=stalled) {
        stalled$.next(stalled);
      }
    }
    const updatePlaying = (playing:boolean)=>{
      if (playing$.value!=playing) {
        playing$.next(playing);
      }
      if (!!playing) {
        updateStalled(false);
      }
    };
    audio.oncanplay = ()=>{
      //this.logger.debug("oncanplay");
      updateStalled(false);
    };
    audio.onwaiting = ()=>{
      //this.logger.debug("onwaiting");
      updateStalled(true);
    };
    audio.onsuspend = ()=>{
      //this.logger.debug("onsuspend");
      updateStalled(true);
    };
    audio.onstalled = ()=>{
      //this.logger.debug("onstalled");
      updateStalled(true);
    };
    audio.onplay    = ()=>{
      //this.logger.debug("onplay");
      updateStalled(false);
      updatePlaying(true);
    };
    audio.onplaying = ()=>{
      //this.logger.debug("onplaying");
      updateStalled(false);
      updatePlaying(true);
    };
    audio.onpause = ()=>{
      //this.logger.debug("onpause",duration$.value,position$.value);
      updatePlaying(false);
    };
    audio.onended = ()=>{
      //this.logger.debug("onended",duration$.value,position$.value);
      //this.logger.debug("onended",audio.currentTime);
      updatePosition(duration$.value,true);
      updatePlaying(false);
    };
    audio.onseeking = ()=>{
      //this.logger.debug("onseeking",audio.currentTime);
      //updatePosition(audio.currentTime);
    };
    audio.onseeked = ()=>{
      //this.logger.debug("onseeked",audio.currentTime);
      seeking$.next(false);
      updatePosition(audio.currentTime,true);
    };
    audio.ontimeupdate = ()=>{
      //this.logger.debug("time",audio.currentTime);
      updatePosition(audio.currentTime,false);
    };
    audio.ondurationchange = ()=>{
      const duration = audio.duration;
      const position = Math.max(0,Math.min(position$.value ?? 0, duration));
      duration$.next(audio.duration);
      audio.currentTime = position;
    };
    let current$ = this.currentPlayback$;
    let source$  = Promise.resolve(source);
    let released = false;
    let detached = false;
    let playback = new class implements DetachablePlayback {
      source$():Promise<string> {
        return source$;
      }
      duration$():Promise<number> {
        if (released) {
          return Promise.reject('already released');
        } else {
          return new Promise<number>((resolve, reject) => {
            source$.catch(reject).then(source => {
              if (!!source) {
                if (audio.src!=source) {
                  duration$.next(undefined);
                  audio.src = source;
                }
                duration$.pipe(
                  filter(duration=>isValidNumber(duration))
                ).subscribe(resolve);
              } else {
                reject();
              }
            });
          });
        }
      }
      pause() {
        //log("DO_PAUSE");
        audio.pause();
      }
      play(): Promise<void> {
        //this.logger.debug("DO_PLAY");
        return new Promise<void>((resolve, reject) => {
          this.duration$().then(duration =>{
            if (isValidNumber(duration) && duration>0) {
              firstValueFrom(seeking$.pipe(filter(seeking=>!seeking)))
                .finally(()=>{
                  //this.logger.debug("PLAY_NOW_AFTER_SEEKING");
                  let current = current$.value;
                  if (current!=playback) {
                    current?.pause();
                    current$.next(playback);
                    current?.detach();
                  }
                  audio.play();
                  resolve();
                });
            } else {
              reject();
            }
          }).catch(reject);
        });
      }
      playing(): boolean {
        return playing$.value;
      }
      playing$(): Observable<boolean> {
        return playing$;
      }
      stalled$(): Observable<boolean> {
        return stalled$;
      }
      position(position?:number): number {
        const loaded = isValidNumber(duration$.value);
        const currentTime = loaded ? audio.currentTime : position$.value;
        if (isValidNumber(position)) {
          if (loaded) {
            position = Math.max(0,Math.min(position,duration$.value));
            if (position>=0 &&
              position<=duration$.value &&
              position!=currentTime) {
              updatePosition(position,true);
              audio.currentTime = position;
              if (audio.seeking!=seeking$.value) {
                seeking$.next(audio.seeking);
              }
            }
          } else {
            updatePosition(Math.max(0,position),true);
          }
        } else if (loaded) {
          updatePosition(currentTime,false);
        }
        //this.logger.debug("POSITION",position,position$.value,"curr",audio.currentTime,"prev",currentTime,"seeking",audio.seeking);
        return position$.value;
      }
      position$(): Observable<number> {
        return position$;
      }
      detach() {
        if (!detached && released && current$.value!=playback) {
          renderer.removeChild(document.body,audio);
          detached = true;
        }
      }
      release() {
        released = true;
        this.detach();
      }
      request() {
        if (!detached) {
          released = false;
        }
      }
    }
    return playback;
  }

  get currentPlayback(): Playback {
    return this.currentPlayback$.value;
  }
  set currentPlayback(playback:Playback) {
    if (this.currentPlayback!=playback) {
      this.currentPlayback?.pause();
      this.currentPlayback$.next(<DetachablePlayback>playback);
    }
  }
  requestCurrentPlayback():Playback {
    const playback = <DetachablePlayback>this.currentPlayback$.value;
    playback?.request();
    return playback;
  }

  syncMedia(media: Media): void {
    this.store$.dispatch(mediaUpdateDoneAction({media}));
  }

  updateMedia(mediaUpdate : Media | [Media, Media], mediaPath?: string[]): Promise<Media> {
    const [media, previous] = mediaUpdate instanceof Array ? [...mediaUpdate] : [mediaUpdate, null];
    //this.logger.debug("MEDIA_SERVICE.updateMedia",mediaUpdate,"path",mediaPath);
    if (!!media?.id) {
      if (!!previous)  {
        const    isEditing = this.isApprovableChange(media, previous);
        const isPublishing = !isEqual(media.published, previous.published);
        const  isApproving = !isEqual(media.review, previous.review);
        const isDownlineIncludeEditing = media.downline_include!==previous.downline_include;
        const   canEditDownlineInclude = this.user.isMember;
        if ((isEditing && !media.editable) ||
          (isDownlineIncludeEditing && !canEditDownlineInclude)) {
          throw new Error ('Media change not permitted')!
        }
        if ((isPublishing && !media.editable) ||
          (isApproving && !media.approvable)) {
          throw new Error ('Media state change not permitted!')
        }
        const state = get(media.review, 'state');
        const approved = state==MediaReviewState.Approved;
        const approve  = isApproving && approved;
        const publish  = isPublishing && media.published;
        if (isEditing && !media.approvable && approved) {
          this.store$.dispatch(mediaUpdateDoneAction({media:{...media, review: null}})); // requires approval after change
        }
        if (publish && media.approvable && !approved) {
          media.review = { ...media.published, state: MediaReviewState.Approved };     // auto approve if published by approver
        }
        if (approve && media.editable && !media.published) {
          media.published = {contact: media.review.contact, time: media.review.time }; // auto publish if approved by author/admin
        }
      }
      const correlationId = this.correlationIdGenerator.next();
      const promise = new Observable<any>(subscriber => {
        const reducer = `updateMedia_${correlationId}`;
        this.store$.addReducer(reducer, (state, action: {
          correlationId?: string,
          media: Media,
          error?: any
        } & Action) => {
          if (action.type == mediaUpdateDoneAction.type  ||
            action.type == mediaUpdateFailedAction.type) {
            this.logger.debug(reducer, state, action);
            if (action.correlationId == correlationId) {
              if (action.type == mediaUpdateDoneAction.type) {
                subscriber.next(action.media);
              } else {
                subscriber.error(action.error);
              }
              subscriber.complete();
              this.store$.removeReducer(reducer);
            }
          }
          return state;
        });
      }).toPromise();
      //this.logger.debug("MEDIA update: media",media,"previous",previous);
      this.store$.dispatch(mediaUpdateAction({media, mediaPath, previous, correlationId}));
      return promise;
    } else {
      return Promise.reject('Invalid media');
    }
  }

  requiresReapprove(media: Media, previous?: Media): boolean {
    previous = previous || media;
    const  reviewState = get(previous, 'review.state') as MediaReviewState;
    return reviewState == MediaReviewState.Approved && !media.approvable && this.isApprovableChange(media, previous);
  }

  isApprovableChange(media: Media, previous?: Media): boolean {
    const acquiredProperties = ['published', 'review', 'touched', 'completed', 'downline_include', 'rating'];
    const tagsFilter = (media: Media) => media.tags.filter(f => !f.startsWith('campaign.'));
    return !isEqual(
      omit({ ...media,    tags: tagsFilter(media)   }, acquiredProperties),
      omit({ ...previous, tags: tagsFilter(previous)}, acquiredProperties)
    );
  }

  deleteMedia(media: Media, mediaPath?: string[], deleteOrphans = false): Promise<Media> {
    return new Promise<Media>((resolve,reject)=>{
      media = {...media,timeDeleted:new Date().getTime()};
      this.dispatch(mediaUpdateAction({media, mediaPath, deleteOrphans}))
        .then(()=>resolve(media))
        .catch(error=>reject(error));
    });
  }

  react(mediaId: string, reaction: Reaction, mediaPath?: string[]): Promise<Media> {
    return new Promise((resolve, reject) => {
      this.store$.pipe(select(fromMedia.selectMedia, mediaId))
        .pipe(take(1))
        .subscribe((media: Media) => {
          //this.logger.debug("MEDIA",media,"!completed",!media?.completed,"!duration>0",!(media?.duration>0));
          if (media) {
            this.httpClient
              .post(
                '/v1.0/media/reaction/' + mediaId,
                { type: reaction }
              )
              .subscribe({
                next: (response: Media) => {
                  if (response) {
                    this.syncMedia(response);
                    resolve(response);
                  } else {
                    reject('media reaction failed on '+mediaId);
                  }
                },
                error: reject
              });
          } else {
            resolve(media);
          }
        });
    });
  }

  /**
   @deprecated
   */
  uploadMediaDashboard(fileType?: string) {
    /*
    const overlayRef = this.uploadOverlayService.open({ data: {
        fileType: fileType,
        onUploadEvent: (event: UploadEvent) => {
          const [type, args] = [...event];
          this.logger.debug("uploaded", fileType, type, args);
          if (type === 'complete') {
            overlayRef.close();
            this.onUploadComplete(fileType, args);
          }
        }
      }});
     */
  }

  uploadMedia(host: ElementRef, options?: any): Observable<MediaUploadRef> { //Observable<boolean> {
    let subscriber: Subscriber<MediaUploadRef>;
    const observable = new Observable<MediaUploadRef>(subscribe => subscriber = subscribe).pipe(shareReplay(1));
    let bottomSheetDismissSubscription: Subscription;
    const onUploadTypeSelect = (uploadType: string) => {
      //this.logger.debug('uploadMedia > FILE TYPE SELECTED', uploadType);
      bottomSheetDismissSubscription?.unsubscribe();
      this.uploadService.upload(host, { fileType: uploadType, meta: options?.media, cropping: options?.cropping })
        .then(uploadRef => this.onUploadRef(uploadRef).then(([uploadId, onComplete]) => [uploadRef, uploadId, onComplete]))
        .then(([uploadRef, uploadId, onComplete]: [UploadRef, string, Promise<[MediaUpload[], any]>]) => {
          const complete: Promise<MediaUpload[]> = onComplete.then(([mediaUploads, fileUploads]) =>
            this.onUploadComplete(uploadType, fileUploads, options?.media)
              .then((completedMediaUploads) => {
                completedMediaUploads.forEach(completedMediaUpload => {
                  const index = mediaUploads.findIndex((mediaUpload, index) => {
                    return completedMediaUpload?.media?.id == mediaUpload?.media?.id;
                  });
                  if (index >= 0) {
                    mediaUploads[index] = {...mediaUploads[index], ...completedMediaUpload};
                  } else {
                    mediaUploads.push(completedMediaUpload);
                  }
                });
                this.loadRequest();
                return mediaUploads;
              })
          );
          complete.then(mediaUploads => this.logger.debug('UPLOAD.COMPLETE', mediaUploads) )
          subscriber.next({ uploadId, uploadRef, options, complete } as MediaUploadRef);
          subscriber.complete();
        })
        .catch(error => {
          this.logger.error('uploadMedia', error);
          subscriber.error(new Error(`Failed file media post request. Error ${error}`));
        });
      // .then(() => bottomSheetRef.dismiss())
      bottomSheetRef.dismiss();
    };
    const onLinkAdd = (link: string): Promise<Media> => {
      //this.logger.debug('uploadMedia > LINK ADDED', link);
      if (!bottomSheetDismissSubscription) {
        bottomSheetDismissSubscription = bottomSheetRef.afterDismissed().subscribe(() => subscriber.complete());
      }
      const tokens = this.parseMediaLink(link);
      if (!tokens) {
        return Promise.reject('Invalid link');
      } else {
        return this.httpClient.post<any>('/v1.0/media/link', link)
          .toPromise()
          .catch(error => {
            this.logger.error('uploadMedia', error);
            return new Error(`Failed link media post request. Error ${error}`);
          });
      }
    };
    const onUploadItemSelect = async (type: string, item: any): Promise<Media> => {
      this.logger.debug('onUploadItemSelect', {type, item});
      if (!bottomSheetDismissSubscription) {
        bottomSheetDismissSubscription = bottomSheetRef.afterDismissed().subscribe(() => subscriber.complete());
      }
      if (type && item) {
        try {
          const body: any = { [type]: item };
          options?.media && (body.media = options.media);
          const media = await lastValueFrom(this.httpClient.post<any>(`/v1.0/media/${type}`, body));
          this.logger.debug('uploadMedia', {media});
          return media;
        } catch(error) {
          this.logger.error('uploadMedia', {error, type, item});
          throw new Error(`Failed media post request [type: ${type}, item: ${item}]. Error ${error}`);
        } finally {
          bottomSheetRef.dismiss()
        }
      } else {
        return Promise.reject(`Invalid arguments ${{type, item}}`);
      }
    };
    const config = {
      data: {
        ...(options ? { uploadTypes: options.uploadTypes, selectors: options.selectors } : undefined),
        onUploadTypeSelect: onUploadTypeSelect,
        // TODO: remove onLinkAdd - use only onUploadItemSelect instead
        onLinkAdd: (link: string): Promise<void> => onLinkAdd(link)
          .then(media => {
            subscriber.next({ complete: Promise.resolve([{file: null, media}]) });
            this.loadRequest();
          })
          .catch(error => subscriber.error(error))
          .then(() => subscriber.complete()),
        onUploadItemSelect: (type: string, item: any): Promise<void> => onUploadItemSelect(type, item)
          .then(media => {
            subscriber.next({ complete: Promise.resolve([{file: null, media}]) });
            this.loadRequest();
          })
          .catch(error => subscriber.error(error))
          .then(() => subscriber.complete()),
      },
      panelClass: ['bottom-sheet'],
      autoFocus: false
    };
    if (!this.bottomSheet) {
      this.bottomSheet = this.injector.get(MatBottomSheet);
    }
    const bottomSheetRef = this.bottomSheet.open(UploadBottomSheetComponent, config);
    return observable; //await lastValueFrom(observable);
  }

  uploadMediaFiles(files: FileList, options?: any): Observable<MediaUploadRef> {
    const promise = this.uploadService.uploadFiles(files, { meta: options?.media })
      .then(uploadRef => this.onUploadRef(uploadRef)
      .then(([uploadId, onComplete]) => [uploadRef, uploadId, onComplete] as [UploadRef, string, Promise<[MediaUpload[], any]>]))
      .then(([uploadRef, uploadId, onComplete]) => {
        const complete: Promise<MediaUpload[]> = onComplete.then(([mediaUploads, fileUploads]) =>
          this.onUploadComplete(undefined, fileUploads, options?.media)
            .then((completedMediaUploads) => {
              completedMediaUploads.forEach(completedMediaUpload => {
                const index = mediaUploads.findIndex((mediaUpload, index) => {
                  return completedMediaUpload?.media?.id == mediaUpload?.media?.id;
                });
                if (index >= 0) {
                  mediaUploads[index] = {...mediaUploads[index], ...completedMediaUpload};
                } else {
                  mediaUploads.push(completedMediaUpload);
                }
              });
              this.loadRequest();
              return mediaUploads;
            })
        );
        return { uploadId, uploadRef, options, complete } as MediaUploadRef;
      })
      .catch(error => {
        this.logger.error('uploadMedia', error);
        return Promise.reject(`Failed file media post request. Error ${error}`);
      });
    return from(promise);
  }

  protected onUploadRef(uploadRef: UploadRef): Promise<[string, Promise<[MediaUpload[], any]>]> {
    return new Promise((resolve, reject) => {
      let completeResolve: (result: [MediaUpload[], any]) => void
      let completeReject: (error: any) => void;
      const complete = new Promise<[MediaUpload[], any]>((resolve, reject) => { completeResolve = once(resolve); completeReject = reject; })
      // NOTES:
      // 1. complete event will not come when all files are removed and upload is fully cancelled
      // 2. cancel-all event is fired after on every upload (even the succeeded ones). it follows the complete event.
      const onCancelAll = (args) => {
        uploadRef.off('cancel-all', onCancelAll);
        //this.logger.info('uploadMedia > onCancelAll', args);
        completeResolve(undefined);
      };

      uploadRef.on('cancel-all', onCancelAll);
      const mediaUploads = [] as MediaUpload[];
      const onUpload = (args) => {
        const uploadId = args.id;
        uploadRef.off('upload', onUpload);
        resolve([uploadId, complete]);
        // subscriber.next({ uploadId, uploadRef, options, complete });
        // subscriber.complete();
        const onSuccess: any = (file, response) =>  {
          let {
            status,   // HTTP status code (0, 200, 300)
            body,     // response body
            uploadURL // the file url, if it was returned
          } = response;
          //this.logger.debug('upload-success', { file, response });
          if (body?.media?.id) {
            mediaUploads.push({file, media: body.media});
          }
        };
        uploadRef.on('upload-success', onSuccess);
        const onError: any = (file, error, response) =>  {
          this.logger.debug('upload-error', { file, error, response });
          if (error.isNetworkError) {
            // TODO: retry?
            //  uploadRef.retry(file.id);
          }
          mediaUploads.push({file, error});
        }
        uploadRef.on('upload-error', onError);
        const onComplete = (args) =>  {
          /*
            args: {
              successful: {
               source: "uploadFile", id: "uppy-gopr2483/mp4-1e-video/mp4-8412158-1537597974000", name: "GOPR2483.MP4", extension: "MP4", meta: {…}, …}
              failed: []
              uploadID: "ck91shnuo00003h9m1wn2uws9"
            }
         */
          //this.logger.debug('uploadMedia -> onComplete', args);
          if (args.uploadID == uploadId) {
            uploadRef.off('complete', onComplete);
            uploadRef.off('upload-success', onSuccess);
            uploadRef.off('upload-error', onError);
            uploadRef.off('cancel-all', onCancelAll); // prevents premature resolution of complete promise with empty uploads array
            //this.logger.debug('uploadMedia -> onComplete', args);
            // this.onUploadComplete(fileType, args, options?.media)
            //   .then((completedMediaUploads) => {
            //     completedMediaUploads.forEach(completedMediaUpload => {
            //       const index  = mediaUploads.findIndex((mediaUpload, index) => {
            //         return completedMediaUpload?.media?.id == mediaUpload?.media?.id;
            //       })
            //       if (index>=0) {
            //         mediaUploads[index] = {...mediaUploads[index], ...completedMediaUpload};
            //       } else {
            //         mediaUploads.push(completedMediaUpload);
            //       }
            //     });
            //     this.loadRequest();
            //     completeResolve(mediaUploads);
            //   })
            //   .catch((error) => completeReject(error));
            completeResolve([mediaUploads, args]);
          }
        };
        uploadRef.on('complete', onComplete);
      };
      uploadRef.on('upload', onUpload);

      uploadRef.upload().then((result) => {
        //this.logger.debug('upload -> RESULT', result);
        uploadRef.release();
        // onUpload was not called - uploadId, upload args are undefined. media uploads are empty
        completeResolve([mediaUploads, undefined]);
        resolve([undefined, complete])
      });
      // uploadRef.upload().then((result) => { uploadRef.release(); !subscriber.closed && subscriber.complete() });
    })
  }

  protected onUploadComplete(uploadType: string, fileUploads: any, media?: Partial<Media>): Promise<MediaUpload[]> {
    let promise: Promise<MediaUpload[]>;
    // in order to be able to create media from upload the main application server needs additional metadata when:
    // the upload is performed using tus (currently enabled only for videos) or
    // when audio file is uploaded (as its duration is measured and provided by the client).
    if ((!uploadType || uploadType == 'video' || uploadType == 'audio' ) && fileUploads.successful.length > 0) {
      const promises: Promise<any>[] = [];
      fileUploads.successful.forEach((result: any) => {
        const  file = result.data;
        const vimeo = result.vimeo;
        const mediaType = uploadType || this.uploadService.resolveUploadType(file.type)
        const meta: any = {
          category: 'upload',
          file: {
            name: file.name,
            type: file.type,
            size: file.size,
            extension: result.extension,
            lastModified: file.lastModified,
            language: this.translateService.currentLang,
            mediaType: mediaType
          },
          upload: {
            started: result.progress.uploadStarted,
            completed: new Date().getTime(),
            url: result.uploadURL
          }
        };
        if (vimeo) {
          meta.vimeo = {
            link: vimeo.link,
            id: vimeo.id
          }
        }
        if (mediaType == 'audio') {
          const uploadedMedia: Media = get(result, 'response.body.media') as Media;
          if (uploadedMedia && uploadedMedia.id) {
            const  file = result.data;
            const   url = URL.createObjectURL(file);
            const audio = new Audio(url);
            // audio.preload = 'metadata';
            promises.push(new Promise(resolve => {
              audio.addEventListener('loadedmetadata', function onLoadedMetadata() {
                audio.removeEventListener('loadedmetadata', onLoadedMetadata);
                this.logger.debug('DURATION', audio.duration);
                meta.media =  {
                  ...media,
                  id: uploadedMedia.id,
                  duration: audio.duration
                };
                audio.remove();
                resolve(meta);
              }.bind(this));
            }));
          } else {
            promises.push(Promise.reject('Audio media not returned by the server.'));
          }
        } else {
          meta && (meta.media = media);
          promises.push(Promise.resolve(meta));
        }
      });
      promise = Promise.all(
        promises.map(promise => promise.catch(error => error instanceof Error ? error : new Error(error)))
      ).then(results => {
        const body: any[] = [];
        results.forEach(result => {
          if (result instanceof Error) {
            this.logger.error('Failed to get metadata for uploaded file. Error', result);
          } else {
            body.push(result);
          }
        });
        return lastValueFrom(this.cacheId$.pipe(take(1))).then(cacheId => {
          return this.httpClient.post<Media[]>(`/v1.0/media/upload/meta/${cacheId}`, body)
              .toPromise()
              .then(result => {
                return result.reduce((result, media, index) => {
                  // TODO: change server to communicate possible media upload meta errors and handle them here
                  result.push({ file: fileUploads.successful[index], media });
                  return result;
                }, [] as MediaUpload[])
              })
              .catch((error) => { this.logger.error('Failed upload metadata post request'); return Promise.reject(error);});
        });
      })
    } else {
      promise = Promise.resolve([]);
    }
    return promise;
  }

  get filters$() : Observable<string[]> {
    return this.getCombinedFilters$();
  }

  /**
   * set typed filters for all types at once...
   * @param typedFilters  0.n typed filters
   * @param remove        callback to check if existing type/filters other the given types should be removed....
   * @returns boolean true if updated, else false
   */
  setTypedFilters(typedFilters:{[key:string]:string[]},remove?:(type:string,filters:string[])=>boolean) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      typedFilters = {...typedFilters};
      Object.keys(typedFilters).forEach(type=>[...typedFilters[type]].sort());
      let updated = false;
      let added   = {...typedFilters};
      firstValueFrom(this.getTypedFilters$((type,previous)=>{
        delete added[type];
        if (!updated) {
          const filters = typedFilters[type];
          updated = !!filters || !remove ? !isEqual(filters,previous) : remove(type,previous);
        }
        return true;
      }))
        .then(allFilters => {
          updated = updated || Object.keys(added).length>0;
          this.dispatch(mediaSetTypedFiltersAction({filters:typedFilters,remove}))
            .then(()=>resolve(updated))
            .catch(error=>reject(error));
        })
        .catch(error=>reject(error));
    });
  }

  /**
   * get all typed filters which are not excluded by given callback
   * @param exclude     callback to check if this type of filter should not be returned
   * @returns a copy of all requested typed filters
   */
  getTypedFilters$(exclude?:(type:string,filters:string[])=>boolean) : Observable<{[key:string]:string[]}> {
    return this.store$.select(fromMedia.selectMediaFilters).pipe(
      map(filters=>{
        const result:{[key:string]:string[]} = {};
        Object.keys(filters).forEach(type=>{
          if (!exclude || !exclude(type,filters[type])) {
            result[type] = [...filters[type]];
          }
        });
        return result;
      })
    );
  }

  /**
   * get all typed filters which are not excluded by given callback in one string array
   * @param exclude     callback to check if this type of filter should not be returned
   * @returns a combined array of all requested typed filters
   */
  getCombinedFilters$(exclude?:(type:string,filters:string[])=>boolean,caller?:string) : Observable<string[]> {
    const result$ = new ReplaySubject<string[]>(1);
    return this.store$.select(fromMedia.selectMediaFilters).pipe(
      //tap(filters=>console.log("getCombinedFilters$.1",filters,caller)),
      map(filters=>{
        const result:string[] = [];
        Object.keys(filters).forEach(type=>{
          if (!exclude || !exclude(type,filters[type])) {
            result.push(...filters[type]);
          }
        });
        //console.log("getCombinedFilters$.2",result,caller);
        return result.sort();
      }),
      distinctUntilChanged((a,b)=>isEqual(a,b)),
      switchMap(filters=>{
        result$.next(filters);
        return result$;
      })
    );
  }

  updateSearchTerm(term : string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dispatch(mediaUpdateSearchTermAction({term}))
        .then(()=>resolve())
        .catch(error=>reject(error));
    });
  }

  select(selectedIndex: number): void {
    //of(new MediaSelectAction(selectedIndex)).pipe(first()).subscribe(this.store$);
    this.store$.dispatch(mediaSelectAction({selectedIndex}));
  }


  markPlayed(mediaId:string, force = false): Promise<void> {
    return new Promise((resolve,reject)=>{
      this.store$.select(fromMedia.selectMedia, mediaId)
        .pipe(take(1))
        .subscribe((media: Media) => {
          //this.logger.debug("MEDIA",media,"!completed",!media?.completed,"!duration>0",!(media?.duration>0));
          if (force || !!media && !media.completed && (!media.readOnly || !!media.readOnlyReact) && !(media.duration>0)) {
            this.httpClient.post("/v1.0/media/reaction/" + mediaId, {
              played: '[]'
            }).subscribe((response: Media) => {
              if (response) {
                this.played(mediaId,{
                  played: response.played,
                  playedTime: response.playedTime,
                  completed: response.completed,
                  touched: response.touched
                });
                resolve();
              } else {
                reject('media reaction failed on '+mediaId);
              }
            },function(error) {
              reject(error);
            });
          } else {
            resolve();
          }
        });
    });
  }

  played(mediaId: string, status: {
    played?:string,
    playedTime?:number,
    touched?: boolean,
    completed?: boolean,
  }) {
    //this.logger.debug("played",mediaId,status);
    this.store$.pipe(select(fromMedia.selectMedia, mediaId))
      .pipe(take(1))
      .subscribe((media: Media) => {
        if (media) {
          media = cloneDeep(media);
          //this.logger.debug("mediaService.played 2: playedTime:"+media.playedTime+" played:"+media.played);
          if (status.played!=undefined) {
            media.played = status.played;
          }
          if (status.playedTime!=undefined) {
            media.playedTime = status.playedTime;
          }
          if (status.touched!=undefined) {
            media.touched = status.touched;
          }
          if (status.completed!=undefined) {
            media.completed = status.completed;
          }
          //this.logger.debug("played", media, "status",status);
          this.store$.dispatch(mediaUpdateDoneAction({media}));
        }
      });
  }

  getMediaExtension(media:Media):string {
    const link = media.links?.length>0 ? media.links[0] : media.cover;
    if (!!link) {
      const extensions = !!link.contentType ? mimeExtensions[link.contentType] : undefined;
      //this.logger.debug("EXTENSION",link,extensions);
      if (!extensions || extensions.length>1) {
        const path  = link.link ?? '';
        const index = path.lastIndexOf('.');
        const extension = path.substring(index+1);
        return extensions?.length>1 ?
          extensions.includes(extension) ? extension : extensions[0] :
          extension;
      }
      return extensions[0];
    }
    return undefined;
  }

  getMediaCoverSrc(media: Partial<Media>, maxWidth?:number, fallbackToDefault:boolean = true): string {
    let src:string = undefined;
    if (media) {
      // take serverUrl as rootPath is undefined - all relative media links
      // (i.e. the ones with start with /) will be resolved with respect ot this root path.
      const rootPath = media.rootPath ?? this.environment.serverUrl;
      const contentType = media.links?.length>0 ? media.links[0].contentType.split(";")[0] : undefined;
      if (media.cover && media.cover.link) {
        src = media.cover.link.startsWith('/') ? `${rootPath}${media.cover.link}` : media.cover.link;
        /*
        if (media.cover.link) {
          return cover.link.startsWith('/') ? `${this.environment.serverUrl}${cover.link}` : cover.link;
        } else if ('application/pdf'===cover.contentType) {
          return '/assets/media/cover/pdf.png'
        }*/
      } else if (media.links && media.links.length && media.links[0].link) {
        if (media.mediaType=='image') {
          src = media.links[0].link.startsWith('/') ? `${rootPath}${media.links[0].link}` : media.links[0].link;
        } else if (media.mediaType=='binary') {
          if (!!contentType) {
            switch(contentType) {
              case 'application/pdf':
                src = '/assets/media/cover/PDF.png';
                break;
              case 'application/zip':
                src = '/assets/media/cover/ZIP.png'
                break;
              case 'application/msword':
              case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                src = '/assets/media/cover/DOC.png'
                break;
              case 'application/vnd.ms-excel':
              case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                src = '/assets/media/cover/XLS.png'
                break;
              case 'application/vnd.ms-powerpoint':
              case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                src = '/assets/media/cover/PPT.png'
                break;
            }
            if (!src && fallbackToDefault) {
              src = '/assets/media/cover/BIN.png';
            }
          }
        } else if (media.mediaType=='audio') {
          src = '/assets/media/cover/AUDIO.png';
        } else if (media.mediaType=='iap') {
          src = '/assets/media/cover/IAP.png';
        } else {
          const mediaId = this.getYoutubeMediaId(media);
          if (mediaId) {
            src = `https://img.youtube.com/vi/${mediaId}/0.jpg`;
          }
        }
      }
    }
    if (!src && fallbackToDefault) {
      src = '/assets/media/cover/default.jpg';
    }
    if (!!src) {
      if (!!maxWidth && maxWidth>=80 && (src.startsWith('/') || src.startsWith(this.environment.serverUrl))) {
        if (src.includes('?')) {
          return `${src}&max-width=${maxWidth}`;
        }
        return `${src}?max-width=${maxWidth}`;
      } else if (src.startsWith('https://i.vimeocdn.com/')) {
        try {
          // this.logger.debug('getMediaCoverSrc -> vimeoImageSizeRegExp.exec', src);
          const match = this.vimeoImageSizeRegExp.exec(src);
          if (match.length == 3) {
            let width  = Number(match[1]);
            let height = Number(match[2]);
            if (width > maxWidth && height > 0) {
              let replace = `_${Math.round(maxWidth)}x${Math.round((maxWidth/width)*height)}.`;
              src = src.replace(this.vimeoImageSizeRegExp,replace);
              //this.logger.debug("src",src,"width",width,"height",height);
            }
          }
          this.vimeoImageSizeRegExp.lastIndex = 0;
        } catch(e) {
          this.logger.error('getMediaCoverSrc', 'src', src, e);
        }
      }
    }
    return src;
  }

  getMediaCoverHeight(media: Partial<Media>, defaultHeightPct?:number): number {
    if (!!media) {
      const roundToTwo = (value: number) => Number.parseFloat(value.toFixed(4));
      if (!!media.cover?.link && media.cover.width>0 && media.cover.height>0) {
        let ratio = media.cover.height/media.cover.width;
        let pct   = 100 * ratio;
        return roundToTwo(pct);
      } else if (media.links?.length>0 && !!media.links[0].contentType) {
        const isYoutubeMedia = this.isYoutubeMedia(media);
        const link = <ImageLink>media.links.find(link=>link.width>0 && link.height>0) ?? (isYoutubeMedia?{ width: 480, height: 360 }:undefined);
        if (!!link) {
          let ratio = link.height/link.width;
          let pct   = 100 * ratio;
          return roundToTwo(pct);
        } else if (media.mediaType=='binary') {
          if (!!media.links[0].contentType && media.links[0].contentType.startsWith('application/pdf')) {
            return defaultHeightPct ?? 56.25;
          }
        } else { //if (media.mediaType=='audio') {
          return defaultHeightPct ?? 56.25;
        }
      }
    }
    return 0;
  }

  isMediaReady(media: Partial<Media>): boolean {
    if (media) {
      switch (media.mediaType) {
        case 'video':
          return !!media.links && media.links.length>0 && (!!media.cover && !!media.cover.link || this.isYoutubeMedia(media)) ;
        case 'iap':
          // first link - the zip file
          // second link html
          return media.links?.length>=2;
          /* return media.links?.length>=3 &&
                 media.links[1].contentType=='text/html' &&
                // (!media.links?.find(link => link.type==MediaType.video) ||
                !!media.links?.find(link => link.type==MediaType.video && link.quality=='hls') //)*/
        case 'image':
        case 'binary':
        case 'audio':
          return !!media.links && media.links.length>0 && !!media.links[0].link;
        case 'survey': return !!(media?.links?.[0] as SurveyLink)?.survey;
      }
    }
    // this.logger.warn("media.not.ready", media);
    return false;
  }

  getMediaRating(media: Media): number {
    let totalRating: number, totalRatingCount: number;
    totalRating = totalRatingCount = 0;
    const ratings = media.reactionSummary?.ratings;
    if (ratings) {
      Object.keys(ratings).forEach((key) => {
        const  rating = Math.max(0, Math.min(Number(key) || 0, 5));
        if (rating > 0) {
          const segment = ratings[rating];
          totalRating  += rating * segment;
          totalRatingCount += segment;
        }
      });
    }
    return totalRatingCount > 0 ? totalRating / totalRatingCount : 0;
  }

  getMediaPlayedBadgeInfo(media: Media): { percent?:number, icon?:string, label?:string, class:string } {
    let result = undefined;
    if (media && media.links && media.links.length>0) {
      if (!media.touched) {
        result = { label:'media.new', class:'new' };
      } else if (media.duration>0 && media.playedTime>=0) {
        let duration   = Math.max(1.0,Math.floor(media.duration));
        let playedTime = Math.max(1.0,Math.floor(media.playedTime));
        let percent    = playedTime*100/duration;
        //console.log("DURATION",duration,"PLAYEDTIME",playedTime,"percent",percent);
        if (percent<=99.0) {
          result = {
            icon: media.mediaType==MediaType.audio ? 'hearing' : 'eye',
            label: Math.round(percent).toString()+'%',
            class: 'touched'
          };
        }
      }
    }
    // console.debug('playedBadgeInfo()', media, result);
    return result;
  }

  isFolderMedia(media:Partial<Media>): boolean {
    return !!media?.tags?.includes('folder');
  }

  getPrimaryAction(media:Partial<Media>): MediaAction {
    return media?.actions?.find(action=>!!action.primary);
  }

  getMediaViewIcon(media:Partial<Media>): string {
    if (media) {
      if (this.isFolderMedia(media)) {
        return 'folder_open';
      } else {
        switch (media.mediaType) {
          case 'video':
          case 'audio':
          case 'iap':
            return 'play_arrow';
          case 'image':
          case 'binary':
          case 'survey':
            return 'search';
        }
      }
    }
    return undefined;
  }

  getMediaInfoIcon(media:Partial<Media>): string {
    if (media) {
      if (this.isFolderMedia(media)) {
        return 'folder_open';
      } else {
        switch (media.mediaType) {
          case 'video':
          case 'audio':
          case 'iap':
            return 'play_arrow';
          case 'image':
          case 'binary':
          case 'survey':
            return 'search';
        }
      }
    }
    return undefined;
  }

  isMediaViewable(media:Partial<Media>): boolean {
    return media?.links?.length>0 &&
      (media.mediaType!='binary' || media.links[0].contentType?.startsWith('application/pdf'));
  }

  isMediaApproved(media:Partial<Media>): boolean {
    return media?.review?.state == MediaReviewState.Approved;
  }

  isMediaApprovalPending(media:Partial<Media>): boolean {
    return !!media?.published && (!media?.review || media?.review?.state == MediaReviewState.Pending);
  }

  isMediaDeclined(media:Partial<Media>): boolean {
    return media?.review?.state == MediaReviewState.Declined;
  }

  parseMediaLink(link: string): [ string, string ] { // [source, mediaId] - e.g. ['app', 123456] or ['youtube', 'ABC123']
    link = link || '';
    if (link.startsWith('/') || link.startsWith(this.environment.serverUrl)) {
      return ['app', link.substring(link.lastIndexOf('/'))]
    } else {
      const match = link.match(this.youtubeLinkRegex);
      return match && match.length==2 ? ['youtube', match[1]] : undefined;
    }
  }

  getYoutubeMediaId(media: Partial<Media>): string {
    let mediaId = undefined;
    if (media && media.links) {
      for (let i=0; i<media.links.length; i++) {
        const link = media.links[i];
        if (link.contentType.toLowerCase() == 'video/x-youtube') {
          const [source, id] = [...this.parseMediaLink(link.link)];
          if (source=='youtube') {
            mediaId = id;
            break;
          }
        }
      }
    }
    return mediaId;
  }

  isYoutubeMedia(media: Partial<Media>): boolean {
    return !!this.getYoutubeMediaId(media);
  }

  downloadMedia(media: Media, host: Element, unblockDelay?: number): Promise<Blob> {
    const url = media.links.length > 0 && media.links[0].link;
    if (url) {
      return this.downloadService
        .download(url, host, { unblockDelay })
        .catch(error => { throw new Error(`Failed to download media. Error: ${error}`); })
    } else {
      return Promise.reject("Failed to download media. Error: missing media link.");
    }
  }

  // see: https://css-tricks.com/making-an-audio-waveform-visualizer-with-vanilla-javascript/
  getAudioAnalysis$(source:string):Promise<AudioAnalysis> {
    //window.AudioContext = window.AudioContext || window.webkitAudioContext;
    return new Promise<AudioAnalysis>((resolve, reject) => {
      const audioContext = new AudioContext();
      const filterData = (audioBuffer,points) => { // Number of points we want to have in our final data set
        const rawData = audioBuffer.getChannelData(0); // We only need to work with one channel of data
        const blockSize = Math.floor(rawData.length / points); // the number of points in each subdivision
        const filteredData = [];
        for (let i = 0; i < points; i++) {
          let blockStart = blockSize * i; // the location of the first sample in the block
          let sum = 0;
          for (let j = 0; j < blockSize; j++) {
            sum = sum + Math.abs(rawData[blockStart + j]) // find the sum of all the points in the block
          }
          filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
        }
        return filteredData;
      }
      const normalizeData = filteredData => {
        const multiplier = Math.pow(Math.max(...filteredData), -1);
        return filteredData.map(n => Math.floor(n * multiplier * 255));
      }
      fetch(source)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
        .then(audioBuffer => {
          resolve(new class implements AudioAnalysis {
            duration():number {
              return audioBuffer.duration;
            }
            wave(points:number):Uint8Array {
              return Uint8Array.from(normalizeData(filterData(audioBuffer,points)));
            }
          });
        })
        .catch(reject);
    });
  }

  replaceName(label:string):string {
    return this.translateService.parser.interpolate(label,{ firstName: this.user.firstName || this.user.name, name: this.user.name });
  }

  displayName(media: Media, firstName?: string, lastName?: string):string {
    if (media?.name) {
      firstName = firstName || '';
      lastName  = lastName || '';
      let name  = `${firstName} ${lastName}`.trim() || this.propertiesService.user.firstName || this.propertiesService.user.name;
      return this.translateService.parser.interpolate(media.name,{ firstName: firstName || name, name: name });
    }
    return media?.name;
  }

  protected getRenderer(host: any) {
    return this._renderer ? this._renderer : this.rendererFactory.createRenderer(host, null);
  }
}
