import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MaterialModule} from 'material';
import {AuthenticationRoutingModule} from './authentication-routing.module';
import {LoginPageComponent} from './containers/login/login-page.component';
import {RouterModule} from "@angular/router";
import {StoreModule} from "@ngrx/store";
import {NULL_USER, StorageModule} from "core";
import {authenticationFeatureKey, authenticationReducer} from "./store/reducers";
import {EffectsModule} from "@ngrx/effects";
import {AuthenticationEffects} from "./store/effects";
import {NativeModule, PipesModule, QrCodeAllModule, SharedModule} from "shared";
import {Clipboard} from "@ionic-native/clipboard/ngx";
import {AuthenticationModuleConfig, AuthenticationRoutingConfig} from "./authentication.module.config";
import {LoginQrCodeDialogComponent} from "./containers/login-qr-code-dialog/login-qr-code-dialog.component";
import {nullUserFactory} from "properties";
import {LayoutModule} from "layout";
import {GroupModule} from "group";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    NativeModule,
    PipesModule,
    FlexLayoutModule,
    MaterialModule,
    AuthenticationRoutingModule,
    QrCodeAllModule,
    StorageModule,
    StoreModule.forFeature(authenticationFeatureKey, authenticationReducer),
    EffectsModule.forFeature([AuthenticationEffects]),
    LayoutModule,
    // GroupModule
  ],
  declarations: [
    LoginPageComponent,
    LoginQrCodeDialogComponent
  ],
  providers: [
    Clipboard,
    // using User.NULL causes library build time error:
    // "Only initialized variables and constants can be referenced in decorators"
    // { provide: NULL_USER, useValue: User.NULL}
    { provide: NULL_USER, useFactory: nullUserFactory}
  ],
  // entryComponents: [
  //   LoginQrCodeDialogComponent
  // ]
})
export class AuthenticationModule {
  constructor(@Optional() @SkipSelf() protected parentModule: AuthenticationModule) {
    // Import guard
    if (parentModule) {
      // throw new Error(`${parentModule} has already been loaded. Import AuthenticationModule module only once.`);
    }
  }

  static forRoot(config: AuthenticationModuleConfig): ModuleWithProviders<AuthenticationModule> {
    return {
      ngModule: AuthenticationModule,
      providers: [
        { provide: AuthenticationRoutingConfig, useValue: config.routing },
        config.loginObserver
      ]
    };
  }
}
