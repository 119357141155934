<ng-container *ngIf="partnerId" [ngSwitch]="hasMenu">
  <ng-container *ngSwitchCase="true">
    <a href="javascript:void(0);" [matMenuTriggerFor]="partnerIdMenu">
      <span [className]="cssClass">{{partnerId}}</span>
    </a>
    <mat-menu #partnerIdMenu="matMenu">
      <button mat-menu-item (click)="goToLink(partnerUrl)">
        <mat-icon>how_to_reg</mat-icon>
        <span translate>domain.pm.labels.quickstart</span>
      </button>
      <button mat-menu-item (click)="goToLink(shopUrl)">
        <mat-icon>shopping_cart</mat-icon>
        <span translate>domain.pm.labels.shop</span>
      </button>
      <div *ngIf="hasCloneLink && (platform.is('hybrid') || !environment.production)">
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="goToClone()">
          <mat-icon>exit_to_app</mat-icon>
          <span translate>domain.labels.openWebApp</span>
        </button>
      </div>
    </mat-menu>
  </ng-container>
  <span *ngSwitchCase="false" [className]="cssClass">{{partnerId}}</span>
</ng-container>
