/* projects/media/src/lib/components/media-details-info/media-details-info.component.scss */
:root {
  --color-primary-50: #ee68d3;
  --color-primary-100: #ee68d3;
  --color-primary-200: #aa138c;
  --color-primary-300: #aa138c;
  --color-primary-400: #931179;
  --color-primary-500: #7c0e66;
  --color-primary-600: #650b53;
  --color-primary-700: #4e0940;
  --color-primary-800: #4e0940;
  --color-primary-900: #000000;
  --color-primary-A100: #7c0e664C;
  --color-primary-A200: #7c0e6680;
  --color-primary-A400: #7c0e66B3;
  --color-primary-A700: #7c0e66E6;
  --color-primaryContrast-50: #ffffff;
  --color-primaryContrast-100: #ffffff;
  --color-primaryContrast-200: #ffffff;
  --color-primaryContrast-300: #ffffff;
  --color-primaryContrast-400: #ffffff;
  --color-primaryContrast-500: #ffffff;
  --color-primaryContrast-600: #f2f2f2;
  --color-primaryContrast-700: #e6e6e6;
  --color-primaryContrast-800: #e6e6e6;
  --color-primaryContrast-900: #999999;
  --color-primaryContrast-A100: #ffffff4C;
  --color-primaryContrast-A200: #ffffff80;
  --color-primaryContrast-A400: #ffffffB3;
  --color-primaryContrast-A700: #ffffffE6;
  --color-accent-50: #fef5f8;
  --color-accent-100: #fef5f8;
  --color-accent-200: #fef5f8;
  --color-accent-300: #ed6d98;
  --color-accent-400: #eb5688;
  --color-accent-500: #e83f78;
  --color-accent-600: #e52868;
  --color-accent-700: #da1a5b;
  --color-accent-800: #da1a5b;
  --color-accent-900: #510a22;
  --color-accent-A100: #e83f784C;
  --color-accent-A200: #e83f7880;
  --color-accent-A400: #e83f78B3;
  --color-accent-A700: #e83f78E6;
  --color-accentContrast-50: #ffffff;
  --color-accentContrast-100: #ffffff;
  --color-accentContrast-200: #ffffff;
  --color-accentContrast-300: #ffffff;
  --color-accentContrast-400: #ffffff;
  --color-accentContrast-500: #ffffff;
  --color-accentContrast-600: #f2f2f2;
  --color-accentContrast-700: #e6e6e6;
  --color-accentContrast-800: #e6e6e6;
  --color-accentContrast-900: #999999;
  --color-accentContrast-A100: #ffffff4C;
  --color-accentContrast-A200: #ffffff80;
  --color-accentContrast-A400: #ffffffB3;
  --color-accentContrast-A700: #ffffffE6;
}
:host {
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.container {
  position: relative;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
}
.container form {
  display: flex;
  display: flex;
  flex-direction: column;
}
.container form > mat-checkbox {
  padding-bottom: 0.9em;
}
.media-name {
  width: 252px;
  overflow-wrap: break-word;
}
textarea::-webkit-scrollbar {
  width: 0 !important;
}
textarea {
  overflow: -moz-scrollbars-none;
}
.separator {
  border-width: 1px 0 0 0;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  margin: 16px -24px;
}
.author-date {
  border-width: 1px 0;
  margin: 16px -24px;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  padding: 8px 24px;
  display: flex;
}
.author-date img {
  cursor: pointer;
  margin-right: 8px;
}
.author-date-text {
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.author-date-text span {
  font-size: 14px;
  display: flex;
}
.author-date-text .author-name {
  font-weight: 500;
  cursor: pointer;
  display: flex;
}
more-text {
  width: 252px;
  font-size: 14px;
}
.spacing {
  height: 24px;
}
.header,
.media-id {
  display: block;
  padding: 0;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.54);
  line-break: anywhere;
}
.header {
  margin: 0 0 6px;
}
.media-id > span {
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  user-select: all;
}
.state-container {
  margin-bottom: 25px;
}
.state-container .state-info,
.state-container .reaction {
  display: flex;
  align-items: center;
}
.state-container .state-info > mat-icon,
.state-container .reaction > mat-icon {
  margin-right: 8px;
}
.state-container .state-info > span,
.state-container .reaction > span {
  font-size: 13.5px;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.54);
}
.state-container .state-info > span:first-of-type,
.state-container .reaction > span:first-of-type {
  display: block;
  flex: 1 1 auto;
  word-break: break-word;
}
.state-container .state-info .mat-mdc-icon-button,
.state-container .reaction .mat-mdc-icon-button {
  margin-right: -20px;
  color: #9e9e9e;
}
.state-container .state-info.state-info.published mat-icon,
.state-container .reaction.state-info.published mat-icon {
  color: orange;
}
.state-container .state-info.state-info.approved mat-icon,
.state-container .reaction.state-info.approved mat-icon {
  color: var(--color-primary);
}
.state-container .state-info.state-info.declined mat-icon,
.state-container .reaction.state-info.declined mat-icon {
  color: #f44336;
}
.state-container .state-info.reaction > mat-icon,
.state-container .reaction.reaction > mat-icon {
  color: #f44336;
}
.state-container .state-info.reaction .mat-mdc-icon-button.delete:hover,
.state-container .reaction.reaction .mat-mdc-icon-button.delete:hover {
  color: #f44336;
}
:host-context(.color-scheme-dark) .header,
:host-context(.color-scheme-dark) .media-id {
  color: #aeafb0;
}
:host-context(.color-scheme-dark) .state-info > span,
:host-context(.color-scheme-dark) .reaction > span {
  color: #aeafb0;
}
:host-context(.color-scheme-dark) .separator {
  border-color: rgba(255, 255, 255, 0.2);
}
:host-context(.color-scheme-dark) .author-date {
  border-color: rgba(255, 255, 255, 0.2);
}
:host-context(.color-scheme-dark) a {
  color: #E5E5E5;
}
:host-context(.color-scheme-dark) mat-hint {
  color: #aeafb0;
}
/*# sourceMappingURL=media-details-info.component-BCLSNHG7.css.map */
