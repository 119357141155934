import {Pipe, PipeTransform} from '@angular/core';
import {DomainService} from "../services/domain.service";

@Pipe({ name: 'markup' })
export class MarkupPipe implements PipeTransform {

  // uses lazy/non-greedy lookahead to avoid formatting characters duplication
  protected boldItalicStrike = /\*(?<options>(?:([bis])(?![^\*]*?\2))+)?\*(?<text>.*?)\*\*/gim;

  // does not prevent repeated formatting characters e.g. *biis*text**
  // protected boldItalicStrike = /\*(?<options>[bis]{0,3})?\*(?<text>.*?)\*\*/gim;

  // link/email should be between word boundaries e.g. will not match ~kht@troyer.co.at
  // protected linkOrEmail = /\b(?<prefix>(?:https?:\/\/)|\S*@)?(?<domain>(?:(?=[a-z0-9-]{1,63}\.)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63})(?:\?(?<query>.*))?\b/gim;

  // link/email should be preceded by a whitespace or start of line and end with a whitespace or end of line
  // use lookahead and lookbehind to ensure the final match does not include the aforementioned separator characters
  // (e.g. exclude start/end of line and leading/trailing whitespace)
  // ATTENTION: Safari (desktop & mobile) does not support lookahead/lookbehind
  // query only:
  // protected linkOrEmail = /(?<=^|\s)(?<prefix>(?:https?:\/\/)|\S*@)?(?<domain>(?:(?=[a-z0-9-]{1,63}\.)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63})(?:\?(?<query>.*))?(?=$|\s)/gim;
  // path + query:
  // protected linkOrEmail = (?<=^|\s)(?<prefix>(?:https?:\/\/)|\S*@)?(?<domain>(?:(?=[a-z0-9-]{1,63}\.)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63})(?:\/(?<path>[^?.]*?))?(?:\?(?<query>.*?))?(?=$|\s)

  // Safari compatible regex: avoid lookahead and lookbehind
  // should start at a word boundary: \b
  // and end at:
  //  - end of line - $,
  //  - whitespace - \s
  //  - ) or ]
  //  - <br/> - looks like chat needs this?
  // detects: prefix, domain, path, query and fragment:
  protected linkOrEmail = /\b(?<linkOrEmail>(?<prefix>(?:https?:\/\/)|\S*@)?(?<domain>(?:(?=[a-z0-9-]{1,63}\.)[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63})(?:\/(?<path>[^?#]*?)\/?)?(?:\?(?<query>[^#]*?))?(?:#(?<fragment>.*?))?)(?:$|\s|\)|\]|<br\/>)+/gim;

  protected newline = /\n$/gim;
  protected br = /<br\s*\/>/gim;

  constructor(private domainService: DomainService) {
  }

  transform(input: string, args= { clean: false }) {
    const markup = (str: string, clean: boolean): string => {
      let match: RegExpExecArray;
      while ((match = this.boldItalicStrike.exec(str))!==null) {
        let out = match.groups['text'];
        if (out) {
          if (!clean) {
            let options = match.groups['options'] || 'b';
            while (options) {
              const option = options[0];
              switch (option) {
                case 'b': out = `<b>${out}</b>`; break;
                case 'i': out = `<i>${out}</i>`; break;
                case 's': out = `<strike>${out}</strike>`; break;
              }
              options = options.substring(option.length);
            }
          }
          str = str.replace(match[0], out);
          this.boldItalicStrike.lastIndex = 0;
        }
      }
      if (!clean) {
        const matches: RegExpExecArray[] = [];
        while ((match = this.linkOrEmail.exec(str))!==null) {
          matches.push(match);
        }
        if (matches.length > 0) {
          let out = '';
          for (let i=0, max=matches.length; i<max; i++) {
            const match = matches[i];
            const previousMatch = i > 0 ? matches[i-1] : null;
            out += str.substring(previousMatch ? previousMatch.index + previousMatch[0].length : 0, match.index);
            const prefix = match.groups['prefix'];
            const domain = match.groups['domain'];
            const path   = match.groups['path'];
            const query  = match.groups['query'];
            if (domain) {
              if (prefix?.endsWith('@') && !(path || query)) {
                const email = match.groups['linkOrEmail'];
                // str = str.replace(email, `<a href="mailto:${email}">${email}</a>`);
                out += str.substr(match.index, match[0].length).replace(email, `<a href="mailto:${email}">${email}</a>`);
              } else {
                const link = match.groups['linkOrEmail'];
                let href = link;
                let replace = !!prefix;
                if  (!replace) {
                  for (const tld of this.domainService.tlds) {
                    if (domain.endsWith(tld)) {
                      href = `http://${href}`;
                      replace = true;
                      break;
                    }
                  }
                }
                const substr = str.substr(match.index, match[0].length);
                if (replace) {
                  // str = str.replace(link, `<a target="_blank" href="${href}">${link}</a>`)
                  out += substr.replace(link, `<a target="_blank" href="${href}">${link}</a>`);
                } else {
                  out += substr;
                }
              }
            }
          }
          const lastMatch = matches[matches.length-1];
          str = out + str.substring(lastMatch.index+lastMatch[0].length, str.length);
        }
        str = str?.replace(this.newline, '<br/>');
      } else {
        str = str?.replace(this.br, '\n');
      }
      return str;
    };
    const segments = (input||'').split('##');
    //console.debug('MarkupPipe -> TRANSFORM', {input, args, segments});
    return segments.reduce((output, segment, index) => {
        output += index%2 ? segment : markup(segment, args.clean);
        return output;
    }, '');
  }
}
