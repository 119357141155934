import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormBuilder, NgControl} from "@angular/forms";
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from "@angular/material/form-field";
import {BooleanInput, coerceBooleanProperty, coerceNumberProperty} from "@angular/cdk/coercion";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Subject} from "rxjs";
import {Logger} from "core";

@Component({
  selector: 'number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: NumberInputComponent }],
  host: {
    '[class.floating]': 'shouldLabelFloat',
    '[id]': 'id',
  }
})
export class NumberInputComponent implements ControlValueAccessor, MatFormFieldControl<number>, OnDestroy {

  static nextId = 0;

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  id = `number-input-${NumberInputComponent.nextId++}`;
  controlType = 'number-input';
  stateChanges = new Subject<void>();
  focused = false;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    return !this.value && this.value!=0;
  }

  get shouldLabelFloat() {
    // console.log('shouldLabelFloat', this.focused, this.empty);
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): number | null {
    return this._value;
  }

  set value(value: number | null) {
    this._value = value;
    this.stateChanges.next();
  }
  private _value: number | null;

  get errorState(): boolean {
    return !!this.ngControl.errors;
  }

  protected logger = new Logger('NumberInputComponent');

  constructor(protected formBuilder: FormBuilder,
              protected focusMonitor: FocusMonitor,
              protected elementRef: ElementRef<HTMLElement>,
              @Optional()
              @Inject(MAT_FORM_FIELD)
              public formField: MatFormField,
              @Optional()
              @Self()
              public ngControl: NgControl) {
    focusMonitor
      .monitor(elementRef, true)
      .subscribe(origin => {
        if (this.focused && !origin) {
          this.onTouched();
        }
        this.focused = !!origin;
        this.stateChanges.next();
      });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this.elementRef.nativeElement.querySelector('input')!; // this.input.nativeElement
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this.focusMonitor.focusVia(this.input, 'program');
  }

  writeValue(value: number | null): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  onInput(event: Event) {
    this.onChange(this.value = coerceNumberProperty(this.input.nativeElement.value, null));
  }
}
