import {BehaviorSubject, firstValueFrom, Observable, Subject, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {FilterDefinition, FilterDefinitions} from "./models/filter";
import {MenuSelection} from "./models/selection";
import {FilterSection} from "./models/section";
import {EMPTY_ARRAY, Logger, Topic} from "core";
import isEqual from 'lodash/isEqual';

import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import {isValidNumber} from "../../util/number.util";
import {Badge} from "../../models/badge";

export const VIEWED_FILTER_PREFIX = 'view:';

export interface FilterService {
  getFilterTopics$: (path: string) => Observable<Topic[]>;
}

export abstract class MenuService implements CanActivate, CanActivateChild {

  rootPath = ''; //'realizer'; // should be provided from outside i.e. injected

  menuSelection$     = new BehaviorSubject<MenuSelection>(MenuSelection.EMPTY);
  filterSections$    = new BehaviorSubject<FilterSection[]>([]);
  filterSectionsMap$ = new BehaviorSubject<{[key:string]: FilterSection}>({});
  filterTagsMap$     = new BehaviorSubject<{[key:string]: string[]}>({});
  breadcrumb$        = new BehaviorSubject<string[]>([]);
  update$            = new BehaviorSubject<boolean>(undefined);
  select$            = new Subject<void>();
  protected badges: {[key:string]: { badge$: BehaviorSubject<Badge>, subscription?:Subscription}} = {};
  protected logger   = new Logger('MenuService');

  constructor(protected filterService: FilterService,
              protected router: Router,
              protected appId: number) {
  }

  setBadgeObservable$(path: string, observable?: Observable<Badge>) {
    const value = this.badges[path] ?? { badge$: new BehaviorSubject<Badge>(undefined) };
    value.subscription?.unsubscribe();
    if (!!observable) {
      value.subscription = observable.subscribe(badge => {
        if (value.badge$.value != badge) {
          value.badge$.next(badge);
        }
      });
      this.badges[path] = value;
    } else {
      delete value.subscription;
      delete this.badges[path];
    }
  }

  getBadgeObservable$(path: string): Observable<Badge> {
    const value = this.badges[path] ?? { badge$: new BehaviorSubject<Badge>(undefined) };
    this.badges[path] = value;
    return value.badge$;
  }

  select(path: string, force = false): void {
    const selection = MenuSelection.fromPath(path);
    // this.logger.debug("select("+path+")", selection);
    if (this.filterSections.length>0) {
      let filterSection = this.filterSections.find(section => section.id==selection.section);
      if (!filterSection && this.filterSections.length) {
        // e.g. switching group could lead to not found section, so we try navigate to first....
        // this.router.navigate([`/${this.filterSections[0].id}`],{ replaceUrl: true });
        return;
      }
      //console.log("select("+path+") section", filterSection,"sections",this.filterSections);
      if (filterSection) {
        let visibleFilterDefinitions: FilterDefinition[] = ((filterSection.filters || <FilterDefinitions>{}).all || <FilterDefinition[]>[]).filter(filterDefinition => filterDefinition.visible && filterDefinition.enabled);
        if (!!selection.filters && selection.filters.length && visibleFilterDefinitions && visibleFilterDefinitions.length && !visibleFilterDefinitions.find((filterDefinition) => filterDefinition.id==selection.filters[0])) {
          // console.debug("select("+path+") filters",selection.filters,"visibleFilterDefinitions",visibleFilterDefinitions);
          this.select("/"+selection.section+"/"+visibleFilterDefinitions[0].id);
        } else if (!this.menuSelection.equals(selection) || force) {
          // console.debug("select("+path+") visibleFilterDefinitions", visibleFilterDefinitions);
          const hasSectionFilters = this.hasSectionFilters(filterSection);
          // selection.done = filterSection.submenu ? false : !hasSectionFilters;
          this.menuSelectionNext(selection);
          const promise = hasSectionFilters
            ? firstValueFrom(this.filterService.getFilterTopics$(path)).then(filterTopics => {
                const previousFilters = this.selectedFilters(filterSection);
                const selectedFilters = previousFilters.filter(filter =>
                  !filterSection.filters.filterToDefinition[filter]
                  && !filter.startsWith(VIEWED_FILTER_PREFIX)
                  // In order to keep the filters in a consistent state
                  // we have to remove n-th (n>=3) level filters when the menu section remains the same.
                  // These filters can "leak" only when performing menu selection within the same section because
                  // in this case the filters share the same FilterSection.filters.selected structure.
                  // For media filters this is already handled directly in MediaPageComponent (displayMediaFilters method)
                  // TODO: this is a workaround! more generic mechanism to detect exactly which filters to remove is needed!
                  && (![/*'project',*/ 'team'].includes(selection.section) ||  filter.split('.').length < 2)
                  && (!!filterTopics.find(topic => topic.id==filter) || filter==filterSection.id)
                );
                if (selection.filters.length) {
                  let keepViewed = this.menuSelection.section!=selection.section /*|| !this.menuSelection.hasFilters()*/ ;
                  if (!keepViewed) {
                    const viewedIndex = this.menuSelection.filters.findIndex(filter => filter.startsWith(VIEWED_FILTER_PREFIX));
                    keepViewed = this.menuSelection.equals(selection, viewedIndex);
                    //this.logger.debug('keepViewed', { keepViewed, viewedIndex, previous: this.menuSelection, next: selection });
                  }
                  selection.filters.forEach((id:any) => {// only process "known" selection filters
                    // e.g. the ones having a corresponding topic + "view:" filters
                    const definition = filterSection.filters.idToDefinition[id];
                    if (definition || (id.startsWith(VIEWED_FILTER_PREFIX) && keepViewed)) {
                      selectedFilters.push(definition ? definition.filter : id);
                    }
                  });
                }
                filterSection.filters.selected = selectedFilters?.length>0 ? selectedFilters : EMPTY_ARRAY;
                filterSection.filters.updateFilters(selectedFilters);
                // console.debug("select("+path+")",'previousFilters',previousFilters,'selectedFilters',selectedFilters);
              })
            : Promise.resolve();
            promise.then(() => {
              selection.done = filterSection.submenu ? false : !hasSectionFilters;
              this.menuSelectionNext(selection);
            })
        }
      }
    }
  }

  protected menuSelectionNext(selection:MenuSelection) {
    if (!selection?.equals(this.menuSelection) ||
         selection.done != this.menuSelection.done) {
      this.menuSelection$.next(selection);
    }
  }

  get breadcrumb(): string[] {
    return this.breadcrumb$.getValue();
  }

  get menuSelection(): MenuSelection {
    return this.menuSelection$.getValue();
  }

  get filterSections(): FilterSection[] {
    return this.filterSections$.getValue();
  }

  get visibleFilterSections(): FilterSection[] {
    return this.filterSections$.getValue().filter(section => section.visible && section.enabled);
  }

  get visibleFilterSections$() : Observable<FilterSection[]> {
    return this.filterSections$.pipe(
      map(sections => sections.filter(section => section.visible && section.enabled))
    );
  }

  getFilterTags(filterId:string):string[] {
    return this.filterTagsMap$.value?.[filterId] ?? [];
  }

  hasSectionFilters(filterSection: FilterSection): boolean {
    return filterSection?.filters?.all?.length > 0;
  }

  selectedFilters(filterSection: FilterSection): string[] {
    //console.debug("selectedFilters",filterSection.filters.selected,filterSection.filters.default);
    return filterSection.filters.selected || filterSection.filters.default || [];
  }

  sectionLink(filterSection: FilterSection): string {
    return `${this.rootPath}/${this.sectionUrl(filterSection)}`;
  }

  sectionUrl(filterSection: FilterSection): string {
    let path = `/${filterSection.id}`;
    if (this.hasSectionFilters(filterSection)) {
      //let rawFilterIds     = new Set(filterSection.filters.all.map(filterSection => filterSection.id));
      //let visibleFilterIds = new Set(filterSection.filters.all.filter(filterSection => filterSection.visible).map(filterSection => filterSection.id));
      let currentFilters  = this.selectedFilters(filterSection);
      let possibleFilters = currentFilters.filter(id => {
        let definition = (<any>(filterSection.filters.filterToDefinition[id] || {}));
        return !!definition.visible && !!definition.enabled;
      });
      if (possibleFilters.length>0) {
        const filterId = filterSection.filters.filterToDefinition[possibleFilters[0]].id;
        path = `/${filterSection.id}/${filterId}`;
      }
    } else {
      //console.debug("menu.sectionLink.path:"+path);
    }
    return path;
  }

  filterLink(filterSection: FilterSection, filterDefinition: FilterDefinition): string {
    /*if (this.menuSelection &&
        this.menuSelection.section==filterSection.id &&
        this.menuSelection.filters.length>1 &&
       (
         //drop the 'viewed:' filter when changing the first level filter in the same section
         this.menuSelection.filters[0]==filterDefinition.id ||
        !this.menuSelection.filters[1].startsWith(VIEWED_FILTER_PREFIX)
       )) {
      // return `${this.rootPath}/${filterSection.id}/${filterDefinition.id}/${this.menuSelection.filters[1]}`;
    }*/
    if (!!filterSection?.id && !!filterDefinition?.id) {
      const channelsActive = false; // Temp rerouting to channels page, remove once officially included
      if (channelsActive && filterSection.id == "project" && filterDefinition.id == "channels") {
        return `${this.rootPath}/${filterDefinition.id}`;
      }
      return `${this.rootPath}/${filterSection.id}/${filterDefinition.id}`;
    } else {
      return `${this.rootPath}/${filterSection.id}`;
    }
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | Observable<boolean | UrlTree> {
    return this.canActivateChild(next,state);
  }

  canActivateChild(child: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): boolean | Observable<boolean | UrlTree> {
    if (state.url && this.filterSections$.value.length) {
      let menuSelection = MenuSelection.fromPath(state.url);
      //console.log("MenuService.canActivateChild child",child,"state",state,"menuSelection",menuSelection,"menuSelection$",this.menuSelection$.value,"url",state.url);
      if (menuSelection.section) {
        let filterSection = this.filterSectionsMap$.value[menuSelection.section];
        let filter = undefined;
        //console.log("filterSection",filterSection);
        if (filterSection &&
           ((filterSection.visible && filterSection.enabled) || filterSection.routable)) {
          //console.log("filterSection",filterSection,"hasFilters()",menuSelection.hasFilters());
          if (menuSelection.hasFilters()) {
            if (filterSection.filters.all.length==0) {  // e.g. chat
              return true;
            } else if (
                filterSection.filters.idToDefinition[menuSelection.filters[0]] &&
                filterSection.filters.idToDefinition[menuSelection.filters[0]].visible &&
                filterSection.filters.idToDefinition[menuSelection.filters[0]].enabled) {
              return true;
            } else {
              filter = filterSection.filters.all.find(filter => filter.visible && filter.enabled);
              if (filter) {
                this.router.navigate([`/${filterSection.id}/${filter.id}`],{ replaceUrl: true });
                return false;
              }
            }
          } else {
            return true;
          }
        }
        this.router.navigate(["/project/intro"],{ replaceUrl: true });
        return false;
      }
    }
    return true;
  }

  reset() {
    const filterSections = this.filterSections$.getValue();
    this.filterSections$.next(this.filterSections.map(section => ({
      ...section,
      filters: {...section.filters, selected: section.filters.default}
    })));
    if (!isEqual(filterSections, this.filterSections$.getValue())) {
      this.select(this.router.url, true);
    }
  }
}
