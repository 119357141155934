/* projects/shared/src/lib/components/virtual-scroller/virtual-section-scroller.scss */
:host {
  overflow: hidden;
}
:host > .viewport {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  transform: translate3d(0, 0, 0);
}
:host > .viewport::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
}
:host.vertical > .viewport {
  overflow-y: auto;
}
:host.horizontal > .viewport {
  overflow-x: auto;
}
:host > .viewport > div {
  position: relative;
}
:host-context(.plt-ios) > .viewport {
  overflow-x: hidden;
  overflow-y: hidden;
}
:host .sticky-header-container,
:host .sticky-header-container > .header {
  position: absolute;
  top: 0;
  left: 0;
}
:host .sticky-header-container > .header {
  opacity: 0;
}
:host.vertical .sticky-header-container,
:host.vertical .sticky-header-container > .header {
  right: 0;
}
:host.horizontal .sticky-header-container,
:host.horizontal .sticky-header-container > .header {
  bottom: 0;
}
:host .scroll-container {
  position: absolute;
  left: 0;
  top: 0;
  overflow: visible;
  transform: translate3d(0, 0, 0);
}
:host.vertical .scroll-container {
  width: 100%;
  max-width: 100%;
  min-height: 100%;
}
:host.horizontal .scroll-container {
  height: 100%;
  max-height: 100%;
  min-width: 100%;
}
:host .render-container {
  position: absolute;
  top: 0;
  left: 0;
}
:host.vertical .render-container {
  width: 100%;
  max-width: 100%;
}
:host.horizontal .render-container {
  height: 100%;
  max-height: 100%;
}
:host .item-container {
  position: relative;
}
:host .item-container ::ng-deep > * {
  box-sizing: border-box;
}
:host > .debug {
  background-color: rgba(255, 255, 255, 0.7529411765);
  border-radius: 10px;
  color: #333;
  font-size: 12px;
  padding: 4px 12px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
}
/*# sourceMappingURL=virtual-section-scroller-VRQ6TDHF.css.map */
