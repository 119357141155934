<!--<div>{{'TAB.'+uniqueId+': '+selected}}</div>-->
<ng-container *ngFor="let tabDefinition of tabDefinitions; let index = index;">
  <ng-container *ngLet="!!tabDefinition.unseen$ ? (tabDefinition.unseen$ | async) : 0 as unseen">
    <div *ngIf="tabDefinition.type!='flex'"
         [class.selected]="selected==index && !!selectable"
         class="tab-button"
         (click)="onSelect.emit(index)">
      <mat-icon>{{tabDefinition.icon}}</mat-icon>
      <span translate>{{tabDefinition.abbreviation}}</span>
      <div *ngIf="unseen>0" class="badge">{{unseen}}</div>
    </div>
    <div *ngIf="tabDefinition.type=='flex'" class="flex"></div>
  </ng-container>
</ng-container>
