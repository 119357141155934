/* projects/media/src/lib/components/media-details-filter/media-details-filter.component.scss */
:host {
  flex: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}
.container {
  position: relative;
  padding: 16px 24px;
}
/*# sourceMappingURL=media-details-filter.component-BKRHRUM5.css.map */
