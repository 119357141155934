import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {ENVIRONMENT, getCurrentThemeMode, isThemeModePure, pureMode$, ThemeMode, themeMode$, User} from "core";
import {BasicContainerComponent} from "shared";
import {Media} from "media";
import {Group, PropertiesService} from "properties";

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupListComponent extends BasicContainerComponent implements OnChanges {

  @Input() entities$: Observable<Group[]>;
  @Input() user$: Observable<User>;
  @Input() theme: string;
  @Output() selected = new EventEmitter<any>();
  @Output() play = new EventEmitter<Media>();

  //@ViewChildren(GroupLogoComponent) viewChildren!: QueryList<GroupLogoComponent>;

  public animated$ = new BehaviorSubject<boolean>(false);

  protected mode$ = themeMode$.asObservable();
  protected pure$ = pureMode$.asObservable();

  trackByGroup = (index: number, group: Group): string => {
    // return `${group.id}.${group.version}`;
    return `${group.id}.${this.theme}`;
  };
  constructor(
    @Inject(ENVIRONMENT) public environment: any,
    protected propertiesService: PropertiesService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  updateColors() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['theme']) {
      //this.backgroundColor = this.theme=='dark' ? 'transparent' : this.environment.primaryColor;
    }
  }

  isGroupSelected(group: Group, index: number) {
    return false;
  }

  onSelectedGroup(group: Group) {
    this.selected.emit(group);
  }

  onPlayMedia(media: Media, index: number) {
    this.play.emit(media);
  }

  safeGroup(group: Group): any {
    return group || {};
  }

  animate(animate:boolean,delay?:number) {
    window.setTimeout(()=> {
      //console.log("ANIMATE.LIST",animate);
      if (this.animated$.value != animate) {
        this.animated$.next(animate);
      }
      /*
      if (this.viewChildren?.length) {
        this.viewChildren.forEach(component => {
          component.animate(animate);
        });
      }*/
    }, delay>0?delay:undefined);
  }

  getThemeMode$():Observable<ThemeMode> {
    return this.mode$;
  }

  isPureMode$():Observable<boolean> {
    return this.pure$;
  }

}
