import {Injectable} from "@angular/core";
// import {PropertiesService} from "../properties/store/service";
import {AppIdentifierProvider} from "../app/app.provider";


@Injectable({
  providedIn: 'root'
})
export class PhoneService {

  callingCode: string;

  constructor(/*protected propertiesService: PropertiesService,*/
              protected appIdentifier: AppIdentifierProvider) {

    // this.propertiesService.session$.subscribe(session => {
    //   if (session && session.calling_code) {
    //     this.callingCode = session.calling_code;
    //   }
    // });
  }

  convertToWhatsappNumber(phone: string, callingCode?: string) : string {
    if (phone = this.convertToPlainNumber(phone)) {
      if (phone.charAt(0)=='+') {
        return phone.substring(1);
      } else if (phone.startsWith('00')) {
        return phone.substring(2);
      } else if (phone.charAt(0)=='0' && callingCode) {
        return callingCode+phone.substring(1);
      }
    }
    return phone;
  }

  convertToPlainNumber(phone : string) : string {
    //return (phone || '').replace(/^[0-9\+]$/g,'');
    return (phone || '').replace(/[^0-9\+]*/g,'');
  }
}
