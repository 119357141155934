import {Inject, Injectable, NgZone, Predicate} from '@angular/core';
import EventBus from 'vertx3-eventbus-client';
import {BehaviorSubject, firstValueFrom, Observable, of, race, switchMap, throwError, timer} from "rxjs";
import {filter} from "rxjs/operators";
import {Message, MessageEnvelope} from "./models";
import {ENVIRONMENT} from "core";
import {SessionTokenService} from "session";
import {MAX_JAVA_INTEGER} from "shared";

declare type MessageHandler = (error: Error, message: any) =>  void;

declare interface Registration {
  id: string;
  messageHandler: MessageHandler
}

declare interface Measurement {
  min: number;
  max: number;
  avg: number;
  time: number;
  times: {at:number,thread?:number,time:number}[];
  errors: {at:number,reason:string}[];
}

const MeasurementResultMessageType: string = "measurement.result";
interface MeasurementResultMessage extends Message {
  measurements:{[key:string]:Measurement};
}

const MeasurementStartMessageType: string = "measurement.start";
interface MeasurementStartMessage extends Message {
  count: number;
  size: number;
  time: number;
  delay: number;
}

const MeasurementDataMessageType: string = "measurement.data";
interface MeasurementDataMessage extends Message {
  counter: number;
  delay: number;
  thread?: number;
  payload: string;
}

/*declare interface MessageEnvelopePromise {
  resolve: (value?: (MessageEnvelope | PromiseLike<MessageEnvelope>)) => void;
  reject:  (reason?: any) => void;
}*/
declare interface MessageEnvelopePromise {
  resolve: (value?: MessageEnvelope) => void;
  reject:  (reason?: any) => void;
}

// EventBus.state is not exposed in type definition file
export enum MessagingState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3
}


// https://github.com/ng-packagr/ng-packagr/issues/641
// @dynamic
@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  private eventBus: EventBus.EventBus;
  private state : boolean = undefined;

  protected _open$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public get open$() {
    return this._open$.asObservable() ;
  }
  protected messageHandlerRegistrations: {[key: string]: Registration} = {};
  protected callbackSourceIdRegistrations: {[key: string]: MessageEnvelopePromise} = {};

  protected defaultHeaders: any;
  protected counter:number = 0;

  public get connectionId(): string {
    if (this.eventBus) {
      return (<any>this.eventBus).connectionId;
    }
    return undefined;
  }

  /**
   * on websocket level:
   *    connectionId .... unique identification of connection from client to the server
   *                      server gets this connectionId in the clients request for http
   *                      protocol upgrade to websocket (code 101)
   *
   * on eventbus level:
   *    address      .... over one connectionId there can be communication to many addresses.
   *                      when message handler is registered, it is registered for a given
   *                      address. this address does not need to be unique.
   *                      but in our case we let them all be unique - see MessageService.java
   *                      and the use of remoteAddressConnectionIdMap.
   *
   */
  constructor(private zone: NgZone,
              private sessionTokenService: SessionTokenService,
              @Inject(ENVIRONMENT)
              private environment: any) {
    console.log("MessageService.ctor()");
    (window as any).messagingService = this;
  }

  initialize(connectionId?: string) {
    //console.log("MessageService.initialize");
    if (!this.eventBus) {
      connectionId = connectionId ||
        window.sessionStorage?.getItem('connectionId') ||
        MessagingService.createConnectionId();
      const address: string = '/v1.0/messaging/'+connectionId;
      const handler = this.handleIncomingMessage.bind(this);
      window.sessionStorage?.setItem('connectionId',connectionId);
      this.defaultHeaders = {connection_id: connectionId};
      this.eventBus = this.createEventBus(connectionId);
      this.eventBus.onopen = () => {
        let opened = this.isOpen();
        if (this.state != opened && opened) {
          this.state = opened;
          //console.info('EVENTBUS OPENED');
          this.eventBus.registerHandler(address, this.defaultHeaders, handler);
          this.zone.run(() => this._open$.next(true));
        }
      };
      this.eventBus.onclose = () => {
        let opened = this.isOpen();
        if (this.state != opened && !opened) {
          this.state = opened;
          //console.info('EVENTBUS CLOSED');
          this.zone.run(() => this._open$.next(false));
        }
      };
      if (this.isOpen()) {
        this.eventBus.registerHandler(address, this.defaultHeaders, handler);
      }
      this.register(message=> message.message.type == MeasurementStartMessageType)
        .subscribe(envelope => window.setTimeout(()=>
          this.handleMeasurementStartMessage(<MeasurementStartMessage>envelope.message),
          1000));
      //this.register(message=> message.message.type == MeasurementDataMessageType)
      //  .subscribe(envelope => this.handleMeasurementDataMessage(<MeasurementDataMessage>envelope.message));
    }
  }

  handleMeasurementStartMessage(message: MeasurementStartMessage) {
    console.log("measurement.start",message);
    if (message.count > 0 && message.size > 0) {
      delete this.measurements[MeasurementDataMessageType];
      let success = 0;
      let failure = 0;
      const next = (counter) => {
        this.sendMessage(this.initializeMessage(<MeasurementDataMessage>{
          type:MeasurementDataMessageType,
          timeCreated:message.timeCreated,
          counter,
          delay: message.delay,
          payload: 'x'.repeat(message.size)
        }),true)
          .then(() => success++)
          .catch(() => failure++)
          .finally(() => {
            console.log("measurement.success",success,"failure",failure);
            if (success+failure >= message.count && !!this.measurements[MeasurementDataMessageType]) {
              this.sendMessage(this.initializeMessage(<MeasurementResultMessage>{
                type:MeasurementResultMessageType,
                timeCreated:message.timeCreated,
                measurements:{
                  [MeasurementDataMessageType]:this.measurements[MeasurementDataMessageType]
                }}),false);
              delete this.measurements[MeasurementDataMessageType];
            }
          });
      }
      const interval = (message.time??0) / message.count;
      if (interval >= 10 && message.count > 1) {
        const time = Date.now();
        console.log("measurement.interval.0",interval,"count",message.count,"time",time);
        const timeLimit = Date.now() + Math.max(message.time * 3, 600_000); // we do not allow devices to participate if sleeping
        let counter = 0;
        let handler = window.setInterval(() => {
          if (counter >= message.count || Date.now()>timeLimit) {
            window.clearInterval(handler);
          } else {
            next(++counter);
          }
          console.log("measurement.interval."+counter,interval,"count",message.count,"time",time,Date.now()-time);
        },interval);
      } else {
        for (let counter = 0; counter < message.count; counter++) {
          next(counter);
        }
      }
    }
  }

  handleMeasurementDataMessage(message: MeasurementDataMessage) {
  }

  handleIncomingMessage(error, message) {
    //console.log("XMESSAGE",message,"error",error);
    var jsonized = message?.headers?.jsonized;
    if (jsonized) {
      delete message.headers.jsonized;
      message.headers = { ...message.headers, ...JSON.parse(jsonized) };
    }
    error = error ?? (!message?.body ||
      !message.body.id ||
      !message.body.type ||
      !message.body.timeCreated ||
      !message.body.timeSent) ?
      new Error("message: "+JSON.stringify(message)) : undefined;
    const sourceId = message?.headers?.sourceId;
    message = <MessageEnvelope>{message:<Message>message.body,headers:message.headers||{}};
    if (!!sourceId) {
      const promise = this.callbackSourceIdRegistrations[sourceId];
      delete this.callbackSourceIdRegistrations[sourceId];
      if (!!promise) {
        promise.resolve(message);
      }
    } else {
      //console.log("STORE.messageService.incoming."+message?.message?.type,message,"error",error);
      Object.keys(this.messageHandlerRegistrations).forEach((id) => {
        var registration = this.messageHandlerRegistrations[id];
        registration.messageHandler(error, message);
      });
    }
  }

  isOpen(): boolean {
    return this.eventBus && (<any>this.eventBus).state == MessagingState.OPEN; //EventBus.OPEN;
  };

  /*

  onOpen(): Promise<boolean> {
    //console.log("MessageService.onOpen");
    return this.open$.pipe(
      filter(opened => opened),
      take(1)
      //map(opened => void 0),
    ).toPromise();
  }*/

  //protected sent = new Map<string,any>();
  protected measurementsSendTime = 0;
  protected measurements:{[type:string]:Measurement} = {};
  sendMessage(message: Message, awaitResult: boolean|{result?:boolean,timeout:number} = false, headers = {}, receiverId = '/v1.0/messaging/server'): Promise<MessageEnvelope> {
    //console.log("send:sync:"+message.type, message);
    //console.trace("sendMessage",message);
    return new Promise((resolve,reject)=> {
      const startTime = Date.now();
      const awaitResultIsBoolean = typeof awaitResult == 'boolean';
      const timeout= (awaitResultIsBoolean ? 10_000 : Math.max((<any>awaitResult).timeout??100,100));
      awaitResult = (awaitResultIsBoolean ? awaitResult : !!(<any>awaitResult).result) && !!message.id;
      //console.log("sendMessage.awaitResult",awaitResult,"timeout",timeout,"message",message,"headers",headers,"receiverId",receiverId)
      /*if (this.sent.has(message.id)) {
        const time = message.timeSent-message.timeCreated;
        console.trace("MessageService.sendMessage.delay",time,message,"data",this.sent.get(message.id));
      }*/
      if (awaitResult && this.measurementsSendTime<startTime) {
        const startTime = this.measurementsSendTime ?? Date.now();
        const now = new Date(); // Gets the current date and time
        now.setHours(23, 59, 59, 999); // Sets time to last millisecond of today
        this.measurementsSendTime = now.getTime();
        if (Object.keys(this.measurements).length > 0) {
          this.sendMessage(this.initializeMessage(<MeasurementResultMessage>{
            type:MeasurementResultMessageType,
            startTime,
            measurements:this.measurements
          }),false);
        }
        this.measurements = {};
      }
      const measurement = awaitResult ? this.measurements[message.type] = this.measurements[message.type] ?? {times:[],min:MAX_JAVA_INTEGER,max:0,avg:0,time:0,errors:[]} : undefined;
      firstValueFrom(race(
        this.open$.pipe(
          filter(open => !!open),
          switchMap(open => {
            const elapsed = Date.now()-startTime;
            if (elapsed < timeout) {
              return of(open);
            } else {
              return throwError(() => new Error('timeout due to late true'));
            }
          })),
        timer(timeout).pipe(
          switchMap(() => throwError(() => new Error('timeout'))))
      )).then(() => {
        message.timeSent = Date.now();
        if (awaitResult) {
          let timeoutHandler = window.setTimeout(() => {
            delete this.callbackSourceIdRegistrations[message.id];
            measurement.errors.push({at:Date.now(),reason:'timeout'});
            reject('timeout');
          },Math.max(100,timeout-(Date.now()-startTime)));
          this.callbackSourceIdRegistrations[message.id] = <MessageEnvelopePromise>{
            resolve: (result)=>{
              window.clearTimeout(timeoutHandler);
              const time = Date.now()-message.timeSent;
              const thread = (<any>result.message)?.thread;
              measurement.times.push(thread ? {at:Date.now(),thread,time} : {at:Date.now(),time});
              measurement.time += time;
              measurement.min = Math.min(measurement.min,time);
              measurement.max = Math.max(measurement.max,time);
              measurement.avg = measurement.time/measurement.times.length;
              console.log("messagingService.measurement",measurement,this.measurements);
              resolve(result);
            },
            reject: (error)=> {
              window.clearTimeout(timeoutHandler);
              measurement.errors.push({at:Date.now(),reason:error.message??error.toString()});
              reject(error);
            }
          };
        }
        headers = awaitResult ? { ...headers, sourceId:message.id } : headers;
        //this.sent.set(message.id,message);
        this.eventBus.send(receiverId, message, headers);
        if (!awaitResult) {
          resolve(<MessageEnvelope>{message:message,headers:headers});
        }
      })
      .catch(error => {
        console.error("sendMessage.error",error,"message",message);
        if (awaitResult) {
          measurement.errors.push({at:Date.now(),reason:error.message??error.toString()});
        }
        reject(error);
      });
    });
  }

  initializeMessage<M extends Message>(message: M) : M {
    if (!message.timeCreated) {
      message.timeCreated = Date.now();
    }
    if (!message.id) {
      message.id = this.createMessageId();
    }
    return message;
  }

  createMessageId() : string {
    const first = Math.random().toString(36).slice(2,10);
    const second = Date.now().toString(36).split('').reverse().join('');
    return (first+second).substring(0,16);
  }

  //register(address: string): Observable<Message> {
  register(predicate?: Predicate<MessageEnvelope>): Observable<MessageEnvelope> {
    // this.initialize();
    return new Observable<MessageEnvelope>(subscriber => {
      let id = Date.now().toString(16)+Math.round(Math.random()*65535).toString(16);
      let messageHandler : MessageHandler = (error, message) => {
        if (error) {
          subscriber.error(error);
        } else {
          subscriber.next(message);
        }
      };
      let registration = {
        id: id,
        messageHandler: messageHandler,
        deregister : () => {
          delete this.messageHandlerRegistrations[id];
        }
      };
      this.messageHandlerRegistrations[registration.id] = registration;
      return registration.deregister;
    }).pipe(
      filter(envelope => !!predicate ? predicate(envelope) : true)
      //tap(envelope => console.log("STORE.messageService.handle",envelope))
    );
  }

  reset() {
    //console.trace("MessageService.reset");
    if (this.isOpen()) {
      this.eventBus.close();
      this.eventBus = null;
      const connectionId = MessagingService.createConnectionId();
      this.initialize(connectionId);
    }
  }

  createEventBus(connectionId: string): EventBus.EventBus {
    //console.log("MessageService.createEventBus",connectionId);
    const options = {
      vertxbus_reconnect_attempts_max: Infinity, // Max reconnect attempts
      vertxbus_reconnect_delay_min: 1000, // Initial delay (in ms) before first reconnect attempt
      vertxbus_reconnect_delay_max: 2000, // Max delay (in ms) between reconnect attempts
      vertxbus_reconnect_exponent:     2, // Exponential backoff factor
      vertxbus_randomization_factor: 0.5  // Randomization factor between 0 and 1
    };
    const eventBus = new EventBus(this.sessionTokenService.rewrite(`${this.environment.serverUrl}/v1.0/messaging/eventbus`), options);
    (<any>eventBus).connectionId = connectionId;
    // eventBus.defaultHeaders   = MessagingService.defaultHeaders;
    eventBus.enableReconnect(true);
    return eventBus;
  }

  static createConnectionId(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (a, b) {
      return b = Math.random() * 16, (a == 'y' ? b & 3 | 8 : b | 0).toString(16);
    });
  }
}
