import {Component, ContentChild, TemplateRef, ViewChild} from '@angular/core';
import {Media} from "../../store/models";
import {MediaService} from "../../service/media.service";
import {BasicContainerComponent} from "shared";
import {LayoutService} from "layout";
import {combineLatest} from "rxjs";
import {pairwise, startWith, take, takeUntil} from "rxjs/operators";
import isEqual from "lodash/isEqual";
import {PropertiesService} from "properties";
import {REMOVE_ALL_OTHER_TYPES} from "core";

export declare interface MediaSelectorContext {
  onMediaSelected: (media: Media) => void;
  onPlayMedia?: (media: Media) => void,
  mediaFilters?: string[],
  mediaNameTemplateRef?: TemplateRef<any>
}

@Component({
  selector: 'media-selector',
  templateUrl: './media-selector.component.html',
  styleUrls: ['./media-selector.component.scss']
})
export class MediaSelectorComponent extends BasicContainerComponent {

  // static noop = (media: Media): void => {};
  static DEFAULT_CONTEXT: MediaSelectorContext = {
    onMediaSelected: (media: Media): void => {},
    onPlayMedia: (media: Media): void => {},
    mediaFilters: ['']
  };

  protected _context: MediaSelectorContext;

  searchTerm: string;
  previousSearchTerm: string;
  previousFilters: {[key:string]:string[]} = {};

  mediaNameTemplateRef: TemplateRef<any>;

  constructor(public mediaService: MediaService,
              public layoutService: LayoutService,
              public propertiesService: PropertiesService) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.layoutService.details.getState((state) => {
      this.layoutService.details.state$
        .pipe(
          takeUntil(this.onDestroy$),
          startWith(state),
          pairwise()
        ).subscribe(([previousState, currentState]) => {
          if (previousState.open && !currentState.open) {
            // TODO: consider restoring media filters and searchTerm in a single step
            // or maintain media selector own isolated store state...
            const promise = !isEqual(this.previousFilters, { selector: this.context.mediaFilters })
              ? this.mediaService.setTypedFilters(this.previousFilters, REMOVE_ALL_OTHER_TYPES)
              : Promise.resolve();
            promise.then(() => {
              if (this.searchTerm!=this.previousSearchTerm) {
                this.mediaService.updateSearchTerm(this.previousSearchTerm);
              }
            });
          }
        });
    });
    combineLatest([
      this.mediaService.searchTerm$,
      this.mediaService.getTypedFilters$(),
    ])
    .pipe(take(1))
    .subscribe(([term, filters]) => {
      this.previousSearchTerm = term;
      this.previousFilters = filters;
    });
  }

  set context(context: MediaSelectorContext) {
    this._context = {
      ...MediaSelectorComponent.DEFAULT_CONTEXT,
      ...context
    };
    this.mediaNameTemplateRef = this._context.mediaNameTemplateRef;
    const mediaFilters = { selector: this.context.mediaFilters };
    if (!isEqual(this.previousFilters, mediaFilters)) {
      this.mediaService.setTypedFilters(
        mediaFilters,
        REMOVE_ALL_OTHER_TYPES
      );
    }
  }

  get context(): MediaSelectorContext {
    return this._context || MediaSelectorComponent.DEFAULT_CONTEXT;
  }

  onSearchTermChange(term: string) {
    this.mediaService.updateSearchTerm(term || '');
  }

  onMediaSelected(media: Media) {
    this.context.onMediaSelected(media);
  }

  onPlayMedia(media: Media) {
    this.context.onPlayMedia(media);
  }
}
