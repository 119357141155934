import {Injectable} from "@angular/core";
import {BehaviorSubject, fromEvent, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Size} from "../../types/size";
import {isValidNumber} from "../../util/number.util";

@Injectable({
  providedIn: 'root'
})
export class ResizeService {

  protected _windowSize : Size;
  protected _windowSize$ : BehaviorSubject<Size>;
  protected asyncTimeout : number;
  protected openTicks : number = 0;

  constructor() {
    this._windowSize   = this.getSize(window);
    this._windowSize$  = new BehaviorSubject<Size>(this._windowSize);
    fromEvent(window, 'resize').pipe(
      map((event) : Size => this.getSize(window))
    ).subscribe(size => {
      if (this._windowSize.width  != size.width ||
          this._windowSize.height != size.height) {
        this._windowSize = size;
        this._windowSize$.next(size);
      }
    });
  }

  get windowSize() : Size {
    return this._windowSize;
  }

  get windowSize$() : Observable<Size> {
    return this._windowSize$;
  }

  triggerSync(time?:number) : void {
    this.clearAsync();
    this._windowSize$.next(this._windowSize);
    if (isValidNumber(time) && time>=50) {
      const startTicks = this.openTicks<=0;
      this.openTicks = Math.max(this.openTicks,Math.ceil(time/50));
      //console.log("triggerSync",time,"open",this.openTicks);
      if (startTicks) {
        let interval = window.setInterval(()=> {
          if (--this.openTicks <= 0) {
            window.clearInterval(interval);
            //console.log("triggerSync",time,"ended");
          }
          this.clearAsync();
          this._windowSize$.next(this._windowSize);
        },50);
      }
    }
  }

  protected clearAsync() : void {
    if (this.asyncTimeout) {
      window.clearTimeout(this.asyncTimeout);
      this.asyncTimeout = undefined;
    }
  }

  triggerAsync() : void {
    if (!this.asyncTimeout) {
      this.asyncTimeout = window.setTimeout(()=> {
        this.asyncTimeout = undefined;
        this._windowSize$.next(this._windowSize);
      },100);
    }
  }

  getZoom(target:Element) : number {
    let style:any = window.getComputedStyle(target);
    let zoom = style.zoom;
    if (zoom == 'normal') zoom = '1';
    return parseFloat(zoom);
  }

  getSize(target: Element | EventTarget): Size  {
    return {
      // https://plainjs.com/javascript/styles/getting-width-and-height-of-an-element-23/
      width:  target instanceof Element ? target.clientWidth  : target['innerWidth'],
      height: target instanceof Element ? target.clientHeight : target['innerHeight'],
    }
    /*if (target instanceof Element) {
      let rect = target.getBoundingClientRect();
      let text = "GET_SIZE";
      let element = target;
      while (element && element != window.document.body) {
        var rect2 = element.getBoundingClientRect();
        var style = getComputedStyle(element, null);
        var marginLeft = parseInt(style.marginLeft) || 0;
        var marginRight = parseInt(style.marginRight) || 0;
        var marginTop = parseInt(style.marginTop) || 0;
        var marginBottom = parseInt(style.marginBottom) || 0;
        text += "\n\t"+DomDirective.renderNode(element)+
                "\n\t: width:"+rect2.width+",height:"+rect2.height+",left:"+element.clientLeft+",top:"+element.clientTop+" margin(top:"+marginTop+",right:"+marginRight+",bottom:"+marginBottom+",left:"+marginLeft+")";
        element = element.parentElement;
      }
      console.debug(text);
      return {
        width: target.clientWidth,
        height: target.clientHeight
      }
    } else {
      return {
        width:  target['innerWidth'],
        height: target['innerHeight'],
      }
    }*/
  }
}
