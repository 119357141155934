import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {

  transform(text: string, length: number = 250) {
    const withoutHtml = text.replace(/(<([^>]+)>)/gi, '');
    if (text.length >= length) {
      return `${withoutHtml.slice(0, length)}...`;
    }
    return withoutHtml;
  }
}
