import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Action, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, debounceTime, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import {addUrlParameter, EMPTY_ARRAY} from "core";
import {selectMediaListState, State} from "./state";
import {
    mediaLoadPageAction,
    mediaLoadPageDoneAction,
    mediaLoadPageFailedAction,
    mediaLoadRequestAction,
    mediaOnboardingLoadAction,
    mediaOnboardingLoadDoneAction,
    mediaOnboardingLoadFailedAction,
    mediaSetTypedFiltersAction,
    mediaUpdateAction,
    mediaUpdateDoneAction,
    mediaUpdateFailedAction,
    mediaUpdateSearchTermAction,
    mediaUpdateUserAction
} from "./actions";
import {Media} from "./models";

@Injectable()
export class MediaEffects {

  mediaLoadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaLoadRequestAction,mediaSetTypedFiltersAction,mediaUpdateSearchTermAction,mediaUpdateUserAction),
      debounceTime(100),
      withLatestFrom(this.store$.select(selectMediaListState)),
      map(([action,state]) => {
        //console.debug('loadRequest$', 'action', action, 'state', state);
        return mediaLoadPageAction({cacheId:state.currentCacheId,index:0,size:25})
      })));

  mediaLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaLoadPageAction),
      withLatestFrom(this.store$.select(selectMediaListState)),
      mergeMap(([action, state]) => {
        const filters = <string[]>[].concat(...Object.keys(state.filters).map(type=>state.filters[type]??EMPTY_ARRAY));
        if (filters?.length > 0) {
          //console.debug('loadPage$', 'action', action, 'state', state);
          var query   = encodeURI(state.term.trim().replace(/\s+/g, ','));
          var append  = addUrlParameter("all",filters.join(','),true).add("query",query,true).toString();
          var path    = "/v1.0/media/segment/"+action.cacheId+"/"+action.index+"/"+action.size+append;
          return this.http.get(path).pipe(
            map(result => {
              //console.debug("loaded",result);
              return mediaLoadPageDoneAction({cacheId:action.cacheId, index:action.index, data:result['data'], total:result['size'],parent:result['parent'],
                dispatch: (dispatchAction : Action) => {
                  //console.debug("dispatch loaded ["+action.index+"/"+action.size+"]");
                  this.store$.dispatch(dispatchAction);
                }})
            }),
            catchError((error) => {
              return of(mediaLoadPageFailedAction({cacheId:action.cacheId,index:action.index,size:action.size,error}))
            })
          )
        } else {
          return of(mediaLoadPageDoneAction({cacheId:action.cacheId,index:0,total:0,data:[],
            dispatch:(dispatchAction : Action) => this.store$.dispatch(dispatchAction)}));
        }
      })));

  mediaUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaUpdateAction),
      mergeMap(action  => {
        let path = `/v1.0/media`;
        const { media, mediaPath, deleteOrphans } = { ...action };
        const body: any = { media };
        mediaPath && (body.mediaPath = mediaPath);
        deleteOrphans && (body.deleteOrphans = deleteOrphans);
        return this.http.post(path, body).pipe(
          map((media: Media) => {
            if (!!action.media?.timeDeleted) {
              return mediaLoadRequestAction();
            } else {
              return mediaUpdateDoneAction({correlationId:action.correlationId,media});
            }
          }),
          catchError((error) => {
            console.error('ERROR', error);
            return of(mediaUpdateFailedAction({correlationId:action.correlationId,media:action.previous, error}));
          })
        )
      })));

    mediaOnboardingLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(mediaOnboardingLoadAction),
            //withLatestFrom(this.store$.pipe(select(selectMediaOnboardingState))),
            mergeMap(action => { //([action,state]) => {
                const filters = [`language.${action.language}`];
                const append  = addUrlParameter("all", filters.join(','),true).toString();
                const path = `/v1.0/media/onboarding/${action.contactId}${append}`
                return this.http.get(path).pipe(
                    map((result: { data: Media[] }) => {
                        return mediaOnboardingLoadDoneAction({
                          language: action.language,
                          data: result.data,
                          dispatch: (dispatchAction : Action) => {
                            this.store$.dispatch(dispatchAction);
                          }});
                    }),
                    catchError((error) => {
                        console.error('ERROR', error);
                        return of(mediaOnboardingLoadFailedAction({language: action.language, contactId: action.contactId, error}));
                    })
                )
            })));

  constructor(private http: HttpClient, private actions$: Actions, private store$: Store<State>) {}
}
