<!--<button class="close" mat-icon-button [mat-dialog-close]="false" aria-label="close">-->
<!--  <mat-icon>close</mat-icon>-->
<!--</button>-->
<div mat-dialog-content>
<!--  <template #logoContainer></template>-->
<!--  <ng-container [ngSwitch]="media?.mediaType===mediaType.video">-->
<!--    <app-video *ngSwitchCase="true" [media]="media" [inline]="true" class="logo"></app-video>-->
<!--    <ng-container *ngSwitchCase="false">-->
      <group-logo *ngIf="mode=='join'"
                  id="switch"
                  [group]="group"
                  [theme]="theme$ | async"
                  [backgroundColor]="backgroundColor$ | async"
                  (play)="onPlayMedia($event)">
      </group-logo>
<!--    </ng-container>-->
<!--  </ng-container>-->
  <div class="message">
    <div [innerHTML]="message | safeHtml"></div>
    <span class="avatar">
      <img *ngIf="photo; let photo" matListItemAvatar [src]="photo | sessionLink" onload="this.style.opacity=1"/>
      <span *ngIf="host.name">{{ host.name }}</span>
    </span>
    <div *ngIf="mode=='connect'" class="info" translate>groups.infoConnection</div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button *ngIf="mode!='noop'" mat-button [mat-dialog-close]="false" cdkFocusInitial>
    <span translate>{{'actions.'+(mode=='join' ? 'cancel' : 'decline')}}</span>
  </button>
  <button mat-button [mat-dialog-close]="mode!='noop'">
    <span translate>{{'actions.'+(mode!='noop' ? mode : 'ok')}}</span>
  </button>
</div>
