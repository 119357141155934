export class MenuSelection {

  static DEFAULT = new MenuSelection('project');
  static EMPTY = new MenuSelection(undefined);

  static fromPath(url: string, root = '') {
    url = url?.split(/[?#]/)[0] || ''; // remove query string and hash
    if (url.startsWith('/'+root)) {
      url = url.substr(root.length+1, url.length);
    }
    const segments = url.split('\/').filter(segment => !!segment);
    switch (segments.length) {
      case 0:  return MenuSelection.DEFAULT;
      case 1:  return new MenuSelection(segments[0]);
      default: return new MenuSelection(segments[0], segments.splice(1));
    }
  }

  constructor(public section: string, public filters?: string[], public done?: boolean) {
    this.filters = filters || [];
  }

  equals(other: MenuSelection, depth?:number): boolean {
    let equal = false;
    if (this.section == other?.section) {
      if (depth == 0) {
        equal = true;
      } else {
        if (depth || this.filters?.length==other.filters?.length) {
          depth = !depth || depth < 0 ? this.filters.length : depth;
          equal = this.filters.slice(0, depth).every((filter, index) => other.filters[index] == filter )
        }
      }
    }
    return equal;
  }

  toString(): string {
    return `Selection [section=${this.section}, filters=${this.filters}}`;
  }

  hasFilters(): boolean {
    //console.log("hasFilters section",this.section,"hasFilters",this.filters);
    return this.filters.length>0;
  }
}
