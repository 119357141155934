<h4 *ngIf="header" class="header" matSubheader disabled translate>{{ filter.label }}</h4>
<filter-tags #filterTags
   [filter]="filter"
   [selected]="selected"
   [readOnly]="readOnly"
   (onFilter)="onFilter.emit($event)"
   (onUpdate)="header=!!filterTags.filter$.value; onUpdate.emit(header)"
   [visibleFilter]="visibleFilter"
   [editableFilter]="editableFilter"
   [markEditable]="markEditable"
   [filterTagsTemplateRef]="filterTagsTemplateRef"
   class="section">
</filter-tags>
