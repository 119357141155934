<!--
<tri-state-checkbox *ngIf="canChangeDownlineInclude"
                    #downlineIncludeCheckbox
                    (change)="onDownlineIncludeChange(downlineIncludeCheckbox.value)"
                    aria-label="downline include">
  <span translate>media.downlineInclude</span>
</tri-state-checkbox>
-->
<ng-container *ngIf="current() as mediaReview">
  <mat-checkbox #approveCheckbox [checked]="mediaReview.state==MediaReviewState.Approved" (change)="onReviewChange(approveCheckbox)" aria-label="approve">
    <span translate>media.approved</span>
  </mat-checkbox>
  <mat-checkbox #declineCheckbox [checked]="mediaReview.state==MediaReviewState.Declined" (change)="onReviewChange(declineCheckbox)" aria-label="decline">
    <span translate>media.declined</span>
  </mat-checkbox>
  <mat-form-field *ngIf="declineCheckbox.checked">
    <mat-label translate>media.declineReason</mat-label>
    <textarea #autosize="cdkTextareaAutosize"
              matInput
              unmodified-input
              [value]="mediaReview.reason || ''"
              (change)="onReasonChange($event)"
              cdkTextareaAutosize
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="3"
              aria-label="decline reason">
          </textarea>
  </mat-form-field>
</ng-container>
