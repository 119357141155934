import {
  AuthenticationActionIds,
  authenticationLoginFailure, AuthenticationLoginSuccessAction,
  authenticationLogout,
  authenticationLogoutFailure,
  authenticationLogoutSuccess,
  authenticationUpdateUser,
  signContractFailure,
  signContractSuccess
} from "./actions";
import {User} from "core";
import {Action, createFeatureSelector, createReducer, createSelector, on} from "@ngrx/store";

export const authenticationFeatureKey = 'authentication';

export interface AuthenticationState {
  user: User;
  error: any;
  loading: boolean;
  autoLogin: boolean;
  signed: boolean;
}

export const initialAuthenticationState: AuthenticationState = {
  user: User.NULL,
  error: null,
  loading: false,
  autoLogin: false,
  signed: false
};

// do not export - leads to naming conflicts with other feature State interfaces
interface State {
  readonly [authenticationFeatureKey] : AuthenticationState;
}

export const selectAuthenticationState = createFeatureSelector<State, AuthenticationState>(authenticationFeatureKey);

export const selectAuthenticationUser  = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState): User => state.user
);

export const selectAuthenticationError  = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState): any => state.error
);

export const selectAuthenticationLoading  = createSelector(
  selectAuthenticationState,
  (state: AuthenticationState): boolean => state.loading
);

export const reducer = createReducer(
  initialAuthenticationState,
  {
    types: [AuthenticationActionIds.LOGIN],
    reducer: (state, action) => ({
      ...initialAuthenticationState,
      loading: true
    })
  },
  {
    types: [AuthenticationActionIds.LOGIN_SUCCESS],
    reducer: (state, action: AuthenticationLoginSuccessAction) => ({
      user: action.user,
      error: null,
      loading: false,
      autoLogin: action.autoLogin,
      signed: action.signed
    })
  },
  on(authenticationLoginFailure, (state, { reason }) => ({ ...initialAuthenticationState, error: reason, loading: false })),
  on(authenticationLogout, (state) => ({ ...state, loading: true })),
  on(authenticationLogoutSuccess, (state) => initialAuthenticationState),
  on(authenticationLogoutFailure, (state, { reason }) => ({...initialAuthenticationState, error: reason, loading: false,})),
  on(authenticationUpdateUser, (state, { user}) => ({ ...state, user })),
  on(signContractSuccess, (state) => ({ ...state, signed: true, loading: false })),
  on(signContractFailure, (state, { reason }) => ({ ...state, error: reason, loading: false }))
);

export function authenticationReducer(state: AuthenticationState | undefined, action: Action) {
  return reducer(state, action);
}
