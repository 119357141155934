import {AfterContentInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Subject, Subscription, timer} from "rxjs";
import {takeUntil} from "rxjs/operators";

export interface PressEvent {
  time: number,   // time between mouse down and up (or autotrigger)
  auto: boolean   // automatically triggered
}

export class PressStartEvent {
  time: number;   // automatically triggered
  public constructor() {
    this.time = Date.now();
  }
  cancel() {
    this.time = undefined;
  }
}

interface Position {
  pageX:number;
  pageY:number;
}

/**
 * add press support to component
 */
@Directive({
  selector: '[onPressStart],[onPress],[onContextMenu]'
})
export class PressDirective implements AfterContentInit, OnDestroy {
  @Input()  pressTriggerTime:number = undefined;
  @Output() onPress: EventEmitter<PressEvent> = new EventEmitter();
  @Output() onPressStart: EventEmitter<PressStartEvent> = new EventEmitter();
  @Output()
  get onContextMenu():EventEmitter<PressEvent> {
    this.contextMenu = true;
    return this.onPress;
  }

  protected contextMenu:boolean = false;
  protected onDestroy$ = new Subject<void>();
  protected pressDownSubscription:Subscription = undefined;
  protected pressStartEvent:PressStartEvent = undefined;
  protected pressStartPosition:Position = undefined;

  protected eventSubscription: Subscription;

  public constructor(protected elementRef: ElementRef) {
    // console.debug("PRESS");
    this.eventSubscription = this.addEventListeners(this.elementRef.nativeElement);
  }

  public ngOnDestroy(): void {
    this.onPress.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.eventSubscription.unsubscribe();
  }

  public ngAfterContentInit(): void {
  }

  public isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints;
  }

  protected addEventListeners(element: Element): Subscription {
    const events: string[][] = this.isTouchDevice() ?
      [['touchstart'], ['touchmove'], ['touchend', 'touchcancel'],['contextmenu']] :
      [['mousedown'], ['mousemove'], ['mouseup'],['contextmenu']];
    const listeners: Function[] = [
      (event) => this.onPointerDown(event),
      (event) => this.onPointerMove(event),
      (event) => this.onPointerUp(event),
      (event) => this.onContextMenuSelected(event)
    ]
    events.forEach((events, index) => {
        events.forEach(event =>
          element.addEventListener(event, event => listeners[index](event),{ passive: false })
        );
    })
    return new Subscription(() => {
      events.forEach((events, index) => {
        events.forEach(event => {
            // console.debug('UNSUBSCRIBE', event);
            element.removeEventListener(event, event => listeners[index](event))
        }
        );
      })
    });
  }

  protected cancelPress() {
    if (this.pressDownSubscription) {
      this.pressDownSubscription.unsubscribe();
    }
    this.pressDownSubscription = undefined;
    this.pressStartEvent = undefined;
    this.pressStartPosition = undefined;
  }

  // @HostListener('contextmenu',['$event'])
  onContextMenuSelected(event: MouseEvent): void {
    //console.debug("onPress.onContextMenu",event);
    event.preventDefault();
    event.stopPropagation();
    if (this.contextMenu) {
      this.onPress.emit({
        time: this.pressStartEvent?.time ? (Date.now()-this.pressStartEvent.time) : 0,
        auto: true
      });
    }
  }

  // @HostListener('touchstart',['$event'])
  // @HostListener('mousedown',['$event'])
  onPointerDown(event: MouseEvent|TouchEvent): void {
    //console.log("DOWN",event);
    //console.debug("onPress.onPointerDown",event,"event",this.pressStartEvent);
    //event.preventDefault();
    this.cancelPress();
    this.pressStartEvent = new PressStartEvent();
    if (!!(<MouseEvent>event).pageX && !!(<MouseEvent>event).pageY) {
      this.pressStartPosition = {
        pageX:(<MouseEvent>event).pageX,
        pageY:(<MouseEvent>event).pageY
      };
    } else if (!!(<TouchEvent>event).touches && (<TouchEvent>event).touches.length==1) {
      const touch = (<TouchEvent>event).touches[0];
      this.pressStartPosition = {
        pageX:touch.pageX,
        pageY:touch.pageY
      };
    }
    this.onPressStart.emit(this.pressStartEvent);
    if (this.pressTriggerTime && this.pressTriggerTime>100) {
      this.pressDownSubscription = timer(this.pressTriggerTime).pipe(
        takeUntil(this.onDestroy$),
      ).subscribe(time => {
        this.onTriggerPress(true);
      });
    }
  }

  // @HostListener('mouseout', ['$event'])
  onPointerOut(event:MouseEvent) {
    //console.debug("onPress.onMouseOut",event);
    this.cancelPress();
  }

  // @HostListener('touchmove', ['$event'])
  // @HostListener('mousemove', ['$event'])
  onPointerMove(event:MouseEvent|TouchEvent) {
    if (!!this.pressStartPosition) {
      if (!!(<MouseEvent>event).pageX || (<TouchEvent>event).touches.length==1) {
        const pageX = (<MouseEvent>event).pageX ?? (<TouchEvent>event).touches[0].pageX;
        const pageY = (<MouseEvent>event).pageY ?? (<TouchEvent>event).touches[0].pageY;
        if (Math.abs(pageX-this.pressStartPosition.pageX)>20 ||
            Math.abs(pageY-this.pressStartPosition.pageY)>20) {
          this.cancelPress();
        }
        //console.log("MOVE",event);
      } else {
        this.cancelPress();
      }
    }
  }

  // @HostListener('touchend',['$event'])
  // @HostListener('touchcancel',['$event'])
  // @HostListener('mouseup',['$event'])
  onPointerUp(event: MouseEvent | TouchEvent): void {
    //console.debug("onPress.onPointerUp",event);
    this.onTriggerPress(false);
  }

  onTriggerPress(auto:boolean) {
    //console.log("onTriggerPress",auto,"pressStartEvent",this.pressStartEvent);
    if (this.pressStartEvent &&
        this.pressStartEvent.time) {
      this.onPress.emit({
        time: (Date.now()-this.pressStartEvent.time),
        auto: auto
      });
    }
    this.cancelPress();
  }
}
