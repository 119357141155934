import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {PropertiesService} from "properties";

interface ConfirmDialogComponentData {
  label: string,
  parameters: {},
  icon?: string   // 'none' = no icon
  title?: string   // 'none' = no title
  confirmAction?: string;
  cancelAction?: string;
}

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <h1 mat-dialog-title align="center" *ngIf="data.title!='none'">{{data.title ? (data.title | translate) : undefined ?? propertiesService.group?.name ?? ('app.name' | translate)}}</h1>
    <div mat-dialog-content class="content">
      <mat-icon class="mat-accent" *ngIf="data.icon!='none'">{{data.icon ? data.icon : 'warning'}}</mat-icon>
      <h4 [innerHTML]="data.label | translate:data.parameters | safeHtml"></h4>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="false" cdkFocusInitial><span translate>actions.{{data.cancelAction || 'cancel'}}</span></button>
      <button mat-button [mat-dialog-close]="true"><span translate>actions.{{data.confirmAction || 'proceed'}}</span></button>
    </div>
  `,
  styles: [
    `.content { display: flex; align-items: center }`,
    `h4 { text-align: center; margin: 0; }`,
    `mat-icon { width: auto; height: auto; font-size: 3em; margin-right: 10px; flex-shrink: 0 }`,
    `div[mat-dialog-actions] { margin-top:16px; }`,
  ]
})
export class ConfirmDialogComponent {
  // following code produces "Expression form not supported." error when compiled/built as library
  // see https://github.com/angular/angular/issues/23904
  // constructor(
  //   public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  //   @Inject(MAT_DIALOG_DATA) public message: {
  //     label: string,
  //     parameters: {},
  //     icon?: string   // 'none' = no icon
  //     title?: string   // 'none' = no title
  //   }) { }
  constructor(
    public propertiesService: PropertiesService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogComponentData) { }
}

/*
@Injectable()
export class MediaChangeConsent {

  constructor(protected dialog: MatDialog,
              protected mediaService: MediaService) {}

  display(media: Media, previous?: Media): Promise<boolean> {
    return this.dialog
      .open(MediaChangeDialogComponent)
      .afterClosed()
      .pipe(map((result) => result === 'proceed'))
      .toPromise();
  }
}
*/
