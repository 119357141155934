import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  NgZone,
  Renderer2,
  SimpleChange,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {Platform} from "core";
import {Future, Viewport, ViewportUpdateInfo, VirtualScrollerComponent} from "./virtual-scroller";
import {Direction} from "./virtual-scroll.enums";
import {ObservableSubscription} from "../basic-container/basic-container.component";
import {Observable} from "rxjs";
import {EMPTY_ARRAY} from "core";
import {isValidNumber} from "../../util/number.util";
import {ResizeService} from "shared/lib/directives/resize/resize.service";

class SectionHeaderHandler {
  public get end() { return this.sectionOffset+this.sectionSize; }
  public get hidden() { return this._hidden; }
  public set hidden(hidden:boolean) {
    if (this.hidden!=hidden) {
      this._hidden = hidden;
      if (hidden) {
        this.renderer.removeStyle(this.headerElement, 'opacity');
        if (this.itemHeaderElement) {
          this.renderer.removeStyle(this.itemHeaderElement, 'opacity');
        }
      } else {
        this.renderer.setStyle(this.headerElement, 'opacity','1');
        if (this.itemHeaderElement) {
          this.renderer.setStyle(this.itemHeaderElement, 'opacity','0');
        }
      }
    }
  }
  public get padding() { return this._padding; }
  public set padding(padding) {
    if (this._padding!=padding) {
      const displacement = padding-this._padding;
      this.sectionOffset += displacement;
      this._padding = padding;
    }
  }
  public itemHeaderElement:Element;
  constructor(
    public readonly headerElement:Element,
    public readonly itemElement:Element|undefined,
    protected readonly itemHeaderElementAccessor:(Element)=>Element,
    public readonly renderer: Renderer2,
    public sectionOffset:number,
    public sectionSize:number,
    protected _padding:number,
    protected _hidden:boolean=undefined) {
    if (itemHeaderElementAccessor && itemElement) {
      this.itemHeaderElement = itemHeaderElementAccessor(itemElement);
      //console.log("HEADER",this.itemHeaderElement);
    }
  }
}

@Component({
  selector: 'virtual-section-scroller',
  template: `
    <div class="viewport" #viewport (onResize)="onResize($event)">
      <ng-container *ngTemplateOutlet="viewportStartComponentTemplateRef; context: { gap: startGap }"></ng-container>
      <div (onAttach)="ngOnAttach()" (onDetach)="ngOnDetach()"
           [dragHandler]="virtualScrollHandler"
           class="scroll-container" #scrollContainer>
        <div class="render-container" #renderContainer>
          <ng-container *ngTemplateOutlet="renderStartComponentTemplateRef; context: { gap: startGap }"></ng-container>
          <div (onDomModified)="onDomModified()" class="item-container" #itemContainer>
            <ng-content></ng-content>
          </div>
          <ng-container *ngTemplateOutlet="renderEndComponentTemplateRef; context: { gap: endGap }"></ng-container>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="viewportEndComponentTemplateRef; context: { gap: startGap }"></ng-container>
    </div>
    <div #stickyHeader class="sticky-header-container">
      <ng-container *ngIf="hasViewportSectionIndexes">
        <ng-container *ngFor="let index of viewportSectionIndexes">
          <div class="header">
            <ng-container
              *ngTemplateOutlet="sectionHeaderTemplate; context: { item: items[index], index: index }"></ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="scrollbar">
      <virtual-scrollbar [virtualScrollHandler]="virtualScrollHandler" [startGap]="stickyHeaderScrollGap ? stickyHeaderSize : 0"></virtual-scrollbar>
    </ng-container>
    <ng-container
      *ngTemplateOutlet="navigationTemplate; context: { virtualScrollHandler: virtualScrollHandler }">
    </ng-container>
    <!--
    <div class="debug">
      <div>scrollEvent all:{{this.updateMetrics.scrollEventsTotal | number: '1.0-0':'de'}} measured:{{this.updateMetrics.scrollEventsMeasured | number: '1.0-0':'de'}} delayed:{{this.updateMetrics.scrollEventsDelayed | number: '1.0-0':'de'}}</div>
      <div>scrollIdle all:{{this.updateMetrics.scrollIdles | number: '1.0-0':'de'}} avg:{{this.updateMetrics.scrollIdleTimes/(this.updateMetrics.scrollIdles||1) | number: '1.0-0':'de'}}ms</div>
      <div>scrollCalc all:{{this.updateMetrics.scrollMeasurements | number: '1.0-0':'de'}} avg:{{this.updateMetrics.scrollMeasurementTimes/(this.updateMetrics.scrollMeasurements||1) | number: '1.0-0':'de'}}ms</div>
      <div>render all:{{this.updateMetrics.scrollViewportUpdates | number: '1.0-0':'de'}} prep:{{this.updateMetrics.scrollViewportUpdatePrepareTimes/(this.updateMetrics.scrollViewportUpdates||1) | number: '1.0-0':'de'}}ms render:{{this.updateMetrics.scrollViewportUpdateTimes/(this.updateMetrics.scrollViewportUpdates||1) | number: '1.0-0':'de'}}ms adjust:{{this.updateMetrics.scrollViewportAdjustTimes/(this.updateMetrics.scrollViewportUpdates||1) | number: '1.0-0':'de'}}ms</div>
      <div>rendered: ({{previousViewport.renderedCount}}) {{previousViewport.renderedIndex}}-{{previousViewport.renderedIndex+previousViewport.renderedCount-1}} {{previousViewport.renderedSize | number: '1.0-0':'de'}}px</div>
      <div>buffered: ({{previousViewport.bufferedEndIndex+1-previousViewport.bufferedStartIndex}}) {{previousViewport.bufferedStartIndex}}-{{previousViewport.bufferedEndIndex}} {{previousViewport.expectedSize | number: '1.0-0':'de'}}px</div>
      <div>visible: ({{previousViewport.visibleEndIndex+1-previousViewport.visibleStartIndex}}) {{previousViewport.visibleStartIndex}}-{{previousViewport.visibleEndIndex}}</div>
      <div>total: {{previousViewport.itemCount}} size: {{previousViewport.scrollSize | number: '1.0-0':'de'}}px padding: {{previousViewport.padding | number: '1.0-0':'de'}}px</div>
      <div>scrollPosition: {{previousViewport.scrollPosition | number: '1.0-0':'de'}}px real: {{scrollPosition$ | async | number: '1.0-0':'de'}}px</div>
      <div>scrollStartPosition: {{previousViewport.scrollStartPosition | number: '1.0-0':'de'}}px</div>
      <div>scrollEndPosition: {{previousViewport.scrollEndPosition | number: '1.0-0':'de'}}px</div>
    </div>
    -->
  `,
  host: {
    '[class.horizontal]': "!vertical",
    '[class.vertical]': "vertical"
  },
  styleUrls: ['./virtual-section-scroller.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualSectionScrollerComponent extends VirtualScrollerComponent {

  protected _itemHeaderElementAccessor:(Element)=>Element;
  @Input()
  public get itemHeaderElementAccessor(): (Element)=>Element {
    return this._itemHeaderElementAccessor;
  }
  public set itemHeaderElementAccessor(accessor:(Element)=>Element)  {
    this._itemHeaderElementAccessor = accessor;
  }

  public sectionIndexes: number[] = [];

  protected sectionIndexesOS:ObservableSubscription<any[]> = this.createObservableSubscription((previousValue,currentValue, first)=> {
    //console.log("sectionIndexes$.previous",previousValue?.length,"current",currentValue.length,"equals",previousValue === currentValue);
    this.sectionIndexes = currentValue ?? EMPTY_ARRAY;
    this.changeDetectorRef.markForCheck();
    this.triggerChange('sectionIndexes',new SimpleChange(previousValue,currentValue,first));
  },[]);

  @Input()
  public get sectionIndexes$(): Observable<number[]> {
    return this.sectionIndexesOS.get();
  }
  public set sectionIndexes$(value: Observable<number[]>) {
    this.sectionIndexesOS.set(value);
  }

  @Input() stickyHeaderScrollGap:boolean = false;
  public stickyHeaderSize:number = 0;

  public _viewportSectionIndexes:number[] = [];
  public _currentSectionIndex:number = undefined;

  public get currentSectionIndex():number {
    return this._currentSectionIndex;
  }

  public set currentSectionIndex(index:number) {
    this._currentSectionIndex = index;
  }

  public get viewportSectionIndexes():number[] {
    return this._viewportSectionIndexes||[];
  }

  get hasViewportSectionIndexes(): boolean {
    //return this.viewportSectionIndexes.length>0 && !this.touchScroller;
    return this.viewportSectionIndexes.length>0;
  }

  protected setViewportItems(viewportItems:any[], index:number, items:any[]) {
    //this.viewportElementRef.nativeElement['scrollTop'] = this.virtualScrollHandler.scrollPosition;
    //console.log("setViewportItems.scrollTop",this.viewportElementRef.nativeElement['scrollTop'],this.virtualScrollHandler.scrollPosition);
    let sectionIndexes:number[] = [];
    if (!viewportItems?.length || !this.sectionIndexes.length) {
      this._viewportSectionIndexes = sectionIndexes;
    } else {
      const firstIndex = index;
      const lastIndex  = index+viewportItems.length;
      //console.info("FIRSTLAST",firstIndex,lastIndex,this.sectionIndexes);
      let leadingIndex = undefined;
      for (let section=0, max=this.sectionIndexes.length; section<max; section++) {
        let itemIndex = this.sectionIndexes[section];
        if (itemIndex>=lastIndex) {
          break;
        } else if (itemIndex<firstIndex) {
          leadingIndex = itemIndex;
        } else {
          if (sectionIndexes.length==0 && isValidNumber(leadingIndex) && itemIndex>firstIndex) {
            sectionIndexes.push(leadingIndex);
            leadingIndex = undefined;
          }
          sectionIndexes.push(itemIndex);
        }
      }
      if (isValidNumber(leadingIndex) && sectionIndexes.length==0) {
        sectionIndexes.push(leadingIndex);
      }
      if (this._viewportSectionIndexes?.length!=sectionIndexes.length ||
        this._viewportSectionIndexes.findIndex((value,index)=>value!=sectionIndexes[index])>=0) {
        // after rendering (dom changed), virtual scroller calls viewportUpdated()
        this._viewportSectionIndexes = sectionIndexes;
        //console.log("HEADERS",sectionIndexes);
      }
    }
    super.setViewportItems(viewportItems,index,items);
  }

  protected resetViewport() {
    super.resetViewport();
    this._viewportSectionIndexes = [];
  }

  protected viewportUpdated() {
    //console.log("viewportUpdated.scrollTop.1",this.viewportElementRef.nativeElement['scrollTop'],this.virtualScrollHandler.scrollPosition);
    //this.viewportElementRef.nativeElement['scrollTop'] = this.virtualScrollHandler.scrollPosition;
    //console.log("viewportUpdated.scrollTop.2",this.viewportElementRef.nativeElement['scrollTop'],this.virtualScrollHandler.scrollPosition);
    if (this.hasViewportSectionIndexes) {
      if (this.previousViewport.renderedIndex==this.previousViewport.bufferedStartIndex &&
        this.previousViewport.renderedCount==this.previousViewport.expectedCount &&
        this.viewportSectionIndexes?.length==this.stickyHeaderElementRef.nativeElement.children?.length) {
        const headerElements = this.stickyHeaderElementRef.nativeElement.children;
        let index = this.previousViewport.bufferedStartIndex;
        let sectionIndexes: number[] = this.viewportSectionIndexes;
        let sectionSizes: number[] = [];
        let sectionElements: Element[] = [];
        let currItemIndex = this.itemContainerElementRef.nativeElement.children.length - 1;
        //console.log("HEADERS.CALC",sectionIndexes,"items",currItemIndex);
        if (sectionIndexes.length && currItemIndex >= 0) {
          const fixedToStart   = this.previousViewport.scrollDirection==Direction.EndToStart ? false : true;
          const anchorDistance = fixedToStart ? this.previousViewport.padding : this.previousViewport.padding+this.previousViewport.renderedSize;//FIX!! this.previousViewport.anchorDistance || 0;
          const itemsSize      = this.itemContainerElementRef.nativeElement[this._sizeProperty];
          let   coreSpace      = fixedToStart ? anchorDistance : anchorDistance - itemsSize;
          //console.log("CORE:SPACE",coreSpace);
          for (let i = sectionIndexes.length - 1; i >= 0; i--) {
            const sectionIndex = sectionIndexes[i];
            let   itemElement:Element = undefined;
            let   containerSpace = 0;
            while (currItemIndex >= 0 && (index + currItemIndex) >= sectionIndex) {
              itemElement = this.itemContainerElementRef.nativeElement.children[currItemIndex--];
              containerSpace += itemElement[this._sizeProperty] || 0;
            }
            if (i == sectionIndexes.length-1) {
              containerSpace += (this.previousViewport.scrollSize-itemsSize-coreSpace);
            }
            if (i == 0 && sectionIndex < index) {
              itemElement = undefined;
              //console.log("CORE:i",i,"sectionIndex",sectionIndex,"index",index,"coreSpace",coreSpace);
              while (currItemIndex >= 0) {
                coreSpace += this.itemContainerElementRef.nativeElement.children[currItemIndex--][this._sizeProperty] || 0;
              }
              containerSpace += coreSpace;
              coreSpace = 0;
            }
            sectionSizes.push(containerSpace);
            sectionElements.push(itemElement);
            //console.log("CORE:PUSH:i",i,"containerSpace",containerSpace,"coreSpace",coreSpace);
          }
          while (currItemIndex >= 0) {
            coreSpace += this.itemContainerElementRef.nativeElement.children[currItemIndex--]?.offsetHeight || 0;
          }
          this.viewportSectionHandlers = [];
          for (let i=sectionSizes.length-1, x=0, offset=coreSpace; i>=0; i--, x++) {
            this.viewportSectionHandlers.push(new SectionHeaderHandler(headerElements[x],sectionElements[i],this.itemHeaderElementAccessor,this.renderer,offset,sectionSizes[i],this.previousViewport.padding));
            offset += sectionSizes[i];
          }
          //console.log("CORE:ALL",this.viewportSectionHandlers);
          this.updateStickyHeader();
        }
      } else {
        console.trace("HEADERS.ERROR",this.previousViewport,
          "\nrenderedIndex",this.previousViewport.renderedIndex,this.previousViewport.bufferedStartIndex,
          "\nrenderedCount",this.previousViewport.renderedCount,this.previousViewport.expectedCount,
          "\nlength",this.viewportSectionIndexes?.length,this.stickyHeaderElementRef.nativeElement.children?.length);
      }
    }
    super.viewportUpdated();
  }

  protected viewportSectionHandlers:SectionHeaderHandler[];

  protected adjustOnScroll():boolean {
    if (super.adjustOnScroll()) {
      this.updateStickyHeader();
      return true;
    }
    return false;
  }

  protected updateViewport(itemsChanged: boolean, itemsUpdated:boolean = false, resized:boolean = false, scrolled:boolean = false, updateInfo:ViewportUpdateInfo = { pass:3,finished:new Future(), items:this.items, itemsId:this.itemsId }): Future<Viewport> {
    //console.log("refresh_internal_sync.scrollTop",this.viewportElementRef.nativeElement['scrollTop'],this.virtualScrollHandler.scrollPosition);
    const result = super.updateViewport(itemsChanged,itemsUpdated,resized,scrolled,updateInfo);
    if (updateInfo.rendered) {
      this.updateStickyHeader();
    }
    return result;
  }

  protected updateStickyHeader() {
    let stickyHeaderSize = 0;
    //console.log("updateStickyHeader",this.viewportSectionHandlers?.length,this.viewportSectionIndexes.length);
    if (this.hasViewportSectionIndexes &&
      this.viewportSectionHandlers?.length==this.viewportSectionIndexes.length) {
      //console.log("updateStickyHeader.position",this.virtualScrollHandler.scrollPosition,this.viewportSectionHandlers);
      let currentSectionIndex    = undefined;
      const padding = this.startFromTop ?
                      this.previousViewport.padding :
                      this.previousViewport.padding + Math.max(0,this.previousViewport.viewportSize-this.previousViewport.renderedSize);
      for (let i=0, position=this.virtualScrollHandler.scrollPosition; i<this.viewportSectionHandlers.length; i++) {
        const headerHandler   = this.viewportSectionHandlers[i];
        headerHandler.padding = padding;
        //console.log("updateStickyHeader.index",i,"position",position,"headerHandler.start",headerHandler.offset,"headerHandler.end",headerHandler.end);
        if (position<=headerHandler.end && position>=headerHandler.sectionOffset && currentSectionIndex==undefined) {
          //console.log("i",i,"of",this.viewportSectionHandlers.length,"visible","\nhandler",headerHandler);
          this.currentSectionIndex = currentSectionIndex = this.viewportSectionIndexes[i];
          const headerSize = headerHandler.headerElement[this._sizeProperty];
          const headerDisplacement = headerSize-Math.min(headerSize,headerHandler.end-position);
          //console.log("HEADER.SIZE",headerSize,"displacement",headerDisplacement,"offset",headerHandler.offset,"position",position);
          headerHandler.hidden = false;
          stickyHeaderSize = headerSize;
          this.renderer.setStyle(this.stickyHeaderElementRef.nativeElement, 'transform', `${this._translatePaddingProperty}(${-headerDisplacement}px)`);
        } else {
          //console.log("i",i,"of",this.viewportSectionHandlers.length,"hidden","\nhandler",headerHandler);
          headerHandler.hidden = true;
        }
      }
    }
    if (this.stickyHeaderSize!=stickyHeaderSize) {
      this.stickyHeaderSize = stickyHeaderSize;
      this.changeDetectorRef.markForCheck();
    }
  }

  @ContentChild('sectionHeaderTemplate', { static: true })
  sectionHeaderTemplate: TemplateRef<any>;

  @ViewChild('stickyHeader', { read: ElementRef, static: false })
  protected stickyHeaderElementRef: ElementRef;

  constructor(
    public readonly elementRef: ElementRef,
    protected readonly platform: Platform,
    protected readonly renderer: Renderer2,
    protected readonly zone: NgZone,
    protected changeDetectorRef: ChangeDetectorRef,
    protected resizeService: ResizeService) {
    super(elementRef,platform,renderer,zone,changeDetectorRef,resizeService);
  }
}
