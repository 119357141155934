export const Reactions = [
  'like', 'love', 'lol', 'wow', 'sad', 'angry',
  'sexual', 'violent', 'hateful', 'harmful', 'spam',
  'none'
] as const;

export type ReactionTuple  = typeof Reactions;
export type Reaction       = ReactionTuple[number];
export type ReactionType   = 'emotion' | 'report';

export namespace Reaction {
  export const like:    Reaction = Reactions[0];
  export const love:    Reaction = Reactions[1];
  export const lol:     Reaction = Reactions[2];
  export const wow:     Reaction = Reactions[3];
  export const sad:     Reaction = Reactions[4];
  export const angry:   Reaction = Reactions[5];
  export const sexual:  Reaction = Reactions[6];
  export const violent: Reaction = Reactions[7];
  export const hateful: Reaction = Reactions[8];
  export const harmful: Reaction = Reactions[9];
  export const spam:    Reaction = Reactions[10];
  export const none:    Reaction = Reactions[11];

  export function getReactionsByType(type: ReactionType, none = true): Reaction[]  {
    let reactions: Reaction[];
    switch (type) {
      case 'emotion': reactions = Reactions.slice(0, 5); break;
      case 'report': reactions = Reactions.slice(6, 10); break;
      default: reactions = [];
    }
    return none ? [...reactions, Reaction.none] : reactions;
  }

  export function getReactionType(reaction: Reaction): ReactionType  {
    const index = Reactions.indexOf(reaction)
    return index >=0 && index < Reactions.length-1
         ? index <= 5
            ? 'emotion'
            : index < Reactions.length-2
              ? 'report' : undefined
        : undefined
  }
}
