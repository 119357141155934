import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {FilterService} from "../../filter.service";
import {BasicFilterComponent} from "./basic-filter.component";
import {BehaviorSubject, take, takeUntil} from 'rxjs';
import {ResizeEvent, VirtualSectionScrollerComponent} from "shared";
import {AccordionFilterSectionContext} from "../filter.component.factory.service";

@Component({
  selector: 'filter-list',
  templateUrl: './basic-filter-list.component.html',
  styleUrls: ['./basic-filter-list.component.scss']
})
export class BasicFilterListComponent extends BasicFilterComponent {

  items$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  cacheId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  searchFocus: boolean = false;
  searchValue: string = null;
  selectAll: boolean = false;

  itemDefaultSize:number = 40;
  startGap:number = 8;
  endGap:number = 8;

  @ViewChild('scroller', { read: VirtualSectionScrollerComponent })
  scrollerComponent: VirtualSectionScrollerComponent;

  constructor(
    protected filterService: FilterService,
    protected changeDetectorRef: ChangeDetectorRef
  ) {
    super(filterService,changeDetectorRef);
    this.updateCacheId();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.items$.pipe(takeUntil(this.onDestroy$)).subscribe(items=>{
      this.scrollToStart();
    });
  }

  protected updateCacheId() {
    this.cacheId$.next('m' + Math.floor((Math.random() * 100_000_000) + 1).toString(16) + Date.now().toString(16));
  }

  @Input()
  set context(context: AccordionFilterSectionContext) {
    //this.startGap = !!context.search ? 8 : 16;
    super.context = context;
  }

  get context():AccordionFilterSectionContext {
    return super.context;
  }

  onSearch(value: string) {
    this.loadItems(value);
  }

  protected loadItems(value?: string, selectedIds?: string[]) {
    /*
    this.filterService.getFilterItems$(this.filter, value, selectedIds)
      .then(result=>this.items$.next(result));
    */
  }

  protected scrollToStart() {
    // Timeout to fix rendering issue (missing items)
    // It would be better to have a setting in section scroller that always
    // scrolls back to start when items are updated
    if (this.scrollerComponent) {
      window.setTimeout(() => {
        this.scrollerComponent.scrollToStart(0);
        this.scrollerComponent.refresh();
      }, 100);
    }
  }

  resetFilters() {
    super.resetFilters();
    this.selectAll = false;
  }

  clearSearch() {
    this.searchValue = '';
    this.selectAll = false;
    this.onSearch(this.searchValue);
  }

  setSelectAll(value: boolean) {
    if (value) {
      this.items$.pipe(take(1)).subscribe(items => {
        let selected = new Set<string>();
        this.selectedFilterIds.forEach(id=>selected.add(id));
        items.forEach(item=>selected.add(item.id));
        let ids = [];
        selected.forEach(id=>ids.push(id));
        super.selectedFilterIds = ids.sort();
      });
    } else {
      this.resetFilters();
    }
  }

  onOpen() {
    this.searchValue = '';
    this.selectAll = false;
    this.loadItems('', this.selectedFilterIds);
  }

  onResize(event: ResizeEvent) {
    if (this.scrollerComponent) {
      this.scrollerComponent.refresh();
    }
  }

  trackByItem(index: number, item: any): string {
    return `${item}`;
  }
}
