import {ChangeDetectionStrategy, Component, ElementRef} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MediaViewer} from "../../media-viewer";
import {MediaService} from "../../../../service/media.service";
import {Media} from "../../../../store/models";

@Component({
  selector: 'image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageViewerComponent extends MediaViewer {

  tracked:boolean;

  constructor(public http: HttpClient,
              public mediaService: MediaService,
              protected elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.mountActionsDisplayTrigger(this.elementRef.nativeElement);
  }

  setMedia(media: Media): Promise<Media>  {
    this.tracked = false;
    return super.setMedia(media);
  }

  loaded() {
    //console.info("LOADED");
    if (!this.tracked && this.media && !this.media.touched) {
      this.tracked = true;
      this.mediaService.markPlayed(this.media.id);
      /*

      this.http.post("/v1.0/media/reaction/" + this.media.id, {
        played: '[]'
      }).subscribe((response: Media) => {
        if (response) {
          this.mediaService.played(this.media.id,{
            played: response.played,
            playedTime: response.playedTime,
            completed: response.completed,
            touched: response.touched
          });
        }
      },function(error) {
        console.log("error",error);
        //this.logger.error("Task Tag ERROR", error);
        //reject(error);
      });*/
      /*/v1.0/media/reaction/
      this.http.post("/v1.0/media/tag/" + this.task.id + "/started/add", {
        played: segments.toString()
      }).subscribe((response: any) => {
        if (response.data && response.data.done && response.data.task) {
          this.task = response.task;
        }
        resolve(void 0);
      },function(error) {
        this.logger.error("Task Tag ERROR", error);
        reject(error);
      });
      */
    }
  }
}
