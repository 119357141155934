<div class="container">
  <!-- index property binding leads to issues with initial slide visualization -->
  <swiper [slidesPerView]="1"
          [spaceBetween]="30"
          [navigation]="false"
          [keyboard]="{ enabled: true }"
          (afterInit)="onAfterInit($event)"
          (realIndexChange)="onRealIndexChange($event)"
          (activeIndexChange)="onActiveIndexChange($event)"
          (slideChange)="onSlideChange($event)"
          (slideChangeTransitionStart)="onSlideChangeTransitionStart($event)"
          (slideChangeTransitionEnd)="onSlideChangeTransitionEnd($event)"
          (slideNextTransitionStart)="onSlideNextTransitionStart($event)"
          (slideNextTransitionEnd)="onSlideNextTransitionEnd($event)"
          (slidePrevTransitionStart)="onSlidePrevTransitionStart($event)"
          (slidePrevTransitionEnd)="onSlidePrevTransitionEnd($event)"
          (transitionStart)="onTransitionStart($event)"
          (transitionEnd)="onTransitionEnd($event)"
          (touchStart)="onTouchStart($event)"
          (touchMove)="onTouchMove($event)"
          (touchEnd)="onTouchEnd($event)">
    <ng-container *ngFor="let media of entities; let index = index">
      <ng-template swiperSlide>
        <media-viewer [autoplay]="autoplay"
                      [hasToolbar]="false"
                      [toolbarHandler]="onToolbarDisplay"
                      (changed)="onMediaChanged($event)"
                      (actionIds)="onMediaActionIds($event)"
                      (closed)="onClose()"
                      (action)="onMediaViewerAction($event)"
                      (pointerdown)="onMediaViewerPointerDown($event)">
        </media-viewer>
      </ng-template>
    </ng-container>
    <!--
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
    -->
  </swiper>
  <ng-container *ngIf="{ count: entities?.length } as slides">
    <ng-container *ngIf="{ mediaActions: mediaActions$ | async,
                           hasNavigation: slides.count > 1,
                           prev: index > 0,
                           next: index < slides.count - 1 } as context">
      <ng-template #actionsTemplate>
        <ng-container *ngIf="context.hasNavigation"
                      [ngTemplateOutlet]="actionTemplate"
                      [ngTemplateOutletContext]="{ action: navigationActions[ACTION_PREVIOUS], disabled: !context.prev }">
        </ng-container>
        <ng-container *ngTemplateOutlet="mediaActionsTemplate; context: { mediaActions: context.mediaActions }"></ng-container>
        <ng-container *ngIf="context.hasNavigation"
                      [ngTemplateOutlet]="actionTemplate"
                      [ngTemplateOutletContext]="{ action: navigationActions[ACTION_NEXT], disabled: !context.next  }">
        </ng-container>
      </ng-template>
      <div *ngIf="hasToolbar() && !!context.mediaActions?.length"
           [@fadeInOut]="{ value:':enter,:leave', params:  animationTimings.fadeInOut}"
           [@slideInOut]="{ value:':enter,:leave', params: animationTimings.slideInOut}"
           class="actions-container"
           [class]="media.mediaType">
        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-template #mediaActionsTemplate let-mediaActions="mediaActions">
  <div class="media-actions">
    <ng-container *ngFor="let mediaAction of mediaActions; trackBy: trackByMediaAction">
      <ng-container *ngTemplateOutlet="actionTemplate; context: { action: mediaAction }"></ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template #actionTemplate let-action="action" let-disabled="disabled">
  <ng-template #buttonTemplate let-action="action" let-disabled="disabled">
    <div *ngIf="action.image as image" class="image">
      <img matListItemAvatar [src]="imageUrl(image) | sessionLink"/>
    </div>
    <button mat-icon-button
            class="action-button"
            [disabled]="disabled"
            [class.dual]="!!action.icon">
      <mat-icon *ngIf="action.icon as icon">{{icon}}</mat-icon>
      <span *ngIf="action.label as label" translate>{{label}}</span>
      <span *ngIf="!action.label" translate>media.actions.{{action?.id}}</span>
    </button>
  </ng-template>
  <ng-template #defaultTemplate let-action="action" let-disabled="disabled">
    <ng-container [ngSwitch]="action.id">
      <ng-container *ngSwitchCase="'review'">
        <div  #mediaReviewMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="mediaReviewMenu">
          <mat-menu #mediaReviewMenu="matMenu" yPosition="above" xPosition="after">
            <div class="media-review-menu">
              <media-review #mediaReview
                            [value]="media.review"
                            (onMediaReviewChange)="onMediaReviewChange($event)"
                            (click)="$event.stopPropagation()">
              </media-review>
              <div class="media-review-actions">
                <button mat-mini-fab
                        color="primary"
                        aria-label="review"
                        [disabled]="!mediaReviewDirty()"
                        (click)="onMediaReviewUpdate(action, mediaReview.current())">
                  <mat-icon>done</mat-icon>
                </button>
                <button mat-mini-fab
                        color="primary"
                        arial-label="close"
                        (click)="onMediaReviewClose()">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </mat-menu>
        </div>
       <ng-container *ngTemplateOutlet="buttonTemplate; context: { action, disabled }"/>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="buttonTemplate; context: { action, disabled }"/>
      </ng-container>
    </ng-container>
  </ng-template>
  <div [@fadeInOut]="{ value:':enter,:leave', params:  animationTimings.fadeInOut}"
       [@.disabled]="false"
       [action]="action"
       (onAction)="!disabled && onMediaAction($event)"
       class="action"
       [class.disabled]="disabled">
    <ng-container *ngTemplateOutlet="action.template || defaultTemplate; context: { action, disabled }"></ng-container>
  </div>
</ng-template>
