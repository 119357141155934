import {Inject, Injectable} from "@angular/core";
import {
  AuthenticationGuestLoginAction,
  AuthenticationLoginAction,
  AuthenticationLoginSuccessAction,
  authenticationLogout,
  authenticationLogoutSuccess,
  AuthenticationPasscodeLoginAction,
  signContract,
} from "../store/actions";
import {
  AuthenticationState,
  selectAuthenticationError,
  selectAuthenticationLoading,
  selectAuthenticationState
} from "../store/reducers";
import {APP_ID, AppIdentifierProvider, Logger, NULL_USER, User} from "core";
import {ActionsSubject, select, Store} from "@ngrx/store";
import {BehaviorSubject, Observable, of, throwError} from "rxjs";
import {catchError, filter, map, mergeMap} from "rxjs/operators";
import {PropertiesService} from "properties";
import {HttpClient} from "@angular/common/http";
import isEqual from "lodash/isEqual";
import {LoginResult, LogoutResult} from "../models/credentials";
import {StoreService} from "store";

// const credentialsKey = 'credentials';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends StoreService {

  protected _user$ = new BehaviorSubject<User>(this.nullUser);
  protected _signed$ = new BehaviorSubject<boolean>(false);
  protected logger = new Logger('AuthenticationService');

  constructor(protected store$: Store<any>,
              protected action$: ActionsSubject,
              protected propertiesService: PropertiesService,
              protected http: HttpClient,
              @Inject(NULL_USER) protected nullUser: User,
              @Inject(APP_ID) protected appId: number,
              private appIdentifierProvider: AppIdentifierProvider) {
    super(store$,action$);
    console.log('AuthenticationService.ctor');
    this.store$
      .pipe(
        select(selectAuthenticationState),
        filter (state => !isEqual(this.user, state?.user) || this.signed!=state?.signed)
      )
      .subscribe(state => {
        const user = state.user || this.nullUser;
        this._user$.next(user);
        this._signed$.next(state.signed);
        if (!isEqual(state.user, this.propertiesService.user) ||
            this.propertiesService.properties.signed != state.signed) {
          this.propertiesService.reload();
        }
        (window as any).user = user;
    });
    this.propertiesService.properties$
      .pipe(
        filter(properties =>
          !isEqual(this._user$.value, properties.user) ||
          this._signed$.getValue() != properties.signed)
      )
      .subscribe(properties => {
        this.store$.dispatch(new AuthenticationLoginSuccessAction(
          { app_id: this.appId },
          properties.user,
          !this.user.isAuthenticated, // autologin if it was not logged in
          properties.signed
        ));
      });
  }

  get user$(): Observable<User> {
    return this._user$;
  }

  get user(): User {
    return this._user$.getValue();
  }

  get authenticated$(): Observable<boolean> {
    return this.user$.pipe(map(user => user.isAuthenticated));
  }

  get authenticated(): boolean {
    return this._user$.getValue().isAuthenticated;
  }

  get signed$(): Observable<boolean> {
    return this._signed$;
  }

  get signed(): boolean {
    return this._signed$.getValue();
  }

  authenticatedAndSigned() {
    return this.authenticated && this.signed;
  }

  login(memberId: string, password: string): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      let action = new AuthenticationLoginAction({
        app_id: this.appId,
        member_id: memberId,
        password: password,
        state: {
          path: window.location.pathname,
          href: window.location.href
        }
      },resolve,reject);
      this.store$.dispatch(action);
    });
  }

  loginGuest(token: string, autosign:boolean=false): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      let action = new AuthenticationGuestLoginAction({
        app_id: this.appId,
        invitation_token: token,
        autosign: autosign,
        state: {
          path: window.location.pathname,
          href: window.location.href
        }
      },resolve,reject);
      this.store$.dispatch(action);
    });
  }

  loginPasscode(memberId: string, passcode: string): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      let action = new AuthenticationPasscodeLoginAction({
        app_id: this.appId,
        member_id: memberId,
        passcode: passcode,
        state: {
          path: window.location.pathname,
          href: window.location.href
        }
      },resolve,reject);
      this.store$.dispatch(action);
    });
  }

  logout(local = false): Promise<LogoutResult> {
    return new Promise((resolve, reject) => {
      if (local) {
        this.dispatch(authenticationLogoutSuccess()).then(() => resolve({ status:'ok' })).catch(reject);
        // this.store$.dispatch(new AuthenticationLogoutSuccessAction());
      } else {
        this.store$.dispatch(authenticationLogout({ onSuccess: resolve, onFailure: reject }));
        // this.store$.dispatch(new AuthenticationLogoutAction());
      }
    });
  }

  requestPasscode(memberId: string, phone: string): Promise<void> {
    // this.store$.dispatch(new PasscodeRequestAction(memberId));
    return this.http.post<any>(
      '/v1.0/auth/password/code',
      {"member_id": memberId, "phone": phone}
    )
      .pipe(
        mergeMap(result => {
          console.debug(result);
          if (!result || !result.done) {
            return throwError(new Error('Failed to get passcode'));
          } else {
            return of(null);
          }
        })
      )
      .toPromise();
  }

  requestAuthenticationToken(): Promise<string> {
    // this.store$.dispatch(new PasscodeRequestAction(memberId));
    return this.http.get<any>('/v1.0/session/clone')
      .pipe(
        mergeMap(result => {
          console.debug(result);
          if (!result || !result.done || !result.token) {
            return throwError(new Error('Failed to get token'));
          } else {
            return of(result.token);
          }
        })
      )
      .toPromise();
  }

  sign(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.store$.dispatch(signContract({
        signed: true,
        callback: (signed:boolean)=>{
          if (signed) {
            resolve(signed);
          } else {
            reject();
          }
        }
      }));
    });
  }

  state$(): Observable<AuthenticationState> {
    return this.store$.pipe(select(selectAuthenticationState));
  }

  loading$(): Observable<boolean> {
    return this.store$.pipe(select(selectAuthenticationLoading));
  }

  error$(): Observable<any> {
    return this.store$.pipe(select(selectAuthenticationError));
  }

  sign$(): Observable<boolean> {
    return this.store$.pipe(
      select(selectAuthenticationState),
      map(state => state.user.isAuthenticated && !state.signed));
  }

  setInvitationCode(code: string): Promise<void> {
    return this.http.post('/v1.0/auth/invitationToken/store', {
      token: code
    }).toPromise().then(result => void 0);
  }

  hasInvitationCode(): Promise<boolean> {
    return this.http.get<any>('/v1.0/auth/invitationToken/store')
      .pipe(
        map(result => result?.found),
        catchError(error => {
          return throwError(new Error('Failed to check invitation code availability'));
        })
      )
      .toPromise();
  }

  /**
   @deprecated
   */
  getInvitationCode(): Promise<string> {
    return this.http.get<any>('/v1.0/auth/invitationToken/store')
      .pipe(
        mergeMap(result => {
          console.debug(result);
          if (!result || result.token == undefined) {
            return throwError(new Error('Failed to get invitation code'));
          } else {
            return of(result.token);
          }
        })
      )
      .toPromise();
  }
}
