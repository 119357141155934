import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Inject,
  Renderer2
} from '@angular/core';
import {Contact, ENVIRONMENT, themeMode$} from "core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Group} from "properties";
import {Observable} from "rxjs";
import {Media, MediaService} from "media";
import {BasicContainerComponent} from "shared";
import {TranslateService} from "@ngx-translate/core";
import {map, shareReplay, takeUntil, tap} from "rxjs/operators";
import {GroupLogoComponent} from "group";

@Component({
  selector: 'app-group-switch-dialog',
  templateUrl: './group-switch-dialog.component.html',
  styleUrls: ['./group-switch-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupSwitchDialogComponent extends BasicContainerComponent {

  // @ViewChild('logoContainer', { static: true, read: ViewContainerRef }) container;
  // componentRef: ComponentRef<any>;

  group: Group;
  host: Partial<Contact>;
  member: boolean;
  connected: boolean;
  time: string;
  theme$: Observable<string>;
  backgroundColor$: Observable<string>;
  component: GroupLogoComponent;

  mode: 'join' | 'connect' | 'noop' = 'noop';
  message: string;

  constructor(protected dialogRef: MatDialogRef<GroupSwitchDialogComponent>,
              protected resolver: ComponentFactoryResolver,
              protected changeDetector: ChangeDetectorRef,
              protected renderer: Renderer2,
              public mediaService: MediaService,
              protected translateService: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              @Inject(ENVIRONMENT) public environment: any) {
    super();
    const {group, host, member, connected} = data;
    this.group = group;
    this.host = host;
    this.member = member;
    this.connected = connected;
    this.mode = !member ? 'join' : !connected ? 'connect' : 'noop';
    const messageKey = this.mode == 'join'    ? 'invitedBy'
                     : this.mode == 'connect' ? 'confirmConnection'
                     : 'alreadyConnected';
    this.translateService.get(`groups.${messageKey}`, { group: this.group?.name })
      .toPromise()
      .then(message => this.message = message);
    // https://stackoverflow.com/questions/52742855/rxjs-share-operator-with-behavior-subject-and-async-pipe-angular
    this.theme$ = themeMode$.asObservable().pipe(shareReplay(1)); // publishReplay(1).refCount() see https://github.com/ReactiveX/rxjs/issues/3336
    this.backgroundColor$ = this.theme$.pipe(
      takeUntil(this.onDestroy$),
      map(theme => theme=='dark' ? 'transparent' : this.environment.primaryColor),
      tap(backgroundColor => console.debug('BACKGROUND COLOR', backgroundColor))
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    // this.createComponent();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    // this.componentRef.destroy();
  }

  get photo(): string {
    return this.host?.id
      ? `${this.environment.serverUrl}/v1.0/content/avatar/${this.host.version || 0}/${this.host.id}.jpg`
      : undefined;
  }

  onPlayMedia(media: Media) {
  }

  /*
  createComponent(): Promise<GroupLogoComponent> {
    this.container.clear();
    const componentType: Type<any> = GroupLogoComponent;
    const factory: ComponentFactory<GroupLogoComponent> = this.resolver.resolveComponentFactory(componentType);
    this.componentRef = this.container.createComponent(factory);
    const component: GroupLogoComponent = this.componentRef.instance;
    component.group = this.group;
    return themeMode$.asObservable()
      .toPromise()
      .then((theme) => {
        component.theme = theme;
        component.onChanges();
        return component;
      });
  }
  */
}
