export interface SyncStorage<T = any> {
  has(key: string): boolean;
  get(key: string): T;
  set(key: string, value: T): void;
  remove(key: string): void;
  clear(): void;
}

export function isStorageAvailable(storage: Storage): boolean {
  if (storage) {
    try {
      const now = Date.now();
      const testKey = `storage-test-key-${now}`;
      const testValue = `storage-test-value-${now}`;
      storage.setItem(testKey, testValue);
      const retrievedValue = storage.getItem(testKey);
      storage.removeItem(testKey);
      return retrievedValue === testValue;
    } catch (error) {
      console.error('isStorageAvailable', error)
    }
  }
  return false;
}
