import {ChangeDetectorRef, Component} from '@angular/core';
import {FilterService} from '../../filter.service';
import {BasicFilterComponent} from '../core/basic-filter.component';

@Component({
  selector: 'tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.scss']
})
export class TagFilterComponent extends BasicFilterComponent {

  constructor(
    protected filterService: FilterService,
    protected changeDetectorRef: ChangeDetectorRef) {
    super(filterService,changeDetectorRef);
  }
}
