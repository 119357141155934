import {CachedState, EntityState, IoState, SelectableState} from "core";
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {Session} from "./models";

export interface SessionState extends
  IoState,
  SelectableState,
  EntityState<Session> {
  cacheId?:string,
  subscribed:boolean
}

export const initialSessionState : SessionState = {
  entities: [],
  indices: {},
  subscribed: false,
  error: null
};

export interface State {
  readonly session: SessionState;
}

export const selectSessionState = createFeatureSelector<State,SessionState>('session');

export const selectSessionEntities = createSelector(
  selectSessionState,
  state => state.entities
);

export const selectSessionCacheId = createSelector(
  selectSessionState,
  state => state.cacheId
);

export const selectSubscribed = createSelector(
  selectSessionState,
  state => state.subscribed
);

export const selectSessionEntitiesLength = createSelector(
  selectSessionEntities,
  state => state.length
);

export const selectSelectedSessionIndex = createSelector(
  selectSessionState,
  state => state.selectedIndex
);

export const selectSession = createSelector(
  selectSessionState,
  (state: SessionState, sessionId: string): Session => {
    let index = state.indices[sessionId];
    return index >= 0 && index < state.entities.length ? {...state.entities[index]} : null;
  }
);

export const selectSessionIndex = createSelector(
  selectSessionState,
  (state: SessionState, sessionId: string): number => {
    return state.indices[sessionId];
  }
);

export const selectSessionError = createSelector(
  selectSessionState,
  state => state.error
);
