<div class="action-selector">
  <button *ngIf="visible" #button
          mat-icon-button
          type="button"
          aria-haspopup="dialog"
          [attr.tabindex]="disabled ? -1 : tabIndex"
          [disabled]="disabled"
          [disableRipple]="disableRipple"
          (click)="onClick($event)"
          [@ngIfAnimation]>
    <mat-icon class="icon"><ng-content></ng-content></mat-icon>
  </button>
</div>
