import { __extends } from "tslib";
import { Injectable } from '@angular/core';
import { IonicNativePlugin, checkAvailability, cordova, getPromise } from '@ionic-native/core';
var EmailComposer = /** @class */function (_super) {
  __extends(EmailComposer, _super);
  function EmailComposer() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  EmailComposer.prototype.hasPermission = function () {
    return cordova(this, "hasPermission", {
      "successIndex": 0,
      "errorIndex": 2
    }, arguments);
  };
  EmailComposer.prototype.requestPermission = function () {
    return cordova(this, "requestPermission", {
      "successIndex": 0,
      "errorIndex": 2
    }, arguments);
  };
  EmailComposer.prototype.hasAccount = function () {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return getPromise(function (resolve) {
          EmailComposer.getPlugin().hasAccount(function (result) {
            if (result) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        });
      }
    }();
  };
  EmailComposer.prototype.hasClient = function (app) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return getPromise(function (resolve) {
          if (app) {
            EmailComposer.getPlugin().hasClient(app, function (result) {
              if (result) {
                resolve(true);
              } else {
                resolve(false);
              }
            });
          } else {
            EmailComposer.getPlugin().getClients(function (apps) {
              resolve(apps && apps.length > 0);
            });
          }
        });
      }
    }();
  };
  EmailComposer.prototype.getClients = function () {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return getPromise(function (resolve) {
          EmailComposer.getPlugin().getClients(function (apps) {
            if (Object.prototype.toString.call(apps) === '[object String]') {
              apps = [apps];
            }
            resolve(apps);
          });
        });
      }
    }();
  };
  EmailComposer.prototype.isAvailable = function (app) {
    var _this = this;
    return function () {
      if (checkAvailability(_this) === true) {
        return getPromise(function (resolve) {
          Promise.all([_this.hasAccount, _this.hasClient(app)]).then(function (results) {
            return resolve(results.length === 2 && results[0] && results[1]);
          });
        });
      }
    }();
  };
  EmailComposer.prototype.open = function (options, scope) {
    return cordova(this, "open", {
      "successIndex": 1,
      "errorIndex": 3
    }, arguments);
  };
  EmailComposer.prototype.addAlias = function (alias, packageName) {
    return cordova(this, "addAlias", {}, arguments);
  };
  EmailComposer.pluginName = "EmailComposer";
  EmailComposer.plugin = "cordova-plugin-email-composer";
  EmailComposer.pluginRef = "cordova.plugins.email";
  EmailComposer.repo = "https://github.com/katzer/cordova-plugin-email-composer";
  EmailComposer.platforms = ["Amazon Fire OS", "Android", "Browser", "iOS", "Windows", "macOS"];
  EmailComposer.decorators = [{
    type: Injectable
  }];
  return EmailComposer;
}(IonicNativePlugin);
export { EmailComposer };
