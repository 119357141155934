import {Directive} from '@angular/core';

/**
 * add press support to component
 */
@Directive({
  selector: '[uncorrected-input]',
  host: {
    "autocomplete":"on",
    "autocorrect":"off",
    "autocapitalize":"off",
    "spellcheck":"false",
  }
})
export class UncorrectedInputDirective {
}
